import React, { useState } from "react";
import styles from "./index.module.css";
import { ReactComponent as GoBackIcon } from "assets/icons/Icon-Back-Button.svg";
import Button from "components/SixtyButton";
import SixtySelectField from "components/SixtySelect";
import Avatar from "components/Avatar";
import { useHistory } from "react-router-dom";
import ReactWhatsapp from 'react-whatsapp';

const SellerPageHeader = ({
  title,
  onGoBack,
  onEdit,
  onActivate,
  onRestrictAccount,
  onUnlockAccountRestriction,
  status,
  loadingStatus,
  pendingApproval,
  accountRestricted,
  restrictLoading,
  unlockLoading,
  code,
  contact,
}) => {
  const [moreActionsValue, setMoreActionsValue] = useState("");
  const history = useHistory();

  const getMoreActions = () => {
    if (status && status === "Approved") {
      return [
        {
          label: "Deactivate",
          value: "deactivate",
        },
      ];
    }
    if (status && status === "Suspended") {
      return [
        {
          label: "Activate",
          value: "activate",
        },
      ];
    }
    return [];
  };

  const onBrowseCreditHistories = () => {
    // history.push("/admin/influencer/histories/" + code);
    const win = window.open("/admin/influencer/histories/" + code, "_blank");
    win.focus();
  };

  const renderStatusButton = () =>{
    if(pendingApproval){
        return(      
            <> 
            <Button
                className={styles.actionButtons}
                variant={"secondary"}
                label={"Approve"}
                onClick={onActivate}
                disabled={loadingStatus}
              />
            </>
        )
    }
    else{
        return(      
            <> 
            <Button
                className={styles.actionButtons}
                variant={"secondary"}
                label={status && status === "Suspended" ? "Activate" : "Suspend"}
                onClick={onEdit}
                disabled={loadingStatus}
              />
            </>
        )
    }
}

const renderRestrictionButton = () =>{
  if(accountRestricted){
      return(      
          <> 
          <Button
              className={styles.actionButtons}
              variant={"secondary"}
              label={"Unlock Restriction"}
              onClick={onUnlockAccountRestriction}
              disabled={unlockLoading}
            />
          </>
      )
  }
  else{
      return(      
          <> 
          <Button
              className={styles.actionButtons}
              variant={"secondary"}
              label={"Restrict Account"}
              onClick={onRestrictAccount}
              disabled={restrictLoading}
            />
          </>
      )
  }
}

  return (
    <div className={styles.sellerHeader}>
      <div className={styles.titleBox}>
        <div onClick={onGoBack}>
          <GoBackIcon />
        </div>
        <div className={styles.sellerAvatar}>
          <Avatar name={title} />
        </div>
        <div className={styles.titleContent}>{title}</div>
      </div>
      <div className={styles.ButtonsContainer}>
        <div>
          <Button
                className={styles.directionButtons}
                variant={"tertiary"}
                label={"Browse Credit Histories"}
                onClick={onBrowseCreditHistories}
           />
            {renderStatusButton()} 
            {renderRestrictionButton()}
        </div>
        <div>
         {contact && (
           <>
            <ReactWhatsapp number={contact} message="">
               Open Whatapps
            </ReactWhatsapp>  
           </>
         )}
        </div>
      </div>
    </div>
  );
};

export default SellerPageHeader;
