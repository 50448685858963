import { forwardRef, useEffect, useRef } from "react";
import clsx from "clsx";
import Button from "../../components/SixtyButton";

import styles from "./index.module.css";
import Checkbox from "components/SixtyCheckBox";

export default function Table({
  instance,
  setStart,
  renderRowSubComponent,
  totalItems = 0,
  startFrom,
  limit = 50,
  onRowClicked,
}) {
  // table hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = instance;

  return (
    <div className={styles.tableDiv}>
      <div className={styles.tableWrap}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={styles.th}
                    {...column.getHeaderProps({
                      className: column.collapse ? styles.collapse : "",
                      style: column.style || {},
                    })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {(instance.pageOptions ? instance.page : rows).map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps({
                    className: onRowClicked ? clsx(styles.rowClick) : "",
                  })}
                >
                  {row.isExpanded && renderRowSubComponent ? (
                    <td colSpan={instance.visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  ) : (
                    row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.collapse
                              ? clsx(
                                  styles.collapse,
                                  cell.column.className && cell.column.className
                                )
                              : clsx(
                                  styles.td,
                                  cell.column.className && cell.column.className
                                ),
                            style: cell.column.style || {},
                            onClick: cell.column.collapse
                              ? undefined
                              : (e) => onRowClicked && onRowClicked(row, e),
                          })}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* pagination section */}
        {totalItems > 0 && (
          <Pagination
            total={totalItems}
            limit={limit}
            startFrom={startFrom}
            setStart={setStart}
          />
        )}
      </div>
    </div>
  );
}

function Pagination({ total, limit, startFrom, setStart }) {
  const goPrev = () => {
    let start = startFrom - limit;
    if (start >= 0) {
      setStart(start);
    }
  };

  const goNext = () => {
    let start = startFrom + limit;
    if (start < total) {
      setStart(start);
    }
  };

  return (
    <>
      <div className={styles.paginationContainer}>
        {total > limit && (
          <>
            <Button
              onClick={goPrev}
              disabled={startFrom === 0}
              variant="secondary"
              className={clsx(startFrom === 0 && styles.disabledPage)}
            >
              Prev
            </Button>

            <PaginationPages
              total={total}
              limit={limit}
              start={startFrom}
              setStart={setStart}
            />

            <Button
              onClick={goNext}
              disabled={startFrom + limit >= total}
              variant="secondary"
              className={clsx(startFrom + limit >= total && styles.disabledPage)}
            >
               Next
            </Button>
          </>
        )}
      </div>
    </>
  );
}

// shows first pages, last pages, the current page and it's neighbours in the pagination section
function PaginationPages({ total, limit, setStart, start }) {
  let pagination = [];
  let pages = Math.ceil(total / limit);
  for (let i = 0; i < pages; ++i) {
    if ([0, 1, pages - 1, pages - 2].includes(i)) {
      pagination.push(i);
    } else if (
      (i + 1) * limit === start ||
      i * limit === start ||
      (i - 1) * limit === start
    ) {
      pagination.push(i);
    } else {
      pagination.push(-1);
    }
  }
  pagination = pagination.reduce((acc, cur) => {
    if (cur === -1 && acc[acc.length - 1] === -1) {
      return acc;
    }
    return [...acc, cur];
  }, []);

  return pagination.map((page) =>
    page === -1 ? (
      "..."
    ) : (
      <PaginationNumber
        key={page}
        page={page}
        active={start / limit === page}
        setStart={setStart}
        limit={limit}
        total={total}
      />
    )
  );
}

function PaginationNumber({ page, active, setStart, limit, total }) {
  const handlePageClick = (page) => {
    let start = page * limit;
    if (page !== active) setStart(start);
  };
  return (
    <div
      onClick={() => handlePageClick(page)}
      className={clsx(styles.page, active && styles.pageActive)}
    >
      {page + 1}
    </div>
  );
}

export function TableHeader({ children }) {
  return <div className={styles.headerOptionsContainer}>{children}</div>;
}

export function RowSelectionContainer({
  renderRowSelectionText,
  renderRowSelectionButtons,
}) {
  return (
    <div className={styles.rowsSelectedContainer}>
      <div className={styles.rowsSelectedText}>{renderRowSelectionText()}</div>
      <div className={styles.rowsSelectedButtons}>
        {renderRowSelectionButtons()}
      </div>
    </div>
  );
}

export const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={ref} {...rest} uncheckStyleVariant="greyedOut" />
      </>
    );
  }
);
