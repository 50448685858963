import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import RefundTable from "./Components/RefundTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import Tabs from "components/Tabs";
import { useGetVendorRefundStatusCount } from "queries/admin";

const VendorRefunds = () => {
  const [isRefundDetailOpen, setRefundDetailOpen] = useState(false);
  const history = useHistory();
  const refundDetailRef = useRef();
  const [selectedRefund, setSelectedRefund] = useState({});

  const {
    data: refundData,
  } = useGetVendorRefundStatusCount();

  const RefundDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedRefund.vendorName} />
          </div>
          <div className={styles.modalTitle}>{selectedRefund.vendorName}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setRefundDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onRefundClick = (value) => {
    const path = `/admin/vendors/refunds/${value}`;
    history.push(path);
  };

  return (
    <div>
      <GridHeader header="Refunds" />
      {/* <Panel className={styles.panelWrap}>
        <div className={styles.vendorTable}>
          <RefundTable onRefundClick={onRefundClick} />
        </div>
      </Panel> */}
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Pending",
              recordsCount: refundData?.pendingRefundCount,
              TabComponent: RefundTable,
              props: {
                status: "Pending",
                onRefundClick: onRefundClick
              },
            },
            {
              tabId: "2",
              label: "Completed",
              recordsCount: refundData?.completedRefundCount,
              TabComponent: RefundTable,
              props: {
                status: "Completed",
                onRefundClick: onRefundClick
              },
            },
          ]}
        ></Tabs>
      </Panel>
    </div>
  );
};

export default VendorRefunds;
