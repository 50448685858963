import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useGetSellerRejectReasonDropdown, useUpdateWithdrawStatus } from "queries/admin";

import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import ControlledSelect from "components/FormComponents/ControlledSelect";
import Input from "components/FormComponents/ControlledInput";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import { useEffect } from "react";
import { useState } from "react";


const defaultValues = {
  rejectionReason: "",
  message: "",
};

const WithdrawRejectForm = ({ selectedWithdraw, handleClose }) => {
  const [sellerRejectOptions, setSellerRejectOptions] = useState([]);
  const formMethods = useForm({
    defaultValues,
  });
  const { data: sellerRejectReasonDropdown, isLoading: isSellerReasonLoading } = useGetSellerRejectReasonDropdown();
  
  const { mutateAsync: updateWithdrawStatus, isLoading: withdrawStatusLoading } = useUpdateWithdrawStatus();

  useEffect(() => {
    if(!isSellerReasonLoading && sellerRejectReasonDropdown) {
      setSellerRejectOptions(
        sellerRejectReasonDropdown?.map((option) => ({
        label: option.name,
        value: option.key,
      }))
      );
    }
  },
  [isSellerReasonLoading, sellerRejectReasonDropdown]);

  const onSubmit = async (data) => {
    console.log("data from form", data);
    const { id } = selectedWithdraw;
    try {
      const result = await updateWithdrawStatus({
        id: id,
        status: "Rejected",
        reason: data.rejectionReason,
        message: data.message
      });
      if (result) {
        handleClose();
        setToast("successfully rejected seller");
      }
    } catch (e) {
      handleClose();
      reportError("error in : SellerRequestForm: onRejectSeller, error: ", e);
    }
  };
  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.sellerRejectFormContainer}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={styles.sellerRejectForm}>
              <div>
                <ControlledSelect
                  name={`rejectionReason`}
                  options={sellerRejectOptions}
                  fullWidth={true}
                  defaultOption="Select a reason"
                  variant="secondary"
                />
              </div>
              <div className={styles.rejectionReasonContainer}>
                <Input
                  name="message"
                  placeholder="Message to seller(optional)"
                  label="Message to seller"
                  lines="5"
                />
              </div>
            </div>
            <footer className={styles.footer}>
              <SixtyButton
                type="submit"
                variant="warning"
                className={styles.rejectBtn}
                disabled={withdrawStatusLoading}
              >
                Reject
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={handleClose}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default WithdrawRejectForm;
