import React from "react";
import { formatCurrency } from "utils/common";
import { paymentDetails } from "../../fakeData";
import styles from "./index.module.css";

const PaymentDetails = ({ paymentDetail }) => {
  // const totalAmount =
  //   (parseFloat(paymentDetail?.subTotal) || 0) +
  //   (parseFloat(paymentDetail?.shippingFee) || 0) +
  //   (parseFloat(paymentDetail?.processingFee) || 0) +
  //   (parseFloat(paymentDetail?.refund) || 0) +
  //   (parseFloat(
  //     (paymentDetail?.subTotal +
  //       paymentDetail?.shippingFee +
  //       paymentDetail?.refund +
  //       paymentDetail?.processingFee) *
  //       (paymentDetail?.tax / 100)
  //   ) || 0);
  return (
    <React.Fragment>
      {paymentDetails && (
        <div>
          <div className={styles.paymentBody}>
            {paymentDetail?.subTotal && (
              <div className={styles.paymentRow}>
                <div className={styles.paymentFieldName}>Subtotal</div>
                <div className={styles.paymentFieldValue}>
                  {formatCurrency(paymentDetail?.subTotal)}
                </div>
              </div>
            )}
            
            <div className={styles.paymentRow}>
              <div className={styles.paymentFieldName}>Commission</div>
              <div className={styles.paymentFieldValue}>
                {formatCurrency(paymentDetail?.commission)}
              </div>
            </div>
            <div className={styles.paymentRow}>
              <div className={styles.paymentFieldName}>Shipping fees</div>
              <div className={styles.paymentFieldValue}>
                {formatCurrency(paymentDetail?.shippingFee)}
              </div>
            </div>

            <div className={styles.paymentRow}>
              <div className={styles.paymentFieldName}>
                Applicable tax
                {/* ({`${paymentDetail?.tax}%`}) */}
              </div>
              <div className={styles.paymentFieldValue}>
                {formatCurrency(paymentDetail?.tax)}
              </div>
            </div>

            <div className={styles.paymentRow}>
              <div className={styles.paymentFieldName}>Processing fee</div>
              <div className={styles.paymentFieldValue}>
                {formatCurrency(paymentDetail?.processingFee)}
              </div>
            </div>

            <div className={styles.paymentRow}>
              <div className={styles.paymentFieldName}>Discount</div>
              <div className={styles.paymentFieldValue}>
                {formatCurrency(paymentDetail?.discount)}
              </div>
            </div>

            <div className={styles.paymentRow}>
              <div className={styles.paymentFieldName}>Refund</div>
              <div className={styles.paymentFieldValue}>
                {formatCurrency(paymentDetail?.refund)}
              </div>
            </div>

            {(
              <div
                className={[styles.paymentRow, styles.totalAmountRow].join(" ")}
              >
                <div className={styles.paymentFieldName}>Total</div>
                <div className={styles.paymentFieldValue}>
                  {formatCurrency(paymentDetail?.total)}
                </div>
              </div>
            )}
          </div>
          {/* TODO -> Hidden for now -> Add when API supports this */}
          {paymentDetail?.status && (
            <div className={styles.paymentSummary}>
              <div>Paid by customer</div>
              <div className={styles.paymentRightContainer}>
                {/* <div className={styles.paymentStatus}>
                  {paymentDetail?.status}
                </div> */}
                <div>
                  {"RM" +
                    (paymentDetail?.paidByCustomer
                      ? paymentDetail?.paidByCustomer
                      : "0.00")}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default PaymentDetails;
