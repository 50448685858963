import React, { useState, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAddProducts } from "queries/product";
import { useUploads } from "queries/media";
import _cloneDeep from "lodash/cloneDeep";

import Modal from "components/Modal";
import Panel from "components/SixtyPanel";
import BasicInfo from "../components/BasicInfo";
import Media from "../components/Media";
import Pricing from "../components/Pricing";
import Inventory from "../components/Inventory";
import Shipping from "../components/Shipping";
import Options from "../components/Options";
import Variants from "../components/Variants";
import PanelHeader from "components/PanelHeader";
import SixtyButton from "components/SixtyButton";

import styles from "./index.module.css";
import Loader from "components/Loader";
import ErrorModal from "components/Error";
import { setToast } from "components/Toast";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

const defaultValues = {
  categoryId: "",
  taxGroupId: null,
  shippingGroupId: "",
  baseUomUnit: "Unit",
  IsFeatured: false,
  skuCode: "",
  name: "",
  description: "",
  costPrice: null,
  sellingPrice: null,
  marketPrice: null,
  gotStockCount: false,
  weight: null,
  weightUom: "Kg",
  height: null,
  heightUom: "Cm",
  width: null,
  widthUom: "Cm",
  length: null,
  lengthUom: "Cm",
  barcode: null,
  ContinueSellingWithoutStock: false,
  status: "",
  categories: [],
  optionGroups: [],
  productImages: [],
  productVariants: [],
  productPriceTiers: [],
};

const CreateProduct = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const history = useHistory();

  const handleClose = () => {
    history.push("/dashboard/products");
    setModalIsOpen(false);
  };

  const Title = () => {
    return (
      <div className={styles.modalTitleBar}>
        <div className={styles.productModalTitle}>Create product</div>
        <div>
          <CloseIcon className={styles.closeIcon} onClick={handleClose} />
        </div>
      </div>
    );
  };

  const createProductRef = useRef();

  // validation schema [------IMPORTANT------]
  const schema = yup.object().shape({
    name: yup.string().required("name is required"),
    description: yup.string(),
    IsFeatured: yup.boolean(),
    status: yup.string().required("select the status of product"),
    costPrice: yup
      .number()
      .required("required")
      .min(0, "price should be valid")
      .nullable(),
    sellingPrice: yup
      .number()
      .required("required")
      .min(0, "price should be valid")
      .nullable(),
    marketPrice: yup
      .number()
      .required("required")
      .min(0, "price should be valid")
      .nullable(),
    taxGroupId: yup.string().nullable(),
    productPriceTiers: yup.array().of(
      yup.object().shape({
        PriceTierId: yup.string(),
        OverrideDiscountRate: yup.number().nullable(),
      })
    ),
    skuCode: yup.string(),
    barcode: yup.string().nullable(),
    gotStockCount: yup.boolean(),
    stockCount: yup.number().when("gotStockCount", {
      is: true,
      then: yup
        .number()
        .min(0, "stock count should be valid")
        .required("enter stock count"),
      otherwise: yup.number().nullable(),
    }),
    weight: yup.number().nullable(),
    height: yup.number().nullable(),
    width: yup.number().nullable(),
    length: yup.number().nullable(),
    optionGroups: yup.array(
      yup.object().shape({
        optionGroupName: yup.string().required("required"),
        options: yup
          .array(
            yup.object().shape({
              optionGroupName: yup.string(),
            })
          )
          .required()
          .min(1, "add atleast one option or remove the option group"),
      })
    ),
    productVariants: yup.array().when("optionGroups", {
      is: (val) => Array.isArray(val) && val.length > 0,
      then: yup.array(
        yup.object().shape({
          skuCode: yup.string().nullable(),
          costPrice: yup.number().nullable(),
          sellingPrice: yup.number().nullable(),
          marketPrice: yup.number().nullable(),
          gotStockCount: yup.boolean(),
          stockCount: yup.number().nullable(),
          imagePath: yup.array().nullable(),
          shippingGroupId: yup.string().nullable(),
          weight: yup.number().nullable(),
          height: yup.number().nullable(),
          width: yup.number().nullable(),
          length: yup.number().nullable(),
          variantOptions: yup.array(
            yup.object().shape({
              optionGroupName: yup.string(),
              optionValueName: yup.string(),
            })
          ),
        })
      ),
      otherwise: yup.array(),
    }),
  });

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  // mutation for adding product api
  const {
    mutateAsync: addProduct,
    isLoading: addingProduct,
  } = useAddProducts();

  // mutation for file uploaidng api
  const { mutateAsync: uploadFiles, isLoading: uploadingFiles } = useUploads();

  const [error, setError] = useState(false);
  const errorRef = useRef();
  const closeError = () => {
    setError(false);
  };

  // if api fails, store in this ref for retries
  const errorValsRef = useRef();
  const handleAction = () => {
    setError(false);
    let vals = _cloneDeep(errorValsRef.current);
    formMethods.reset(vals);
    onSubmit(vals);
  };

  async function onSubmit(data) {
    const formData = _cloneDeep(data);
    delete formData.enableTiers; // the api doesn't need this field
    try {
      // Check if images are present,
      // if images are added, need to upload and get the links
      if (formData.mainImages?.length > 0) {
        const images = new FormData();
        images.append(`files[0].fileName`, formData.mainImages[0].name);
        images.append("files[0].file", formData.mainImages[0]);
        if (formData.moreImages?.length > 0) {
          formData.moreImages?.forEach((file, index) => {
            images.append(`files[${index + 1}].fileName`, file.name);
            images.append(`files[${index + 1}].file`, file);
          });
        }

        const result = await uploadFiles(images);

        // transform API result to addProducts API body format
        formData.productImages = result
          ? result.map((image, index) => ({
              name: image.fileName,
              path: image.path,
              position: index,
            }))
          : null;

        // after adding images to productImages, we can remove
        // mainImages, moreImages fields from formData
        delete formData.mainImages;
        delete formData.moreImages;
      }
      if (formData.productVariants?.length > 0) {
        let variantImages = [];
        let variantImageList = new FormData();
        let currentIndex = 0;
        formData.productVariants?.forEach((variant, index) => {
          if (variant.imagePath.length > 0) {
            variantImages = [
              ...variantImages,
              { file: variant.imagePath[0], position: index },
            ];
            variantImageList.append(
              `files[${currentIndex}].fileName`,
              variant.imagePath[0].name
            );
            variantImageList.append(
              `files[${currentIndex}].file`,
              variant.imagePath[0]
            );
            ++currentIndex;
          } else {
            variant.imagePath = null;
          }
        });

        if (variantImages.length > 0) {
          const result = await uploadFiles(variantImageList);
          variantImages.forEach((image, i) => {
            let index = image.position;
            if (result[i])
              formData.productVariants[index].imagePath = result[i]?.path;
          });
        }
      }

      // delete empty array fields from formData
      if (formData.productImages?.length === 0) delete formData.productImages;

      if (formData.productPriceTiers?.length === 0)
        delete formData.productPriceTiers;

      if (formData.productVariants?.length === 0)
        delete formData.productVariants;

      if (formData.optionGroups?.length === 0) delete formData.optionGroups;

      const success = await addProduct(formData);
      // if adding product succeed, redirect to all products page, and close modal
      if (success) {
        setToast("Product successfully added");
        handleClose();
      }
    } catch (e) {
      errorValsRef.current = data;
      setToast("Adding product failed", "error");
      setError(true);
    }
  }

  if (error) {
    return (
      <ErrorModal
        open={error}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage="Adding product failed"
      />
    );
  }

  // we check updates to 'optionGroups' to display the variants Table
  const options = formMethods.watch("optionGroups");
  const isValidOptions = () => {
    if (options && Array.isArray(options) && options.length > 0) {
      for (let i = 0; i < options.length; ++i) {
        if (options[i].options.length > 0) {
          return true;
        }
      }
      return false;
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAttemptClose={handleClose}
      modalRef={createProductRef}
      Title={Title}
      loading={uploadingFiles || addingProduct}
      loadingContent={
        <div>
          <Loader />
          {uploadingFiles ? (
            <div className={styles.loaderText}>Uploading Files</div>
          ) : (
            addingProduct && (
              <div className={styles.loaderText}>Adding product</div>
            )
          )}
        </div>
      }
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          className={styles.form}
        >
          <div className={styles.itemsContainer}>
            <Panel className={styles.panelWrap}>
              <PanelHeader title="1. Basic info" />
              <BasicInfo />
            </Panel>
            <Panel className={styles.panelWrap}>
              <PanelHeader title="2. Media" />
              <Media />
            </Panel>
            <Panel className={styles.panelWrap}>
              <PanelHeader title="3. Pricing" />
              <Pricing />
            </Panel>
            {/* <Panel className={styles.panelWrap}>
              <PanelHeader title="4. Inventory" />
              <Inventory />
            </Panel> */}
            {/* <Panel className={clsx(styles.panelWrap, styles.lastPanel)}>
              <PanelHeader
                title="5. Shipping"
                subTitle="Scale below are used to calculate shipping rates at checkout. Shipping address and method will be collected when customers checkout."
                className={styles.shippingPanelHeader}
              />
              <Shipping />
            </Panel> */}
            {/* <Panel className={styles.panelWrap}>
              <PanelHeader title="6. Options" />
              <Options />
            </Panel> */}
            {/* only display Variants section if the options are valid */}
            {/* {isValidOptions() && (
              <Panel className={styles.panelWrap}>
                <PanelHeader title="7. Variants" />
                <Variants options={options} />
              </Panel>
            )} */}
          </div>

          <footer className={styles.footer}>
            <SixtyButton type="submit">Create Product</SixtyButton>
            <SixtyButton
              variant="secondary"
              className={styles.cancelButton}
              onClick={handleClose}
            >
              Cancel
            </SixtyButton>
          </footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CreateProduct;
