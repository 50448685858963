import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetSellerRegistrationDetail } from "queries/seller";
import { useGetSellerShipmentDetail, useUpdateSellerCampaignShipment } from "queries/campaign";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import { useHistory, useParams } from "react-router-dom";

import styles from "./index.module.css";
import Loader from "components/Loader";

import { useForm, FormProvider } from "react-hook-form";
import Input from "components/FormComponents/ControlledInput";
import { clearStorage } from "utils/storage";

const Calendly = () => {

  const params = useParams();
  const role = localStorage.getItem("userRole");
  const history = useHistory();

  const onPreview = async () => {
      const _path = `https://calendly.com/chuan/15min`;
      window.open(_path, "_blank")
  };

  const onLogout = async () => {
    clearStorage();
    history.push(`/`);
  };

  return (
    <React.Fragment>
      <div className={styles.sellerRequestFormContainer}>

      <div className={styles.sellerRequestForm} hidden={false}>
            <div>
                <Panel className={styles.panelWrap}>
                    <PanelHeader
                    className={styles.sellerInfoHeader}
                    title="Talk with our sales team to activate account"
                    />
                    <div className={styles.postContainer}>
                        {/* <SixtyButton
                            variant="tertiary"
                            onClick={() => onPreview()}
                            disabled={false}
                            >
                            {"Book Appointment"}
                        </SixtyButton> */}
                    </div>
                </Panel>
            </div>
        </div>

        <footer className={styles.footer}>
           <SixtyButton
              variant="warning"
              className={styles.cancelButton}
              onClick={onLogout}
            >
              Logout
            </SixtyButton>
        </footer>

      </div>
    </React.Fragment>
  );
};

export default Calendly;
