import jwt_decode from 'jwt-decode';

export const hasTokenExpired = (token) => {
    if (token) {
        const tokenData = jwt_decode(token);
        const current_time = new Date().getTime() / 1000;
        return current_time > tokenData.exp;
    } else {
        return false;
    }
};

export function getAccessToken() {
  const token = localStorage.getItem("token");
  return token;
}

export function getRefreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  return refreshToken;
}

export function setTokens({ token, refreshToken, accountType}){
  localStorage.setItem('token', token)
  localStorage.setItem('userRole', accountType)
  localStorage.setItem('refreshToken', refreshToken)
 }