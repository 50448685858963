import styles from "./index.module.css";
import SixtyButton from "components/SixtyButton";
import { useHistory } from "react-router-dom";

export default function SubscriptionPermission({fillContainer}) {

  const history = useHistory();

  const onSubscribe = () => {
    history.push('/dashboard/settings/billing')
  };

  return (
    <div className={fillContainer ? styles.fillContainer : styles.container}>
      <h2 className={styles.h2}>Subscribe to view</h2>
      <SixtyButton
            variant="tertiary"
            className={styles.subscribezButton}
            onClick={onSubscribe}
          >
            Subscribe
      </SixtyButton>
    </div>
  );
}
