import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import PanelHeader from "components/PanelHeader";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import GridHeader from "components/GridHeader";
import Button from "components/SixtyButton";
import ProductsByCategory from "../ProductsByCategory";
import { ReactComponent as GoBackIcon } from "assets/icons/Icon-Back-Button.svg";
import { useDeleteCategory, useGetCategoryDetail } from "queries/product";
import CategoryForm from "../../components/CategoryForm";
import ErrorModal from "components/Error";
import { setToast } from "components/Toast";

const SellerDetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const onGoBack = () => {
    history.goBack();
  };

  const { data: categoryData } = useGetCategoryDetail(id);

  const { mutateAsync: deleteCategory, isSuccess } = useDeleteCategory();

  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef();

  const closeModal = () => {
    setModalOpen(false);
  };

  const onDuplicate = () => {};

  const onArchive = () => {};

  const onDelete = async () => {
    await deleteCategory(categoryData.id);
  };
  useEffect(() => {
    if (isSuccess) {
      setToast("successfully deleted category");
      history.push("/dashboard/product-categories/");
    }
  }, [history, isSuccess]);

  return (
    <div className={styles.detailsPage}>
      <GridHeader
        header={categoryData?.name}
        renderButtons={() => (
          <>
            {/* <Button variant="secondary" onClick={onDuplicate}>Duplicate</Button>
                        <Button variant="secondary" onClick={onArchive}>
                            Archive
                        </Button> */}
            <Button variant="secondary" onClick={() => setModalOpen(true)}>
              Delete
            </Button>
          </>
        )}
        PreHeaderBtn={() => (
          <span className={styles.goBackBtn}>
            <GoBackIcon onClick={onGoBack} />
          </span>
        )}
        className={styles.categoryGridHeader}
      />
      <div className={styles.row}>
        <div className={styles.sellerDetailsLeftContainer}>
          <Panel className={clsx(styles.panelWrap, styles.firstPanel)}>
            {categoryData?.name && (
              <CategoryForm categoryName={categoryData?.name} />
            )}
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader title="Products" />
            <ProductsByCategory />
          </Panel>
        </div>
      </div>
      <ErrorModal
        open={modalOpen}
        handleClose={closeModal}
        handleCancel={closeModal}
        handleAction={onDelete}
        actionLabel={"Delete"}
        errorRef={modalRef}
        errorMessage={"Are you sure?"}
      />
    </div>
  );
};

export default SellerDetailsPage;
