import RadioField from "components/RadioField";
import Input from "components/SixtyInput";
import Checkbox from "components/SixtyCheckBox";
import {
  FilterGroup,
  FilterItem,
  FilterRow,
  FilterChipContainer,
  ChipItem,
} from "components/SixtyTable/components/Filter";

import _omit from "lodash/omit";
import { useGetPriceTierDropdown } from "queries/product";
import SixtySelectField from "components/SixtySelect";
import DatePicker from "components/SixtyDatePicker";

export const labelMap = {
  filterByActive: () => "Active",
  filterByDeactivated: () => "Deactivated",
  filterByMaxItemsSold: (state) => "Max items sold: " + state,
  filterByMinItemsSold: (state) => "Min items sold: " + state,
  filterByMinTotalCommission: (state) => "Min total commission: " + state,
  filterByMaxTotalCommission: (state) => "Max total commission: " + state,
  filterByMinTotalRevenue: (state) => "Min total revenue: " + state,
  filterByMaxTotalRevenue: (state) => "Max total revenue: " + state,
  date: {
    filterByToday: "Today",
    filterByLast7Days: "Last 7 days",
    filterByLast30Days: "Last 30 days",
    filterByLast90Days: "Last 90 days",
    filterByLast12Months: "Last 12 months",
  },
};

export default function SellerFilter({ state, setState }) {
  // const { data: priceTierData, isLoading } = useGetPriceTierDropdown({
  //   staleTime: 60 * 1000,
  // });

  // const options =
  //   priceTierData?.length > 0
  //     ? priceTierData.map((category) => ({
  //         label: category.name,
  //         value: category.id,
  //       }))
  //     : [];

  const handleInputChange = (field, value) => {
    setState((filter) => {
      if (value) filter[field] = value;
      else {
        delete filter[field];
      }
    });
  };

  // const handleTierSelect = (value) => {
  //   setState((filter) => {
  //     filter.filterByPriceTierId = value;
  //     filter.tierName = options.find((option) => option.value === value).label;
  //   });
  // };

  function handleCustomFieldChange(field, value) {
    setState((filterState) => {
      filterState["date"][field] = value;
    });
  }

  const handleDateFieldChange = (fieldName) => {
    setState((filter) => {
      if (fieldName === "custom")
        filter.date = { custom: true, fromDate: null, untilDate: null };
      else filter.date = { [fieldName]: true };
    });
  };

  return (
    <>
      <FilterRow>
        {/* <FilterGroup label="Tiers" colWidth={2}>
          <FilterItem fullWidth>
            <SixtySelectField
              options={options}
              defaultOption={isLoading ? "fetching options..." : "Tier Name"}
              value={state?.filterByPriceTierId}
              onChange={(value) => handleTierSelect(value)}
              disableDropIcon
              fullWidth
            />
          </FilterItem>
        </FilterGroup> */}
        {/* <FilterGroup label="No of items sold" colWidth={2}>
          <FilterItem colWidth={2}>
            <Input
              label="Min. amount"
              value={state?.filterByMinItemsSold}
              type="number"
              onChange={(e) => {
                handleInputChange("filterByMinItemsSold", e.target.value);
              }}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <Input
              label="Max. amount"
              value={state?.filterByMaxItemsSold}
              type="number"
              onChange={(e) => {
                handleInputChange("filterByMaxItemsSold", e.target.value);
              }}
            />
          </FilterItem>
        </FilterGroup> */}
      </FilterRow>
      {/* <FilterRow>
        <FilterGroup label="Total revenue" colWidth={2}>
          <FilterItem colWidth={2}>
            <Input
              label="Min. amount"
              value={state?.filterByMinTotalRevenue}
              type="number"
              onChange={(e) => {
                handleInputChange("filterByMinTotalRevenue", e.target.value);
              }}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <Input
              label="Max. amount"
              value={state?.filterByMaxTotalRevenue}
              type="number"
              onChange={(e) => {
                handleInputChange("filterByMaxTotalRevenue", e.target.value);
              }}
            />
          </FilterItem>
        </FilterGroup>
        <FilterGroup label="Total commission" colWidth={2}>
          <FilterItem colWidth={2}>
            <Input
              label="Min. amount"
              value={state?.filterByMinTotalCommission}
              type="number"
              onChange={(e) => {
                handleInputChange("filterByMinTotalCommission", e.target.value);
              }}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <Input
              label="Max. amount"
              value={state?.filterByMaxTotalCommission}
              type="number"
              onChange={(e) => {
                handleInputChange("filterByMaxTotalCommission", e.target.value);
              }}
            />
          </FilterItem>
        </FilterGroup>
      </FilterRow> */}
      <FilterRow>
        <FilterGroup label="Date" colWidth={2}>
          <FilterItem colWidth={2}>
            <RadioField
              label="Today"
              name={"date"}
              value={"filterByToday"}
              checked={state?.date?.filterByToday}
              onChange={(e) => handleDateFieldChange("filterByToday")}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <RadioField
              label="Last 7 Days"
              name={"date"}
              value={"filterByLast7Days"}
              checked={state?.date?.filterByLast7Days}
              onChange={(e) => handleDateFieldChange("filterByLast7Days")}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <RadioField
              label="Last 30 Days"
              name={"date"}
              value={"filterByLast30Days"}
              checked={state?.date?.filterByLast30Days}
              onChange={(e) => handleDateFieldChange("filterByLast30Days")}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <RadioField
              label="Last 90 Days"
              name={"date"}
              value={"filterByLast90Days"}
              checked={state?.date?.filterByLast90Days}
              onChange={(e) => handleDateFieldChange("filterByLast90Days")}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <RadioField
              label="Last 12 Months"
              name={"date"}
              value={"filterByLast12Months"}
              checked={state?.date?.filterByLast12Months}
              onChange={(e) => handleDateFieldChange("filterByLast12Months")}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <RadioField
              label="Custom"
              name={"date"}
              value={"custom"}
              checked={state?.date?.custom}
              onChange={(e) => handleDateFieldChange("custom")}
            />
          </FilterItem>
          {state?.date?.custom && (
            <>
              <FilterItem colWidth={2}>
                 <DatePicker
                  label="Start date"
                  value={state?.date?.fromDate}
                  onChange={(date) => {
                    handleCustomFieldChange("fromDate", date);
                  }}
                  dateFormat="dd/MM/yyyy"
                  maxDate={state?.date?.untilDate}
                />
              </FilterItem>
              <FilterItem colWidth={2}>
                <DatePicker
                  label="End date"
                  value={state?.date?.untilDate}
                  onChange={(date) => {
                    handleCustomFieldChange("untilDate", date);
                  }}
                  dateFormat="dd/MM/yyyy"
                  minDate={state?.date?.fromDate}
                />
              </FilterItem>
            </>
          )}
        </FilterGroup>
        {/* <FilterGroup label="Status">
          <FilterItem>
            <Checkbox
              label="Active"
              name={"filterByActive"}
              checked={!!state["filterByActive"]}
              onChange={(e) => {
                handleInputChange("filterByActive", e.target.checked);
              }}
            />
          </FilterItem>
          <FilterItem>
            <Checkbox
              label="Deactivated"
              name={"filterByDeactivated"}
              checked={!!state["filterByDeactivated"]}
              onChange={(e) => {
                handleInputChange("filterByDeactivated", e.target.checked);
              }}
            />
          </FilterItem>
        </FilterGroup> */}
      </FilterRow>
    </>
  );
}

export function renderFilterChips(filterState, setFilterState) {
  let filtersPresent = false;
  for (const filterKey in filterState) {
    if (filterKey === "date") {
      for (const dateKey in labelMap["date"]) {
        if (!!filterState.date[dateKey]) {
          filtersPresent = true;
          break;
        }
      }
      if (filtersPresent) break;
    } else if (!!filterState[filterKey]) {
      filtersPresent = true;
    }
  }

  const removeFilter = (filterItem) => {
    setFilterState((filter) => {
      delete filter[filterItem];
    });
  };

  const removeTier = () => {
    setFilterState((filter) => {
      delete filter.filterByPriceTierId;
      delete filter.tierName;
    });
  };

  const removeDateFilter = (filterItem) => {
    setFilterState((filter) => {
      if (filterItem === "custom") {
        delete filter.date.custom;
        delete filter.date.fromDate;
        delete filter.date.untilDate;
      } else delete filter.date[filterItem];
    });
  };

  if (filtersPresent) {
    let dateFilters = filterState.date || {};
    let otherFilters = _omit(filterState, [
      "date",
      "tierName",
      "filterByPriceTierId",
    ]);
    let customFields = false;
    if (dateFilters.custom) {
      customFields = true;
      dateFilters = _omit(dateFilters, ["custom", "fromDate", "untilDate"]);
    }

    let tierFilter = filterState.tierName;
    return (
      <FilterChipContainer>
        {Object.keys(otherFilters).length > 0 &&
          Object.keys(otherFilters)?.map((filterItem) => (
            <>
              <ChipItem
                label={labelMap[filterItem](filterState[filterItem])}
                name={filterItem}
                handleRemove={removeFilter}
                key={filterItem}
              />
            </>
          ))}
        {Object.keys(dateFilters).length > 0 &&
          Object.keys(dateFilters)?.map((filterItem) => (
            <ChipItem
              label={labelMap.date[filterItem]}
              name={filterItem}
              handleRemove={removeDateFilter}
              key={filterItem}
            />
          ))}
        {customFields && (
          <ChipItem
            label={`${filterState.date.fromDate} - ${filterState.date.untilDate}`}
            name="custom"
            handleRemove={removeDateFilter}
          />
        )}
        {tierFilter && (
          <>
            <ChipItem
              label={`Tier is ${filterState.tierName}`}
              name={"tierName"}
              handleRemove={removeTier}
            />
          </>
        )}
      </FilterChipContainer>
    );
  }
  return null;
}
