import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import SellerApprovalTable from "../../Sellers/SellerApproval/components/ApprovalTable";
import InfluencerRequestTable from "../components/InfluencerRequestTable";
import InfluencerOfflineRequestTable from "../components/InfluencerOfflineRequestTable";
import InfluencerRequestDetails from "../components/InfluencerRequestDetails";
import AdminCampaignInvitation from "../components/AdminCampaignInvitation";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import { setToast } from "components/Toast";
import BasicPageHeader from "../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetCampaignSellerRequestCount } from "queries/admin";
import { useGetCampaignAnalytics } from "queries/campaign";
import Loader from "components/Loader";
import SixtyText from "components/SixtyText";

const AdminInfluencerJoinRequest = () => {
  
  const history = useHistory();
  const params = useParams();

  const [isSellerDetailOpen, setSellerDetailOpen] = useState(false);
  const sellerDetailRef = useRef();

  const [isInvitation, setInvitation] = useState(false);
  const invitationRef = useRef();

  const [selectedSeller, setSelectedSeller] = useState({});

  const {
    data: requestData,
  } = useGetCampaignSellerRequestCount(params.id);

  const { 
    data: campaignData, 
    isLoading: campaignLoading 
  } = useGetCampaignAnalytics(params.id);

  const SellerDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedSeller.name} />
          </div>
          <div className={styles.modalTitle}>{selectedSeller.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setSellerDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const InvitationTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedSeller.name} />
          </div>
          <div className={styles.modalTitle}>{selectedSeller.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setInvitation(false)}
          />
        </div>
      </div>
    );
  };

  const RenderOnlineTable = () => {
    return (
      <>
          <Panel className="tabs">
              <Tabs
                activeTab={ "1" }
                tabs={[              
                  {
                    tabId: "1",
                    label: 'Pending Approval Influencers',
                    recordsCount: requestData?.pendingRequestCount,
                    TabComponent: InfluencerRequestTable,
                    props: {
                      status: "Pending",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Influencers can access public campaign from app, recruit influencers who interesting to our campaign."                        
                    },
                  },
                  {
                    tabId: "2",
                    label: "Rejected Influencers",
                    recordsCount: requestData?.rejectedRequestCount,
                    TabComponent: InfluencerRequestTable,
                    props: {
                      status: "Rejected",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Rejected influencers who not qualified for the campaign criteria."
                    },
                  },
                  {
                    tabId: "3",
                    label: "Shortlisted Influencers",
                    recordsCount: requestData?.shortlistRequestCount,
                    TabComponent: InfluencerRequestTable,
                    props: {
                      status: "Shortlisted",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Shortlisted Influencers, awaiting influencers confirmation."
                    },
                  },
                  {
                    tabId: "4",
                    label: "Joined Influencers",
                    recordsCount: requestData?.approvedRequestCount,
                    TabComponent: InfluencerRequestTable,
                    props: {
                      status: "Approved",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Joined influencers able to access campaign, upload draft, generate posting link and claim reward from app."
                    },
                  },
                ]}
              ></Tabs>
            </Panel>
      </>
    );
  };

  const RenderOflineTable = () => {
    return (
      <>
          <Panel className="tabs">
              <Tabs
                activeTab={ "1" }
                tabs={[              
                  {
                    tabId: "1",
                    label: 'Pending Approval Influencers',
                    recordsCount: requestData?.pendingRequestCount,
                    TabComponent: InfluencerOfflineRequestTable,
                    props: {
                      status: "Pending",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Influencers can access public campaign from app, recruit influencers who interesting to our campaign."                        
                    },
                  },
                  {
                    tabId: "2",
                    label: "Rejected Influencers",
                    recordsCount: requestData?.rejectedRequestCount,
                    TabComponent: InfluencerOfflineRequestTable,
                    props: {
                      status: "Rejected",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Rejected influencers who not qualified for the campaign criteria."
                    },
                  },
                  {
                    tabId: "3",
                    label: "Joined Influencers",
                    recordsCount: requestData?.approvedRequestCount,
                    TabComponent: InfluencerOfflineRequestTable,
                    props: {
                      status: "Approved",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Joined influencers able to access campaign, submit job and claim reward from app."
                    },
                  },
                ]}
              ></Tabs>
            </Panel>
      </>
    );
  };

  const RenderOnlineInvitationTable = () => {
    return (
      <>
          <Panel className="tabs">
              <Tabs
                activeTab={ "1" }
                tabs={[              
                  {
                    tabId: "1",
                    label: "Invitation",
                    TabComponent: SellerApprovalTable,
                    props: {
                      status: "Active",
                      onSellerClick: onInvitationClick,
                      passObject: true,
                      msg: "Invite influencers to join the private campaign, once invited, influencer will received notification."
                    },           
                  },
                  {
                    tabId: "2",
                    label: 'Invited Influencers',
                    recordsCount: requestData?.pendingRequestCount,
                    TabComponent: InfluencerRequestTable,
                    props: {
                      status: "Pending",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Pending approval from influencers, invited influencers able to view invitations from the app's close campaign, once accept invitation, influencer will be able to join the campaign."                        
                    },
                  },
                  {
                    tabId: "3",
                    label: "Rejected Invitations",
                    recordsCount: requestData?.rejectedRequestCount,
                    TabComponent: InfluencerRequestTable,
                    props: {
                      status: "Rejected",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Influencers rejected the invitation."
                    },
                  },
                  {
                    tabId: "4",
                    label: "Joined Influencers",
                    recordsCount: requestData?.approvedRequestCount,
                    TabComponent: InfluencerRequestTable,
                    props: {
                      status: "Approved",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Joined influencers able to access campaign, submit job and claim reward from app."
                    },
                  },
                ]}
              ></Tabs>
            </Panel>
      </>
    );
  };

  const RenderOfflineInvitationTable = () => {
    return (
      <>
          <Panel className="tabs">
              <Tabs
                activeTab={ "1" }
                tabs={[              
                  {
                    tabId: "1",
                    label: "Invitation",
                    TabComponent: SellerApprovalTable,
                    props: {
                      status: "Active",
                      onSellerClick: onInvitationClick,
                      passObject: true,
                      msg: "Invite influencers to join the private campaign, once invited, influencer will received notification."
                    },           
                  },
                  {
                    tabId: "2",
                    label: 'Invited Influencers',
                    recordsCount: requestData?.pendingRequestCount,
                    TabComponent: InfluencerOfflineRequestTable,
                    props: {
                      status: "Pending",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Pending approval from influencers, invited influencers able to view invitations from the app's close campaign, once accept invitation, influencer will be able to join the campaign."                        
                    },
                  },
                  {
                    tabId: "3",
                    label: "Rejected Invitations",
                    recordsCount: requestData?.rejectedRequestCount,
                    TabComponent: InfluencerOfflineRequestTable,
                    props: {
                      status: "Rejected",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Influencers rejected the invitation."
                    },
                  },
                  {
                    tabId: "4",
                    label: "Joined Influencers",
                    recordsCount: requestData?.approvedRequestCount,
                    TabComponent: InfluencerOfflineRequestTable,
                    props: {
                      status: "Approved",
                      onInfluencerClick: onInfluencerClick,
                      msg: "Joined influencers able to access campaign, submit job and claim reward from app."
                    },
                  },
                ]}
              ></Tabs>
            </Panel>
      </>
    );
  };

  const onInfluencerClick = (value) => {
    setSelectedSeller(value);
    setSellerDetailOpen(true);
  };

  const onInvitationClick = (value) => {  
    setSelectedSeller(value);
    setInvitation(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      {campaignLoading && (
        <div style={{width:800, height:500}} >
             <Loader/>
        </div>
      )}
      {!campaignLoading && campaignData && (
      <>
         
          <SixtyText type="h1" color="secondary" className={styles.name}>
                {campaignData?.name}
           </SixtyText>
        
          <BasicPageHeader title="Influencer Recruitment" onGoBack={onGoBack} />

          {["Standard", "WebsiteReview"].includes(campaignData?.campaignType) && 
            campaignData?.privacyStatus == 'Private' && (
            <>
             {RenderOnlineInvitationTable()}
            </>
          )}

          {["Offline"].includes(campaignData?.campaignType) && 
            campaignData?.privacyStatus == 'Private' && (
            <>
             {RenderOfflineInvitationTable()}
            </>
          )}

          {["Standard", "WebsiteReview"].includes(campaignData?.campaignType) && 
            campaignData?.privacyStatus == 'Public' && (
            <>
              {RenderOnlineTable()}
            </>
          )}
          {["Offline"].includes(campaignData?.campaignType) && 
            campaignData?.privacyStatus == 'Public' && (
            <>
              {RenderOflineTable()}
            </>
          )}
       </>
      )}

      {isSellerDetailOpen && (
        <Modal
          isOpen={isSellerDetailOpen}
          onAttemptClose={() => {
            setSellerDetailOpen(false);
          }}
          modalRef={sellerDetailRef}
          Title={SellerDetailTitle}
        >
          <InfluencerRequestDetails
            campaign={campaignData}
            selectedSeller={selectedSeller}
            handleClose={() => setSellerDetailOpen(false)}
          />
        </Modal>
      )}

      {isInvitation && (
        <Modal
          isOpen={isInvitation}
          onAttemptClose={() => {
            setInvitation(false);
          }}
          modalRef={invitationRef}
          Title={InvitationTitle}
        >
          <AdminCampaignInvitation
            campaign={campaignData}
            selectedSeller={selectedSeller}
            handleClose={() => setInvitation(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default AdminInfluencerJoinRequest;
