import React, { useState, useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useGetCampaignPriceTypeDropdown, 
         useGetCampaignStatusDropdown,
         useGetVendorProductDropdown,
         useGetSellerDropdown,
         useGetCampaignRewardRequirementDropdown,
         useGetSocialMediaDropdown,
         useGetAudiencePreferenceDropdown } from "queries/admin";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import ChipInput from "components/FormComponents/ControlledChipInput";
import {
  useGetDiscountLimitPerCustomerConditionDropdown,
  useGetDiscountOrderConditionDropdown,
  useGetDiscountUsageLimitConditionDropdown,
  useGetProductDropdown,
} from "queries/product";
import SixtySelect from "components/SixtySelect";
import Checkbox from "components/SixtyCheckBox";
import DatePicker from "components/SixtyDatePicker";
import { format, parse } from "date-fns";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";
import SixtyButton from "components/SixtyButton";
import { setToast } from "components/Toast";

const OverrideCommissionForm = () => {

  const { control, watch, errors } = useFormContext();

  return (
    <div className={styles.form}>

      <div style={{height:15}} ></div>
      <div className={styles.section}>Extra Reward</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
        <Input
          name="overrideCommission"
          error={errors.overrideCommission}
          control={control}
          label={"Override Commission"}
          type="number"
          min={0}
          step=".01"
          defaultValue={null}
        />
      </div>

    </div>
  );
};

export default OverrideCommissionForm;
