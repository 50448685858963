import React, { useEffect, useRef, useState } from "react";
import Tabs from "components/Tabs";
import Panel from "components/SixtyPanel";
import ProductTable from "./components/ProductsTable";
import GridHeader from "components/GridHeader";
import SixtyButton from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import {
  useExportProducts,
  useGetProducts,
  useImportProducts,
} from "queries/product";
import { setToast } from "components/Toast";

const Products = (props) => {
  const [activeCount, setActiveCount] = useState(0);
  const { data: activeData } = useGetProducts({ status: "Active" });
  useEffect(() => {
    if (activeData?.totalRecords) {
      setActiveCount(activeData.totalRecords);
    }
  }, [activeData]);

  const [draftCount, setDraftCount] = useState(0);
  const { data: draftData } = useGetProducts({ status: "Draft" });
  useEffect(() => {
    if (draftData?.totalRecords) {
      setDraftCount(draftData.totalRecords);
    }
  }, [draftData]);

  const [privateCount, setPrivateCount] = useState(0);
  const { data: privateData } = useGetProducts({ status: "Private" });
  useEffect(() => {
    if (privateData?.totalRecords) {
      setPrivateCount(privateData.totalRecords);
    }
  }, [privateData]);

  const [archivedCount, setArchivedCount] = useState(0);
  const { data: archivedData } = useGetProducts({ filterByAchieved: true });
  useEffect(() => {
    if (archivedData?.totalRecords) {
      setArchivedCount(archivedData.totalRecords);
    }
  }, [archivedData]);

  const history = useHistory();
  const {
    mutateAsync: exportProducts,
    isLoading: exporting,
  } = useExportProducts();
  const onExport = async () => {
    setToast("Exporting Products, it may take some time");
    if (!exporting) await exportProducts({});
  };

  const {
    mutateAsync: importProducts,
    isLoading: uploadingFiles,
  } = useImportProducts();
  const [files, setFiles] = useState([]);

  const fileInput = useRef();
  const onImport = () => {
    fileInput.current.click();
  };

  useEffect(() => {
    async function importFiles() {
      let formData = new FormData();
      formData.append(`name`, files[0].name);
      formData.append("file", files[0]);
      await importProducts(formData);
      setToast("successfully imported files");
      setFiles([]);
    }
    if (files.length > 0) {
      setToast("Importing Products, it may take some time");
      importFiles();
    }
  }, [files, importProducts]);

  return (
    <div>
      <GridHeader
        header="Products"
        renderButtons={() => (
          <>
            <SixtyButton
              onClick={() => history.push("/dashboard/create-product")}
            >
              Add new product
            </SixtyButton>
            <input
              type="file"
              hidden
              ref={fileInput}
              onChange={(e) => setFiles(e.target.files)}
              accept=".xlsx, .xls, .csv"
            />
            <SixtyButton
              variant="secondary"
              onClick={onImport}
              disabled={exporting || uploadingFiles}
            >
              Import
            </SixtyButton>
            <SixtyButton
              variant="secondary"
              onClick={onExport}
              disabled={exporting || uploadingFiles}
            >
              Export
            </SixtyButton>
          </>
        )}
      />
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Published",
              recordsCount: activeCount,
              TabComponent: ProductTable,
              props: {
                setCount: setActiveCount,
                status: "Published",
              },
            },
            {
              tabId: "2",
              label: "Draft",
              recordsCount: draftCount,
              TabComponent: ProductTable,
              props: {
                setCount: setDraftCount,
                status: "Draft",
              },
            },
            {
              tabId: "3",
              label: "Private",
              recordsCount: privateCount,
              TabComponent: ProductTable,
              props: {
                setCount: setPrivateCount,
                status: "Private",
              },
            },
            {
              tabId: "4",
              label: "Archived",
              recordsCount: archivedCount,
              TabComponent: ProductTable,
              props: {
                setCount: setArchivedCount,
                // status: "archived",
                filter: {
                  filterByAchieved: true,
                },
              },
            },
          ]}
        ></Tabs>
      </Panel>
    </div>
  );
};

export default Products;
