import Link from "components/SixtyLink";
import Button from "components/SixtyButton";
import Input from "components/SixtyInput";
import Text from "components/SixtyText";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import getParameterByName from "utils/getParamsByName";
import { ReactComponent as EyeIcon } from "assets/icons/passwordView.svg";
import { ReactComponent as EyeIconActive } from "assets/icons/passwordViewActive.svg";
import { useState } from "react";
import ProfileSetupLayout from "routes/layouts/ProfileSetupLayout";
import UserAuthenticationContainer from "./components/UserAuthenticationContainer";

export default function ResetPassword() {
  const schema = yup.object().shape({
    newPassword: yup.string().required("Enter a password"),
    confirmPassword: yup
      .string()
      .required("Please confirm password")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const handleShowClick = (e) => setShowPassword((show) => !show);
  const handleShowRepeatClick = (e) => setShowRepeatPassword((show) => !show);

  const prefillEmail = getParameterByName("email");
  const history = useHistory();
  const onReset = (data) => {
    // TODO -- Need to send data to reset api
  };
  return (
    <ProfileSetupLayout>
      <UserAuthenticationContainer
        header="Reset your password"
        subHeader={
          <>
            Choose a new password for{" "}
            <Text inline weight="medium">
              {prefillEmail || "your email"}
            </Text>
            . Make sure it’s strong!
          </>
        }
        form={
          <>
            <Controller
              as={Input}
              control={control}
              label="New Password"
              name="newPassword"
              error={errors.newPassword}
              defaultValue=""
              rules={{ required: "enter new password" }}
              type={showPassword ? "text" : "password"}
              endIcon={
                showPassword ? (
                  <EyeIconActive onClick={handleShowClick} />
                ) : (
                  <EyeIcon onClick={handleShowClick} />
                )
              }
            />
            <Controller
              as={Input}
              control={control}
              label="Confirm Password"
              name="confirmPassword"
              error={errors.confirmPassword}
              defaultValue=""
              rules={{ required: "confirm password" }}
              type={showRepeatPassword ? "text" : "password"}
              endIcon={
                showRepeatPassword ? (
                  <EyeIconActive onClick={handleShowRepeatClick} />
                ) : (
                  <EyeIcon onClick={handleShowRepeatClick} />
                )
              }
            />
          </>
        }
        footer={
          <Button
            type="submit"
            size="large"
            fullWidth
            onClick={handleSubmit(onReset)}
          >
            Reset
          </Button>
        }
        footerLink={
          <Link to="/auth/login" type="p1" color="orange">
            Remember your password? Try logging in
          </Link>
        }
      />
    </ProfileSetupLayout>
  );
}
