import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./index.module.css";
import LeftNavigationMenuItem from "./components/LeftNavigationMenuItem";

const Navigation = ({ items = [] }) => {
  const [activeItemId, setActiveItemId] = useState("");
  const history = useHistory();

  const handleClick = (itemId, path) => {
    setActiveItemId(itemId);
    history.push(path);
  };
  return (
    <div className={styles.sideNav}>
      {items.length > 0 && (
        <nav role="navigation" aria-label="side-navigation">
          {items.map((item) => {
            return (
              <div key={item.itemId}>
                <LeftNavigationMenuItem
                  activeItemId={activeItemId}
                  item={item}
                  onSelect={handleClick}
                />
              </div>
            );
          })}
        </nav>
      )}
    </div>
  );
};

export default Navigation;
