import React, { useState, useMemo, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetCampaignDetail, useUpdateCampaign, useDeleteCampaign, useGenerateCampaignPermalink } from "queries/admin";
import { useUploads } from "queries/media";
import { useExportCampaignReport } from "queries/campaign";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import CampaignEditInfo from "./components/CampaignEditInfo";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import SixtyText from "components/SixtyText";
import { formatCurrency } from "utils/common";
import { isEmpty, isArray } from 'lodash';

const CampaignDetail = ({ selectedCampaign, handleClose }) => {

const defaultValues = {
    id: '',
    name: '',
    campaignType: '',
    totalCommission: 0,
    instruction: '',
    price: 0,
    priceType: '',
    isPublic: '',
    expiredDate: '',
    status:'',
    applicantLimit:'',
    defaultCampaignProducts: [],
    campaignProducts: [],
    campaignSellers: []
 };
  const formMethods = useForm({
    defaultValues,
    shouldUnregister: false,
    mode: "onChange",
  });

  const { setValue } = formMethods;
  const history = useHistory();
  const { id } = selectedCampaign;

  const { data: campaignInfo, isLoading, isError, isFetchedAfterMount, refetch } = useGetCampaignDetail(
    id
  );

  const { mutateAsync: uploadFiles,
          isLoading: uploadingFiles,
        } = useUploads();

  const {
          mutateAsync: exportCampaignReport,
          isLoading: exporting,
        } = useExportCampaignReport();

  const [permalink, setPermalink] = useState(campaignInfo?.permalink);

  useEffect(() => {
    setPermalink(campaignInfo?.permalink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignInfo?.permalink]);

  const hasPermalink = useMemo(() => permalink?.length > 0, [permalink]);

  useEffect(() => {

   if (campaignInfo && isFetchedAfterMount) {
    let newDate = "";
    if (campaignInfo?.expiredDate) {
      let myDate = campaignInfo?.expiredDate.split("/");
      newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
    }

    let postingDate = "";
    if (campaignInfo?.postingExpiredOnUtc) {
      let myDate = campaignInfo?.postingExpiredOnUtc.split("/");
      postingDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
    }

    for (const [key, value] of Object.entries({
        id:campaignInfo?.id,
        name: campaignInfo?.name,
        socialMediaId: campaignInfo.socialMediaId,
        campaignType: campaignInfo?.campaignType,
        totalCommission: campaignInfo?.totalCommission,
        instruction: campaignInfo?.instruction,
        price: campaignInfo?.price,
        priceType: campaignInfo?.priceType,
        isPublic: campaignInfo?.isPublic,
        expiredDate: campaignInfo?.expiredDate && newDate,
        status: campaignInfo?.status,
        applicantLimit: campaignInfo?.applicantLimit,
        mainImages: campaignInfo?.defaultCampaignImagePath,
        picContact: campaignInfo?.picContact,
        redemptionType: campaignInfo?.redemptionType,
        redemptionReferenceUrl: campaignInfo?.redemptionReferenceUrl,
        deliverableType: campaignInfo?.deliverableType,
        deliverableReferenceUrl: campaignInfo?.deliverableReferenceUrl,
        shipmentType: campaignInfo?.shipmentType,
        appointmentType: campaignInfo?.appointmentType,
        appointmentLocation: campaignInfo?.appointmentLocation,
        isRequiredPurchaseProduct: campaignInfo?.isRequiredPurchaseProduct,
        productPurchasePrice: campaignInfo?.productPurchasePrice,
        shippingCoveredCost: campaignInfo?.shippingCoveredCost,
        purchaseLink: campaignInfo?.purchaseLink,
        isCoveredShippingCost: campaignInfo?.isCoveredShippingCost,
        isRequiredEcommerceReview: campaignInfo?.isRequiredEcommerceReview,
        isRequiredDraftReview: campaignInfo?.isRequiredDraftReview,
        isProfitSharing: campaignInfo?.isProfitSharing,
        postingExpiredOnUtc: campaignInfo?.postingExpiredOnUtc && postingDate,
        tasks: campaignInfo?.tasks,
        guidelines: campaignInfo?.guidelines,
        additionalRewards: campaignInfo?.additionalRewards,
        //campaignAudiences: campaignInfo?.campaignAudiences,
        campaignProducts: campaignInfo?.campaignProducts,
        campaignSellers: campaignInfo?.campaignSellers,
    })) {
      setValue(key, value);
    }
  }

  }, [setValue, campaignInfo, isFetchedAfterMount]);

  const { mutateAsync: updateCampaign, 
          isLoading : campaignLoading 
        } = useUpdateCampaign();

  const { mutateAsync: deleteCampaign, 
            isLoading : deleteCampaignLoading 
        } = useDeleteCampaign();

  const { mutateAsync: generateCampaignPermalink, 
          isLoading : generatePermalinkLoading 
        } = useGenerateCampaignPermalink();

   const onExport = async () => {
        setToast("Exporting Report, it may take some time");
        if (!exporting) await exportCampaignReport(id);
   };

  const onSubmit = async (data) => {
    try {

      var _defaultCampaignImagePath = null;
      if (isArray(data.mainImages) && data.mainImages?.length > 0) {
        const images = new FormData();
        let hasNewImages = false;
        if (data.isNewUpdate == 'true') {
          images.append(`files[0].fileName`, data.mainImages[0].name);
          images.append("files[0].file", data.mainImages[0]);
          hasNewImages = true;
        }
  
        const result = hasNewImages ? await uploadFiles(images) : [];
  
        const path = result?.length ? result[0].path : '';
        _defaultCampaignImagePath= path;
      }

        let rewards = [];
        if(data.additionalRewards){
          data.additionalRewards.map((field, index) => {
            rewards.push({
              id: data.additionalRewards[index].id,
              requirementType: data.additionalRewards[index].requirementType,
              requirementAmount: data.additionalRewards[index].requirementAmount ?
                                 parseFloat(data.additionalRewards[index].requirementAmount) :
                                 0,
              reward: data.additionalRewards[index].reward ?
                      parseFloat(data.additionalRewards[index].reward) :
                      0,
            });
          })
        }

        const model = {
            id: campaignInfo?.id,
            socialMediaId: data.socialMediaId,
            name: data.name,
            campaignType: data.campaignType,
            totalCommission: data.totalCommission ? parseFloat(data.totalCommission) : null,
            channelInvitationLink: data.channelInvitationLink,
            instruction: data.instruction,
            price: parseFloat(data.price),
            priceType: data.priceType,
            isPublic: data.isPublic,
            expiredDate: data.expiredDate && format(new Date(data.expiredDate), "dd/MM/yyyy"),
            status: data.status,
            applicantLimit: data.applicantLimit,
            defaultCampaignImagePath: _defaultCampaignImagePath,
            picContact: data.picContact,
            redemptionType: data.redemptionType,
            redemptionReferenceUrl: data.redemptionReferenceUrl,
            deliverableType: data.deliverableType,
            deliverableReferenceUrl: data.deliverableReferenceUrl,
            shipmentType: data.shipmentType,
            appointmentType: data.appointmentType,
            appointmentLocation: data.appointmentLocation,
            productPurchasePrice: data.productPurchasePrice ? parseFloat(data.productPurchasePrice) : null,
            shippingCoveredCost: data.shippingCoveredCost ? parseFloat(data.shippingCoveredCost) : null,
            purchaseLink: data.purchaseLink,
            isCoveredShippingCost: data.isCoveredShippingCost,
            isRequiredEcommerceReview: data.isRequiredEcommerceReview,
            isRequiredDraftReview: data.isRequiredDraftReview,
            isProfitSharing: data.isProfitSharing,
            postingExpiredOnUtc: data.postingExpiredOnUtc && format(new Date(data.postingExpiredOnUtc), "dd/MM/yyyy"),
            tasks: data.tasks,
            guidelines: data.guidelines,
            campaignAudiences: data.campaignAudiences,
            additionalRewards: rewards,
         };

         var result = await updateCampaign(model);

         if(result){
            refetch();
            setToast("successfully update campaign");
            handleClose();
         }
        
    } catch (e) {
      reportError(
        "error in : CampaignDetail: onSubmit, error: ",
        e
      );
    }
  };

  const onPublishSubmit = async (data) => {
    try {

      var _defaultCampaignImagePath = null;
      if (isArray(data.mainImages) && data.mainImages?.length > 0) {
        const images = new FormData();
        let hasNewImages = false;
        if (data.isNewUpdate == 'true') {
          images.append(`files[0].fileName`, data.mainImages[0].name);
          images.append("files[0].file", data.mainImages[0]);
          hasNewImages = true;
        }
  
        const result = hasNewImages ? await uploadFiles(images) : [];
  
        const path = result?.length ? result[0].path : '';
        _defaultCampaignImagePath= path;
      }
      
      let rewards = [];
      if(data.additionalRewards){
        data.additionalRewards.map((field, index) => {
          rewards.push({
            id: data.additionalRewards[index].id,
            requirementType: data.additionalRewards[index].requirementType,
            requirementAmount: data.additionalRewards[index].requirementAmount ?
                               parseFloat(data.additionalRewards[index].requirementAmount) :
                               0,
            reward: data.additionalRewards[index].reward ?
                    parseFloat(data.additionalRewards[index].reward) :
                    0,
          });
        })
      }

        const model = {
            id: campaignInfo?.id,
            name: data.name,
            socialMediaId: data.socialMediaId,
            campaignType: data.campaignType,
            totalCommission: data.totalCommission ? parseFloat(data.totalCommission) : null,
            instruction: data.instruction,
            price: parseFloat(data.price),
            priceType: data.priceType,
            isPublic: data.isPublic,
            isPublished: true,
            expiredDate: data.expiredDate && format(new Date(data.expiredDate), "dd/MM/yyyy"),
            status: data.status,
            applicantLimit: data.applicantLimit,
            defaultCampaignImagePath: _defaultCampaignImagePath,
            picContact: data.picContact,
            redemptionType: data.redemptionType,
            redemptionReferenceUrl: data.redemptionReferenceUrl,
            deliverableType: data.deliverableType,
            deliverableReferenceUrl: data.deliverableReferenceUrl,
            shipmentType: data.shipmentType,
            appointmentType: data.appointmentType,
            appointmentLocation: data.appointmentLocation,
            productPurchasePrice: data.productPurchasePrice ? parseFloat(data.productPurchasePrice) : null,
            shippingCoveredCost: data.shippingCoveredCost ? parseFloat(data.shippingCoveredCost) : null,
            purchaseLink: data.purchaseLink,
            isCoveredShippingCost: data.isCoveredShippingCost,
            isRequiredEcommerceReview: data.isRequiredEcommerceReview,
            isRequiredDraftReview: data.isRequiredDraftReview,
            isProfitSharing: data.isProfitSharing,
            postingExpiredOnUtc: data.postingExpiredOnUtc && format(new Date(data.postingExpiredOnUtc), "dd/MM/yyyy"),
            tasks: data.tasks,
            guidelines: data.guidelines,
            campaignAudiences: data.campaignAudiences,
            additionalRewards: rewards,
         };
         
         var result = await updateCampaign(model);

         if(result){
            refetch();
            setToast("successfully publish campaign");
            handleClose();
         }
        
    } catch (e) {
 
      reportError(
        "error in : CampaignDetail: onPublishSubmit, error: ",
        e
      );
    }
  };

  const onDeleteCampaign = async () => {
    try {
     
         var result = await deleteCampaign(campaignInfo?.id);

         if(result){
            setToast("successfully delete campaign");
            handleClose();
         }
        
    } catch (e) {
      reportError(
        "error in : CampaignDetail: onDeleteCampaign, error: ",
        e
      );
    }
  };

  const onGenerateCampaignPermalink = async () => {
    try {
     
         var result = await generateCampaignPermalink(campaignInfo?.id);

         if(result){
            setToast("successfully generate permalink");
            setPermalink(result?.url);
         }
        
    } catch (e) {
      reportError(
        "error in : CampaignDetail: onGenerateCampaignPermalink, error: ",
        e
      );
    }
  };

  const onBrowseProfile = async (id) => {
    try {
     
      window.open(`/admin/influencers/AnalyticDetails/${id}`, "_blank")
        
    } catch (e) {
      reportError(
        "error in : CampaignDetail: onBrowseProfile, error: ",
        e
      );
    }
  };

  const onBrowseCampaignPayoutRequest = () => {    
    const path = `/admin/PayoutRequest/${campaignInfo?.id}`;
    window.open(path, "_blank")
  };

  const onBrowseCampaignAnalytic = () => {    
    const path = `/admin/campaigns/AnalyticDetails/${campaignInfo?.id}`;
    window.open(path, "_blank")
  };

  const onBrowseInfluencerRequest = () => {    
    const path = `/admin/campaigns/InfluencerRequests/${campaignInfo?.id}`;
    window.open(path, "_blank")
  };

  const onStepToComplete = () => {    
    const path = `/admin/campaigns/stepToComplete/${campaignInfo?.id}`;
    window.open(path, "_blank")
  };

  const onBrowseCampaignProducts = () => {    
    const path = `/admin/campaign/products/${campaignInfo?.id}`;
    window.open(path, "_blank")
  };

  const onBrowseCampaignPosts = () => {    
    const path = `/admin/campaigns/contentPublishing/${campaignInfo?.id}`;
    window.open(path, "_blank")
  };

  const copyToClipboard = () => {
      const textField = document.createElement("textarea");
      let innerText = ``;
      if (permalink)
      innerText = `${permalink}`;
 
      textField.innerText = innerText;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      if (innerText !== "") setToast("Permalink copied to clipboard");
      else setToast("No content to copy", "error");
  };

  return (
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Campaign Info"
            />
            <div className={styles.btnActionContainer}>
              <SixtyButton
                 variant="tertiary"
                 onClick={() => onBrowseCampaignAnalytic()}
                 className={styles.approveBtn}
               >
                 {"Browse analytics"}
              </SixtyButton>
              <SixtyButton
                 variant="tertiary"
                 onClick={() => onStepToComplete()}
                 className={styles.approveBtn}
               >
                 {"Manage Campaign Progress"}
              </SixtyButton>
              <SixtyButton
                 variant="primary"
                 onClick={() => onExport()}
                 className={styles.approveBtn}
                 disabled={exporting}
               >
                 {"Export Report"}
              </SixtyButton>

            </div>
            <SixtyText className={styles.message}>
                  {'Update campaign progress status as close when the campaign is ended.'}
             </SixtyText>
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Status
                  </div>
                  <div>
                    {campaignInfo?.isPublished ? "Published" : "Draft"}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Visibility</div>
                  <div>
                    {campaignInfo?.isPublic ? "Public" : "Private"}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Generated Traffics</div>
                  <div>
                    {campaignInfo?.totalTraffic}
                  </div>
                </div>
              </div>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Progress Status
                  </div>
                  <div>
                    {campaignInfo?.status}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Campaign Conversion"
            />
            <div className={styles.btnActionContainer}>
              {!hasPermalink && (
                <SixtyButton
                   variant="secondary"
                   onClick={() => onGenerateCampaignPermalink()}
                   className={styles.approveBtn}
                   disabled={generatePermalinkLoading}
                 >
                   {campaignInfo?.isPublic ? "Generate Permalink" : "Generate Invitation Link"}
                </SixtyButton>
              )}

              {hasPermalink && (
                <SixtyButton
                    variant="secondary"
                    onClick={() => copyToClipboard()}
                    className={styles.approveBtn}
                >
                  {"Copy"}
                 </SixtyButton>
              )}
            </div>
            <div className={styles.campaignInfoContainer}>
             <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>{campaignInfo?.isPublic ? 'Permalink' : 'Invitation Link'}</div>
                  <div>
                    {hasPermalink ? permalink : 'Pending'}
                  </div>
                </div>
              </div>
            </div>
          </Panel>


          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Vendor Details"
            />
            <div className={styles.campaignInfoContainer}>

            <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>Code</div>
                  <div>
                    {campaignInfo?.vendorCode}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Name
                  </div>
                  <div>
                    {campaignInfo?.vendorName}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Email</div>
                  <div>
                    {campaignInfo?.vendorEmail}
                  </div>
                </div>
              </div>

              <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>Plan</div>
                  <div>
                    {campaignInfo?.plan}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Expired date</div>
                  <div>
                    {campaignInfo?.membershipExpiredDate}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Campaign Payment"
            />
              <SixtyText className={styles.message}>
                  {'Track vendor payment activities.'}
             </SixtyText>
            <div className={styles.campaignInfoContainer}>
            <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>{'Paid Amount'}</div>
                  <div>
                    {'Coming Soon'}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                  {'Latest Payment Date'}
                  </div>
                  <div>
                    {'-'}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Payment Records</div>
                  <div>
                    {'-'}
                  </div>
                </div>
              </div>
            </div>
          </Panel>


          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Influencers"
            />
            <div className={styles.btnActionContainer}>
                  <SixtyButton
                    variant="secondary"
                    onClick={() => onBrowseInfluencerRequest()}
                    className={styles.approveBtn}
                    >
                    {"Manage Influencers"}
                  </SixtyButton>
            </div>
            <div className={styles.campaignInfoContainer}>
            <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>{campaignInfo?.isPublic ? 'Pending Approval Influencers' : 'Invited Influencers'}</div>
                  <div>
                    {campaignInfo?.pendingSellerCount}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                  {campaignInfo?.isPublic ? 'Rejected Influencers' : 'Rejected Invitions'}
                  </div>
                  <div>
                    {campaignInfo?.rejectedSellerCount}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Joined Influencers</div>
                  <div>
                    {campaignInfo?.joinedSellerCount}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Products"
            />
            <div className={styles.btnActionContainer}>
                  <SixtyButton
                    variant="secondary"
                    onClick={() => onBrowseCampaignProducts()}
                    className={styles.approveBtn}
                    >
                    {"Manage Products"}
                  </SixtyButton>
            </div>
            <div className={styles.campaignInfoContainer}>
            <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>{'Vendor Available Products'}</div>
                  <div>
                    {campaignInfo?.vendorProductCount}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                  {'Campaign Products'}
                  </div>
                  <div>
                    {campaignInfo?.campaignProductCount}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Redirect Traffic Products</div>
                  <div>
                    {campaignInfo?.productRedirectCount}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Post Contents"
            />
            <div className={styles.btnActionContainer}>
                  <SixtyButton
                    variant="secondary"
                    onClick={() => onBrowseCampaignPosts()}
                    className={styles.approveBtn}
                    >
                    {"Manage Uploaded Posts"}
                  </SixtyButton>
                  {campaignInfo && campaignInfo.priceType == 'Fixed' && (
                   <SixtyText className={styles.message}>
                     {'This campaign is offer fixed reward, before close the campaign, make sure all postings status mark as completed so influencers able to claim reward from the app.'}
                   </SixtyText>
                  )}
            </div>
            <div className={styles.campaignInfoContainer}>
             <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>{'Pending Approval Drafts'}</div>
                  <div>
                    {campaignInfo?.pendingPostCount}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                  {'Approved Posts'}
                  </div>
                  <div>
                    {campaignInfo?.approvedPostCount}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>{'Rejected Posts'}</div>
                  <div>
                    {campaignInfo?.rejectedPostCount}
                  </div>
                </div>
              </div>
              <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>{'Completed Posts'}</div>
                  <div>
                    {campaignInfo?.completedPostCount}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Orders"
            />
              <SixtyText className={styles.message}>
                  {'Track sales activities.'}
             </SixtyText>
            <div className={styles.campaignInfoContainer}>
            <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>{'Total Orders'}</div>
                  <div>
                    {campaignInfo?.campaignSales?.totalOrders}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                  {'Commission Costs'}
                  </div>
                  <div>
                    {formatCurrency(campaignInfo?.campaignSales?.commissionCost)}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Refund Amounts</div>
                  <div>
                    {formatCurrency(campaignInfo?.campaignSales?.refundAmount)}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          {campaignInfo && campaignInfo.priceType == 'Fixed' && (
              <Panel className={styles.panelWrap}>
              <PanelHeader
                className={styles.campaignInfoHeader}
                title="Payouts"
              />
              <div className={styles.btnActionContainer}>
                    <SixtyButton
                      variant="primary"
                      onClick={() => onBrowseCampaignPayoutRequest()}
                      className={styles.approveBtn}
                    >
                      {"Check Payout Requests"}
                  </SixtyButton>
              </div>
              <SixtyText className={styles.message}>
                  {'Proceed influencers credit rewards.'}
             </SixtyText>
              <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                  <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                    <div className={styles.campaignInfoHeader}>{'Claimable Posts'}</div>
                    <div>
                      {campaignInfo?.totalPostCount}
                    </div>
                  </div>
                  <div className={styles.campaignInfoItem}>
                    <div className={styles.campaignInfoHeader}>
                    {'Pending Payouts'}
                    </div>
                    <div>
                      {campaignInfo?.pendingRewardRequest}
                    </div>
                  </div>
                  <div className={styles.campaignInfoItem}>
                    <div className={styles.campaignInfoHeader}>{'Completed Payouts'}</div>
                    <div>
                      {campaignInfo?.campaignPayout?.completedPayout}
                    </div>
                  </div>
                </div>
                <div className={styles.campaignInfoRow}>
                  <div className={styles.campaignInfoItem}>
                    <div className={styles.campaignInfoHeader}>
                    {'Paid Off Amounts'}
                    </div>
                    <div>
                      {formatCurrency(campaignInfo?.campaignPayout?.paidOffAmount)}
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
          )}
     
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Edit Vendor Campaign"
            />
          <FormProvider {...formMethods}>
            <form
                className={styles.form}
            >
            {campaignInfo && (
             <CampaignEditInfo selectedCampaign={campaignInfo} />
            )}         
            </form>
        </FormProvider>
         </Panel>

        </div>
      </div>

        <footer className={styles.footer} hidden={isLoading}>
          <SixtyButton
            type="submit"
            onClick={formMethods.handleSubmit(onSubmit)}
            className={styles.approveBtn}
            disabled={campaignLoading || deleteCampaignLoading}
          >
            {campaignInfo && campaignInfo.isPublished ? "Update" : "Update Draft"}
          </SixtyButton>

          {campaignInfo && !campaignInfo.isPublished && (            
            <SixtyButton
               type="submit"
               variant="tertiary"
               onClick={formMethods.handleSubmit(onPublishSubmit)}
               className={styles.approveBtn}
               disabled={campaignLoading || deleteCampaignLoading}
             >
               {"Update & Publish"}
             </SixtyButton>
          )}

          {campaignInfo && !campaignInfo.isPublished && (            
           <SixtyButton
                type="submit"
                variant="secondary"
                onClick={onDeleteCampaign}
                className={styles.approveBtn}
                disabled={campaignLoading || deleteCampaignLoading}
                >
                {"Delete"}
           </SixtyButton>
          )}

          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={campaignLoading || deleteCampaignLoading}
          >
            Close
          </SixtyButton>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default CampaignDetail;
