import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetVendorDetail, useSuspendOrActivateVendor, 
         useAddToAffiliateProgram, useRemoveFromAffiliateProgram,
         useGenerateAffiliateProgramPermalink, useApproveAccess } from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

const VendorDetails = ({ selectedVendor, handleClose }) => {
  const { id } = selectedVendor;

  const { data: venderInfo, isLoading, isError, refetch } = useGetVendorDetail(
    id
  );

  const [permalink, setPermalink] = useState(venderInfo?.permalink);

  useEffect(() => {
    setPermalink(venderInfo?.permalink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venderInfo?.permalink]);

  const hasPermalink = useMemo(() => permalink?.length > 0, [permalink]);

  const { mutateAsync: generateAffiliateProgramPermalink, 
          isLoading : generatePermalinkLoading 
        } = useGenerateAffiliateProgramPermalink();

  const { mutateAsync: suspendOrActivateVendor, 
          isLoading : suspendLoading 
        } = useSuspendOrActivateVendor();

  const onSuspendOrActivateVendor = async () => {
    try {
      const result = await suspendOrActivateVendor(id);
      if (result) {
        handleClose();
        setToast("successfully updated vendor");
      }
    } catch (e) {
      reportError(
        "error in : VendorDetails: onSuspendOrActivateVendor, error: ",
        e
      );
    }
  };

  const { mutateAsync: addVendorToAffiliateProgram, 
    isLoading : addLoading 
  } = useAddToAffiliateProgram();

  const onAddVendorToAffiliateProgram = async () => {
      try {
      const result = await addVendorToAffiliateProgram(id);
      if (result) {
        handleClose();
        setToast("successfully updated vendor");
      }
      } catch (e) {
      reportError(
        "error in : VendorDetails: onAddVendorToAffiliateProgram, error: ",
        e
      );
  }
  };

  const { mutateAsync: approveVendorAccess, 
          isLoading : approveLoading 
  } = useApproveAccess();

  const onApproveVendorAccess = async () => {
      try {
      const result = await approveVendorAccess(id);
      if (result) {
        handleClose();
        setToast("successfully updated vendor");
      }
      } catch (e) {
      reportError(
        "error in : VendorDetails: onApproveVendorAccess, error: ",
        e
      );
  }
  };

  const { mutateAsync: removeVendorFromAffiliateProgram, 
    isLoading : removeLoading 
  } = useRemoveFromAffiliateProgram();

  const onRemoveVendorFromAffiliateProgram = async () => {
      try {
      const result = await removeVendorFromAffiliateProgram(id);
      if (result) {
        handleClose();
        setToast("successfully updated vendor");
      }
      } catch (e) {
      reportError(
        "error in : VendorDetails: onRemoveVendorFromAffiliateProgram, error: ",
        e
      );
  }
  };

  const onGeneratePermalink = async () => {
    try {
     
         var result = await generateAffiliateProgramPermalink(id);

         if(result){
            setToast("successfully generate permalink");
            setPermalink(result?.url);
         }
        
    } catch (e) {
      reportError(
        "error in : VendorDetails: onGeneratePermalink, error: ",
        e
      );
    }
  };

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    let innerText = ``;
    if (permalink)
    innerText = `${permalink}`;

    textField.innerText = innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (innerText !== "") setToast("Permalink copied to clipboard");
    else setToast("No content to copy", "error");
};

  const requestedDate = getFormattedDateString(
    selectedVendor?.submitDate,
    "MMM d, y"
  );

  return (
    <React.Fragment>
      <div className={styles.vendorFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.vendorRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.vendorInfoHeader}
              title="Total Sales performance"
            />
            <div className={styles.vendorInfoContainer}>
              <div className={styles.vendorInfoRow}>
                <div className={clsx(styles.vendorInfoItem, styles.elipsis)}>
                  <div className={styles.vendorInfoHeader}>Total Sales</div>
                  <div
                    className={styles.elipsis}
                    title={selectedVendor?.totalSales}
                  >
                    {venderInfo?.totalSales}
                  </div>
                </div>
                <div className={styles.vendorInfoItem}>
                  <div className={styles.vendorInfoHeader}>Total Order</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.totalOrder}
                  >
                    {venderInfo?.totalOrder}
                  </div>
                </div>
                <div className={styles.vendorInfoItem}>
                  <div className={styles.vendorInfoHeader}>Total Sellers</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.totalSellers}
                  >
                    {venderInfo?.totalSellers}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.vendorInfoHeader}
              title="Affiliate Program Conversion"
            />
            <div className={styles.btnActionContainer}>
              {!hasPermalink && (
                <SixtyButton
                   variant="secondary"
                   onClick={() => onGeneratePermalink()}
                   className={styles.approveBtn}
                   disabled={generatePermalinkLoading}
                 >
                   {"Generate Permalink"}
                </SixtyButton>
              )}

              {hasPermalink && (
                <SixtyButton
                    variant="secondary"
                    onClick={() => copyToClipboard()}
                    className={styles.approveBtn}
                >
                  {"Copy"}
                 </SixtyButton>
              )}
            </div>
            <div className={styles.vendorInfoContainer}>
             <div className={styles.vendorInfoRow}>
                <div className={clsx(styles.vendorInfoItem, styles.elipsis)}>
                  <div className={styles.vendorInfoHeader}>{'Permalink'}</div>
                  <div>
                    {hasPermalink ? permalink : 'Pending'}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.vendorInfoHeader}
              title="Store details"
            />
            <div className={styles.vendorInfoContainer}>
              <div className={styles.vendorInfoRow}>
                <div className={clsx(styles.vendorInfoItem)}>
                  <div className={styles.vendorInfoHeader}>Store name</div>
                  <div>
                    {venderInfo?.storeDetails?.storeName}
                  </div>
                </div>
                <div className={styles.vendorInfoItem}>
                  <div className={styles.vendorInfoHeader}>
                    Customer support email
                  </div>
                  <div
                    className={clsx(styles.email, styles.elipsis)}
                    title={venderInfo?.storeDetails?.customerSupportEmail}
                  >
                    {venderInfo?.storeDetails?.customerSupportEmail}
                  </div>
                </div>
                <div className={styles.vendorInfoItem}>
                  <div className={styles.vendorInfoHeader}>Mobile number</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.storeDetails?.contactNumber}
                  >
                    {venderInfo?.storeDetails?.contactNumber}
                  </div>
                </div>
              </div>

              <div className={styles.vendorInfoRow}>
                <div className={styles.vendorInfoItem}>
                  <div className={styles.vendorInfoHeader}>Industry</div>
                  <div>{venderInfo?.storeDetails?.industry}</div>
                </div>
                <div className={clsx(styles.address,styles.vendorInfoItem)}>
                  <div className={styles.vendorInfoHeader}>
                    Business address
                  </div>
                  <div>{venderInfo?.storeDetails?.businessAddress}</div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.vendorInfoHeader}
              title="Vendor details"
            />
            <div className={styles.vendorInfoContainer}>
              <div className={styles.vendorInfoRow}>
                <div className={clsx(styles.vendorInfoItem, styles.elipsis)}>
                  <div className={styles.vendorInfoHeader}>Username</div>
                  <div
                    className={clsx(styles.email, styles.elipsis)}
                    title={venderInfo?.vendorDetails?.userName}
                  >
                    {venderInfo?.vendorDetails?.userName}
                  </div>
                </div>
                <div className={styles.vendorInfoItem}>
                  <div className={styles.vendorInfoHeader}>
                    Business email address
                  </div>
                  <div
                    className={clsx(styles.email, styles.elipsis)}
                    title={venderInfo?.vendorDetails?.businessEmailAddress}
                  >
                    {venderInfo?.vendorDetails?.businessEmailAddress}
                  </div>
                </div>
                <div className={styles.vendorInfoItem}>
                  <div className={styles.vendorInfoHeader}>Last login</div>
                  <div>{venderInfo?.vendorDetails?.lastLogin}</div>
                </div>
              </div>

              <div className={styles.vendorInfoRow}>
                <div className={styles.vendorInfoItem}>
                  <div className={styles.vendorInfoHeader}>Date of birth</div>
                  <div>{venderInfo?.vendorDetails?.dateOfBirth}</div>
                </div>
                <div className={styles.vendorInfoItem}>
                  <div className={styles.vendorInfoHeader}>Gender</div>
                  <div>{venderInfo?.vendorDetails?.gender}</div>
                </div>

              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.vendorInfoHeader}
              title="Subscription plan"
            />
            <div className={styles.vendorInfoContainer}>
              <div className={styles.vendorInfoRow}>
                <div className={clsx(styles.vendorInfoItem, styles.elipsis)}>
                  <div className={styles.vendorInfoHeader}>Plan</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.subscriptionPlan?.plan}
                  >
                    {venderInfo?.subscriptionPlan?.plan}
                  </div>
                </div>
                <div className={styles.vendorInfoItem}>
                  <div className={styles.vendorInfoHeader}>
                    Subscription plan status
                  </div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.subscriptionPlan?.planStatus}
                  >
                    {venderInfo?.subscriptionPlan?.planStatus}
                  </div>
                </div>
                <div className={styles.vendorInfoItem}>
                  <div className={styles.vendorInfoHeader}>Expired date</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.subscriptionPlan?.expiredDate}
                  >
                    {venderInfo?.subscriptionPlan?.expiredDate}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </div>
        </div>

        <footer className={styles.footer} hidden={isLoading}>
          <SixtyButton
            type="submit"
            onClick={onSuspendOrActivateVendor}
            className={styles.approveBtn}
            disabled={suspendLoading || addLoading || removeLoading || approveLoading}
          >
            {venderInfo?.status === "Active" ? "Suspend" : "Activate"}
          </SixtyButton>

          {!venderInfo?.isJoinedAffiliateProgram &&(
           <SixtyButton
              variant="tertiary"
              className={styles.approveBtn}
              onClick={onAddVendorToAffiliateProgram}
              disabled={suspendLoading || addLoading || removeLoading || approveLoading}
            >
              Add to Affiliate Program
            </SixtyButton>
          )}

          {venderInfo?.isJoinedAffiliateProgram &&(
           <SixtyButton
              variant="warning"
              className={styles.approveBtn}
              onClick={onRemoveVendorFromAffiliateProgram}
              disabled={suspendLoading || addLoading || removeLoading || approveLoading}
            >
              Removed from Affiliate Program
            </SixtyButton>
          )}

          {!venderInfo?.isApprovedAccess &&(
           <SixtyButton
              variant="tertiary"
              className={styles.approveBtn}
              onClick={onApproveVendorAccess}
              disabled={suspendLoading || addLoading || removeLoading || approveLoading}
            >
              Approve Access
            </SixtyButton>
          )}

           <SixtyButton
              variant="warning"
              className={styles.cancelButton}
              onClick={handleClose}
              disabled={suspendLoading || addLoading || removeLoading || approveLoading}
            >
              Close
            </SixtyButton>

        </footer>
      </div>
    </React.Fragment>
  );
};

export default VendorDetails;
