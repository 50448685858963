import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import CampaignAnalyticTable from "../components/CampaignAnalyticTable";
import CreateCampaign from "../../CampaignSubProducts/Components/UgcCampaign/Components/CreateCampaign";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import Tabs from "components/Tabs";
import { useGetCampaignStatusCount } from "queries/campaign";
import { useExportCampaignInfluencers } from "queries/admin";
import { setToast } from "components/Toast";
import SixtyButton from "components/SixtyButton";

const CampaignAnalytic = () => {

  // const [isCampaignDetailOpen, setCampaignDetailOpen] = useState(false);
  // const history = useHistory();
  // const campaignDetailRef = useRef();
  // const [selectedCampaign, setSelectedCampagin] = useState({});

  // const {
  //   data: campaignData,
  // } = useGetCampaignStatusCount();

  const newCampaignRef = useRef();
  const role = localStorage.getItem("userRole");
  const [isNewCampaign, setNewCampaign] = useState(false);

  const NewCampaignTitle = () => {
    return (
      <div className={styles.modalTitleBar}>
        <div className={styles.modalContainer}>
          <div className={styles.modalTitle}>{'New Campaign'}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setNewCampaign(false)}
          />
        </div>
      </div>
    );
  };

  const onCampaignClick = (value) => {
    
    if(value.campaignType === "Ugc"){
        const path = `/dashboard/services/ugc/${value?.id}`;
        window.open(path, "_blank")
    }
    else{
        if (role === "InternalUser") {
          const path = `/admin/campaigns/AnalyticDetails/${value?.id}`;
          window.open(path, "_blank")
        } else {
          const path = `/dashboard/campaigns/stepToComplete/${value?.id}`;
          window.open(path, "_blank")
        }
    }
  };

  const {
    mutateAsync: exportInfluencers,
    isLoading: exporting,
  } = useExportCampaignInfluencers();

  const onExport = async () => {
    setToast("Exporting Records, it may take some time");
    if (!exporting) await exportInfluencers();
  };

  const onOpenNewCampaign = () => {
     setNewCampaign(true);
  };

  const onCloseNewCampaign = () => {
     setNewCampaign(false);
  };


  return (
    <div>
      <GridHeader header="All Orders" 
         renderButtons={() => (
          <>
            {role === "InternalUser" && (
              <SixtyButton
                  variant="secondary"
                  onClick={onExport}
                  disabled={exporting}
              >
                 Export
              </SixtyButton>
            )}
          </>
         )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.campaignTable}>
          <CampaignAnalyticTable onCampaignClick={onCampaignClick} />
        </div>
      </Panel>
      {isNewCampaign && (
          <Modal
              isOpen={isNewCampaign}
              onAttemptClose={() => {
                  setNewCampaign(false);
               }}
              modalRef={newCampaignRef}
              Title={NewCampaignTitle}
          >
            <CreateCampaign
               handleClose={onCloseNewCampaign}
            />
          </Modal>
        )}
    </div>
  );
};

export default CampaignAnalytic;
