import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import PayoutTable from "./components/PayoutTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import PayoutDetails from "./components/PayoutDetails";
import Tabs from "components/Tabs";
import { useGetPayoutStatusCount, useExportPayoutHistories } from "queries/admin";
import { setToast } from "components/Toast";

const Payout = () => {

  const [isPayoutDetailOpen, setPayoutDetailOpen] = useState(false);
  const history = useHistory();
  const payoutDetailRef = useRef();
  const [selectedPayout, setSelectedPayout] = useState({});

  const {
    mutateAsync: exportPayout,
    isLoading: exporting,
  } = useExportPayoutHistories();

  const {
    data: payoutData,
  } = useGetPayoutStatusCount();

  const InfluencerDetailTitle = () => {
    return (
      <div className={styles.payoutDetailModalTitleBar}>
        <div className={styles.payoutNameContainer}>
          <div>
            <Avatar name={selectedPayout.sellerName} />
          </div>
          <div className={styles.modalTitle}>{selectedPayout.sellerName}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPayoutDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onExport = async () => {
    setToast("Exporting Report, it may take some time");
    if (!exporting) await exportPayout();
  };

  const onPayoutClick = (value) => {
    setSelectedPayout(value);
    setPayoutDetailOpen(true);
  };

  return (
    <div>
      <GridHeader header="Influencer Payouts" 
         renderButtons={() => (
          <>
             <Button onClick={() => history.push("/admin/payouts/influencer-selection")}>Add New Payout</Button>
             <Button onClick={() => onExport()} disabled={exporting}>Export Transactions</Button>
          </>
         )}
      />
      {/* <Panel className={styles.panelWrap}>
        <div className={styles.payoutTable}>
          <PayoutTable onPayoutClick={onPayoutClick} />
        </div>
      </Panel> */}
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Completed",
              recordsCount: payoutData?.completedPayoutCount,
              TabComponent: PayoutTable,
              props: {
                status: "Completed",
                onPayoutClick: onPayoutClick
              },
            },
            {
              tabId: "2",
              label: "Voided",
              recordsCount: payoutData?.voidedPayoutCount,
              TabComponent: PayoutTable,
              props: {
                status: "Voided",
                onPayoutClick: onPayoutClick
              },
            },
          ]}
        ></Tabs>
      </Panel>
      {isPayoutDetailOpen && (
        <Modal
          isOpen={isPayoutDetailOpen}
          onAttemptClose={() => {
            history.push("/admin/payouts");
            setPayoutDetailOpen(false);
          }}
          modalRef={payoutDetailRef}
          Title={InfluencerDetailTitle}
        >
          <PayoutDetails
            selectedPayout={selectedPayout}
            handleClose={() => setPayoutDetailOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Payout;
