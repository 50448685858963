import React, { useState, useRef } from "react";
import styles from "./index.module.css";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "utils/common";
import clsx from "clsx";
import Button from "components/SixtyButton";
import RefundDetails from "../RefundDetails";
import Modal from "components/Modal";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import Avatar from "components/Avatar";

const RefundList = ({ refunds, refetchData }) => {

  const [isViewDetails, setDetailModalOpen] = useState(false);
  const [selectedRefund, setSelectedRefund] = useState(null);
  const viewDetailModalRef = useRef();

  const Title = () => {
    return (
      <>
        <div className={styles.refundModalTitleBar}>
            <div className={styles.refundNameContainer}>
              <div>
                <Avatar name={'Refund Proposal'} />
              </div>
              <div className={styles.modalTitle}>{'Refund Proposal'}</div>
          </div>
           <div>
                <CloseIcon
                  className={styles.closeIcon}
                  onClick={() => setDetailModalOpen(false)}
                />
            </div>
        </div>

      </>
    )
  };

  const viewRefundDetails = (data) => {
    setSelectedRefund(data);
    setDetailModalOpen(true);
  };

  const handleRefundDetailClose = () => {
    setDetailModalOpen(false);
    refetchData();
  };

  return (
    <>
      <React.Fragment>
        {refunds?.length > 0 && (
          <ul>
            {refunds.map((refund) => (
              <li key={refund.id}>
                <RefundItem
                  item={refund}
                  onRefundClick={viewRefundDetails}
                />
              </li>
            ))}
          </ul>
        )}
      </React.Fragment>
      {isViewDetails && (
         <Modal
            isOpen={isViewDetails}
            onAttemptClose={() => {
               setDetailModalOpen(false);
            }}
                modalRef={viewDetailModalRef}
                Title={Title}
          >
          <RefundDetails
              selectedRefund={selectedRefund}
              handleClose={handleRefundDetailClose}
          />
         </Modal>
      )}
    </>
  );
};

export const RefundItem = ({ item, onRefundClick }) => {
  const {
    id,
    productId,
    itemName,
    itemPath,
    amount,
    refundReason,
    status,
    requestDate,
    images
  } = item;
  const history = useHistory();

  return (
    productId && (
      <div className={styles.refundItemContainer}>
        <div
          className={clsx(
            styles.productNameCell,
            styles.fullWidth
          )}
          onClick={() => 
            onRefundClick(item)
          }
        >
          {itemPath && (
            <div className={styles.productImg}>
              {" "}
              <img
                className={styles.productImage}
                src={itemPath}
                alt="product"
              />{" "}
            </div>
          )}
          <div className={styles.productNameContainer}>
            <div
              title={itemName}
              className={[styles.productName, styles.elipsis].join(" ")}
            >
              {itemName}
            </div>
            <div className={styles.brandName}>{itemName || ""}</div>
          </div>
        </div>
        {amount && (
          <div
            className={clsx(
              styles.variationOption,
              styles.productCell,
              styles.elipsis
            )}
          >
            {'RM ' + amount}
          </div>
        )}
        <div className={[styles.priceQuantity, styles.productCell].join(" ")}>
          {status}
        </div>
        <div className={[styles.productCell].join(" ")}>
         <Button onClick={() => onRefundClick(item)}>
           {'View'}
         </Button>
        </div>
      </div>
    )
  );
};

export default RefundList;
