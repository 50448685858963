import React, { useState, useRef, useEffect, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAddDiscount } from "queries/discount";

import Modal from "components/Modal";
import Panel from "components/SixtyPanel";
import BasicInfo from "../components/BasicInfo";
import PanelHeader from "components/PanelHeader";
import SixtyButton from "components/SixtyButton";
import DiscountDetails from "../components/DiscountDetails";
import DiscountDates from "../components/DiscountDates";
import DiscountSettings from "../components/DiscountSettings";

import styles from "./index.module.css";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

const Title = () => {
  return <span className={styles.newDiscountTitle}>New Promotion</span>;
};

const defaultValues = {
  name: "",
  description: "",
  discountCode: "",
  status: "",
  discountAmountMeasurement: "",
  discountAmount: 10,
  startDate: "",
  expiredDate: null,
  Settings: {
    limitPerUserCondition: "",
    reusablePerUser: 1,
    minimumOrderCondition: "",
    minimumOrderValue: 10,
    usageLimitCondition: "",
    usageLimit: 1,
    appliedProducts: [],
  },
};

const CreateDiscountForm = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const createDiscountRef = useRef();

  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/dashboard/promotions");
  }, [history]);

  const schema = yup.object().shape({
    discountCode: yup.string().required('Add a discount code'),
    name: yup.string().required('Add a name'),
    description: yup.string().nullable(),
    discountAmountMeasurement: yup.string().required('Required'),
    discountAmount: yup.number().required('Add discount amount'),
    maximumDiscountValue: yup.number(),
    startDate: yup.string().required('Add start date'),
    expiredDate: yup.string().nullable(),
    status: yup.string().nullable().required('Add a status'),
    Settings: yup.object().shape({
      limitPerUserCondition: yup.string().required('required'),
      reusablePerUser: yup.number().nullable(),
      usageLimitCondition: yup.string().required('required'),
      usageLimit: yup.number().nullable(),
      minimumOrderCondition: yup.string().required('required'),
      minimumOrderValue: yup.number().nullable(),
      appliedProducts: yup
        .array()
        .of(
          yup.object({
            name: yup.string().nullable(),
            productId: yup.string().nullable(),
          })
        )
        .nullable(),
    }),
  });

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
    mode: 'onChange'
  });

  const { mutateAsync: addDiscount, isSuccess } = useAddDiscount();

  const onSubmit = async (data) => {
    try {
      await addDiscount(data);
    } catch (error) {
      setToast("Failed adding discount", "error");
      reportError("AddDiscount: onSubmit: error: ", error);
      handleClose();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      setToast("Discount successfully added");
    }
  }, [handleClose, isSuccess]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onAttemptClose={() => {
        history.push("/dashboard/promotions");
        setModalIsOpen(false);
      }}
      modalRef={createDiscountRef}
      Title={Title}
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          className={styles.form}
        >
          <div className={styles.itemsContainer}>
            <Panel className={styles.panelWrap}>
              <PanelHeader title="1. Basic info" />
              <BasicInfo />
            </Panel>
            <Panel className={styles.panelWrap}>
              <PanelHeader title="2. Discount details" />
              <DiscountDetails />
            </Panel>
            <Panel className={styles.panelWrap}>
              <PanelHeader title="3. Discount dates" />
              <DiscountDates />
            </Panel>
            <Panel className={styles.panelWrap}>
              <PanelHeader title="4. Settings" />
              <DiscountSettings />
            </Panel>
          </div>

          <footer className={styles.footer}>
            <SixtyButton type="submit">Create discount</SixtyButton>
            <SixtyButton
              variant="secondary"
              className={styles.cancelButton}
              onClick={handleClose}
            >
              Cancel
            </SixtyButton>
          </footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CreateDiscountForm;
