import React from "react";
import clsx from "clsx";
import styles from "./index.module.css";
import { ReactComponent as CheckIconLogo } from '../../assets/images/Icon-Success.svg';

const Checkbox = React.forwardRef(
  ({ label, helperClass = "", labelHelperClass, checkboxStyle, uncheckStyleVariant, ...props }, ref) => {

    const getUncheckedStateStyle = () => {
      if (uncheckStyleVariant === "greyedOut") {
        return styles.greyedOut;
      }
    }
    return (
      <div
        className={clsx(styles.checkBoxDiv, {
          [helperClass]: helperClass?.trim() !== "",
        })}
      >
        <label>
          <input
            ref={ref}
            type="checkbox"
            {...props}
          />
          <span
            className={clsx(styles.checkboxWrapper, getUncheckedStateStyle(), {
              [checkboxStyle]: checkboxStyle?.trim() !== "",
            })}
          >
            {<CheckIconLogo />}
          </span>
          {label && <span className={clsx(styles.checkboxLabel, {
            [labelHelperClass]: labelHelperClass?.trim() !== ""
          })}>{label}</span>}
        </label>
      </div>
    );
  }
);

export default Checkbox;
