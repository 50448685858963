import Card from "./Components/Card";
import styles from "./index.module.css";
import clsx from "clsx";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import SixtyLink from "components/SixtyLink";

const CampaignInformation = ({ data }) => {

  return (
    <div>
      <div className={styles.row}>
            <Card
              title={'Ordered Bundles'}
              unit={data?.videoStoryName}
              desc={'Bundle of videos for your social media'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
           <Card
              title={'Budget to be Top-up'}
              unit={data?.total}
              desc={'Pay as you go, we charge the top-up for completed videos'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
            <Card
              title={'Status'}
              unit={data?.status}
              desc={'Status of your order.'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
      </div>
    </div>
  );
};

export default CampaignInformation;
