import React, { useState, useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useGetSubscriptionTypeDropdown } from "queries/admin";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import SixtySelect from "components/SixtySelect";
import Checkbox from "components/SixtyCheckBox";
import DatePicker from "components/SixtyDatePicker";
import { format, parse } from "date-fns";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";
import SixtyButton from "components/SixtyButton";
import { setToast } from "components/Toast";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import clsx from "clsx";
import { reportError } from "crashlytics";

const PricingPlanForm = ({selectedPricingPlan}) => {

   const defaultFieldValues = {
    id: null,
    title: '',
   };

    const { id,
            name,
            label,
            freeTrialLabel,
            subscriptionTypeName,
            active,
            freeTrial,
            freeTrialSubscriptionType } = selectedPricingPlan;

    const [activePlan, setActive] = useState(false);
    const [freeTrialPlan, setFreeTrial] = useState(false);
    const [planType, setPlanType] = useState(subscriptionTypeName);
    const [freeTrialPlanType, setFreeTrialPlanType] = useState(freeTrialSubscriptionType);

    const { control, watch, errors } = useFormContext();

    // packages 
    const { fields, append, remove } = useFieldArray({
            control,
            name: "packages",
    });
    
    const _packages = watch("packages");

    // freeTrialPackages
    const { fields : freeTrialFields, append : freeTrialAppend, remove : freeTrialRemove } = useFieldArray({
        control,
        name: "freeTrialPackages",
    });

    const _freeTrialPackages = watch("freeTrialPackages");

    const { data: subscriptionTypeOptions, isLoading: isLoadingSubscriptionType } = useGetSubscriptionTypeDropdown();

    const subscriptionOptions = subscriptionTypeOptions?.length
     ? 
         subscriptionTypeOptions.map((option) => ({
           label: option.name,
           value: option.key,
         }))
       
     : [];

      useEffect(() => {
        setActive(active);
        setFreeTrial(freeTrial);
      }, []);

     useEffect(() => {
        if(activePlan){
            control.setValue('active', true);
        }     
        else{
            control.setValue('active', false);
        }

        if(freeTrialPlan){
          control.setValue('freeTrial', true);
        }     
        else{
          control.setValue('freeTrial', false);
        }
    }, [activePlan, freeTrialPlan]);

  return (
    <div className={styles.form}>
      <div className={styles.section}>Pricing Plan Information</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
         <Input
           name="name"
           error={errors.name}
           control={control}
           label="Name"
           defaultValue={name}
         />
      </div>
      <div className={styles.fullFormRow}>
        <Input
           name="label"
           error={errors.label}
           control={control}
           label="Label"
           defaultValue={label}
         />
      </div>
      <div className={styles.fullFormRow}>
        <Input
           name="price"
           error={errors.price}
           control={control}
           label="Price"
           type="number"
           min={0}
           step="1"
           defaultValue={388}
        />
      </div>
      <div className={styles.formRow}>
        {subscriptionOptions && subscriptionOptions.length > 0 && (
            <Select
              name="subscriptionType"
              error={errors.subscriptionType}
              options={subscriptionOptions}
              control={control}
              fullWidth
              defaultOption="Select subscription type"
              handleChange={(name, value) => {
                setPlanType(value);
              }}
              value={planType}
              defaultValue={planType}
            />
        )}
        <Input
           name="period"
           error={errors.period}
           control={control}
           label="Period"
           type="number"
           min={0}
           step="1"
           defaultValue={1}
        />
      </div>
      <div className={styles.formRow}>
        <Checkbox
          label="Active"
          checked={activePlan}
          onChange={(e) => setActive(e.target.checked)}
          uncheckStyleVariant="greyedOut"
        />
        <Input
           name={`active`}
           control={control}
           type="hidden"
           label="Active"
           defaultValue={true}
           value={true}
           className={styles.hidden}
        />
      </div>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Packages</div>
      <hr className={styles.hr} />
      <div>
        {fields && 
         fields.map((field, index) => (
          <React.Fragment key={field.id}>
              <div className={styles.fullFormRow}>
                  <Input
                    name={`packages[${index}].title`}
                    control={control}
                    label="Title"
                    lines={3}
                    defaultValue={fields[index].title}
                    value={fields[index].title}
                  />
                 <Input
                    name={`packages[${index}].id`}
                    control={control}
                    type="hidden"
                    label="Unique Id"
                    lines={3}
                    defaultValue={fields[index].id}
                    value={fields[index].id}
                    className={styles.hidden}
                 />
                 <div style={{margin:15}}>
                    <SixtyButton
                      variant="text"
                      onClick={(e) => {
                        remove(index);
                      }}
                    >
                      Remove
                    </SixtyButton>
                </div>
              </div>
              <hr className={styles.hr} />
          </React.Fragment>
        ))}
        <SixtyButton
          variant="secondary"
          onClick={(e) => append({ ...defaultFieldValues })}
        >
           Add new package
        </SixtyButton>
      </div>
      <div style={{height:25}} ></div>
      <div className={styles.section}>Free Trial</div>
      <hr className={styles.hr} />
      <div className={styles.formRow}>
        <Checkbox
            label="Free Trial"
            checked={freeTrialPlan}
            onChange={(e) => setFreeTrial(e.target.checked)}
            uncheckStyleVariant="greyedOut"
        />
        <Input
            name={`freeTrial`}
            control={control}
            type="hidden"
            label="Free Trial"
            defaultValue={'false'}
            value={'false'}
            className={styles.hidden}
        />
      </div>
      <div hidden={!freeTrialPlan}>
        <div className={styles.fullFormRow}>
          <Input
            name="freeTrialLabel"
            error={errors.freeTrialLabel}
            control={control}
            label="Label"
            defaultValue={freeTrialLabel}
          />
        </div>
        <div className={styles.formRow}>
          {subscriptionOptions && subscriptionOptions.length > 0 && (
              <Select
                name="freeTrialSubscriptionType"
                error={errors.freeTrialSubscriptionType}
                options={subscriptionOptions}
                control={control}
                fullWidth
                defaultOption="Select free trial subscription type"
                handleChange={(name, value) => {
                  setFreeTrialPlanType(value);
                }}
                value={freeTrialPlanType}
                defaultValue={freeTrialPlanType}
              />
          )}
          <Input
            name="freeTrialPeriod"
            error={errors.freeTrialPeriod}
            control={control}
            label="Free Trial Period"
            type="number"
            min={0}
            step="1"
            defaultValue={1}
          />
        </div>
      </div>
      <div hidden={!freeTrialPlan}>
        <div style={{height:15}} ></div>
        <div className={styles.section}>Free Trial Packages</div>
        <hr className={styles.hr} />
        <div>
        {freeTrialFields && 
          freeTrialFields.map((field, index) => (
            <React.Fragment key={field.id}>
                <div className={styles.fullFormRow}>
                    <Input
                      name={`freeTrialPackages[${index}].title`}
                      control={control}
                      label="Title"
                      lines={3}
                      defaultValue={freeTrialFields[index].title}
                      value={freeTrialFields[index].title}
                    />
                  <Input
                      name={`freeTrialPackages[${index}].id`}
                      control={control}
                      type="hidden"
                      label="Unique Id"
                      defaultValue={freeTrialFields[index].id}
                      value={freeTrialFields[index].id}
                      className={styles.hidden}
                  />
                  <div style={{margin:15}}>
                        <SixtyButton
                          variant="text"
                          onClick={(e) => {
                            freeTrialRemove(index);
                          }}
                        >
                          Remove
                        </SixtyButton>
                  </div>
                </div>
                <div className={styles.fullFormRow}>

                </div>
                <hr className={styles.hr} />
            </React.Fragment>
          ))}
          {freeTrialPlan && (
            <SixtyButton
              variant="secondary"
              onClick={(e) => freeTrialAppend({ ...defaultFieldValues })}
            >
              Add new package
            </SixtyButton>
         )}
        </div>
      </div>
    </div>
  );
};

export default PricingPlanForm;
