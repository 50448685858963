import React, { useState, useEffect, useRef } from "react";
import { Controller, useFieldArray, useFormContext, useWatch, useForm, FormProvider } from "react-hook-form";
import { useGetVideoStoryDropdown, useCreateUgcCampaign } from "queries/campaign";
import { useUploads } from "queries/media";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import ChipInput from "components/FormComponents/ControlledChipInput";
import SixtySelect from "components/SixtySelect";
import Checkbox from "components/SixtyCheckBox";
import DatePicker from "components/SixtyDatePicker";
import { format, parse } from "date-fns";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";
import SixtyButton from "components/SixtyButton";
import { setToast } from "components/Toast";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import { ReactComponent as IconSingleImageDrop } from "assets/icons/Icon-SingleImageDrop.svg";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import Button from "components/SixtyButton";
import Confirmation from "permissions/Confirmation";
import Modal from "components/Modal";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import { isEmpty, isArray } from 'lodash';
import CreateForm  from './Components/CreateForm';
import clsx from "clsx";

const defaultValues = {
    supplierReference: '',
    supplierCode: '',
    supplierName: '',
    supplierId: '',
    name: '',
    instruction: '',
    price: 0,
    priceType: '',
    isPublic: '',
    expiredDate: '',
    defaultCampaignProducts: [],
    campaignProducts: [],
    campaignSellers: []
 };

const CreateCampaign = ({handleClose, templateId = null, adsCampaign = false, availableBalance = 0}) => {

   const [createdCampaign, setCampaign] = useState(null);

   const onNavigateCampaign = () => {
      const path = `/dashboard/services/ugc/${createdCampaign?.id}`;
      window.open(path, "_blank")
   };

   const onNavigateAppointment = () => {
      const path = createdCampaign?.redirectLink;
      window.open(path, "_blank")
   };

    const formMethods = useForm({
        defaultValues,
    });

    const { mutateAsync: uploadFiles,
            isLoading: uploadingFiles,
    } = useUploads();

    const { mutateAsync: createCampaign, 
         isLoading : campaignLoading 
    } = useCreateUgcCampaign();

    const onSubmit = async (data) => {
        try {
            
            // var defaultCampaignImagePath = null;
            // if (isArray(data.mainImages) && data.mainImages?.length > 0) {
            //   const images = new FormData();
            //   let hasNewImages = false;
            //   if (!data.mainImages[0]?.id) {
            //     images.append(`files[0].fileName`, data.mainImages[0].name);
            //     images.append("files[0].file", data.mainImages[0]);
            //     hasNewImages = true;
            //   }
        
            //   const result = hasNewImages ? await uploadFiles(images) : [];
        
            //   const path = result?.length ? result[0].path : '';
            //   defaultCampaignImagePath= path;
            // }

            // let recruitmentRequirement = {
            //    race: data.race,
            //    gender: data.gender
            // };
    
            const model = {
                videoStoryId: data.videoStory,
                name: data.name,
                instruction: data.instruction,
               // defaultCampaignImagePath: defaultCampaignImagePath,
                picContact: data.picContact,
                applicantLimit: '' + data.applicantLimit,
                shipmentType: data.shipmentType,
                isRequiredOwnership: true,
                isAdsUsage: true,
                isRequiredVideoEditing: true,
               // expiredDate: data.expiredDate && format(new Date(data.expiredDate), "dd/MM/yyyy"),
                tasks: data.tasks,
                guidelines: data.guidelines,
                selectedVideoScenes: data.videoScenes,
               // recruitmentRequirement: recruitmentRequirement
             };

             var response = await createCampaign(model);
    
             if(response){
                setToast("Campaign created");
                setCampaign(response);          
             }
            
        } catch (e) {
          // reportError(
          //   "error in : CampaignForm: onSubmit, error: ",
          //   e
          // );
        }
      };

    return (
      <React.Fragment>
        <div className={styles.campaignRequestFormContainer}>
         <Panel className={styles.panelWrap}>
            <PanelHeader
                className={styles.sellerInfoHeader}
                title="Get ready-to-post content created for your brand to enhance your social media presence"
            />

           {createdCampaign && (
            <>
             <div className={styles.form}>
                <Panel className={styles.panelWrap}>
                  <PanelHeader
                    className={styles.campaignInfoHeader}
                    title="Order Created"
                  />
                   <div className={styles.btnActionContainer}>
                        {/* <SixtyButton
                          variant="secondary"
                          onClick={() => onNavigateAppointment()}
                          className={styles.approveBtn}
                          >
                          {"Arrange Google Meeting"}
                        </SixtyButton> */}
                        <SixtyButton
                          variant="secondary"
                          onClick={() => onNavigateCampaign()}
                          className={styles.approveBtn}
                          >
                          {"Check campaign status"}
                        </SixtyButton>
                  </div> 
                  <div className={styles.campaignInfoContainer}>
                    <div className={styles.campaignInfoRow}>
                      <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                        <div className={styles.campaignInfoHeader}>Status</div>
                        <div
                          className={styles.elipsis}
                          title={'Status'}
                        >
                          {'Processing'}
                        </div>
                      </div>
                    </div>
                    <div className={styles.campaignInfoRow}>
                    <div className={styles.campaignInfoRow}>
                      <div className={clsx(styles.campaignInfoItem)}>
                 
                        <div
                          className={styles.elipsis}
                          title={''}
                        >
                          {'Thank you for the order, our operation team will contact you shortly'}
                        </div>
                      </div>
                    </div>
                    </div>
              </div>
             </Panel>
             </div>
            </>
          )}
          {!createdCampaign && (
              <FormProvider {...formMethods}>            
                 <form className={styles.form}>
                    <CreateForm 
                      templateId={templateId} 
                      adsCampaign={adsCampaign} 
                      availableBalance={availableBalance}
                    />       
                 </form>                      
              </FormProvider>
          )}

        </Panel>
        <footer className={styles.footer}>           
                    <SixtyButton
                        type="submit"
                        onClick={formMethods.handleSubmit(onSubmit)}
                        className={clsx(styles.approveBtn, "order-btn")}
                        disabled={campaignLoading || uploadingFiles || createdCampaign}
                        >
                        {"Pay with credits"}
                    </SixtyButton>
                    <SixtyButton
                        variant="warning"
                        className={styles.cancelButton}
                        onClick={handleClose}
                        disabled={campaignLoading}
                    >
                        Cancel
                    </SixtyButton>
        </footer>
    </div>
    </React.Fragment>
  );
};

export default CreateCampaign;
