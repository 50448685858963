import React, { useState, useEffect, useRef } from "react";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useGetCampaignPriceTypeDropdown, 
         useGetCampaignStatusDropdown,
         useGetVendorProductDropdown,
         useGetSellerDropdown,
         useGetCampaignRewardRequirementDropdown,
         useGetSocialMediaDropdown,
         useGetAudiencePreferenceDropdown } from "queries/admin";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import ChipInput from "components/FormComponents/ControlledChipInput";
import {
  useGetDiscountLimitPerCustomerConditionDropdown,
  useGetDiscountOrderConditionDropdown,
  useGetDiscountUsageLimitConditionDropdown,
  useGetProductDropdown,
} from "queries/product";
import SixtySelect from "components/SixtySelect";
import Checkbox from "components/SixtyCheckBox";
import DatePicker from "components/SixtyDatePicker";
import { format, parse } from "date-fns";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";
import SixtyButton from "components/SixtyButton";
import { setToast } from "components/Toast";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import { ReactComponent as IconSingleImageDrop } from "assets/icons/Icon-SingleImageDrop.svg";
import Button from "components/SixtyButton";

const defaultFieldValues = {
  id: null,
  requirementType: '',
  requirementAmount: 0,
  reward: 0,
 };

const CampaignInfo = ({selectedVendor}) => {

  const { code } = selectedVendor;
  const { control, watch, setValue, errors } = useFormContext();

  // products 
  const { fields, append, remove } = useFieldArray({
    control,
    name: "campaignProducts",
  });

  const _campaignProducts = watch("campaignProducts");

    // additional rewards
    const { fields : requirementFields, append: appendRequirement, remove: removeRequirement } = useFieldArray({
      control,
      name: "additionalRewards",
    });
  
    const _additionalRewards = watch("additionalRewards");
    const openDropzone = useRef(() => {});

    const mainImage = useWatch({
      control,
      name: "mainImages",
      defaultValue: [],
    });

    const onMainImageDelete = () => {
      setValue("mainImages", []);
    };
  
  const [campaignType, setCampaignType] = useState('Standard');
  const [shipmentType, setShipmentType] = useState('');
  const [deliverableType, setDeliverableType] = useState('');
  const [appointmentType, setAppointmentType] = useState('');
  const [rewardType, setRewardType] = useState('');
  const [socialMediaType, setSocialMediaType] = useState('');
  const [requirementType, setRequirementType] = useState('');

  const [product, setProduct] = useState('');

  const [keyword, setKeyword] = useState("");
  // product dropdown option query
  const { data: productdata, isLoadingProducts } = useGetVendorProductDropdown({
    keyword,
    limit: 10,
    vendorCode: code
  });

  const productOptions = productdata
    ? productdata.map((item) => {
        return { label: item.name, value: item.id };
      })
    : [];

    const [sellerKeyword, setSellerKeyword] = useState("");
    // product dropdown option query
    const { data: sellerdata, isLoadingSellers } = useGetSellerDropdown({
      keyword: sellerKeyword,
      limit: 10
    });
  
    const sellerOptions = sellerdata
      ? sellerdata.map((item) => {
          return { label: item.name, value: item.key };
        })
      : [];

    const { data: rewardRequirementOptions } = useGetCampaignRewardRequirementDropdown();
 
    const requirementTypeOptions = rewardRequirementOptions?.length
     ? 
        
         rewardRequirementOptions.map((option) => ({
           label: option.name,
           value: option.key,
         }))
       
     : [];
    
     const { data: audiencePreferenceOptions, isLoading: isAudienceLoading } = useGetAudiencePreferenceDropdown();
 
     const audienceOptions = audiencePreferenceOptions?.length
      ? audiencePreferenceOptions.map((item) => {
          return { label: item.name, value: item.id };
        })
      : [];
  
     const { data: socialMediaOptions } = useGetSocialMediaDropdown();
 
     const mediaOptions = socialMediaOptions?.length
      ? [
          { value: null, label: "None" },
          ...socialMediaOptions.map((option) => ({
            label: option.name,
            value: option.id,
          })),
        ]
      : [];

     const { data: campaignStatusOptions } = useGetCampaignStatusDropdown();
 
     const statusOptions = campaignStatusOptions?.length
      ? [
          { value: null, label: "None" },
          ...campaignStatusOptions.map((option) => ({
            label: option.name,
            value: option.key,
          })),
        ]
      : [];

      const { data: campaignPriceTypeOptions } = useGetCampaignPriceTypeDropdown();
 
      const priceTypeOptions = campaignPriceTypeOptions?.length
       ? [
           { value: null, label: "None" },
           ...campaignPriceTypeOptions.map((option) => ({
             label: option.name,
             value: option.key,
           })),
         ]
       : [];

     const [publicCampaign, setPublic] = useState(false);
     const [draftReview, setDraftReview] = useState(false);
     const [profitSharing, setProfitSharing] = useState(false);
     const [ecommerceReview, setEcommerceReview] = useState(false);
     const [coveredShippingCost, setCoveredShippingCost] = useState(false);
     const [publicSetting, setPublicSetting] = useState("false");
     const [progressStatus, setStatus] = useState('');
     

     useEffect(() => {
        if(publicCampaign){
            control.setValue('isPublic', "true");
        }     
        else{
            control.setValue('isPublic', "false");
        }

        if(draftReview){
            control.setValue('isRequiredDraftReview', "true");
        }
        else{
            control.setValue('isRequiredDraftReview', "false");
        }

        if(profitSharing){
             control.setValue('isProfitSharing', "true");
        }
        else{
             control.setValue('isProfitSharing', "false");
        }

        if(coveredShippingCost){
             control.setValue('isCoveredShippingCosts', "true");
        }
        else{
             control.setValue('isCoveredShippingCosts', "false");
        }
        if(ecommerceReview){
          control.setValue('isRequiredEcommerceReview', "true");
        }
        else{
          control.setValue('isRequiredEcommerceReview', "false");
        }
      }, [publicCampaign, draftReview, profitSharing, coveredShippingCost, ecommerceReview], [fields]);

      function SetProducts(id){
        setProduct(id);
        if(productdata){
          const result = productdata.find(x => x.id === id);
          if(result){
            var exits = fields.find(x => x.productId == id);
            if(!exits){
              append(
                {
                    productId: result.id,
                    name: result.name,
                    redirectLink: result.redirectLink
                });
            }
            else{
              setToast("PRODUCT ALREADY EXITS IN LISTING.", "error");
            }
          }
        }  
      }

  return (
    <div className={styles.form}>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Campaign Image</div>
      <hr className={styles.hr} />
      <ImageDropzone
           control={control}
           error={errors.mainImages}
           name="mainImages"
           title="Drag your image here"
           subTitle="or Click to browse for a file"
           DefaultThumb={IconSingleImageDrop}
           maximumFiles={1}
           onDropAccepted={(name, selectedFiles) => {
             setValue("mainImages", selectedFiles);
           }}
           setOpenDropzonehook={(open) => {
             openDropzone.current = open;
           }}
           selectedFiles={mainImage}
      />
      {mainImage.length > 0 && (
            <div className={styles.actionButtonsContainer}>
              <div>
                <Button
                  onClick={() => openDropzone.current()}
                  variant="secondary"
                >
                  Replace
                </Button>
              </div>
              <div>
                <Button onClick={onMainImageDelete} variant="secondary">
                  Delete
                </Button>
              </div>
            </div>
      )}
      <div style={{height:15}} ></div>
      <div className={styles.section}>Campaign Information</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
         <Input
           name="name"
           label="Campaign Title"
           defaultValue=""
         />
      </div>
      <div className={styles.formRow}>
         <Input
           name="applicantLimit"
           label="Campaign Limit e.g 10"
           defaultValue=""
         />
          <Input
           name="picContact"
           label="Contact number e.g 0145645678"
           defaultValue=""
         />
      </div>
       <div className={styles.fullFormRow}>
         <Input
           name="channelInvitationLink"
           label="Invitation Link e.g Tiktok affiliate invitation link"
           defaultValue=""
         />
      </div>       
      <div className={styles.fullFormRow}>
        <ChipInput
          name="campaignAudiences"
          control={control}
          error={errors.campaignAudiences}
          label="Target Audiences"
          fullWidth
          defaultValue={[]}
          handleChange={(value, options) =>           
            value.map((id) => {
              return { audienceId: id.value, audienceName: id.label};
            })
          }
          getValue={(value) => 
            value?.map((item) => { return {value: item.audienceId, label: item.audienceName}})
          }
          options={audienceOptions}
          optionsLoading={isAudienceLoading}
          useSelectProps={{
            filterFn: (options) => options,
          }}
        />
      </div>
      <div className={styles.formRow}>
        <ControlledDatepicker
          name="postingExpiredOnUtc"
          error={errors?.postingExpiredOnUtc}
          label="Posting Date"
          control={control}
          dateFormat="dd/MM/yyyy"
          minDate={new Date()}
        />
        <ControlledDatepicker
          name="expiredDate"
          error={errors?.expiredDate}
          label="Expired date"
          control={control}
          dateFormat="dd/MM/yyyy"
          minDate={new Date()}
        />
      </div>
      <div className={styles.formRow}>
        <Select
          name="status"
          error={errors.status}
          options={statusOptions}
          control={control}
          fullWidth
          defaultOption="Select an progress status"
          handleChange={(name, value) => {
            setStatus(value);
          }}
          value={progressStatus}
          defaultValue={progressStatus}
        />
      </div>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Settings</div>
      <hr className={styles.hr} />
      <div className={styles.formRow}>
      <Checkbox
          label="Set as public campaign"
          checked={publicCampaign}
          onChange={(e) => setPublic(e.target.checked)}
          uncheckStyleVariant="greyedOut"
        />
        <Input
           name={`isPublic`}
           control={control}
           type="hidden"
           label="Visibility"
           defaultValue={'false'}
           value={'false'}
           className={styles.hidden}
        />
      </div>
      <div className={styles.formRow}>
        <Checkbox
          label="Required draft review"
          checked={draftReview}
          onChange={(e) => setDraftReview(e.target.checked)}
          uncheckStyleVariant="greyedOut"
        />
        <Input
           name={`isRequiredDraftReview`}
           control={control}
           type="hidden"
           label="Visibility"
           defaultValue={'false'}
           value={'false'}
           className={styles.hidden}
        />
      </div>
      <div className={styles.formRow}>
        <Checkbox
          label="Profit sharing"
          checked={profitSharing}
          onChange={(e) => setProfitSharing(e.target.checked)}
          uncheckStyleVariant="greyedOut"
        />
        <Input
           name={`isProfitSharing`}
           control={control}
           type="hidden"
           label="Visibility"
           defaultValue={'false'}
           value={'false'}
           className={styles.hidden}
        />
      </div>
      <div style={{height:15}} ></div>
      <div className={styles.formRow}>
          <Select
             name="campaignType"
             error={errors.target}
             options={[
               {
                 label: "Online",
                 selectedLabel: "Online",
                 value: "Standard",
               },
               {
                  label: "Offline",
                  selectedLabel: "Offline",
                  value: "Offline",
               },
               {
                label: "Shopee Review",
                selectedLabel: "Shopee Review",
                value: "WebsiteReview",
             },
             ]}
             control={control}
             fullWidth
             defaultOption="Select campaign type"
             handleChange={(name, value) => {
               setCampaignType(value);
             }}
             value={campaignType}
             defaultValue={campaignType}
          />
          {campaignType == 'AffiliateEvent' && (
              <Input
              name="totalCommission"
              error={errors.totalCommission}
              control={control}
              label="Campaign Commission %"
              type="number"
              min={0}
              step=".01"
              defaultValue={100}
              />
          )}      
      </div>
      {campaignType == 'WebsiteReview' && (
            <div className={styles.formRow}>
              <div className={styles.section}>* Influencers write good ratings and review on shopee once received the product</div>
            </div>
      )}

     {['Standard','WebsiteReview'].includes(campaignType) && (
         <>
            <div style={{height:15}} ></div>
            <div className={styles.section}>Shipment</div>
            <hr className={styles.hr} />
            <div className={styles.fullFormRow}>
                <Select
                  name="shipmentType"
                  error={errors.target}
                  options={[
                    {
                      label: "None",
                      selectedLabel: "None",
                      value: "None",
                    },
                    {
                      label: "Manual",
                      selectedLabel: "Manual",
                      value: "Manual",
                    },
                    {
                      label: "Shopee voucher code",
                      selectedLabel: "Shopee voucher code",
                      value: "VoucherCode",
                    },
                    {
                      label: "Purchase",
                      selectedLabel: "Purchase",
                      value: "Purchase",
                    },
                  ]}
                  control={control}
                  fullWidth
                  defaultOption="Select shipment type"
                  handleChange={(name, value) => {
                    setShipmentType(value);
                  }}
                  value={shipmentType}
                  defaultValue={shipmentType}
                />
            </div>

            {['Purchase'].includes(shipmentType) && (
            <>
              <div className={styles.fullFormRow}>
                <div className={styles.section}>* Influencers purchase the product by themselves to start the campaign</div>
              </div>
              <div className={styles.fullFormRow}>
                  <Input
                    name={`purchaseLink`}
                    control={control}
                    label="Product Link e.g https://shopee.com.my/product/863838974/22217367413"
                    defaultValue=""
                  />
              </div>
              <div className={styles.fullFormRow}>
                <div className={styles.section}>* Product to be purchase by influencer through website</div>
              </div>
              <div className={styles.fullFormRow}>
                  <Input
                    name={`productPurchasePrice`}
                    control={control}
                    label="Product price"
                    type="number"
                    min={0}
                  />
              </div>
              <div className={styles.fullFormRow}>
                <div className={styles.section}>* Price of the product, influencer can claim back the fees from 60sec once complete the campaign</div>
              </div>
              <div className={styles.fullFormRow}>
                  <Input
                    name={`shippingCoveredCost`}
                    control={control}
                    label="Covered shipping cost"
                    type="number"
                    min={0}
                  />
              </div>
              <div className={styles.fullFormRow}>
                <div className={styles.section}>* Shipping costs you want to cover for influencer e.g MYR 10 per order</div>
              </div>
            </>
          )}
          {['VoucherCode'].includes(shipmentType) && (
              <>
                <div className={styles.fullFormRow}>
                  <div className={styles.section}>* Influencers redeem the product with provided voucher code on your website</div>
                </div>
                <div className={styles.fullFormRow}>
                    <Checkbox
                      label="Cover shipping cost on shopee"
                      checked={coveredShippingCost}
                      onChange={(e) => setCoveredShippingCost(e.target.checked)}
                      uncheckStyleVariant="greyedOut"
                    />
                    <Input
                      name={`isCoveredShippingCosts`}
                      control={control}
                      type="hidden"
                      label="Visibility"
                      defaultValue={'false'}
                      value={'false'}
                      className={styles.hidden}
                    />
                </div>
                <div className={styles.fullFormRow}>
                  <div className={styles.section}>* On shopee set seller covered shipping cost for product you want influencer redeem</div>
                </div>
                {!coveredShippingCost && (
                  <>
                    <div className={styles.fullFormRow}>
                        <Input
                          name={`shippingCoveredCost`}
                          control={control}
                          label="Covered shipping cost"
                          type="number"
                          min={0}
                        />
                    </div>
                    <div className={styles.fullFormRow}>
                      <div className={styles.section}>* Shipping costs you want to cover for influencer e.g MYR 10 per order</div>
                    </div>
                  </>
                )}
              </>
          )}
          {['Manual'].includes(shipmentType) && (
              <div className={styles.fullFormRow}>
                <div className={styles.section}>* You manually sent product to influencers with their provided shipping information</div>
              </div>
          )}
          {['Purchase','VoucherCode'].includes(shipmentType) && (
            <>
              <div className={styles.formRow}>
                <Checkbox
                  label="Shopee ratings & review"
                  checked={ecommerceReview}
                  onChange={(e) => setEcommerceReview(e.target.checked)}
                  uncheckStyleVariant="greyedOut"
                />
                <Input
                  name={`isRequiredEcommerceReview`}
                  control={control}
                  type="hidden"
                  label="Visibility"
                  defaultValue={'false'}
                  value={'false'}
                  className={styles.hidden}
                />
              </div>
              <div className={styles.fullFormRow}>
                  <div className={styles.section}>* Only applicable if influencers purchase or redeem product from shopee</div>
              </div>
            </>
          )}
         </>
     )}


     {['Offline'].includes(campaignType) && (
         <>
              <div style={{height:15}} ></div>
              <div className={styles.section}>Appointment Type</div>
              <hr className={styles.hr} />
              <div className={styles.fullFormRow}>
                  <Select
                    name="appointmentType"
                    error={errors.target}
                    options={[
                      {
                        label: "Whatapps",
                        selectedLabel: "Whatapps",
                        value: "Whatapps",
                      },
                    ]}
                    control={control}
                    fullWidth
                    defaultOption="Select appointment method"
                    handleChange={(name, value) => {
                      setAppointmentType(value);
                    }}
                    value={appointmentType}
                    defaultValue={appointmentType}
                  />
              </div>

              {appointmentType == 'Whatapps' && (
                <div className={styles.fullFormRow}>
                  <div className={styles.section}>* Influencers contact you through whatapps for appointment arrangement</div>
                </div>
              )}
              <div style={{height:15}} ></div>
              <div className={styles.section}>Location to be visit</div>
              <hr className={styles.hr} />
              <div className={styles.fullFormRow}>
                <Input
                  name="appointmentLocation"
                  label="Address"
                  lines={6}
                  defaultValue=""
                />
              </div>
         </>
     )}

      {['Standard','Offline'].includes(campaignType) && (
           <>
              <div style={{height:15}} ></div>
              <div className={styles.section}>Social Media</div>
              <hr className={styles.hr} />
              <div className={styles.formRow}>
                  <Select
                    name="deliverableType"
                    error={errors.target}
                    options={[
                      {
                        label: "Carousels",
                        selectedLabel: "Carousels",
                        value: "Carousels",
                      },
                      {
                          label: "Video",
                          selectedLabel: "Video",
                          value: "Video",
                      },
                    ]}
                    control={control}
                    fullWidth
                    defaultOption="Select deliverables"
                    handleChange={(name, value) => {
                      setDeliverableType(value);
                    }}
                    value={deliverableType}
                    defaultValue={deliverableType}
                  />
                  <Select
                  name="socialMediaId"
                  error={errors.socialMediaId}
                  options={mediaOptions}
                  control={control}
                  fullWidth
                  defaultOption="Select an social media"
                  handleChange={(name, value) => {
                    setSocialMediaType(value);
                  }}
                  value={socialMediaType}
                  defaultValue={socialMediaType}
                />
              </div>    
              <div className={styles.fullFormRow}>
                <Input
                  name="deliverableReferenceUrl"
                  label="Sample of reference e.g https://www.instagram.com/"
                  defaultValue=""
                />
              </div>    
              <div className={styles.fullFormRow}>
                  <div className={styles.section}>* Feel free to provide sample of reference for influencer to deliver your prefered content</div>
              </div> 
           </>
      )}

      <div style={{height:15}} ></div>
      <div className={styles.section}>Recruitment Requirement</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
        <Input
           name="instruction"
           label="Requirements"
           lines={6}
           defaultValue=""
         />
      </div>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Task Details</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
        <Input
           name="tasks"
           label="Tasks"
           lines={6}
           defaultValue={''}
         />
      </div>
      <div className={styles.section}>Guidelines Details</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
        <Input
           name="guidelines"
           label="Guidelines"
           lines={6}
           defaultValue={''}
         />
      </div>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Rewards</div>
      <hr className={styles.hr} />
      <div className={styles.formRow}>
        <Input
          name="price"
          error={errors.price}
          control={control}
          label={rewardType == "Commission" ? "Paid Campaign %" : "Paid Campaign RM"}
          type="number"
          min={0}
          step=".01"
          defaultValue={100}
        />
         <Select
          name="priceType"
          error={errors.priceType}
          options={priceTypeOptions}
          control={control}
          fullWidth
          defaultOption="Select an reward measurement"
          handleChange={(name, value) => {
            setRewardType(value);
          }}
          value={rewardType}
          defaultValue={rewardType}
        />
      </div>
      {rewardType == 'Fixed' && (
        <>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Additional Rewards</div>
      <hr className={styles.hr} />
      <div>
        {requirementFields && 
         requirementFields.map((field, index) => (
          <React.Fragment key={field.id}>
              <div className={styles.fullFormRow}>
                  <Select
                    name={`additionalRewards[${index}].requirementType`}
                    options={requirementTypeOptions}
                    control={control}
                    fullWidth
                    defaultOption="Select an requirement type"
                  />
                 <Input
                    name={`additionalRewards[${index}].id`}
                    control={control}
                    type="hidden"
                    label="Unique Id"
                    lines={3}
                    defaultValue={requirementFields[index].id}
                    value={requirementFields[index].id}
                    className={styles.hidden}
                 />
                 <div style={{margin:15}}>
                    <SixtyButton
                      variant="text"
                      onClick={(e) => {
                        removeRequirement(index);
                      }}
                    >
                      Remove
                    </SixtyButton>
                </div>
              </div>
              <div className={styles.fullFormRow}>
                  <Input
                    name={`additionalRewards[${index}].requirementAmount`}
                    control={control}
                    label="Requirement Value"
                    type="number"
                    min={0}
                    defaultValue={requirementFields[index].requirementAmount}
                    value={requirementFields[index].requirementAmount}
                  />
              </div>
              <div className={styles.fullFormRow}>
                  <Input
                    name={`additionalRewards[${index}].reward`}
                    control={control}
                    label="Reward RM"
                    type="number"
                    min={0}
                    step=".01"
                    defaultValue={requirementFields[index].reward}
                    value={requirementFields[index].reward}
                  />
              </div>
              <hr className={styles.hr} />
          </React.Fragment>
        ))}
        <SixtyButton
          variant="secondary"
          onClick={(e) => appendRequirement({ ...defaultFieldValues })}
        >
           Add new reward 
        </SixtyButton>
      </div>
        </>
      )}

      {/* <div style={{height:15}} ></div>
      <div className={styles.section}>Invitations</div>
      <div className={styles.formRow}>
        <ChipInput
          name="campaignSellers"
          control={control}
          error={errors.campaignSellers}
          label="Invite Influencers"
          defaultValue={[]}
          onSearchChange={setSellerKeyword}
          handleChange={(value, options) =>           
            value.map((id) => {
              const name = options.find(
                (option) => option.value === id
              )?.label;
              return { sellerCode: id, sellerName: name };
            })
          }
          getValue={(value) => value.map((item) => item.sellerCode)}
          options={sellerOptions}
          optionsLoading={isLoadingSellers}
          useSelectProps={{
            filterFn: (options) => options,
          }}
        />
      </div>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Applied To</div>
      <div className={styles.fullFormRow}>
      <ChipInput
          name="defaultCampaignProducts"
          label="Vendor Products"
          onSearchChange={setKeyword}
          options={productOptions}
          optionsLoading={isLoadingProducts}
          value={product}
          onChange={(value) => SetProducts(value)}
          isMultiSelect={false}
        />
      </div>
      {fields && fields.length > 0 && (
         <div>
           <div style={{height:15}} ></div>
           <div className={styles.section}>Redirect Link Mapping</div>
           <div style={{height:15}} ></div>
         </div>
     )}
      {fields && 
       fields.map((field, index) => (
        <React.Fragment key={field.id}>
             <div className={styles.fullFormRow}>
                <Input
                  name={`campaignProducts[${index}].name`}
                  control={control}
                  label="Product Name"
                  defaultValue={fields[index].name}
                  value={fields[index].name}
                  disabled={true}
                />
              </div>
               <div className={styles.fullFormRow}>
               <Input
                  name={`campaignProducts[${index}].productId`}
                  control={control}
                  type="hidden"
                  label="Unique Id"
                  defaultValue={fields[index].productId}
                  value={fields[index].productId}
                  className={styles.hidden}
                />
                <Input
                  name={`campaignProducts[${index}].redirectLink`}
                  control={control}
                  label="Redirect Url"
                  placeholder={"e.g https://www.60sec.asia/"}
                  defaultValue={fields[index].redirectLink}
                  value={fields[index].redirectLink}
                />
                <div style={{margin:5}}>
                  <SixtyButton
                    variant="text"
                    onClick={(e) => {
                      remove(index);
                    }}
                  >
                    Remove
                  </SixtyButton>
                </div>
              </div>
              <hr className={styles.hr} />
        </React.Fragment>
       ))} */}
    </div>
  );
};

export default CampaignInfo;
