import React from 'react';
import { Chart } from 'chart.js';
import styles from './index.module.css';
import clsx from 'clsx';

//todo: tooltip is now displaying separately for each point. We need to make it sync with design.
//todo: rounded edge.
class HorizontalBarChart extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.minXaxesvalue = (Math.min.apply(null, this.props.data.map(item => item.value)) - 5);
        var customTooltips = function (tooltip, data) {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjstooltip');
            // Hide if no tooltip
            if (tooltipEl && tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                tooltipEl.remove();
                return;
            }
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjstooltip';
            tooltipEl.innerHTML = "<div style='border: none'></div>"
            document.body.appendChild(tooltipEl);

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltip.yAlign) {
                tooltipEl.classList.add(tooltip.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }
            function getBody(bodyItem) {
                return bodyItem.lines;
            }
            // Set Text
            function getRowStyles(index) {
                const a = clsx({
                    [styles.tooltipRows]: true,
                    [styles.tooltipSeperator]: index !== props.data.length - 1,
                    [styles.tooltipfirstRow]: index === 0
                });
                return a;
            }
            if (tooltip.body) {
                props.data.forEach((dataValue, index) => {
                    var bodyLines = tooltip.body.map(getBody);
                    //PUT CUSTOM HTML TOOLTIP CONTENT HERE (innerHTML)
                    var innerHtml = `<div  class="${getRowStyles(index)}">`;
                    innerHtml += `<div><span class=${styles.tooltipHeader}>` + dataValue.label + `</span>`;
                    innerHtml += `</div><div>`;
                    bodyLines.forEach(function (body, i) {
                        var colors = tooltip.labelColors[i];
                        var style = 'background:' + colors.backgroundColor;
                        style += '; border-color:' + colors.borderColor;
                        style += '; border-width: 2px';
                        var span = '<span class="chartjstooltip-key" style="' + style + '"></span>';
                        innerHtml += `<div><div class=${styles.tooltipValue}>` + span + dataValue.value + ' ' + (props.tooltipText || '') + `</div></div>`;
                    });
                    innerHtml += '</div></div>';
                    var tableRoot = tooltipEl.querySelector('div');
                    tableRoot.innerHTML += innerHtml;
                });
            }
            var position = this._chart.canvas.getBoundingClientRect();
            // Display, position, and set styles for font
            tooltipEl.style.position = "absolute";
            tooltipEl.style.background = "black";
            tooltipEl.style.color = "white";
            tooltipEl.style.width = "117px";
            tooltipEl.style.height = "auto";
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = position.left + tooltip.caretX + 'px';
            tooltipEl.style.top = position.top + tooltip.caretY + 'px';
            tooltipEl.style.fontFamily = tooltip._fontFamily;
            tooltipEl.style.fontSize = tooltip.fontSize;
            tooltipEl.style.fontStyle = tooltip._fontStyle;
            tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
        };
        this.options = {
            tooltips: {
                enabled: false,
                mode: 'index',
                position: 'nearest',
                custom: customTooltips
            },
            scales: {
                xAxes: [{
                    display: false,
                    ticks: {
                        min: this.minXaxesvalue
                    }
                }],
                yAxes: [{
                    barThickness: 8,
                    hitRadius: 12,
                    ticks: {
                        beginAtZero: true,
                        fontSize: '12',
                        fontFamily: 'Roboto',
                        fontColor: 'rgba(0, 0, 0, 0.25)',
                        fontStyle: 'normal'
                    },
                    gridLines: {
                        display: false
                    }
                }],
            },
            maintainAspectRatio: false,
            responsive: true,
            legend: {
                display: false,
            },
            title: {
                display: false
            }
        };
    }

    componentDidUpdate() {
        this.myChart.data.labels = this.props.data.map(d => d.label);
        this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
        this.myChart.update();
    }

    componentDidMount() {
        this.ctx = document.getElementById('canvas').getContext("2d")
        this.gradient1 = this.ctx.createLinearGradient(0, 0, 470, 0)
        this.gradient1.addColorStop(0, 'rgba(255,128,114,1)');
        this.gradient1.addColorStop(0.5, 'rgba(245,183,85,1)');
        this.gradient2 = this.ctx.createLinearGradient(0, 0, 470, 0)
        this.gradient2.addColorStop(0, 'rgba(0,0,0,0.5)');
        this.gradient2.addColorStop(0.5, 'rgba(0,0,0,0.1)');
        this.myChart = new Chart(this.canvasRef.current, {
            type: 'horizontalBar',
            options: this.options,
            data: {
                labels: this.props.data.map(d => d.label),
                datasets: [{
                    data: this.props.data.map(d => d.value),
                    backgroundColor: [this.gradient1, this.gradient2],
                    pointRadius: 0,
                    borderWidth: 700,
                    borderColor: "transparent",
                    radius: 22,
                }
                ]

            }
        });
    }



    render() {
        return (
            <div className={styles.chartContainer}>
                <canvas id="canvas" ref={this.canvasRef}></canvas>
                <div id={styles.chartjstooltip}></div>
            </div>
        )
    }
}

export default HorizontalBarChart;
