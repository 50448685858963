import { Controller } from "react-hook-form";
import ImageDropzone from "components/ImageDropzone";

export default function ControlledImageDropzone({
  control,
  name,
  onDropAccepted,
  onDropRejected,
  onRemoveFile,
  defaultValue,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, ...rest }) => (
        <>
          <ImageDropzone
            onDropAccepted={(files) => {
              onChange(files);
              onDropAccepted(name, files);
            }}
            onDropRejected={(files) => {
              if (onDropRejected) {
                onDropRejected(name, files);
              }
            }}
            onRemoveFile={(file) => {
              if (onRemoveFile) {
                onRemoveFile(name, file);
              }
            }}
            {...props}
           // selectedFiles={value}
            {...rest}
          />
        </>
      )}
    ></Controller>
  );
}
