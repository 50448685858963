import React, {useState, useEffect} from "react";
import { useForm, FormProvider } from "react-hook-form";
import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";

const defaultValues = {
  orderId: "",
  shippingCompany: "",
  shippingTrackingNumber: "",
  quickTrackLinkUrl: "",
  shippedOn: "",
};

const UpdateOrderForm = ({ handleSubmit, handleCancel, data, isLoading = false }) => {

  const [isSubmited, setSubmited] = useState(false);
  const formMethods = useForm({
    defaultValues,
  });

  const { setValue } = formMethods;

  const onSubmit = (data) => {
    setSubmited(true);
    handleSubmit(data);
  };

  useEffect(() => {

    if (data) {
     let newDate = "";
     if (data?.startDate) {
       let myDate = data?.startDate.split("/");
       newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
     }
 
     for (const [key, value] of Object.entries({
         shippingCompany: data?.companyName,
         shippingTrackingNumber: data?.trackingCode,
         quickTrackLinkUrl: data?.trackingUrl,
         shippedOn: data?.startDate && newDate,
     })) {
       setValue(key, value);
     }
   }
 
   }, []);

  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.updateOrderFormContainer}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={styles.updateOrderForm}>
              <div className={styles.updateOrderFieldRow}>
                <Input
                  name="shippingCompany"
                  label="Shipping company"
                  className={styles.subjectInput}
                  value={data?.companyName}
                  defaultValue={data?.companyName}
                />
              </div>
              <div className={styles.updateOrderFieldRow}>
                <Input
                  name="shippingTrackingNumber"
                  label="Shipping tracking number"
                  className={styles.subjectInput}
                  value={data?.trackingCode}
                  defaultValue={data?.trackingCode}
                />
              </div>
              <div className={styles.updateOrderFieldRow}>
                <Input
                  name="quickTrackLinkUrl"
                  label="Tracking URL"
                  className={styles.subjectInput}
                  defaultValue={data?.trackingUrl}
                />
              </div>
              <div className={styles.updateOrderFieldRow}>
                <ControlledDatepicker
                  label="Start date"
                  name="shippedOn"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className={styles.subNotes}>
                  Send the tracking code so that the seller can share the
                  tracking code with the customers.
              </div>
            </div>
            <footer className={styles.footer}>
              <SixtyButton type="submit" className={styles.sendInvoiceBtn} disabled={isLoading}>
                {data ? "Update" : "Create"}
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={() => handleCancel()}
                disabled={isLoading}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default UpdateOrderForm;
