import React, { useState, useRef } from "react";
import Tabs from "components/Tabs";
import Panel from "components/SixtyPanel";
import ItemRefundTable from "../components/ItemRefundTable";
import GridHeader from "components/GridHeader";
import SixtyButton from "components/SixtyButton";
import { useExportOrders } from "queries/order";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import styles from "./index.module.css";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import Avatar from "components/Avatar";
import Modal from "components/Modal";
import RefundDetails from "./components/RefundDetails";

const AdminBuyerRefund = (props) => {

  const [isViewDetails, setDetailModalOpen] = useState(false);
  const [selectedRefund, setSelectedRefund] = useState(null);
  const viewDetailModalRef = useRef();

  const Title = () => {
    return (
      <>
        <div className={styles.refundModalTitleBar}>
            <div className={styles.refundNameContainer}>
              <div>
                <Avatar name={'Refund Details'} />
              </div>
              <div className={styles.modalTitle}>{'Refund Details'}</div>
          </div>
           <div>
                <CloseIcon
                  className={styles.closeIcon}
                  onClick={() => setDetailModalOpen(false)}
                />
            </div>
        </div>

      </>
    )
  };

  const onItemClick = async (data) => {
    setSelectedRefund(data);
    setDetailModalOpen(true);
  };

  const handleRefundDetailClose = () => {
    setDetailModalOpen(false);
  };

  return (
    <div>
      <GridHeader
        header="Buyer Refund Request"
        renderButtons={() => (
          <>
          </>
        )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.orderTable}>
          <ItemRefundTable onItemClick={onItemClick} />
        </div>
      </Panel>
      {isViewDetails && (
         <Modal
            isOpen={isViewDetails}
            onAttemptClose={() => {
               setDetailModalOpen(false);
            }}
                modalRef={viewDetailModalRef}
                Title={Title}
          >
          <RefundDetails
              selectedRefund={selectedRefund}
              handleClose={handleRefundDetailClose}
          />
         </Modal>
      )}
    </div>
  );
};

export default AdminBuyerRefund;
