import React, { useEffect, useState } from "react";
import Tabs from "components/Tabs";
import Panel from "components/SixtyPanel";
import OrderTable from "./components/OrderTable";
import GridHeader from "components/GridHeader";
import SixtyButton from "components/SixtyButton";
import { useExportOrders, useGetOrders } from "queries/order";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";

const Orders = (props) => {
  const { 
    mutateAsync: exportOrders,
    isLoading : exportLoading
   } = useExportOrders();

  const [processingCount, setProcessingCount] = useState(0);

  const { data: processingData } = useGetOrders({
    status: "Processing",
  });

  const exportOrderExcel = async () => {
    try {
      setToast("Exporting Orders, it may take some time.");
      await exportOrders();
    } catch (error) {
      setToast(`Failed Export Order`, "error");
      reportError("OrderIndex: exportOrders: error: ", error);
    }
  };

  useEffect(() => {
    if (processingData?.totalRecords) {
      setProcessingCount(processingData.totalRecords);
    }
  }, [processingData]);

  const [shippedCount, setShippedCount] = useState(0);

  const { data: shippedData } = useGetOrders({
    status: "Shipped",
  });

  useEffect(() => {
    if (shippedData?.totalRecords) {
      setShippedCount(shippedData.totalRecords);
    }
  }, [shippedData]);

  const [completedCount, setCompletedCount] = useState(0);

  const { data: completedData } = useGetOrders({
    status: "Completed",
  });

  useEffect(() => {
    if (completedData?.totalRecords) {
      setCompletedCount(completedData.totalRecords);
    }
  }, [completedData]);
  const [refundedCount, setRefundedCount] = useState(0);

  const { data: refundedData } = useGetOrders({
    status: "Refunded",
  });

  useEffect(() => {
    if (refundedData?.totalRecords) {
      setRefundedCount(refundedData.totalRecords);
    }
  }, [refundedData]);

  const [archivedCount, setArchivedCount] = useState(0);

  const { data: archivedData } = useGetOrders({
    filterByAchieved: true,
  });

  useEffect(() => {
    if (archivedData?.totalRecords) {
      setArchivedCount(archivedData.totalRecords);
    }
  }, [archivedData]);

  return (
    <div>
      <GridHeader
        header="Orders"
        renderButtons={() => (
          <>
            {/* <SixtyButton>Create new order</SixtyButton> */}
            <SixtyButton variant="secondary" onClick={exportOrderExcel} disabled={exportLoading}>
              Export
            </SixtyButton>
          </>
        )}
      />
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Processing",
              recordsCount: processingCount,
              TabComponent: OrderTable,
              props: {
                status: "Processing",
                // setCount: setProcessingCount,
              },
            },
            {
              tabId: "2",
              label: "Shipped",
              recordsCount: shippedCount,
              TabComponent: OrderTable,
              props: {
                status: "Shipped",
                // setCount: setShippedCount,
              },
            },
            {
              tabId: "3",
              label: "Completed",
              recordsCount: completedCount,
              TabComponent: OrderTable,
              props: {
                status: "Completed",
                // setCount: setCompletedCount,
              },
            },
            {
              tabId: "4",
              label: "Refunded",
              recordsCount: refundedCount,
              TabComponent: OrderTable,
              props: {
                status: "Refunded",
                // setCount: setRefundedCount,
              },
            },
            {
              tabId: "5",
              label: "Archived",
              recordsCount: archivedCount,
              TabComponent: OrderTable,
              props: {
                status: "Archived",
                // setCount: setArchivedCount,
              },
            },
          ]}
        ></Tabs>
      </Panel>
    </div>
  );
};

export default Orders;
