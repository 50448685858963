import styles from "./index.module.css";
import clsx from "clsx";
import { forwardRef } from "react";

const SixtyButton = forwardRef(
  (
    {
      label,
      onClick,
      fullWidth = false,
      variant = "primary",
      size = "regular",
      icon,
      className,
      disabled,
      children,
      iconClassHelper,
      square = false,
      roundedSquare = false,
      ...rest
    },
    ref
  ) => {
    const _className = clsx(
      className,
      styles.button,
      styles[variant],
      styles[size],
      {
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
        [styles.square]: square,
        [styles.roundedSquare]: roundedSquare,
      }
    );

    function renderContent() {
      if (label) {
        return label;
      }
      if (children) {
        return children;
      }
      return "button";
    }

    return (
      <button
        className={_className}
        onClick={!disabled ? onClick : undefined}
        type="button"
        ref={ref}
        {...rest}
      >
        {icon && (
          <span className={clsx(styles.icon, iconClassHelper)}>{icon}</span>
        )}{" "}
        {renderContent()}
      </button>
    );
  }
);
export default SixtyButton;
