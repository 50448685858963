import { queryClient } from "common/query";
import { QueryClientProvider } from "react-query";
import StartupHelper from "helpers/StartupHelper";
import Router from "./routes";
import CustomToastContainer from 'components/Toast';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-TD5GLPKJ'
}

TagManager.initialize(tagManagerArgs)

function App() {
  window.dataLayer.push({
    event: 'pageview'
  });
  return (
    <QueryClientProvider client={queryClient}>
      <StartupHelper />
      <Router />
      <div>
          <CustomToastContainer
            position="bottom-left"
          />
      </div>
    </QueryClientProvider>
  );
}

export default App;
