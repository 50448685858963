import React from "react";
import styles from "./index.module.css";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "utils/common";
import clsx from "clsx";

const ProductsList = ({ products }) => {
  let variantsPresent =
    products?.length > 0 &&
    products.reduce((acc, cur) => {
      if (cur.variantionOption) {
        return true;
      }
      return acc;
    }, false);
  return (
    <React.Fragment>
      {products?.length > 0 && (
        <ul>
          {products.map((product) => (
            <li key={product.productId}>
              <ProductItem
                product={product}
                variantsPresent={variantsPresent}
              />
            </li>
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

export const ProductItem = ({ product, variantsPresent }) => {
  const {
    productId,
    path,
    productName,
    brandName,
    unitPrice,
    quantity,
    variantionOption,
  } = product;
  const history = useHistory();

  return (
    productId && (
      <div className={styles.productItemContainer}>
        <div
          className={clsx(
            styles.productNameCell,
            styles.fullWidth
          )}
          onClick={() => {
            history.push(`/dashboard/products/${productId}`);
          }}
        >
          {path && (
            <div className={styles.productImg}>
              {" "}
              <img
                className={styles.productImage}
                src={path}
                alt="product"
              />{" "}
            </div>
          )}
          <div className={styles.productNameContainer}>
            <div
              title={productName}
              className={[styles.productName, styles.elipsis].join(" ")}
            >
              {productName}
            </div>
            <div className={styles.brandName}>{brandName || ""}</div>
          </div>
        </div>
        {variantsPresent && (
          <div
            className={clsx(
              styles.variationOption,
              styles.productCell,
              styles.elipsis
            )}
          >
            {variantionOption}
          </div>
        )}
        <div className={[styles.priceQuantity, styles.productCell].join(" ")}>
          {formatCurrency(unitPrice) + " x " + quantity}
        </div>
        <div className={[styles.totalPrice, styles.productCell].join(" ")}>
          {formatCurrency(unitPrice * quantity)}
        </div>
      </div>
    )
  );
};

export default ProductsList;
