import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { saveAs } from "file-saver";
import { setToast } from 'components/Toast';
import { queryClient } from "common/query";


export function useSetupFreeTierSubscription() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Subscription.SetupFreeTierSubscription, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useSetupFreeTierSubscription: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetCustomerDefaultPaymentMethod() {
  return useQuery(
    Endpoints.Subscription.GetCustomerDefaultPaymentMethod,
    () =>
      api
        .get(Endpoints.Subscription.GetCustomerDefaultPaymentMethod)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCustomerDefaultPaymentMethod: useQuery: onError: error", error);
      },
    }
  );
}

export function useActivateCancelledSubscription() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Subscription.ActivateCancelledSubscription, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Subscription.GetSubscriptionDetail);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useActivateCancelledSubscription: useMutation: onError: error", error);
      },
    }
  );
}

export function useChangeSubscription() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Subscription.ChangeSubscription, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Subscription.GetSubscriptionDetail);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useChangeSubscription: useMutation: onError: error", error);
      },
    }
  );
}

export function useUpdateCustomerPaymentMethod() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Subscription.UpdateCustomerPaymentMethod, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Subscription.GetSubscriptionDetail);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdateCustomerPaymentMethod: useMutation: onError: error", error);
      },
    }
  );
}

export function useCancelMemberSubscription() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Subscription.CancelMemberSubscription, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Subscription.GetSubscriptionDetail);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useCancelMemberSubscription: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetSubscriptionListing(filter) {
  return useQuery(
    [Endpoints.Subscription.GetSubscriptionListing, filter],
    () =>
      api
        .post(Endpoints.Subscription.GetSubscriptionListing, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSubscriptionListing: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSubscriptionPlans(status) {
  return useQuery(
    [Endpoints.Subscription.GetSubscriptionPlans, status],
    () =>
      api
        .get(Endpoints.Subscription.GetSubscriptionPlans +
          (status ? "?status=" + status : ""))
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSubscriptionPlans: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetRenewableSubscriptionPlans() {
  return useQuery(
    Endpoints.Subscription.GetRenewableSubscriptionPlans,
    () =>
      api
        .get(Endpoints.Subscription.GetRenewableSubscriptionPlans)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetRenewableSubscriptionPlans: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSubscriptionDetail(id) {
  return useQuery(
    [Endpoints.Subscription.GetSubscriptionDetail, id],
    () =>
      api
        .get(Endpoints.Subscription.GetSubscriptionDetail  + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSubscriptionDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useProceedCreditCardSubscription() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Subscription.ProceedCreditCardSubscription, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useProceedCreditCardSubscription: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetMemberBilling() {
  return useQuery(
    Endpoints.Subscription.GetMemberBilling,
    () =>
      api
        .get(Endpoints.Subscription.GetMemberBilling)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetMemberBilling: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCompanyBillingHistories(filter) {
  return useQuery(
    [Endpoints.Subscription.GetCompanyBillingHistories, filter],
    () =>
      api
        .post(Endpoints.Subscription.GetCompanyBillingHistories, filter)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetCompanyBillingHistories: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useDownloadAllBillingStatement() {
  return useMutation(
    () =>
      api
        .get(Endpoints.Subscription.DownloadAllBillingStatement, {
          responseType: "blob",
          "Accept-Encoding": "gzip, deflate, br",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            ?.find((n) => n.includes("filename="))
            ?.replace("filename=", "")
            ?.trim();
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename || "data.zip");
        }),
    {
      onError: (error) => {
        // setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useDownloadAllBillingStatement: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useDownloadBillingStatement() {
  return useMutation(
    (id) =>
      api
        .get(Endpoints.Subscription.DownloadBillingStatement + "/" + id, {
          responseType: "blob",
          "Accept-Encoding": "gzip, deflate, br",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            ?.find((n) => n.includes("filename="))
            ?.replace("filename=", "")
            ?.trim();
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename || "data.pdf");
        }),
    {
      onError: (error) => {
        // setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useDownloadBillingStatement: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useProceedSubscriptionPayment() {
  return useMutation(
    (id) =>
      api
        .post(Endpoints.Subscription.ProceedSubscriptionPayment + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useProceedSubscriptionPayment: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useRenewSubscription() {
  return useMutation(
    (id) =>
      api
        .post(Endpoints.Subscription.RenewSubscription)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useRenewSubscription: useMutation: onError: error", error);
      },
    }
  );
}
