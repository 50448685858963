import styles from "./index.module.css";

export default function RadioField({ label, ...props }) {
  return (
    <label>
      <input type="radio" className={styles.radio} {...props} />
      <span className={styles.label}>{label}</span>
    </label>
  );
}
