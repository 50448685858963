import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import ChipInput from "components/FormComponents/ControlledChipInput";
import {
  useGetDiscountLimitPerCustomerConditionDropdown,
  useGetDiscountOrderConditionDropdown,
  useGetDiscountUsageLimitConditionDropdown,
  useGetProductDropdown,
} from "queries/product";
import SixtySelect from "components/SixtySelect";

const discountTypeOptions = [
  {
    label: "Selected products",
    value: "selectedProducts",
  },
];

const DiscountSettings = () => {
  const { control, errors } = useFormContext();

  const [keyword, setKeyword] = useState("");
  // product dropdown option query
  const { data: productdata, isLoadingProducts } = useGetProductDropdown({
    keyword,
    limit: 10,
  });

  const productOptions = productdata
    ? productdata.map((item) => {
        return { label: item.name, value: item.id };
      })
    : [];

  // min order value
  const { data: minimumOrderValueOptions } =
    useGetDiscountOrderConditionDropdown();

  const minOrderValueOptions = minimumOrderValueOptions?.length
    ? [
        { value: null, label: "None" },
        ...minimumOrderValueOptions.map((option) => ({
          label: option.name,
          value: option.key,
        })),
      ]
    : [];

  const { data: usageLimitOptionsData } =
    useGetDiscountUsageLimitConditionDropdown();

  const usageLimitOptions = usageLimitOptionsData?.length
    ? [
        { label: "None", value: null },
        ...usageLimitOptionsData.map((option) => ({
          label: option.name,
          value: option.key,
        })),
      ]
    : [];

  const { data: limitPerUserConditionOptionsData } =
    useGetDiscountLimitPerCustomerConditionDropdown();

  const limitPerUserConditionOptions = limitPerUserConditionOptionsData?.length
    ? [
        { label: "None", value: null },
        ...limitPerUserConditionOptionsData.map((option) => ({
          label: option.name,
          value: option.key,
        })),
      ]
    : [];

  return (
    <div className={styles.form}>
      <div className={styles.section}>Applies to</div>
      <div className={styles.formRow}>
        <SixtySelect
          options={discountTypeOptions}
          value="selectedProducts"
          fullWidth
        />
      </div>
      <div className={styles.formRow}>
        <ChipInput
          name="Settings.appliedProducts"
          control={control}
          error={errors.Settings?.appliedProducts}
          label="Options"
          defaultValue={[]}
          onSearchChange={setKeyword}
          handleChange={(value, options) =>
            value.map((id) => {
              const name = options.find(
                (option) => option.value === id
              )?.label;
              return { productId: id, name };
            })
          }
          getValue={(value) => value.map((item) => item.productId)}
          options={productOptions}
          optionsLoading={isLoadingProducts}
          useSelectProps={{
            filterFn: (options) => options,
          }}
        />
      </div>
      <div className={styles.section}>Minimum order value</div>
      <div className={styles.formRow}>
        <Select
          name="Settings.minimumOrderCondition"
          error={errors.Settings?.minimumOrderCondition}
          options={minOrderValueOptions}
          control={control}
          fullWidth
          defaultOption="Select an option"
        />
        <Input
          name="Settings.minimumOrderValue"
          error={errors.Settings?.minimumOrderValue}
          control={control}
          label="RM"
          type="number"
          min={0}
          step=".01"
          defaultValue={10}
        />
      </div>
      <div className={styles.section}>Total usage limit</div>
      <div className={styles.formRow}>
        <Select
          name="Settings.usageLimitCondition"
          error={errors.Settings?.usageLimitCondition}
          options={usageLimitOptions}
          control={control}
          fullWidth
          defaultOption="Select an option"
        />
        <Input
          name="Settings.usageLimit"
          error={errors.Settings?.usageLimit}
          control={control}
          defaultValue={1}
          type="number"
          min={0}
          step=".01"
        />
      </div>
      <div className={styles.section}>Limit uses per customer</div>
      <div className={styles.formRow}>
        <Select
          name="Settings.limitPerUserCondition"
          error={errors.Settings?.limitPerUserCondition}
          options={limitPerUserConditionOptions}
          control={control}
          fullWidth
          defaultOption="Select an option"
        />
        <Input
          name="Settings.reusablePerUser"
          error={errors.Settings?.reusablePerUser}
          control={control}
          defaultValue={1}
          type="number"
          min={0}
          step=".01"
        />
      </div>
    </div>
  );
};

export default DiscountSettings;
