import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetVendorDetail, useCreateCampaign } from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import { useUploads } from "queries/media";
import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import CampaignInfo from "./components/CampaignInfo";
import { format } from "date-fns";
import { isEmpty, isArray } from 'lodash';

const CampaignForm = ({ selectedVendor, handleClose }) => {

const [newCampaign, setCreatedCampaign] = useState();
const [campaignModel, setCampaignModel] = useState();
const [campaignCreated, setCampaign] = useState(false);

const defaultValues = {
    supplierReference: '',
    supplierCode: '',
    supplierName: '',
    supplierId: '',
    name: '',
    instruction: '',
    price: 0,
    priceType: '',
    isPublic: '',
    expiredDate: '',
    defaultCampaignProducts: [],
    campaignProducts: [],
    campaignSellers: []
 };
 const formMethods = useForm({
         defaultValues,
 });
  
  const { id } = selectedVendor;

  const { data: venderInfo, isLoading, isError, refetch } = useGetVendorDetail(
    id
  );

  const onBrowseInfluencerRequest = () => {    
    const path = `/admin/campaigns/InfluencerRequests/${newCampaign?.id}`;
    window.open(path, "_blank")
  };

  const onBrowseCampaignProducts = () => {    
    const path = `/admin/campaign/products/${newCampaign?.id}`;
    window.open(path, "_blank")
  };

  const { mutateAsync: uploadFiles,
         isLoading: uploadingFiles,
        } = useUploads();

  const { mutateAsync: createCampaign, 
          isLoading : campaignLoading 
        } = useCreateCampaign();

  const onSubmit = async (data) => {
    try {

      var defaultCampaignImagePath = null;
      if (isArray(data.mainImages) && data.mainImages?.length > 0) {
        const images = new FormData();
        let hasNewImages = false;
        if (!data.mainImages[0]?.id) {
          images.append(`files[0].fileName`, data.mainImages[0].name);
          images.append("files[0].file", data.mainImages[0]);
          hasNewImages = true;
        }
  
        const result = hasNewImages ? await uploadFiles(images) : [];
  
        const path = result?.length ? result[0].path : '';
        defaultCampaignImagePath= path;
      }

        let additionalRewards = [];
        if(data.additionalRewards){
          for(let i = 0; i < data.additionalRewards.length; i++){
            additionalRewards.push({
              id: data.additionalRewards[i].id,
              requirementType: data.additionalRewards[i].requirementType,
              requirementAmount: data.additionalRewards[i].requirementAmount ?
                                 parseFloat(data.additionalRewards[i].requirementAmount) :
                                 0,
              reward: data.additionalRewards[i].reward ?
                      parseFloat(data.additionalRewards[i].reward) :
                      0,
            });
          }
        }

        const model = {
            supplierReference: 'Supplier',
            supplierCode: venderInfo?.code,
            supplierName: venderInfo?.storeDetails?.storeName,
            supplierId: venderInfo?.id,
            socialMediaId: data.socialMediaId,
            name: data.name,
            campaignType: data.campaignType,
            totalCommission: data.totalCommission ? parseFloat(data.totalCommission) : null,
            channelInvitationLink: data.channelInvitationLink,
            instruction: data.instruction,
            price: parseFloat(data.price),
            priceType: data.priceType,
            isPublic: data.isPublic == 'true',
            expiredDate: data.expiredDate && format(new Date(data.expiredDate), "dd/MM/yyyy"),
            status: data.status,
            applicantLimit: data.applicantLimit,
            defaultCampaignImagePath: defaultCampaignImagePath,
            picContact: data.picContact,
            redemptionType: data.redemptionType,
            redemptionReferenceUrl: data.redemptionReferenceUrl,
            deliverableType: data.deliverableType,
            deliverableReferenceUrl: data.deliverableReferenceUrl,
            shipmentType: data.shipmentType,
            appointmentType: data.appointmentType,
            appointmentLocation: data.appointmentLocation,
            productPurchasePrice: data.productPurchasePrice ? parseFloat(data.productPurchasePrice) : null,
            shippingCoveredCost: data.shippingCoveredCost ? parseFloat(data.shippingCoveredCost) : null,
            purchaseLink: data.purchaseLink,
            isCoveredShippingCost: data.isCoveredShippingCost == 'true',
            isRequiredDraftReview: data.isRequiredDraftReview == 'true',
            isRequiredEcommerceReview: data.isRequiredEcommerceReview == 'true',
            isProfitSharing: data.isProfitSharing == 'true',
            postingExpiredOnUtc: data.postingExpiredOnUtc && format(new Date(data.postingExpiredOnUtc), "dd/MM/yyyy"),
            tasks: data.tasks,
            guidelines: data.guidelines,
            campaignAudiences: data.campaignAudiences,
            additionalRewards: additionalRewards
         };

         var response = await createCampaign(model);

         if(response){
            setCampaignModel(model);
            setCreatedCampaign(response);
            setCampaign(true);
            setToast("successfully create campaign");
         }
        
    } catch (e) {
      reportError(
        "error in : CampaignForm: onSubmit, error: ",
        e
      );
    }
  };

  const requestedDate = getFormattedDateString(
    selectedVendor?.submitDate,
    "MMM d, y"
  );

  return (
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Store details"
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>Store name</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.storeDetails?.storeName}
                  >
                    {venderInfo?.storeDetails?.storeName}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Business support email
                  </div>
                  <div>
                    {venderInfo?.vendorDetails?.businessEmailAddress}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Mobile number</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.storeDetails?.contactNumber}
                  >
                    {venderInfo?.storeDetails?.contactNumber}
                  </div>
                </div>
              </div>

              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Industry</div>
                  <div>{venderInfo?.storeDetails?.industry}</div>
                </div>
                <div className={clsx(styles.address,styles.campaignInfoItem)}>
                  <div className={styles.campaignInfoHeader}>
                    Business address
                  </div>
                  <div>{venderInfo?.storeDetails?.businessAddress}</div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Subscription plan"
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>Plan</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.subscriptionPlan?.plan}
                  >
                    {venderInfo?.subscriptionPlan?.plan}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Subscription plan status
                  </div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.subscriptionPlan?.planStatus}
                  >
                    {venderInfo?.subscriptionPlan?.planStatus}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Expired date</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.subscriptionPlan?.expiredDate}
                  >
                    {venderInfo?.subscriptionPlan?.expiredDate}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          {campaignCreated && (
            <>
                <Panel className={styles.panelWrap}>
                <PanelHeader
                  className={styles.campaignInfoHeader}
                  title="New Created Campaign"
                />
                <div className={styles.campaignInfoContainer}>
                  <div className={styles.campaignInfoRow}>
                    <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                      <div className={styles.campaignInfoHeader}>Name</div>
                      <div
                        className={styles.elipsis}
                        title={'Name'}
                      >
                        {campaignModel?.name}
                      </div>
                    </div>
                    <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                      <div className={styles.campaignInfoHeader}>Reward Type</div>
                      <div
                        className={styles.elipsis}
                        title={'Reward Type'}
                      >
                        {campaignModel?.priceType}
                      </div>
                    </div>
                    <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                      <div className={styles.campaignInfoHeader}>Expired Date</div>
                      <div
                        className={styles.elipsis}
                        title={'Expired Date'}
                      >
                        {campaignModel?.expiredDate}
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
              <Panel className={styles.panelWrap}>
              <PanelHeader
                className={styles.campaignInfoHeader}
                title="Influencers"
              />
              <div className={styles.btnActionContainer}>
                    <SixtyButton
                      variant="secondary"
                      onClick={() => onBrowseInfluencerRequest()}
                      className={styles.approveBtn}
                      >
                      {"Manage Influencers"}
                    </SixtyButton>
             </div>
              <div className={styles.campaignInfoContainer}>
                <div className={styles.campaignInfoRow}>
                  <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                    <div className={styles.campaignInfoHeader}>Joined Influencers</div>
                    <div
                      className={styles.elipsis}
                      title={'Joined Influencers'}
                    >
                      {0}
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
            <Panel className={styles.panelWrap}>
              <PanelHeader
                className={styles.campaignInfoHeader}
                title="Products"
              />
              <div className={styles.btnActionContainer}>
                    <SixtyButton
                      variant="secondary"
                      onClick={() => onBrowseCampaignProducts()}
                      className={styles.approveBtn}
                      >
                      {"Manage Products"}
                    </SixtyButton>
             </div>
              <div className={styles.campaignInfoContainer}>
                <div className={styles.campaignInfoRow}>
                  <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                    <div className={styles.campaignInfoHeader}>Campaign Products</div>
                    <div
                      className={styles.elipsis}
                      title={'Campaign Products'}
                    >
                      {0}
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
            </>
          )}

          {campaignCreated == false && (
            <>
              <Panel className={styles.panelWrap}>
                  <PanelHeader
                    className={styles.sellerInfoHeader}
                    title="Setup Vendor Campaign"
                  />
                <FormProvider {...formMethods}>
                  <form
                      className={styles.form}
                  >
                  {venderInfo && (
                  <CampaignInfo selectedVendor={venderInfo} />
                  )}         
                  </form>
              </FormProvider>
              </Panel>
            </>
          )}


        </div>
      </div>

        <footer className={styles.footer} hidden={isLoading}>
          {campaignCreated == false && (
            <SixtyButton
              type="submit"
              onClick={formMethods.handleSubmit(onSubmit)}
              className={styles.approveBtn}
              disabled={campaignLoading || uploadingFiles}
              >
              {"Create"}
            </SixtyButton>
          )}

          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={campaignLoading}
          >
            Close
          </SixtyButton>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default CampaignForm;
