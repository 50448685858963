import React from 'react';
import styles from './index.module.css';
import Avatar from "components/Avatar";


const SellerInfo = (data) => {
    const{ data: sellerData } = data;

    return (
        <React.Fragment>
            {
                sellerData &&
                (<div className={styles.sellerNameContainer}>
                    <div className={styles.avatarContainer}><Avatar name={sellerData?.name} className={styles.sellerAvatar} /></div>
                    <div className={styles.sellerName}>{sellerData?.name}</div>
                </div>
                )
            }
            <div className={styles.sellerBody}>
                {sellerData &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Email
                            </div>
                        <div className={styles.sellerFieldValue}>
                            <span className={styles.email}>{sellerData?.email} </span>
                        </div>
                    </div>
                }
                {sellerData &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Mobile number
                                </div>
                        <div className={styles.sellerFieldValue}>
                            {sellerData?.contactNumber}
                        </div>
                    </div>
                }
                {sellerData &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Gender
                                </div>
                        <div className={styles.sellerFieldValue}>
                            {sellerData?.gender}
                        </div>
                    </div>
                }
                {sellerData &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Occupation
                                </div>
                        <div className={styles.sellerFieldValue}>
                            {sellerData?.occupation}
                        </div>
                    </div>
                }
                {sellerData &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Race
                                </div>
                        <div className={styles.sellerFieldValue}>
                            {sellerData?.race}
                        </div>
                    </div>
                }
                {sellerData &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Date of birth
                                </div>
                        <div className={styles.sellerFieldValue}>
                            {sellerData?.dateOfBirth}
                        </div>
                    </div>
                }
                   {sellerData &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Audience Types
                                </div>
                        <div className={styles.sellerFieldValue}>
                            {sellerData?.audienceTypes}
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default SellerInfo;
