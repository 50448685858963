import Card from "./Components/Card";
import styles from "./index.module.css";
import clsx from "clsx";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import SixtyLink from "components/SixtyLink";

const CampaignOptions = ({ onSocialMedia, onAds, onComingSoon, handleClose }) => {

  return (
    <div>
      <div className={styles.row}>
  
            <Card
              title={'Content Creation'}
              unit={'Get Started '}
              desc={'Get ready-to-post content created for your brand to enhance your social media presence.'}
              clickEvent={onSocialMedia}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
            
            <Card
              title={'Influencer Marketing'}
              warning={'Coming Soon'}
              desc={'Connect with influencers to promote your brand on their social media channels.'}
              clickEvent={onComingSoon}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            /> 
      </div>
    </div>
  );
};

export default CampaignOptions;
