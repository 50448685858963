import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useForm, FormProvider } from "react-hook-form";
import { useGetSellerRegistrationDetail } from "queries/seller";
import { useInviteSeller } from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import ReactWhatsapp from 'react-whatsapp';


const AdminCampaignInvitation = ({campaign, selectedSeller, handleClose }) => {
  const { code, id } = selectedSeller;
  const role = localStorage.getItem("userRole");

  const { data: sellerInfo, isLoading, isError, refetch } = useGetSellerRegistrationDetail(
    code
  );

  const { mutateAsync: inviteSeller, 
          isLoading : inviteLoading 
  } = useInviteSeller();

  const onInviteSeller = async () => {
    try {

        var model = {
          sellerCode: code,
          campaignId: campaign?.id
        };
        console.log(model);
        const result = await inviteSeller(model);

        if (result) {
          handleClose();
          setToast("successfully invite influencer");
        }
     } catch (e) {
          reportError(
            "error in : InfluencerRequestDetails: onInviteSeller, error: ",
           e
          );
      }
  };

  const isUrlValid = (userInput) => {

    if(!userInput) return (false);

    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return (false);
    else
        return (true);
  };

  const onBrowseProfile = async () => {
    try {
     
      window.open(`/admin/influencers/AnalyticDetails/${code}`, "_blank")
        
    } catch (e) {
      reportError(
        "error in : InfluencerRequestDetails: onBrowseProfile, error: ",
        e
      );
    }
  };

  return (
    <React.Fragment>
      <div className={styles.sellerRequestFormContainer}>
        {(isLoading) && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.sellerRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Account Information"
            />
            {sellerInfo?.contact && (
              <div className={styles.btnActionContainer}>             
              <ReactWhatsapp number={sellerInfo?.contact} message="" className={clsx(styles.button, styles.tertiary)}>
                Open Whatapps
              </ReactWhatsapp>
              </div>
            )}
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Code</div>
                  <div>
                    {sellerInfo?.code}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={clsx(styles.email, styles.elipsis, styles.sellerInfoHeader)}>Email</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.email}
                  >
                    {sellerInfo?.email}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Contact</div>
                  <div>
                    {sellerInfo?.contactNumber}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Personal details"
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerInfoItem)}>
                  <div className={styles.sellerInfoHeader}>Name</div>
                  <div>
                    {sellerInfo?.name}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}> Gender </div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.gender}
                  >
                    {sellerInfo?.gender}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Race</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.race}
                  >
                    {sellerInfo?.race}
                  </div>
                </div>
              </div>

              <div className={styles.sellerInfoRow}>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Occupation</div>
                  <div>{sellerInfo?.occupation}</div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}> Date Of Birth </div>
                  <div>{sellerInfo?.dateOfBirth}</div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}> Register Date </div>
                  <div>{sellerInfo?.registerDate}</div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Audience Type Details"
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerFullInfoItem, styles.elipsis)}>
                  <div className={styles.sellerInfoHeader}>Audience Type</div>
                  <div>
                    {sellerInfo?.audienceTypes}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Location"
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>State</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.state}
                  >
                    {sellerInfo?.state}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Country</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.country}
                  >
                    {sellerInfo?.country}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

         {sellerInfo?.socialMediaChannels.map((field, index) =>(
         <React.Fragment key={field.id}>

           <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title={sellerInfo?.socialMediaChannels[index].name}
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerInfoItem, styles.elipsis)}>
                  <div className={styles.sellerInfoHeader}>Follower Range</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.socialMediaChannels[index].followerRange}
                  >
                    {sellerInfo?.socialMediaChannels[index].followerRange}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>
                    Profile Link
                  </div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.socialMediaChannels[index].profileLink}
                  >
                    {isUrlValid(sellerInfo?.socialMediaChannels[index].profileLink) &&(
                     <a className={styles.link} href={sellerInfo?.socialMediaChannels[index].profileLink} target={"_blank"} rel={"noreferrer"}>
                         {sellerInfo?.socialMediaChannels[index].profileLink}
                     </a>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </Panel>
         </React.Fragment>
         ))}

        </div>
        </div>

        <footer className={styles.footer} hidden={isLoading}>

          {role == "InternalUser" && (
            <>
                <SixtyButton
                  onClick={onInviteSeller}
                  className={styles.approveBtn}
                  disabled={inviteLoading}
                >
                    Invite
               </SixtyButton>

              <SixtyButton
                 variant="tertiary"
                 className={styles.cancelButton}
                 onClick={onBrowseProfile}
               >
                  Browse Influencer Profile
              </SixtyButton>
            </>
          )}
        </footer>
      </div>
    </React.Fragment>
  );
};

export default AdminCampaignInvitation;
