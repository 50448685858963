import Loader from "components/Loader";
import Modal from "components/Modal";
import { setToast } from "components/Toast";
import { useGetPriceTierDetail, useUpdatePriceTier } from "queries/product";
import { useRef } from "react";
import { useHistory, useParams } from "react-router";
import AddTierForm from "../components/AddTierForm";
import styles from "./index.module.css";

const AddTierTitle = () => {
  return (
    <div>
      <div className={styles.modalTitle}>Update tier</div>
    </div>
  );
};

export default function TierDetails() {
  const { id } = useParams();
  const history = useHistory();
  const modalRef = useRef();

  const { data: tierData } = useGetPriceTierDetail(id);

  const { mutateAsync: updateTier, isLoading: updating } = useUpdatePriceTier();

  const onUpdate = async (data) => {
    const result = await updateTier({ ...data, id });
    if (result) {
      setToast("Successfully updated tier");
      onClose();
    }
  };
  const onClose = () => {
    history.push("/dashboard/tiers");
  };
  return (
    <Modal
      isOpen={true}
      onAttemptClose={onClose}
      modalRef={modalRef}
      Title={AddTierTitle}
    >
      {updating ? (
        <Loader text="Updating tier" />
      ) : (
        <AddTierForm
          data={tierData}
          onCreateTierSubmit={onUpdate}
          onCreateTierCancel={onClose}
        />
      )}
    </Modal>
  );
}
