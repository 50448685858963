import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useGetPostingContentDetail, useUpdateStatus, useDownloadPostMedia, useSuspendOrActivateContent } from "queries/posting";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Button from "components/SixtyButton";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

const AffiliateContentDetail = ({ selectedPost, handleClose, viewOnly = false }) => {

  const { id } = selectedPost;

  const { data: postInfo, isLoading, isError, refetch } = useGetPostingContentDetail(
    id
  );

  const { mutateAsync: SuspendOrActivateContent, 
          isLoading : suspendLoading 
        } = useSuspendOrActivateContent();

  const {
         mutateAsync: downloadMedia,
         isLoading: exporting,
        } = useDownloadPostMedia();

    const onSuspendOrActivate = async () => {
      try {
      
        var result = await SuspendOrActivateContent(postInfo?.id);

        if(result){
           refetch();
           setToast("successfully update status");
           handleClose();
        }
       
     } catch (e) {
        reportError(
          "error in : onApproved: onSubmit, error: ",
          e
        );
     }
  };

  const onDownload = async () => {    
    setToast("Downloading Media");
    if (!exporting) await downloadMedia(id);
  };

  const onPreview = () => {    
     const path = `${postInfo?.previewUrl}`;
     window.open(path, "_blank")
  };

  const onViewPost = () => {    
    window.open(postInfo?.postLink, "_blank")
  };

  return (
      <>
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
          <div className={styles.btnActionContainer}>

          <SixtyButton
            variant="tertiary"
            onClick={() => onPreview()}
            className={styles.approveBtn}
            disabled={postInfo?.isSuspendContent ? true : false}
          >
           {"Preview Web Store"}
          </SixtyButton>

        </div>
          <div className={styles.subHeader}>
                <span> Media </span>
                <div className={styles.profPhotoContainer}>
                  <div className={styles.profPhotoDropzone}>
                  <img
                    src={postInfo?.postImageUrl}
                    className={clsx(styles.img)}
                    alt="No preview"
                  />
                  </div>
                  <div className={styles.actionButtonsContainer}>
                      <div onClick={onDownload} disabled={exporting}>
                        <Button label="Download" variant="secondary" />
                      </div>
                  </div>
                </div>
              </div>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Post Info"
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Status
                  </div>
                  <div>
                    {postInfo?.status}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Uploaded by</div>
                  <div>
                    {postInfo?.uploadBy}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Created Date</div>
                  <div>
                    {postInfo?.createdDate}
                  </div>
                </div>
              </div>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoFullWidthItem}>
                  <div className={styles.campaignInfoHeader}>
                    Caption
                  </div>
                  <div>
                    {postInfo?.description}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
      
        </div>
      </div>

      <footer className={styles.footer} hidden={isLoading || viewOnly}>

          <SixtyButton
            variant= {postInfo?.isSuspendContent ? "tertiary" : "secondary"}
            className={styles.approveBtn}
            onClick={onSuspendOrActivate}
            disabled={suspendLoading}
          >
             {postInfo?.isSuspendContent ? 'Activate' : 'Trash'}
         </SixtyButton> 
          
          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={suspendLoading}
          >
            Close
          </SixtyButton>

        </footer>
      </div>
    </React.Fragment>
    </>
  );
};

export default AffiliateContentDetail;
