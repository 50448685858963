import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import CampaignShipmentTable from "../components/CampaignShipmentTable";
import CampaignOfflineTable from "../components/CampaignOfflineTable";
import CampaignVoucherTable from "../components/CampaignVoucherTable";
import CampaignPurchaseTable from "../components/CampaignPurchaseTable";
import InfluencerShipmentDetails from "../components/InfluencerShipmentDetails";
import styles from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import BasicPageHeader from "../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetCampaignSellerRequestCount, useGetCampaignAnalytics } from "queries/campaign";

const CampaignShipment = () => {
  const [isSellerDetailOpen, setSellerDetailOpen] = useState(false);
  const history = useHistory();
  const params = useParams();
  const sellerDetailRef = useRef();
  const [selectedSeller, setSelectedSeller] = useState({});

  const {
    data: requestData,
  } = useGetCampaignSellerRequestCount(params.id);

  const { data: campaignData, isLoading: campaignLoading } = useGetCampaignAnalytics(params.id);

  const SellerDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedSeller.sellerName} />
          </div>
          <div className={styles.modalTitle}>{selectedSeller.sellerName}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setSellerDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onInfluencerClick = (value) => {
    setSelectedSeller(value);
    setSellerDetailOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <BasicPageHeader title="Shipment / Appoinment" onGoBack={onGoBack} />
      <Panel className={styles.panelWrap}>
        <div className={styles.vendorTable}>
          {["Offline"].includes(campaignData?.campaignType) && (
            <>
               <CampaignOfflineTable onInfluencerClick={onInfluencerClick} />
            </>
          )}
          {["Standard", "WebsiteReview"].includes(campaignData?.campaignType) && 
           ["Manual"].includes(campaignData?.shipmentType) && (
            <>
             <CampaignShipmentTable onInfluencerClick={onInfluencerClick} />
            </>
           )}
          {["Standard", "WebsiteReview"].includes(campaignData?.campaignType) && 
           ["VoucherCode"].includes(campaignData?.shipmentType) && (
            <>
               <CampaignVoucherTable onInfluencerClick={onInfluencerClick} />
            </>
          )}
          {["Standard", "WebsiteReview"].includes(campaignData?.campaignType) && 
           ["Purchase"].includes(campaignData?.shipmentType) && (
            <>
               <CampaignPurchaseTable onInfluencerClick={onInfluencerClick} />
            </>
          )}
        </div>
      </Panel>
      {isSellerDetailOpen && (
        <Modal
          isOpen={isSellerDetailOpen}
          onAttemptClose={() => {
            setSellerDetailOpen(false);
          }}
          modalRef={sellerDetailRef}
          Title={SellerDetailTitle}
        >
          <InfluencerShipmentDetails
            selectedSeller={selectedSeller}
            campaign={campaignData}
            handleClose={() => setSellerDetailOpen(false)}
            viewOnly={false}
          />
        </Modal>
      )}
    </div>
  );
};

export default CampaignShipment;
