import Input from "components/SixtyInput";

import {
  FilterGroup,
  FilterItem,
  FilterRow,
  FilterChipContainer,
  ChipItem,
} from "components/SixtyTable/components/Filter";

import _omit from "lodash/omit";
import { useGetPriceTierDropdown } from "queries/product";
import SixtySelectField from "components/SixtySelect";

export const labelMap = {
  filterByMinDefaultDiscounts: (state) => "Min default discounts: " + state,
  filterByMaxDefaultDiscounts: (state) => "Max default discounts: " + state,
};

export default function SellerTierFilter({ state, setState }) {
  const { data: priceTierData, isLoading } = useGetPriceTierDropdown({
    staleTime: 60 * 1000,
  });

  const options =
    priceTierData?.length > 0
      ? priceTierData.map((category) => ({
          label: category.name,
          value: category.id,
        }))
      : [];

  const handleInputChange = (field, value) => {
    setState((filter) => {
      if (value) filter[field] = value;
      else {
        delete filter[field];
      }
    });
  };

  const handleTierSelect = (value) => {
    setState((filter) => {
      filter.filterByPriceTierId = value;
      filter.tierName = options.find((option) => option.value === value).label;
    });
  };

  return (
    <>
      <FilterRow>
        <FilterGroup label="Tiers" colWidth={2}>
          <FilterItem fullWidth>
            <SixtySelectField
              options={options}
              defaultOption={isLoading ? "fetching options..." : "Tier Name"}
              value={state?.filterByPriceTierId}
              onChange={(value) => handleTierSelect(value)}
              disableDropIcon
              fullWidth
            />
          </FilterItem>
        </FilterGroup>
      </FilterRow>
      <FilterRow>
        <FilterGroup label="Default discounts" colWidth={2}>
          <FilterItem colWidth={2}>
            <Input
              label="Minimum %"
              value={state?.filterByMinDefaultDiscounts}
              type="number"
              step=".01"
              onChange={(e) => {
                handleInputChange(
                  "filterByMinDefaultDiscounts",
                  e.target.value
                );
              }}
              max={100}
              min={0}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <Input
              label="Maximum %"
              value={state?.filterByMaxDefaultDiscounts}
              type="number"
              step=".01"
              onChange={(e) => {
                handleInputChange(
                  "filterByMaxDefaultDiscounts",
                  e.target.value
                );
              }}
              max={100}
              min={0}
            />
          </FilterItem>
        </FilterGroup>
      </FilterRow>
    </>
  );
}

export function renderFilterChips(filterState, setFilterState) {
  let filtersPresent = false;
  for (const filterKey in filterState) {
    if (!!filterState[filterKey]) {
      filtersPresent = true;
    }
  }

  const removeFilter = (filterItem) => {
    setFilterState((filter) => {
      delete filter[filterItem];
    });
  };

  const removeTier = () => {
    setFilterState((filter) => {
      delete filter.filterByPriceTierId;
      delete filter.tierName;
    });
  };

  if (filtersPresent) {
    let otherFilters = _omit(filterState, ["tierName", "filterByPriceTierId"]);

    let tierFilter = filterState.tierName;
    return (
      <FilterChipContainer>
        {Object.keys(otherFilters).length > 0 &&
          Object.keys(otherFilters)?.map((filterItem) => (
            <>
              <ChipItem
                label={labelMap[filterItem](filterState[filterItem])}
                name={filterItem}
                handleRemove={removeFilter}
                key={filterItem}
              />
            </>
          ))}

        {tierFilter && (
          <>
            <ChipItem
              label={`Tier is ${filterState.tierName}`}
              name={"tierName"}
              handleRemove={removeTier}
            />
          </>
        )}
      </FilterChipContainer>
    );
  }
  return null;
}
