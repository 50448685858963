import { useGenerateDiscountCode } from "queries/discount";
import { useGetDiscountStatusDropdown } from "queries/product";
import { useFormContext } from "react-hook-form";

import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import SixtyButton from "components/SixtyButton";

import styles from "./index.module.css";

const BasicInfo = () => {
  // status options
  const { data: statusOptionsData } = useGetDiscountStatusDropdown();
  const statusOptions = statusOptionsData?.length
    ? [
        { label: "None", value: null },
        ...statusOptionsData.map((option) => ({
          label: option.name,
          value: option.key,
        })),
      ]
    : [];
  
  const { control, errors, setValue } = useFormContext();

  // generate discount code
  const {
    mutateAsync: generateCode,
    isLoading: generatingCode,
  } = useGenerateDiscountCode();

  const handleGenerateCode = async () => {
    if (!generatingCode) {
      const result = await generateCode();
      if (result?.code) {
        setValue("discountCode", result.code);
      }
    }
  };

  return (
    <div className={styles.form}>
      <Input
        name="name"
        control={control}
        label="Name"
        error={errors.name}
        defaultValue=""
      />
      <Input
        name="description"
        control={control}
        label="Description"
        error={errors.description}
        lines={4}
        defaultValue=""
      />
      <div className={styles.discountCodeContainer}>
        <Input
          name="discountCode"
          control={control}
          label="Discount code"
          error={errors.discountCode}
          defaultValue=""
          className={styles.discountCodeHelper}
        />
        <SixtyButton variant="text" onClick={handleGenerateCode}>
          Generate code
        </SixtyButton>
      </div>
      <Select
        name="status"
        label="Status"
        error={errors.status}
        options={statusOptions}
        control={control}
      />
    </div>
  );
};

export default BasicInfo;
