import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useUpdateStatus } from "queries/posting";

import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import ControlledSelect from "components/FormComponents/ControlledSelect";
import Input from "components/FormComponents/ControlledInput";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import { useEffect } from "react";
import { useState } from "react";
import Text from "components/SixtyText";

const defaultValues = {
  rejectionReason: "",
  message: "",
};

const ContentRepostForm = ({ selectedContent, handleClose }) => {

  const formMethods = useForm({
    defaultValues,
  });

  const { mutateAsync: updatePostStatus, isLoading: statusLoading } = useUpdateStatus();


  const onSubmit = async (data) => {

    const { id } = selectedContent;
    try {

      let reason = data.rejectReason;

      var model = {
        id: id,
        status: "Repost",
        reason: reason
      };

      const result = await updatePostStatus(model);
      if (result) {
        handleClose();
        setToast("successfully update status");
      }
    } catch (e) {
      handleClose();
      reportError("error in : ContentRejectForm: onRejectContent, error: ", e);
    }
  };
  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.contentRejectForm}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={styles.sellerRejectForm}>
            <div className={styles.margin}></div>
              <Text type="p1" color="secondary">
                  if posted content on social media e.g instagram, twitter not fit your requirement, you may request creator reposting the content.                           
              </Text>
            <div className={styles.margin}></div>
            <div className={styles.rejectionReasonContainer}>
                  <Input
                    name="rejectReason"
                    placeholder="Reason to creator"
                    label="Reason to creator for unqualified content"
                    lines="5"
                  />
            </div>
            </div>
            <footer className={styles.footer}>
              <SixtyButton
                type="submit"
                variant="warning"
                className={styles.rejectBtn}               
                disabled={statusLoading}
              >
                Request to repost
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={handleClose}
                disabled={statusLoading}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default ContentRepostForm;
