import React, { useState, useEffect, useRef } from "react";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useGetCampaignPriceTypeDropdown, 
         useGetCampaignStatusDropdown,
         useGetVendorProductDropdown,
         useGetSellerDropdown,
         useGetSocialMediaDropdown,
         useGetAudiencePreferenceDropdown,
         useGetCampaignRewardRequirementDropdown  } from "queries/admin";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import ControlledChipInput from "components/FormComponents/ControlledChipInput";
import SixtySelect from "components/SixtySelect";
import Checkbox from "components/SixtyCheckBox";
import DatePicker from "components/SixtyDatePicker";
import { format, parse } from "date-fns";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";
import SixtyButton from "components/SixtyButton";
import { setToast } from "components/Toast";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import clsx from "clsx";
import { reportError } from "crashlytics";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import { ReactComponent as IconSingleImageDrop } from "assets/icons/Icon-SingleImageDrop.svg";
import Button from "components/SixtyButton";
import { isEmpty, isArray } from 'lodash';

const defaultFieldValues = {
  id: null,
  requirementType: '',
  requirementAmount: 0,
  reward: 0,
 };

const CampaignEditInfo = ({selectedCampaign}) => {

  const { vendorCode, 
          name,
          socialMediaId,
          campaignType,
          priceType,
          isPublic,
          channelInvitationLink,
          instruction,
          status,
          applicationLimit,
          defaultCampaignImagePath,
          picContact,
          redemptionType,
          redemptionReferenceUrl,
          deliverableType,
          deliverableReferenceUrl,
          shipmentType,
          appointmentType,
          appointmentLocation,
          isRequiredPurchaseProduct,
          productPurchasePrice,
          shippingCoveredCost,
          purchaseLink,
          isCoveredShippingCost,
          isRequiredEcommerceReview,
          isRequiredDraftReview,
          isProfitSharing,
          postingExpiredOnUtc,
          tasks,
          guidelines,
          campaignProducts,
          campaignSellers,
          campaignAudiences } = selectedCampaign;
  
  const { control, watch, setValue, errors } = useFormContext();

  // products 
  const { fields, append, remove } = useFieldArray({
        control,
        name: "campaignProducts",
  });
  
  const _campaignProducts = watch("campaignProducts");

  const [socialMediaType, setSocialMediaType] = useState();
  const [defaultCampaignAudience, setDefaultCampaignAudience] = useState([]);
  const [_campaignType, setCampaignType] = useState('');
  const [product, setProduct] = useState('');

  // sellers
  const { fields : sellerFields, append : sellerAppend, remove : sellerRemove } = useFieldArray({
    control,
    name: "campaignSellers",
  });

  const _campaignSellers = watch("campaignSellers");

  // audiences
  const { fields : audienceFields, append : audienceAppend, remove : audienceRemove } = useFieldArray({
    control,
    name: "campaignAudiences",
  });
  
  const _campaignAudiences = watch("campaignAudiences");

  // additional rewards
   const { fields : rewardFields, append: appendReward, remove: removeReward } = useFieldArray({
     control,
     name: "additionalRewards",
   });
  
  const _additionalRewards = watch("additionalRewards");
 
  const [seller, setSeller] = useState('');
  const [sellerPriceType, setPriceType] = useState("");
  const [progressStatus, setStatus] = useState("");
  const [publicCampaign, setPublic] = useState(isPublic);
  const [publicSetting, setPublicSetting] = useState("false");
  const [draftReview, setDraftReview] = useState(false);
  const [profitSharing, setProfitSharing] = useState(false);
  const [ecommerceReview, setEcommerceReview] = useState(false);
  const [coveredShippingCost, setCoveredShippingCost] = useState(false);
  const [_shipmentType, setShipmentType] = useState('');
  const [_deliverableType, setDeliverableType] = useState('');
  const [_appointmentType, setAppointmentType] = useState('');

   // product dropdown option query
    const [keyword, setKeyword] = useState("");

    const { data: rewardRequirementOptions } = useGetCampaignRewardRequirementDropdown();
 
    const requirementTypeOptions = rewardRequirementOptions?.length
     ?      
         rewardRequirementOptions.map((option) => ({
           label: option.name,
           value: option.key,
         }))
       
     : [];
 
    const { data: campaignPriceTypeOptions, isLoading: isLoadingPriceType } = useGetCampaignPriceTypeDropdown();
 
    const priceTypeOptions = campaignPriceTypeOptions?.length
     ? [
         { value: null, label: "None" },
         ...campaignPriceTypeOptions.map((option) => ({
           label: option.name,
           value: option.key,
         })),
       ]
     : [];

     const { data: campaignStatusOptions } = useGetCampaignStatusDropdown();
 
     const statusOptions = campaignStatusOptions?.length
      ? [
          { value: null, label: "None" },
          ...campaignStatusOptions.map((option) => ({
            label: option.name,
            value: option.key,
          })),
        ]
      : [];

      const { data: audiencePreferenceOptions, isLoading: isAudienceLoading } = useGetAudiencePreferenceDropdown();
 
      const audienceOptions = audiencePreferenceOptions?.length
       ? audiencePreferenceOptions.map((item) => {
           return { value: item.id, label: item.name };
         })
       : [];
   
      const { data: socialMediaOptions, isLoading: isSocialLoading } = useGetSocialMediaDropdown();
  
      const mediaOptions = socialMediaOptions?.length
       ? [
           { value: null, label: "None" },
           ...socialMediaOptions.map((option) => ({
             label: option.name,
             value: option.id,
           })),
         ]
       : [];

      const onBrowseProfile = async (id) => {
        try {
         
          window.open(`/admin/influencers/AnalyticDetails/${id}`, "_blank")
            
        } catch (e) {
          reportError(
            "error in : CampaignDetail: onBrowseProfile, error: ",
            e
          );
        }
      };

      useEffect(() => {
        setPublic(isPublic);
        setPriceType(priceType);
        setStatus(status);
        setCampaignType(campaignType);
        setSocialMediaType(socialMediaId);
        setDraftReview(isRequiredDraftReview);
        setEcommerceReview(isRequiredEcommerceReview);
        setProfitSharing(isProfitSharing);
        setCoveredShippingCost(isCoveredShippingCost);
        setShipmentType(shipmentType);
        setDeliverableType(deliverableType);
        setAppointmentType(appointmentType);
    }, []);

     useEffect(() => {

        if(publicCampaign){
            control.setValue('isPublic', true);
        }     
        else{
            control.setValue('isPublic', false);
        }

        if(draftReview){
            control.setValue('isRequiredDraftReview', true);
        }
        else{
            control.setValue('isRequiredDraftReview', false);
        }

        if(profitSharing){
             control.setValue('isProfitSharing', true);
        }
        else{
             control.setValue('isProfitSharing', false);
        }

        if(coveredShippingCost){
             control.setValue('isCoveredShippingCost', true);
        }
        else{
             control.setValue('isCoveredShippingCost', false);
        }

        if(ecommerceReview){
          control.setValue('isRequiredEcommerceReview', true);
        }
        else{
          control.setValue('isRequiredEcommerceReview', false);
        }

        // campaign audience multi select mapping
        if(audienceOptions?.length > 0 && defaultCampaignAudience?.length <= 0){
          let options = campaignAudiences?.length
          ? campaignAudiences?.map((item) => {

            let name = audienceOptions.find(
              (option) => option.value === item.audienceId
            )?.label;

              return { audienceId: item.audienceId, audienceName: name };
            })
          : [];

          setDefaultCampaignAudience(options);
          control.setValue('campaignAudiences', options);
        }
      
    }, [publicCampaign, draftReview, profitSharing, coveredShippingCost, ecommerceReview, audienceOptions], [fields]);

    const openDropzone = useRef(() => {});
    const mainImage = useWatch({
      control,
      name: "mainImages",
      defaultValue: [],
    });

    const onMainImageDelete = () => {
      setValue("mainImages", []);
    };
    const imageSelected = typeof(mainImage) === 'string' ?
    [{
      path: mainImage
    }] :
    isArray(mainImage) ? mainImage : [];

  return (
    <div className={styles.form}>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Campaign Image</div>
      <hr className={styles.hr} />
  
      <ImageDropzone
           control={control}
           error={errors.mainImages}
           name="mainImages"
           title="Drag your image here"
           subTitle="or Click to browse for a file"
           DefaultThumb={IconSingleImageDrop}
           maximumFiles={1}
           onDropAccepted={(name, selectedFiles) => {
             setValue("mainImages", selectedFiles);
             setValue("isNewUpdate", 'true');
           }}
           setOpenDropzonehook={(open) => {
             openDropzone.current = open;
           }}
           selectedFiles={imageSelected}
      />
      <Input
           name={`isNewUpdate`}
           control={control}
           type="hidden"
           label="Visibility"
           defaultValue={'false'}
           value={'false'}
           className={styles.hidden}
      />
      {mainImage && mainImage.length > 0 && (
            <div className={styles.actionButtonsContainer}>
              <div>
                <Button
                  onClick={() => openDropzone.current()}
                  variant="secondary"
                >
                  Replace
                </Button>
              </div>
              <div>
                <Button onClick={onMainImageDelete} variant="secondary">
                  Delete
                </Button>
              </div>
            </div>
      )}
      <div className={styles.section}>Campaign Information</div>
      <div className={styles.fullFormRow}>
         <Input
           name="name"
           label="Campaign Title"
           defaultValue={name}
         />
      </div>
      <div className={styles.formRow}>
         <Input
           name="applicantLimit"
           label="Campaign Limit e.g 10"
           defaultValue={applicationLimit}
         />
        <Input
           name="picContact"
           label="Contact number e.g 0145645678"
           defaultValue={picContact}
         />
      </div>
       <div className={styles.fullFormRow}>
         <Input
           name="channelInvitationLink"
           label="Group Invitation Link e.g Tiktok affiliate invitation link"
           defaultValue={channelInvitationLink}
         />
      </div> 
      {/* {mediaOptions && mediaOptions.length > 0 &&(
        <div className={styles.fullFormRow}>
            <Select
                name="socialMediaId"
                error={errors.socialMediaId}
                options={mediaOptions}
                control={control}
                fullWidth
                defaultOption="Select an social media"
                handleChange={(name, value) => {
                  setSocialMediaType(value);
                }}
                value={socialMediaType}
                defaultValue={socialMediaType}
              />    
         </div>   
      )} */}

    <div className={styles.formRow}>
        <ControlledDatepicker
          name="postingExpiredOnUtc"
          error={errors?.postingExpiredOnUtc}
          label="Posting Date"
          control={control}
          dateFormat="dd/MM/yyyy"
          minDate={new Date()}
        />
        <ControlledDatepicker
          name="expiredDate"
          error={errors?.expiredDate}
          label="Expired date"
          control={control}
          dateFormat="dd/MM/yyyy"
          minDate={new Date()}
        />
      </div>

     {audienceOptions && audienceOptions.length > 0 &&(
        <div className={styles.fullFormRow}>
            <ControlledChipInput
              name="campaignAudiences"
              control={control}
              error={errors.campaignAudiences}
              label="Target Audiences"
              fullWidth
              defaultValue={[]}
              helperText="Add this campaign to one or more audiences"
              handleChange={(value, options) =>          
                value.map((id) => {
                  return { audienceId: id.value, audienceName: id.label};
                })
              }
              getValue={(value) => 
                 value?.map((item) => { return {value: item.audienceId, label: item.audienceName}})
              }
              options={audienceOptions}
              optionsLoading={isAudienceLoading}
              isMultiSelect={true}
            />  
         </div>   
      )}
      <div className={styles.formRow}>
        {statusOptions && statusOptions.length > 0 && (
            <Select
                name="status"
                error={errors.status}
                options={statusOptions}
                control={control}
                fullWidth
                defaultOption="Select an progress status"
                handleChange={(name, value) => {
                  setStatus(value);
                }}
                value={progressStatus}
                defaultValue={progressStatus}
          />
        )}
      </div>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Settings</div>
      <hr className={styles.hr} />
      <div className={styles.formRow}>
      <Checkbox
          label="Set as public campaign"
          checked={publicCampaign}
          onChange={(e) => setPublic(e.target.checked)}
          uncheckStyleVariant="greyedOut"
        />
        <Input
           name={`isPublic`}
           control={control}
           type="hidden"
           label="Visibility"
           defaultValue={'false'}
           value={'false'}
           className={styles.hidden}
        />
      </div>
      <div className={styles.formRow}>
        <Checkbox
          label="Required draft review"
          checked={draftReview}
          onChange={(e) => setDraftReview(e.target.checked)}
          uncheckStyleVariant="greyedOut"
        />
        <Input
           name={`isRequiredDraftReview`}
           control={control}
           type="hidden"
           label="Visibility"
           defaultValue={'false'}
           value={'false'}
           className={styles.hidden}
        />
      </div>
      <div className={styles.formRow}>
        <Checkbox
          label="Profit sharing"
          checked={profitSharing}
          onChange={(e) => setProfitSharing(e.target.checked)}
          uncheckStyleVariant="greyedOut"
        />
        <Input
           name={`isProfitSharing`}
           control={control}
           type="hidden"
           label="Visibility"
           defaultValue={'false'}
           value={'false'}
           className={styles.hidden}
        />
      </div>
      <div style={{height:15}} ></div>
      <div className={styles.formRow}>
          <Select
             name="campaignType"
             error={errors.target}
             options={[
               {
                 label: "Online",
                 selectedLabel: "Online",
                 value: "Standard",
               },
               {
                  label: "Offline",
                  selectedLabel: "Offline",
                  value: "Offline",
               },
               {
                label: "Shopee Review",
                selectedLabel: "Shopee Review",
                value: "WebsiteReview",
             },
             ]}
             control={control}
             fullWidth
             defaultOption="Select campaign type"
             handleChange={(name, value) => {
               setCampaignType(value);
             }}
             value={_campaignType}
             defaultValue={_campaignType}
          />
          {_campaignType == 'AffiliateEvent' && (
              <Input
              name="totalCommission"
              error={errors.totalCommission}
              control={control}
              label="Campaign Commission %"
              type="number"
              min={0}
              step=".01"
              defaultValue={100}
              />
          )}      
      </div>
      {_campaignType == 'WebsiteReview' && (
            <div className={styles.formRow}>
              <div className={styles.section}>* Influencers write good ratings and review on shopee once received the product</div>
            </div>
      )}

      {['Standard','WebsiteReview'].includes(_campaignType) && (
         <>
            <div style={{height:15}} ></div>
            <div className={styles.section}>Shipment</div>
            <hr className={styles.hr} />
            <div className={styles.fullFormRow}>
                <Select
                  name="shipmentType"
                  error={errors.target}
                  options={[
                    {
                      label: "None",
                      selectedLabel: "None",
                      value: "None",
                    },
                    {
                      label: "Manual",
                      selectedLabel: "Manual",
                      value: "Manual",
                    },
                    {
                      label: "Shopee voucher code",
                      selectedLabel: "Shopee voucher code",
                      value: "VoucherCode",
                    },
                    {
                      label: "Purchase",
                      selectedLabel: "Purchase",
                      value: "Purchase",
                    },
                  ]}
                  control={control}
                  fullWidth
                  defaultOption="Select shipment type"
                  handleChange={(name, value) => {
                    setShipmentType(value);
                  }}
                  value={_shipmentType}
                  defaultValue={_shipmentType}
                />
            </div>
            {['Purchase'].includes(_shipmentType) && (
            <>
              <div className={styles.fullFormRow}>
                <div className={styles.section}>* Influencers purchase the product by themselves to start the campaign</div>
              </div>
              <div className={styles.fullFormRow}>
                  <Input
                    name={`purchaseLink`}
                    control={control}
                    label="Product Link e.g https://shopee.com.my/product/863838974/22217367413"
                    value={purchaseLink}
                    defaultValue={purchaseLink}
                  />
              </div>
              <div className={styles.fullFormRow}>
                <div className={styles.section}>* Product to be purchase by influencer through website</div>
              </div>
              <div className={styles.fullFormRow}>
                  <Input
                    name={`productPurchasePrice`}
                    control={control}
                    label="Product price"
                    type="number"
                    min={0}
                    value={productPurchasePrice}
                    defaultValue={productPurchasePrice}
                  />
              </div>
              <div className={styles.fullFormRow}>
                <div className={styles.section}>* Price of the product, influencer can claim back the fees from 60sec once complete the campaign</div>
              </div>
              <div className={styles.fullFormRow}>
                  <Input
                    name={`shippingCoveredCost`}
                    control={control}
                    label="Covered shipping cost"
                    type="number"
                    min={0}
                    value={shippingCoveredCost}
                    defaultValue={shippingCoveredCost}
                  />
              </div>
              <div className={styles.fullFormRow}>
                <div className={styles.section}>* Shipping costs you want to cover for influencer e.g MYR 10 per order</div>
              </div>
            </>
          )}

          {['VoucherCode'].includes(_shipmentType) && (
              <>
                <div className={styles.fullFormRow}>
                  <div className={styles.section}>* Influencers redeem the product with provided voucher code on your website</div>
                </div>
                <div className={styles.fullFormRow}>
                    <Checkbox
                      label="Cover shipping cost on shopee"
                      checked={coveredShippingCost}
                      onChange={(e) => setCoveredShippingCost(e.target.checked)}
                      uncheckStyleVariant="greyedOut"
                    />
                    <Input
                      name={`isCoveredShippingCost`}
                      control={control}
                      type="hidden"
                      label="Visibility"
                      defaultValue={'false'}
                      value={'false'}
                      className={styles.hidden}
                    />
                </div>
                <div className={styles.fullFormRow}>
                  <div className={styles.section}>* On shopee set seller covered shipping cost for product you want influencer redeem</div>
                </div>
                {!coveredShippingCost && (
                  <>
                    <div className={styles.fullFormRow}>
                        <Input
                          name={`shippingCoveredCost`}
                          control={control}
                          label="Covered shipping cost"
                          type="number"
                          min={0}
                          value={shippingCoveredCost}
                          defaultValue={shippingCoveredCost}
                        />
                    </div>
                    <div className={styles.fullFormRow}>
                      <div className={styles.section}>* Shipping costs you want to cover for influencer e.g MYR 10 per order</div>
                    </div>
                  </>
                )}
              </>
          )}

          {['Manual'].includes(_shipmentType) && (
              <div className={styles.fullFormRow}>
                <div className={styles.section}>* You manually sent product to influencers with their provided shipping information</div>
              </div>
          )}
          {['Purchase','VoucherCode'].includes(_shipmentType) && (
            <>
              <div className={styles.formRow}>
                <Checkbox
                  label="Shopee ratings & review"
                  checked={ecommerceReview}
                  onChange={(e) => setEcommerceReview(e.target.checked)}
                  uncheckStyleVariant="greyedOut"
                />
                <Input
                  name={`isRequiredEcommerceReview`}
                  control={control}
                  type="hidden"
                  label="Visibility"
                  defaultValue={'false'}
                  value={'false'}
                  className={styles.hidden}
                />
              </div>
              <div className={styles.fullFormRow}>
                  <div className={styles.section}>* Only applicable if influencers purchase or redeem product from shopee</div>
              </div>
            </>
          )}
         </>
     )}


      {['Offline'].includes(_campaignType) && (
         <>
              <div style={{height:15}} ></div>
              <div className={styles.section}>Appointment Type</div>
              <hr className={styles.hr} />
              <div className={styles.fullFormRow}>
                  <Select
                    name="appointmentType"
                    error={errors.target}
                    options={[
                      {
                        label: "Whatapps",
                        selectedLabel: "Whatapps",
                        value: "Whatapps",
                      },
                    ]}
                    control={control}
                    fullWidth
                    defaultOption="Select appointment method"
                    handleChange={(name, value) => {
                      setAppointmentType(value);
                    }}
                    value={_appointmentType}
                    defaultValue={_appointmentType}
                  />
              </div>

              {_appointmentType == 'Whatapps' && (
                <div className={styles.fullFormRow}>
                  <div className={styles.section}>* Influencers contact you through whatapps for appointment arrangement</div>
                </div>
              )}
              <div style={{height:15}} ></div>
              <div className={styles.section}>Location to be visit</div>
              <hr className={styles.hr} />
              <div className={styles.fullFormRow}>
                <Input
                  name="appointmentLocation"
                  label="Address"
                  lines={6}
                  value={appointmentLocation}
                  defaultValue={appointmentLocation}
                />
              </div>
         </>
     )}

     {['Standard','Offline'].includes(_campaignType) && (
           <>
              <div style={{height:15}} ></div>
              <div className={styles.section}>Social Media</div>
              <hr className={styles.hr} />
              <div className={styles.formRow}>
                  <Select
                    name="deliverableType"
                    error={errors.target}
                    options={[
                      {
                        label: "Carousels",
                        selectedLabel: "Carousels",
                        value: "Carousels",
                      },
                      {
                          label: "Video",
                          selectedLabel: "Video",
                          value: "Video",
                      },
                    ]}
                    control={control}
                    fullWidth
                    defaultOption="Select deliverables"
                    handleChange={(name, value) => {
                      setDeliverableType(value);
                    }}
                    value={_deliverableType}
                    defaultValue={_deliverableType}
                  />
                  {mediaOptions && mediaOptions.length > 0  && (
                    <>
                      <Select
                        name="socialMediaId"
                        error={errors.socialMediaId}
                        options={mediaOptions}
                        control={control}
                        fullWidth
                        defaultOption="Select an social media"
                        handleChange={(name, value) => {
                          setSocialMediaType(value);
                        }}
                        value={socialMediaType}
                        defaultValue={socialMediaType}
                      />
                    </>
                  )}

              </div>    
              <div className={styles.fullFormRow}>
                <Input
                  name="deliverableReferenceUrl"
                  label="Sample of reference e.g https://www.instagram.com/"
                  value={deliverableReferenceUrl}
                  defaultValue={deliverableReferenceUrl}
                />
              </div>    
              <div className={styles.fullFormRow}>
                  <div className={styles.section}>* Feel free to provide sample of reference for influencer to deliver your prefered content</div>
              </div> 
           </>
      )}

     <div style={{height:15}} ></div>
      <div className={styles.section}>Recruitment Requirement</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
        <Input
           name="instruction"
           label="Requirements"
           lines={6}
           value={instruction}
           defaultValue={instruction}
         />
      </div>

      <div style={{height:15}} ></div>
      <div className={styles.section}>Task Details</div>
      <div className={styles.fullFormRow}>
        <Input
           name="tasks"
           label="Tasks"
           lines={6}
           defaultValue={tasks}
         />
      </div>
      <div className={styles.section}>Guidelines Details</div>
      <div className={styles.fullFormRow}>
        <Input
           name="guidelines"
           label="Guidelines"
           lines={6}
           defaultValue={guidelines}
         />
      </div>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Rewards</div>
      <div className={styles.formRow}>
      <Input
          name="price"
          error={errors.price}
          control={control}
          label={sellerPriceType == "Commission" ? "Paid Campaign %" : "Paid Campaign RM"}
          type="number"
          min={0}
          step=".01"
          defaultValue={100}
        />
        {priceTypeOptions && priceTypeOptions.length > 0 && (
          <Select
              name="priceType"
              error={errors.priceType}
              options={priceTypeOptions}
              control={control}
              fullWidth
              defaultOption="Select an reward measurement"
              handleChange={(name, value) => {
                setPriceType(value);
              }}
              value={sellerPriceType}
              defaultValue={sellerPriceType}
          />
        )}

      </div>

      {sellerPriceType == 'Fixed' && (
        <>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Additional Rewards</div>
      <hr className={styles.hr} />
      <div>
        {rewardFields && 
         requirementTypeOptions &&
         requirementTypeOptions.length > 0 &&
         rewardFields.map((field, index) => (
          <React.Fragment key={field.id}>
              <div className={styles.fullFormRow}>
                  <Select
                    name={`additionalRewards[${index}].requirementType`}
                    options={requirementTypeOptions}
                    control={control}
                    fullWidth
                    defaultOption="Select an requirement type"
                    defaultValue={rewardFields[index].requirementType}
                  />
                 <Input
                    name={`additionalRewards[${index}].id`}
                    control={control}
                    type="hidden"
                    label="Unique Id"
                    lines={3}
                    defaultValue={rewardFields[index].id}
                    value={rewardFields[index].id}
                    className={styles.hidden}
                 />
                 <div style={{margin:15}}>
                    <SixtyButton
                      variant="text"
                      onClick={(e) => {
                        removeReward(index);
                      }}
                    >
                      Remove
                    </SixtyButton>
                </div>
              </div>
              <div className={styles.fullFormRow}>
                  <Input
                    name={`additionalRewards[${index}].requirementAmount`}
                    control={control}
                    label="Requirement Value"
                    type="number"
                    min={0}
                    defaultValue={rewardFields[index].requirementAmount}
                    value={rewardFields[index].requirementAmount}
                  />
              </div>
              <div className={styles.fullFormRow}>
                  <Input
                    name={`additionalRewards[${index}].reward`}
                    control={control}
                    label="Reward RM"
                    type="number"
                    min={0}
                    step=".01"
                    defaultValue={rewardFields[index].reward}
                    value={rewardFields[index].reward}
                  />
              </div>
              <hr className={styles.hr} />
          </React.Fragment>
        ))}
        <SixtyButton
          variant="secondary"
          onClick={(e) => appendReward({ ...defaultFieldValues })}
        >
           Add new reward 
        </SixtyButton>
      </div>
        </>
      )}
    </div>
  );
};

export default CampaignEditInfo;
