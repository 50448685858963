import { useState, useEffect, useCallback } from "react";
import { useSetupUserAuthentication } from "queries/account";
import {
  useCheckVendorOnboardingStatus,
  useSetupVendorAccountInfo,
} from "queries/vendor";
import { reportError } from "crashlytics";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { useImmer } from "utils/hooks/useImmer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import getParameterByName from "utils/getParamsByName";

import Text from "components/SixtyText";
import Button from "components/SixtyButton";
import Stepper from "components/Stepper";
import Link from "components/SixtyLink";
import UserAuthenticationContainer from "../components/UserAuthenticationContainer";
import ProfileSetupLayout from "routes/layouts/ProfileSetupLayout";
import StepOneForm from "./components/StepOne";
import StepTwoForm from "./components/StepTwo";
import { useGetVendorUserDetails } from "queries/vendor";
import { useParams } from "react-router-dom";

import styles from "./index.module.css";

export default function SetupProfile() {
  const email = getParameterByName("email");
  const referral = localStorage.getItem("AgencyReferral");
  const { data: vendorData } = useGetVendorUserDetails();
  const [isCreateAccount, setCreateAccount] = useState(false);

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const { data: onBoardingStatus } = useCheckVendorOnboardingStatus();

  const [stepOneValues, setStepOneValues] = useImmer({
    name: "",
    userName: "",
    email: email || "",
    countryCode: "+60",
    mobile: "",
    password: "",
    repeatPassword: "",
    dob: "",
    gender: "",
  });
  //  step 2

  const [stepTwoValues, setStepTwoValues] = useImmer({
    companyName: "",
    businessType: "",
    street1: "",
    street2: "",
    country: "",
    postcode: "",
    state: "",
    city: "",
  });

  const schema = yup.object().shape({
    owner: yup.object().shape({
      name: yup.string().required("Enter full name"),
      // userName: yup.string().required("Enter a user name"),
      email: yup.string().required("Enter business email address"),
      countryCode: yup.string().required("*"),
      mobile: yup.string().required("Enter contact"),
      password: yup.string().required("Enter a password"),
      repeatPassword: yup
        .string()
        .required("Please confirm password")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
      dob: yup
        .string()
        .required("Enter date of birth")
        .test("validDob", "Enter a valid date", (val) => {
          return true;
        }),
      gender: yup.string().required("Enter gender"),
    }),
    company: yup.object().shape({
      // brand: yup.string().required("Enter brand name"),
      companyName: yup.string().required("Enter company name"),
      businessType: yup.string().required("Enter bussiness type"),
      // buildingName: yup.string().required("Enter building name"),
      // unitNumber: yup.string().required("Enter unit number"),
      // floorNumber: yup.string().required("Enter floor number"),
      street1: yup.string().required("Enter street 1"),
      street2: yup.string(),
      country: yup.string().required("Enter country"),
      postcode: yup.string().required("Enter post code"),
      state: yup.string().required("Enter state"),
      city: yup.string().required("Enter city"),
    }),
  });

  const {
    handleSubmit,
    control,
    setValue,
    errors,
    trigger,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      owner: stepOneValues,
      company: stepTwoValues,
    },
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const handleStepOneChange = useCallback(
    (name, value) => {
      let field = name.replace("owner.", "");
      setStepOneValues((state) => {
        state[field] = value;
      });
      if (dirtyFields?.owner?.name) {
        trigger("owner.name");
      }
      // if (dirtyFields?.owner?.userName) {
      //   trigger("owner.userName");
      // }
      if (dirtyFields?.owner?.email) {
        trigger("owner.email");
      }
      if (dirtyFields?.owner?.mobile) {
        trigger("owner.mobile");
      }
      if (dirtyFields?.owner?.password) {
        trigger("owner.password");
      }
      if (dirtyFields?.owner?.repeatPassword) {
        trigger("owner.repeatPassword");
      }
      if (dirtyFields?.owner?.dob) {
        trigger("owner.dob");
      }
      if (dirtyFields?.owner?.gender) {
        trigger("owner.gender");
      }
      // trigger([
      //   "owner.name",
      //   "owner.userName",
      //   "owner.email",
      //   "owner.mobile",
      //   "owner.password",
      //   "owner.repeatPassword",
      //   "owner.dob",
      //   "owner.gender",
      // ]);
    },
    [dirtyFields, setStepOneValues, trigger]
  );

  const handleStepTwoChange = (name, value) => {
    let field = name.replace("company.", "");
    setStepTwoValues((state) => {
      state[field] = value;
    });
  };

  const [progress, setProgress] = useState(["active", "notReached"]);
  const goToStepTwo = () => {
    trigger([
      "owner.name",
      "owner.email",
      "owner.mobile",
      "owner.password",
      "owner.repeatPassword",
      "owner.dob",
      "owner.gender",
    ]).then((valid) => {
      if (valid) {
        const updatedProgress = ["visited", "active"];
        setProgress(updatedProgress);
      }
    });
  };
  const goToStepOne = () => {
    const updatedProgress = ["active", "notReached"];
    setProgress(updatedProgress);
  };

  const randomUsername = (length, chars) => {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  const {
    mutateAsync: setupUserAuthentication,
    isLoading: userAuthSetupLoading,
  } = useSetupUserAuthentication();

  const { mutateAsync: setupVendorAccountInfo } = useSetupVendorAccountInfo();

  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      if (!userAuthSetupLoading)
          setCreateAccount(true);
     
          await setupVendorAccountInfo({
          referralCode: referral ?? null,
          company: {
            // brand: data?.company?.brand,
            companyName: data.company.companyName,
            businessType: data.company.businessType,
            companyContact: data.owner.countryCode + data.owner.mobile,
            unitNumber: data.company.unitNumber,
            floorNumber: data.company.floorNumber,
            buildingName: data.company.buildingName,
            street1: data.company.street1,
            street2: data.company.street2,
            postcode: data.company.postcode,
            city: data.company.city,
            state: data.company.state,
            country: data.company.country,
          },
          owner: {
            name: data.owner.name,
            gender: data.owner.gender,
            dateOfBirth:
              data.owner.dob && format(new Date(data.owner.dob), "dd/MM/yyyy"),
          },
        });

    } catch (error) {
      reportError("SetupVendorAccountInfo: onSubmit: error: ", error);
      setCreateAccount(false);
    }

    try {
      // temp solutions
      var rsUsername = randomUsername(8, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

      const result = await setupUserAuthentication({
        username: rsUsername,
        password: data.owner.password,
      });

      await timeout(2000); 

      if (result) {
        //history.push("/pricing-plan");
        history.push("/dashboard");
      }
    } catch (error) {
      reportError("SetupUserAuthentication: onSubmit: error: ", error);
      setCreateAccount(false);
    }
  };

  const renderForm = () => {
    return (
      <>
        <StepOneForm
          handleChange={handleStepOneChange}
          control={control}
          setValue={setValue}
          values={stepOneValues}
          errors={errors}
          active={progress[0] === "active"}
        />
        <StepTwoForm
          handleChange={handleStepTwoChange}
          control={control}
          setValue={setValue}
          values={stepTwoValues}
          errors={errors}
          active={progress[1] === "active"}
        />
      </>
    );
  };

  const renderFooter = () => {
    if (progress[0] === "active") {
      return (
        <Button size="large" onClick={goToStepTwo} fullWidth>
          Next Step
        </Button>
      );
    }
    if (progress[1] === "active")
      return (
        <div className={styles.footer}>
          <Button variant="secondary" size="large" onClick={goToStepOne}>
            Back
          </Button>
          <Button size="large" type="submit" onClick={onSubmit} disabled={isCreateAccount || userAuthSetupLoading}>
            Create Account
          </Button>
        </div>
      );
  };

  useEffect(() => {
    if (vendorData) {
      // handleStepOneChange("owner.email", vendorData?.detail?.email);
      setValue("owner.email", vendorData?.detail?.email);
    }
  }, [email, handleStepOneChange, history, setValue, vendorData]);


  return (
    <ProfileSetupLayout>
      <UserAuthenticationContainer
        large
        header="Setup your account"
        subHeader={
          <>
            <div className={styles.subHeader}>
              <Text type="p1" color="secondary">
                Enter your account and company details to complete the
                registration
              </Text>
            </div>
            <Stepper
              status={progress}
              labels={["Account details", "Company details"]}
            />
          </>
        }
        form={renderForm()}
        formFooter={
          progress[1] === "active" && (
            <Text color="secondary" type="p2">
              By clicking on Create account, I hereby agree and accept the{" "}
              {/* <Link to="https://www.60sec.asia/terms-and-conditions/" disableUnderline={false} type="p2" weight="medium">
                Terms of Use
              </Link>{" "}
              and{" "} */}
              <Link to="http://www.60sec.asia/privacy-policy/" disableUnderline={false} type="p2" weight="medium">
                Privacy Policy
              </Link>{" "}
              in use of the 60SEC platform and to receive text message communications
              from 60SEC on my mobile device.
            </Text>
          )
        }
        onFormSubmit={handleSubmit(onSubmit)}
        footer={renderFooter()}
      />
    </ProfileSetupLayout>
  );
}
