import React, { useState, useEffect, useRef } from "react";
import Panel from "components/SixtyPanel";
import ContentTable from "./Components/ContentTable";
import ContentDetails from "./Components/ContentDetails";
import styles from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import BasicPageHeader from "../../../../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetCampaignSellerRequestCount } from "queries/campaign";
import Loader from "components/Loader";

const Contents = ({campaign, refresh}) => {
  const [isSellerDetailOpen, setSellerDetailOpen] = useState(false);
  const history = useHistory();
  const params = useParams();
  const sellerDetailRef = useRef();
  const [selectedSeller, setSelectedSeller] = useState({});
  const role = localStorage.getItem("userRole");

  const {
    data: requestData,
    refetch
  } = useGetCampaignSellerRequestCount(params.id);

  const SellerDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedSeller.name} />
          </div>
          <div className={styles.modalTitle}>{selectedSeller.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setSellerDetailOpen(false)}
          />
        </div>
      </div>
    );
  };


  const RenderOnlineRecruitment = () => {
    return (
        <>
          <Panel className="tabs">
            <Tabs
              activeTab={"1"}
              tabs={[
                {
                  tabId: "1",
                  label: "Drafts",
                  recordsCount: requestData?.pendingRequestCount,
                  TabComponent: ContentTable,
                  props: {
                    status: "Approved",
                    onInfluencerClick: onInfluencerClick
                  },
                },
              ]}
            ></Tabs>
        </Panel>
        </>
      );
  };

  const onInfluencerClick = (value) => {
    setSelectedSeller(value);
    setSellerDetailOpen(true);
  };

  const onClose = () => {
    setSellerDetailOpen(false);
    refetch();
    refresh();
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <BasicPageHeader title="Creator Content & Payout" onGoBack={onGoBack} disableBack={true} />
      {RenderOnlineRecruitment()}
   
      {isSellerDetailOpen && (
        <Modal
          isOpen={isSellerDetailOpen}
          onAttemptClose={() => {
            setSellerDetailOpen(false);
          }}
          modalRef={sellerDetailRef}
          Title={SellerDetailTitle}
        >
          <ContentDetails
            campaign={campaign}
            selectedSeller={selectedSeller}
            handleClose={() => onClose()}
          />
        </Modal>
      )}
    </div>
  );
};

export default Contents;
