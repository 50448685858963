
import React, {useContext} from 'react';
import Loader from "../../components/Loader";
import { useCheckVendorPermission } from "../../queries/vendor";

const Restricted = ({children, action,  fallback}) => {

    const { data, isLoading } = useCheckVendorPermission(action);

    if(isLoading){
        return (<Loader text={'Loading...'}/>);
    }

    if(data && data.success){
        return (<>{children}</>);
    }

    return (<>{fallback}</>);
};

export default Restricted;