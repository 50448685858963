import { ReactComponent as CopyContentsIcon } from "assets/icons/Icon-Copy-Content.svg";

const CopyContents = ({ selectedRef, className = "" }) => {
  const copyToClipboard = (e) => {
    e.preventDefault();
    selectedRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };

  return (
    document.queryCommandSupported("copy") && (
      <button className={className} onClick={copyToClipboard}>
        <CopyContentsIcon />
      </button>
    )
  );
};

export default CopyContents;
