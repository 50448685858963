import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import VendorAnalyticTable from "./components/vendorAffiliateTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";

const VendorAnalytic = () => {

  const history = useHistory();
  const [selectedAnalytic, setSelectedAnalytic] = useState({});

  const onAnalyticClick = (value) => {
    console.log(value);
  };

  return (
    <div>
      <GridHeader header="Affiliate Traffics" />
      <Panel className={styles.panelWrap}>
        <div className={styles.vendorTable}>
          <VendorAnalyticTable onAnalyticClick={onAnalyticClick} />
        </div>
      </Panel>
    </div>
  );
};

export default VendorAnalytic;
