import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { setToast } from 'components/Toast';
import Button from "components/SixtyButton";
import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { useMediaQuery } from "react-responsive";
import {
  useProceedCreditCardSubscription
} from "queries/subscription";
import Input from "components/SixtyInput";
import { useForm, Controller } from "react-hook-form";
import Text from "components/SixtyText";
import Checkbox from "components/SixtyCheckBox";
import { ReactComponent as IconAmex } from "assets/icons/Icon-amex-v2.svg";
import { ReactComponent as IconMaster } from "assets/icons/Icon-mastercard-v2.svg";
import { ReactComponent as IconVisa } from "assets/icons/Icon-visa-v3.svg";
import { ReactComponent as IconStripe } from "assets/icons/Icon-stripe.svg";
import { ReactComponent as IconLock } from "assets/icons/Icon-lockv3.svg";

const SubscribeForm = ({ handleSuccess, id, subscriptionReference, data }) => {

    const [buttonStatus, setButtonStatus] = useState("Subscribe Now");
    const [days, setDays] = useState("0");
    const [isHandleSubscribe, setSubscribe] = useState(false);
    const isMobileOrTablet = useMediaQuery({ maxWidth: 768 });
    const [toggleAgreement, setAgreement] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const { handleSubmit, control, errors } = useForm();

    const customPlan = data?.isCustomPlan;
    const freeTrial = data?.freeTrial;
    const price = data?.price;
    const planName = data?.name + " Plan";
    const subscriptionTypeName = data?.label;
    const plan = customPlan ? "Get promote code to activate lite plan" : "RM " + price + " " + subscriptionTypeName;
    const freeTrialPlan = "RM 0";

    const CARD_OPTIONS = {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#00000",
          color: "#00000",
          fontWeight: 500,
          fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
          fontSize: "18px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#fce883"
          },
          "::placeholder": {
            color: "gray"
          }
        },
        invalid: {
          iconColor: "red",
          color: "red"
        }
      }
    };

    if(isMobileOrTablet){
      CARD_OPTIONS.style.base.fontSize = "15px";
    }

    useEffect(() => {   

      let status = "";

      if(subscriptionTypeName === "Monthly"){
        status = "Start 30 days Free Trial";
        setDays("30");
      }
      else if(subscriptionTypeName === "Weekly"){
        status = "Start 7 days Free Trial";
        setDays("7");
      }
      else if(subscriptionTypeName === "Anually"){
        status = "Start 365 days Free Trial";
        setDays("365");
      }

      if(freeTrial){
        setButtonStatus(status);
      } 
    });

    const {
      mutateAsync: ProceedCreditCardSubscription,
    } = useProceedCreditCardSubscription();

    function timeout(delay) {
      return new Promise( res => setTimeout(res, delay) );
    }
  
    const onSubmit = async (event) => {
      
      if (!stripe || !elements) {
        return;
      }

      setSubscribe(true);
      const cardElement = elements.getElement(CardElement);
      
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setToast(error.message, "error");
        setSubscribe(false);
      } else {

        await ProceedCreditCardSubscription(
        {
          referenceSubscriptionId: id,
          planId: subscriptionReference,
          token: paymentMethod.id,
          promotionCode: event.promotionCode ? event.promotionCode : null
        });

        await timeout(500); 
        setToast("Thank you for your subscription");      
        handleSuccess();              
        setSubscribe(false);
      }
    };
  
    return (
      <>
      <form className={styles.paymentForm}>
      <div className={styles.formRowHeader}>
        <Text color="primary" type="h1">
          Payment Details
        </Text>
        <IconLock className={styles.iconV3} />
      </div>
      <div className={styles.formRow}>
        <Text color="secondary" type="p1">
         All transactions are secured and encrypted
        </Text>
      </div>
      <div className={styles.formRowAlign}>
        <IconMaster className={styles.icon} />   
        <IconVisa className={styles.icon} />   
        <IconAmex className={styles.icon} />  
      </div>
      <div className={styles.formRow}>
         <Controller
              as={Input}
              name="plan"
              control={control}
              label="Payment Amount"
              error={errors.firstName}
              defaultValue={freeTrial ? freeTrialPlan : plan}
              disabled={true}
          />
       </div>
         {/* <div className={styles.formRow}>
         <Controller
              as={Input}
              name="firstName"
              control={control}
              rules={{ required: "first name required" }}
              label="First Name"
              error={errors.firstName}
              defaultValue=""
          />
          </div> */}
          <div className={styles.formRow}>
          <Controller
              as={Input}
              name="promotionCode"
              control={control}
              label="Promotion Code"
              error={errors.promotionCode}
              defaultValue=""
          />
          </div>
         <div className={styles.cardElement}>
         <CardElement 
           options={CARD_OPTIONS}
         /> 
        </div>
             
       <div className={styles.formRow}>
         <Button onClick={handleSubmit(onSubmit)} size="large" fullWidth disabled={!stripe || isHandleSubscribe}>
           {buttonStatus}
         </Button>
        </div>

        {freeTrial &&(
          <div className={styles.formRow}>
           <Text color="secondary" type="p2">
             {/* You can cancel at any time during your trial and you won't be charged. {planName} of 
             RM {price} will be charged after {days}-days free trial. Afterward your subscription will renew
             recurringly, but you can cancel anytime. Please be informed payment are not refundable. */}
             Recurring payment {planName} of RM {price} will be charged after {days}-days free trial and it's not refundable. 
             You can cancel your subscription anytime before the due date.
           </Text>
           </div>
        )}

        {!freeTrial &&(
          <div className={styles.formRow}>
           <Text color="secondary" type="p2">
             Recurring payment will be charged and it's not refundable. You can cancel your subscription anytime before the due date.
           </Text>
           </div>
        )}

         <div className={styles.formRowFooter}>
          <IconStripe className={styles.iconV2} />
         </div>

      </form>
      </>
    );
};

export default SubscribeForm;