import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useDeletePromotion } from "queries/admin";
import { useGetSubscriptionDetail } from "queries/subscription";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import PromotionForm from "../components/PromotionForm";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

const PromotionDetails = ({ selectedPromotion, handleClose }) => {

const defaultValues = {
    name: '',
    promotionCode: '',
    percentOff: 0,
    duration: '',
    durationInMonths: 0,
 };

  const formMethods = useForm({
    defaultValues,
    shouldUnregister: false,
    mode: "onChange",
  });

  const { setValue } = formMethods;
  const history = useHistory();

  useEffect(() => {

    if (selectedPromotion) {
 
     for (const [key, value] of Object.entries({
         name: selectedPromotion?.name,
         promotionCode: selectedPromotion?.promotionCode,
         percentOff: selectedPromotion?.percentOff ? parseFloat(selectedPromotion.percentOff) : 0,
         duration: selectedPromotion?.duration,
         durationInMonths: selectedPromotion?.durationInMonths ? parseFloat(selectedPromotion.durationInMonths) : 0,
     })) {
       setValue(key, value);
     }
   }
 
   }, [setValue]);

  const { mutateAsync: deletePromotion, 
          isLoading : promotionLoading 
        } = useDeletePromotion();

  const onSubmit = async () => {
    try {
 
         var result = await deletePromotion(selectedPromotion?.promotionCode);

         if(result){
            setToast("successfully delete promotion");
            handleClose();
         }
        
    } catch (e) {
      reportError(
        "error in : CreatePromotion: onSubmit, error: ",
        e
      );
    }
  };

  return (
    <React.Fragment>
      <div className={styles.pricingRequestFormContainer}>
      <div className={styles.pricingRequestForm}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.pricingInfoHeader}
              title="Promotion"
            />
          <FormProvider {...formMethods}>
            <form
                className={styles.form}
            >        
             <PromotionForm selectedPromotion={selectedPromotion} disabledForm={true} />                   
            </form>
        </FormProvider>
         </Panel>

        </div>
      </div>

        <footer className={styles.footer}>
          <SixtyButton
            type="submit"
            onClick={formMethods.handleSubmit(onSubmit)}
            className={styles.approveBtn}
            disabled={promotionLoading}
          >
            Delete
          </SixtyButton>

          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={promotionLoading}
          >
            Close
          </SixtyButton>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default PromotionDetails;
