import React, { useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import PanelHeader from "components/PanelHeader";
import CampaignProductTable from "./components/CampaignProductTable";
import CampaignInfluencerTable from "./components/CampaignInfluencerTable";
import clsx from "clsx";
import CampaignSummary from "./components/Summary";
import CampaignInfo from "./components/CampaignInfo";
import OwnerDetails from "./components/OwnerDetails";
import CampaignInstruction from "./components/CampaignInstruction";
import { ReactComponent as IconArrowRight } from "assets/icons/Icon-Arrow-Right.svg";
import Modal from "components/Modal";
import { useGetCampaignAnalytics, useRejectSellerCampaignAccess } from "queries/campaign";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import BasicPageHeader from "../../Components/BasicHeader";
import InfluencerDetails from "./components/InfluencerDetails";
import ProductDetails from "./components/ProductDetails";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

const CampaignAnalyticPage = () => {

  const history = useHistory();
  const params = useParams();

  // influencer
  const [isInfluencerDetailOpen, setInfluencerDetailOpen] = useState(false);
  const influencerDetailRef = useRef();
  const [selectedInfluencer, setSelectedInfluencer] = useState({});

  // product
  const [isProductDetailOpen, setProductDetailOpen] = useState(false);
  const productDetailRef = useRef();
  const [selectedProduct, setSelectedProduct] = useState({});

  const { data: campaignInfo, isLoading, isError, refetch } = useGetCampaignAnalytics(
    params.id
  );

  const { mutateAsync: rejectSeller, isLoading: rejectLoading } = useRejectSellerCampaignAccess();

  const onGoBack = () => {
    history.goBack();
  };

  const InfluencerDetailTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedInfluencer.name} />
          </div>
          <div className={styles.modalTitle}>{selectedInfluencer.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setInfluencerDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onInfluencerClick = (value) => {
    setSelectedInfluencer(value);
    setInfluencerDetailOpen(true);
  };

  const ProductDetailTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedProduct.name} />
          </div>
          <div className={styles.modalTitle}>{selectedProduct.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setProductDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onProductClick = (value) => {
    setSelectedProduct(value);
    setProductDetailOpen(true);
  };

  const onStatusUpdate = async (id) => {
    try {
      const result = await rejectSeller(params.id);
      if (result) {
        setToast("Successfully reject influencer access");
        refetch();
      }
    } catch (error) {
      reportError("rejectSeller: onStatusUpdate: Error: ", error);
      setToast("Error while updating influncer status", "error");
    }
  };

  return (
    <>
    <div className={styles.campaignDetailsPage}>
     <BasicPageHeader title="Campaign Details" onGoBack={onGoBack} />
      <div className={styles.row}>
        <div className={styles.campaignDetailsLeftContainer}>
          <Panel className={clsx(styles.panelWrap, styles.firstPanel)}>
            <PanelHeader title="Summary" />
            <CampaignSummary data={campaignInfo} />
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader title="Description" />
            <CampaignInstruction data={campaignInfo} />
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader title="Owner Details" />
            <OwnerDetails data={campaignInfo} />
          </Panel>
          {/* <Panel className={styles.panelWrap}>
            <PanelHeader title="Campaign Influencers" />
            <CampaignInfluencerTable id={params?.id} onInfluencerClick={onInfluencerClick} />
          </Panel> */}
          <Panel className={styles.panelWrap}>
            <PanelHeader title="Campaign Products" />
            <CampaignProductTable id={params?.id} onProductClick={onProductClick} />
          </Panel>
        </div>
        <div className={styles.campaignDetailsRightContainer}>
          {campaignInfo && (
            <React.Fragment>
              <Panel
                className={[styles.panelWrap, styles.campaignInfoPanel].join(" ")}
              >
                <PanelHeader title="Campaign info" />
                <CampaignInfo data={campaignInfo} />
              </Panel>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
    {isInfluencerDetailOpen && (
        <Modal
          isOpen={isInfluencerDetailOpen}
          onAttemptClose={() => {
            setInfluencerDetailOpen(false);
          }}
          modalRef={influencerDetailRef}
          Title={InfluencerDetailTitle}
        >
          <InfluencerDetails
            selectedSeller={selectedInfluencer}
            handleClose={() => setInfluencerDetailOpen(false)}
          />
        </Modal>
      )}
      {isProductDetailOpen && (
        <Modal
          isOpen={isProductDetailOpen}
          onAttemptClose={() => {
            setProductDetailOpen(false);
          }}
          modalRef={influencerDetailRef}
          Title={ProductDetailTitle}
        >
          <ProductDetails
            selectedProduct={selectedProduct}
            handleClose={() => setProductDetailOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default CampaignAnalyticPage;
