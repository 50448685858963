import { useImmer } from "utils/hooks/useImmer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useRowSelectionTable from "utils/hooks/useRowSelectionTable";
import { useGetProducts } from "queries/product";
import _isEmpty from "lodash/isEmpty";

import Table, {
  RowSelectionContainer,
  TableHeader,
} from "components/SixtyTable";
import EmptyGrid from "components/EmptyGrid";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import SixtyText from "components/SixtyText";
import SixtyButton from "components/SixtyButton";
import Filter from "components/SixtyTable/components/Filter";
import Sort from "components/SixtyTable/components/Sort";
import ProductFilter, { renderFilterChips } from "./components/Filter";
import ProductSort from "./components/Sort";
import ErrorModal from "components/Error";
import Loader from "components/Loader";
import { PanelActionButton } from "components/SixtyPanel";

import { ReactComponent as ProductIcon } from "assets/icons/Icon-Products.svg";
import styles from "./index.module.css";
import { unknown_Error } from "common/constants";
import { formatCurrency } from "utils/common";

export default function ProductTable({ status, setCount, filter }) {
  const history = useHistory();

  const onProductClick = useCallback(
    (value) => {
      const path = `/dashboard/products/${value}`;
      history.push(path);
    },
    [history]
  );

  const [search, setSearch] = useState("");
  const handleSearch = (val) => setSearch(val);

  const [filterState, setFilterState] = useImmer({});
  const [sort, setSort] = useState("orderByDescending");

  const onFilterChange = (filterVal) => {
    // component filter state set
    setFilterState(() => filterVal);
  };

  const handleSortChange = useCallback(
    (field) => {
      setSort(field);
    },
    [setSort]
  );

  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    let inventory = { ...(filterState.inventory ? filterState.inventory : {}) };
    let orders = { ...(filterState.orders ? filterState.orders : {}) };
    let category = filterState.filterByCategoryId;

    return {
      ...inventory,
      ...orders,
      [sort]: true,
      filterByCategoryId: category,
      ...(filter ? filter : {}),
      keyword: search,
      startFrom,
      limit,
      status,
    };
  }, [
    filter,
    filterState.filterByCategoryId,
    filterState.inventory,
    filterState.orders,
    search,
    sort,
    startFrom,
    status,
  ]);

  const {
    data: productData,
    isLoading,
    isError,
    refetch,
    error,
  } = useGetProducts(getFilters());

  useEffect(() => {
    if (productData?.result?.length && setCount)
      setCount(productData?.totalRecords);
  }, [productData, setCount]);

  const columns = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "name",
      },
      {
        Header: "SKU",
        accessor: "skuCode",
        className: styles.collapsedCell,
        collapse: true,
      },
      {
        Header: "Price",
        accessor: "costPrice",
        className: styles.collapsedCell,
        Cell: ({ value }) => formatCurrency(value),
        collapse: true,
      },

      // {
      //   Header: "Inventory",
      //   accessor: "stockCount",
      //   collapse: true,
      //   className: styles.collapsedCell,
      //   Cell: ({ value }) => value + " in stock",
      // },
      {
        Header: "Category",
        accessor: "categoryName",
        className: styles.collapsedCell,
        collapse: true,
      },
      {
        Header: "Orders",
        accessor: "orderCount",
        className: styles.collapsedCell,
        collapse: true,
      },
    ],
    []
  );

  let data = useMemo(() => (productData?.result ? productData.result : []), [
    productData,
  ]);

  const tableInstance = useRowSelectionTable({
    data,
    columns,
  });

  // Error modal
  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (_isEmpty(filterState) && !search && productData?.result?.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      {(!Array.isArray(productData?.result) || isDataEmpty()) && !isLoading && (
        <>
          <EmptyGrid
            Icon={ProductIcon}
            emptyMessage={
              isError
                ? "Error in fetching data"
                : "The products will show up here"
            }
          />

          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}

      {!isError && !isDataEmpty() && (
        <>
          {tableInstance.selectedFlatRows.length > 0 ? (
            <RowSelectionContainer
              renderRowSelectionText={() => (
                <>
                  <SixtyText>
                    {tableInstance.selectedFlatRows.length} products selected
                  </SixtyText>
                  <SixtyButton
                    className={styles.unselectButton}
                    variant="text"
                    onClick={() => tableInstance.toggleAllRowsSelected(false)}
                  >
                    unselect all
                  </SixtyButton>
                </>
              )}
              renderRowSelectionButtons={() => (
                <>
                  {/* <SixtyButton>Mark as shipped</SixtyButton>
                  <SixtyButton variant="secondary">Mark as paid</SixtyButton>
                  <SixtyButton variant="secondary">Archive</SixtyButton> */}
                </>
              )}
            />
          ) : (
            <>
              <TableHeader>
                <SearchFilter
                  search={search}
                  onSearchChange={handleSearch}
                  placeholder="Search for products"
                />
                <Filter
                  filter={filterState}
                  setFilter={onFilterChange}
                  renderFilter={ProductFilter}
                />
                <Sort
                  render={ProductSort}
                  state={sort}
                  handleSortChange={handleSortChange}
                />
              </TableHeader>
              {renderFilterChips(filterState, setFilterState)}
            </>
          )}
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(productData?.result) && (
              <Table
                instance={tableInstance}
                totalItems={productData?.totalRecords}
                startFrom={startFrom}
                limit={limit}
                setStart={setStart}
                onRowClicked={(row) => onProductClick(row.original.id)}
              />
            )
          )}
        </>
      )}
    </>
  );
}
