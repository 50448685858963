import React, { useEffect, useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import ShipmentTable from "../components/ShipmentTable";
import OfflineTable from "../components/OfflineTable";
import VoucherTable from "../components/VoucherTable";
import PurchaseTable from "../components/PurchaseTable";
import InfluencerShipmentDetails from "../../components/InfluencerShipmentDetails";
import styles from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import BasicPageHeader from "../../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetCampaignSellerRequestCount, useExportCampaignShipment, useImportCampaignShipment } from "queries/campaign";
import GridHeader from "components/GridHeader";
import SixtyButton from "components/SixtyButton";
import { setToast } from "components/Toast";

const Shipment = ({campaign}) => {
  const [isSellerDetailOpen, setSellerDetailOpen] = useState(false);
  const history = useHistory();
  const params = useParams();
  const sellerDetailRef = useRef();
  const [selectedSeller, setSelectedSeller] = useState({});
  const [files, setFiles] = useState([]);

  const {
    mutateAsync: exportShipments,
    isLoading: exporting,
  } = useExportCampaignShipment();

  const onExport = async () => {
    setToast("Exporting shipment, it may take some time");
    if (!exporting) await exportShipments(params.id);
  };

  const fileInput = useRef();
  const onImport = () => {
    fileInput.current.click();
  };

  const {
    mutateAsync: importShipments,
    isLoading: uploadingFiles,
  } = useImportCampaignShipment();

  const {
    data: requestData,
  } = useGetCampaignSellerRequestCount(params.id);

  const SellerDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedSeller.sellerName} />
          </div>
          <div className={styles.modalTitle}>{selectedSeller.sellerName}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setSellerDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onInfluencerClick = (value) => {
    setSelectedSeller(value);
    setSellerDetailOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    async function importFiles() {
      let formData = new FormData();
      formData.append(`campaignId`, params.id);
      formData.append("file", files[0]);

      let result = await importShipments(formData);

      if(result){
        setToast(`successfully imported ${result?.importedCount} result`);
      }
      
      setFiles([]);
    }
    if (files.length > 0) {
      setToast("Importing Shipments, it may take some time");
      importFiles();
    }
  }, [files, importShipments]);

  return (
    <div>
      {/* <BasicPageHeader title="Sample Product Shipment" onGoBack={onGoBack} disableBack={true} /> */}
      <GridHeader
        header="Shipment / Appoinment"
        renderButtons={() => (
          <>
            {["Standard", "WebsiteReview"].includes(campaign?.campaignType) && 
            ["Manual"].includes(campaign?.shipmentType) && (
             <>
               <input
                  type="file"
                  hidden
                  ref={fileInput}
                  onChange={(e) => setFiles(e.target.files)}
                  accept=".xlsx, .xls, .csv"
                />
                <SixtyButton
                  variant="secondary"
                  onClick={onImport}
                  disabled={exporting || uploadingFiles}
                >
                  Import Shipment
                </SixtyButton>
                <SixtyButton
                  variant="secondary"
                  onClick={onExport}
                  disabled={exporting || uploadingFiles}
                >
                  Export Shipment
                </SixtyButton>
             </>
           )}

          </>
        )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.vendorTable}>
          {["Offline"].includes(campaign?.campaignType) && (
            <>
               <OfflineTable onInfluencerClick={onInfluencerClick} />
            </>
          )}
          {["Standard", "WebsiteReview"].includes(campaign?.campaignType) && 
           ["Manual"].includes(campaign?.shipmentType) && (
            <>
               <ShipmentTable onInfluencerClick={onInfluencerClick} />
            </>
          )}
          {["Standard", "WebsiteReview"].includes(campaign?.campaignType) && 
           ["VoucherCode"].includes(campaign?.shipmentType) && (
            <>
               <VoucherTable onInfluencerClick={onInfluencerClick} />
            </>
          )}
          {["Standard", "WebsiteReview"].includes(campaign?.campaignType) && 
           ["Purchase"].includes(campaign?.shipmentType) && (
            <>
               <PurchaseTable onInfluencerClick={onInfluencerClick} />
            </>
          )}
        </div>
      </Panel>
      {isSellerDetailOpen && (
        <Modal
          isOpen={isSellerDetailOpen}
          onAttemptClose={() => {
            setSellerDetailOpen(false);
          }}
          modalRef={sellerDetailRef}
          Title={SellerDetailTitle}
        >
          <InfluencerShipmentDetails
            selectedSeller={selectedSeller}
            campaign={campaign}
            handleClose={() => setSellerDetailOpen(false)}
            viewOnly={false}
          />
        </Modal>
      )}
    </div>
  );
};

export default Shipment;
