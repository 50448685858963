import React from "react";
import styles from "./index.module.css";
import { ReactComponent as GoBackIcon } from "assets/icons/Icon-Back-Button.svg";
import Button from "components/SixtyButton";
import SixtySelectField from "components/SixtySelect";

const OrderPageHeader = ({
  title,
  onGoBack,
  onMarkAsShipped,
  onRefund,
  onEdit,
  onUpdateOrder,
  onArchive,
  onDuplicate,
  onPrint,
  onDelete,
  onDownloadInvoice,
  status,
  onArchiveloading = false,
  isArchive = false,
  isDownloadLoading = false
}) => {
  const role = localStorage.getItem("userRole");
  const moreActionsOptions = [
    {
      label: "Duplicate",
      value: "duplicate",
    },
    {
      label: "Print",
      value: "print",
    },
    {
      label: "Delete",
      value: "delete",
    },
  ];

  const onSelectAction = (value) => {
    if (value === "duplicate") {
      onDuplicate();
    } else if (value === "print") {
      onPrint();
    } else if (value === "delete") {
      onDelete();
    }
  };
  return (
    <div className={styles.orderHeader}>
      <div className={styles.titleBox}>
        <div onClick={onGoBack}>
          <GoBackIcon />
        </div>
        <div className={styles.titleContent}>{title}</div>
      </div>
      <div className={styles.ButtonsContainer}>
        {role == 'Supplier' && (
          <>
            <div>
                <Button
                    className={styles.markAsShippedBtn}
                    label="Mark as shipped"
                    variant={
                      ["Refunded", "Achieved", "Shipped", "Completed"].includes(status)
                        ? "secondary"
                        : "primary"
                    }
                    onClick={onUpdateOrder}
                    disabled={["Refunded", "Achieved", "Shipped", "Completed"].includes(
                      status
                    )}
                />
          </div>
          <div>
              <Button
                  className={styles.actionButtons}
                  variant="secondary"
                  label="Refund"
                  disabled={["Refunded", "Achieved", "Completed"].includes(status)}
                  onClick={onRefund}
              />
          </div>
          <div>
              <Button
                  className={styles.actionButtons}
                  variant="secondary"
                  label={ isArchive ? "Unarchive" : "Archive"}
                  onClick={onArchive}
                  disabled={["Achieved"].includes(status) || onArchiveloading}
              />
          </div>
          <div>
              <Button
                  className={styles.actionButtons}
                  variant="secondary"
                  label={"Download Invoice"}
                  onClick={onDownloadInvoice}
                  disabled={isDownloadLoading}
              />
          </div>
          </>
        )}

        {/* <div>
          <div>
            <Button
              className={styles.actionButtons}
              variant="secondary"
              label="Update Parcel Courier"
              onClick={onUpdateOrder}
            />
          </div>
        </div> */}

        {/* <div>
                    <Button className={styles.actionButtons} variant="secondary" label="Edit" onClick={onEdit}/>
            </div> */}

        {/* Show when API available */}
        {/* <div>
          <SixtySelectField
            options={moreActionsOptions}
            defaultOption="More actions"
            value={""}
            selectBoxHelperClass={styles.moreActionsSelectBox}
            variant="secondary"
            activeVariant="greyish"
            size="regular"
            selectBoxFontHelper={styles.selectBoxText}
            optionBoxFontHelper={styles.optionBoxText}
            onChange={(value) => {
              onSelectAction(value);
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default OrderPageHeader;
