import { useImmer } from "utils/hooks/useImmer";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetOrders, useMarkOrdersAsShipped } from "queries/order";
import useRowSelectionTable from "utils/hooks/useRowSelectionTable";

import Table, {
  RowSelectionContainer,
  TableHeader,
} from "components/SixtyTable";
import EmptyGrid from "components/EmptyGrid";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import SixtyText from "components/SixtyText";
import SixtyButton from "components/SixtyButton";
import Filter from "components/SixtyTable/components/Filter";
import Sort from "components/SixtyTable/components/Sort";
import OrderFilter, { renderFilterChips } from "./components/Filter";
import OrderSort from "./components/Sort";

import { ReactComponent as OrderIcon } from "assets/icons/orders-empty.svg";

import styles from "./index.module.css";
import Loader from "components/Loader";
import ErrorModal from "components/Error";
import { PanelActionButton } from "components/SixtyPanel";
import { useGetSellerDropdown } from "queries/seller";
import { getFormattedDateString } from "utils/date.util";
import { format } from "date-fns";
import { unknown_Error } from "common/constants";
import { formatCurrency } from "utils/common";

export default function OrderTable({ status, setCount }) {
  const history = useHistory();

  const onOrderClick = useCallback(
    (value) => {
      const path = `/dashboard/orders/${value}`;
      history.push(path);
    },
    [history]
  );

  const {
    mutateAsync: markAsShipped,
    isLoading: markingAsShipped,
  } = useMarkOrdersAsShipped();

  const [search, setSearch] = useState("");
  const handleSearch = (value) => setSearch(value);

  const [filterState, setFilterState] = useImmer({});
  const [sort, setSort] = useState("orderByDescending");

  // get sellerdropdown list
  useGetSellerDropdown({});

  const onFilterChange = (filterVal) => {
    // component filter state set
    setFilterState(() => filterVal);
  };

  const handleSortChange = useCallback(
    (field) => {
      setSort(field);
    },
    [setSort]
  );

  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    let filter = { ..._omit(filterState, "date") };

    let dateFilters = { ...(filterState.date ? filterState.date : {}) };

    if (!!dateFilters["custom"]) {
      delete dateFilters.custom;
      dateFilters = {
        fromDate: format(dateFilters.fromDate, "dd/MM/yyyy"),
        untilDate: format(dateFilters.untilDate, "dd/MM/yyyy"),
      };
    }
    filter = { ...filter, ...dateFilters };

    return {
      ...filter,
      [sort]: true,
      keyword: search,
      startFrom,
      limit,
      filterByAchieved: status === "Archived" ? true : null,
      status: status !== "Archived" ? status : null,
    };
  }, [filterState, search, sort, startFrom, status]);

  const { data: orderData, isLoading, isError, refetch, error } = useGetOrders(
    getFilters()
  );

  const onRowClick = (row, e) => {
    onOrderClick(row.original.id);
  };

  useEffect(() => {
    if (orderData?.result && setCount) setCount(orderData?.totalRecords);
  }, [orderData, setCount]);

  const columns = useMemo(
    () => [
      {
        Header: "Order",
        accessor: "transactionCode",
      },
      {
        Header: "Date",
        accessor: "transactionDate",
        Cell: ({ value }) => getFormattedDateString(value, "MMM d, y"),
      },
      {
        Header: "Customer",
        accessor: "customerName",
      },
      {
        Header: "Creator",
        accessor: "sellerName",
      },
      {
        Header: "Net Total",
        accessor: "total",
        Cell: ({ value, row }) => formatCurrency(value),
      },
      {
        Header: "Items",
        accessor: "itemCount",
        collapse: true,
      },
      {
        Header: "Order Status",
        accessor: "transactionStatus",
        Cell: ({ value, row }) =>
          value && (
            <div className={styles.statusColumns}>
              <div className={styles.transactionStatusCell}>{value}</div>
            </div>
          ),
      },
    ],
    []
  );

  let data = useMemo(() => (orderData?.result ? orderData.result : []), [
    orderData,
  ]);

  const tableInstance = useRowSelectionTable({
    data,
    columns,
  });

  const errorRef = useRef();
  const [errorOpen, setErrorOpen] = useState(false);

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (_isEmpty(filterState) && !search && orderData?.result?.length === 0) {
      return true;
    }
    return false;
  }

  // Since we have to support Search, we need to detach it's rendering from the table.
  return (
    <>
      {(!Array.isArray(orderData?.result) || isDataEmpty()) && !isLoading && (
        <>
          <EmptyGrid
            Icon={OrderIcon}
            emptyMessage={
              isError
                ? "Error in fetching data"
                : "The orders will show up here"
            }
          />

          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}
      {!isError && !isDataEmpty() && (
        <>
          {tableInstance.selectedFlatRows.length > 0 ? (
            <RowSelectionContainer
              renderRowSelectionText={() => (
                <>
                  <SixtyText>
                    {tableInstance.selectedFlatRows.length} orders selected
                  </SixtyText>
                  <SixtyButton
                    className={styles.unselectButton}
                    variant="text"
                    onClick={() => tableInstance.toggleAllRowsSelected(false)}
                  >
                    unselect all
                  </SixtyButton>
                </>
              )}
              renderRowSelectionButtons={() => (
                <>
                  <SixtyButton
                    onClick={() => {
                      let ids = tableInstance.selectedFlatRows.map(
                        (row) => row.original.id
                      );
                      markAsShipped(ids);
                    }}
                    disabled={markingAsShipped}
                  >
                    Mark as shipped
                  </SixtyButton>
                  {/* TODO  --- Need to add when API available */}
                  {/* <SixtyButton variant="secondary">Mark as paid</SixtyButton>  */}
                  {/* <SixtyButton variant="secondary">Archive</SixtyButton> */}
                </>
              )}
            />
          ) : (
            <>
              <TableHeader>
                <SearchFilter
                  search={search}
                  onSearchChange={handleSearch}
                  placeholder="Search for orders"
                />
                <Filter
                  filter={filterState}
                  setFilter={onFilterChange}
                  renderFilter={OrderFilter}
                  columnLayout
                />
                <Sort
                  render={OrderSort}
                  state={sort}
                  handleSortChange={handleSortChange}
                />
              </TableHeader>
              {renderFilterChips(filterState, setFilterState)}
            </>
          )}
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(orderData?.result) && (
              <Table
                instance={tableInstance}
                totalItems={orderData?.totalRecords}
                startFrom={startFrom}
                limit={limit}
                setStart={setStart}
                onRowClicked={onRowClick}
              />
            )
          )}
        </>
      )}
    </>
  );
}
