import SixtySelect from "components/SixtySelect";
import RadioField from "components/RadioField";
import {
  FilterGroup,
  FilterItem,
  FilterChipContainer,
  ChipItem,
} from "components/SixtyTable/components/Filter";
import { useGetCategoryDropdown } from "queries/product";
import { useGetSocialMediaDropdown, useGetFollowerGroupDropdown, useGetAudiencePreferenceDropdown } from "queries/admin";
import {
  useGetStateDropdown,
} from "queries/product";

export const labelMap = {
  race: {
    malay: "Malay",
    chinese: "Chinese",
    india: "India",
    others: "Others",
  },
  gender: {
    male: "Male",
    female: "Female",
  },
};

export default function SellerFilter({ state, setState }) {
 
  const { data: socialMediaData, isLoading: socialMediaLoading } = useGetSocialMediaDropdown({
    staleTime: 60 * 1000,
  });

  const socialMediaOptions =
  socialMediaData?.length > 0
      ? socialMediaData.map((option) => ({
          label: option.name,
          value: option.id,
        }))
      : [];

  const { data: stateData, isLoading: stateLoading } = useGetStateDropdown();
  const stateOptions = stateData?.length
        ? [
            { label: "None", value: null },
            ...stateData.map((option) => ({
              label: option.name,
              value: option.id,
            })),
          ]
        : [];

   const { data: followerData, isLoading: followerLoading } = useGetFollowerGroupDropdown({
     staleTime: 60 * 1000,
   });

   const followerOptions =
   followerData?.length > 0
       ? followerData.map((option) => ({
           label: option.name,
           value: option.id,
         }))
       : [];

   const { data: audienceData, isLoading: audienceLoading } = useGetAudiencePreferenceDropdown({
        staleTime: 60 * 1000,
   });
   
  const audienceOptions =
  audienceData?.length > 0
      ? audienceData.map((option) => ({
          label: option.name,
           value: option.id,
          }))
       : [];

  const handleGenderChange = (group, fieldName, value) => {
    setState((filter) => {
      filter[group] = { [fieldName]: true };
      filter.filterByGender = value;
    });
  };

  const handleRaceChange = (group, fieldName, value) => {
    setState((filter) => {
      filter[group] = { [fieldName]: true };
      filter.filterByRace = value;
    });
  };

  const handleSocialMediaSelect = (value) => {
    setState((filter) => {
      filter.filterBySocialMediaId = value;
      filter.socialMediaName = socialMediaOptions.find(
        (option) => option.value === value
      ).label;
    });
  };

  const handleFollowerSelect = (value) => {
    setState((filter) => {
      filter.filterByFollowerGroupId = value;
      filter.followerGroupName = followerOptions.find(
        (option) => option.value === value
      ).label;
    });
  };

  const handleAudienceSelect = (value) => {
    setState((filter) => {
      filter.filterByAudienceId = value;
      filter.audienceName = audienceOptions.find(
        (option) => option.value === value
      ).label;
    });
  };

  const handleStateSelect = (value) => {
    setState((filter) => {
      filter.filterByStateId = value;
      filter.stateName = stateOptions.find(
        (option) => option.value === value
      ).label;
    });
  };

  return (
    <>
      <FilterGroup label="Race">
        <FilterItem>
          <RadioField
            label={labelMap.race.malay}
            value={"malay"}
            checked={!!state?.race?.malay}
            onChange={(e) =>
                handleRaceChange("race", "malay", "Malay")
            }
          />
        </FilterItem>
        <FilterItem>
          <RadioField
            label={labelMap.race.chinese}
            value={"chinese"}
            checked={!!state?.race?.chinese}
            onChange={(e) =>
                handleRaceChange("race", "chinese", "Chinese")
            }
          />
        </FilterItem>
        <FilterItem>
          <RadioField
            label={labelMap.race.india}
            value={"india"}
            checked={!!state?.race?.india}
            onChange={(e) =>
                handleRaceChange("race", "india", "India")
            }
          />
        </FilterItem>
        <FilterItem>
          <RadioField
            label={labelMap.race.others}
            value={"others"}
            checked={!!state?.race?.others}
            onChange={(e) =>
                handleRaceChange("race", "others", "Others")
            }
          />
        </FilterItem>
      </FilterGroup>
      <FilterGroup label="Gender">
        <FilterItem>
          <RadioField
            value={"male"}
            label={labelMap.gender.male}
            checked={!!state?.gender?.male}
            onChange={(e) => {
                handleGenderChange("gender", "male", "Male");
            }}
          />
        </FilterItem>
        <FilterItem>
          <RadioField
            value={"female"}
            label={labelMap.gender.female}
            checked={!!state?.gender?.female}
            onChange={(e) => {
                handleGenderChange("gender", "female", "Female");
            }}
          />
        </FilterItem>
      </FilterGroup>
      <FilterGroup label="Social Media">
        <FilterItem>
          <SixtySelect
            options={socialMediaOptions}
            defaultOption={
              socialMediaLoading ? "fetching options..." : "select a channel"
            }
            value={state?.filterBySocialMediaId}
            onChange={(value) => handleSocialMediaSelect(value)}
            disableDropIcon
            fullWidth
          />
        </FilterItem>
        <FilterItem>
          <SixtySelect
            options={followerOptions}
            defaultOption={
              followerLoading ? "fetching options..." : "select a follower range"
            }
            value={state?.filterByFollowerGroupId}
            onChange={(value) => handleFollowerSelect(value)}
            disableDropIcon
            fullWidth
          />
        </FilterItem>
        <FilterItem>
          <SixtySelect
            options={audienceOptions}
            defaultOption={
              audienceLoading ? "fetching options..." : "select a audience type"
            }
            value={state?.filterByAudienceId}
            onChange={(value) => handleAudienceSelect(value)}
            disableDropIcon
            fullWidth
          />
        </FilterItem>
        <FilterItem>
          <SixtySelect
            options={stateOptions}
            defaultOption={
              stateLoading ? "fetching options..." : "select location"
            }
            value={state?.filterByStateId}
            onChange={(value) => handleStateSelect(value)}
            disableDropIcon
            fullWidth
          />
        </FilterItem>
      </FilterGroup>
    </>
  );
}

export function renderFilterChips(filterState, setFilterState) {
 
  let filtersPresent = false;
  for (const filterKey in filterState) {
    if (filterKey === "race") {
      for (const label in labelMap.race) {
        if (!!filterState.race[label]) {
          filtersPresent = true;
          break;
        }
      }
      if (filtersPresent) break;
    } else if (filterKey === "gender") {
      for (const label in labelMap.gender) {
        if (!!filterState.gender[label]) {
          filtersPresent = true;
          break;
        }
      }
      if (filtersPresent) break;
    } else if (!!filterState[filterKey]) {
      filtersPresent = true;
    }
  }

  const removeFilter = (filterItem) => {
    setFilterState((filter) => {
      if (filterItem === "socialMediaName") {
        delete filter["filterBySocialMediaId"];
        delete filter.socialMediaName;
      }
      else if (filterItem === "followerGroupName") {
        delete filter["filterByFollowerGroupId"];
        delete filter.followerGroupName;
      }
      else if (filterItem === "audienceName") {
        delete filter["filterByAudienceId"];
        delete filter.audienceName;
      }
      else if (filterItem === "stateName") {
        delete filter["filterByStateId"];
        delete filter.stateName;
      }
      delete filter[filterItem];
    });
  };

  if (filtersPresent) {
    let raceFilters = filterState.race || {};
    let genderFilters = filterState.gender || {};
    let socialMediaFilters = filterState.socialMediaName;
    let followerFilters = filterState.followerGroupName;
    let audienceFilters = filterState.audienceName;
    let stateFilters = filterState.stateName;

    return (
      <FilterChipContainer>
        {Object.keys(raceFilters).length > 0 &&
          Object.keys(raceFilters)?.map((filterItem) => (
            <ChipItem
              label={labelMap.race[filterItem]}
              name={"race"}
              handleRemove={removeFilter}
              key={filterItem}
            />
          ))}
        {Object.keys(genderFilters).length > 0 &&
          Object.keys(genderFilters)?.map((filterItem) => (
            <ChipItem
              label={labelMap.gender[filterItem]}
              name={"gender"}
              handleRemove={removeFilter}
              key={filterItem}
            />
          ))}
        {socialMediaFilters && (
          <>
            <ChipItem
              label={`social media is ${filterState.socialMediaName}`}
              name={"socialMediaName"}
              handleRemove={removeFilter}
            />
          </>
        )}
        {followerFilters && (
          <>
            <ChipItem
              label={`follower range between ${filterState.followerGroupName}`}
              name={"followerGroupName"}
              handleRemove={removeFilter}
            />
          </>
        )}
         {audienceFilters && (
          <>
            <ChipItem
              label={`audience type is ${filterState.audienceName}`}
              name={"audienceName"}
              handleRemove={removeFilter}
            />
          </>
        )}
        {stateFilters && (
          <>
            <ChipItem
              label={`location is ${filterState.stateName}`}
              name={"stateName"}
              handleRemove={removeFilter}
            />
          </>
        )}
      </FilterChipContainer>
    );
  }
  return null;
}
