import React, { useEffect, useState } from "react";
import Tabs from "components/Tabs";
import Panel from "components/SixtyPanel";
import OrderTable from "../components/OrderTable";
import GridHeader from "components/GridHeader";
import SixtyButton from "components/SixtyButton";
import { useExportOrders } from "queries/order";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import styles from "./index.module.css";

const RefundRequests = (props) => {

  return (
    <div>
      <GridHeader
        header="Order Refund Request"
        renderButtons={() => (
          <>
          </>
        )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.orderTable}>
          <OrderTable status={"PendingRefund"} />
        </div>
      </Panel>
    </div>
  );
};

export default RefundRequests;
