import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";
import { queryClient } from "common/query";
import { saveAs } from "file-saver";


export function useGetBuyerRefundRequests(filter) {
  return useQuery(
    [Endpoints.Order.GetBuyerRefundRequests, filter],
    () =>
      api
        .post(Endpoints.Order.GetBuyerRefundRequests, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetBuyerRefundRequests: useQuery: onError: error", error);
      },
    }
  );
}

export function useApproveRefund() {
  return useMutation(
    (orderId) =>
      api
        .put(Endpoints.Order.ApproveRefund + `/${orderId}`)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useApproveRefund: useMutation: onError: error", error);
      },
    }
  );
}

export function useRejectRefund() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Order.RejectRefund, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useRejectRefund: useMutation: onError: error", error);
      },
    }
  );
}

export function useDownloadOrderStatement() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Order.DownloadOrderInvoice, data, {
          responseType: "blob",
          "Accept-Encoding": "gzip, deflate, br",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            ?.find((n) => n.includes("filename="))
            ?.replace("filename=", "")
            ?.trim();
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename || "data.pdf");
        }),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useDownloadBillingStatement: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetOrderCount() {
  return useQuery(
    [Endpoints.Order.GetOrderCount],
    () =>
      api
        .get(Endpoints.Order.GetOrderCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetOrderCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetOrders(filter) {
  return useQuery(
    [Endpoints.Order.GetOrders, filter],
    () =>
      api
        .post(Endpoints.Order.GetOrders, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetOrders: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetOrderDetail(orderId) {
  return useQuery(
    [Endpoints.Order.GetOrderDetail, orderId],
    () =>
      api
        .get(Endpoints.Order.GetOrderDetail + "/" + orderId)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetOrderDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetOrderStatusByProduct(productId) {
  return useQuery(
    [Endpoints.Order.GetOrderStatusByProduct, productId],
    () =>
      api
        .get(Endpoints.Order.GetOrderStatusByProduct + `/${productId}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "UserGetOrderStatusByProduct: useQuery: onError: error",
          error
        );
      },
      enabled: !!productId,
    }
  );
}

export function useGetRefundReasonDropdown() {
  return useQuery(
    Endpoints.Order.GetRefundReasonDropdown,
    () =>
      api
        .get(Endpoints.Order.GetRefundReasonDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "UserGetRefundReasonDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useAchieveOrder() {
  return useMutation(
    (orderId) =>
      api
        .put(Endpoints.Order.AchieveOrder + `/${orderId}`)
        .then((response) => response?.data),
    {
      onMutate: (orderId) => {
        return { orderId };
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useAchieveOrder: useMutation: onError: error ", error);
      },
      onSettled: (data, err, vars, context) => {
        queryClient.invalidateQueries([
          Endpoints.Order.GetOrderDetail,
          context.orderId,
        ]);
      },
    }
  );
}

export function useMarkAsShipped() {
  return useMutation(
    (orderId) =>
      api
        .put(Endpoints.Order.MarkAsShipped + `/${orderId}`)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useMarkAsShipped: useMutation: onError: error", error);
      },
    }
  );
}

export function useMarkOrdersAsShipped() {
  return useMutation(
    (ids) =>
      api
        .put(Endpoints.Order.MarkOrdersAsShipped, ids)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useMarkOrdersAsShipped: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useUpdateShipping() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Order.UpdateShiping, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdateShipping: useMutation: onError: error", error);
      },
    }
  );
}

export function useOrderRefund() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Order.OrderRefund + "/" + id)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useOrderRefund: useMutation: onError: error", error);
      },
    }
  );
}

export function useExportOrders() {
  return useMutation(
    () =>
      api
        .get(Endpoints.Order.ExportOrders, {
          responseType: "blob",
          "Accept-Encoding": "gzip, deflate, br",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            ?.find((n) => n.includes("filename="))
            ?.replace("filename=", "")
            ?.trim();
          ;
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename || "data.xlsx");
          return response;
        }),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useExportOrders: useMutation: onError: error", error);
      },
    }
  );
}

export function useUpdateOrederShipping() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Order.UpdateOrderShipping, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateOrederShipping: useMutation: onError: error",
          error
        );
      },
    }
  );
}
