import React, { useState } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

const CampaignInstruction = ({ data }) => {

  return (
    <React.Fragment>
      <div className={styles.instructionFormContainer}>
      <div className={styles.instructionForm} >
        <div>

         {data && (
           <Panel className={styles.panelWrap}>
           <PanelHeader
             className={styles.instructioInfoHeader}
             title={"Campaign Instruction"}
           />
           <div className={styles.instructionInfoContainer}>
             <div className={styles.instructionInfoRow}>
               <div className={styles.instructionFullInfoItem}>
                 <div className={styles.instructionInfoHeader}>
                 Instruction
                 </div>
                 <div className={styles.instructionDesc}>
                   {data.instruction}
                 </div>
               </div>

             </div>
           </div>
         </Panel>
         )}

        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CampaignInstruction;
