import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useVoidPayout } from "queries/admin";

import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import ControlledSelect from "components/FormComponents/ControlledSelect";
import Input from "components/FormComponents/ControlledInput";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import { useEffect } from "react";
import { useState } from "react";


const defaultValues = {
  id: "",
  password: "",
};

const PayoutVoidForm = ({ selectedContent, handleClose, actionCompleted }) => {

  const formMethods = useForm({
    defaultValues,
  });

  const { mutateAsync: voidPayout, isLoading: voidLoading } = useVoidPayout();


  const onSubmit = async (data) => {

    const { id } = selectedContent;

    try {

      var model = {
        id: id,
        password: data.password,
      };

      const result = await voidPayout(model);

      if (result) {
        actionCompleted();
        setToast("successfully void payout");
      }
    } catch (e) {
      reportError("error in : PayoutVoidForm: onSubmit, error: ", e);
    }
  };
  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.contentVoidForm}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={styles.sellerRejectForm}>
            
            <div className={styles.Container}>
                  <Input
                    name="password"
                    placeholder="Enter password to confirm your action"
                    label="Password"
                    type={"password"}
                  />
            </div>
            </div>
            <footer className={styles.footer}>
              <SixtyButton
                type="submit"
                variant="warning"
                className={styles.voidBtn}               
                disabled={voidLoading}
              >
                Confirm Action
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={handleClose}
                disabled={voidLoading}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default PayoutVoidForm;
