import Card from "./Components/Card";
import styles from "./index.module.css";
import clsx from "clsx";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import SixtyLink from "components/SixtyLink";

const Invoicing = ({ data }) => {

  return (
    <div>
      <div className={styles.row}>
            <Card
              title={'Subtotal'}
              unit={'MYR ' + data?.total}
              desc={'Subtotal for creator costs.'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
            <Card
              title={'Platform service fees'}
              unit={'10%'}
              desc={'This helps us run our platform and after services.'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
           <Card
              title={'Total'}
              unit={'MYR ' + data?.totalAfterServiceFees}
              desc={'Total amount after service fees'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
            <Card
              title={'Status'}
              unit={data?.topupStatus}
              desc={'Status of your topup'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
      </div>
    </div>
  );
};

export default Invoicing;
