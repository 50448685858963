import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import ControlledSelect from "components/FormComponents/ControlledSelect";
import { useGetPriceTiers } from "queries/product";
import {
  useAssignSellerPriceTier,
  useUpdateSellerStatus,
} from "queries/seller";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import { useHistory } from "react-router";

const defaultValues = {
  autoApproveSellerApplication: false,
  email: "",
  tier: "",
};

const EditSellerNameForm = ({ sellerId, handleClose }) => {
  const history = useHistory();
  const {
    mutateAsync: asignSellerPriceTier,
    isLoading,
  } = useAssignSellerPriceTier();

  const formMethods = useForm({
    defaultValues,
  });

  const onSubmit = async (data) => {
    console.log("data from form", data);
    try {
      const result = await asignSellerPriceTier({
        priceTierId: data.tiers,
        sellerId: sellerId,
      });
      if (result) {
        handleClose();
        setToast("successfully assigned tier to seller");
      }
    } catch (e) {
      handleClose();
      reportError("error in : Seller tier assignment, error: ", e);
    }
  };

  const { data: priceTiersData } = useGetPriceTiers({});

  const priceTiersOptions = priceTiersData?.result?.length
    ? priceTiersData.result.map((option) => ({
        label: option.name,
        value: option.id,
      }))
    : [];

  const {
    mutateAsync: updateSeller,
    isLoading: updatingSeller,
    isSuccess: updatedSeller,
  } = useUpdateSellerStatus();

  const handleRemoveSeller = async () => {
    await updateSeller({
      id: sellerId,
      action: "Remove",
    });
  };

  useEffect(() => {
    if (updatedSeller) {
      history.push("/dashboard/creators");
    }
  }, [history, updatedSeller]);

  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.editSellerFormContainer}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={styles.editSellerForm}>
              {/* <div>
                <ControlledSelect
                  name={`tiers`}
                  options={priceTiersOptions}
                  fullWidth={true}
                  defaultValue="Select a tier"
                  variant="secondary"
                  label="Select a tier"
                />
              </div> */}
              <div className={styles.removeSellerContainer}>
                <SixtyButton variant="text" onClick={handleRemoveSeller}>
                  Remove seller account from dropshipping
                </SixtyButton>
                <div className={styles.subNotes}>
                  This action cannot be undone. All activity on this seller will
                  be blocked immediately.
                </div>
              </div>
            </div>
            <footer className={styles.footer}>
              {/* <SixtyButton
                type="submit"
                className={styles.updateBtn}
                disabled={isLoading || updatingSeller}
              >
                Update
              </SixtyButton> */}
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={() => handleClose()}
                disabled={isLoading || updatingSeller}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default EditSellerNameForm;
