import React, { useState } from "react";
import styles from "./index.module.css";
import { ReactComponent as GoBackIcon } from "assets/icons/Icon-Back-Button.svg";

const BasicPageHeader = ({
  title,
  onGoBack,
  disableBack = false
}) => {

  return (
    <div className={styles.basicHeader}>
      <div className={styles.titleBox}>
        {!disableBack && (
          <div onClick={onGoBack}>
            <GoBackIcon />
          </div>
        )}
        <div className={styles.titleContent}>{title}</div>
      </div>
      <div className={styles.ButtonsContainer}>
        <div></div>   
      </div>
    </div>
  );
};

export default BasicPageHeader;
