import React, { useState } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

const OwnerDetails = ({ data }) => {

  return (
    <React.Fragment>
      <div className={styles.ownerRequestFormContainer}>
      <div className={styles.ownerRequestForm} >
        <div>

         {data && (
           <Panel className={styles.panelWrap}>
           <PanelHeader
             className={styles.ownerInfoHeader}
             title={"Owner Information"}
           />
           <div className={styles.ownerInfoContainer}>
             <div className={styles.ownerInfoRow}>
               <div className={styles.ownerInfoItem}>
                 <div className={styles.ownerInfoHeader}>
                 Company Name
                 </div>
                 <div
                   className={styles.elipsis}
                   title={data.vendorName}
                 >
                   {data.vendorName}
                 </div>
               </div>
               <div className={styles.ownerInfoItem}>
                 <div className={styles.ownerInfoHeader}>
                   Email
                 </div>
                 <div
                   className={styles.elipsis}
                   title={data.vendorEmail}
                 >
                   {data.vendorEmail}
                 </div>
               </div>

             </div>
           </div>
         </Panel>
         )}

        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OwnerDetails;
