import styles from "./index.module.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import SixtyButton from "components/SixtyButton";
import Avatar from "components/Avatar";
import { ReactComponent as SixtyLogo } from "assets/images/Appbar-Logo.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/Icon-Notification.svg";
import NotificationsFlyOut from "components/NotificationsFlyOut";
import ProfileDropdown from "components/ProfileDropdown";
import { useHistory } from "react-router-dom";
import SixtySelectField from "components/SixtySelect";
import { useGetNotifications, useGetVendorUserDetails } from "queries/vendor";
import GlobalSearch from "components/GlobalSearch";

const createOptions = [
  // {
  //   label: "Create order",
  //   value: "createOrder",
  // },
  {
    label: "Create product",
    value: "createProduct",
  },
  // {
  //   label: "Create promotion",
  //   value: "createPromotion",
  // },
];

export default function Topbar() {
  const [searchValue, setSearch] = useState("");
  const [isNotificationsModalOpen, setNotificationsModalOpen] = useState(false);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const history = useHistory();

  const onSelectAction = (value) => {
    if (value === "createOrder") {
      // ToDo: create order modal open here
    } else if (value === "createProduct") {
      history.push("/dashboard/create-product");
    } else if (value === "createPromotion") {
      history.push("/dashboard/create-promotion");
    }
  };

  const { data: vendorData } = useGetVendorUserDetails();
  const { data: notificationData } = useGetNotifications();

  return (
    <div className={styles.topbar}>
      <div className={styles.leftContainer}>
        <Link to="/dashboard">
          <SixtyLogo />
        </Link>
        {/* <GlobalSearch placeholder={"Search for products, creators"}/> */}
      </div>

      <div className={styles.rightContainer}>
        {/* <SixtySelectField
          options={createOptions}
          defaultOption="Create"
          value={""}
          selectBoxHelperClass={styles.selectBox}
          selectBoxOptionsHelperClass={styles.optionsBox}
          variant="secondary"
          size="regular"
          selectBoxFontHelper={styles.selectBoxText}
          optionBoxFontHelper={styles.optionBoxText}
          onChange={(value) => {
            onSelectAction(value);
          }}
        /> */}
        <div className={styles.notificationBtn}>
          <SixtyButton
            variant="iconButton"
            onClick={() => setNotificationsModalOpen(true)}
          >
            <NotificationIcon />
          </SixtyButton>
          {isNotificationsModalOpen && notificationData && (
            <NotificationsFlyOut
              notificationData={notificationData}
              isOpen={isNotificationsModalOpen}
              onAttemptClose={() => setNotificationsModalOpen(false)}
            />
          )}
        </div>
        <div
          className={styles.avatarBtn}
          onClick={() => setProfileModalOpen(true)}
        >
          <Avatar name={vendorData?.name || "AA"} />
          {isProfileModalOpen && (
            <ProfileDropdown
              isOpen={isProfileModalOpen}
              onAttemptClose={() => setProfileModalOpen(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
