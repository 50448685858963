import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useUpdateSubscription, useUpdateCampaign, useDeleteCampaign } from "queries/admin";
import { useGetSubscriptionDetail } from "queries/subscription";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import PricingPlanForm from "../components/PricingPlanForm";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

const PricingPlanDetail = ({ selectedPricingPlan, handleClose }) => {

const defaultValues = {
    id: '',
    name: '',
    label: '',
    freeTrialLabel: '',
    price: 0,
    subscriptionType: '',
    period: 0,
    active: true,
    freeTrial: false,
    freeTrialSubscriptionType:'',
    freeTrialPeriod: 0,
 };

  const formMethods = useForm({
    defaultValues,
    shouldUnregister: false,
    mode: "onChange",
  });

  const { setValue } = formMethods;
  const history = useHistory();
  const { id } = selectedPricingPlan;

  const { data: pricingInfo, isLoading, isError, isFetchedAfterMount, refetch } = useGetSubscriptionDetail(
    id
  );

  useEffect(() => {

   if (pricingInfo && isFetchedAfterMount) {

    for (const [key, value] of Object.entries({
        id: pricingInfo?.id,
        name: pricingInfo?.name,
        label: pricingInfo?.label,
        freeTrialLabel: pricingInfo?.freeTrialLabel,
        price: pricingInfo?.price ? parseFloat(pricingInfo.price) : 0,
        subscriptionType: pricingInfo?.subscriptionTypeName,
        period: pricingInfo?.period ? parseFloat(pricingInfo.period) : 0,
        active: pricingInfo?.active,
        freeTrial: pricingInfo?.freeTrial,
        freeTrialSubscriptionType: pricingInfo?.freeTrialSubscriptionType,
        freeTrialPeriod: pricingInfo?.freeTrialPeriod ? parseFloat(pricingInfo.freeTrialPeriod) : 0,
        packages: pricingInfo?.packages,
        freeTrialPackages: pricingInfo?.freeTrialPackages
    })) {
      setValue(key, value);
    }
  }

  }, [setValue, pricingInfo, isFetchedAfterMount]);

  const { mutateAsync: updatePlan, 
          isLoading : planLoading 
        } = useUpdateSubscription();

  const onSubmit = async (data) => {
    try {

         const model = {
            id: pricingInfo?.id,
            name: data.name,
            label: data.label,
            freeTrialLabel: data.freeTrialLabel,
            price: parseFloat(data.price),
            subscriptionType: data.subscriptionType,
            period: parseFloat(data.period),
            active: data.active,
            freeTrial: data.freeTrial,
            freeTrialSubscriptionType: data.freeTrialSubscriptionType,
            freeTrialPeriod: parseFloat(data.freeTrialPeriod),
            packages: data.packages,
            freeTrialPackages: data.freeTrialPackages,
         };
     
         var result = await updatePlan(model);

         if(result){
            refetch();
            setToast("successfully update pricing plan");
            handleClose();
         }
        
    } catch (e) {
      reportError(
        "error in : PricingPlanDetail: onSubmit, error: ",
        e
      );
    }
  };

  return (
    <React.Fragment>
      <div className={styles.pricingRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.pricingRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.pricingInfoHeader}
              title="Edit Pricing Plan"
            />
          <FormProvider {...formMethods}>
            <form
                className={styles.form}
            >
            {pricingInfo && (
             <PricingPlanForm selectedPricingPlan={pricingInfo} />
            )}         
            </form>
        </FormProvider>
         </Panel>

        </div>
      </div>

        <footer className={styles.footer} hidden={isLoading}>
          <SixtyButton
            type="submit"
            onClick={formMethods.handleSubmit(onSubmit)}
            className={styles.approveBtn}
            disabled={planLoading}
          >
            Update
          </SixtyButton>

          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={planLoading}
          >
            Close
          </SixtyButton>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default PricingPlanDetail;
