import styles from "./index.module.css";
import Paper from "components/SixtyPaper";
import Text from "components/SixtyText";
import clsx from "clsx";
import CustomToastContainer from "components/Toast";

export default function LoginContainer({
  header,
  subHeader,
  form,
  formFooter,
  onFormSubmit,
  footer,
  footerLink,
  large = false,
  guidelinesMessage,
  guidelinesLink,
}) {
  const renderHeader = () => {
    return (
      <header className={styles.header}>
        {typeof header === "string" ? (
          <Text type="h1" weight="medium">
            {header}
          </Text>
        ) : (
          header
        )}
        {subHeader && typeof subHeader === "string" ? (
          <>
            <Text color="secondary">{subHeader}</Text>  
          </>
        ) : (
          subHeader
        )}
      </header>
    );
  };

  return (
    <>
      <Paper className={clsx(styles.loginPaper, large && styles.largePaper)}>
        {renderHeader()}
        <form
          onSubmit={onFormSubmit}
          className={styles.form}
          autoComplete="off"
        >
          {form && (
            <>
              <div className={styles.formBody}>{form}</div>
              {formFooter && (
                <div className={styles.formFooter}>{formFooter}</div>
              )}
            </>
          )}
          {guidelinesMessage && (
            <>
             <Text type="p2" color="secondary">{guidelinesMessage}</Text>
             <br/>
            </>      
          )}
           {guidelinesLink && (
            <>
             {guidelinesLink}
             <br/><br/>
            </>      
          )}
          {footer && footer}
        </form>
      </Paper>
      {footerLink && footerLink}
      <div>
        <CustomToastContainer />
      </div>
    </>
  );
}
