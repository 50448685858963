import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useGetSellerRejectionDropdown, useUpdateSellerStatus } from "queries/seller";

import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import ControlledSelect from "components/FormComponents/ControlledSelect";
import Input from "components/FormComponents/ControlledInput";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import { useEffect } from "react";
import { useState } from "react";


const defaultValues = {
  rejectionReason: "",
  message: "",
};

const SellerRejectForm = ({ selectedSeller, handleClose }) => {
  const [sellerRejectOptions, setSellerRejectOptions] = useState([]);
  const [rejectReason, setRejectReason] = useState('');

  const formMethods = useForm({
    defaultValues,
  });
  const { data: sellerRejectReasonDropdown, isLoading: isSellerReasonLoading } = useGetSellerRejectionDropdown();
  
  const { mutateAsync: updateSellerStatus, isLoading: statusLoading } = useUpdateSellerStatus();

  useEffect(() => {
    if(!isSellerReasonLoading && sellerRejectReasonDropdown) {
      setSellerRejectOptions(
        sellerRejectReasonDropdown?.map((option) => ({
        label: option.name,
        value: option.key,
      }))
      );
    }
  },
  [isSellerReasonLoading, sellerRejectReasonDropdown]);

  const onSubmit = async (data) => {

    const { id } = selectedSeller;
    try {

      let reason = data.rejectionReason;

      if(data.rejectionReason == 'Others'){
        reason = data.other;
      }

      var model = {
        id: id,
        action: "Reject",
        reason: reason,
        message: data.message
      };

      const result = await updateSellerStatus(model);
      if (result) {
        handleClose();
        setToast("successfully rejected creator");
      }
    } catch (e) {
      handleClose();
      reportError("error in : SellerRequestForm: onRejectSeller, error: ", e);
    }
  };
  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.sellerRejectFormContainer}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={styles.sellerRejectForm}>
              <div>
                <ControlledSelect
                  name={`rejectionReason`}
                  options={sellerRejectOptions}
                  fullWidth={true}
                  defaultOption="Select a reason"
                  variant="secondary"
                  handleChange={(name, value) => {
                    setRejectReason(value);
                  }}
                  value={rejectReason}
                  defaultValue={rejectReason}
                />
              </div>
              {rejectReason == 'Others' &&(
                  <div className={styles.rejectionReasonContainer}>
                  <Input
                    name="other"
                    placeholder="Reason for rejection"
                    label="Reason to creator"
                    lines="5"
                  />
                </div>
              )}
              <div className={styles.rejectionReasonContainer}>
                <Input
                  name="message"
                  placeholder="Message to creator(optional)"
                  label="Message to creator"
                  lines="5"
                />
              </div>
            </div>
            <footer className={styles.footer}>
              <SixtyButton
                type="submit"
                variant="warning"
                className={styles.rejectBtn}               
                disabled={statusLoading}
              >
                Reject
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={handleClose}
                disabled={statusLoading}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default SellerRejectForm;
