import styles from "./index.module.css";
import clsx from "clsx";

const CampaignSummary = ({ data }) => {
  return (
    <div className={styles.earningHistoryContainer}>
      <div
        className={clsx(
          styles.earningHistoryItem,
          styles.firstEarningHistoryItem
        )}
      >
        <div className={styles.header}>Generated Affiliate</div>
        <div className={styles.value}>{'-'}</div>
      </div>
      <div className={styles.earningHistoryItem}>
        <div className={styles.header}>Traffic</div>
        <div className={styles.value}>{'-'}</div>
      </div>
      <div className={styles.earningHistoryItem}>
        <div className={styles.header}>Influencers</div>
        <div className={styles.value}>{data?.totalSellers}</div>
      </div>
      <div
        className={clsx(
          styles.earningHistoryItem,
          styles.firstEarningHistoryItem
        )}
      >
        <div className={styles.header}>Products</div>
        <div className={styles.value}>{data?.totalProducts}</div>
      </div>
    </div>
  );
};

export default CampaignSummary;
