import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useGetProductSessionDetail, useSetupProductSessionShipment, useRejectProductRequest, useApproveProductRequest } from "queries/product";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Button from "components/SixtyButton";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import { useHistory } from "react-router-dom";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import { Link } from 'react-router-dom';

import { useForm, FormProvider } from "react-hook-form";
import Input from "components/FormComponents/ControlledInput";
import ReactWhatsapp from 'react-whatsapp';

const defaultValues = {
    shippingCompany: '',
    trackingCode: ''
};

const ProductRequestDetail = ({ selectedRequest, handleClose }) => {

  const role = localStorage.getItem("userRole");
  const { id } = selectedRequest;

  const { data: requestInfo, isLoading, isError, refetch , isFetchedAfterMount} = useGetProductSessionDetail(
    id
  );

  const formMethods = useForm({
    defaultValues,
  });

  const { setValue } = formMethods;

  const { mutateAsync: UpdateShipment, 
          isLoading : shipmentLoading 
        } = useSetupProductSessionShipment();

  const {
         mutateAsync: rejectRequest,
         isLoading: rejectLoading,
        } = useRejectProductRequest();

  const {
          mutateAsync: approveRequest,
          isLoading: approveLoading,
        } = useApproveProductRequest();

    const onUpdateShipment = async (data) => {
      try {
      
        let model = {
          id: id,
          shippingCompany: data?.shippingCompany,
          trackingCode: data?.trackingCode
        };

        var result = await UpdateShipment(model);

        if(result){
           refetch();
           setToast("successfully update shipment");
           handleClose();
        }
       
     } catch (e) {
        reportError(
          "error in : onUpdateShipment: onSubmit, error: ",
          e
        );
     }
  };

  const onApproved = async () => {
    try {

         var result = await approveRequest(id);

         if(result){
            refetch();
            setToast("successfully update status");
            handleClose();
         }
        
    } catch (e) {
      reportError(
        "error in : onApproved: onSubmit, error: ",
        e
      );
    }
  };

  const onRejected = async () => {
    try {

        var result = await rejectRequest(id);

        if(result){
           refetch();
           setToast("successfully update status");
           handleClose();
        }
       
   } catch (e) {
     reportError(
       "error in : onRejected: onSubmit, error: ",
       e
     );
   }
  };

  const onPreview = () => {    
     const path = `${requestInfo?.postingRedirectUrl}`;
     window.open(path, "_blank")
  };

  const onReviewProlfile = () => {    
    window.open(requestInfo?.sellerProfileRedirectUrl, "_blank")
  };

  useEffect(() => {

    if (requestInfo && isFetchedAfterMount) {

     for (const [key, value] of Object.entries({
         shippingCompany: requestInfo?.shippingCompany,
         trackingCode: requestInfo?.trackingCode,
     })) {
       setValue(key, value);
     }
   }
 
   }, [setValue, requestInfo, isFetchedAfterMount]);

  return (
      <>
    <React.Fragment>
      <div className={styles.productRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.productRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
          <div className={styles.btnActionContainer}>
          {requestInfo?.postingRedirectUrl && (
            <div>
              
                  <SixtyButton
                    variant="tertiary"
                    onClick={() => onPreview()}
                    className={styles.approveBtn}
                  >
                    {"Preview Creator Upload"}
                 </SixtyButton>
                
            </div>
          )}
        <SixtyButton
            variant="tertiary"
            onClick={() => onReviewProlfile()}
            className={styles.approveBtn}
        >
          {"Review Creator Profile"}
        </SixtyButton>

        {requestInfo?.sellerContact && (
            <ReactWhatsapp number={requestInfo?.sellerContact} message="" className={clsx(styles.button, styles.tertiary)}>
               Open Whatapps
            </ReactWhatsapp>                
        )}
        </div>

          {requestInfo?.postingMediaPath && (
            <div>
                <div className={styles.subHeader}>
                    <span> Media </span>
                    <div className={styles.profPhotoContainer}>
                    <div className={styles.profPhotoDropzone}>
                    <img
                        src={requestInfo?.postingMediaPath}
                        className={clsx(styles.img)}
                        alt="No preview"
                    /> 
                    </div>
                    </div>
               </div>
            </div>
          )}
  
          </Panel>

          <Panel className={styles.panelWrap}>
          <PanelHeader
              className={styles.productInfoHeader}
              title="Product Request"
            />
            <div className={styles.productInfoContainer}>
              <div className={styles.productInfoRow}>
                <div className={styles.productInfoItem}>
                  <div className={styles.productInfoHeader}>
                    Status
                  </div>
                  <div>
                    {requestInfo?.status}
                  </div>
                </div>
                <div className={styles.productInfoItem}>
                  <div className={styles.productInfoHeader}>Requested by</div>
                  <div>
                    {requestInfo?.sellerName}
                  </div>
                </div>
                <div className={styles.productInfoItem}>
                  <div className={styles.productInfoHeader}>Company</div>
                  <div>
                    {requestInfo?.supplierName}
                  </div>
                </div>
              </div>
              <div className={styles.productInfoRow}>
                <div className={styles.productInfoItem}>
                  <div className={styles.productInfoHeader}>
                    Product Count
                  </div>
                  <div>
                    {requestInfo?.requestedProductCount}
                  </div>
                </div>
                <div className={styles.productInfoItem}>
                  <div className={styles.productInfoHeader}>Product Value</div>
                  <div>
                    {requestInfo?.productValue}
                  </div>
                </div>
                <div className={styles.productInfoItem}>
                  <div className={styles.productInfoHeader}>Contact Number</div>
                  <div>
                    {requestInfo?.sellerContact}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          {requestInfo && requestInfo?.status == 'Approved' && (   
              <>         
                <Panel className={styles.panelWrap}>
                <PanelHeader
                  className={styles.sellerInfoHeader}
                  title="Shipping Address"
                />
                <div className={styles.productInfoContainer}>
                <div className={styles.productInfoRow}>
                    <div className={styles.productInfoItem}>
                      <div className={styles.productInfoHeader}>Unit Number</div>
                      <div>
                        {requestInfo?.shippingAddress?.unitNumber}
                      </div>
                    </div>
                    <div className={styles.productInfoItem}>
                      <div className={styles.productInfoHeader}>Floor Number</div>
                      <div>
                        {requestInfo?.shippingAddress?.floorNumber}
                      </div>
                    </div>
                    <div className={styles.productInfoItem}>
                      <div className={styles.productInfoHeader}>Building Name</div>
                      <div>
                        {requestInfo?.shippingAddress?.buildingName}
                      </div>
                    </div>
                  </div>
                  <div className={styles.productInfoRow}>
                    <div className={styles.productInfoItem}>
                      <div className={styles.productInfoHeader}>Street 1</div>
                      <div>
                        {requestInfo?.shippingAddress?.street1}
                      </div>
                    </div>
                    <div className={styles.productInfoItem}>
                      <div className={styles.productInfoHeader}>Street 2</div>
                      <div>
                        {requestInfo?.shippingAddress?.street2}
                      </div>
                    </div>
                    <div className={styles.productInfoItem}>
                      <div className={styles.productInfoHeader}>Street 3</div>
                      <div>
                        {requestInfo?.shippingAddress?.street3}
                      </div>
                    </div>
                  </div>
                  <div className={styles.productInfoRow}>
                    <div className={styles.productInfoItem}>
                      <div className={styles.productInfoHeader}>Poscode</div>
                      <div>
                        {requestInfo?.shippingAddress?.postcode}
                      </div>
                    </div>
                    <div className={styles.productInfoItem}>
                      <div className={styles.productInfoHeader}>City</div>
                      <div>
                        {requestInfo?.shippingAddress?.city}
                      </div>
                    </div>
                    <div className={styles.productInfoItem}>
                      <div className={styles.productInfoHeader}>State</div>
                      <div>
                        {requestInfo?.shippingAddress?.state}
                      </div>
                    </div>
                  </div>
                  <div className={styles.productInfoRow}>
                    <div className={styles.productInfoItem}>
                      <div className={styles.productInfoHeader}>Country</div>
                      <div>
                        {requestInfo?.shippingAddress?.country}
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
              </>
          )}
  
          {requestInfo && ["Approved"].includes(requestInfo.status) && (
            <Panel className={styles.panelWrap}>
                <PanelHeader
                className={styles.payoutInfoHeader}
                title="Product Shipment"
                />
                    <FormProvider {...formMethods}>
                        <form
                            className={styles.form}
                        >
                            <div className={styles.row}>             
                                <Input
                                    name="shippingCompany"                             
                                    label="Shipping Company"
                                    className={styles.nameInput}
                                    defaultValue={requestInfo?.shippingCompany}
                                />                 
                            </div>
                            <div className={styles.row}>
                                <Input
                                    name="trackingCode"
                                    label="Tracking Code"                             
                                    className={styles.nameInput}
                                    defaultValue={requestInfo?.trackingCode}
                                />
                            </div>
                        </form>
                    </FormProvider>
            </Panel>
          )}
  

          <Panel className={styles.panelWrap}>        
          <PanelHeader
              className={styles.campaignInfoHeader}
              title="Selected Products"
          />
           <div className={styles.subHeader}>
                <span> Products </span>
                <React.Fragment>
                     {requestInfo && requestInfo?.requestedProducts?.length > 0 && (
                         <div className={styles.profPhotoContainer}>
                            {requestInfo?.requestedProducts.map((img) => (
                                <div key={img.id} className={styles.profPhotoDropzone}>
                                  <a href={img?.redirectUrl} target="_blank">
                                  <img
                                       src={img?.path}
                                       className={clsx(styles.img)}
                                       alt={img?.name}
                                     />
                                  </a>   
                                  <span>{img?.remark}</span>
                                 </div>
                             ))}
                         </div>
                      )}
                 </React.Fragment>
           </div>
          </Panel>
      
        </div>
      </div>

      <footer className={styles.footer} hidden={isLoading}>

          {requestInfo && requestInfo?.status == 'Pending' && (   
              <>         
            <SixtyButton
               type="submit"
               onClick={onApproved}
               className={styles.approveBtn}
               disabled={approveLoading || rejectLoading}
             >
               {"Approve"}
             </SixtyButton>
             <SixtyButton
                type="submit"
                variant="warning"
                onClick={onRejected}
                className={styles.approveBtn}
                disabled={approveLoading || rejectLoading}
             >
               {"Reject"}
             </SixtyButton>
            </>
          )}

          {requestInfo && requestInfo?.status == 'Approved' && (   
              <>         
             <SixtyButton
                type="submit"
                onClick={formMethods.handleSubmit(onUpdateShipment)}
                className={styles.approveBtn}
                disabled={shipmentLoading}
             >
               {"Update Shipment"}
             </SixtyButton>
            </>
          )}
   
          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={shipmentLoading || approveLoading || rejectLoading }
          >
            Close
          </SixtyButton>

        </footer>
      </div>
    </React.Fragment>
    </>
  );
};

export default ProductRequestDetail;
