import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import BlogTable from "./components/BlogTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import Tabs from "components/Tabs";
import { useGetBlogStatusCount } from "queries/admin";

const Blog = () => {

  const role = localStorage.getItem("userRole");
  const history = useHistory();

  const {
    data: blogData,
  } = useGetBlogStatusCount();

  const onBlogClick = (value) => {
     history.push("/admin/blogs/update-blog/" + value.id)
  };

  return (
    <div>
      <GridHeader header="Blogs" 
         renderButtons={() => (
          <>
            {role == "InternalUser" && (
              <Button onClick={() => history.push("/admin/blogs/vendor-selection")}>Add New Blog</Button>
            )}     
          </>
         )}
      />
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Draft",
              recordsCount: blogData?.draftCount,
              TabComponent: BlogTable,
              props: {
                status: "Draft",
                onContentClick: onBlogClick
              },
            },
            {
              tabId: "2",
              label: "Published",
              recordsCount: blogData?.publishedCount,
              TabComponent: BlogTable,
              props: {
                status: "Published",
                onContentClick: onBlogClick
              },
            },
          ]}
        ></Tabs>
      </Panel>
    </div>
  );
};

export default Blog;
