import React, { useMemo } from "react";
import styles from "./index.module.css";
import Table from "components/SixtyTable";
import { usePagination, useTable } from "react-table";
import { useGetSellerLatestOrders } from "queries/seller";
import { ReactComponent as OrderIcon } from "assets/icons/Icon-Orders.svg";
import EmptyGrid from "components/EmptyGrid";
import { PanelActionButton } from "components/SixtyPanel";

const LatestOrders = ({ sellerId }) => {
  const { data: sellerLatestOrders, isLoading, isError, refetch } = useGetSellerLatestOrders(sellerId);

  const columns = useMemo(
    () => [
      {
        Header: "Order",
        accessor: "transactionCode",
        style: {
          paddingLeft: 16,
        },
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Customer",
        accessor: "customer",
        style: {
          width: "3%",
        },
      },
      {
        Header: "Total",
        Cell: ({
          value = {
            totalAmount: "RM888.00",
            quantity: 1,
          },
          row,
        }) => (
          <div>
            <div className={styles.totalAmount}>
              {"RM" + row?.original?.total || 0}
            </div>
            {/* // Remove this default 1 when api returns this */}
            <div className={styles.quantity}>
              {row?.original?.totalItems || 1 + "items"}
            </div>
          </div>
        ),
      },
      {
        Header: "Order status",
        accessor: "status",
        Cell: ({ value }) => <div className={styles.orderStatus}>{value}</div>,
      },
    ],
    []
  );

  let data = useMemo(
    () => (sellerLatestOrders?.result ? sellerLatestOrders.result : []),
    [sellerLatestOrders]
  );

  const tableInstance = useTable(
    {
      data,
      columns,
    },
    usePagination
  );

    // if no data in table (default filters)
    function isDataEmpty() {
      if (sellerLatestOrders?.result?.length === 0) {
        return true;
      }
      return false;
    }

  return (
    <>
    {(!Array.isArray(sellerLatestOrders?.result) || isDataEmpty()) &&
      !isLoading && (
        <>
          <EmptyGrid
            Icon={OrderIcon}
            emptyMessage={
              isError
                ? "Error in fetching data"
                : "The orders will show up here"
            }
          />

          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}

      {!isError && !isDataEmpty() && (
        <>
          <React.Fragment>
            <Table instance={tableInstance} />
          </React.Fragment>
        </>
      )};
    </>
  );
};

export default LatestOrders;
