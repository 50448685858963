import React, { useState, useEffect, useRef } from "react";
import { Controller, useFieldArray, useFormContext, useWatch, useForm, FormProvider } from "react-hook-form";
import { useGetVideoStoryDropdown, useCreateUgcCampaign } from "queries/campaign";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import ChipInput from "components/FormComponents/ControlledChipInput";
import SixtySelect from "components/SixtySelect";
import Checkbox from "components/SixtyCheckBox";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import { ReactComponent as IconSingleImageDrop } from "assets/icons/Icon-SingleImageDrop.svg";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import Button from "components/SixtyButton";
import Confirmation from "permissions/Confirmation";
import Modal from "components/Modal";
import { isEmpty, isArray } from 'lodash';
import { setToast } from "components/Toast";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";

const defaultValues = {
    supplierReference: '',
    supplierCode: '',
    supplierName: '',
    supplierId: '',
    name: '',
    instruction: '',
    price: 0,
    priceType: '',
    isPublic: '',
    expiredDate: '',
    defaultCampaignProducts: [],
    campaignProducts: [],
    campaignSellers: []
 };

const UpdateForm = ({selectedOrder}) => {

    const { 
        socialMediaId,
        videoStoryId,
        campaignType,
        priceType,
        isPublic,
        channelInvitationLink,
        instruction,
        status,
        applicantLimit,
        defaultCampaignImagePath,
        picContact,
        redemptionType,
        redemptionReferenceUrl,
        deliverableType,
        deliverableReferenceUrl,
        shipmentType,
        appointmentType,
        appointmentLocation,
        isRequiredPurchaseProduct,
        productPurchasePrice,
        shippingCoveredCost,
        purchaseLink,
        isCoveredShippingCost,
        isRequiredEcommerceReview,
        isRequiredDraftReview,
        isProfitSharing,
        isRequiredOwnership,
        isAdsUsage,
        isRequiredVideoEditing,
        postingExpiredOnUtc,
        topupStatus,
        tasks,
        guidelines,
        campaignProducts,
        campaignSellers,
        campaignAudiences,
        recruitmentRequirement } = selectedOrder;

    const [isComfirmationOpen, setComfirmationOpen] = useState(false);
    const [videoStory, setVideoStory] = useState(null);
    const [race, setRace] = useState(null);
    const [noOfRecruitment, setNoOfRecruitment] = useState(applicantLimit);
    const [gender, setGender] = useState(null);
    const [objective, setObjective] = useState("VideoCreation");
    const [_shipmentType, setShipmentType] = useState('');
    const [videoEditing, setVideoEditing] = useState(false);
    const [adsCopyright, setAdsCopyright] = useState(false);
    const [ownership, setOwnership] = useState(false);

    const { control, watch, setValue, errors } = useFormContext();
    const openDropzone = useRef(() => {});
    const comfirmationRef = useRef();

    const mainImage = useWatch({
        control,
        name: "mainImages",
        defaultValue: [],
    });

    const onMainImageDelete = () => {
        setValue("mainImages", []);
    };

    const onDefaultRawFile = () => {
        setToast("Raw File is minimum requirement", "error");
    };

    const onNonEditable = () => {
        setToast("Not editable", "error");
    };

    const imageSelected = typeof(mainImage) === 'string' ?
    [{
      path: mainImage
    }] :
    isArray(mainImage) ? mainImage : [];
    
    const ConfirmationTitle = () => {
        return (
          <div className={styles.modalTitleBar}>
            <div className={styles.modalContainer}>
              <div className={styles.modalTitle}>{'Confirmation'}</div>
            </div>
            <div>
              <CloseIcon
                className={styles.closeIcon}
                onClick={() => setComfirmationOpen(false)}
              />
            </div>
          </div>
        );
      };

    const { data: videoStories } = useGetVideoStoryDropdown();
 
    const videoStoryOptions = videoStories?.length
    ? videoStories.map((item) => {
        return { label: item.name, value: item.id };
      })
    : [];

    const onChangeStory = async (value) => {
        setVideoStory(value);
        var script = control.getValues('tasks');

        if(script){
            setComfirmationOpen(true);
            return;
        }
        else{
            videoStories.map((item) => {
                if(value === item.id){
                    if(item.instruction){
                        setValue("tasks", item.instruction);
                    }
                    else{
                        setValue("tasks", "");
                    }
                    
                }
            })
        } 
     };
   
     const onCloseStory = () => {
       setComfirmationOpen(false);
     };

    const updatePricing = () => {

        // temp solutions
        var rawFilePrice = 60;   
        var videoEditingPrice = 60;
        var adsCopyrightPrice = 80;
        var ownershipPrice = 120;

        var total = rawFilePrice + 0;

        if(videoEditing){
            total = total + videoEditingPrice;
        }

        if(adsCopyright){
            total = total + adsCopyrightPrice;
        }

        if(ownership){
            total = total + ownershipPrice;
        }
       
        setValue("price", String(parseFloat(total).toFixed(2)));
    }
  
    const updateStoryScript = () => {
        if(videoStory){
            videoStories.map((item) => {
                if(videoStory === item.id){
                    if(item.instruction){
                        setValue("tasks", item.instruction);
                    }
                    else{
                        setValue("tasks", "");
                    }
                    
                }
            })
        } 
    }

    const onOpenWhatapps = async () => {
        try {

         var contact = picContact;
         contact = contact.replace("+",'');
         
          window.open(`https://api.whatsapp.com/send/?phone=${contact}&text&type=phone_number&app_absent=0`, "_blank")
            
        } catch (e) {
          reportError(
            "error in : EditOrder: onOpenWhatapps, error: ",
            e
          );
        }
      };

     useEffect(() => {
        updatePricing();
        control.setValue('isRequiredVideoEditing', videoEditing);
        control.setValue('isAdsUsage', adsCopyright);
        control.setValue('isRequiredOwnership', ownership);
     }, [videoEditing, adsCopyright, ownership]);

     useEffect(() => {
        setVideoStory(videoStoryId);
        setVideoEditing(isRequiredVideoEditing);
        setAdsCopyright(isAdsUsage);
        setOwnership(isRequiredOwnership);
        setNoOfRecruitment(applicantLimit);

        if(recruitmentRequirement?.race){
            setRace(recruitmentRequirement?.race);
        }

        if(recruitmentRequirement?.gender){
            setGender(recruitmentRequirement?.gender);
        }

        if(shipmentType){
            setShipmentType(shipmentType);
        }
        
    }, []);

    return (
        <div>
                <div className={styles.form}>
                <div style={{height:15}} ></div>
                <div className={styles.section}>Image of your product / service</div>
                <hr className={styles.hr} />
                <ImageDropzone
                    control={control}
                    error={errors.mainImages}
                    name="mainImages"
                    title="Drag your image here"
                    subTitle="or Click to browse for a file"
                    DefaultThumb={IconSingleImageDrop}
                    maximumFiles={1}
                    onDropAccepted={(name, selectedFiles) => {
                        setValue("mainImages", selectedFiles);
                        setValue("isNewUpdate", 'true');
                    }}
                    setOpenDropzonehook={(open) => {
                        openDropzone.current = open;
                    }}
                    selectedFiles={imageSelected}
                />
                <Input
                    name={`isNewUpdate`}
                    control={control}
                    type="hidden"
                    label="Visibility"
                    defaultValue={'false'}
                    value={'false'}
                    className={styles.hidden}
                />
                {mainImage?.length > 0 && (
                        <div className={styles.actionButtonsContainer}>
                        <div>
                            <Button
                            onClick={() => openDropzone.current()}
                            variant="secondary"
                            >
                            Replace
                            </Button>
                        </div>
                        <div>
                            <Button onClick={onMainImageDelete} variant="secondary">
                            Delete
                            </Button>
                        </div>
                        </div>
                )}
                <div style={{height:15}} ></div>
                <div className={styles.section}>Order Options</div>
                <hr className={styles.hr} />
                <div className={styles.fullFormRow}>
                <Select
                        name="objective"
                        error={errors.target}
                        options={[
                        {
                            label: "Video Creation (UGC)",
                            selectedLabel: "Video Creation (UGC)",
                            value: "VideoCreation",
                        },
                        {
                            label: "Promote on creator social media (Coming Soon)",
                            selectedLabel: "Promote on creator social media (Coming Soon)",
                            value: "Posting",
                            disabled: true
                        },
                        ]}
                        control={control}
                        fullWidth
                        defaultOption="Objective"
                        handleChange={(name, value) => {
                            setObjective(value);
                        }}
                        isOptionDisabled={(option) =>
                            option.disabled
                        }
                        value={objective}
                        defaultValue={objective}
                    />
                </div>  
                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* Creator create creative video content for your products, you may use the video for own social media post, ads and so on. </div>
                </div>
                <div style={{height:15}} ></div>
                <div className={styles.section}>Order Information</div>
                <hr className={styles.hr} />
                <div className={styles.fullFormRow}>
                  <Input
                    name="name"
                    label="Video objective e.g routine tutorial for xxx shampoo"
                    defaultValue=""
                    />
                </div>  
                <div className={styles.fullFormRow}>
                    <Select
                        name="applicantLimit"
                        error={errors.target}
                        options={[
                        {
                            label: "1",
                            selectedLabel: "1",
                            value: "1",
                        },
                        {
                            label: "2 (Coming Soon)",
                            selectedLabel: "2 (Coming Soon)",
                            value: "2",
                            disabled: true
                        },
                        {
                            label: "3 (Coming Soon)",
                            selectedLabel: "3 (Coming Soon)",
                            value: "3",
                            disabled: true
                        },
                        ]}
                        control={control}
                        fullWidth
                        defaultOption="No of creators to be recruit"
                        handleChange={(name, value) => {
                            setNoOfRecruitment(value);
                        }}
                        value={noOfRecruitment}
                        defaultValue={noOfRecruitment}
                    />
                </div>  
                <div style={{height:15}} ></div>
                <div className={styles.section}>Notifications</div>
                <hr className={styles.hr} />
                <div className={styles.formRow}>
                  <Input
                    name="picContact"
                    label="Contact number e.g 0145645678"
                    defaultValue=""
                  />
                  <Button onClick={onOpenWhatapps} variant="tertiary">
                    Whatapps User
                  </Button>
                </div>  
                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* Contact number will received progress notification from 60Sec Whatapps bot. </div>
                </div>
                <div className={styles.formRow}>
                  <ControlledDatepicker
                    name="expiredDate"
                    error={errors?.expiredDate}
                    label="Task Reminder"
                    control={control}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                  />
                  <Button onClick={onMainImageDelete} variant="secondary" disabled={true}>
                     Notify Creators (Coming Soon)
                  </Button>
                </div> 
                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* Creator will received task reminder daily, 7 days before deadline, default deadline will be 1 month since campaign created.</div>
                </div>
                <div style={{height:15}} ></div>
                <div className={styles.section}>Creator Requirement</div>
                <hr className={styles.hr} />
                {/* <div className={styles.fullFormRow}>
                    <Select
                        name="race"
                        error={errors.target}
                        options={[
                        {
                            label: "None",
                            selectedLabel: "None",
                            value: "None",
                        },
                        {
                            label: "Chinese",
                            selectedLabel: "Chinese",
                            value: "Chinese",
                        },
                        {
                            label: "Malay",
                            selectedLabel: "Malay",
                            value: "Malay",
                        },
                        {
                            label: "Indian",
                            selectedLabel: "Indian",
                            value: "Indian",
                        },
                        ]}
                        control={control}
                        fullWidth
                        defaultOption="Preferred race"
                        handleChange={(name, value) => {
                            setRace(value);
                        }}
                        value={race}
                        defaultValue={race}
                    />
                </div> */}
                <div className={styles.fullFormRow}>
                    <Input
                    name="instruction"
                    label="Requirement"
                    lines={8}
                    defaultValue={''}
                    />
                </div>
                <div style={{height:15}} ></div>
                <div className={styles.section}>Shipment Options</div>
                <hr className={styles.hr} />
                <div className={styles.fullFormRow}>
                <Select
                        name="shipmentType"
                        error={errors.target}
                        options={[
                        {
                            label: "None",
                            selectedLabel: "None",
                            value: "None",
                        },
                        {
                            label: "Manual Shipment",
                            selectedLabel: "Manual Shipment",
                            value: "Manual",
                        },
                        {
                            label: "Voucher Code (Coming Soon)",
                            selectedLabel: "Voucher Code (Coming Soon)",
                            value: "VoucherCode",
                            disabled: true
                        },
                        ]}
                        control={control}
                        fullWidth
                        defaultOption="Shipment Options"
                        handleChange={(name, value) => {
                            setShipmentType(value);
                        }}
                        value={_shipmentType}
                        defaultValue={_shipmentType}
                    />
                </div>  
                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* Ship your product to creator for content creations, set to none if no product involve for your campaign. </div>
                </div>
                {["Pending"].includes(topupStatus) && (
                    <>
                        <div style={{height:15}} ></div>
                        <div className={styles.section}>Creator Costs</div>
                        <hr className={styles.hr} />
                        <div className={styles.formRow}>
                        <div className={styles.section}>Raw File      : MYR  60</div>
                        <div className={styles.section}>Video Editing : MYR  60</div>
                        <div className={styles.section}>Ads Copyright : MYR  80</div>
                        <div className={styles.section}>Ownership     : MYR 120</div>
                        </div>
                        <div className={styles.fullFormRow}>
                            <Input
                            name="price"
                            error={errors.price}
                            control={control}
                            label={"Cost per creator"}
                            type="number"
                            min={0}
                            step=".01"
                            defaultValue={100}
                            disabled={true}
                            />
                        </div>
                        <div className={styles.fullFormRow}>
                            <div className={styles.section}>
                                {!videoEditing && !adsCopyright && !ownership && (
                                    "You may use the raw video for your own video editing"
                                )}

                                {!videoEditing && !adsCopyright && ownership && (
                                    "You may use the raw video for your own video editing"
                                )}

                                {videoEditing && !adsCopyright && !ownership && (
                                    "By default you can use the video for social media e.g Instagram, Facebook, Tiktok and so on..."
                                )}

                                {videoEditing && !adsCopyright && ownership && (
                                    "You can use the video for website, shop and so on..."
                                )}

                                {adsCopyright && !ownership && (
                                    "You can use the video for posting and boosting on social media."
                                )}

                                {adsCopyright && ownership && (
                                    "You may have full ownership to the video and use for ads, website, shop and so on."
                                )}
                            </div>
                        </div>
                        <div style={{height:15}} ></div>
                        <div className={styles.fullFormRow}>
                            <Checkbox
                            label="Raw File"
                            checked={true}
                            onChange={(e) => onDefaultRawFile()}
                            uncheckStyleVariant="greyedOut"
                            />
                        </div>
                        <div className={styles.fullFormRow}>
                            <div className={styles.section}>* Raw video file without any editing or effect</div>
                        </div>
                        <div className={styles.fullFormRow}>
                            <Checkbox
                            label="Video Editing"
                            checked={videoEditing}
                            onChange={(e) => setVideoEditing(e.target.checked)}
                            uncheckStyleVariant="greyedOut"
                            />
                            <Input
                            name={`isRequiredVideoEditing`}
                            control={control}
                            type="hidden"
                            label="Visibility"
                            defaultValue={false}
                            value={false}
                            className={styles.hidden}
                            />
                        </div>

                        <div className={styles.fullFormRow}>
                            <div className={styles.section}>* Creator edit your raw video to become more interesting e.g background music, voice over, text, effect</div>
                        </div>

                        <div className={styles.fullFormRow}>
                            <Checkbox
                            label="ads copyright"
                            checked={adsCopyright}
                            onChange={(e) => setAdsCopyright(e.target.checked)}
                            uncheckStyleVariant="greyedOut"
                            />
                            <Input
                            name={`isAdsUsage`}
                            control={control}
                            type="hidden"
                            label="Visibility"
                            defaultValue={false}
                            value={false}
                            className={styles.hidden}
                            />
                        </div>

                        <div className={styles.fullFormRow}>
                            <div className={styles.section}>* Copyright to use creator content for ads purpose</div>
                        </div>

                        <div className={styles.fullFormRow}>
                            <Checkbox
                            label="Content Ownership"
                            checked={ownership}
                            onChange={(e) => setOwnership(e.target.checked)}
                            uncheckStyleVariant="greyedOut"
                            />
                            <Input
                            name={`isRequiredOwnership`}
                            control={control}
                            type="hidden"
                            label="Visibility"
                            defaultValue={false}
                            value={false}
                            className={styles.hidden}
                            />
                        </div>
                    </>
                )}

                {["Paid"].includes(topupStatus) && (
                    <>
                        <div style={{height:15}} ></div>
                        <div className={styles.section}>Creator Costs</div>
                        <hr className={styles.hr} />
                        <div className={styles.formRow}>
                        <div className={styles.section}>Raw File      : MYR  60</div>
                        <div className={styles.section}>Video Editing : MYR  60</div>
                        <div className={styles.section}>Ads Copyright : MYR  80</div>
                        <div className={styles.section}>Ownership     : MYR 120</div>
                        </div>
                        <div className={styles.formRow}>
                            <Input
                            name="price"
                            error={errors.price}
                            control={control}
                            label={"Cost per creator"}
                            type="number"
                            min={0}
                            step=".01"
                            defaultValue={100}
                            disabled={true}
                            />
                        </div>
                        <div style={{height:15}} ></div>
                        <div className={styles.fullFormRow}>
                            <div className={styles.section}>* Below settings not editable after top-up</div>
                        </div>
                        <div className={styles.fullFormRow}>
                            <Checkbox
                            label="Raw File"
                            checked={true}
                            onChange={(e) => onDefaultRawFile()}
                            uncheckStyleVariant="greyedOut"
                            />
                        </div>
                        <div className={styles.fullFormRow}>
                            <div className={styles.section}>* Raw video file without any editing or effect</div>
                        </div>
                        <div className={styles.fullFormRow}>
                            <Checkbox
                            label="Video Editing"
                            checked={videoEditing}
                            onChange={(e) => onNonEditable()}
                            uncheckStyleVariant="greyedOut"
                            />
                        </div>

                        <div className={styles.fullFormRow}>
                            <div className={styles.section}>* Creator edit your raw video to become more interesting e.g background music, voice over, text, effect</div>
                        </div>

                        <div className={styles.fullFormRow}>
                            <Checkbox
                            label="ads copyright"
                            checked={adsCopyright}
                            onChange={(e) => onNonEditable()}
                            uncheckStyleVariant="greyedOut"
                            />
                        </div>

                        <div className={styles.fullFormRow}>
                            <div className={styles.section}>* Copyright to use creator content for ads purpose</div>
                        </div>

                        <div className={styles.fullFormRow}>
                            <Checkbox
                            label="Content Ownership"
                            checked={ownership}
                            onChange={(e) => onNonEditable()}
                            uncheckStyleVariant="greyedOut"
                            />
                        </div>
                    </>
                )}

                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* You can own the video content for branding purpose e.g website, offline shop</div>
                </div>

                <div style={{height:15}} ></div>
                <div className={styles.section}>Preferred story</div>
                <hr className={styles.hr} />
                {videoStoryOptions && videoStoryOptions.length > 0 && (
                    <div className={styles.fullFormRow}>
                        <Select
                        name="videoStoryId"
                        error={errors.videoStory}
                        options={videoStoryOptions}
                        control={control}
                        fullWidth
                        defaultOption="Select your video story"
                        handleChange={(name, value) => {         
                            onChangeStory(value);
                        }}
                        value={videoStory}
                        defaultValue={videoStory}
                        />
                    </div>
                )}
                {videoStory && (
                    <div className={styles.fullFormRow}>
                        <div className={styles.section}>* Feel free to edit the below script template with your own product information.</div>
                    </div>
                )}
                <div style={{height:15}} ></div>
                <div className={styles.section}>Script for creators</div>
                <hr className={styles.hr} />
                <div className={styles.fullFormRow}>
                    <Input
                    name="tasks"
                    label="Tasks"
                    lines={24}
                    defaultValue={''}
                    />
                </div>
       
                {isComfirmationOpen && (
                    <Modal
                    isOpen={isComfirmationOpen}
                    onAttemptClose={() => {
                        setComfirmationOpen(false);
                    }}
                    modalRef={comfirmationRef}
                    Title={ConfirmationTitle}
                    >
                    <Confirmation
                        title={'Your scripts will be overwrite.'}
                        handleSuccess={updateStoryScript}
                        handleClose={onCloseStory}
                    />
                    </Modal>
                )}
                </div>                          
    </div>
  );
};

export default UpdateForm;
