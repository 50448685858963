import { useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints } from "../common/constants";
import { reportError } from "../crashlytics";

export function useGetCustomers(filter) {
  return useQuery(
    [Endpoints.Customer.GetCustomers, filter],
    () =>
      api
        .post(Endpoints.Customer.GetCustomers, filter)
        .then((response) => response?.data), {enabled: true},
    {
      onError: (error) => {
        reportError("useGetCustomers: useQuery: onError: error", error);
      },
    }
  );
}
