import { useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";

export function useGetOrderSummary(filter) {
  return useQuery(
    [Endpoints.Report.GetOrderSummary, filter],
    () =>
      api
        .post(Endpoints.Report.GetOrderSummary, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGetOrderSummary: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetTotalSalesFullReport(reportDate) {
  return useQuery(
    [Endpoints.Report.GetTotalSalesFullReport, reportDate],
    () =>
      api
        .get(
          Endpoints.Report.GetTotalSalesFullReport +
            (reportDate ? `?reportDate=${reportDate}` : "")
        )
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useGetTotalSalesFullReport: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetTopSellerSalesSummary(reportDate) {
  return useQuery(
    [Endpoints.Report.GetTopSellerSalesSummary, reportDate],
    () =>
      api
        .get(
          Endpoints.Report.GetTopSellerSalesSummary +
            (reportDate ? `?reportDate=${reportDate}` : "")
        )
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useGetTopSellerSalesSummary: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetTopProductSalesSummary(reportDate) {
  return useQuery(
    [Endpoints.Report.GetTopProductSalesSummary, reportDate],
    () =>
      api
        .get(
          Endpoints.Report.GetTopProductSalesSummary +
            (reportDate ? `?reportDate=${reportDate}` : "")
        )
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useGetTopProductSalesSummary: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetTotalOrdersSummary(reportDate) {
  return useQuery(
    [Endpoints.Report.GetTotalOrdersSummary, reportDate],
    () =>
      api
        .get(
          Endpoints.Report.GetTotalOrdersSummary +
            (reportDate ? `?reportDate=${reportDate}` : "")
        )
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useGetTotalOrdersSummary: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetTotalSalesSummary(reportDate) {
  return useQuery(
    [Endpoints.Report.GetTotalSalesSummary, reportDate],
    () =>
      api
        .get(
          Endpoints.Report.GetTotalSalesSummary +
            (reportDate ? `?reportDate=${reportDate}` : "")
        )
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGetTotalSalesSummary: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerAnalyticSummary(reportDate) {
  return useQuery(
    [Endpoints.Report.GetSellerAnalyticSummary, reportDate],
    () =>
      api
        .get(
          Endpoints.Report.GetSellerAnalyticSummary +
            (reportDate ? `?reportDate=${reportDate}` : "")
        )
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useGetSellerAnalyticSummary: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetAnalyticSummary(reportDate) {
  return useQuery(
    [Endpoints.Report.GetAnalyticSummary, reportDate],
    () =>
      api
        .get(
          Endpoints.Report.GetAnalyticSummary +
            (reportDate ? `?reportDate=${reportDate}` : "")
        )
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGetAnalyticSummary: useQuery: onError: error", error);
      },
    }
  );
}
