import React, { useState, useRef, useEffect } from "react";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import SellerTierTable from "./components/SellerTierTable";
import AddTierForm from "./components/AddTierForm";
import GridHeader from "components/GridHeader";
import { useAddPriceTier } from "queries/product";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Loader from "components/Loader";

const SellerTiers = () => {
  const [isAddTierModalOpen, setAddTierModalOpen] = useState(false);

  const history = useHistory();
  const sellerTierModalRef = useRef();

  const {
    mutateAsync: addPriceTier,
    isLoading: addingTier,
    isSuccess: addingSuccess,
  } = useAddPriceTier();

  const AddTierTitle = () => {
    return (
      <div>
        <div className={styles.modalTitle}>Add tier</div>
      </div>
    );
  };

  const onExport = () => {
    console.log("export clicked");
  };

  const onAddTier = () => {
    setAddTierModalOpen(true);
  };

  const onCreateTierSubmit = async (data) => {
    try {
      await addPriceTier(data);
    } catch (error) {
      reportError("AddPriceTier: onSubmit: error: ", error);
    }
  };

  useEffect(() => {
    if (addingSuccess) {
      setToast("Tier successfully added");
      setAddTierModalOpen(false);
    }
  }, [addingSuccess]);

  const onCreateTierCancel = () => {
    setAddTierModalOpen(false);
  };

  return (
    <div>
      <GridHeader
        header="Commission Tiers"
        renderButtons={() => (
          <>
            <Button onClick={onAddTier}>Add tier</Button>
            {/* <Button variant="secondary" onClick={onExport}>
              Export
            </Button> */}
          </>
        )}
      />
      <Panel>
        <div className={styles.sellerTable}>
          <SellerTierTable />
        </div>
      </Panel>
      {isAddTierModalOpen && (
        <Modal
          isOpen={isAddTierModalOpen}
          onAttemptClose={() => {
            history.push("/dashboard/tiers");
            setAddTierModalOpen(false);
          }}
          modalRef={sellerTierModalRef}
          Title={AddTierTitle}
        >
          {addingTier ? (
            <Loader text="Adding tier" />
          ) : (
            <AddTierForm
              onCreateTierSubmit={onCreateTierSubmit}
              onCreateTierCancel={onCreateTierCancel}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default SellerTiers;
