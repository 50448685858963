import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import SellerTable from "./components/SellerTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import InviteSellerForm from "./components/InviteSellerForm";
import GridHeader from "components/GridHeader";
import SixtyButton from "components/SixtyButton";

const Sellers = (props) => {
  const [isInviteSellerOpen, setIsInviteSellerOpen] = useState(false);
  const history = useHistory();
  const inviteSellerRef = useRef();

  const Title = () => {
    return (
      <div className={styles.inviteSellerModalTitleBar}>
        <div className={styles.modalTitle}>Invite seller</div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setIsInviteSellerOpen(false)}
          />
        </div>
      </div>
    );
  };

  const Actions = () => {
    return (
      <footer>
        <SixtyButton form="invite-seller-form" type="submit">
          Invite
        </SixtyButton>
      </footer>
    );
  };

  const onInviteSeller = () => {
    setIsInviteSellerOpen(true);
  };

  const onExport = () => {};

  return (
    <div>
      <GridHeader
        header="Creators"
        renderButtons={() => (
          <>
            {/* <Button onClick={onInviteSeller}>Invite creator</Button> */}
            {/* <Button variant="secondary" onClick={onExport}>
              Export
            </Button> */}
          </>
        )}
      />
      <Panel>
        <div className={styles.sellerTable}>
          <SellerTable />
        </div>
      </Panel>
      {isInviteSellerOpen && (
        <Modal
          isOpen={isInviteSellerOpen}
          onAttemptClose={() => {
            history.push("/dashboard/creators");
            setIsInviteSellerOpen(false);
          }}
          modalRef={inviteSellerRef}
          Title={Title}
          Actions={Actions}
        >
          <InviteSellerForm handleInviteSellerOpen={setIsInviteSellerOpen} />
        </Modal>
      )}
    </div>
  );
};

export default Sellers;
