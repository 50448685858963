import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import ContentPublishingTable from "../components/ContentPublishingTable";
import DraftContentTable from "../components/DraftContentTable";
import CampaignContentDetail from "../components/CampaignContentDetails";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import { setToast } from "components/Toast";
import BasicPageHeader from "../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetCampaignPublishContentRequestCount } from "queries/posting";

const ContentPublishing = () => {
  const [isPostOpen, setPostOpen] = useState(false);
  const history = useHistory();
  const params = useParams();
  const postRef = useRef();
  const [selectedPost, setSelectedPost] = useState({});
  const role = localStorage.getItem("userRole");

  const {
    data: requestData,
  } = useGetCampaignPublishContentRequestCount(params.id);

  const PostTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedPost.title} />
          </div>
          <div className={styles.modalTitle}>{selectedPost.title}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPostOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onContentClick = (value) => {
    setSelectedPost(value);
    setPostOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <BasicPageHeader title="Post Contents" onGoBack={onGoBack} />
      {role == 'Supplier' && (
        <Panel className="tabs">
          <Tabs
            activeTab={"1"}
            tabs={[
              {
                tabId: "1",
                label: "Pending Approval",
                recordsCount: requestData?.pendingDraftContentCount,
                TabComponent: DraftContentTable,
                props: {
                  status: "Pending",
                  campaignId: params.id,
                  onContentClick: onContentClick
                },
              },
              {
                tabId: "2",
                label: "Revised Content",
                recordsCount: requestData?.rejectedDraftContentCount,
                TabComponent: DraftContentTable,
                props: {
                  status: "Rejected",
                  campaignId: params.id,
                  onContentClick: onContentClick
                },
              },
              {
                  tabId: "3",
                  label: "Pending Posting",
                  recordsCount: requestData?.approvedDraftContentCount,
                  TabComponent: DraftContentTable,
                  props: {
                    status: "Approved",
                    campaignId: params.id,
                    onContentClick: onContentClick
                  },
              },
              {
                  tabId: "4",
                  label: "Posted Content",
                  recordsCount: requestData?.postedContentCount,
                  TabComponent: DraftContentTable,
                  props: {
                    status: "Posted",
                    campaignId: params.id,
                    onContentClick: onContentClick
                  },
              },
              {
                tabId: "5",
                label: "Pending Repost",
                recordsCount: requestData?.rePostContentCount,
                TabComponent: DraftContentTable,
                props: {
                  status: "Repost",
                  campaignId: params.id,
                  onContentClick: onContentClick
                },           
              },
              {
                tabId: "6",
                label: "Completed",
                recordsCount: requestData?.completedContentCount,
                TabComponent: ContentPublishingTable,
                props: {
                  status: "Completed",
                  campaignId: params.id,
                  onContentClick: onContentClick
                },           
              }
            ]}
          ></Tabs>
        </Panel>
      )}

      {role == 'InternalUser' && (
        <Panel className="tabs">
          <Tabs
            activeTab={"1"}
            tabs={[
              {
                tabId: "1",
                label: "Pending Approval",
                recordsCount: requestData?.pendingDraftContentCount,
                TabComponent: DraftContentTable,
                props: {
                  status: "Pending",
                  campaignId: params.id,
                  onContentClick: onContentClick
                },
              },
              {
                tabId: "2",
                label: "Rejected Content",
                recordsCount: requestData?.rejectedDraftContentCount,
                TabComponent: DraftContentTable,
                props: {
                  status: "Rejected",
                  campaignId: params.id,
                  onContentClick: onContentClick
                },
              },
              {
                  tabId: "3",
                  label: "Pending Posting",
                  recordsCount: requestData?.approvedDraftContentCount,
                  TabComponent: DraftContentTable,
                  props: {
                    status: "Approved",
                    campaignId: params.id,
                    onContentClick: onContentClick
                  },
              },
              {
                  tabId: "4",
                  label: "Posted Content",
                  recordsCount: requestData?.postedContentCount,
                  TabComponent: DraftContentTable,
                  props: {
                    status: "Posted",
                    campaignId: params.id,
                    onContentClick: onContentClick
                  },
              },
              {
                tabId: "5",
                label: "Pending Repost",
                recordsCount: requestData?.rePostContentCount,
                TabComponent: DraftContentTable,
                props: {
                  status: "Repost",
                  campaignId: params.id,
                  onContentClick: onContentClick
                },           
              },
              {
                tabId: "6",
                label: "Completed",
                recordsCount: requestData?.completedContentCount,
                TabComponent: ContentPublishingTable,
                props: {
                  status: "Completed",
                  campaignId: params.id,
                  onContentClick: onContentClick
                },           
              },
              {
                tabId: "7",
                label: "Trash",
                recordsCount: requestData?.suspendedContentCount,
                TabComponent: ContentPublishingTable,
                props: {
                  status: "Suspended",
                  campaignId: params.id,
                  onContentClick: onContentClick
                },           
              },
            ]}
          ></Tabs>
        </Panel>
      )}

      {isPostOpen && (
        <Modal
          isOpen={isPostOpen}
          onAttemptClose={() => {
            setPostOpen(false);
          }}
          modalRef={postRef}
          Title={PostTitle}
        >
          <CampaignContentDetail
            selectedPost={selectedPost}
            handleClose={() => setPostOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default ContentPublishing;
