import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import PayoutWalletTable from "../components/PayoutWalletTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import PayoutRequestDetails from "../components/PayoutRequestDetails";
import Tabs from "components/Tabs";

const PayoutWallets = () => {

 
  const onPayoutClick = (value) => {
    const path = `/admin/influencer/histories/${value?.code}`;
    window.open(path, "_blank")
  };

  return (
    <div>
      <GridHeader header="Creators Payout Wallets" 
         renderButtons={() => (
          <>
          </>
         )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.payoutTable}>
          <PayoutWalletTable onRecordClick={onPayoutClick} />
        </div>
      </Panel>

    </div>
  );
};

export default PayoutWallets;
