import { useState, useEffect, useCallback } from "react";
import { useGetCampaignAnalytics, useUpdateCampaignProgress } from "queries/campaign";
import { reportError } from "crashlytics";
import { format } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import Loader from "components/Loader";
import Text from "components/SixtyText";
import Button from "components/SixtyButton";
import Stepper from "components/Stepper";
import Link from "components/SixtyLink";
import Recruitment from "./Recuitment";
import Shipment from "./Shipment";
import ContentCreation from "./ContentCreation";
import Reporting from "./Reporting";
import Posting from "./Posting";
import ClosedCampaign from "./Close";
import Paper from "components/SixtyPaper";
import clsx from "clsx";
import { setToast } from "components/Toast";

import styles from "./index.module.css";

export default function CampaignStepAutomate() {
  const params = useParams();
  const [currentProgress, setCurrentProgress] = useState(0);
  const { data: campaignData, isLoading: campaignLoading } = useGetCampaignAnalytics(params.id);

  const [progress, setProgress] = useState(["active", "notReached", "notReached", "notReached", "notReached"]);

  const { mutateAsync: updateProgress, 
          isLoading : progressLoading 
  } = useUpdateCampaignProgress();

  const onUpdateProgress = async (progress) => {
  try {

      const model = {
          id: params.id,
          progress: progress,
      };
      
      var result = await updateProgress(model);

      if(result){
          setToast("successfully update progress");
          loadProgressStatus(progress);
      }
    
  } catch (e) {
      reportError(
        "error in : CampaignStepAutomate: onUpdateProgress, error: ",
        e
      );
    }
  };

  function GetCurrentProgress(targetProgress){
    let totalProgress = 4;
    let result = [];

    for(let i = 0; i <= totalProgress; i++){
       if(i == targetProgress){
        result.push("active")
       }
       else if(i != targetProgress && i <= currentProgress){
        result.push("visited")
       }
       else if(i != targetProgress && i > currentProgress){
        result.push("notReached")
       }
    }
    return result;
  }

  const goToRecruiment = () => {
      const progress = 0;
      // const updatedProgress = ["active", "notReached", "notReached", "notReached", "notReached", "notReached"];
      const updatedProgress = GetCurrentProgress(progress);
      setProgress(updatedProgress);
  };

  const goToShipment = () => {
    const progress = 1;
    //const updatedProgress = ["visited", "active", "notReached", "notReached", "notReached", "notReached"];
    const updatedProgress = GetCurrentProgress(progress);
    setProgress(updatedProgress);
  };

  const goToContentCreation = () => {
    const progress = 2;
   // const updatedProgress = ["visited", "visited", "active", "notReached", "notReached", "notReached"];
    const updatedProgress = GetCurrentProgress(progress);
    setProgress(updatedProgress);
  };

  const goToPosting = () => {
    const progress = 2;
    //const updatedProgress = ["visited", "visited", "visited", "active", "notReached", "notReached"];
    const updatedProgress = GetCurrentProgress(progress);
    setProgress(updatedProgress);
  };

  const goToReporting = () => {
    const progress = 3;
    //const updatedProgress = ["visited", "visited", "visited", "visited", "active", "notReached"];
    const updatedProgress = GetCurrentProgress(progress);
    setProgress(updatedProgress);
  };

  const goToClose = () => {
    const progress = 4;
    //const updatedProgress = ["visited", "visited", "visited", "visited", "visited", "active"];
    const updatedProgress = GetCurrentProgress(progress);
    setProgress(updatedProgress);
  };

  const loadProgressStatus = (newProgress) => {

        switch(newProgress){
          case 'Recruitment':
              setCurrentProgress(0);
              const pendingProgress = ["active", "notReached", "notReached", "notReached", "notReached"];
              setProgress(pendingProgress);
          break;
          case 'Shipment':
              setCurrentProgress(1);
              const shipmentProgress = ["visited", "active", "notReached", "notReached", "notReached"];
              setProgress(shipmentProgress);
          break;
          case 'ContentCreation':
              //ignore content creation
              setCurrentProgress(2);
              const contentProgress = ["visited", "visited", "active", "notReached", "notReached"];
              setProgress(contentProgress);
          break;
          case 'Posting':
              setCurrentProgress(2);
              const postingProgress = ["visited", "visited", "active", "notReached", "notReached"];
              setProgress(postingProgress);
          break;
          case 'Reporting':
              setCurrentProgress(3);
              const reportingProgress = ["visited", "visited", "visited", "active", "notReached"];
              setProgress(reportingProgress);
          break;
          case 'Close':
              setCurrentProgress(4);
              const closeProgress = ["visited", "visited", "visited", "visited", "active"];
              setProgress(closeProgress);
        break;
      }

  };

  useEffect(() => {
    if (campaignData) {
      loadProgressStatus(campaignData?.progressStatus);
    }
  }, [campaignData, campaignLoading]);

  
  const renderForm = () => {

    if(campaignLoading){
        return(
         <div style={{width:200, height:200}} >
            <Loader/>
         </div>
        )
    }
    else{
        return (
            <>
              {progress[0] === "active" && (
                <Recruitment campaign={campaignData}/>
              )}
              {progress[1] === "active" && (
                <Shipment campaign={campaignData}/>
              )}
              {progress[2] === "active" && (
                <Posting campaign={campaignData}/>
              )}
              {progress[3] === "active" && (
                <Reporting/>
              )}
              {progress[4] === "active" && (
               <ClosedCampaign/>
              )}              
            </>
          );
    }
  };

  const renderNavigation = () => {
    if (progress[0] === "active") {
      return (
        <>
            <div className={styles.footer}>
            <Button variant="secondary" size="large" onClick={goToShipment} className={styles.spacing} disabled={progressLoading}>
                  Next
            </Button>
             {currentProgress == 0 && (
                <Button size="large" onClick={() => onUpdateProgress("Shipment")} disabled={progressLoading}>
                   Recruitment Completed
               </Button>
             )}
            </div>
            <div className={styles.margin}></div>
            <Text type="p1" color="secondary">
                Influencer will join your campaign through our app.
            </Text>
        </>
      );
    }
    else if (progress[1] === "active"){
      return (
        <>
          <div className={styles.margin}></div>
           <div className={styles.footer}>
              <Button variant="secondary" size="large" onClick={goToRecruiment} className={styles.spacing} disabled={progressLoading}>
                  Back
              </Button>
              <Button variant="secondary" size="large" onClick={goToPosting} className={styles.spacing} disabled={progressLoading}>
                  Next
              </Button>
              {currentProgress == 1 && (
                 <Button size="large" type="submit" onClick={() => onUpdateProgress("Posting")} disabled={progressLoading}>
                      Shipment Completed
                 </Button>
               )}
           </div>
           <div className={styles.margin}></div>
           <Text type="p1" color="secondary">
              Manage and keep track your shipment or appointment.
          </Text>
       </>
      );
    }
    else if (progress[2] === "active"){
      return (
        <>
           {/* <Text type="p1" color="secondary">
              Review your influencer draft contents.
          </Text>
          <div className={styles.margin}></div>
           <div className={styles.footer}>
              <Button variant="secondary" size="large" onClick={goToShipment} className={styles.spacing} disabled={progressLoading}>
                  Back
              </Button>
              <Button variant="secondary" size="large" onClick={goToPosting} className={styles.spacing} disabled={progressLoading}>
                  Next
              </Button>
              {currentProgress == 2 && (
                   <Button size="large" type="submit" onClick={() => onUpdateProgress("Posting")} disabled={progressLoading}>
                        Content Creation Completed
                   </Button>
              )}
           </div> */}
           <div className={styles.footer}>
              <Button variant="secondary" size="large" onClick={goToShipment} className={styles.spacing} disabled={progressLoading}>
                  Back
              </Button>
              <Button variant="secondary" size="large" onClick={goToReporting} className={styles.spacing} disabled={progressLoading}>
                  Next
              </Button>
              {currentProgress == 2 && (
                   <Button size="large" type="submit" onClick={() => onUpdateProgress("Reporting")} disabled={progressLoading}>
                        Posting Completed
                   </Button>
              )}
           </div>
           <div className={styles.margin}></div>
           <Text type="p1" color="secondary">
             Your influencer will update social media link e.g instagram post, do check on submitted job and approve the job.
          </Text>
       </>
      );
    }
    else if (progress[3] === "active"){
      return (
        <>
           <div className={styles.footer}>
              <Button variant="secondary" size="large" onClick={goToPosting} className={styles.spacing} disabled={progressLoading}>
                  Back
              </Button>
              <Button variant="secondary" size="large" onClick={goToClose} className={styles.spacing} disabled={progressLoading}>
                  Next
              </Button>
              {currentProgress == 3 && (
                   <Button size="large" type="submit" onClick={() => onUpdateProgress("Close")} disabled={progressLoading}>
                         Reporting Completed
                   </Button>
              )}

           </div>
           <div className={styles.margin}></div>
           <Text type="p1" color="secondary">
              View social media post insight.
          </Text>
       </>
      );
    }
    else if (progress[4] === "active"){
      return (
        <>
           <div className={styles.footer}>
              <Button variant="secondary" size="large" onClick={goToReporting}>
                  Back
              </Button>
           </div>
           <div className={styles.margin}></div>
           <Text type="p1" color="secondary">
             Your campaign is completed and closed.
          </Text>
       </>
      );
    }
    else if (progress[5] === "active"){
      return (
        <>
      
       </>
      );
    }
  };

  return (
    <>
    <div className={styles.container}>
    <Paper className={clsx(styles.stepPaper)}>
         {/* <div className={styles.subHeader}>
              <Text type="p1" color="secondary">
                Manage your campaign 
              </Text>
          </div> */}
          <Text type="h1" color="secondary">
                {'Campaign : ' + campaignData?.name}
         </Text>

         <div className={styles.margin}></div>

         {["Offline"].includes(campaignData?.campaignType) && (
          <>
             <Stepper
              status={progress}
              labels={["Recruitment", "Appointment", "Posting", "Reporting", "Completed"]}
             />
          </>
         )}
         {["Standard", "WebsiteReview"].includes(campaignData?.campaignType) && (
          <>
            <Stepper
              status={progress}
              labels={["Recruitment", "Shipment", "Posting", "Reporting", "Completed"]}
             />
          </>
         )}
         <div className={styles.margin}></div>
         <div className={styles.margin}></div>
         {renderNavigation()}
         <div className={styles.margin}></div>
        {renderForm()}     
    </Paper>
    </div>
    </>
  );
}
