import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import CampaignRequestTable from "../components/CampaignRequestTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";

const CampaignRequests = () => {

  const [isCampaignDetailOpen, setCampaignDetailOpen] = useState(false);
  const history = useHistory();
  const campaignDetailRef = useRef();
  const [selectedCampaign, setSelectedCampagin] = useState({});

  const onCampaignClick = (value) => {   
    const path = `/admin/campaigns/InfluencerRequests/${value?.id}`;
    history.push(path);
  };

  return (
    <div>
      <GridHeader header="Campaign Join Requests" 
         renderButtons={() => (
          <>
          </>
         )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.campaignTable}>
          <CampaignRequestTable onCampaignClick={onCampaignClick} />
        </div>
      </Panel>
    </div>
  );
};

export default CampaignRequests;
