import styles from "./index.module.css";
import Paper from "components/SixtyPaper";
import Text from "components/SixtyText";
import clsx from "clsx";

export default function UserAuthenticationContainer({
  header,
  subHeader,
  form,
  formFooter,
  onFormSubmit,
  footer,
  footerLink,
  large = false,
}) {
  const renderHeader = () => {
    return (
      <header className={styles.header}>
        {typeof header === "string" ? (
          <Text type="h1" weight="medium">
            {header}
          </Text>
        ) : (
          header
        )}
        {subHeader && typeof subHeader === "string" ? (
          <Text color="secondary">{subHeader}</Text>
        ) : (
          subHeader
        )}
      </header>
    );
  };

  return (
    <>
      <Paper className={clsx(styles.userAuthenticationPaper, large && styles.largePaper)}>
        {renderHeader()}
        <form onSubmit={onFormSubmit} className={styles.form}>
          {form && (
            <>
              <div className={styles.formBody}>
                {form}
              </div>
              {formFooter && (
                <div className={styles.formFooter}>{formFooter}</div>
              )}
            </>
          )}
          {footer && footer}
        </form>
      </Paper>
      {footerLink && footerLink}
    </>
  );
}
