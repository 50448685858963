import React from 'react';
import styles from './index.module.css';
import Avatar from "components/Avatar";


const SellerInfo = (props) => {
    console.log(props)

    const { name, email, contact, joinedDate, tier, status, lastLoginToApp } = props?.sellerInfo;

    return (
        <React.Fragment>
            {
                name &&
                (<div className={styles.sellerNameContainer}>
                    <div className={styles.avatarContainer}><Avatar name={name} className={styles.sellerAvatar} /></div>
                    <div className={styles.sellerName}>{name}</div>
                </div>
                )
            }
            <div className={styles.sellerBody}>
                {email &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Email
                            </div>
                        <div className={styles.sellerFieldValue}>
                            <span className={styles.email}>{email} </span>
                        </div>
                    </div>
                }
                {contact &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Mobile number
                                </div>
                        <div className={styles.sellerFieldValue}>
                            {contact}
                        </div>
                    </div>
                }
                {joinedDate &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Joined date
                                </div>
                        <div className={styles.sellerFieldValue}>
                            {joinedDate}
                        </div>
                    </div>
                }
                {/* {tier &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Tier
                                </div>
                        <div className={styles.sellerFieldValue}>
                            {tier}
                        </div>
                    </div>
                } */}
                {status &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Status
                                </div>
                        <div className={styles.sellerFieldValue}>
                            {status}
                        </div>
                    </div>
                }
                {lastLoginToApp &&
                    <div className={styles.sellerFieldContainer}>
                        <div className={styles.sellerFieldName}>
                            Last login to app
                                </div>
                        <div className={styles.sellerFieldValue}>
                            {lastLoginToApp}
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default SellerInfo;
