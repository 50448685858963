import { useState } from "react";
import Link from "components/SixtyLink";
import Button from "components/SixtyButton";
import OTPInput from "components/OtpInput";
import Text from "components/SixtyText";
import LoginContainer from "./components/LoginContainer";
import { useHistory } from "react-router-dom";
import getParameterByName from "utils/getParamsByName";
import {
  useEmailOtpVerification,
  useResendEmailVerificationCode,
} from "queries/account";
import { reportError } from "crashlytics";
import { setTokens } from "utils/auth";

export default function VerifyEmail() {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);

  const email = getParameterByName("email");

  const {
    mutateAsync: emailOtpVerification,
    isLoading: emailOtpVerificationLoading,
  } = useEmailOtpVerification();

  const {
    mutate: resendEmailVerificationCode,
    isLoading: resendEmailVerificationCodeLoading,
  } = useResendEmailVerificationCode();

  const history = useHistory();

  const onSubmit = async () => {
    try {
      if (otp.length && otp.length === 6 && !emailOtpVerificationLoading) {
        const result = await emailOtpVerification(otp);
        if (result?.result) {
          setTokens(result.result);
          history.push(`/setup-profile${email ? `?email=${email}` : ""}`);
        }
      } else {
        setError(true);
        throw new Error("OTP is incorrect: 6 Characters required");
      }
    } catch (error) {
      reportError("Verification: onSubmit: error: ", error);
    }
  };

  const onResendVerificationCode = async () => {
    try {
      if (!resendEmailVerificationCodeLoading)
        await resendEmailVerificationCode();
    } catch (error) {
      reportError("Verification: onResendVerificationCode: error: ", error);
    }
  };

  return (
    <LoginContainer
      header="Verify your email address"
      subHeader={
        <>
          We have sent a 6 digit code to your email address{" "}
          <Text inline weight="medium">
            {email}
          </Text>{" "}
          (
          <Link
            to={`/auth/sign-up?email=${email}`}
            inline
            color="orange"
            weight="medium"
          >
            Change
          </Link>
          )
        </>
      }
      form={
        <OTPInput
          autoFocus
          isNumberInput
          length={6}
          onChangeOTP={(otp) => {
            if (error) {
              setError(false);
            }
            setOtp(otp);
          }}
          error={error}
        />
      }
      formFooter={
        <Button variant="text" onClick={onResendVerificationCode}>
          Resend code
        </Button>
      }
      footer={
        <Button
          type="button"
          size="large"
          fullWidth
          onClick={onSubmit}
          disabled={emailOtpVerificationLoading}
          className={"verify-otp-btn"}
        >
          Create account
        </Button>
      }
      footerLink={
        <Link to="/auth/login" color="orange">
          Remember your password? Try logging in
        </Link>
      }
      guidelinesMessage ={"** You may check the email on spam folder if not found on inbox"}
      guidelinesLink={
        <Link external={true} to="https://60sec.asia/email-troubleshoot/" type="p1" color="orange">
          Not received email?
       </Link>
      }
    />
  );
}
