import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import SellerSellectionTable from "../components/InfluencerSelectionTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import PayoutForm from "../components/PayoutForm";
import BasicPageHeader from "../../Components/BasicHeader";

const InfluencerSelection = () => {

  const [isPayoutDetailOpen, setPayoutDetailOpen] = useState(false);
  const history = useHistory();
  const payoutDetailRef = useRef();
  const [selectedSeller, setSelectedSeller] = useState({});

  const InfluencerDetailTitle = () => {
    return (
      <div className={styles.payoutDetailModalTitleBar}>
        <div className={styles.payoutNameContainer}>
          <div>
            <Avatar name={selectedSeller.name} />
          </div>
          <div className={styles.modalTitle}>{selectedSeller.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPayoutDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onSellerClick = (value) => {
    setSelectedSeller(value);
    setPayoutDetailOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <BasicPageHeader title="Influencer Selection" onGoBack={onGoBack} />
      <Panel className={styles.panelWrap}>
        <div className={styles.payoutTable}>
          <SellerSellectionTable onSellerClick={onSellerClick} />
        </div>
      </Panel>
      {isPayoutDetailOpen && (
        <Modal
          isOpen={isPayoutDetailOpen}
          onAttemptClose={() => {
            setPayoutDetailOpen(false);
          }}
          modalRef={payoutDetailRef}
          Title={InfluencerDetailTitle}
        >
          <PayoutForm
            selectedSeller={selectedSeller}
            handleClose={() => setPayoutDetailOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default InfluencerSelection;
