import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useForm, FormProvider } from "react-hook-form";
import { getFormattedDateString } from "utils/date.util";
import { useApproveCampaignRequest, useRejectCampaignRequest } from "queries/campaign";
import { useGetSellerRegistrationDetail } from "queries/seller";
import { useGetCampaignSellerDetail } from "queries/campaign";
import { useInviteSeller, useOverrideSellerCommission } from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import ReactWhatsapp from 'react-whatsapp';
import OverrideCommissionForm from './components/OverrideCommissionForm';

const defaultValues = {
  overrideCommission: 0,
 };

const InfluencerRequestDetails = ({campaign, selectedSeller, handleClose, viewOnly = false }) => {
  const { code, id } = selectedSeller;
  const role = localStorage.getItem("userRole");

  const formMethods = useForm({
    defaultValues,
  });

  const { setValue } = formMethods;

  const { data: sellerInfo, isLoading, isError, refetch } = useGetSellerRegistrationDetail(
    code
  );

  const { data: sellerRequestInfo, isLoading: isCampaignSellerLoading, isFetchedAfterMount } = useGetCampaignSellerDetail(
    id
  );

  useEffect(() => {

    if (sellerRequestInfo && isFetchedAfterMount) {

     for (const [key, value] of Object.entries({
         overrideCommission: sellerRequestInfo?.overrideCommission,
     })) {
       setValue(key, value);
     }
   }
 
   }, [setValue, sellerRequestInfo, isFetchedAfterMount]);

  const { mutateAsync: approveSeller, 
          isLoading : approveLoading 
  } = useApproveCampaignRequest();

  const onApproveSeller = async () => {
    try {
      const result = await approveSeller(id);

      if (result) {
        handleClose();
        setToast("successfully approve influencer access");
      }
    } catch (e) {
      reportError(
        "error in : InfluencerRequestDetails: onApproveSeller, error: ",
        e
      );
    }
  };

    const { mutateAsync: rejectSeller, 
        isLoading : rejectLoading 
    } = useRejectCampaignRequest();

    const onRejectSeller = async () => {
        try {
            const result = await rejectSeller(id);

            if (result) {
              handleClose();
              setToast("successfully reject influencer access");
            }
         } catch (e) {
              reportError(
                "error in : InfluencerRequestDetails: onRejectSeller, error: ",
               e
              );
          }
    };

  const { mutateAsync: overrideCommission, 
    isLoading : overrideLoading 
  } = useOverrideSellerCommission();

const onOverrideCommission = async (data) => {
  try {

      var model = {
        sellerCode: code,
        campaignId: campaign?.id,
        overrideCommission: data?.overrideCommission ? parseFloat(data?.overrideCommission) : null
      };

      const result = await overrideCommission(model);

      if (result) {
        handleClose();
        setToast("successfully update override commission");
      }
   } catch (e) {
        reportError(
          "error in : InfluencerRequestDetails: onOverrideCommission, error: ",
         e
        );
    }
};

  const isUrlValid = (userInput) => {

    if(!userInput) return (false);

    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return (false);
    else
        return (true);
  };

  const onBrowseProfile = async () => {
    try {
     
      window.open(`/admin/influencers/AnalyticDetails/${code}`, "_blank")
        
    } catch (e) {
      reportError(
        "error in : InfluencerRequestDetails: onBrowseProfile, error: ",
        e
      );
    }
  };

  return (
    <React.Fragment>
      <div className={styles.sellerRequestFormContainer}>
        {(isLoading || isCampaignSellerLoading) && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.sellerRequestForm} hidden={isLoading || isCampaignSellerLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Account Information"
            />
            {sellerInfo?.contactNumber && !viewOnly && (
              <div className={styles.btnActionContainer}>             
              <ReactWhatsapp number={sellerInfo?.contactNumber} message="" className={clsx(styles.button, styles.tertiary)}>
                Open Whatapps
              </ReactWhatsapp>
              </div>
            )}
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Code</div>
                  <div>
                    {sellerInfo?.code}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={clsx(styles.email, styles.elipsis, styles.sellerInfoHeader)}>Email</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.email}
                  >
                    {sellerInfo?.email}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Contact</div>
                  <div>
                    {sellerInfo?.contactNumber}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Personal details"
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerInfoItem)}>
                  <div className={styles.sellerInfoHeader}>Name</div>
                  <div>
                    {sellerInfo?.name}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}> Gender </div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.gender}
                  >
                    {sellerInfo?.gender}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Race</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.race}
                  >
                    {sellerInfo?.race}
                  </div>
                </div>
              </div>

              <div className={styles.sellerInfoRow}>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Occupation</div>
                  <div>{sellerInfo?.occupation}</div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}> Date Of Birth </div>
                  <div>{sellerInfo?.dateOfBirth}</div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}> Register Date </div>
                  <div>{sellerInfo?.registerDate}</div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Job Status"
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
              <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Status</div>
                  <div>{sellerRequestInfo?.postStatus}</div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}> Posted Link </div>
                    {isUrlValid(sellerRequestInfo?.postLink) &&(
                     <a className={styles.link} href={sellerRequestInfo?.postLink} target={"_blank"} rel={"noreferrer"}>
                         {sellerRequestInfo?.postLink}
                     </a>
                    )}
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Audience Type Details"
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerFullInfoItem, styles.elipsis)}>
                  <div className={styles.sellerInfoHeader}>Audience Type</div>
                  <div>
                    {sellerInfo?.audienceTypes}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Location"
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>State</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.state}
                  >
                    {sellerInfo?.state}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Country</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.country}
                  >
                    {sellerInfo?.country}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

         {sellerInfo?.socialMediaChannels.map((field, index) =>(
         <React.Fragment key={field.id}>

           <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title={sellerInfo?.socialMediaChannels[index].name}
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerInfoItem, styles.elipsis)}>
                  <div className={styles.sellerInfoHeader}>Follower Range</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.socialMediaChannels[index].followerRange}
                  >
                    {sellerInfo?.socialMediaChannels[index].followerRange}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>
                    Profile Link
                  </div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.socialMediaChannels[index].profileLink}
                  >
                    {isUrlValid(sellerInfo?.socialMediaChannels[index].profileLink) &&(
                     <a className={styles.link} href={sellerInfo?.socialMediaChannels[index].profileLink} target={"_blank"} rel={"noreferrer"}>
                         {sellerInfo?.socialMediaChannels[index].profileLink}
                     </a>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </Panel>
         </React.Fragment>
         ))}

        {role == "InternalUser" && campaign?.campaignType == "AffiliateEvent" && (
            <>
              <Panel className={styles.panelWrap}>
                  <PanelHeader
                    className={styles.sellerInfoHeader}
                    title="Default Reward"
                  />
                  <div className={styles.sellerInfoContainer}>
                    <div className={styles.sellerInfoRow}>
                      <div className={styles.sellerInfoItem}>
                        <div className={styles.sellerInfoHeader}>Reward</div>
                        <div
                          className={styles.elipsis}
                          title={sellerRequestInfo?.defaultReward}
                        >
                          {sellerRequestInfo?.defaultReward}
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>

                <FormProvider {...formMethods}>
                  <form>
                    <OverrideCommissionForm  />      
                  </form>
                </FormProvider>
            </>
          )}

          </div>
        </div>

        <footer className={styles.footer} hidden={isLoading || viewOnly}>
          { ["Pending", "Removed"].includes(sellerRequestInfo?.status) && (
            <>
              <SixtyButton
                onClick={onApproveSeller}
                className={styles.approveBtn}
                disabled={approveLoading || rejectLoading || overrideLoading}
              >
                Shortlisting
              </SixtyButton>
              <SixtyButton
                className={styles.approveBtn}
                disabled={approveLoading || rejectLoading || overrideLoading}
                onClick={onRejectSeller}
              >
                Reject
              </SixtyButton>
            </>
          )}

          {sellerRequestInfo?.status == "Approved" && (
            <>
              <SixtyButton
                className={styles.approveBtn}
                disabled={approveLoading || rejectLoading || overrideLoading}
                onClick={onRejectSeller}
              >
                Reject 
              </SixtyButton>
            </>
          )}

          {sellerRequestInfo?.status == "Rejected" && (
            <>
              <SixtyButton
                onClick={onApproveSeller}
                className={styles.approveBtn}
                disabled={approveLoading || rejectLoading || overrideLoading}
              >
                Approve
              </SixtyButton>
            </>
          )}

          {role == "Supplier" && sellerRequestInfo?.status == "Shortlisted" && (
            <>
              <SixtyButton
                onClick={onApproveSeller}
                className={styles.approveBtn}
                disabled={true}
              >
                Awaiting Invitation Confirmation
              </SixtyButton>
            </>
          )}

          {role == "InternalUser" && sellerRequestInfo?.status == "Shortlisted" && (
            <>
              <SixtyButton
                onClick={onApproveSeller}
                className={styles.approveBtn}
                disabled={approveLoading || rejectLoading || overrideLoading}
              >
                Approve
              </SixtyButton>
            </>
          )}

         {role == "InternalUser" && campaign?.campaignType == "AffiliateEvent" && (
            <>
               <SixtyButton
                  type="submit"
                  onClick={formMethods.handleSubmit(onOverrideCommission)}
                  className={styles.approveBtn}
                  disabled={approveLoading || rejectLoading || overrideLoading}
                >
                    Update Commission
               </SixtyButton>
            </>
          )}

          {role == "InternalUser" && (
            <>
              <SixtyButton
                 variant="tertiary"
                 className={styles.cancelButton}
                 onClick={onBrowseProfile}
                 disabled={approveLoading || rejectLoading || overrideLoading}
               >
                  Browse Influencer Profile
              </SixtyButton>
            </>
          )}
        </footer>
      </div>
    </React.Fragment>
  );
};

export default InfluencerRequestDetails;
