import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import PricingPlanTable from "./components/PricingPlanTable";
import PricingPlanDetail from "./PricingPlanDetails";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import Tabs from "components/Tabs";
import { useGetCampaignStatusCount } from "queries/campaign";

const PricingPlanListing = () => {

  const [isPricingPlanDetailOpen, setPricingPlanDetailOpen] = useState(false);
  const history = useHistory();
  const pricingPlanDetailRef = useRef();
  const [selectedPricingPlan, setSelectedPricingPlan] = useState({});

  const PricingPlanDetailTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedPricingPlan.name} />
          </div>
          <div className={styles.modalTitle}>{selectedPricingPlan.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPricingPlanDetailOpen(false)}
          />
        </div>
      </div>
    );
  };


  const onPricingPlanClick = (value) => {
    setSelectedPricingPlan(value);
    setPricingPlanDetailOpen(true);
  };

  return (
    <div>
      <GridHeader header="Pricing Plans" 
         renderButtons={() => (
          <>
          </>
         )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.pricingTable}>
          <PricingPlanTable onPricingClick={onPricingPlanClick} />
        </div>
      </Panel>
      {isPricingPlanDetailOpen && (
        <Modal
          isOpen={isPricingPlanDetailOpen}
          onAttemptClose={() => {
            setPricingPlanDetailOpen(false);
          }}
          modalRef={pricingPlanDetailRef}
          Title={PricingPlanDetailTitle}
        >
          <PricingPlanDetail
            selectedPricingPlan={selectedPricingPlan}
            handleClose={() => setPricingPlanDetailOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default PricingPlanListing;
