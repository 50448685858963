import React, { useState, useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useGetPromotionDropdown } from "queries/admin";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import SixtySelect from "components/SixtySelect";
import Checkbox from "components/SixtyCheckBox";
import DatePicker from "components/SixtyDatePicker";
import { format, parse } from "date-fns";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";
import SixtyButton from "components/SixtyButton";
import { setToast } from "components/Toast";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import clsx from "clsx";
import { reportError } from "crashlytics";

const PromotionForm = ({selectedPromotion, disabledForm = false}) => {

    const [promotionType, setPromotionType] = useState("");

    const { control, watch, errors } = useFormContext();

    const { data: promotionTypeOptions, isLoading: isLoadingPromotionType } = useGetPromotionDropdown();

    const promotionOptions = promotionTypeOptions?.length
     ? 
           promotionTypeOptions.map((option) => ({
           label: option.name,
           value: option.key,
         }))
       
     : [];

      useEffect(() => {
        if(selectedPromotion){
           setPromotionType(selectedPromotion?.duration);
        }
      }, []);

 
  return (
    <div className={styles.form}>
      <div className={styles.section}>Promotion Information</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
         <Input
           name="name"
           error={errors.name}
           control={control}
           label="Name"
           disabled={disabledForm}
         />
      </div>
      <div className={styles.fullFormRow}>
        <Input
           name="promotionCode"
           error={errors.label}
           control={control}
           label="Promotion Code"
           disabled={disabledForm}
         />
      </div>
      <div className={styles.fullFormRow}>
        <Input
           name="percentOff"
           error={errors.percentOff}
           control={control}
           label="Percent Off %"
           type="number"
           min={0}
           step=".01"
           defaultValue={0}
           disabled={disabledForm}
        />
      </div>

      <div style={{height:25}} ></div>
      <div className={styles.section}>Settings</div>
      <hr className={styles.hr} />
      <div className={styles.formRow}>
        {promotionOptions && promotionOptions.length > 0 && (
            <Select
              name="duration"
              error={errors.duration}
              options={promotionOptions}
              control={control}
              fullWidth
              defaultOption="Select promotion type"
              handleChange={(name, value) => {
                setPromotionType(value);
              }}
              value={promotionType}
              defaultValue={promotionType}
              disabled={disabledForm}
            />
        )}
        {promotionType == 'repeating' && (
          <Input
              name="durationInMonths"
              error={errors.period}
              control={control}
              label="Duration In Months"
              type="number"
              min={0}
              step="1"
              defaultValue={1}
              disabled={disabledForm}
          />
        )}

      </div>
      <div style={{height:55}} ></div>
    </div>
  );
};

export default PromotionForm;
