import SixtySelect from "components/SixtySelect";
import RadioField from "components/RadioField";
import {
  FilterGroup,
  FilterItem,
  FilterChipContainer,
  ChipItem,
} from "components/SixtyTable/components/Filter";
import { useGetCampaignDropdown } from "queries/campaign";

export const labelMap = {
  socialMedia: {
    instagram: "Instagram",
  }
};

export default function ReportingFilter({ state, setState }) {
 
   const { data: campaignData, isLoading: campaignLoading } = useGetCampaignDropdown({
     staleTime: 60 * 1000,
   });

   const campaignOptions =
   campaignData?.length > 0
       ? campaignData.map((option) => ({
           label: option.name,
           value: option.id,
         }))
       : [];



  const handleSocialMediaChange = (group, fieldName, value) => {
        setState((filter) => {
          filter[group] = { [fieldName]: true };
          filter.filterBySocialMedia = value;
        });
  };


  const handleCampaignSelect = (value) => {
    setState((filter) => {
      filter.campaignId = value;
      filter.campaignGroupName = campaignOptions.find(
        (option) => option.value === value
      ).label;
    });
  };


  return (
    <>
      <FilterGroup label="Social Media">
        <FilterItem>
          <RadioField
            label={labelMap.socialMedia.instagram}
            value={"instagram"}
            checked={!!state?.socialMedia?.instagram}
            onChange={(e) =>
                handleSocialMediaChange("socialMedia", "instagram", "Instagram")
            }
          />
        </FilterItem>
      </FilterGroup>
      <FilterGroup label="Campaign">
        <FilterItem>
          <SixtySelect
            options={campaignOptions}
            defaultOption={
                campaignLoading ? "fetching options..." : "select a campaign"
            }
            value={state?.campaignId}
            onChange={(value) => handleCampaignSelect(value)}
            disableDropIcon
            fullWidth
          />
        </FilterItem>
      </FilterGroup>
    </>
  );
}

export function renderFilterChips(filterState, setFilterState) {
 
  let filtersPresent = false;
  for (const filterKey in filterState) {
    if (filterKey === "socialMedia") {
      for (const label in labelMap.socialMedia) {
        if (!!filterState.socialMedia[label]) {
          filtersPresent = true;
          break;
        }
      }
      if (filtersPresent) break;
    } 
    else if (!!filterState[filterKey]) {
      filtersPresent = true;
    }
  }

  const removeFilter = (filterItem) => {
    setFilterState((filter) => {
      if (filterItem === "campaignGroupName") {
        delete filter["campaignId"];
        delete filter.campaignGroupName;
      }
      delete filter[filterItem];
    });
  };

  if (filtersPresent) {
    let socialMediaFilters = filterState.socialMedia || {};
    let campaignFilters = filterState.campaignGroupName;

    return (
      <FilterChipContainer>
        {Object.keys(socialMediaFilters).length > 0 &&
          Object.keys(socialMediaFilters)?.map((filterItem) => (
            <ChipItem
              label={labelMap.socialMedia[filterItem]}
              name={"socialMedia"}
              handleRemove={removeFilter}
              key={filterItem}
            />
          ))}

        {campaignFilters && (
          <>
            <ChipItem
              label={`selected ${filterState.campaignGroupName}`}
              name={"campaignGroupName"}
              handleRemove={removeFilter}
            />
          </>
        )}
      </FilterChipContainer>
    );
  }
  return null;
}
