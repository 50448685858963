import styles from "./index.module.css";
import clsx from "clsx";

const Summary = ({ data }) => {
  return (
    <div className={styles.earningHistoryContainer}>
      <div
        className={clsx(
          styles.earningHistoryItem,
          styles.firstEarningHistoryItem
        )}
      >
        <div className={styles.header}>Affiliate</div>
        <div className={styles.value}>{data?.affiliateCount}</div>
      </div>
      <div className={styles.earningHistoryItem}>
        <div className={styles.header}>Traffic</div>
        <div className={styles.value}>{data?.traffic}</div>
      </div>
      <div className={styles.earningHistoryItem}>
        <div className={styles.header}>Joined Brands</div>
        <div className={styles.value}>{data?.joinedBrandCount}</div>
      </div>
      <div
        className={clsx(
          styles.earningHistoryItem,
          styles.firstEarningHistoryItem
        )}
      >
        <div className={styles.header}>Commission</div>
        <div className={styles.value}>{data?.commission}</div>
      </div>
    </div>
  );
};

export default Summary;
