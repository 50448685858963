import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetSellerRegistrationDetail } from "queries/seller";
import { useGetSellerShipmentDetail, useUpdateSellerCampaignShipment } from "queries/campaign";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import { useHistory, useParams } from "react-router-dom";

import styles from "./index.module.css";
import Loader from "components/Loader";

import { useForm, FormProvider } from "react-hook-form";
import Input from "components/FormComponents/ControlledInput";


const ClosedCampaign = () => {

  const params = useParams();
  const role = localStorage.getItem("userRole");

  const onPreview = async () => {

    if (role === "InternalUser") {
      const path = `/admin/campaigns/AnalyticDetails/${params?.id}`;
      window.open(path, "_blank")
    } else {
      const _path = `/dashboard/campaigns/AnalyticDetails/${params?.id}`;
      window.open(_path, "_blank")
    }

  };

  return (
    <React.Fragment>
      <div className={styles.sellerRequestFormContainer}>

      <div className={styles.sellerRequestForm} hidden={false}>
            <div>
                <Panel className={styles.panelWrap}>
                    <PanelHeader
                    className={styles.sellerInfoHeader}
                    title="Campaign Completed"
                    />
                    <div className={styles.postContainer}>
                        <SixtyButton
                            variant="tertiary"
                            onClick={() => onPreview()}
                            disabled={false}
                            >
                            {"View Campaign Analytics"}
                        </SixtyButton>
                    </div>
                </Panel>
            </div>
        </div>

      </div>
    </React.Fragment>
  );
};

export default ClosedCampaign;
