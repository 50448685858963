import React, { useRef } from "react";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import clsx from "clsx";
import GridHeader from "components/GridHeader";
import BillingPlan from "./components/BillingPlan";
import BillingStatementsTable from "./components/BillingStatementsTable";

const BillingSettings = () => {
  return (
    <div>
      <GridHeader header="Billing" />
      <div className={styles.billingPanel}>
        <div className={clsx(styles.row, styles.firstRow)}>
          <Panel className={styles.panelWrap}>
            <BillingPlan />
          </Panel>
          {/* <Panel className={styles.panelWrap}>
            <BillingCardDetails />
          </Panel> */}
        </div>
        <div className={styles.row}>
          <Panel className={styles.panelWrap}>
            <div className={styles.billingTable}>
              <BillingStatementsTable />
            </div>
          </Panel>
        </div>
      </div>

    </div>
  );
};

export default BillingSettings;
