import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";
import { saveAs } from "file-saver";



export function useGetVendorCreditBalance() {
  return useQuery(
    Endpoints.Wallet.GetVendorCreditBalance,
    () =>
      api
        .get(Endpoints.Wallet.GetVendorCreditBalance)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetVendorCreditBalance: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetVendorCreditHistories(filter) {
  return useQuery(
    [Endpoints.Wallet.GetVendorCreditHistories, filter],
    () =>
      api
        .post(Endpoints.Wallet.GetVendorCreditHistories, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError(
          "useGetVendorCreditHistories: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useDownloadCreditTopupReceipt() {
    return useMutation(
        (data) =>
          api
            .post(Endpoints.Wallet.DownloadCreditTopupReceipt, data, {
              responseType: "blob",
              "Accept-Encoding": "gzip, deflate, br",
            })
            .then((response) => {
              let filename = response.headers["content-disposition"]
                ?.split(";")
                ?.find((n) => n.includes("filename="))
                ?.replace("filename=", "")
                ?.trim();
              let url = window.URL.createObjectURL(new Blob([response.data]));
              saveAs(url, filename || "data.pdf");
            }),
        {
          onError: (error) => {
            setToast(error.response?.data?.message || unknown_Error, "error");
            reportError(
              "useDownloadCreditTopupReceipt: useQuery: onError: error",
              error
            );
          },
        }
      );
}

export function useTopupVendorCredits() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Wallet.TopupVendorCredits, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useTopupVendorCredits: useMutation: onError: error", error);
      },
    }
  );
}
