import { forwardRef } from "react";
import clsx from "clsx";
import styles from "./index.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "components/SixtyButton";
import { ReactComponent as CalendarIcon } from "assets/icons/Icon-Calendar.svg";

const SixtyDatePicker = forwardRef(
  (
    {
      label = "",
      name,
      value = "",
      error,
      className,
      handleChange,
      timeInputLabel = "Time:",
      dateFormat = "dd/MM/yyyy h:mm aa",
      showTimeInput = false,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        className={clsx(
          styles.field,
          error && styles.error,
          className && className
        )}
      >
        <div
          className={clsx(styles.fieldGroup, {
            [styles.activeGroup]: !!value,
          })}
        >
          <label
            htmlFor={name}
            className={clsx(styles.label, {
              [styles.activeLabel]: !!value,
            })}
          >
            {label}
          </label>
          <div
            className={clsx(styles.inputWrapper, {
              [styles.disabled]: disabled,
            })}
          >
            <DatePicker
              name={name}
              selected={value}
              onChange={(date) => handleChange(date)}
              timeInputLabel={timeInputLabel}
              dateFormat={dateFormat}
              showTimeInput={showTimeInput}
              className={styles.input}
              // wrapperClassName={styles.datepickerWrapper}
              disabled={disabled}
              {...rest}
            />
          </div>
        </div>
        {error && <div className={styles.errorMessage}>{error.message}</div>}
      </div>
    );
  }
);

const DateButton = ({ value, onClick, ref }) => (
  <Button
    className={styles.dateBtn}
    variant="secondary"
    onClick={onClick}
    ref={ref}
    roundedSquare
  >
    <span className={styles.calendarIcon}>
      <CalendarIcon />
    </span>
    {value}
  </Button>
);

export const ButtonDatePicker = ({ value, handleChange, ...rest }) => {
  return (
    <DatePicker
      selected={value}
      onChange={(date) => handleChange(date)}
      customInput={<DateButton />}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: "viewport",
        },
      }}
      {...rest}
    />
  );
};

export default SixtyDatePicker;
