import React, { useRef, useState } from "react";
import styles from "./index.module.css";
import { ReactComponent as GoBackIcon } from "assets/icons/Icon-Back-Button.svg";
import Button from "components/SixtyButton";
import SixtySelectField from "components/SixtySelect";
import ErrorModal from "components/Error";

const ProductPageHeader = ({
  title,
  onGoBack,
  onPreview,
  onDuplicate,
  onArchive,
  onDelete,
}) => {
  const [moreActionsValue, setMoreActionsValue] = useState("");

  const moreActionsOptions = [
    {
      label: "Archive",
      value: "archive",
    },
    {
      label: "Delete",
      value: "delete",
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const modalRef = useRef();

  const onSelectAction = (value) => {
    setMoreActionsValue(value);
    setModalOpen(true);
  };

  return (
    <div className={styles.orderHeader}>
      <div className={styles.titleBox}>
        <div onClick={onGoBack}>
          <GoBackIcon />
        </div>
        <div className={styles.titleContent}>{title}</div>
      </div>
      <div className={styles.ButtonsContainer}>
        {/* <div>
          <Button
            className={styles.actionButtons}
            variant="secondary"
            label="Duplicate"
            onClick={onDuplicate}
          />
        </div>
        <div>
          <Button
            className={styles.actionButtons}
            variant="secondary"
            label="Preview"
            onClick={onPreview}
          />
        </div> */}
        <div>
          <SixtySelectField
            options={moreActionsOptions}
            defaultOption="More actions"
            value=""
            selectBoxHelperClass={styles.moreActionsSelectBox}
            variant="secondary"
            size="regular"
            selectBoxFontHelper={styles.selectBoxText}
            optionBoxFontHelper={styles.optionBoxText}
            onChange={(value) => {
              setMoreActionsValue(value);
              onSelectAction(value);
            }}
          />
        </div>
      </div>
      <ErrorModal
        open={modalOpen}
        handleClose={closeModal}
        handleCancel={closeModal}
        handleAction={moreActionsValue === "delete" ? onDelete : onArchive}
        actionLabel={moreActionsValue === "delete" ? "Delete" : "Archive"}
        errorRef={modalRef}
        errorMessage={"Are you sure?"}
      />
    </div>
  );
};

export default ProductPageHeader;
