import SixtySelect from "components/SixtySelect";
import RadioField from "components/RadioField";
import {
  FilterGroup,
  FilterItem,
  FilterChipContainer,
  ChipItem,
} from "components/SixtyTable/components/Filter";
import { useGetCategoryDropdown } from "queries/product";

export const labelMap = {
  inventory: {
    filterByAvailableInStock: "Available in stock",
    filterByStockNotAvailable: "Stock not available",
    filterByNoInventory: "No inventory",
  },
  orders: {
    filterByWithOrders: "With orders",
    filterByWithNoOrders: "No orders",
  },
};

export default function ProductFilter({ state, setState }) {
  // category dropdown option query
  const { data: categoriesData, isLoading } = useGetCategoryDropdown({
    staleTime: 60 * 1000,
  });

  const options =
    categoriesData?.length > 0
      ? categoriesData.map((category) => ({
          label: category.name,
          value: category.id,
        }))
      : [];

  const handleRadioFieldChange = (group, fieldName) => {
    setState((filter) => {
      filter[group] = { [fieldName]: true };
    });
  };

  const handleCategorySelect = (value) => {
    setState((filter) => {
      filter.filterByCategoryId = value;
      filter.categoryName = options.find(
        (option) => option.value === value
      ).label;
    });
  };

  return (
    <>
      <FilterGroup label="Inventory status">
        <FilterItem>
          <RadioField
            label={labelMap.inventory.filterByAvailableInStock}
            value={"filterByAvailableInStock"}
            checked={!!state?.inventory?.filterByAvailableInStock}
            onChange={(e) =>
              handleRadioFieldChange("inventory", "filterByAvailableInStock")
            }
          />
        </FilterItem>
        <FilterItem>
          <RadioField
            label={labelMap.inventory.filterByStockNotAvailable}
            value={"filterByStockNotAvailable"}
            checked={!!state?.inventory?.filterByStockNotAvailable}
            onChange={(e) =>
              handleRadioFieldChange("inventory", "filterByStockNotAvailable")
            }
          />
        </FilterItem>
        <FilterItem>
          <RadioField
            label={labelMap.inventory.filterByNoInventory}
            value={"filterByNoInventory"}
            checked={!!state?.inventory?.filterByNoInventory}
            onChange={(e) =>
              handleRadioFieldChange("inventory", "filterByNoInventory")
            }
          />
        </FilterItem>
      </FilterGroup>
      <FilterGroup label="Orders">
        <FilterItem>
          <RadioField
            value={"filterByWithOrders"}
            label={labelMap.orders.filterByWithOrders}
            checked={!!state?.orders?.filterByWithOrders}
            onChange={(e) => {
              handleRadioFieldChange("orders", "filterByWithOrders");
            }}
          />
        </FilterItem>
        <FilterItem>
          <RadioField
            value={"filterByWithNoOrders"}
            label={labelMap.orders.filterByWithNoOrders}
            checked={!!state?.orders?.filterByWithNoOrders}
            onChange={(e) => {
              handleRadioFieldChange("orders", "filterByWithNoOrders");
            }}
          />
        </FilterItem>
      </FilterGroup>
      <FilterGroup label="Category">
        <FilterItem>
          <SixtySelect
            options={options}
            defaultOption={
              isLoading ? "fetching options..." : "select a category"
            }
            value={state?.filterByCategoryId}
            onChange={(value) => handleCategorySelect(value)}
            disableDropIcon
            fullWidth
          />
        </FilterItem>
      </FilterGroup>
    </>
  );
}

export function renderFilterChips(filterState, setFilterState) {
  let filtersPresent = false;
  for (const filterKey in filterState) {
    if (filterKey === "inventory") {
      for (const label in labelMap.inventory) {
        if (!!filterState.inventory[label]) {
          filtersPresent = true;
          break;
        }
      }
      if (filtersPresent) break;
    } else if (filterKey === "orders") {
      for (const label in labelMap.orders) {
        if (!!filterState.orders[label]) {
          filtersPresent = true;
          break;
        }
      }
      if (filtersPresent) break;
    } else if (!!filterState[filterKey]) {
      filtersPresent = true;
    }
  }

  const removeFilter = (filterItem) => {
    setFilterState((filter) => {
      if (filterItem === "categoryName") {
        delete filter["filterByCategoryId"];
        delete filter.categoryName;
      }
      delete filter[filterItem];
    });
  };

  if (filtersPresent) {
    let inventoryFilters = filterState.inventory || {};
    let ordersFilters = filterState.orders || {};
    let categoryFilters = filterState.categoryName;

    return (
      <FilterChipContainer>
        {Object.keys(inventoryFilters).length > 0 &&
          Object.keys(inventoryFilters)?.map((filterItem) => (
            <ChipItem
              label={labelMap.inventory[filterItem]}
              name={"inventory"}
              handleRemove={removeFilter}
              key={filterItem}
            />
          ))}
        {Object.keys(ordersFilters).length > 0 &&
          Object.keys(ordersFilters)?.map((filterItem) => (
            <ChipItem
              label={labelMap.orders[filterItem]}
              name={"orders"}
              handleRemove={removeFilter}
              key={filterItem}
            />
          ))}
        {categoryFilters && (
          <>
            <ChipItem
              label={`Category is ${filterState.categoryName}`}
              name={"categoryName"}
              handleRemove={removeFilter}
            />
          </>
        )}
      </FilterChipContainer>
    );
  }
  return null;
}
