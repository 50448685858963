import React from 'react';
import styles from './index.module.css';
import Avatar from "components/Avatar";


const CampaignInfo = (data) => {
    const{ data: campaignData } = data;

    return (
        <React.Fragment>
            {
                campaignData &&
                (<div className={styles.campaignNameContainer}>
                    <div className={styles.campaignName}>{campaignData?.name}</div>
                </div>
                )
            }
            <div className={styles.campaignBody}>
                {campaignData &&
                    <div className={styles.campaignFieldContainer}>
                        <div className={styles.campaignFieldName}>
                            Visibility
                            </div>
                        <div className={styles.campaignFieldValue}>
                            <span className={styles.campaignFieldValue}>{campaignData?.privacyStatus} </span>
                        </div>
                    </div>
                }
                {campaignData &&
                    <div className={styles.campaignFieldContainer}>
                        <div className={styles.campaignFieldName}>
                            Progress
                                </div>
                        <div className={styles.campaignFieldValue}>
                            {campaignData?.progressStatus}
                        </div>
                    </div>
                }
                {campaignData &&
                    <div className={styles.campaignFieldContainer}>
                        <div className={styles.campaignFieldName}>
                            Created Date
                                </div>
                        <div className={styles.campaignFieldValue}>
                          {campaignData?.createdDate}
                        </div>
                    </div>
                }
                {campaignData &&
                    <div className={styles.campaignFieldContainer}>
                        <div className={styles.campaignFieldName}>
                            Status
                                </div>
                        <div className={styles.campaignFieldValue}>
                            {campaignData?.status}
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default CampaignInfo;
