import ChipInput from "components/FormComponents/ControlledChipInput";
import Input from "components/FormComponents/ControlledInput";
import ControlledSelect from "components/FormComponents/ControlledSelect";
import Checkbox from "components/SixtyCheckBox";
import {
  useGetCategoryDropdown,
  useGetProductStatusDropdown,
} from "queries/product";
import { useFormContext } from "react-hook-form";

import styles from "./index.module.css";

export default function BasicInfo() {
  // category dropdown option query
  const { data: categoriesData, isLoading } = useGetCategoryDropdown();

  const { control, errors } = useFormContext();

  const options =
    categoriesData?.length > 0
      ? categoriesData.map((category) => ({
          label: category.name,
          value: category.id,
        }))
      : [];

  const {
    data: productStatusData,
    isLoading: loadingProductStatus,
  } = useGetProductStatusDropdown();

  const statusOptions =
    productStatusData?.length > 0
      ? productStatusData.map((option) => ({
          label: option.name,
          value: option.key,
        }))
      : [];

  return (
    <div className={styles.form}>
      <Input
        name="name"
        control={control}
        rules={{ required: "name is required" }}
        label="Name"
        error={errors.name}
      />
      <Input
        name="description"
        control={control}
        rules={{ required: "description is required" }}
        label="Description"
        error={errors.description}
        lines={3}
        defaultValue=""
      />
      <ChipInput
        name="categories"
        control={control}
        label="Categories"
        error={errors.categories}
        defaultValue={[]}
        disabled={isLoading}
        getValue={(value) => value.map((cat) => cat.categoryId)}
        helperText="Add this product to one or more categories"
        handleChange={(value) => {
          return value.map((id) => ({
            categoryId: id,
          }));
        }}
        options={options}
      />
      {/* <Checkbox
        control={control}
        name="IsFeatured"
        label="Featured Product"
        uncheckStyleVariant="greyedOut"
        error={errors.IsFeatured}
      /> */}
      <div className={styles.statusField}>
        <ControlledSelect
          name="status"
          label={loadingProductStatus ? "loading options" : "Status"}
          options={statusOptions}
          control={control}
          error={errors.status}
          fullWidth
        />
      </div>
    </div>
  );
}
