import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import Link from "components/SixtyLink";
import Button from "components/SixtyButton";
import Input from "components/SixtyInput";
import Checkbox from "components/SixtyCheckBox";
import LoginContainer from "./components/LoginContainer";

import { ReactComponent as EyeIcon } from "assets/icons/passwordView.svg";
import { ReactComponent as EyeIconActive } from "assets/icons/passwordViewActive.svg";
import { useLogin } from "queries/account";
import { reportError } from "crashlytics";
import { setTokens } from "utils/auth";
import Loader from "components/Loader";

export default function Login() {
  const { handleSubmit, control, errors } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = (e) => setShowPassword((show) => !show);

  const history = useHistory();

  const { mutateAsync: login, isLoading: loginLoading, isError } = useLogin();

  const onSubmit = async (data) => {
    try {
      const result = await login({
        usernameOrEmail: data.email,
        password: data.password,
      });
      if (result) {
        setTokens(result?.result);
        /** TODO --- when reset password API is available, need to uncomment following */
        // if (result.result?.requiredResetPassword) {
        //   history.push("/reset-password");
        // } else {
        //   history.push("/dashboard");
        // }
        const role = localStorage.getItem("userRole");

        if (role === "InternalUser") {
          history.push("/admin/dashboard");
        } else {
          history.push("/dashboard");
        }
      }
    } catch (error) {
      reportError("Login: onSubmit: error: ", error);
    }
  };

  return (
    <>
      {loginLoading && (
          <div style={{width:300, height:200}} >
            <Loader/>
         </div>
       )}
       {!loginLoading && (
               <LoginContainer
               header="Login to vendor account"
               form={
                 <>
                   <Controller
                     as={Input}
                     name="email"
                     control={control}
                     rules={{ required: "email required" }}
                     label="Email address"
                     error={errors.email || isError}
                     defaultValue=""
                   />
                   <Controller
                     as={Input}
                     name="password"
                     control={control}
                     rules={{ required: "password required" }}
                     label="Password"
                     error={errors.password || isError}
                     defaultValue=""
                     type={showPassword ? "text" : "password"}
                     endIcon={
                       showPassword ? (
                         <EyeIconActive onClick={handleShowClick} />
                       ) : (
                         <EyeIcon onClick={handleShowClick} />
                       )
                     }
                   />
                 </>
               }
               formFooter={
                 <>
                   {/* <Controller
                     as={Checkbox}
                     control={control}
                     name="rememberMe"
                     label="Remember me"
                     defaultValue={false}
                     // checked={values.rememberMe}
                   /> */}
                   {/* REMOVE THIS EMPTY DIV WHEN ADDING REMEMBER ME */}
                   <div
                     style={{
                       marginBottom: 16,
                       height: 16,
                       width: 16,
                     }}
                   ></div>
                   <Link to="/auth/forgot-password" type="p1" color="orange">
                     Forgot Password
                   </Link>
                 </>
               }
               onFormSubmit={handleSubmit(onSubmit)}
               footer={
                 <Button className={"login-btn"} type="submit" size="large" fullWidth disabled={loginLoading}>
                   Login
                 </Button>
               }
               footerLink={
                  <Link to="/auth/sign-up" type="p1" color="orange">
                    Don’t have an account? Sign up now
                  </Link>
               }
             />
       )}

    </>
  );
}
