import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useImmer } from "utils/hooks/useImmer";
import { useGetCategories } from "queries/product";
import useRowSelectionTable from "utils/hooks/useRowSelectionTable";
import _isEmpty from "lodash/isEmpty";

import SixtyText from "components/SixtyText";
import SixtyButton from "components/SixtyButton";
import Table, {
  RowSelectionContainer,
  TableHeader,
} from "components/SixtyTable";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import Sort from "components/SixtyTable/components/Sort";
import Filter from "components/SixtyTable/components/Filter";
import EmptyGrid from "components/EmptyGrid";
import CategoryFilter, { renderFilterChips } from "./components/Filter";
import CategorySort from "./components/Sort";
import ErrorModal from "components/Error";

import { ReactComponent as ProductIcon } from "assets/icons/Icon-Products.svg";
import styles from "./index.module.css";
import Loader from "components/Loader";
import { PanelActionButton } from "components/SixtyPanel";
import { useHistory } from "react-router-dom";
import { unknown_Error } from "common/constants";

export default function CategoryTable({ resetRef }) {
  const history = useHistory();

  const onCategoryClick = useCallback(
    (value) => {
      const path = `/dashboard/product-categories/${value}`;
      history.push(path);
    },
    [history]
  );

  //-----------filters , state, handlers starts here --------------//

  const [search, setSearch] = useState("");
  const [filterState, setFilterState] = useImmer({});
  const [sort, setSort] = useState("orderByDescending");

  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    return {
      ...filterState,
      [sort]: true,
      keyword: search,
      startFrom,
      limit,
    };
  }, [filterState, search, sort, startFrom]);

  //-----------------end of filter--------------------//

  //----------start data---------------//

  const {
    data: categoryData,
    isLoading,
    isError,
    refetch,
    error,
  } = useGetCategories(getFilters());

  useEffect(() => {
    resetRef.current = refetch;
  }, [refetch, resetRef]);

  const onRowClick = (row, e) => {
    onCategoryClick(row.original.id);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Products",
        accessor: "products",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    [onCategoryClick]
  );

  let data = useMemo(() => (categoryData?.result ? categoryData.result : []), [
    categoryData,
  ]);

  //----------end data---------------//

  //----------start handlers---------------//

  const onFilterChange = (filterVal) => {
    // component filter state set
    setFilterState(() => filterVal);
  };

  const handleSortChange = useCallback(
    (field) => {
      setSort(field);
    },
    [setSort]
  );

  const handleSearch = (value) => setSearch(value);

  //----------end handlers---------------//

  const tableInstance = useRowSelectionTable({
    data,
    columns,
  });

  // error modal
  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (
      _isEmpty(filterState) &&
      !search &&
      categoryData?.result?.length === 0
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      {(!Array.isArray(categoryData?.result) || isDataEmpty()) && !isLoading && (
        <>
          <EmptyGrid
            Icon={ProductIcon}
            emptyMessage={
              isError ? "Error in fetching data" : "Add your product category"
            }
          />
          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}
      {!isError && !isDataEmpty() && (
        <>
          {tableInstance.selectedFlatRows.length > 0 ? (
            <RowSelectionContainer
              renderRowSelectionText={() => (
                <>
                  <SixtyText>
                    {tableInstance.selectedFlatRows.length} products selected
                  </SixtyText>
                  <SixtyButton
                    variant="text"
                    onClick={() => tableInstance.toggleAllRowsSelected(false)}
                  >
                    unselect all
                  </SixtyButton>
                </>
              )}
              renderRowSelectionButtons={() => (
                <>
                  {/* <SixtyButton variant="secondary">Archive</SixtyButton> */}
                </>
              )}
            />
          ) : (
            <>
              <TableHeader>
                <SearchFilter
                  search={search}
                  onSearchChange={handleSearch}
                  placeholder="Search for categories"
                />
                <Filter
                  filter={filterState}
                  setFilter={onFilterChange}
                  renderFilter={CategoryFilter}
                  columnLayout
                />
                <Sort
                  render={CategorySort}
                  state={sort}
                  handleSortChange={handleSortChange}
                />
              </TableHeader>
              {renderFilterChips(filterState, setFilterState)}
            </>
          )}
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(categoryData?.result) && (
              <Table
                instance={tableInstance}
                totalItems={categoryData?.totalRecords}
                startFrom={startFrom}
                limit={limit}
                setStart={setStart}
                onRowClicked={onRowClick}
              />
            )
          )}
        </>
      )}
    </>
  );
}
