import React from "react";
import PanelHeader from "components/PanelHeader";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import { useForm, FormProvider } from "react-hook-form";
import SixtyButton from "components/SixtyButton";

const ServiceAgreementSettingsForm = ({
    onServiceAgreementSubmit,
    onServiceAgreementCancel,
    serviceAgreement
}) => {

    const defaultValues = {
        sellerAnnouncementContent: serviceAgreement?.sellerAnnouncementContent || "",
        serviceContent: serviceAgreement?.serviceContent || ""
    };

    const formMethods = useForm({
        defaultValues
    });

    const onSubmit = (data) => {
        console.log("data from form", data);
        onServiceAgreementSubmit(data);
    };

    return (
                    <FormProvider {...formMethods}>
                        <div className={styles.serviceAgreementForm}>
                            <form
                                onSubmit={formMethods.handleSubmit(onSubmit)}>
                                <div className={styles.formBody}>
                                    <Panel>
                                        <PanelHeader title="Message influencers to join your brand" />
                                        <Input
                                            name="sellerAnnouncementContent"
                                            label="Message to influencers"
                                            lines={6}
                                            defaultValue=""
                                            className={styles.messageToSeller}
                                        />
                                    </Panel>
                                    <Panel>
                                        <PanelHeader title="Service agreement" />
                                        <Input
                                            name="serviceContent"
                                            label="Enter the service agreement"
                                            lines={6}
                                            defaultValue=""
                                            className={styles.serviceAgreement}
                                        />
                                    </Panel>
                                </div>
                                <footer className={styles.footer}>
                                    <SixtyButton type="submit" className={styles.saveBtn}>Save</SixtyButton>
                                    <SixtyButton variant="secondary" onClick={() => onServiceAgreementCancel()}>Cancel</SixtyButton>
                                </footer>
                            </form>
                        </div>
                    </FormProvider>
    )
}

export default ServiceAgreementSettingsForm;