import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetPayoutDetail, useVoidPayout } from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import { useHistory } from "react-router-dom";
import PayoutVoidForm from "../VoidConfirmation";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Modal from "components/Modal";
import Loader from "components/Loader";

import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

const PayoutDetails = ({ selectedPayout, handleClose, hideActionBtn = false }) => {
  const { id } = selectedPayout;
  const [isComfirmationOpen, setComfirmationOpen] = useState(false);
  const [isVoided, setVoided] = useState(false);
  const history = useHistory();
  const comfirmationRef = useRef();

  const { data: payoutInfo, isLoading, isError, refetch } = useGetPayoutDetail(
    id
  );

  useEffect(() => {

    if (['Voided'].includes(payoutInfo?.status)) {
        setVoided(true);
    }
 
   }, [payoutInfo, isLoading]);

  const { mutateAsync: voidPayout, 
          isLoading : voidLoading 
        } = useVoidPayout();

  const onVoidPayout = async () => {
     setComfirmationOpen(true);
  };

  const onClosePayout = async () => {
    setComfirmationOpen(false);
    setVoided(true);
  };

 const onBrowseHistoryClick = () => {
    const path = `/admin/influencer/histories/${payoutInfo?.sellerCode}`;
    window.open(path, "_blank")
 };

  const ConfirmationTitle = () => {
    return (
      <div className={styles.modalTitleBar}>
        <div className={styles.modalContainer}>
          <div>
            <Avatar name={selectedPayout?.sellerName} />
          </div>
          <div className={styles.modalTitle}>{'Void payout ' + selectedPayout?.code}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setComfirmationOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onCancel = async () => {
    try {
        handleClose();
    } catch (e) {
      reportError(
        "error in : PayoutForm: onCancel, error: ",
        e
      );
    }
};

  return (
    <React.Fragment>
      <div className={styles.payoutFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.payoutRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.payoutInfoHeader}
              title="Payout Information"
            />
            {!hideActionBtn && (
                <div className={styles.btnActionContainer}>
                    <SixtyButton
                      variant="tertiary"
                      onClick={() => onBrowseHistoryClick()}
                      className={styles.approveBtn}
                    >
                      {"Browse influencer credit histories"}
                    </SixtyButton>
              </div>
            )}

            <div className={styles.payoutInfoContainer}>
              <div className={styles.payoutInfoRow}>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Code</div>
                  <div>
                    {payoutInfo?.code}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={clsx(styles.payoutInfoHeader)}>Date</div>
                  <div>
                    {payoutInfo?.payoutDate}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Status</div>
                  <div>
                    {payoutInfo?.status}
                  </div>
                </div>
              </div>

              <div className={styles.payoutInfoRow}>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Amount</div>
                  <div>
                    {payoutInfo?.amount} Credit
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          {payoutInfo?.reviewLink && (
          <>
              <Panel className={styles.panelWrap}>
              <PanelHeader
                  className={styles.campaignInfoHeader}
                  title="Ratings & Review"
              />
                            {payoutInfo?.reviewLink ? 
                      (<>
                          <div className={styles.profPhotoContainer}>
                              <div className={styles.profPhotoDropzone}>
                              <img
                                src={payoutInfo?.reviewLink}
                                className={clsx(styles.img)}
                                alt="No preview"
                              />
                              </div>
                        </div>             
                      </>) : 
                      (<>
                          <p>{'N/A'}</p>
                      </>)}
              </Panel>
          </>
         )}

          {payoutInfo?.campaignName && (
            <>
                <Panel className={styles.panelWrap}>
                <PanelHeader
                  className={styles.payoutInfoHeader}
                  title="Campaign Information"
                />
                <div className={styles.payoutInfoContainer}>
                  <div className={styles.payoutInfoRow}>
                    <div className={styles.payoutInfoItem}>
                      <div className={styles.payoutInfoHeader}>Brand</div>
                      <div>
                        {payoutInfo?.campaignBrand}
                      </div>
                    </div>
                    <div className={styles.payoutInfoItem}>
                      <div className={clsx(styles.payoutInfoHeader)}>Campaign</div>
                      <div>
                        {payoutInfo?.campaignName}
                      </div>
                    </div>
                    <div className={styles.payoutInfoItem}>
                      <div className={clsx(styles.payoutInfoHeader)}>Deliver Post</div>
                      <div>
                        {payoutInfo?.postLink}
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
            </>
          )}
  
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.payoutInfoHeader}
              title="Payout Remark"
            />
            <div className={styles.payoutInfoContainer}>
              <div className={styles.payoutInfoRow}>
                <div className={styles.payoutFullInfoItem}>
                  <div className={styles.payoutInfoHeader}>Remark</div>
                  <div>
                    {payoutInfo?.remark}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.payoutInfoHeader}
              title="Influencer Details"
            />
            <div className={styles.payoutInfoContainer}>
              <div className={styles.payoutInfoRow}>
                <div className={clsx(styles.payoutInfoItem)}>
                  <div className={styles.payoutInfoHeader}>Name</div>
                  <div>
                    {payoutInfo?.sellerName}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}> Email </div>
                  <div>
                    {payoutInfo?.sellerEmail}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Contact</div>
                  <div>
                    {payoutInfo?.sellerContact}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.payoutInfoHeader}
              title="Bank Details"
            />
            <div className={styles.payoutInfoContainer}>
              <div className={styles.payoutInfoRow}>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Bank Owner Name</div>
                  <div>
                    {payoutInfo?.bankOwnerName}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Bank Name</div>
                  <div>
                    {payoutInfo?.bankName}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Bank Account No</div>
                  <div>
                    {payoutInfo?.bankAccountNo}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

        </div>
        </div>

        <footer className={styles.footer} hidden={isLoading}>
          {!hideActionBtn && (
              <SixtyButton
              type="submit"
              onClick={onVoidPayout}
              className={styles.approveBtn}
              disabled={voidLoading || isVoided}
            >
              {isVoided ? 'Voided' : 'Void Payout'}
            </SixtyButton>
          )}

          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            disabled={voidLoading}
            onClick={onCancel}
          >
            Cancel
          </SixtyButton>
        </footer>
      </div>
      {isComfirmationOpen && (
        <Modal
          isOpen={isComfirmationOpen}
          onAttemptClose={() => {
            setComfirmationOpen(false);
          }}
          modalRef={comfirmationRef}
          Title={ConfirmationTitle}
        >
          <PayoutVoidForm
            selectedContent={selectedPayout}
            handleClose={() => setComfirmationOpen(false)}
            actionCompleted={() => onClosePayout()}
          />
        </Modal>
    )}
    </React.Fragment>
  );
};

export default PayoutDetails;
