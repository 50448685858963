import React, { useEffect, useState } from "react";
import Tabs from "components/Tabs";
import Panel from "components/SixtyPanel";
import AdminOrderTable from "../components/AdminOrderTable";
import GridHeader from "components/GridHeader";
import SixtyButton from "components/SixtyButton";
import { useExportOrders, useGetOrderCount } from "queries/order";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import styles from "./index.module.css";

const AdminOrders = (props) => {

  const {
    data: orderData,
  } = useGetOrderCount();

  const { 
     mutateAsync: exportOrders,
     isLoading : exportLoading
   } = useExportOrders();

   const exportOrderExcel = async () => {
    try {
      setToast("Exporting Orders, it may take some time.");
      await exportOrders();
    } catch (error) {
      setToast(`Failed Export Order`, "error");
      reportError("OrderIndex: exportOrders: error: ", error);
    }
  };

  return (
    <div>
      <GridHeader
        header="Orders"
        renderButtons={() => (
          <>
            <SixtyButton variant="secondary" onClick={exportOrderExcel} disabled={exportLoading}>
              Export
            </SixtyButton>
          </>
        )}
      />
      {/* <Panel className={styles.panelWrap}>
        <div className={styles.orderTable}>
          <AdminOrderTable status={"Processing"} />
        </div>
      </Panel> */}
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Pending",
              recordsCount: orderData?.pendingCount,
              TabComponent: AdminOrderTable,
              props: {
                status: "Pending",
              },
            },
            {
              tabId: "2",
              label: "Processing",
              recordsCount: orderData?.processingCount,
              TabComponent: AdminOrderTable,
              props: {
                status: "Processing",
              },
            },
            {
              tabId: "3",
              label: "Shipped",
              recordsCount: orderData?.shippedCount,
              TabComponent: AdminOrderTable,
              props: {
                status: "Shipped",
              },
            },
            {
              tabId: "4",
              label: "Completed",
              recordsCount: orderData?.completedCount,
              TabComponent: AdminOrderTable,
              props: {
                status: "Completed",
              },
            },
            {
              tabId: "5",
              label: "Pending Refund",
              recordsCount: orderData?.pendingForRefundCount,
              TabComponent: AdminOrderTable,
              props: {
                status: "PendingRefund",
              },
            },
            {
              tabId: "6",
              label: "Refunded",
              recordsCount: orderData?.refundedCount,
              TabComponent: AdminOrderTable,
              props: {
                status: "Refunded",
              },
            },
          ]}
        ></Tabs>
      </Panel>
    </div>
  );
};

export default AdminOrders;
