import React, { useState } from "react";
import clsx from "clsx";
import { useForm, FormProvider } from "react-hook-form";
import { getFormattedDateString } from "utils/date.util";
import { useIssuedCampaignPayout } from "queries/admin";
import { useGetPostingContentDetail } from "queries/posting";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

const defaultValues = {
    issuedToReference: '',
    issuedToCode: '',
    issuedToName: '',
    issuedToId: '',
    amount: 0,
    remark: ''
  };

const PayoutRequestDetails = ({ selectedPayout, handleClose }) => {

  const formMethods = useForm({
         defaultValues,
  });

  const { id } = selectedPayout;

  const { data: payoutInfo, isLoading, isError, refetch } = useGetPostingContentDetail(
    id
  );

  const { mutateAsync: issuedCampaignPayout, 
          isLoading : payoutLoading 
        } = useIssuedCampaignPayout();

  const onIssuedPayout = async (data) => {
    try {
        
        const model = {
          issuedToReference: '',
          issuedToCode: payoutInfo?.uploadByCode,
          issuedToName: '',
          issuedToId: '',
          amount: parseFloat(data.amount), 
          remark: data.remark,
          password: data.password,
          transactionReference: payoutInfo?.id
        };
  
        const result = await issuedCampaignPayout(model);
  
        if (result) {
          handleClose();
          setToast("successfully issue payout");
        }
      } catch (e) {
        reportError(
          "error in : PayoutForm: onIssuedPayout, error: ",
          e
        );
      }
  };
        
  const onCancel = async () => {
    try {
        handleClose();
    } catch (e) {
      reportError(
        "error in : PayoutForm: onCancel, error: ",
        e
      );
    }
  };

  return (
    <React.Fragment>
      <div className={styles.payoutFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.payoutRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.payoutInfoHeader}
              title="Campaign Information"
            />
            <div className={styles.payoutInfoContainer}>
              <div className={styles.payoutInfoRow}>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Name</div>
                  <div>
                    {payoutInfo?.campaign}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={clsx(styles.payoutInfoHeader)}>Reward Amount</div>
                  <div>
                    {payoutInfo?.campaignReward}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Requested By</div>
                  <div>
                    {payoutInfo?.uploadBy}
                  </div>
                </div>
              </div>

            </div>
          </Panel>

          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.payoutInfoHeader}
              title="Payout"
            />
                <FormProvider {...formMethods}>
                    <form
                        className={styles.form}
                    >
                        <div className={styles.row}>             
                            <Input
                                name="amount"
                                type="number"
                                defaultValue={undefined}
                                label="Amount"
                                step=".01"
                                min={0}
                                className={styles.nameInput}
                            />                 
                        </div>
                        <div className={styles.row}>
                            <Input
                                name="remark"
                                label="Remark"
                                defaultValue=""
                                className={styles.nameInput}
                            />
                        </div>
                        <div className={styles.row}>
                            <Input
                                name="password"
                                label="Password"
                                type={"password"}
                                defaultValue=""
                                className={styles.nameInput}
                            />
                        </div>
                    </form>
                </FormProvider>
         </Panel>

        </div>
        </div>

        <footer className={styles.footer} hidden={isLoading}>
          <SixtyButton
            type="submit"
            onClick={formMethods.handleSubmit(onIssuedPayout)}
            className={styles.approveBtn}
            disabled={payoutLoading}
          >
            Approve
          </SixtyButton>
          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            disabled={payoutLoading}
            onClick={onCancel}
          >
            Cancel
          </SixtyButton>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default PayoutRequestDetails;
