import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useImmer } from "utils/hooks/useImmer";
import clsx from "clsx";
import { useGetDiscounts } from "queries/discount";
import useRowSelectionTable from "utils/hooks/useRowSelectionTable";
import _isEmpty from "lodash/isEmpty";

import Table, {
  RowSelectionContainer,
  TableHeader,
} from "components/SixtyTable";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import SixtyText from "components/SixtyText";
import SixtyButton from "components/SixtyButton";
import Filter from "components/SixtyTable/components/Filter";
import Sort from "components/SixtyTable/components/Sort";
import DiscountFilter, { renderFilterChips } from "./components/Filter";
import DiscountSort from "./components/Sort";
import EmptyGrid from "components/EmptyGrid";
import Loader from "components/Loader";
import { PanelActionButton } from "components/SixtyPanel";
import ErrorModal from "components/Error";

import styles from "./index.module.css";
import { ReactComponent as DiscountIcon } from "assets/icons/Icon-Discounts.svg";
import { getFormattedDateString } from "utils/date.util";
import { unknown_Error } from "common/constants";
import { useHistory } from "react-router-dom";

export default function DiscountTable() {
  const [search, setSearch] = useState("");
  const handleSearch = (value) => setSearch(value);

  const [filterState, setFilterState] = useImmer({});
  const [sort, setSort] = useState("orderByAscending");

  const history = useHistory();

  const onDiscountClick = useCallback(
    (value) => {
      const path = `/dashboard/promotions/${value}`;
      history.push(path);
    },
    [history]
  );

  const onFilterChange = (filterVal) => {
    // component filter state set
    setFilterState(() => filterVal);
  };

  const handleSortChange = useCallback(
    (field) => {
      setSort(field);
    },
    [setSort]
  );

  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    return {
      [sort]: true,
      keyword: search,
      ...filterState,
      startFrom,
      limit,
    };
  }, [filterState, search, sort, startFrom]);

  const {
    data: discountsData,
    isLoading,
    isError,
    refetch,
    error,
  } = useGetDiscounts(getFilters());

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, row }) => (
          <div
            className={clsx({
              [styles.disabled]: row.values.status?.toLowerCase() !== "enabled",
            })}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Code",
        accessor: "discountCode",
        Cell: ({ value, row }) => (
          <div
            className={clsx({
              [styles.disabled]: row.values.status?.toLowerCase() !== "enabled",
            })}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Type",
        accessor: "discountAmountMeasurement",
        Cell: ({ value, row }) => (
          <div
            className={clsx({
              [styles.disabled]: row.values.status?.toLowerCase() !== "enabled",
            })}
          >
            {value === "Percentage" ? value + "(%)" : value}
          </div>
        ),
      },
      {
        Header: "Type value",
        accessor: "discountAmount",
        Cell: ({ value, row }) => (
          <div
            className={clsx({
              [styles.disabled]: row.values.status?.toLowerCase() !== "enabled",
            })}
          >
            {value +
              `${
                row.original.discountAmountMeasurement === "Percentage"
                  ? "%"
                  : ""
              } `}
          </div>
        ),
      },
      {
        Header: "Start date",
        accessor: "beginDate",
        Cell: ({ value, row }) => (
          <div
            className={clsx({
              [styles.disabled]: row.values.status?.toLowerCase() !== "enabled",
            })}
          >
            {getFormattedDateString(value, "MMM d, y")}
          </div>
        ),
      },
      {
        Header: "Expired date",
        accessor: "endDate",
        Cell: ({ value, row }) => (
          <div
            className={clsx({
              [styles.disabled]: row.values.status?.toLowerCase() !== "enabled",
            })}
          >
            {getFormattedDateString(value, "MMM d, y")}
          </div>
        ),
      },
      {
        Header: "Usage",
        accessor: "usage",
        Cell: ({ value, row }) => (
          <div
            className={clsx({
              [styles.disabled]: row.values.status?.toLowerCase() !== "enabled",
            })}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => (
          <div
            className={clsx({
              [styles.disabled]: value?.toLowerCase() !== "enabled",
            })}
          >
            {value}
          </div>
        ),
      },
    ],
    []
  );

  let data = useMemo(
    () => (discountsData?.result ? discountsData.result : []),
    [discountsData]
  );

  const tableInstance = useRowSelectionTable({
    data,
    columns,
  });

  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (
      _isEmpty(filterState) &&
      !search &&
      discountsData?.result?.length === 0
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      {(!Array.isArray(discountsData?.result) || isDataEmpty()) && !isLoading && (
        <>
          <EmptyGrid
            Icon={DiscountIcon}
            emptyMessage="Your promotions will show up here"
          />
          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}
      {!isError && !isDataEmpty() && (
        <>
          {tableInstance.selectedFlatRows.length > 0 ? (
            <RowSelectionContainer
              renderRowSelectionText={() => (
                <>
                  <SixtyText>
                    {tableInstance.selectedFlatRows.length} products selected
                  </SixtyText>
                  <SixtyButton
                    className={styles.unselectButton}
                    variant="text"
                    onClick={() => tableInstance.toggleAllRowsSelected(false)}
                  >
                    unselect all
                  </SixtyButton>
                </>
              )}
              renderRowSelectionButtons={() => (
                <>
                  {/* <SixtyButton>Mark as shipped</SixtyButton>
                  <SixtyButton variant="secondary">Mark as paid</SixtyButton>
                  <SixtyButton variant="secondary">Archive</SixtyButton> */}
                </>
              )}
            />
          ) : (
            <>
              <TableHeader>
                <SearchFilter
                  search={search}
                  onSearchChange={handleSearch}
                  placeholder="Search for promotion name"
                />
                <Filter
                  filter={filterState}
                  setFilter={onFilterChange}
                  renderFilter={DiscountFilter}
                />
                <Sort
                  render={DiscountSort}
                  state={sort}
                  handleSortChange={handleSortChange}
                />
              </TableHeader>
              {renderFilterChips(filterState, setFilterState)}
            </>
          )}

          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(discountsData?.result) && (
              <Table
                instance={tableInstance}
                totalItems={discountsData?.totalRecords}
                startFrom={startFrom}
                limit={limit}
                setStart={setStart}
                onRowClicked={(row) => onDiscountClick(row.original.id)}
              />
            )
          )}
        </>
      )}
    </>
  );
}
