import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    useGetRenewableSubscriptionPlans,
    useProceedSubscriptionPayment,
} from "queries/subscription";
import map from "lodash/map";

import ProfileSetupLayout from "routes/layouts/ProfileSetupLayout";
import PricingPlanTemplate from "./components/SubscriptionPlanTemplate";
import GridHeader from "components/GridHeader";
import Panel from "components/SixtyPanel";
import Loader from "components/Loader";
import SixtyText from "components/SixtyText";
import SixtyLink from "components/SixtyLink";
import styles from "./index.module.css";
import BillingCardDetails from "../components/BillingCardDetails";
import AddEditCardDetailsForm from "../components/AddEditCardDetailsForm";
import Modal from "components/Modal";
import BasicPageHeader from "../../../Components/BasicHeader";

export default function VendorSubscriptionPlan({ gridHeader = "Pick a new plan" }) {
  const history = useHistory();
  const [activePanelId, setActivePanelId] = useState("Intermediate");
  const [isRedirectSubscription, setRedirect] = useState(false);

  const [isCardModalOpen, setCardModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const cardModalRef = useRef();

  const Title = () => {
    return (
        <div className={styles.modalTitleBar}>
           <div className={styles.modalTitle}>Subscription</div>
        </div>
    )
  };

  const {
    data: subscriptionPlans,
    isLoading,
    isSuccess,
  } = useGetRenewableSubscriptionPlans();

  const PaymentModalTitle = () => {
    return <div className={styles.modalTitle}>Payment</div>;
  };

  const sortedByPrice = subscriptionPlans?.length
    ? subscriptionPlans.sort(order)
    : [];

  const proceedSubscription = (id) => {
    setCardModalOpen(true);

    let result = subscriptionPlans.find(x => x.id == id);
    if(result){
        setSelectedPlan(result);
    }
  };

  const close = () => {
    setCardModalOpen(false);
    const path = `/dashboard/settings/billing`;
    history.push(path);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <React.Fragment>     
        <div className={styles.container}>
          {isLoading && (
            <Panel>
              <Loader />
            </Panel>
          )}
          {isSuccess && (
            <>
              <BasicPageHeader
                onGoBack={onGoBack}
                title="Pick a new plan"
              />
              <div className={styles.templateContainer}>
                {map(sortedByPrice, (subscriptionPlan, index) => {
                  return (
                    <PricingPlanTemplate
                      key={index}
                      subscriptionPlan={subscriptionPlan}
                      activePanelId={activePanelId}
                      setActivePanel={(name) => setActivePanelId(name)}
                      handleSubmit={proceedSubscription}
                      isLoading={isLoading}
                      isCurrentplan={subscriptionPlan.isSubscribed}
                    />
                  );
                })}
              </div>
            </>
          )}
          {isCardModalOpen && (
                <Modal
                    isOpen={isCardModalOpen}
                    onAttemptClose={() => {
                      setCardModalOpen(false);
                    }}
                    modalRef={cardModalRef}
                    Title={Title}
                >
                <BillingCardDetails
                      pricingPlan={selectedPlan}
                      close={close}
               />
                </Modal>
          )}

          <footer className={styles.footer}>
            <hr className={styles.footerHr} />
            <div>
              <SixtyText>
                Need help?{" "}
                <SixtyLink
                  color="orange"
                  inline
                  external
                  to={"mailto:hello@60sec.cc"}
                >
                  Contact us
                </SixtyLink>
              </SixtyText>
            </div>
          </footer>
        </div>
    </React.Fragment>
  );
}

function order(a, b) {
  return a.salePrice < b.salePrice ? -1 : a.salePrice > b.salePrice ? 1 : 0;
}
