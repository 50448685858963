import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import GridHeader from "components/GridHeader";
import Button from "components/SixtyButton";
import Input from "components/FormComponents/ControlledInput";
import { setToast } from "components/Toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    useSentAdvertisement,
} from "queries/admin";

import { reportError } from "crashlytics";
import _isEmpty from "lodash/isEmpty"; 
import Select from "components/FormComponents/ControlledSelect";
  
const schema = yup.object().shape({ 
    target: yup.string(),
    method: yup.string().required("select an method"),
    title: yup.string().required("content title is required"),
    body: yup.string().required("content body is required"),
});

const Advertisement = () =>{
    
    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { control, setValue, errors } = formMethods;
    const [targetAudience, setTargetAudience] = useState('');
    const [targetMethod, setTargetMethod] = useState('');

    const { mutateAsync: sentAdvertisement, isLoading } = useSentAdvertisement();
    const onSubmit = async (data) => {

        try {

          const result =  await sentAdvertisement({
            target: data?.target,
            method: data?.method,
            title: data?.title,
            body: data?.body,
            targetEmail: data?.targetEmail ?? null
          });

          if (result) {
            setToast("Successfully send content");
          }
          
        } catch (error) {
          reportError("Advertisement: onSubmit: error: ", error);
        }
      };
    return (
    <div className={styles.advertisementContainer}>
      <GridHeader header="Announcement" />
      <div>
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}
            className={styles.form}
          >
            <Panel className={styles.panelWrap}>
            <div className={styles.formRow}>
                <Select
                    name="target"
                    error={errors.target}
                    options={[
                        {
                          label: "Influencer",
                          selectedLabel: "Influencer",
                          value: "Seller",
                        },
                        {
                            label: "Vendor",
                            selectedLabel: "Vendor",
                            value: "Supplier",
                        },
                    ]}
                    control={control}
                    fullWidth
                    defaultOption="Select an target audience"
                    handleChange={(name, value) => {
                        setTargetAudience(value);
                    }}
                    value={targetAudience}
                    defaultValue={targetAudience}
               />
            </div>
            <div className={styles.formRow}>
                {targetAudience == 'Seller' && (
                    <Select
                        name="method"
                        error={errors.method}
                        options={[
                            {
                               label: "Notification",
                               selectedLabel: "Notification",
                               value: "Notification",
                            },
                            {
                                label: "Email",
                                selectedLabel: "Email",
                                value: "Email",
                            },
                        ]}
                        control={control}
                        fullWidth
                        handleChange={(name, value) => {
                            setTargetMethod(value);
                        }}
                        defaultOption="Select an method"
                    />
                )}
                {targetAudience == 'Supplier' && (
                    <Select
                        name="method"
                        error={errors.method}
                        options={[
                            {
                                label: "Email",
                                selectedLabel: "Email",
                                value: "Email",
                            },
                        ]}
                        control={control}
                        fullWidth
                        handleChange={(name, value) => {
                            setTargetMethod(value);
                        }}
                        defaultOption="Select an method"
                    />
                )}
            </div>
            <div className={styles.formRow}>
               {targetMethod == 'Email' && (
                  <Input
                    name="targetEmail"
                    placeholder="send message to specific email (Default will send to all target audiences)"
                    control={control}
                    fullWidth
                    error={errors.title}
                    label="Specific Email"
                  /> 
                )}             
            </div>
            <div className={styles.formRow}>
                <Input
                    name="title"
                    placeholder="title for content"
                    control={control}
                    fullWidth
                    error={errors.title}
                    label="Content Title"
                />               
            </div>
            <div className={styles.formRow}>
               <Input
                    name="body"
                    placeholder="Content body"
                    control={control}
                    fullWidth
                    label="Content Body"
                    lines="12"
                />
             </div>
            </Panel>
            <div className={styles.footer}>
              <Button type="submit" disabled={isLoading}>Send Content</Button>
            </div>
            </form>
            </FormProvider> 
            </div>
            </div>
   )
}

export default Advertisement;