import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useVoidPayout } from "queries/admin";

import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import ControlledSelect from "components/FormComponents/ControlledSelect";
import Input from "components/FormComponents/ControlledInput";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import { useEffect } from "react";
import { useState } from "react";

const Confirmation = ({ title, handleSuccess, handleClose }) => {

    const onSubmit = () => {
        try {  
         handleSuccess();
         handleClose();
        } catch (e) {
          reportError("error in : Confirmation: onSubmit, error: ", e);
        }
    };

    const boxTitle = () => {
      return (<div>{title}</div>);
    }

    return (
        <React.Fragment>
            <div className={styles.contentVoidForm}>
                <div className={styles.sellerRejectForm}>
                
                <div className={styles.Container}>
                     <div>{title}</div>
                </div>
                </div>
                <footer className={styles.footer}>
                  <SixtyButton
                    type="submit"
                    variant="warning"
                    className={styles.voidBtn}     
                    onClick={onSubmit}          
                  >
                    Confirm Action
                  </SixtyButton>
                  <SixtyButton
                    variant="secondary"
                    className={styles.cancelButton}
                    onClick={handleClose}
                  >
                    Cancel
                  </SixtyButton>
                </footer>
            </div>
        </React.Fragment>
      );
};

export default Confirmation;