import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { queryClient } from "../common/query";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";

export function useGetDiscounts(filter) {
  return useQuery(
    [Endpoints.Discount.GetDiscounts, filter],
    () =>
      api
        .post(Endpoints.Discount.GetDiscounts, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetDiscounts: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetDiscountDetail(orderId) {
  return useQuery(
    [Endpoints.Discount.GetDiscountDetail, orderId],
    () =>
      api
        .get(Endpoints.Discount.GetDiscountDetail + `/${orderId}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetDiscountDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useGenerateDiscountCode() {
  return useMutation(
    () =>
      api
        .post(Endpoints.Discount.GenerateDiscountCode)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGenerateDiscountCode: useQuery: onError: error", error);
      },
    }
  );
}

export function useAddDiscount() {
  return useMutation(
    (discountData) =>
      api
        .post(Endpoints.Discount.AddDiscount, discountData)
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Discount.GetDiscounts);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useAddDiscount: useQuery: onError: error", error);
      },
    }
  );
}

export function useUpdateDiscount() {
  return useMutation(
    (discountData) =>
      api
        .put(Endpoints.Discount.UpdateDiscount, discountData)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdateDiscount: useQuery: onError: error", error);
      },
    }
  );
}

export function useDeleteDiscount() {
  return useMutation(
    (discountId) =>
      api
        .delete(Endpoints.Discount.DeleteDiscount + `/${discountId}`)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useDeleteDiscount: useQuery: onError: error", error);
      },
    }
  );
}
export function useActivateDiscount() {
  return useMutation(
    (discountId) =>
      api
        .put(Endpoints.Discount.ActivateDiscount + `/${discountId}`)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useActivateDiscount: useQuery: onError: error", error);
      },
    }
  );
}

export function useDeactivateDiscount() {
  return useMutation(
    (discountId) =>
      api
        .put(Endpoints.Discount.DeactivateDiscount + `/${discountId}`)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useDeactivateDiscount: useQuery: onError: error", error);
      },
    }
  );
}
