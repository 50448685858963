import {
  BrowserRouter as ReactRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Components from "screens/Components";
import ForgotPassword from "screens/Onboarding/ForgotPassword";
import ResetPassword from "screens/Onboarding/ResetPassword";
import SignUp from "screens/Onboarding/SignUp";
import VerifyEmail from "screens/Onboarding/VerifyEmail";
import Login from "screens/Onboarding/Login";
import SetupProfile from "screens/Onboarding/SetupProfile";
import LoginLayout from "./layouts/LoginLayout/LoginLayout";
import Orders from "screens/Orders";
import Products from "screens/Products";
import Categories from "screens/Products/Categories";
import Sellers from "screens/Sellers";
import Customers from "screens/Customers";
import Discounts from "screens/Discounts";
import CreateDiscountForm from "screens/Discounts/CreateDiscountForm";
import Reports from "screens/Reports";
import WalletBalances from "screens/WalletBalances";
import CompanySettings from "screens/Settings";
import Dashboard from "screens/Dashboard";
import OrderDetails from "screens/Orders/orderDetails";
import DashboardLayout from "./layouts/DashboardLayout";
import AdminLayout from "./layouts/AdminLayout";
import CreateProduct from "screens/Products/CreateProduct";
import ProductDetails from "screens/Products/ProductDetails";
import SellerDetails from "screens/Sellers/SellerDetails";
import SellerRequest from "screens/Sellers/SellerRequest";
import SellerTiers from "screens/Sellers/SellerTiers";
import SetupUserAuthentication from "screens/Onboarding/SetupUserAuthentication";
import TotalSalesDetailsPage from "screens/Reports/components/TotalSalesDetails";
import ShippingSettings from "screens/Settings/Shippings";
import TaxesSettings from "screens/Settings/Taxes";
import BillingSettings from "screens/Settings/Billing";
import NotificationSettings from "screens/Settings/NotificationSettings";
import AccountSettings from "screens/Settings/AccountSettings";
import SellerGlobalCommission from "screens/Settings/SellerGlobalCommission";
import CategoryDetails from "screens/Products/Categories/components/CategoryDetails";
import PricingPlan from "screens/Onboarding/PricingPlan";
import DiscountDetailsForm from "screens/Discounts/DiscountDetails";
import EditTax from "screens/Settings/Taxes/EditTax";
import EditShipping from "screens/Settings/Shippings/components/EditShippingZone";
import TierDetails from "screens/Sellers/SellerTiers/TierDetails";
import Vendors from "screens/Vendors";
import Withdraws from "screens/Withdraws";
import VendorRefunds from"screens/VendorRefunds"
import VendorRefundDetail from"screens/VendorRefunds/Components/RefundDetailForm"
import VendorAnalytic from"screens/VendorAnalytics"
import SellerRegistrations from"screens/Sellers/SellerRegistrations";
import SellerApproval from"screens/Sellers/SellerApproval";
import SellerRejection from"screens/Sellers/SellerRejection";
import SellerAnalyticPage from"screens/Sellers/SellerAnalyticDetails";
import CreditCardSubscribe from"screens/Onboarding/SubscriptionPlan/CreditCard";
import SubscriptionPlan from"screens/Onboarding/SubscriptionPlan";
import Payout from"screens/Payouts";
import CampaignPayout from"screens/Payouts/CampaignPayouts";
import InfluencerSelection from"screens/Payouts/InfluencerSelection";
import Campaign from"screens/Campaigns";
import VendorSelection from"screens/Campaigns/VendorSelection";
import CampaignAnalyticPage from"screens/Campaigns/CampaignAnalyticDetails";
import CampaignAnalytic from"screens/Campaigns/CampaignAnalytics";
import CampaignRequests from"screens/Campaigns/CampaignRequests";
import AdminInfluencerJoinRequest from"screens/Campaigns/AdminInfluencerJoinRequest";
import AffiliateProgram from "screens/Vendors/AffiliateProgram";
import CampaignContents from"screens/Campaigns/CampaignContents";
import ContentPublishing from"screens/Campaigns/ContentPublishing";
import InfluencerPayoutHistory from "screens/Payouts/InfluencerPayoutHistory";
import CampaignStepAutomate from "screens/Campaigns/CampaignStepAutomate";
import Advertisement from "screens/AdminScreens/Advertisements";
import CampaignEvents from "screens/Campaigns/CampaignEvents";
import AffiliateContent from "screens/Campaigns/AffiliateContents";
import AdminOrders from "screens/Orders/AdminOrders";
import VendorSubscriptionPlan from "screens/Settings/Billing/SubscriptionPlan";
import PricingPlanListing from "screens/PricingPlanManagement";
import Promotions from "screens/PricingPlanManagement/Promotions";
import CreatePromotion from "screens/PricingPlanManagement/CreatePromotion";
import RefundRequests from "screens/Orders/RefundRequests";
import AdminBuyerRefund from "screens/Orders/AdminBuyerRefunds";
import CampaignRecruitment from "screens/Campaigns/CampaignRecruitment";
import CampaignShipment from "screens/Campaigns/CampaignShipment";
import CampaignDraft from "screens/Campaigns/CampaignDrafts";
import CampaignPost from "screens/Campaigns/CampaignPosts";
import AdminCampaignProducts from "screens/Campaigns/AdminCampaignProducts";
import Reporting from "screens/Campaigns/Reporting";
import BlogVendorSelection from "screens/Blogs/VendorSelection";
import CreateBlog from "screens/Blogs/CreateBlog";
import UpdateBlog from "screens/Blogs/UpdateBlog";
import Blog from "screens/Blogs";
import BlogProducts from "screens/Blogs/BlogProducts";
import LazadaImport from "screens/Settings/LazadaImport";
import AdminDashboard from "screens/AdminScreens/Dashboard";
import ProductRequests from "screens/ProductRequests";
import VideoStories from "screens/AdminScreens/VideoStories";
import UgcCampaign from "screens/CampaignSubProducts/Components/UgcCampaign";
import UgcOrders from "screens/AdminScreens/UgcOrders";
import PayoutWallets from "screens/Payouts/PayoutWallets";

export default function Router() {
  const routeConfig = [
    {
      path: "/",
      component: <Redirect to="/auth/login" />,
      isPublic: true,
      exact: true,
      roles: ["Supplier"],
    },
    {
      path: "/components",
      component: <Components />,
      exact: true,
      roles: ["Supplier"],
    },
    {
      path: "/setup-profile",
      component: <SetupProfile />,
      exact: true,
      roles: ["Supplier"],
    },
    {
      path: "/setup-user-authentication",
      component: <SetupUserAuthentication />,
      exact: true,
      roles: ["Supplier"],
    },
    {
      path: "/reset-password",
      component: <ResetPassword />,
      exact: true,
      roles: ["Supplier"],
    },
    {
      path: "/pricing-plan",
      component: <SubscriptionPlan />,
      exact: true,
      roles: ["Supplier"],
    },
    {
      path: "/subscription/:id",
      component: <CreditCardSubscribe />,
      exact: true,
      roles: ["Supplier"],
    },
    {
      path: "/auth",
      layout: LoginLayout,
      isPublic: true,
      roles: ["Supplier"],
      routes: [
        {
          path: "/auth/login",
          component: Login,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/auth/forgot-password",
          component: ForgotPassword,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/auth/sign-up",
          component: SignUp,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/auth/sign-up/:id",
          component: SignUp,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/auth/verify-email",
          component: VerifyEmail,
          exact: true,
          roles: ["Supplier"],
        },
      ],
    },
    {
      path: "/dashboard",
      layout: DashboardLayout,
      roles: ["Supplier"],
      routes: [
        {
          path: "/dashboard",
          component: Dashboard,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/product-requests",
          component: ProductRequests,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/orders",
          component: Orders,
          exact: true,
          roles: ["Supplier"],
        },        
        {
          path: "/dashboard/refundRequests",
          component: RefundRequests,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/orders/:id",
          component: OrderDetails,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/products",
          component: Products,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/product-categories",
          component: Categories,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/product-categories/:id",
          component: CategoryDetails,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/create-product",
          component: CreateProduct,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/products/:id",
          component: ProductDetails,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/creators",
          component: Sellers,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/creators/:id",
          component: SellerDetails,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/creatorRequest",
          component: SellerRequest,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/tiers",
          component: SellerTiers,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/tiers/:id",
          component: TierDetails,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/customers",
          component: Customers,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/promotions",
          component: Discounts,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/promotions/:id",
          component: DiscountDetailsForm,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/create-promotion",
          component: CreateDiscountForm,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/reports",
          component: Reports,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/reports/totalSalesReport",
          component: TotalSalesDetailsPage,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/walletbalances",
          component: WalletBalances,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/settings/lazada-import",
          component: LazadaImport,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/settings/company",
          component: CompanySettings,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/settings/shippings",
          component: ShippingSettings,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/settings/shippings/:id",
          component: EditShipping,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/settings/taxes",
          component: TaxesSettings,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/settings/taxes/:id",
          component: EditTax,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/settings/billing",
          component: BillingSettings,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/settings/notifications",
          component: NotificationSettings,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/accountSettings",
          component: AccountSettings,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/settings/affiliateCommission",
          component: SellerGlobalCommission,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/VideoOrders",
          component: CampaignAnalytic,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/campaigns/AnalyticDetails/:id",
          component: CampaignAnalyticPage,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/campaigns/postingContent",
          component: CampaignContents,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/campaigns/contentPublishing/:id",
          component: ContentPublishing,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/campaigns/stepToComplete/:id",
          component: CampaignStepAutomate,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/campaigns/event",
          component: CampaignEvents,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/settings/billing/plans",
          component: VendorSubscriptionPlan,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/campaign/recruitment/:id",
          component: CampaignRecruitment,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/campaign/shipment/:id",
          component: CampaignShipment,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/campaign/draft/:id",
          component: CampaignDraft,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/campaign/post/:id",
          component: CampaignPost,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/campaign/reporting",
          component: Reporting,
          exact: true,
          roles: ["Supplier"],
        },
        {
          path: "/dashboard/services/ugc/:id",
          component: UgcCampaign,
          exact: true,
          roles: ["Supplier"],
        },
      ],
    },
    {
      path: "/admin",
      layout: AdminLayout,
      roles: ["InternalUser"],
      routes: [
        {
          path: "/admin/dashboard",
          component: AdminDashboard,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/product-requests",
          component: ProductRequests,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/blogs/vendor-selection",
          component: BlogVendorSelection,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/blogs/blog-products/:id",
          component: BlogProducts,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/blogs/create-blog/:id",
          component: CreateBlog,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/blogs/update-blog/:id",
          component: UpdateBlog,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/blogs",
          component: Blog,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/vendors",
          component: Vendors,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/withdraws",
          component: Withdraws,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/vendors/refunds",
          component: VendorRefunds,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/vendors/refunds/:id",
          component: VendorRefundDetail,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/analytics/vendorAffliate",
          component: VendorAnalytic,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/influencers/registrations",
          component: SellerRegistrations,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/influencers/approval",
          component: SellerApproval,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/influencers/rejection",
          component: SellerRejection,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/influencers/AnalyticDetails/:id",
          component: SellerAnalyticPage,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/payouts",
          component: Payout,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/InfluencerWallets",
          component: PayoutWallets,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/PayoutRequest",
          component: CampaignPayout,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/PayoutRequest/:id",
          component: CampaignPayout,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/payouts/influencer-selection",
          component: InfluencerSelection,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/campaigns",
          component: Campaign,
          exact: true,
          roles: ["InternalUser"], 
        },
        {
          path: "/admin/campaigns/Requests",
          component: CampaignRequests,
          exact: true,
          roles: ["InternalUser"], 
        },
        {
          path: "/admin/campaigns/InfluencerRequests/:id",
          component: AdminInfluencerJoinRequest,
          exact: true,
          roles: ["InternalUser"], 
        },
        {
          path: "/admin/campaigns/vendor-selection",
          component: VendorSelection,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/campaignAnalytics",
          component: CampaignAnalytic,
          exact: true,
          roles: ["InternalUser"], 
        },
        {
          path: "/admin/campaigns/AnalyticDetails/:id",
          component: CampaignAnalyticPage,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/subscriptionPlan/Preview",
          component: SubscriptionPlan,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/affiliateProgram",
          component: AffiliateProgram,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/campaigns/PostingContents",
          component: CampaignContents,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/campaigns/contentPublishing/:id",
          component: ContentPublishing,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/influencer/histories/:id",
          component: InfluencerPayoutHistory,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/campaigns/stepToComplete/:id",
          component: CampaignStepAutomate,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/announcement",
          component: Advertisement,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/affiliateContents",
          component: AffiliateContent,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/orders",
          component: AdminOrders,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/orders/:id",
          component: OrderDetails,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/pricingPlanListing",
          component: PricingPlanListing,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/promotions",
          component: Promotions,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/createPromotion",
          component: CreatePromotion,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/BuyerRefundRequest",
          component: AdminBuyerRefund,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/campaign/products/:id",
          component: AdminCampaignProducts,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/videoStories",
          component: VideoStories,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/ugcOrders",
          component: UgcOrders,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/ugcOrder/:id",
          component: UgcCampaign,
          exact: true,
          roles: ["InternalUser"],
        },
      ],
    },
  ];
  return (
    <ReactRouter>
      <Switch>
        {routeConfig.map((routeItem) =>
          routeItem?.isPublic ? (
            <Route
              path={routeItem.path}
              exact={routeItem.exact}
              key={routeItem.path}
            >
              {routeItem.layout ? (
                <routeItem.layout
                  children={
                    routeItem.routes?.length > 0
                      ? routeItem.routes.map((route) => (
                          <Route
                            path={route.path}
                            exact={route.exact}
                            key={route.path}
                          >
                            <route.component />
                          </Route>
                        ))
                      : null
                  }
                />
              ) : (
                routeItem.component
              )}
            </Route>
          ) : (
            <AuthenticatedRoute
              path={routeItem.path}
              exact={routeItem.exact}
              key={routeItem.path}
              roles={routeItem.roles}
            >
              {routeItem.layout ? (
                <routeItem.layout
                  children={
                    routeItem.routes?.length > 0
                      ? routeItem.routes.map((route) => (
                          <AuthenticatedRoute
                            path={route.path}
                            exact={route.exact}
                            key={route.path}
                            roles={route.roles}
                          >
                            <route.component />
                          </AuthenticatedRoute>
                        ))
                      : null
                  }
                />
              ) : (
                routeItem.component
              )}
            </AuthenticatedRoute>
          )
        )}
      </Switch>
    </ReactRouter>
  );
}

const AuthenticatedRoute = ({ children, roles, ...rest }) => {
  const token = localStorage.getItem("token");
  return (
    <>
      <Route
        {...rest}
        render={() =>
          token ? grantPermission(roles) && children : <Redirect to="/" />
        }
      ></Route>
    </>
  );
};

export const grantPermission = (requestedRoles) => {
  const role = localStorage.getItem("userRole");
  if (role && requestedRoles.includes(role)) {
    return true;
  }
  return false;
};
