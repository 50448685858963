import { useForm, FormProvider } from "react-hook-form";
import Input from "components/FormComponents/ControlledInput";
import styles from "./index.module.css";
import Button from "components/SixtyButton";

const CategoryForm = ({
    categoryName,
    handleSubmit
}) => {

    const defaultValues = {
        name: categoryName || ''
    };
    const formMethods = useForm({
        defaultValues,
    });

    const onSubmit = (data) => {
        console.log("data from form", data);
        if (handleSubmit) {
            handleSubmit();
        }
    };

    return (
        <FormProvider {...formMethods}>
            <form
                onSubmit={formMethods.handleSubmit(onSubmit)}
                className={styles.form}
            >
                <div className={styles.row}>
                    <Input
                        name="name"
                        label="Name"
                        value={categoryName}
                        defaultValue=""
                        className={styles.nameInput}
                    />
                    <Button type="submit" >Submit</Button>
                </div>
            </form>
        </FormProvider>
    )
}

export default CategoryForm;