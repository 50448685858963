import React from 'react';
import { Chart } from 'chart.js';
import styles from './index.module.css';
import clsx from 'clsx';
//todo: tooltip is now displaying separately for each point. We need to make it sync with design.
//todo: onhover, show a bubble and vertical dashed line


Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
    draw: function (ease) {
        Chart.controllers.line.prototype.draw.call(this, ease);

        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
            var activePoint = this.chart.tooltip._active[0],
                ctx = this.chart.ctx,
                x = activePoint.tooltipPosition().x,
                topY = this.chart.legend.bottom,
                bottomY = this.chart.chartArea.bottom;

            // draw line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
            ctx.setLineDash([2, 2]);
            ctx.stroke();
            ctx.restore();
        }
    }
});

class LineChart extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        var annotation = {
            annotations: [{
                type: 'line',
                mode: 'vertical',
                scaleID: 'x-axis',
                borderColor: '#b6fcd5',
                borderWidth: 2
            }]
        };

        var customTooltips = function (tooltip, data) {
            // Tooltip Element
            var tooltipEl = document.getElementById(props.id);
            // Hide if no tooltip
            if (tooltipEl && tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                tooltipEl.innerHTML = '';
                return;
            }
            tooltipEl.innerHTML = '';
            if (!tooltip?.dataPoints?.length) {
                return;
            }
            var tooltipEl1 = document.createElement('div');
            tooltipEl1.id = 'chartjstooltipLine';
            tooltipEl1.innerHTML = "<div style='border: none'></div>";
            tooltipEl.appendChild(tooltipEl1);

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltip.yAlign) {
                tooltipEl.classList.add(tooltip.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            // Set Text
            function getRowStyles(index, selectedData) {
                const a = clsx({
                    [styles.tooltipRows]: true,
                    [styles.tooltipSeperator]: index !== selectedData.length - 1,
                    [styles.tooltipfirstRow]: index === 0
                });
                return a;
            }
            if (tooltip.body) {
                if (tooltip?.dataPoints?.length) {
                    var selectedData = props.data[tooltip?.dataPoints[0].index];
                    selectedData = selectedData ? [
                        {
                            label: selectedData.label,
                            value: selectedData.getOrginalValue ? selectedData.getOrginalValue(selectedData.value) : selectedData.value
                        },
                        {
                            label: selectedData.previousMonthLabel,
                            value: selectedData.previousMonthValue ? selectedData.getOrginalValue(selectedData.previousMonthValue) : selectedData.previousMonthValue
                        }
                    ] : [];
                    selectedData.forEach((dataValue, index) => {
                        //PUT CUSTOM HTML TOOLTIP CONTENT HERE (innerHTML)
                        var innerHtml = `<div  class="${getRowStyles(index, selectedData)}">`;
                        innerHtml += `<div><span class=${styles.tooltipHeader}>` + dataValue.label + `</span>`;
                        innerHtml += `</div><div>`;
                        var colors = tooltip.labelColors[0];
                        var style = 'background:' + colors.backgroundColor;
                        style += '; border-color:' + colors.borderColor;
                        style += '; border-width: 2px';
                        var span = '<span class="chartjstooltip-key" style="' + style + '"></span>';
                        innerHtml += `<div><div class=${styles.tooltipValue}>` + span + dataValue.value + ' ' + (props.tooltipText || '') + `</div></div>`;
                        innerHtml += '</div></div>';
                        var tableRoot = tooltipEl.querySelector('div');
                        tableRoot.innerHTML += innerHtml;
                    });
                }
            }
            // Display, position, and set styles for font
            tooltipEl.style.position = "absolute";
            tooltipEl.style.background = "black";
            tooltipEl.style.color = "white";
            tooltipEl.style.width = "117px";
            tooltipEl.style.height = "auto";
            tooltipEl.style.opacity = 1;
            tooltipEl.style.zIndex = 10000;
            tooltipEl.style.left = 100 + tooltip.caretX + 'px';
            tooltipEl.style.top = tooltip.caretY + 'px';
            tooltipEl.style.fontFamily = tooltip._fontFamily;
            tooltipEl.style.fontSize = tooltip.fontSize;
            tooltipEl.style.fontStyle = tooltip._fontStyle;
            tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
        };

        this.options = {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                easing: 'easeInOutQuad',
                duration: 520
            },
            scales: {
                xAxes: [{
                    color: 'rgba(0, 0, 0, 0.05)',
                    gridLines: {
                        color: 'rgba(0, 0, 0, 0.05)',
                        lineWidth: 1,
                        zeroLineColor: 'rgba(0, 0, 0, 0.05)'
                    },
                    ticks: {
                        fontSize: '12',
                        fontFamily: 'Roboto',
                        fontColor: 'rgba(0, 0, 0, 0.25)',
                        fontStyle: 'normal',
                    }
                }],
                yAxes: [{
                    gridLines: {
                        color: 'rgba(0, 0, 0, 0.05)',
                        lineWidth: 1,
                        zeroLineColor: 'rgba(0, 0, 0, 0.05)',
                    },
                    ticks: {
                        min: 0,
                        max: props.maxYaxisTicks || 4,
                        stepSize: 1,
                        suggestedMin: 0.5,
                        suggestedMax: 5.5,
                        fontSize: '12',
                        fontFamily: 'Roboto',
                        fontColor: 'rgba(0, 0, 0, 0.25)',
                        fontStyle: 'normal',
                        callback: function (label, index, labels) {
                            if (props.getYaxislabel) {
                                return props.getYaxislabel(label);
                            }
                            return label;
                        }
                    }
                }
                ]
            },
            elements: {
                line: {
                    tension: 0
                }
            },
            legend: {
                display: false
            },
            point: {
                radius: '0'
            },
            tooltips: {
                enabled: false,
                mode: 'nearest',
                intersect: false,
                custom: customTooltips
            },
            annotation: annotation
        };
    }

    componentDidUpdate() {
        this.myChart.data.labels = this.props.data.map(d => d.label);
        this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
        this.myChart.update();
    }

    componentDidMount() {
        this.ctx = document.getElementById('canvas').getContext("2d")
        this.gradient1 = this.ctx.createLinearGradient(0, 0, 0, 400)
        this.gradient1.addColorStop(0, 'rgba(245,183,85,1)');
        this.gradient1.addColorStop(0.5, 'rgba(255,128,114,1)');
        this.gradient2 = this.ctx.createLinearGradient(0, 0, 0, 400)
        this.gradient2.addColorStop(0, 'rgba(0,0,0,0.1)');
        this.gradient2.addColorStop(0.5, 'rgba(0,0,0,0.5)');
        this.myChart = new Chart(this.canvasRef.current, {
            type: 'LineWithLine',
            options: this.options,
            data: {
                labels: this.props.data.map(d => d.label),
                datasets: [{
                    data: this.props.data.map(d => d.value),
                    fill: 'none',
                    backgroundColor: this.props.color,
                    pointRadius: 0,
                    borderColor: this.gradient1,
                    borderWidth: 2,
                },
                {
                    data: this.props.data.map(d => d.previousMonthValue),
                    fill: 'none',
                    backgroundColor: this.props.color,
                    pointRadius: 0,
                    borderColor: this.gradient2,
                    borderWidth: 2,
                }
                ]
            }
        });
    }



    render() {
        return (
            <div className={clsx(styles.chartContainer, this.props.className)}>
                <canvas id="canvas" ref={this.canvasRef}></canvas>
                <div id={this.props.id} className={styles.chartjstooltipLine}></div>
            </div>
        )
    }
}

export default LineChart;