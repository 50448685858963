import PanelHeader from 'components/PanelHeader';
import Panel from 'components/SixtyPanel';
import styles from './index.module.css';
import { ReactComponent as ExclamationIcon } from "assets/icons/Icon-Exclamation.svg";
import Button from "components/SixtyButton";

const PlanLimitExceedDetails = ({
    currentPlan,
    onUpgradePlan,
    onUpgradePlanCancel
}) => {
    return (
        <div className={styles.planExceededContainer}>
            <span className={styles.exclamationIcon}><ExclamationIcon /></span>
            <div className={styles.title}>
                You have exceeded your plan limits
            </div>
            <div className={styles.subTtitle}>
                Please upgrade your plan to add more sellers
            </div>
            <Panel className={styles.panelWrap}>
                <PanelHeader title="Your current plan" className={styles.panelHeaderHelper} />
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div className={styles.fieldHeader}>
                            Plan
                        </div>
                        <div className={styles.fieldValue}>
                            {currentPlan?.plan}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.fieldHeader}>
                            Number of sellers
                        </div>
                        <div className={styles.fieldValue}>
                            {currentPlan?.sellerCount}
                        </div>
                    </div>
                </div>
            </Panel>
            <div className={styles.buttonsContainer}>
                <Button onClick={onUpgradePlan} label="Upgrade plan" />
                <Button variant="secondary" onClick={onUpgradePlanCancel} label="Cancel" />
            </div>
        </div>
    )
}

export default PlanLimitExceedDetails;