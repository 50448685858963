import { useState, useEffect, useRef } from "react";
import { useDownloadCampaignReceipt, useTopupCampaignReward, 
         useUpdateCampaignProgress, useGetCampaignDetail, 
         useCampaignNotification } from "queries/campaign";
import { reportError } from "crashlytics";
import { format } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import Loader from "components/Loader";
import Text from "components/SixtyText";
import Button from "components/SixtyButton";
import Stepper from "components/Stepper";
import Link from "components/SixtyLink";
import CampaignInformation from "./Components/CampaignInformation";
import Paper from "components/SixtyPaper";
import clsx from "clsx";
import { setToast } from "components/Toast";
import styles from "./index.module.css";
import Modal from "components/Modal";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import EditCampaign from '../UgcCampaign/Components/EditCampaign';
import Invoicing from '../UgcCampaign/Components/Invoicing';
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import Recruitment from "./Components/Recruitment";
import Contents from "./Components/Contents";

export default function UgcCampaign() {
  const params = useParams();
  const [recruitmentMessage, setRecruitmentMessage] = useState(null);
  const [isEditCampaign, setEditCampaign] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [AwaitingRecruitment, setRecruitmentStatus] = useState(true);
  const { data: campaignData, isLoading: campaignLoading, refetch } = useGetCampaignDetail(params.id);

  const [progress, setProgress] = useState(["active", "notReached", "notReached", "notReached"]);
  const campaignRef = useRef();

  const CampaignTitle = () => {
    return (
      <div className={styles.modalTitleBar}>
        <div className={styles.modalContainer}>
          <div className={styles.modalTitle}>{'Edit Order'}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setEditCampaign(false)}
          />
        </div>
      </div>
    );
  };

  const onOpenCampaign = () => {
    setEditCampaign(true);
  };

  const onCloseCampaign = () => {
    setEditCampaign(false);
  };

  const { mutateAsync: triggerNotification,
    isSuccess: isTriggerNotification,
    isLoading: isLoadingNotification
  } = useCampaignNotification();

  const { mutateAsync: downloadReceipt,
         isSuccess: isDownloadSuccess,
         isLoading: isDownloadLoading
  } = useDownloadCampaignReceipt();

  const onDownloadReceipt = async () => {
    setToast(`Downloading Receipt`);
    const model = {
      id: campaignData?.topupReference,
      campaignReference: params.id
    }

    await downloadReceipt(model);
  };

  const { mutateAsync: topup,
          isSuccess: isTopupSuccess,
          isLoading: isTopupLoading
  } = useTopupCampaignReward();

  const onTopup= async () => {

        setToast(`Generate Payment Link`);

    try{
      if(campaignData?.topupReference){
        const path = `https://payment.60sec.asia/Transaction/Stripe?transactionId=${campaignData?.topupReference}`;
        window.open(path, "_blank");
      }
      else{
        const model = {
          campaignReference: campaignData?.id
        }
        var response = await topup(model);

        if(response){
          const path = `https://payment.60sec.asia/Transaction/Stripe?transactionId=${response?.id}`;
          window.open(path, "_blank");

          refetch();
        }
    }
    }
    catch(e){
      reportError(
        "error in : UgcCampaign: onTopup, error: ",
        e
      );
    }  
  };

  const { mutateAsync: updateProgress, 
          isLoading : progressLoading 
  } = useUpdateCampaignProgress();

  const onUpdateProgress = async (progress) => {
  try {

      const model = {
          id: params.id,
          progress: progress,
      };
      
      var result = await updateProgress(model);

      if(result){
          setToast("successfully update progress");
          loadProgressStatus(progress);
      }
    
  } catch (e) {
      reportError(
        "error in : UgcCampaign: onUpdateProgress, error: ",
        e
      );
    }
  };

  function GetCurrentProgress(targetProgress){
    let totalProgress = 2;
    let result = [];

    for(let i = 0; i <= totalProgress; i++){
       if(i == targetProgress){
        result.push("active")
       }
       else if(i != targetProgress && i <= currentProgress){
        result.push("visited")
       }
       else if(i != targetProgress && i > currentProgress){
        result.push("notReached")
       }
    }

    return result;
  }

  const goToCampaign = () => {
      const newProgress = 0;
      const updatedProgress = GetCurrentProgress(newProgress);
      setProgress(updatedProgress);
  };

  const goToPayment = () => {
    const newProgress = 1;
    const updatedProgress = GetCurrentProgress(newProgress);
    setProgress(updatedProgress);
  };

  const goToRecruitment = () => {
    const newProgress = 2;
    const updatedProgress = GetCurrentProgress(newProgress);
    setProgress(updatedProgress);
  };

  const goToContent = () => {
    const newProgress = 2;
    const updatedProgress = GetCurrentProgress(newProgress);
    setProgress(updatedProgress);
  };

  const goToClose = () => {
    const newProgress = 4;
    const updatedProgress = GetCurrentProgress(newProgress);
    setProgress(updatedProgress);
  };

  const loadProgressStatus = (newProgress) => {
 
        switch(newProgress){
          case 'Pending':
              setCurrentProgress(0);
              const pendingProgress = ["active", "notReached", "notReached"];
              setProgress(pendingProgress);
          break;
          case 'AwaitingTopup':
              setCurrentProgress(1);
              const topupProgress = ["visited", "active", "notReached"];
              setProgress(topupProgress);
          break;
          case 'Recruitment':
              setCurrentProgress(2);
              const recruitmentProgress = ["visited", "visited", "active"];
              setProgress(recruitmentProgress);
          break;
          case 'Shipment':
              setCurrentProgress(3);
              const shipmentProgress = ["visited", "visited", "active"];
              setProgress(shipmentProgress);
          break;
          case 'ContentCreation':
              setCurrentProgress(3);
              const contentProgress = ["visited", "visited", "active"];
              setProgress(contentProgress);
          break;
          case 'DraftReview':
              setCurrentProgress(3);
              const draftProgress = ["visited", "visited", "active"];
              setProgress(draftProgress);
          break;
          case 'Posting':
              setCurrentProgress(3);
              const postingProgress = ["visited", "visited", "active"];
              setProgress(postingProgress);
          break;
          case 'Reporting':
              setCurrentProgress(3);
              const reportingProgress = ["visited", "visited", "active"];
              setProgress(reportingProgress);
          break;
          case 'Close':
              setCurrentProgress(4);
              const closeProgress = ["visited", "visited", "active"];
              setProgress(closeProgress);
        break;
      }

  };

  const loadRecruitmentStatus = (newProgress) => {

    switch(newProgress){
      case 'Pending':
        setRecruitmentMessage("Your campaign is live, our team will assign best match creators to take your job.");
      break;
      case 'Shortlisted':
        setRecruitmentMessage("Creator is shortlisted, awaiting creator accept the job.");
      break;
      case 'Approved':
        setRecruitmentStatus(false);
        setRecruitmentMessage("Creator had accepted your job, follow up and arrange product shipment with the creator.");
      break;
      case 'Rejected':
        setRecruitmentMessage("You had remove creator from shorlist, feel free to recruit another new candidate.");
      break;
  }

};

  useEffect(() => {
    if (campaignData) {
      loadProgressStatus(campaignData?.status);
      loadRecruitmentStatus(campaignData?.recruitmentStatus);

      if(campaignData?.isRequiredBroadcastNotification){
        triggerNotification(campaignData?.id);
      }
    }
  }, [campaignData, campaignLoading]);


  const renderForm = () => {

    if(campaignLoading){
        return(
         <div style={{width:200, height:200}} >
            <Loader/>
         </div>
        )
    }
    else{
        return (
            <>
              {progress[0] === "active" && (
                <>
                   <Panel className={styles.panelWrap}>
                   <PanelHeader
                      title={'Order Summary'}
                   />
                        <CampaignInformation data={campaignData}/>
                   </Panel>
                  
                   {/* <Button type="submit" onClick={() => onOpenCampaign()} disabled={campaignLoading}>
                         Edit order
                   </Button> */}
                   <br/>
                   {!["Paid"].includes(campaignData?.topupStatus) && (
                      <Button type="submit" variant={'warning'} onClick={() => onOpenCampaign()} disabled={campaignLoading}>
                            Delete Order
                      </Button>
                   )}
  
                </>
              )}
              {progress[1] === "active" && (
                <>
                   <Panel className={styles.panelWrap}>
                   <PanelHeader
                      title={'Payment Summary'}
                   />
                       <Invoicing data={campaignData}/>
                   </Panel>
                   {campaignData?.topupStatus === 'Paid' && (
                      <Button type="submit" onClick={() => onDownloadReceipt()} disabled={isDownloadLoading || campaignLoading}>
                            Download Receipt
                      </Button>
                   )}
    
                   {campaignData?.topupStatus === 'Pending' && (
                      <Button type="submit" variant={'tertiary'} onClick={() => onTopup()} disabled={isTopupLoading || campaignLoading}>
                            Top-up
                      </Button>
                   )}
                </>
              )}
              {progress[2] === "active" && (
                <>
                  {/* <Recruitment 
                    campaign={campaignData}
                    refresh={refetch}
                  /> */}
                  <Contents
                   campaign={campaignData}
                   refresh={refetch}
                 />
                </>
              )}
              {progress[3] === "active" && (
                 <Contents
                   campaign={campaignData}
                   refresh={refetch}
                 />
              )}
              {progress[4] === "active" && (
                //<ClosedCampaign/>
                <></>
              )}              
            </>
          );
    }
  };

  const renderNavigation = () => {
    if (progress[0] === "active") {
      return (
        <>
            <div className={styles.footer}>
            {currentProgress > 0 && (
               <Button variant="secondary" size="large" onClick={goToPayment} className={styles.spacing} disabled={progressLoading}>
                   Next
               </Button>
            )}

             {currentProgress == 0 && (
                <>
                    <Button size="large" onClick={() => onUpdateProgress("AwaitingTopup")} className={styles.spacing} disabled={true}>
                        Proceed to top-up
                    </Button>
                </>
             )}
            </div>
            <div className={styles.margin}></div>
            <Text type="p1" color="secondary">
                You can top-up the creator payout once our team had verify the order with you.
            </Text>
        </>
      );
    }
    else if (progress[1] === "active"){
      return (
        <>
          <div className={styles.margin}></div>
           <div className={styles.footer}>
              <Button variant="secondary" size="large" onClick={goToCampaign} className={styles.spacing} disabled={progressLoading}>
                  Back
              </Button>
              {currentProgress > 1 && (
                 <Button variant="secondary" size="large" onClick={goToContent} className={styles.spacing} disabled={progressLoading}>
                  Next
                 </Button>
              )}
           </div>
           <div className={styles.margin}></div>
           <Text type="p1" color="secondary">
              Your campaign will be live once top-up is proceeded
          </Text>
       </>
      );
    }
    else if (progress[2] === "active"){
      return (
        <>
           {/* <div className={styles.footer}>
              <Button variant="secondary" size="large" onClick={goToPayment} className={styles.spacing} disabled={progressLoading}>
                  Back
              </Button>
              {currentProgress > 2 && (
                  <Button variant="secondary" size="large" onClick={goToContent} className={styles.spacing} disabled={progressLoading}>
                     Next
                  </Button>
              )}
              {currentProgress == 2 && (
                   <Button size="large" type="submit" onClick={() => onUpdateProgress("DraftReview")} disabled={AwaitingRecruitment || progressLoading}>
                        Proceed to Content Review
                   </Button>
              )}
           </div>
           <div className={styles.margin}></div>
           <Text type="p1" color="secondary">
              {recruitmentMessage}
          </Text> */}
             <div className={styles.footer}>
              <Button variant="secondary" size="large" onClick={goToPayment} className={styles.spacing} disabled={progressLoading}>
                  Back
              </Button>
              {currentProgress == 3 && (
                   <Button size="large" type="submit" onClick={() => onUpdateProgress("Close")} disabled={progressLoading}>
                         Close the campaign
                   </Button>
              )}

           </div>
           <div className={styles.margin}></div>
           <Text type="p1" color="secondary">
              Await and review creators uploaded video contents.
          </Text>
       </>
      );
    }
    else if (progress[3] === "active"){
      return (
        <>
           <div className={styles.footer}>
              <Button variant="secondary" size="large" onClick={goToRecruitment} className={styles.spacing} disabled={progressLoading}>
                  Back
              </Button>
              {currentProgress == 3 && (
                   <Button size="large" type="submit" onClick={() => onUpdateProgress("Close")} disabled={progressLoading}>
                         Close the campaign
                   </Button>
              )}

           </div>
           <div className={styles.margin}></div>
           <Text type="p1" color="secondary">
              Await and review creators uploaded video contents.
          </Text>
       </>
      );
    }
  };

  return (
    <>
    <div className={styles.container}>
    <Paper className={clsx(styles.stepPaper)}>
          <Text type="h1" color="secondary">
                {!campaignLoading ? '' + campaignData?.name : "Retrieving Data..."}
         </Text>
         <div className={styles.margin}></div>
         <Stepper
              status={progress}
              labels={["Order", "Top-up", "Contents"]}
         />
         <div className={styles.margin}></div>
         <div className={styles.margin}></div>
         {renderNavigation()}
         <div className={styles.margin}></div>
        {renderForm()}     
    </Paper>
    </div>
    {isEditCampaign && (
        <Modal
            isOpen={isEditCampaign}
            onAttemptClose={() => {
              setEditCampaign(false);
            }}
            modalRef={campaignRef}
            Title={CampaignTitle}
        >
          <EditCampaign
             selectedCampaign={campaignData}
             handleClose={onCloseCampaign}
          />
        </Modal>
    )}
    </>
  );
}
