import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import SellerRequestTable from "./components/SellerRequestTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import SellerRequestForm from "./components/SellerRequestForm";
import SellerRejectForm from "./components/SellerRejectForm";
import GridHeader from "components/GridHeader";
import PlanLimitExceedDetails from "./components/PlanLimitExceedDetails";
import ServiceAgreementSettingsForm from "./components/ServiceAgreementSettingsForm";
import {
  useUpdateServiceAgreement
} from "queries/seller";
import { setToast } from 'components/Toast';
import { useGetGetServiceAgreement } from "queries/seller";

const currentPlan = {
  plan: "Premium",
  sellerCount: 30,
};

const SellerRequest = () => {
  const [isSellerReqModalOpen, setSellerReqModalOpen] = useState(false);
  const [isSellerRejectModalOpen, setSellerRejectModalOpen] = useState(false);
  const [isPlanLimitModalOpen, setPlanLimitModalOpen] = useState(false);
  const [isServiceAgreementOpen, setServiceAgreementOpen] = useState(false);

  const history = useHistory();
  const sellerReqModalRef = useRef();
  const sellerRejectModalRef = useRef();
  const planLimitModalRef = useRef();
  const serviceAgreementModalRef = useRef();
  const { mutateAsync: updateServiceAgreement } = useUpdateServiceAgreement();
  const { data: serviceAgreementData, isLoading: isServiceAgreementLoading, isError, refetch } = useGetGetServiceAgreement();

  const [selectedSeller, setSelectedSeller] = useState({});

  const SellerRequestTitle = () => {
    return (
      <div className={styles.sellerReqModalTitleBar}>
        <div>
          <Avatar name={selectedSeller?.sellerName} />
        </div>
        <div className={styles.modalTitle}>
          {selectedSeller?.sellerName + " "} request
        </div>
      </div>
    );
  };

  const SellerRejectTitle = () => {
    return <div>Rejection reason</div>;
  };

  const ServiceAgreementTitle = () => {
    return <div>Service agreement settings</div>;
  };

  const onSellerClick = (sellerRequest) => {
    setSelectedSeller(sellerRequest);
    setSellerReqModalOpen(true);
  };

  const onExport = () => {
    console.log("export clicked");
  };

  const onRejectSeller = () => {
    setSellerReqModalOpen(false);
    setSellerRejectModalOpen(true);
  };

  const onServiceAgreementSubmit = async (data) => {
    try {
      const result = await updateServiceAgreement(data);
      if (result) {
        setToast("Service Agreement updated");
      }
      refetch()
      setServiceAgreementOpen(false);
    } catch (error) {
      setServiceAgreementOpen(false);
    }
  };

  const onServiceAgreementCancel = () => {
    setServiceAgreementOpen(false);
  };

  const onUpgradePlanSubmit = () => {
    setPlanLimitModalOpen(false);
  };
  const onUpgradePlanCancel = () => {
    setPlanLimitModalOpen(false);
  };

  const onServiceAgreementSetting = () => {
    setServiceAgreementOpen(true);
  };

  return (
    <div>
      <GridHeader
        header="Requests"
        renderButtons={() => (
          <>
            {/* --------TODO-----------------
            ----------------No api support for this now. Hide till API support comes */}
            {/* <Button onClick={onPlanLimitExceeded} label="Plan limit exceeded" /> */}
            {/* <Button
              onClick={onServiceAgreementSetting}
              label="Service agreement settings"
            /> */}
            {/* <Button variant="secondary" onClick={onExport} label="Export" /> */}
          </>
        )}
      />
      <Panel>
        <div className={styles.sellerTable}>
          <SellerRequestTable onSellerClick={onSellerClick} />
        </div>
      </Panel>
      {isSellerReqModalOpen && (
        <Modal
          isOpen={isSellerReqModalOpen}
          onAttemptClose={() => {
            history.push("/dashboard/creatorRequest");
            setSellerReqModalOpen(false);
          }}
          modalRef={sellerReqModalRef}
          Title={SellerRequestTitle}
        >
          <SellerRequestForm
            selectedSeller={selectedSeller}
            onReject={onRejectSeller}
            handleClose={() => {
              history.push("/dashboard/creatorRequest");
              setSellerReqModalOpen(false);
            }}
          />
        </Modal>
      )}
      {isSellerRejectModalOpen && (
        <Modal
          isOpen={isSellerRejectModalOpen}
          onAttemptClose={() => {
            history.push("/dashboard/creatorRequest");
            setSellerRejectModalOpen(false);
          }}
          modalRef={sellerRejectModalRef}
          Title={SellerRejectTitle}
        >
          <SellerRejectForm
            selectedSeller={selectedSeller}
            handleClose={() => {
              history.push("/dashboard/creatorRequest");
              setSellerRejectModalOpen(false);
            }}
          />
        </Modal>
      )}
      {isPlanLimitModalOpen && (
        <Modal
          isOpen={isPlanLimitModalOpen}
          onAttemptClose={() => {
            history.push("/dashboard/creatorRequest");
            setPlanLimitModalOpen(false);
          }}
          modalRef={planLimitModalRef}
        >
          <PlanLimitExceedDetails
            currentPlan={currentPlan}
            onUpgradePlan={onUpgradePlanSubmit}
            onUpgradePlanCancel={onUpgradePlanCancel}
          />
        </Modal>
      )}
      {isServiceAgreementOpen && (
        <Modal
          isOpen={isServiceAgreementOpen}
          onAttemptClose={() => {
            history.push("/dashboard/creatorRequest");
            setServiceAgreementOpen(false);
          }}
          modalRef={serviceAgreementModalRef}
          Title={ServiceAgreementTitle}
        >
          <ServiceAgreementSettingsForm
            onServiceAgreementSubmit={onServiceAgreementSubmit}
            onServiceAgreementCancel={onServiceAgreementCancel}
            serviceAgreement={serviceAgreementData}
          />
        </Modal>
      )}
    </div>
  );
};

export default SellerRequest;
