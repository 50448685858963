import { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import Link from "components/SixtyLink";
import Button from "components/SixtyButton";
import Input from "components/SixtyInput";
import UserAuthenticationContainer from "./components/UserAuthenticationContainer";

import { ReactComponent as EyeIcon } from "assets/icons/passwordView.svg";
import { ReactComponent as EyeIconActive } from "assets/icons/passwordViewActive.svg";
import { useSetupUserAuthentication } from "queries/account";
import { reportError } from "crashlytics";
import { RegexExpressions } from "common/constants";
import SixtyText from "components/SixtyText";
import { useHistory } from "react-router-dom";
import ProfileSetupLayout from "routes/layouts/ProfileSetupLayout";

export default function SetupUserAuthentication() {
  const { handleSubmit, control, errors } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const handleShowClick = (e) => setShowPassword((show) => !show);
  const handleShowRepeatClick = (e) => setShowRepeatPassword((show) => !show);

  const {
    mutateAsync: setupUserAuthentication,
    isLoading,
  } = useSetupUserAuthentication();

  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      const result = await setupUserAuthentication({ ...data });
      if (result) {
        history.push("/dashboard");
      }
    } catch (error) {
      reportError("SetupUserAuthentication: onSubmit: error: ", error);
    }
  };

  return (
    <ProfileSetupLayout>
      <UserAuthenticationContainer
        header="Setup your account"
        form={
          <>
            <Controller
              as={Input}
              name="username"
              control={control}
              label="Username"
              rules={{
                pattern: {
                  value: RegexExpressions.username,
                  message: "Invalid username",
                },
                required: { value: true, message: "Username is required" },
              }}
              error={errors.username}
              defaultValue=""
            />
            <Controller
              as={Input}
              name="password"
              control={control}
              rules={{
                required: { value: true, message: "Password is required" },
              }}
              label="Password"
              error={errors.password}
              defaultValue=""
              type={showPassword ? "text" : "password"}
              endIcon={
                showPassword ? (
                  <EyeIconActive onClick={handleShowClick} />
                ) : (
                  <EyeIcon onClick={handleShowClick} />
                )
              }
            />
            <Controller
              as={Input}
              name="repeatPassword"
              control={control}
              rules={{
                required: { value: true, message: "Password is required" },
              }}
              label="Repeat Password"
              error={errors.repeatPassword}
              defaultValue=""
              type={showRepeatPassword ? "text" : "password"}
              endIcon={
                showRepeatPassword ? (
                  <EyeIconActive onClick={handleShowRepeatClick} />
                ) : (
                  <EyeIcon onClick={handleShowRepeatClick} />
                )
              }
            />
          </>
        }
        formFooter={
          <>
            <SixtyText>
              By clicking on Create account, I hereby agree and accept the
              <Link to="/terms" type="p1" color="orange" inline>
                {` Terms of Use `}
              </Link>
              and
              <Link to="/privacy-policy" type="p1" color="orange" inline>
                {` Privacy Policy `}
              </Link>
            </SixtyText>
          </>
        }
        onFormSubmit={handleSubmit(onSubmit)}
        footer={
          <Button type="submit" size="large" fullWidth disabled={isLoading}>
            Next
          </Button>
        }
        footerLink={
          <Link to="/auth/sign-up" type="p1" color="orange">
            Don’t have an account? Sign up now
          </Link>
        }
      />
    </ProfileSetupLayout>
  );
}
