import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import CreditHistoryTable from "../components/CreditHistoryTable";
import PayoutHistoryTable from "../components/PayoutHistoryTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import PayoutDetails from "../components/PayoutDetails";
import CreditHistoryDetails from "../components/CreditHistoryDetails";
import Tabs from "components/Tabs";
import { useGetSellerCreditCount, useGetPayoutRecordCount, useGetSellerCreditBalances } from "queries/admin";

const InfluencerPayoutHistory = () => {

  const history = useHistory();
  const { id } = useParams();

  const [isPayoutDetailOpen, setPayoutDetailOpen] = useState(false);
  const payoutDetailRef = useRef();
  const [selectedPayout, setSelectedPayout] = useState({});

  const [isCreditDetailOpen, setCreditDetailOpen] = useState(false);
  const creditDetailRef = useRef();
  const [selectedCredit, setSelectedCredit] = useState({});

  // const {
  //     data: creditData,
  // } = useGetSellerCreditCount(id);

  const {
    data: balanceData,
  } = useGetSellerCreditBalances(id);

  const {
    data: payoutData,
  } = useGetPayoutRecordCount(id);

  const PayoutDetailTitle = () => {
    return (
      <div className={styles.payoutDetailModalTitleBar}>
        <div className={styles.payoutNameContainer}>
          <div>
            <Avatar name={selectedPayout.sellerName} />
          </div>
          <div className={styles.modalTitle}>{selectedPayout.sellerName}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPayoutDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const CreditDetailTitle = () => {
    return (
      <div className={styles.payoutDetailModalTitleBar}>
        <div className={styles.payoutNameContainer}>
          <div>
            <Avatar name={selectedCredit.sellerName} />
          </div>
          <div className={styles.modalTitle}>{selectedCredit.sellerName}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setCreditDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onPayoutClick = (value) => {
    setSelectedPayout(value);
    setPayoutDetailOpen(true);
  };

  const onCreditClick = (value) => {
    setSelectedCredit(value);
    setCreditDetailOpen(true);
  };

  return (
    <div>
      <GridHeader header="Influencer Credit Histories" 
         renderButtons={() => (
          <>
          </>
         )}
      />

      {balanceData && (
        <div className={styles.leftAlign}>
            <div className={styles.modalTitle}>Credit Balance : RM {balanceData[0]?.credit}</div>
        </div>   
      )}
     
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            // {
            //   tabId: "1",
            //   label: "Credit Histories",
            //   recordsCount: creditData?.creditCount,
            //   TabComponent: CreditHistoryTable,
            //   props: {
            //     code: id,
            //     onRecordClick: onCreditClick
            //   },
            // },
            {
              tabId: "1",
              label: "Payout Histories",
              recordsCount: payoutData?.payoutCount,
              TabComponent: PayoutHistoryTable,
              props: {
                code: id,
                onRecordClick: onPayoutClick
              },
            },
          ]}
        ></Tabs>
      </Panel>
      {isPayoutDetailOpen && (
        <Modal
          isOpen={isPayoutDetailOpen}
          onAttemptClose={() => {
            setPayoutDetailOpen(false);
          }}
          modalRef={payoutDetailRef}
          Title={PayoutDetailTitle}
        >
          <PayoutDetails
            selectedPayout={selectedPayout}
            handleClose={() => setPayoutDetailOpen(false)}
            hideActionBtn={true}
          />
        </Modal>
      )}
      {isCreditDetailOpen && (
        <Modal
          isOpen={isCreditDetailOpen}
          onAttemptClose={() => {
            setCreditDetailOpen(false);
          }}
          modalRef={creditDetailRef}
          Title={CreditDetailTitle}
        >
          <CreditHistoryDetails
            selectedCredit={selectedCredit}
            handleClose={() => setCreditDetailOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default InfluencerPayoutHistory;
