import React, { useEffect, useRef, useState } from "react";
import Tabs from "components/Tabs";
import Panel from "components/SixtyPanel";
import WithdrawRequestTable from "./components/WithdrawRequestTable";
import styles from "./index.module.css";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import WithdrawRequestForm from "./components/WithdrawRequestForm";
import WithdrawRejectForm from "./components/WithdrawRejectForm";
import GridHeader from "components/GridHeader";
import { useGetWithdrawalRequests } from "queries/admin";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

const Withdraws = () => {
  const [isWithDrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [isWithdrawRejectModalOpen, setWithdrawRejectModalOpen] = useState(
    false
  );

  const [pendingCount, setPendingCount] = useState(0);
  const { data: pendingData } = useGetWithdrawalRequests({ status: "Pending" });
  useEffect(() => {
    if (pendingData?.totalRecords) {
      setPendingCount(pendingData.totalRecords);
    }
  }, [pendingData]);

  const [approvedCount, setApprovedCount] = useState(0);
  const { data: approvedData } = useGetWithdrawalRequests({
    status: "Approved",
  });
  useEffect(() => {
    if (approvedData?.totalRecords) {
      setApprovedCount(approvedData.totalRecords);
    }
  }, [approvedData]);

  const [rejectedCount, setRejectedCount] = useState(0);
  const { data: rejectedData } = useGetWithdrawalRequests({
    status: "Rejected",
  });
  useEffect(() => {
    if (rejectedData?.totalRecords) {
      setRejectedCount(rejectedData.totalRecords);
    }
  }, [rejectedData]);


  const history = useHistory();
  const sellerReqModalRef = useRef();
  const sellerRejectModalRef = useRef();

  const [selectedWithdraw, setSelectedWithdraw] = useState({});

  const SellerRequestTitle = () => {
    return (
      <div className={styles.sellerReqModalTitleBar}>
        <div className={styles.payoutNameContainer}>
            <div>
              <Avatar name={selectedWithdraw?.name} />
            </div>
            <div className={styles.modalTitle}>
              {selectedWithdraw?.name + " "} withdraw request
            </div>
        </div>

        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setWithdrawModalOpen(false)}
          />
        </div>
      </div>
    );
  };

  const SellerRejectTitle = () => {
    return <div>Rejection reason</div>;
  };

  const onWithdrawClick = (withdrawRequest) => {
    setSelectedWithdraw(withdrawRequest);
    setWithdrawModalOpen(true);
  };

  const onRejectSeller = () => {
    setWithdrawModalOpen(false);
    setWithdrawRejectModalOpen(true);
  };

  return (
    <div>
      <GridHeader header="Withdraws" />
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Pending",
              recordsCount: pendingCount,
              TabComponent: WithdrawRequestTable,
              props: {
                setCount: setPendingCount,
                status: "Pending",
                onWithdrawClick: onWithdrawClick,
              },
            },
            {
              tabId: "2",
              label: "Approved",
              recordsCount: approvedCount,
              TabComponent: WithdrawRequestTable,
              props: {
                setCount: setApprovedCount,
                status: "Approved",
                onWithdrawClick: onWithdrawClick,
              },
            },
            {
              tabId: "3",
              label: "Rejected",
              recordsCount: rejectedCount,
              TabComponent: WithdrawRequestTable,
              props: {
                setCount: setRejectedCount,
                status: "Rejected",
                onWithdrawClick: onWithdrawClick,
              },
            },
          ]}
        ></Tabs>
      </Panel>
      {isWithDrawModalOpen && (
        <Modal
          isOpen={isWithDrawModalOpen}
          onAttemptClose={() => {
            history.push("/admin/withdraws");
            setWithdrawModalOpen(false);
          }}
          modalRef={sellerReqModalRef}
          Title={SellerRequestTitle}
        >
          <WithdrawRequestForm
            selectedWithdraw={selectedWithdraw}
            onReject={onRejectSeller}
            handleClose={() => {
              history.push("/admin/withdraws");
              setWithdrawModalOpen(false);
            }}
          />
        </Modal>
      )}
      {isWithdrawRejectModalOpen && (
        <Modal
          isOpen={isWithdrawRejectModalOpen}
          onAttemptClose={() => {
            history.push("/admin/withdraws");
            setWithdrawRejectModalOpen(false);
          }}
          modalRef={sellerRejectModalRef}
          Title={SellerRejectTitle}
        >
          <WithdrawRejectForm
            selectedWithdraw={selectedWithdraw}
            handleClose={() => {
              history.push("/admin/withdraws");
              setWithdrawRejectModalOpen(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default Withdraws;
