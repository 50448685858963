import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import CampaignContentTable from "../components/CampaignContentTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import Tabs from "components/Tabs";
import { useGetCampaignStatusCount } from "queries/campaign";

const CampaignContents = () => {

  const history = useHistory();

  const onCampaignClick = (value) => {
    
    const role = localStorage.getItem("userRole");

    if (role === "InternalUser") {
      const path = `/admin/campaigns/contentPublishing/${value?.id}`;
      history.push(path);
    } else {
      const path = `/dashboard/campaigns/contentPublishing/${value?.id}`;
      history.push(path);
    }

  };

  return (
    <div>
      <GridHeader header="Campaign Posting Contents" 
         renderButtons={() => (
          <>
          </>
         )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.campaignTable}>
          <CampaignContentTable onCampaignClick={onCampaignClick} />
        </div>
      </Panel>
    </div>
  );
};

export default CampaignContents;
