import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useApproveRefund } from "queries/order";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Button from "components/SixtyButton";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import RefundRejectForm from "../RefundRejectForm";

const RefundDetails = ({ selectedRefund, handleClose }) => {

  const { 
    id,
    productId,
    itemName,
    itemPath,
    amount,
    refundReason,
    comment,
    status,
    requestDate,
    images
  } = selectedRefund;

  const [isRejectRefund, setRefundModalOpen] = useState(false);
  const [selectedRejectRefund, setSelectedRefund] = useState(null);
  const rejectRefundModalRef = useRef();

  const Title = () => {
    return (
      <>
        <div className={styles.refundDetailModalTitleBar}>
            <div className={styles.refundNameContainer}>
              <div>
                <Avatar name={'Refund Proposal'} />
              </div>
              <div className={styles.modalTitle}>{'Refund Proposal'}</div>
          </div>
           <div>
                <CloseIcon
                  className={styles.closeIcon}
                  onClick={() => setRefundModalOpen(false)}
                />
            </div>
        </div>

      </>
    )
  };

  const viewRejectRefund = () => {
    setSelectedRefund(selectedRefund);
    setRefundModalOpen(true);
  };

  const handleRefundRejectClose = () => {
    setRefundModalOpen(false);
  };

  const { mutateAsync: ApproveRefund, 
          isLoading : refundLoading 
        } = useApproveRefund();

    const onRefundApprove = async () => {
      try {
      
        var result = await ApproveRefund(id);

        if(result){
           setToast("successfully update status");
           handleClose();
        }
       
     } catch (e) {
        reportError(
          "error in : onRefundApprove: onSubmit, error: ",
          e
        );
     }
  };

  return (
      <>
    <React.Fragment>
      <div className={styles.refundRequestFormContainer}>

      <div className={styles.refundRequestForm}>
        <div>
          <Panel className={styles.panelWrap}>
          <div className={styles.subHeader}>
                <span> Evidences </span>
                <React.Fragment>
                    {images?.length > 0 && (
                        <div className={styles.profPhotoContainer}>
                        {images.map((img) => (
                            <div key={img.id} className={styles.profPhotoDropzone}>
                                <img
                                    src={img?.path}
                                    className={clsx(styles.img)}
                                    alt="No preview"
                                />
                            </div>
                        ))}
                        </div>
                    )}
                </React.Fragment>
              </div>
            <PanelHeader
              className={styles.refundInfoHeader}
              title="Refund Proposal"
            />
            <div className={styles.refundInfoContainer}>
              <div className={styles.refundInfoRow}>
                <div className={styles.refundInfoItem}>
                  <div className={styles.refundInfoHeader}>
                    Item
                  </div>
                  <div>
                    {itemName}
                  </div>
                </div>
                <div className={styles.refundInfoItem}>
                  <div className={styles.refundInfoHeader}>Amount</div>
                  <div>
                    {'MYR ' + amount}
                  </div>
                </div>
                <div className={styles.refundInfoItem}>
                  <div className={styles.refundInfoHeader}>Status</div>
                  <div>
                    {status}
                  </div>
                </div>
              </div>
              <div className={styles.refundInfoRow}>
                <div className={styles.refundInfoFullWidthItem}>
                  <div className={styles.refundInfoHeader}>
                    Reason
                  </div>
                  <div>
                    {refundReason}
                  </div>
                </div>
              </div>
              <div className={styles.refundInfoRow}>
                <div className={styles.refundInfoFullWidthItem}>
                  <div className={styles.refundInfoHeader}>
                    Addtional Comments
                  </div>
                  <div>
                    {comment}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
      
        </div>
      </div>

      <footer className={styles.footer}>

          <SixtyButton
            variant= {"tertiary"}
            className={styles.approveBtn}
            onClick={onRefundApprove}
            disabled={refundLoading || ['Approved','Rejected'].includes(status)}
          >
             {'Approve'}
         </SixtyButton> 

         <SixtyButton
            variant= {"secondary"}
            className={styles.approveBtn}
            onClick={viewRejectRefund}
            disabled={refundLoading || ['Approved','Rejected'].includes(status)}
          >
             {'Reject'}
         </SixtyButton> 
          
          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={refundLoading}
          >
            Close
          </SixtyButton>

        </footer>
      </div>
    </React.Fragment>
    {isRejectRefund && (
         <Modal
            isOpen={isRejectRefund}
            onAttemptClose={() => {
              setRefundModalOpen(false);
            }}
            modalRef={rejectRefundModalRef}
            Title={Title}
          >
          <RefundRejectForm
              selectedContent={selectedRejectRefund}
              handleClose={handleRefundRejectClose}
          />
         </Modal>
    )}
    </>
  );
};

export default RefundDetails;
