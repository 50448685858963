import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import PostingTable from "../components/PostingTable";
import ContentReportDetails from "../../components/ContentReportDetails";
import ReportingDetail from "../../components/ReportingDetails";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import BasicPageHeader from "../../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetCampaignPublishContentRequestCount } from "queries/posting";

const Reporting = () => {
  const [isPostOpen, setPostOpen] = useState(false);
  const history = useHistory();
  const params = useParams();
  const postRef = useRef();
  const [selectedPost, setSelectedPost] = useState({});

  const {
    data: requestData,
  } = useGetCampaignPublishContentRequestCount(params.id);

  const PostTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedPost.title} />
          </div>
          <div className={styles.modalTitle}>{selectedPost.title}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPostOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onContentClick = (value) => {
    setSelectedPost(value);
    setPostOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <BasicPageHeader title="Reporting" onGoBack={onGoBack} disableBack={true} />
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[ 
            {
              tabId: "1",
              label: "Completed",
              recordsCount: requestData?.completedContentCount,
              TabComponent: PostingTable,
              props: {
                status: "Approved",
                campaignId: params.id,
                onContentClick: onContentClick
              },           
            },
          ]}
        ></Tabs>
      </Panel>
      {isPostOpen && (
        <Modal
          isOpen={isPostOpen}
          onAttemptClose={() => {
            setPostOpen(false);
          }}
          modalRef={postRef}
          Title={PostTitle}
        >
          <ReportingDetail
            selectedPost={selectedPost}
            handleClose={() => setPostOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Reporting;
