import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import { useHistory } from "react-router-dom";
import GridHeader from "components/GridHeader";
import SellerApprovalTable from "./components/ApprovalTable";
import Tabs from "components/Tabs";
import { useGetSellerStatusCount } from "queries/admin";


const SellerApproval = () => {

  const history = useHistory();

  const {
    data: sellerData,
  } = useGetSellerStatusCount();


  const onSellerClick = (value) => {
    const path = `/admin/influencers/AnalyticDetails/${value}`;
    window.open(path, "_blank")
  };

  return (
    <div>
      <GridHeader header="Influencers" />
      {/* <Panel className={styles.panelWrap}>
        <div className={styles.sellerTable}>
          <SellerApprovalTable onSellerClick={onSellerClick} />
        </div>
      </Panel> */}
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Active",
              recordsCount: sellerData?.activeSellerCount,
              TabComponent: SellerApprovalTable,
              props: {
                status: "Active",
                onSellerClick: onSellerClick
              },
            },
            {
              tabId: "2",
              label: "Suspended",
              recordsCount: sellerData?.suspendedSellerCount,
              TabComponent: SellerApprovalTable,
              props: {
                status: "Suspended",
                onSellerClick: onSellerClick
              },
            }
          ]}
        ></Tabs>
      </Panel>
    </div>
  );
};

export default SellerApproval;
