import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from 'components/Toast';

export function useGetPublicCompanyInvitation() {
  return useQuery(
    Endpoints.Invitation.GetPublicCompanyInvitation,
    () =>
      api
        .get(Endpoints.Invitation.GetPublicCompanyInvitation)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError(
          "useGetPublicCompanyInvitation: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useUpdatePublicCompanyInvitation() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Invitation.UpdatePublicCompanyInvitation, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdatePublicCompanyInvitation: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGenerateInvitation() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Invitation.GeneratePublicInvitation, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGenerateInvitation: useQuery: onError: error", error);
      },
    }
  );
}

export function useInviteSellerByEmail() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Invitation.InviteSellerByEmail, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useInviteSellerByEmail: useQuery: onError: error", error);
      },
    }
  );
}

export function useResendSellerInvitationByEmail() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Invitation.ResendSellerInvitationByEmail, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useResendSellerInvitationByEmail: useQuery: onError: error",
          error
        );
      },
    }
  );
}
