import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useImmer } from "utils/hooks/useImmer";
import { useHistory } from "react-router-dom";
import { useGetPayouts } from "queries/admin";
import { useGetSellerWallets } from "queries/admin";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import useRowSelectionTable from "utils/hooks/useRowSelectionTable";

import SixtyText from "components/SixtyText";
import Avatar from "components/Avatar";
import SixtyButton from "components/SixtyButton";
import Table, {
  RowSelectionContainer,
  TableHeader,
} from "components/SixtyTable";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import EmptyGrid from "components/EmptyGrid";

import { ReactComponent as SellerIcon } from "assets/icons/Icon-Sellers.svg";
import styles from "./index.module.css";
import ErrorModal from "components/Error";
import Loader from "components/Loader";
import { PanelActionButton } from "components/SixtyPanel";
import { format } from "date-fns";
import { unknown_Error } from "common/constants";
import { getFormattedDateString } from "utils/date.util";

export default function PayoutWalletTable({onRecordClick}) {
  const history = useHistory();

  const [search, setSearch] = useState("");
  const handleSearch = (value) => setSearch(value);


  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    return {
      keyword: search,
      startFrom,
      limit,
    };
  }, [search, startFrom]);

  const {
    data: rewardData,
    isError,
    isLoading,
    error,
    refetch,
  } = useGetSellerWallets(getFilters());

  const onRowClick = (row, e) => {
    onRecordClick(row.original);
  };

  const columns = useMemo(
    () => [
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ value, row }) => (
              <div className={styles.sellerNameCell}>
                <div>
                  <Avatar name={value} />
                </div>
                <div className={styles.sellerNameValue}>{value}</div>
              </div>
            ),
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "Code",
            accessor: "code",
          },
          {
            Header: "Balance",
            accessor: "balance",
          },
          {
            Header: "Last Update On",
            accessor: "lastUpdateOn",
            Cell: ({ value }) => getFormattedDateString(value, "MMM d, y"),
          },
    ],
    []
  );

  let data = useMemo(() => (rewardData?.result ? rewardData.result : []), [
    rewardData,
  ]);

  const tableInstance = useRowSelectionTable({
    data,
    columns,
  });

  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (!search && rewardData?.result?.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      {(!Array.isArray(rewardData?.result) || isDataEmpty()) && !isLoading && (
        <>
          <EmptyGrid
            Icon={SellerIcon}
            emptyMessage={
              isError
                ? "Error in fetching data"
                : "Your wallets data will show up here"
            }
          />
          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}
      {!isError && !isDataEmpty() && (
        <>
          {tableInstance.selectedFlatRows.length > 0 ? (
            <RowSelectionContainer
              renderRowSelectionText={() => (
                <>
                  <SixtyText>
                    {tableInstance.selectedFlatRows.length} records selected
                  </SixtyText>
                  <SixtyButton
                    className={styles.unselectButton}
                    variant="text"
                    onClick={() => tableInstance.toggleAllRowsSelected(false)}
                  >
                    unselect all
                  </SixtyButton>
                </>
              )}
              renderRowSelectionButtons={() => (
                <>
                </>
              )}
            />
          ) : (
            <>
              <TableHeader>
                <SearchFilter
                  search={search}
                  onSearchChange={handleSearch}
                  placeholder="Search for user wallets"
                />
              </TableHeader>
            </>
          )}
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(rewardData?.result) && (
              <Table
                instance={tableInstance}
                totalItems={rewardData?.totalRecords}
                startFrom={startFrom}
                limit={limit}
                setStart={setStart}
                onRowClicked={onRowClick}
              />
            )
          )}
        </>
      )}
    </>
  );
}
