import React, { useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import SellerPageHeader from "./components/SellerPageHeader";
import PanelHeader from "components/PanelHeader";
import clsx from "clsx";
import EarningHistory from "./components/EarningHistory";
import SellerInfo from "./components/SellerInfo";
import SellerActivities from "./components/SellerActivities";
import LatestOrders from "./components/LatestOrders";
import ProductSales from "./components/ProductSales";
import { ReactComponent as IconArrowRight } from "assets/icons/Icon-Arrow-Right.svg";
import Modal from "components/Modal";
import EditSellerNameForm from "./components/EditSellerNameForm";
import DeactivateSellerName from "./components/DeactivateSellerName";
import { useGetSellerDetail, useUpdateSellerStatus } from "queries/seller";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import SubscriptionPermission from "../../Permissions/SubscriptionPermissions"; 
import Restricted from "../../../permissions/Restrictions";

const SellerDetailsPage = () => {
  const [editSellerModalOpen, setEditSellerModalOpen] = useState(false);
  const [deactivateSellerModalOpen, setDeactivateSellerModalOpen] = useState(
    false
  );
  const editSellerRef = useRef();

  const history = useHistory();
  const params = useParams();

  const { data: sellerInfo, isLoading, isError, refetch } = useGetSellerDetail(
    params.id
  );

  const { mutateAsync: updateSellerStatus } = useUpdateSellerStatus();

  const onGoBack = () => {
    history.goBack();
  };

  const onDeactivate = () => {
    setDeactivateSellerModalOpen(true);
  };

  const onEdit = () => {
    setEditSellerModalOpen(true);
  };

  const onDeactivateConfirm = async (id) => {
    try {
      const action = ["Approved", "Activated"].includes(sellerInfo?.status)
        ? "Suspend"
        : ["Suspended", "Rejected"].includes(sellerInfo?.status)
        ? "Reactivate"
        : null;
      const result = await updateSellerStatus({
        id: params.id,
        action,
        reason: null,
      });
      if (result) {
        setToast(
          `Successfully ${
            action === "Suspend" ? "deactivated" : "reactivated"
          } creator`
        );
        setDeactivateSellerModalOpen(false);
        onGoBack();
      }
    } catch (error) {
      reportError("updateSellerStatus: onDeactivate: Error: ", error);
      setToast("Error while deactivating seller", "error");
      setDeactivateSellerModalOpen(false);
    }
  };

  const onDeactivateCancel = () => {
    setDeactivateSellerModalOpen(false);
  };

  const handleEditSellerClose = () => {
    setEditSellerModalOpen(false);
    refetch();
  };

  const Title = () => {
    return (
      <div>
        <div className={styles.modalTitle}>Edit creator details</div>
      </div>
    );
  };

  return (
    <div className={styles.sellerDetailsPage}>
      <SellerPageHeader
        title={sellerInfo?.name}
        onGoBack={onGoBack}
        onEdit={onEdit}
        onDeactivate={onDeactivate}
        status={sellerInfo?.status}
      />
      <div className={styles.row}>
        <div className={styles.sellerDetailsLeftContainer}>
          <Panel className={clsx(styles.panelWrap, styles.firstPanel)}>
            <PanelHeader title="Earning history" />
            <Restricted 
              children={(
                <EarningHistory data={sellerInfo} />
              )}
              action={'affiliate.recruitment.view'}
              fallback={(<SubscriptionPermission />)}
            />
            
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader title="Product sales" />
              <Restricted 
                  children={(
                    <ProductSales sellerId={params?.id} />
                  )}
                  action={'affiliate.recruitment.view'}
                  fallback={(<SubscriptionPermission fillContainer={true} />)}
              />
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader title="Latest orders">
              <div
                className={styles.allOrdersBtn}
                onClick={() => history.push(`/dashboard/orders`)}
              >
                <span>See all orders</span>
                <span className={styles.allOrdersArrow}>
                  <IconArrowRight />
                </span>
              </div>
            </PanelHeader>
            {sellerInfo?.id && <LatestOrders sellerId={sellerInfo?.id} />}
          </Panel>
        </div>
        <div className={styles.sellerDetailsRightContainer}>
          {sellerInfo && (
            <React.Fragment>
              <Panel
                className={[styles.panelWrap, styles.sellerInfoPanel].join(" ")}
              >
                <PanelHeader title="Creator info" />
                <Restricted 
                  children={(
                    <SellerInfo sellerInfo={sellerInfo} />
                  )}
                  action={'affiliate.recruitment.view'}
                  fallback={(<SubscriptionPermission fillContainer={true} />)}
                />
              </Panel>
              <Panel
                className={[styles.panelWrap, styles.sellerInfoPanel].join(" ")}
              >
                <PanelHeader title="Creator latest activities" />
                <SellerActivities activities={sellerInfo?.activities} />
              </Panel>
            </React.Fragment>
          )}
        </div>
      </div>
      {editSellerModalOpen && (
        <Modal
          isOpen={editSellerModalOpen}
          onAttemptClose={() => {
            history.push(`/dashboard/creators/${params.id}`);
            setEditSellerModalOpen(false);
          }}
          modalRef={editSellerRef}
          Title={Title}
        >
          <EditSellerNameForm
            sellerId={sellerInfo?.id}
            handleClose={handleEditSellerClose}
          />
        </Modal>
      )}
      {deactivateSellerModalOpen && (
        <Modal
          isOpen={deactivateSellerModalOpen}
          onAttemptClose={() => {
            history.push(`/dashboard/creators/${params.id}`);
            setDeactivateSellerModalOpen(false);
          }}
          modalRef={editSellerRef}
        >
          <DeactivateSellerName
            onDeactivateConfirm={onDeactivateConfirm}
            onDeactivateCancel={onDeactivateCancel}
            data={sellerInfo}
          />
        </Modal>
      )}
    </div>
  );
};

export default SellerDetailsPage;
