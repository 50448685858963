import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./index.module.css";
import Table, { TableHeader } from "components/SixtyTable";
import ErrorModal from "components/Error";
import Loader from "components/Loader";
import EmptyGrid from "components/EmptyGrid";
import { PanelActionButton } from "components/SixtyPanel";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import Sort from "components/SixtyTable/components/Sort";
import { useTable } from "react-table";
import { useGetSellerVendorTraffic } from "queries/admin";
import { unknown_Error } from "common/constants";
import { ReactComponent as ProductIcon } from "assets/icons/Icon-Products.svg";
import { formatCurrency } from "utils/common";
import { ReactComponent as ImageIcon } from "assets/icons/Icon-Image.svg";

const BrandTraffic = ({ code }) => {

  const history = useHistory();

  const [sort, setSort] = useState("orderByAscending");
  const [search, setSearch] = useState("");

  const handleSortChange = useCallback(
    (field) => {
      setSort(field);
    },
    [setSort]
  );

  const handleSearch = (val) => setSearch(val);

  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    return {
      [sort]: true,
      keyword: search,
      startFrom,
      limit,
    };
  }, [search, sort, startFrom]);

  const {
    data: brandData,
    isLoading,
    isError,
    refetch,
    error,
  } = useGetSellerVendorTraffic(code);

  const columns = useMemo(
    () => [
      {
        Header: "Brand name",
        accessor: "brandName",
        Cell: ({ value, row }) => (
          <div className={styles.productItemContainer}>
            {row?.original?.imagePath ? (
              <div className={styles.productImg}>
                {" "}
                <img
                  className={styles.productImage}
                  src={row?.original?.imagePath}
                  alt="brand"
                />{" "}
              </div>
            ) : (
              <div className={styles.imageIconBox}>
                <ImageIcon />
              </div>
            )}
            <div className={styles.productNameContainer}>
              <div
                title={value}
                className={[styles.productName, styles.elipsis].join(" ")}
              >
                {value}
              </div>
              <div className={[styles.brandName, styles.elipsis].join(" ")}>
                {row.original?.brandName || "brandName"}
              </div>
            </div>
          </div>
        ),
        style: {
          paddingLeft: 16,
        },
      },
      {
        Header: "Traffic",
        accessor: "traffic",
      },
      {
        Header: "Add to cart",
        accessor: "addToCart",
      },
      {
        Header: "Checkout",
        accessor: "checkout",
      },
    ],
    []
  );

  let data = useMemo(
    () => (brandData?.result ? brandData.result : []),
    [brandData]
  );

  const tableInstance = useTable({
    data,
    columns,
  });

  // Error modal
  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (!search && brandData?.result?.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      {(!Array.isArray(brandData?.result) || isDataEmpty()) &&
        !isLoading && (
          <>
            <EmptyGrid
              Icon={ProductIcon}
              emptyMessage={
                isError
                  ? "Error in fetching data"
                  : "The brands will show up here"
              }
            />

            {isError && (
              <PanelActionButton onClick={() => refetch()}>
                Try Again
              </PanelActionButton>
            )}
          </>
        )}

      {!isError && !isDataEmpty() && (
        <>
          <TableHeader>
          </TableHeader>

          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(brandData?.result) && (
              <Table
                instance={tableInstance}
              />
            )
          )}
        </>
      )}
    </>
  );
};

export default BrandTraffic;
