import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import CampaignRecruitmentTable from "../components/CampaignRecruitmentTable";
import InfluencerRequestTable from "../components/InfluencerRequestTable";
import InfluencerRequestDetails from "../components/InfluencerRequestDetails";
import styles from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import BasicPageHeader from "../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetCampaignSellerRequestCount, useGetCampaignAnalytics } from "queries/campaign";

const CampaignRecruitment = () => {
  const [isSellerDetailOpen, setSellerDetailOpen] = useState(false);
  const history = useHistory();
  const params = useParams();
  const sellerDetailRef = useRef();
  const [selectedSeller, setSelectedSeller] = useState({});
  const role = localStorage.getItem("userRole");

  const { data: campaignData, isLoading: campaignLoading } = useGetCampaignAnalytics(params.id);

  const {
    data: requestData,
  } = useGetCampaignSellerRequestCount(params.id);

  const SellerDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedSeller.name} />
          </div>
          <div className={styles.modalTitle}>{selectedSeller.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setSellerDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onInfluencerClick = (value) => {
    setSelectedSeller(value);
    setSellerDetailOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <BasicPageHeader title="Influencer Recruitment" onGoBack={onGoBack} />

      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Pending Approval",
              recordsCount: requestData?.pendingRequestCount,
              TabComponent: InfluencerRequestTable,
              props: {
                status: "Pending",
                onInfluencerClick: onInfluencerClick
              },
            },
            {
              tabId: "2",
              label: "Shortlisted Influencers",
              recordsCount: requestData?.shortlistRequestCount,
              TabComponent: InfluencerRequestTable,
              props: {
                status: "Shortlisted",
                onInfluencerClick: onInfluencerClick
              },
            },
            {
              tabId: "3",
              label: "Rejected Influencers",
              recordsCount: requestData?.rejectedRequestCount,
              TabComponent: InfluencerRequestTable,
              props: {
                status: "Rejected",
                onInfluencerClick: onInfluencerClick
              },
            },
            {
              tabId: "4",
              label: "Joined Influencers",
              recordsCount: requestData?.approvedRequestCount,
              TabComponent: InfluencerRequestTable,
              props: {
                status: "Approved",
                onInfluencerClick: onInfluencerClick
              },
            },
          ]}
        ></Tabs>
      </Panel>
      {isSellerDetailOpen && (
        <Modal
          isOpen={isSellerDetailOpen}
          onAttemptClose={() => {
            setSellerDetailOpen(false);
          }}
          modalRef={sellerDetailRef}
          Title={SellerDetailTitle}
        >
          <InfluencerRequestDetails
            selectedSeller={selectedSeller}
            handleClose={() => setSellerDetailOpen(false)}
            viewOnly={campaignData?.campaignType == 'AffiliateEvent' && role == 'Supplier'}
          />
        </Modal>
      )}
    </div>
  );
};

export default CampaignRecruitment;
