import React, { useState } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import {
  useGetWithdrawalRequestDetail,
  useUpdateWithdrawStatus,
} from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

const WithdrawRequestForm = ({ selectedWithdraw, handleClose, onReject }) => {
  const { 
    mutateAsync: updateWithdrawStatus, 
    isLoading: statusLoading 
  } = useUpdateWithdrawStatus();

  const {
    data: withdrawRequestInfo,
    isLoading,
  } = useGetWithdrawalRequestDetail(selectedWithdraw?.id);

  const onApproveWithdraw = async () => {
    const { id } = selectedWithdraw;
    try {
      const result = await updateWithdrawStatus({
        id,
        status: "Approved",
        reason: "",
        message: "",
      });
      if (result) {
        handleClose();
        setToast("successfully approved seller");
      }
    } catch (e) {
      reportError("error in : SellerRequestForm: onApproveSeller, error: ", e);
    }
  };

  const onBrowseHistoryClick = () => {
    const path = `/admin/influencer/histories/${withdrawRequestInfo?.requestByCode}`;
    window.open(path, "_blank")
 };

  const requestedDate = getFormattedDateString(
    withdrawRequestInfo?.requestedDate || selectedWithdraw?.requestedDate,
    "MMM d, y"
  );

  return (
    <React.Fragment>
      <div className={styles.sellerRequestFormContainer}>
        <div className={styles.sellerRequestForm}>
          <div>
            <Panel className={styles.panelWrap}>
              <PanelHeader
                className={styles.infoHeader}
                title="Withdraw information"
              />
              {isLoading ? (
                <div style={{width:800, height:500}}>
                  <Loader />
                </div>
              ) : (
                <div className={styles.infoContainer}>

                 {withdrawRequestInfo?.accountType == 'Seller' && (
                  <div className={styles.btnActionContainer}>
                        <SixtyButton
                          variant="tertiary"
                          onClick={() => onBrowseHistoryClick()}
                          className={styles.approveBtn}
                        >
                          {"Browse influencer credit histories"}
                        </SixtyButton>
                  </div>
                 )}

                  <div className={styles.infoRow}>
                    <div className={clsx(styles.infoItem, styles.elipsis)}>
                      <div className={styles.infoHeader}>Withdraw amount</div>
                      <div
                        className={styles.elipsis}
                        title={
                          withdrawRequestInfo?.withdrawAmount ||
                          selectedWithdraw?.withdrawAmount
                        }
                      >
                        {withdrawRequestInfo?.withdrawAmount ||
                          selectedWithdraw?.withdrawAmount}
                      </div>
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.infoHeader}>Account type</div>
                      <div
                        className={styles.elipsis}
                        title={
                          withdrawRequestInfo?.accountType ||
                          selectedWithdraw?.accountType
                        }
                      >
                        {withdrawRequestInfo?.accountType ||
                          selectedWithdraw?.accountType}
                      </div>
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.infoHeader}>Requested date</div>
                      <div className={styles.elipsis} title={requestedDate}>
                        {requestedDate}
                      </div>
                    </div>
                  </div>

                  <div className={styles.infoRow}>
                    <div className={styles.infoItem}>
                      <div className={styles.infoHeader}>Current balance</div>
                      <div>
                        {withdrawRequestInfo?.currentBalance ||
                          selectedWithdraw?.currentBalance}
                      </div>
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.infoHeader}>On hold amount</div>
                      <div>
                        {withdrawRequestInfo?.onHoldBalance ||
                          selectedWithdraw?.onHoldBalance}
                      </div>
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.infoHeader}>Final balance</div>
                      <div>
                        {withdrawRequestInfo?.finalBalance ||
                          selectedWithdraw?.finalBalance}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Panel>

            <Panel className={styles.panelWrap}>
              <PanelHeader className={styles.infoHeader} title="Bank details" />
              {isLoading ? (
                <Loader />
              ) : (
                <div className={styles.infoContainer}>
                  <div className={styles.infoRow}>
                    <div className={clsx(styles.infoItem)}>
                      <div className={styles.infoHeader}>Full name</div>
                      <div>
                        {withdrawRequestInfo?.bankOwnerName ||
                          selectedWithdraw?.bankOwnerName}
                      </div>
                    </div>
                    <div className={clsx(styles.infoItem)}>
                      <div className={styles.infoHeader}>Account number</div>
                      <div>
                        {withdrawRequestInfo?.bankAccount ||
                          selectedWithdraw?.bankAccount}
                      </div>
                    </div>
                    <div className={clsx(styles.infoItem)}>
                      <div className={styles.infoHeader}>Bank name</div>
                      <div>
                        {withdrawRequestInfo?.bankName ||
                          selectedWithdraw?.bankName}
                      </div>
                    </div>
                  </div>
                  <div className={styles.infoRow}>
                    <div className={clsx(styles.infoItem)}>
                      <div className={styles.infoHeader}>IC Number</div>
                      <div>
                        {withdrawRequestInfo?.icNumber ||
                          selectedWithdraw?.icNumber}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Panel>
          </div>
        </div>
        <footer className={styles.footer}>
          <SixtyButton
            type="submit"
            onClick={onApproveWithdraw}
            className={styles.approveBtn}
            disabled={statusLoading}
          >
            Approve
          </SixtyButton>
          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={onReject}
            disabled={statusLoading}
          >
            Reject
          </SixtyButton>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default WithdrawRequestForm;
