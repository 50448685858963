import styles from "./index.module.css";
import clsx from "clsx";
import SixtyButton from "components/SixtyButton";

export default function Panel({ className, children, ...rest }) {
  return (
    <div className={clsx(styles.panel, className)} {...rest}>
      {children}
    </div>
  );
}

export function PanelActionButton(props) {
  return (
    <div className={styles.actionButton}>
      <SixtyButton variant="secondary"{...props}/>
    </div>
  );
}
