import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useGetProducts } from "queries/product";

import Table, { TableHeader } from "components/SixtyTable";
import EmptyGrid from "components/EmptyGrid";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import Sort from "components/SixtyTable/components/Sort";
import ErrorModal from "components/Error";
import Loader from "components/Loader";
import { PanelActionButton } from "components/SixtyPanel";
import { SortItem } from "components/SixtyTable/components/Sort";

import { ReactComponent as ProductIcon } from "assets/icons/Icon-Products.svg";
import styles from "./index.module.css";
import { useParams } from "react-router-dom";
import { usePagination, useTable } from "react-table";
import { ReactComponent as ProductDummyIcon } from "assets/icons/productSample.svg";
import { unknown_Error } from "common/constants";
import { formatCurrency } from "utils/common";
//todo: integrate images and brand name in first cell

const ProductByCategoryTable = () => {
  const [search, setSearch] = useState("");
  const handleSearch = (val) => setSearch(val);
  const { id } = useParams();

  const [sort, setSort] = useState("orderByAscending");

  const handleSortChange = useCallback(
    (field) => {
      setSort(field);
    },
    [setSort]
  );

  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    return {
      [sort]: true,
      filterByCategoryId: id,
      keyword: search,
      startFrom,
      limit,
    };
  }, [search, sort, startFrom, id]);

  const {
    data: productData,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetProducts(getFilters());

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        style: { padding: "0px 16px" },
        collapse: true,
        Cell: ({ value, row }) => (
          <div className={styles.productNameCell}>
            <div>
              <img className={styles.productImage} src={row.original.imagePath} alt="product" />
            </div>
            <div className={styles.nameCellContent}>
              <div className={styles.productNameText}>{value}</div>

              {/* <div className={styles.brandName}>BrandName</div> */}
            </div>
          </div>
        ),
      },
      {
        Header: "Cost price",
        accessor: "costPrice",
        collapse: true,
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Status",
        accessor: "isActivated",
        collapse: true,
        Cell: ({ value, row }) => <div>{value ? "Active" : "Archived"}</div>,
      },
    ],
    []
  );

  let data = useMemo(() => (productData?.result ? productData.result : []), [
    productData,
  ]);

  const tableInstance = useTable({ columns, data }, usePagination);

  // Error modal
  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (!search && productData?.result?.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      {(!Array.isArray(productData?.result) || isDataEmpty()) && !isLoading && (
        <>
          <EmptyGrid
            Icon={ProductIcon}
            emptyMessage={
              isError ? "Error in fetching data" : "Products will show up here"
            }
          />

          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}

      {!isError && !isDataEmpty() && (
        <>
          <>
            <TableHeader>
              <SearchFilter
                search={search}
                onSearchChange={handleSearch}
                placeholder="Search for products"
              />
              <Sort
                render={ProductSort}
                state={sort}
                handleSortChange={handleSortChange}
              />
            </TableHeader>
          </>
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(productData?.result) && (
              <Table
                instance={tableInstance}
                totalItems={productData?.totalRecords}
                startFrom={startFrom}
                limit={limit}
                setStart={setStart}
              />
            )
          )}
        </>
      )}
    </>
  );
};

export default ProductByCategoryTable;

function ProductSort({ state, handleSortChange }) {
  return (
    <>
      <SortItem
        label="Product name (Ascending)"
        name="orderByProductNameAscending"
        checked={state === "orderByProductNameAscending"}
        onChange={() => handleSortChange("orderByProductNameAscending")}
      />
      <SortItem
        label="Product name (Descending)"
        name="orderByProductNameDescending"
        checked={state === "orderByProductNameDescending"}
        onChange={() => handleSortChange("orderByProductNameDescending")}
      />
      <SortItem
        label="Price (Ascending)"
        name="orderByPriceAscending"
        checked={state === "orderByPriceAscending"}
        onChange={() => handleSortChange("orderByPriceAscending")}
      />
      <SortItem
        label="Price (Descending)"
        name="orderByPriceDescending"
        checked={state === "orderByPriceDescending"}
        onChange={() => handleSortChange("orderByPriceDescending")}
      />
    </>
  );
}
