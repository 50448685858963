import React from "react";
import styles from "./index.module.css";
import { useFormContext } from "react-hook-form";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import {
  useGetStateDropdown,
  useGetCountryDropdown
} from "queries/vendor";

const BusinessAddressForm = () => {
  const { control, errors, setValue } = useFormContext();

  const { data: countryData } = useGetCountryDropdown();
  const countryOptions = countryData?.length
    ? 
      countryData.map((option) => ({
        label: option.name,
        value: option.key,
      }))
      
    : [];

  const { data: stateData } = useGetStateDropdown();
  const stateOptions = stateData?.length
    ? stateData.map((option) => ({
        label: option.name,
        value: option.key,
      }))
    : [];

  return (
    <React.Fragment>
      <div>
        <div className={styles.formRow}>
          <Input
            name="companyName"
            control={control}
            rules={{ required: "required" }}
            label="Legal company name"
            defaultValue=""
            error={errors.companyName}
          />
         <Select
            name="country"
            label="Country"
            options={countryOptions}
            control={control}                     
            fullWidth={true}
            defaultOption={control.getValues("country")}
            defaultValue={control.getValues("country")}
            handleChange={(name, value) => setValue("country", value)}
            error={errors?.country}
          />
        </div>
      </div>
      <div className={styles.formRow}>
        <Input
          name="street1"
          control={control}
          label="Street address 1"
          defaultValue=""
        />
        <Input
          name="street2"
          control={control}
          label="Street address 2"
          defaultValue=""
        />
      </div>
      <div className={styles.formRow}>
        <Input
          name="city"
          control={control}
          label="City"
          defaultValue=""
        />
        <div className={styles.postalCode}>
          <Input
            name="postcode"
            control={control}
            label="Zip / Postal code"
            defaultValue=""
          />
        </div>
        <Select
          name="state"
          label="State / Province / Region"
          options={stateOptions}
          control={control}
          fullWidth={true}
          defaultOption={control.getValues("state")}
          defaultValue={control.getValues("state")}
          handleChange={(name, value) => setValue("state", value)}
          error={errors?.state}
        />
      </div>
    </React.Fragment>
  );
};

export default BusinessAddressForm;
