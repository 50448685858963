import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useImmer } from "utils/hooks/useImmer";
import { useHistory } from "react-router-dom";
import { useGetBlogs } from "queries/blog";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import useRowSelectionTable from "utils/hooks/useRowSelectionTable";

import SixtyText from "components/SixtyText";
import Avatar from "components/Avatar";
import SixtyButton from "components/SixtyButton";
import Table, {
  RowSelectionContainer,
  TableHeader,
} from "components/SixtyTable";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import EmptyGrid from "components/EmptyGrid";

import { ReactComponent as SellerIcon } from "assets/icons/Icon-Sellers.svg";
import styles from "./index.module.css";
import ErrorModal from "components/Error";
import Loader from "components/Loader";
import { PanelActionButton } from "components/SixtyPanel";
import { format } from "date-fns";
import { unknown_Error } from "common/constants";
import { getFormattedDateString } from "utils/date.util";

export default function BlogTable({ status, onContentClick }) {
  const history = useHistory();

  const [search, setSearch] = useState("");
  const handleSearch = (value) => setSearch(value);

  const [filterState, setFilterState] = useImmer({});
  const [sort, setSort] = useState("orderByDateJoinedDescending");

  const onFilterChange = (filterVal) => {
    setFilterState(() => filterVal);
  };
 
  const handleSortChange = useCallback(
    (field) => {
      setSort(field);
    },
    [setSort]
  );

  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    return {
      [sort]: true,
      keyword: search,
      startFrom,
      limit,
      status: status
    };
  }, [filterState, search, sort, startFrom]);

  const {
    data: blogData,
    isError,
    isLoading,
    error,
    refetch,
  } = useGetBlogs(getFilters());

  const onRowClick = (row, e) => {
    onContentClick(row.original);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Visits",
        accessor: "visitors",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created On",
        accessor: "createdDate",
      },
    ],
    []
  );

  let data = useMemo(() => (blogData?.result ? blogData.result : []), [
    blogData,
  ]);

  const tableInstance = useRowSelectionTable({
    data,
    columns,
  });

  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (_isEmpty(filterState) && !search && blogData?.result?.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      {(!Array.isArray(blogData?.result) || isDataEmpty()) && !isLoading && (
        <>
          <EmptyGrid
            Icon={SellerIcon}
            emptyMessage={
              isError
                ? "Error in fetching data"
                : "Your blogs will show up here"
            }
          />
          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}
      {!isError && !isDataEmpty() && (
        <>
          {tableInstance.selectedFlatRows.length > 0 ? (
            <RowSelectionContainer
              renderRowSelectionText={() => (
                <>
                  <SixtyText>
                    {tableInstance.selectedFlatRows.length} campaigns selected
                  </SixtyText>
                  <SixtyButton
                    className={styles.unselectButton}
                    variant="text"
                    onClick={() => tableInstance.toggleAllRowsSelected(false)}
                  >
                    unselect all
                  </SixtyButton>
                </>
              )}
              renderRowSelectionButtons={() => (
                <>
                </>
              )}
            />
          ) : (
            <>
              <TableHeader>
                <SearchFilter
                  search={search}
                  onSearchChange={handleSearch}
                  placeholder="Search for blogs with title"
                />
              </TableHeader>
            </>
          )}
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(blogData?.result) && (
              <Table
                instance={tableInstance}
                totalItems={blogData?.totalRecords}
                startFrom={startFrom}
                limit={limit}
                setStart={setStart}
                onRowClicked={onRowClick}
              />
            )
          )}
        </>
      )}
    </>
  );
}
