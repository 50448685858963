
import RadioField from "components/RadioField";
import {
  FilterGroup,
  FilterItem,
  FilterChipContainer,
  ChipItem,
  FilterRow,
} from "components/SixtyTable/components/Filter";

import _omit from "lodash/omit";

// for rendering chips
export const labelMap = {
  filterByAccountType: "Seller id",
};

export default function OrderFilter({ state, setState }) {
  function handleradioFieldChange(field, value) {
    setState((filterState) => {
      filterState[field] = value;
    });
  }

  return (
    <>
      <FilterRow>
        <FilterGroup label="Seller type" colWidth={2}>
          <FilterItem colWidth={2}>
            <RadioField
              label="Seller"
              name={"seller"}
              value={"filterByAccountType"}
              checked={state?.filterByAccountType === "Seller"}
              onChange={(e) =>
                handleradioFieldChange("filterByAccountType", "Seller")
              }
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <RadioField
              label="Vendor"
              name={"vendor"}
              value={"filterByAccountType"}
              checked={state?.filterByAccountType === "Supplier"}
              onChange={(e) =>
                handleradioFieldChange("filterByAccountType", "Supplier")
              }
            />
          </FilterItem>
        </FilterGroup>
      </FilterRow>
    </>
  );
}

export function renderFilterChips(filterState, setFilterState) {
  let filtersPresent = false;
  for (const filterKey in filterState) {
    if (!!filterState[filterKey]) {
      filtersPresent = true;
    }
  }

  const removeFilter = (filterItem) => {
    setFilterState((filter) => {
      delete filter[filterItem];
    });
  };

  if (filtersPresent) {
    let otherFilters = _omit(filterState, ["date"]);
    return (
      <FilterChipContainer>
        {Object.keys(otherFilters).length > 0 &&
          Object.keys(otherFilters)?.map((filterItem) => (
            <>
              <ChipItem
                label={`${filterState?.filterByAccountType}`}
                name={filterItem}
                handleRemove={removeFilter}
                key={filterItem}
              />
            </>
          ))}
      </FilterChipContainer>
    );
  }
  return null;
}
