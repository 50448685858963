import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import styles from "./index.module.css";
import Checkbox from "components/SixtyCheckBox";
import DatePicker from "components/SixtyDatePicker";
import { format, parse } from "date-fns";

const DiscountDates = ({ startDate: start, expireDate }) => {
  const { control, errors } = useFormContext();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (start) setStartDate(parse(start, "dd/MM/yyyy", new Date()));
  }, [start]);
  useEffect(() => {
    if (expireDate) setEndDate(parse(expireDate, "dd/MM/yyyy", new Date()));
  }, [expireDate]);

  const [neverExpires, setNeverExpires] = useState(false);

  return (
    <div className={styles.form}>
      <div className={styles.formRow}>
        <Controller
          control={control}
          name="startDate"
          defaultValue=""
          render={({ onChange, ...rest }) => (
            <DatePicker
              label="Start date"
              error={errors.startDate}
              control={control}
              value={startDate}
              onChange={(date) => {
                let formattedDate = format(date, "dd/MM/yyyy");
                onChange(formattedDate);
                setStartDate(date);
              }}
              dateFormat="dd/MM/yyyy h:mm aa"
              minDate={new Date()}
            />
          )}
        ></Controller>
        <Controller
          control={control}
          name="expiredDate"
          defaultValue=""
          render={({ onChange, ...rest }) => (
            <DatePicker
              label="Expire date"
              error={errors.expireDate}
              control={control}
              value={neverExpires ? null : endDate}
              onChange={(date) => {
                let formattedDate = format(date, "dd/MM/yyyy");
                onChange(formattedDate);
                setEndDate(date);
              }}
              dateFormat="dd/MM/yyyy h:mm aa"
              minDate={startDate}
            />
          )}
        ></Controller>
      </div>
      <div className={styles.formRow}>
        <Checkbox
          label="Never expires"
          checked={neverExpires}
          onChange={(e) => setNeverExpires(e.target.checked)}
          uncheckStyleVariant="greyedOut"
        />
      </div>
    </div>
  );
};

export default DiscountDates;
