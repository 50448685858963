import React, { useEffect, useRef, useState } from "react";

import Panel from "components/SixtyPanel";
import CategoryTable from "./components/CategoryTable";
import GridHeader from "components/GridHeader";
import SixtyButton from "components/SixtyButton";

import styles from "./index.module.css";
import Modal from "components/Modal";
import SixtyInput from "components/SixtyInput";
import { useAddCategory } from "queries/product";
import { setToast } from "components/Toast";

const Title = () => {
  return <span className={styles.categoryTitle}>Add category</span>;
};

const Categories = () => {
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const modalRef = useRef();

  const [categoryName, setCategoryName] = useState("");
  const [categoryError, setCategoryError] = useState();

  const { mutateAsync: addCategory, isSuccess } = useAddCategory();

  const handleCategoryAdd = async () => {
    if (categoryName === "") {
      setCategoryError({
        message: "Required",
      });
    } else {
      await addCategory({ name: categoryName });
    }
  };

  const reset = useRef();
  useEffect(() => {
    if (isSuccess) {
      setToast("Category added successfully");
      setCategoryName("");
      setAddCategoryOpen(false);
      if (reset.current) reset.current();
    }
  }, [isSuccess]);

  return (
    <div>
      <GridHeader
        header="Category"
        renderButtons={() => (
          <>
            <SixtyButton onClick={() => setAddCategoryOpen(true)}>
              Add new category
            </SixtyButton>
          </>
        )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.sellerTable}>
          <CategoryTable resetRef={reset} />
        </div>
      </Panel>
      <Modal
        isOpen={addCategoryOpen}
        onAttemptClose={() => {
          setAddCategoryOpen(false);
        }}
        modalRef={modalRef}
        Title={Title}
      >
        <div className={styles.categoryForm}>
          <SixtyInput
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            label="Name"
            placeholder="category"
            error={categoryError}
          />
          <footer className={styles.footer}>
            <SixtyButton onClick={handleCategoryAdd}>Add category</SixtyButton>
          </footer>
        </div>
      </Modal>
    </div>
  );
};

export default Categories;
