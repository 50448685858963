import React, { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./index.module.css";
import { ReactComponent as Icon } from "../../assets/icons/Icon-Arrow-Right.svg";
import { isEmpty } from "lodash";
import useOnClickOutside from "../../utils/hooks/useOnClickOutside";
import { useRef } from "react";
import { forwardRef } from "react";

const SixtySelectField = forwardRef(
  (
    {
      selectBoxHelperClass = "",
      selectBoxOptionsHelperClass = "",
      options,
      defaultOption,
      label = "",
      onChange,
      error,
      value,
      variant = "greyish",
      activeVariant = "secondary",
      size = "large",
      selectBoxFontHelper = "",
      optionBoxFontHelper = "",
      fullWidth = false,
      disableDropIcon = false,
      disabled = false,
      isOptionDisabled = false,
    },
    ref
  ) => {
    const [showOptionList, setShowOptionList] = useState(false);
    const [defaultSelectText, setDefaultSelectText] = useState("");

    const selectBoxRef = useRef();
    useOnClickOutside(ref || selectBoxRef, () => setShowOptionList(false));

    const [width, setWidth] = useState(100);
    const labelRef = useRef();
    const valueElementRef = useRef();
    // arrow width 36px
    useEffect(() => {
      const labelElement = labelRef.current;
      if (label && labelElement && (showOptionList || defaultSelectText)) {
        return;
      } else if (
        label &&
        labelElement &&
        !(showOptionList || defaultSelectText)
      ) {
        setWidth(labelElement?.clientWidth + 60);
      } else {
        setWidth(valueElementRef.current?.clientWidth + 36);
      }
    }, [defaultSelectText, label, showOptionList, value]);

    useEffect(() => {
      const selectedValue = value
        ? options.find((opt) => opt.value === value)
        : "";
      !isEmpty(selectedValue)
        ? setDefaultSelectText(
            selectedValue.selectedLabel || selectedValue.label
          )
        : setDefaultSelectText(defaultOption);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, label, defaultOption]);

    const handleListDisplay = (e) => {
      e.preventDefault();
      if (!disabled || !isEmpty(options)) setShowOptionList(!showOptionList);
    };

    // This method handles the setting of name in select text area
    // and list display on selection
    const handleOptionClick = (e) => {
      e.preventDefault();
      const isDisabled = e.target.getAttribute("data-disabled");
    
      if(isDisabled == "false"){
        setShowOptionList(false);
        if (onChange && typeof onChange === "function") {
          const selectedValue = e.target.getAttribute("data-value");
          onChange(selectedValue);
        }
      }
    };

    const optionElements = options.map((option, i) => {
      const option_attributes = {};
      for (const prop of Object.keys(option)) {
        option_attributes[prop] = option[prop];
      }

      return (
        <li
          className={clsx(styles.customSelectOption, optionBoxFontHelper, option.disabled ? styles.disabledOption : "")}
          data-name={option.label}
          data-value={option.value}
          data-disabled={option.disabled ? true : false}
          key={i}
          onClick={handleOptionClick}
           //{...option_attributes}
        >
          {option.label}
        </li>
      );
    });
    return (
      <div
        ref={ref || selectBoxRef}
        className={clsx(styles.customSelectContainer, {
          [styles.fullWidth]: fullWidth,
          [styles.disabled]: disabled || isEmpty(options),
        })}
      >
        <div
          className={clsx(
            showOptionList || defaultSelectText
              ? disabled ? styles.disabled : styles[activeVariant]
              : styles[variant],
            styles.selectDisplayBox,
            styles[size],
            selectBoxHelperClass,
            error && styles.error
          )}
          onClick={handleListDisplay}
          style={{ minWidth: width }}
        >
          {label && (
            <label
              className={clsx(styles.label, {
                [styles.activeLabel]: showOptionList || defaultSelectText,
              })}
              ref={labelRef}
            >
              {label}
            </label>
          )}

          <span
            className={clsx(styles.fixedlabel, selectBoxFontHelper, {
              [styles.active]: showOptionList,
              [styles.selectedText]: !!value,
            })}
            ref={valueElementRef}
          >
            {defaultSelectText}
          </span>
          {!disableDropIcon && (
            <span className={styles.downArrowIcon}>
              <Icon />
            </span>
          )}
        </div>

        <ul
          className={clsx(
            styles.selectOptions,
            selectBoxOptionsHelperClass,
            {
              [styles.selectOptionsActive]: showOptionList,
            },
            selectBoxOptionsHelperClass
          )}
        >
          {optionElements}
        </ul>

        {error && <div className={styles.errorMessage}>{error.message}</div>}
      </div>
    );
  }
);

export default SixtySelectField;
