const notificationSettingsConfig = {
  Orders: [
    {
      name: "orderReceived",
      title: "Order received",
      description: "Received by admin when a seller places a new order",
      isEnabled: "true",
    },
    {
      name: "orderConfirmed",
      title: "Order confirmed",
      description:
        "Received by the seller and customer when they make their order",
      isEnabled: "true",
    },
    {
      name: "orderShipped",
      title: "Order shipped",
      description:
        "Received by the seller and customer when their order requires shipping and their packages has been shipped",
      isEnabled: "true",
    },
    {
      name: "orderRefunded",
      title: "Order refunded",
      description:
        "Received by the customer when their order has been refunded",
      isEnabled: "true",
    },
  ],
  Sellers: [
    {
      name: "sellerApplicationSubmitted",
      title: "Seller application submitted",
      description:
        "Received by admin when a seller submitted their application",
      isEnabled: "true",
    },
    {
      name: "sellerApplicationApproved",
      title: "Seller application approved",
      description: "Received by seller when admin approved their application",
      isEnabled: "true",
    },
    {
      name: "sellerApplicationRejected",
      title: "Seller application rejected",
      description: "Received by seller when admin rejected their application",
      isEnabled: "true",
    },
  ],
};

export default notificationSettingsConfig;
