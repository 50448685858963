import React from "react";
import styles from "./index.module.css";
import { ReactComponent as BaseIcon } from "assets/images/Base.svg";
import { ReactComponent as ExternalSiteIcon } from "assets/icons/Icon-External-Site.svg";
import Avatar from "components/Avatar";
import { useHistory } from "react-router-dom";

const OrderSummary = ({ data, onUpdateOrder }) => {
  const {
    orderDate,
    status,
    paymentDetail,
    shippingDetail,
    sellerName,
    sellerId,
    sellerTotalOrder,
  } = data;
  const history = useHistory();

  return (
    <React.Fragment>
      {data && (
        <div className={styles.orderBody}>
          {orderDate && (
            <div className={styles.orderFieldContainer}>
              <div className={styles.orderFieldName}>Order date</div>
              <div className={styles.orderFieldValue}>{orderDate}</div>
            </div>
          )}
          {status && (
            <div className={styles.orderFieldContainer}>
              <div className={styles.orderFieldName}>Status</div>
              <div className={styles.orderFieldValue}>
                <span
                  className={[
                    styles.elipse,
                    styles.elipseOrange,
                    styles.elipseSmall,
                  ].join(" ")}
                >
                  <BaseIcon />
                </span>
                {status}
              </div>
            </div>
          )}
          {/* {paymentDetail?.status && (
            <div className={styles.orderFieldContainer}>
              <div className={styles.orderFieldName}>Payment status</div>
              <div className={styles.orderFieldValue}>
                <span
                  className={[
                    styles.elipse,
                    styles.elipseYellow,
                    styles.elipseSmall,
                  ].join(" ")}
                >
                  <BaseIcon />
                </span>
                {paymentDetail?.status}
              </div>
            </div>
          )} */}
          {shippingDetail?.shippingMethod ||
            (shippingDetail?.trackingCode && (
              <div className={styles.orderFieldContainer}>
                <div className={styles.orderFieldName}>Shipping method</div>
                {shippingDetail?.shippingMethod && (
                  <>
                    <div className={styles.orderFieldValue}>
                      {shippingDetail?.shippingMethod}
                    </div>
                  </>
                )}
                  {shippingDetail?.trackingCode && (
                  <div className={styles.orderFieldSubContainer}>
                    <span className={styles.orderFieldName}>
                      Shipping Company:
                    </span>
                    <span className={styles.orderFieldValue}>
                      <a className={styles.link} onClick={onUpdateOrder}>{shippingDetail?.companyName}</a>
                    </span>
                    <span>
                      <ExternalSiteIcon />
                    </span>
                  </div>
                )}
                {shippingDetail?.trackingCode && (
                  <div className={styles.orderFieldSubContainer}>
                    <span className={styles.orderFieldName}>
                      Tracking code:
                    </span>
                    <span className={styles.orderFieldValue}>
                      <a className={styles.link} onClick={onUpdateOrder}>{shippingDetail?.trackingCode}</a>
                    </span>
                    <span>
                      <ExternalSiteIcon />
                    </span>
                  </div>
                )}
              </div>
            ))}
          {sellerName && (
            <div className={styles.orderFieldContainer}>
              <div className={styles.orderFieldName}>Seller</div>
              <div
                className={[styles.orderFieldValue, styles.sellerDetails].join(
                  " "
                )}
                onClick={() => {
                  history.push(`/dashboard/sellers/${sellerId}`);
                }}
              >
                <Avatar name={sellerName} />
                <span className={styles.sellerName}>{sellerName}</span>
                {sellerTotalOrder && (
                  <span className={styles.orderCount}>
                    {sellerTotalOrder + " orders"}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default OrderSummary;
