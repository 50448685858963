import { SortItem } from "components/SixtyTable/components/Sort";

export default function ProductSort({ state, handleSortChange }) {
  return (
    <>
      <SortItem
        label="Order number (Ascending)"
        name="orderByAscending"
        checked={state === "orderByAscending"}
        onChange={() => handleSortChange("orderByAscending")}
      />
      <SortItem
        label="Order number (Descending)"
        name="orderByDescending"
        checked={state === "orderByDescending"}
        onChange={() => handleSortChange("orderByDescending")}
      />
      <SortItem
        label="Product name (Ascending)"
        name="orderByProductNameAscending"
        checked={state === "orderByProductNameAscending"}
        onChange={() => handleSortChange("orderByProductNameAscending")}
      />
      <SortItem
        label="Product name (Descending)"
        name="orderByProductNameDescending"
        checked={state === "orderByProductNameDescending"}
        onChange={() => handleSortChange("orderByProductNameDescending")}
      />
      <SortItem
        label="Price (Ascending)"
        name="orderByPriceAscending"
        checked={state === "orderByPriceAscending"}
        onChange={() => handleSortChange("orderByPriceAscending")}
      />
      <SortItem
        label="Price (Descending)"
        name="orderByPriceDescending"
        checked={state === "orderByPriceDescending"}
        onChange={() => handleSortChange("orderByPriceDescending")}
      />
      <SortItem
        label="Order count (Ascending)"
        name="orderByOrderCounteAscending"
        checked={state === "orderByOrderCounteAscending"}
        onChange={() => handleSortChange("orderByOrderCounteAscending")}
      />
      <SortItem
        label="Order count (Descending)"
        name="orderByOrderCounteDescending"
        checked={state === "orderByOrderCounteDescending"}
        onChange={() => handleSortChange("orderByOrderCounteDescending")}
      />
    </>
  );
}
