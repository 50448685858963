import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import VideoStoryTable from "./components/VideoStoryTable";
import UpdateStory from "./components/UpdateStory";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import Tabs from "components/Tabs";

const VideoStories = () => {

  const history = useHistory();
  const storyRef = useRef();
  const [isOpenStory, setOpenStory] = useState(false);
  const [selectedVideoStory, setVideoStory] = useState(false);

  const onOpenStory = (value) => {
    setVideoStory(value);
    setOpenStory(true);
  };

 const onCloseStory = () => {
    setOpenStory(false);
 };

  const StoryTitle = () => {
    return (
      <div className={styles.modalTitleBar}>
        <div className={styles.modalContainer}>
          <div className={styles.modalTitle}>{'Creator Video Story'}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setOpenStory(false)}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <GridHeader header="Video Stories" 
         renderButtons={() => (
          <>   
          </>
         )}
      />
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Active",
              TabComponent: VideoStoryTable,
              props: {
                status: "Active",
                onContentClick: onOpenStory
              },
            },
          ]}
        ></Tabs>
      </Panel>
      {isOpenStory && (
        <Modal
          isOpen={isOpenStory}
          onAttemptClose={() => {
            setOpenStory(false);
          }}
          modalRef={storyRef}
          Title={StoryTitle}
        >
            <UpdateStory
                selectedStory={selectedVideoStory}
                handleClose={onCloseStory}
            />
         </Modal>
      )}
    </div>
  );
};

export default VideoStories;
