import styles from "./index.module.css";
import { ReactComponent as ErrorIcon } from "assets/icons/Icon-Error.svg";
import SixtyButton from "components/SixtyButton";
import Modal from "components/Modal";
import SixtyText from "components/SixtyText";

export default function ErrorModal({
  open,
  loading = false,
  handleClose,
  handleCancel,
  errorMessage = "Error",
  errorRef,
  actionLabel,
  handleAction,
  noIcon = false,
}) {
  return (
    <Modal isOpen={open} onAttemptClose={handleClose} modalRef={errorRef}>
      <div className={styles.container}>
        {!noIcon && <ErrorIcon />}
        <SixtyText type="h2" className={styles.message}>
          {errorMessage}
        </SixtyText>
        <div className={styles.actions}>
          <SixtyButton variant="warning" onClick={handleAction} disabled={loading}>
            {actionLabel}
          </SixtyButton>
          <SixtyButton variant="secondary" onClick={handleCancel} disabled={loading}>
            Cancel
          </SixtyButton>
        </div>
      </div>
    </Modal>
  );
}
