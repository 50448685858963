import { ReactComponent as ProductIcon } from "assets/icons/productSample.svg";

export const productList = [
  {
    productId: "1",
    productName: "Product1",
    ProductImage: ProductIcon,
    brandName: "brand1",
    unitPrice: 60,
    quantity: 3,
    details: "Red, Wood, Large"
  },
  {
    productId: "2",
    productName: "Product2",
    ProductImage: ProductIcon,
    brandName: "brand2",
    unitPrice: 70,
    quantity: 2,
    details: "Red, Wood, Large"
  }
];

export const paymentDetails = {
  subTotal: 1060,
  shippingFee: 18,
  sst: 4.68,
  paymentStatus: "Payment pending",
  amountPaid: 0
};

export const collectPaymentOptions = [
  {
    text: "Send invoice",
    value: "invoice",
  },
  {
    text: "Pay by credit card",
    value: "credit",
  },
  {
    text: "Mark as paid",
    value: "paid",
  },
]

export const orderInfo = {
  orderDate: "Jan 18, 2020 at 2.30pm",
  status: "Open",
  paymentStatus: "Payment pending",
  shippingMethod: "Standard Shipping",
  trackingCode: "DHL6541215486",
  seller: {
    name: "John Doe",
    orderCount: 91
  }
};

export const customerDetails = {
  name: "Daru Sim",
  orderCount: 6,
  email: "darusim.ws@gmail.com",
  phone: "+6123452789",
  shippingAddress: "123, Robinson Residence, No. 5, Robinson Road, 50000 Damansara Height, Kuala Lumpur, Malaysia.",
  billingAddress: "Same as shipping address"
}