import { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "../index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect.js";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";
import { ReactComponent as EyeIcon } from "assets/icons/passwordView.svg";
import { ReactComponent as EyeIconActive } from "assets/icons/passwordViewActive.svg";
import { useGetGenderDropdown } from "queries/vendor";

export default function StepOneForm({
  control,
  handleChange,
  setValue,
  values,
  errors,
  active,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = (e) => setShowPassword((show) => !show);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const handleShowRepeatClick = (e) => setShowRepeatPassword((show) => !show);

  useEffect(() => {
    setValue("owner", values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: genderData } = useGetGenderDropdown();
  const genderOptions = genderData?.length
    ? genderData.map((option) => ({
        label: option.name,
        value: option.key,
      }))
    : [];

  return (
    <div
      className={clsx(styles.form, {
        [styles.activeForm]: active,
      })}
    >
      <Input
        control={control}
        error={errors?.owner?.name}
        name="owner.name"
        label="Full name"
        handleChange={handleChange}
      />
      {/* <Input
        name="owner.userName"
        error={errors?.owner?.userName}
        label="Username"
        control={control}
        handleChange={handleChange}
      /> */}
      <Input
        label="Business email address"
        name="owner.email"
        error={errors?.owner?.email}
        placeholder="name@business.co"
        control={control}
        handleChange={handleChange}
        disabled={values?.email ? true : false}
      />
      <div className={styles.mobileInputContainer}>
        <div className={styles.countryCode}>
          <Select
            name="owner.countryCode"
            error={errors?.owner?.countryCode}
            disabled={true}
            options={[
              {
                label: "Malaysia +60",
                selectedLabel: "+60",
                value: "+60",
              },
            ]}
            control={control}
            handleChange={handleChange}
          />
        </div>
        <div className={styles.phoneNumber}>
          <Input
            name="owner.mobile"
            error={errors?.owner?.mobile}
            label="Mobile number"
            control={control}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div>
        <ControlledDatepicker
          name="owner.dob"
          error={errors?.owner?.dob}
          label="Date of birth"
          control={control}
          dateFormat="dd/MM/yyyy"
          handleChange={handleChange}
          maxDate={new Date()}
        />
        <div className={styles.dateFormat}>DD / MM / YYYY</div>
      </div>
      <Input
        name="owner.password"
        error={errors?.owner?.password}
        label="Password"
        control={control}
        handleChange={handleChange}
        rules={{
          required: { value: true, message: "Password is required" },
        }}
        defaultValue=""
        type={showPassword ? "text" : "password"}
        endIcon={
          showPassword ? (
            <EyeIconActive onClick={handleShowClick} />
          ) : (
            <EyeIcon onClick={handleShowClick} />
          )
        }
      />

      <Input
        name="owner.repeatPassword"
        error={errors?.owner?.repeatPassword}
        label="Repeat password"
        control={control}
        handleChange={handleChange}
        rules={{
          required: { value: true, message: "Password is required" },
        }}
        defaultValue=""
        type={showRepeatPassword ? "text" : "password"}
        endIcon={
          showRepeatPassword ? (
            <EyeIconActive onClick={handleShowRepeatClick} />
          ) : (
            <EyeIcon onClick={handleShowRepeatClick} />
          )
        }
      />

      <Select
        control={control}
        handleChange={handleChange}
        label="Gender"
        name="owner.gender"
        error={errors?.owner?.gender}
        options={genderOptions}
        defaultValue="Male"
        fullWidth
      />
    </div>
  );
}
