import React from "react";
import styles from "./index.module.css";
import clsx from "clsx";

const Tab = ({ tabId, activeTab, label, onClick, recordsCount, alert }) => {
  const onChange = () => {
    onClick(tabId);
  };

  function renderLabel(){
    if(alert){
      return(
        <span className={styles.tabAlert}>{label}</span>
      );
    }
    else{
      return(
        <span>{label}</span>
      );
    }
  }

  return (
    <div
      className={clsx(styles.tabListItem, {
        [styles.tabListActive]: activeTab === tabId,
      })}
      onClick={onChange}
    >
      {renderLabel()}
      {recordsCount > 0 && (
        <span className={styles.recordsCount}>({recordsCount})</span>
      )}
    </div>
  );
};

export default Tab;
