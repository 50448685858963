import React, { useRef, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import SixtyButton from "components/SixtyButton";
import CopyContents from "components/CopyContents";
import styles from "./index.module.css";
import SixtyInput from "components/SixtyInput";
import Checkbox from "components/FormComponents/ControlledCheckbox";
import Input from "components/FormComponents/ControlledInput";
import ControlledSelect from "components/FormComponents/ControlledSelect";
import { reportError } from "crashlytics";
import {
  useInviteSellerByEmail,
  useGenerateInvitation,
} from "queries/invitation";
import { useGetPriceTierDropdown } from "queries/product";
import { format } from "date-fns";
import { setToast } from "components/Toast";

const defaultValues = {
  autoApproveSellerApplication: false,
  email: "",
  tier: "",
};

const InviteSellerForm = ({ handleInviteSellerOpen }) => {
  const textAreaRef = useRef(null);
  const formMethods = useForm({
    defaultValues,
  });
  const [invitationCode, setInvitationCode] = useState("");
  const [autoApprove, setAutoApprove] = useState(false);
  const { setValue } = formMethods;

  const { mutateAsync: generateInvitation } = useGenerateInvitation();

  const { mutateAsync: inviteSellerByEmail } = useInviteSellerByEmail();

  // pricetier dropdown option query
  const { data: tierData } = useGetPriceTierDropdown();

  const tierOptions =
    tierData?.length > 0
      ? tierData.map((tier) => ({
          label: tier.name,
          value: tier.id,
        }))
      : [];

  const onSubmit = async (data) => {
  
    handleInviteSellerOpen(false);
    try {
      await inviteSellerByEmail({
        invitedEmail: data.email,
        priceTierId: data.tiers,
        allowAutoApprove: data.autoApproveSellerApplication,
      });
      setToast("Successfully invited creator");
    } catch (error) {
      reportError("InviteSellerByEmail: onSubmit: error: ", error);
    }
  };

  const getInvitation = async () => {
    try {
      const result = await generateInvitation({
        // allowAutoApprove: autoApprove,
        // expiredOnUtc: format(new Date(), "dd/MM/yyyy"),
      });
      if (result) {
        setInvitationCode(result.code);
      }
    } catch (error) {
      reportError("GenerateInvitation: onSubmit: error: ", error);
    }
  };

  useEffect(() => {
    getInvitation();
  }, [autoApprove]);

  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.sellerFormContainer}>
          <form
            id="invite-seller-form"
            onSubmit={formMethods.handleSubmit(onSubmit)}
          >
            <div className={styles.invitationCodeContainer}>
              <div className={styles.copyInvitationText}>
                Copy and share your invitation code
              </div>
              <div className={styles.copyInvitationRow}>
                <SixtyInput
                  ref={textAreaRef}
                  value={invitationCode}
                  name="invitationCode"
                  className={styles.invitationInput}
                  fadeOut
                  onChange={() => {}}
                />
                <CopyContents
                  selectedRef={textAreaRef}
                  className={styles.copyContentsIcon}
                />
              </div>
              {/* <div>
                <Checkbox
                  name="autoApproveSellerApplication"
                  label="Auto approve applications"
                  uncheckStyleVariant="greyedOut"
                  labelHelperClass={styles.autoApproveLabel}
                  checked={autoApprove}
                  onChange={(e) => {
                    setAutoApprove(e.target.checked);
                    setValue("autoApproveSellerApplication", e.target.checked);
                  }}
                />
                <div className={styles.subNotes}>
                  The influencer’s application will be approved automatically
                </div>
              </div> */}
            </div>
            <div className={styles.emailInviteContainer}>
              <div className={styles.emailInviteText}>
                Or invite via email address
              </div>
              <div className={styles.emailFieldRow}>
                <Input
                  name="email"
                  rules={{ required: "Email required" }}
                  label="Email address"
                  defaultValue=""
                  type="email"
                  error={formMethods?.errors?.email}
                />
              </div>
              {/* <div>
                <ControlledSelect
                  name={`tiers`}
                  options={tierOptions}
                  fullWidth={true}
                  defaultValue={null}
                  label="Select a tier"
                />
              </div> */}
              <div className={styles.subNotes}>
                Invited creator will be able to browse and add your products to
                their store once they signed up.
              </div>
            </div>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default InviteSellerForm;
