import styles from "./index.module.css";
import clsx from "clsx";

export default function Paper({ className, children, ...rest }) {
  return (
    <div className={clsx(styles.paper, className)} {...rest}>
      {children}
    </div>
  );
}
