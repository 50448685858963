import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import { useDeletePriceTier } from "queries/product";
import { setToast } from "components/Toast";

const defaultValues = {
  name: "",
  discountRate: 5,
};

const AddTierForm = ({ onCreateTierSubmit, onCreateTierCancel, data }) => {
  const schema = yup.object().shape({
    name: yup.string("Required"),
    discountRate: yup
      .number()
      .required("Required")
      .max(100, "Enter a valid discount"),
  });

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onSubmit = (data) => {
    onCreateTierSubmit(data);
  };

  const {
    mutateAsync: deleteTier,
    isLoading: deletingTier,
    isSuccess: deleteSuccess,
  } = useDeletePriceTier();

  const onDelete = async () => {
    try {
      await deleteTier(data?.id);
    } catch (e) {}
  };

  useEffect(() => {
    if (deleteSuccess) {
      setToast("Discount deleted successfully");
      onCreateTierCancel();
    }
  }, [deleteSuccess, onCreateTierCancel]);

  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.addTierFormContainer}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={styles.addTierForm}>
              <div className={styles.tierName}>
                <Input
                  name="name"
                  label="Name"
                  rules={{ required: "Name required" }}
                  error={formMethods?.errors?.name}
                />
              </div>
              <div className={styles.defaultDiscount}>
                <Input
                  name="discountRate"
                  label="Commission Rate"
                  type="number"
                  min={0}
                  max={100}
                  rules={{ required: "Discounts required" }}
                  error={formMethods?.errors?.discountRate}
                />
                <span className={styles.percentSign}>%</span>
              </div>
              <div className={styles.subNotes}>
                When commission tiers is applied to a product, the product price will be
                discounted based on the percentage above.
              </div>
            </div>
            <footer className={styles.footer}>
              <SixtyButton
                type="submit"
                variant={data ? "primary" : "warning"}
                className={styles.createBtn}
                disabled={deletingTier}
              >
                {data ? "Update" : "Create"}
              </SixtyButton>
              {!!data ? (
                <SixtyButton
                  variant="warning"
                  className={styles.createBtn}
                  onClick={onDelete}
                  disabled={deletingTier}
                >
                  Delete
                </SixtyButton>
              ) : null}
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={() => onCreateTierCancel()}
                disabled={deletingTier}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default AddTierForm;
