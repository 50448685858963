import styles from './index.module.css';

const SellerActivities = ({
    activities
}) => {
    return (
        <div className={styles.activitiesBody}>{activities?.map(activity =>
            <div className={styles.cell}>
                <div className={styles.activity}>{activity.activity}</div>
                <div className={styles.activityDate}>{activity.actionDate}</div>
            </div>
        )}</div>
    )
}

export default SellerActivities;