import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import ChipInput from "components/FormComponents/ControlledChipInput";
import { sellerRequestData } from "assets/fakeData/fakeData";

const defaultValues = {
    subject: "Please make payment on #0001",
    message: ""
};

const dummyEmails = sellerRequestData.map(sellerRequest => {
    return {
        label: sellerRequest.email,
        value: sellerRequest.email
    };
});

const SendInvoiceForm = ({
    handleSubmit,
    handleCancel
}) => {

    const formMethods = useForm({
        defaultValues
    });

    const onSubmit = (data) => {
        console.log("data from form", data);
        handleSubmit();
    };

    return (
        <React.Fragment>
            <FormProvider {...formMethods}>
                <div className={styles.sendInvoiceFormContainer}>
                    <form
                        onSubmit={formMethods.handleSubmit(onSubmit)}>
                        <div className={styles.sendInvoiceForm}>
                            <div className={styles.row}>
                                <div className={styles.toEmailField}>
                                    <ChipInput
                                        name="toEmail"
                                        label="To"
                                        lines={1}
                                        defaultValue={[]}
                                        options={dummyEmails}
                                        showDropdownIcon
                                        isMultiSelect={false}
                                    />
                                </div>
                                <div className={styles.fromEmailField}>
                                    <ChipInput
                                        name="fromEmail"
                                        label="From"
                                        lines={1}
                                        defaultValue={[]}
                                        options={dummyEmails}
                                        showDropdownIcon
                                        isMultiSelect={false}
                                    />
                                </div>
                            </div>
                            <div className={styles.row}>
                                <Input
                                    name="subject"
                                    label="Subject"
                                    className={styles.subjectInput}
                                />
                            </div>
                            <div className={styles.row}>
                                <Input
                                    name="message"
                                    label="Custom message(Optional)"
                                    lines="5"
                                    className={styles.messageInput}
                                />
                            </div>
                        </div>
                        <footer className={styles.footer}>
                            <SixtyButton type="submit" className={styles.sendInvoiceBtn}>Send invoice</SixtyButton>
                            <SixtyButton variant="secondary" className={styles.cancelButton} onClick={() => handleCancel()}>Cancel</SixtyButton>
                        </footer>
                    </form>
                </div>
            </FormProvider>
        </React.Fragment>
    )
}

export default SendInvoiceForm;
