import Card from "./Components/Card";
import styles from "./index.module.css";
import clsx from "clsx";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import SixtyLink from "components/SixtyLink";

const ContentRoadmap = ({ items, onSocialMedia }) => {

  return (
    <div>
      <div className={styles.row}>

            {items &&  
                items.map((item) => (
                  <>
                    <Card
                      title={item.name}
                      // unit={item.bundleQuantity1 + ' to ' + item.bundleQuantity2 + ' videos'}
                      callToActionName={'Order Now '}
                      desc={item.instruction}
                      clickEvent={() => onSocialMedia(item.id)}
                      btnClassname={item.classname}
                      className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                    />
                  </>
                )     
             )}
      </div>
    </div>
  );
};

export default ContentRoadmap;
