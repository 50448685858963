import React, { useState } from "react";
import Text from "components/SixtyText";
import styles from "./index.module.css";
import { ReactComponent as SortIcon } from "assets/icons/sort-icon.svg";
import { useRef } from "react";
import useOnClickOutside from "utils/hooks/useOnClickOutside";
import clsx from "clsx";

export default function Sort({ state, handleSortChange, render }) {
  const [open, setOpen] = useState(false);

  const clickSort = () => {
    setOpen((open) => !open);
  };

  const buttonRef = useRef();
  const sortBoxRef = useRef();

  useOnClickOutside(sortBoxRef, () => setOpen(false), [buttonRef]);

  return (
    <div className={styles.sortContainer}>
      <button
        className={clsx(styles.sortButton, open && styles.activeButton)}
        onClick={clickSort}
        ref={buttonRef}
      >
        <SortIcon className={styles.sortIcon} /> Sort
      </button>
      {open && (
        <div className={styles.sortBox} ref={sortBoxRef}>
          <div className={styles.header}>
            <Text type="h3" weight="medium">
              Sort By
            </Text>
          </div>
          <div className={styles.body}>
            {render && render({ state, handleSortChange })}
          </div>
        </div>
      )}
    </div>
  );
}

export const SortItem = ({ name, label, checked, onChange }) => (
  <label className={styles.sortItem}>
    <input
      type="radio"
      className={styles.sortRadio}
      name="sort"
      value={name}
      checked={checked}
      onChange={onChange}
    />
    <Text type="p1" inline>
      {label}
    </Text>
  </label>
);
