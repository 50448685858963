import { useUpdateNotificationSettings } from "queries/setting";
import notificationSettingsConfig from "../../notificationSettingsConfig";
import NotificationRow from "../NotificationRow";

const OrderNotificationSettings = ({ state, setState }) => {
  const {
    mutateAsync: updateNotificationSettings,
  } = useUpdateNotificationSettings();
  const setNotificationPreference = (value, name) => {
    const clonedState = JSON.parse(JSON.stringify(state));
    clonedState[name] = value;
    updateNotificationSettings(clonedState);
    setState(clonedState);
  };

  return (
    <div>
      {notificationSettingsConfig["Orders"]?.map((notificationSetting) => {
        return (
          <NotificationRow
            settingsName={notificationSetting.name}
            settingsTitle={notificationSetting.title}
            settingsDescription={notificationSetting.description}
            isNotificationEnabled={state[notificationSetting.name]}
            setNotificationPreference={setNotificationPreference}
          />
        );
      })}
    </div>
  );
};

export default OrderNotificationSettings;
