import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetSellerRegistrationDetail } from "queries/seller";
import { useGetSellerShipmentDetail, useUpdateSellerCampaignShipment } from "queries/campaign";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import { ReactComponent as CopyContentsIcon } from "assets/icons/Icon-Copy-Content.svg";
import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

import { useForm, FormProvider } from "react-hook-form";
import Input from "components/FormComponents/ControlledInput";
import ReactWhatsapp from 'react-whatsapp';

const defaultValues = {
    shippingCompany: '',
    trackingCode: ''
};

const InfluencerShipmentDetails = ({ selectedSeller, campaign, handleClose, viewOnly = false}) => {
  const { code, id } = selectedSeller;

  const formMethods = useForm({
    defaultValues,
  });

  const { setValue } = formMethods;
  const { data: sellerInfo, isLoading, isError, refetch } = useGetSellerRegistrationDetail(
    code
  );

  const { data: sellerShipmentInfo, isLoading: shipmentDetailLoading, isFetchedAfterMount } = useGetSellerShipmentDetail(
    id
  );

  const { mutateAsync: updateShipment, 
          isLoading : shipmentLoading 
        } = useUpdateSellerCampaignShipment();

  const onUpdateShipment = async (data) => {
    try {

      const model = {
           id: id,
           shippingCompany: data.shippingCompany,
           trackingCode: data.trackingCode
      };

      const result = await updateShipment(model);

      if (result) {
        handleClose();
        setToast("successfully update shipping information");
      }
    } catch (e) {
      reportError(
        "error in : InfluencerShipmentDetails: onUpdateShipment, error: ",
        e
      );
    }
  };

  const onCancel = async () => {
    handleClose();
  };

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    let innerText = ``;
    if (sellerInfo?.shippingAddress?.unitNumber)
    innerText = `${innerText}${sellerInfo?.shippingAddress.unitNumber}
    `;
    if (sellerInfo?.shippingAddress?.floorNumber)
    innerText = `${innerText}${sellerInfo?.shippingAddress.floorNumber}
    `;
    if (sellerInfo?.shippingAddress?.buildingName)
      innerText = `${innerText}${sellerInfo?.shippingAddress.buildingName}
      `;
    if (sellerInfo?.shippingAddress?.street1)
      innerText = `${innerText}${sellerInfo?.shippingAddress.street1}
      `;
    if (sellerInfo?.shippingAddress?.street2)
      innerText = `${innerText}${sellerInfo?.shippingAddress.street2}
      `;
    if (sellerInfo?.shippingAddress?.street3)
      innerText = `${innerText}${sellerInfo?.shippingAddress.street3}
      `;
    if (sellerInfo?.shippingAddress?.buildingName)
      innerText = `${innerText}${sellerInfo?.shippingAddress.buildingName}
      `;
    if (sellerInfo?.shippingAddress?.postcode && sellerInfo?.shippingAddress?.city)
      innerText = `${innerText}${sellerInfo?.shippingAddress?.postcode}, ${sellerInfo?.shippingAddress.city}
      `;
    if (sellerInfo?.shippingAddress?.state && sellerInfo?.shippingAddress?.country)
      innerText = `${innerText}${sellerInfo?.shippingAddress.state}, ${sellerInfo?.shippingAddress.country}
      `;
    textField.innerText = innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (innerText !== "") setToast("Address copied to clipboard");
    else setToast("No content to copy", "error");
  };

  useEffect(() => {

    if (sellerShipmentInfo && isFetchedAfterMount) {

     for (const [key, value] of Object.entries({
         shippingCompany: sellerShipmentInfo?.shippingCompany,
         trackingCode: sellerShipmentInfo?.trackingCode,
     })) {
       setValue(key, value);
     }
   }
 
   }, [setValue, sellerShipmentInfo, isFetchedAfterMount]);


  return (
    <React.Fragment>
      <div className={styles.sellerRequestFormContainer}>
        {(isLoading || shipmentDetailLoading) && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.sellerRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Personal details"
            />
            {sellerInfo?.contactNumber && !viewOnly && (
              <div className={styles.btnActionContainer}>             
              <ReactWhatsapp number={sellerInfo?.contactNumber} message="" className={clsx(styles.button, styles.secondary)}>
                Chat With Influencer
              </ReactWhatsapp>
              </div>
            )}
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerInfoItem)}>
                  <div className={styles.sellerInfoHeader}>Name</div>
                  <div>
                    {sellerInfo?.name}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Contact</div>
                  <div>
                    {sellerInfo?.contactNumber}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={clsx(styles.email, styles.elipsis, styles.sellerInfoHeader)}>Email</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.email}
                  >
                    {sellerInfo?.email}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          {["Standard","WebsiteReview"].includes(campaign?.campaignType) && (
             <>
              {["Manual"].includes(campaign?.shipmentType) && (
                <>
                    <Panel className={styles.panelWrap}>
                    <PanelHeader
                      className={styles.sellerInfoHeader}
                      title="Shipping Address"
                    />
                    <div className={styles.btnActionContainer}>
                        <SixtyButton
                            variant="secondary"
                            onClick={() => copyToClipboard()}
                            className={styles.approveBtn}
                        >
                          {"Copy"}
                        </SixtyButton>
                    </div>
                    <div className={styles.sellerInfoContainer}>
                    <div className={styles.sellerInfoRow}>
                        <div className={styles.sellerInfoItem}>
                          <div className={styles.sellerInfoHeader}>Unit Number</div>
                          <div>
                            {sellerInfo?.shippingAddress?.unitNumber}
                          </div>
                        </div>
                        <div className={styles.sellerInfoItem}>
                          <div className={styles.sellerInfoHeader}>Floor Number</div>
                          <div>
                            {sellerInfo?.shippingAddress?.floorNumber}
                          </div>
                        </div>
                        <div className={styles.sellerInfoItem}>
                          <div className={styles.sellerInfoHeader}>Building Name</div>
                          <div>
                            {sellerInfo?.shippingAddress?.buildingName}
                          </div>
                        </div>
                      </div>
                      <div className={styles.sellerInfoRow}>
                        <div className={styles.sellerInfoItem}>
                          <div className={styles.sellerInfoHeader}>Street 1</div>
                          <div>
                            {sellerInfo?.shippingAddress?.street1}
                          </div>
                        </div>
                        <div className={styles.sellerInfoItem}>
                          <div className={styles.sellerInfoHeader}>Street 2</div>
                          <div>
                            {sellerInfo?.shippingAddress?.street2}
                          </div>
                        </div>
                        <div className={styles.sellerInfoItem}>
                          <div className={styles.sellerInfoHeader}>Street 3</div>
                          <div>
                            {sellerInfo?.shippingAddress?.street3}
                          </div>
                        </div>
                      </div>
                      <div className={styles.sellerInfoRow}>
                        <div className={styles.sellerInfoItem}>
                          <div className={styles.sellerInfoHeader}>Poscode</div>
                          <div>
                            {sellerInfo?.shippingAddress?.postcode}
                          </div>
                        </div>
                        <div className={styles.sellerInfoItem}>
                          <div className={styles.sellerInfoHeader}>City</div>
                          <div>
                            {sellerInfo?.shippingAddress?.city}
                          </div>
                        </div>
                        <div className={styles.sellerInfoItem}>
                          <div className={styles.sellerInfoHeader}>State</div>
                          <div>
                            {sellerInfo?.state}
                          </div>
                        </div>
                      </div>
                      <div className={styles.sellerInfoRow}>
                        <div className={styles.sellerInfoItem}>
                          <div className={styles.sellerInfoHeader}>Country</div>
                          <div
                            className={styles.elipsis}
                            title={sellerInfo?.country}
                          >
                            {sellerInfo?.country}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </>
              )}
 
              <Panel className={styles.panelWrap}>
              <PanelHeader
                  className={styles.sellerInfoHeader}
                  title="Shipment Information"
              />
                <div className={styles.sellerInfoContainer}>
                  <div className={styles.sellerInfoRow}>
                    <div className={clsx(styles.sellerInfoItem)}>
                      <div className={styles.sellerInfoHeader}>Status</div>
                      <div>
                        {sellerShipmentInfo?.status}
                      </div>
                    </div>
                    <div className={styles.sellerInfoItem}>
                      <div className={styles.sellerInfoHeader}>Product Received On</div>
                      <div>
                        {sellerShipmentInfo?.receivedDate}
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
             </>
           )}
           
           {["Offline"].includes(campaign?.campaignType) && (
              <>
                <Panel className={styles.panelWrap}>
                  <PanelHeader
                    className={styles.sellerInfoHeader}
                    title="Appointment Information"
                  />
                  {sellerShipmentInfo && sellerShipmentInfo.appointmentCount <= 0 && (
                    <>
                     <div className={styles.btnActionContainer}>
                         <span> Influencer not yet arrange appointment with you through whatapps, try chat with influencer to arrange the appointment</span>
                          <br/>
                     </div>
                    </>
                  )}
                  <div className={styles.sellerInfoContainer}>
                    <div className={styles.sellerInfoRow}>
                      <div className={clsx(styles.sellerInfoItem)}>
                        <div className={styles.sellerInfoHeader}>Status</div>
                        <div>
                          {sellerShipmentInfo?.status}
                        </div>
                      </div>
                      <div className={clsx(styles.sellerInfoItem)}>
                        <div className={styles.sellerInfoHeader}>Person In Charge</div>
                        <div>
                          {sellerShipmentInfo?.whatappsContact}
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              </>
           )}

           {["Standard","WebsiteReview"].includes(campaign?.campaignType) &&
            ["Manual"].includes(campaign?.shipmentType) && (
            <>
                    <Panel className={styles.panelWrap}>
                    <PanelHeader
                      className={styles.payoutInfoHeader}
                      title="Product Shipping"
                    />
                        <FormProvider {...formMethods}>
                            <form
                                className={styles.form}
                            >
                                <div className={styles.row}>             
                                    <Input
                                        name="shippingCompany"                             
                                        label="Shipping Name"
                                        className={styles.nameInput}
                                        defaultValue={sellerShipmentInfo?.shippingCompany}
                                    />                 
                                </div>
                                <div className={styles.row}>
                                    <Input
                                        name="trackingCode"
                                        label="Tracking Code"                             
                                        className={styles.nameInput}
                                        defaultValue={sellerShipmentInfo?.trackingCode}
                                    />
                                </div>
                            </form>
                        </FormProvider>
                </Panel>
            </>
           )}

           {["Standard","WebsiteReview"].includes(campaign?.campaignType) &&
            ["VoucherCode"].includes(campaign?.shipmentType) && (
            <>
                    <Panel className={styles.panelWrap}>
                    <PanelHeader
                      className={styles.payoutInfoHeader}
                      title="Product Shipping"
                    />
                        <FormProvider {...formMethods}>
                            <form
                                className={styles.form}
                            >
                                <div className={styles.row}>             
                                    <Input
                                        name="shippingCompany"                             
                                        label="Voucher Name"
                                        className={styles.nameInput}
                                        defaultValue={sellerShipmentInfo?.shippingCompany}
                                    />                 
                                </div>
                                <div className={styles.row}>
                                    <Input
                                        name="trackingCode"
                                        label="Voucher Code"                             
                                        className={styles.nameInput}
                                        defaultValue={sellerShipmentInfo?.trackingCode}
                                    />
                                </div>
                            </form>
                        </FormProvider>
                </Panel>
            </>
           )}

        </div>
        </div>

        <footer className={styles.footer} hidden={isLoading || viewOnly}>
          {["Standard","WebsiteReview"].includes(campaign?.campaignType)  &&
           ["VoucherCode", "Manual"].includes(campaign?.shipmentType) && (
              <>
                <SixtyButton
                    type="submit"
                    onClick={formMethods.handleSubmit(onUpdateShipment)}
                    className={styles.approveBtn}
                    disabled={shipmentLoading}
                >
                    Update
                </SixtyButton>
              </>
           )}

            <SixtyButton
                variant="warning"
                onClick={onCancel}
                className={styles.ca}
                disabled={shipmentLoading}
            >
                Close
            </SixtyButton>

        </footer>
      </div>
    </React.Fragment>
  );
};

export default InfluencerShipmentDetails;
