import { Controller } from "react-hook-form";
import Input from "components/SixtyInput";

export default function ControlledInput({
  control,
  name,
  handleChange,
  label,
  rules,
  error,
  defaultValue,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, ...rest }) => (
        <>
          <Input
            onChange={(e) => {
              onChange(e.target.value);
              handleChange && handleChange(name, e.target.value);
            }}
            label={label}
            error={error}
            {...props}
            {...rest}
          />
        </>
      )}
    ></Controller>
  );
}
