import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import ProductsList from "./components/ProductsList";
import PaymentDetails from "./components/PaymentDetails";
import RefundSummary from "./components/RefundInfo";
import CustomerDetails from "./components/CustomerDetails";
import RefundPageHeader from "./components/RefundPageHeader";
import PanelHeader from "components/PanelHeader";
import Button from "components/SixtyButton";
import { useParams } from "react-router-dom";
import Modal from "components/Modal";
import {
  useAchieveOrder,
  useGetOrderDetail,
  useMarkOrdersAsShipped,
  useOrderRefund,
  useUpdateOrederShipping,
} from "queries/order";
import Loader from "components/Loader";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import { format } from "date-fns";
import ErrorModal from "components/Error";

const RefundDetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data: orderData, isLoading, isError } = useGetOrderDetail(id);

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Panel className={styles.panelWrap}>
            <Loader />
          </Panel>
        </div>
      ) : (
        orderData &&
        !isLoading &&
        !isError && (
          <div className={styles.orderDetailsPage}>
            <RefundPageHeader
              title={"#" + orderData?.transactionCode}
              onGoBack={onGoBack}
              status={orderData?.status}
              id={id}
            />
            <div className={styles.row}>
              <div className={styles.orderLeftContainer}>
                <Panel className={styles.panelWrap}>
                  <PanelHeader title="Products" />
                  <ProductsList products={orderData?.items} />
                </Panel>
                <Panel className={styles.panelWrap}>
                  <PanelHeader title="Payment">
                  </PanelHeader>
                  <PaymentDetails paymentDetail={orderData?.paymentDetail} />
                </Panel>
              </div>
              <div className={styles.orderRightContainer}>
                <Panel
                  className={[styles.panelWrap, styles.orderInfoPanel].join(
                    " "
                  )}
                >
                  <div>
                    <PanelHeader title="Order info" />
                    <RefundSummary data={orderData} />
                  </div>
                </Panel>
                <Panel
                  className={[
                    styles.panelWrap,
                    styles.customerDetailsPanel,
                  ].join(" ")}
                >
                  <div>
                    <PanelHeader title="Customer" />
                    <CustomerDetails data={orderData?.customerDetail} />
                  </div>
                </Panel>
              </div>
            </div>

          </div>
        )
      )}
    </>
  );
};

export default RefundDetailsPage;
