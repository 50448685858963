import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import ProductRequestTable from "./components/ProductRequestTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import ProductRequestDetail from "./components/ProductRequestDetails";
import Tabs from "components/Tabs";
import { useGetPayoutStatusCount } from "queries/admin";

const ProductRequests = () => {

  const [isRequestDetailOpen, setRequestDetailOpen] = useState(false);
  const history = useHistory();
  const requestDetailRef = useRef();
  const [selectedRequest, setSelectedRequest] = useState({});

  const RequestDetailTitle = () => {
    return (
      <div className={styles.productRequestDetailModalTitleBar}>
        <div className={styles.productRequestNameContainer}>
          <div>
            <Avatar name={selectedRequest.sellerName} />
          </div>
          <div className={styles.modalTitle}>{selectedRequest.sellerName}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setRequestDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onRequestClick = (value) => {
    setSelectedRequest(value);
    setRequestDetailOpen(true);
  };

  return (
    <div>
      <GridHeader header="Creators product Requests" 
         renderButtons={() => (
          <>
          </>
         )}
      />
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Pending",
              TabComponent: ProductRequestTable,
              props: {
                status: "Pending",
                onRequestClick: onRequestClick
              },
            },
            {
              tabId: "2",
              label: "Shipment",
              TabComponent: ProductRequestTable,
              props: {
                status: "Approved",
                onRequestClick: onRequestClick
              },
            },
            {
                tabId: "3",
                label: "Completed",
                TabComponent: ProductRequestTable,
                props: {
                    status: "Completed",
                    onRequestClick: onRequestClick
                },
            },
          ]}
        ></Tabs>
      </Panel>
      {isRequestDetailOpen && (
        <Modal
          isOpen={isRequestDetailOpen}
          onAttemptClose={() => {
            // history.push("/admin/productRequests");
            setRequestDetailOpen(false);
          }}
          modalRef={requestDetailRef}
          Title={RequestDetailTitle}
        >
          <ProductRequestDetail
            selectedRequest={selectedRequest}
            handleClose={() => setRequestDetailOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProductRequests;
