import GeneralReport from "../../../GeneralReport";
import styles from "./index.module.css";
import LineChart from "components/Charts/LineChart";
import { formatCurrency } from "utils/common";

// function getChartData() {
//     let currentMonthLabels = [];
//     let previousMonthLabels = [];
//     salesData.forEach(data => {
//         currentMonthLabels.push(Object.keys(data)[0]);
//         previousMonthLabels.push(Object.keys(data)[1]);
//     });
//     let data = [];
//     for (var i = 0; i < salesData.length; i++) {
//         const salesObject = salesData[i];
//         const currentMonthValue = Object.keys(salesObject).map(d => salesObject[d])[0];
//         const previousMonthValue = Object.keys(salesObject).map(d => salesObject[d])[1];
//         data.push({
//             label: currentMonthLabels[i],
//             value: currentMonthValue / 250,
//             previousMonthLabel: previousMonthLabels[i],
//             previousMonthValue: previousMonthValue / 250,
//             getOrginalValue: (value) => {
//                 return 'RM' + value * 250 * 1000
//             }
//         });
//     }
//     return data;
// }

const TotalSalesChart = ({ data }) => {
  // let data = [];

  // data.push({
  //     data: getChartData()
  // });

  // const getYaxislabel = (label) => {
  //     switch (label) {
  //         case 0:
  //             return '0';
  //         case 1:
  //             return '250K';
  //         case 2:
  //             return '500K';
  //         case 3:
  //             return '750K';
  //         case 4:
  //             return '1M';
  //         default:
  //             return '';
  //     }
  // }

  return (
    <div className={styles.totalSalesChartContainer}>
      <div className={styles.row}>
        <GeneralReport
          title="Total sales"
          currentMonthCount={`RM${data?.totalSales}`}
          unit="%"
          currentMonthNewRecord={data?.totalSalesPerformance}
          showProfitInNewLine={true}
        />
        <GeneralReport
          title="Gross sales"
          currentMonthCount={`RM${data?.grossSales}`}
          currentMonthNewRecord={data?.grossSalesPerformance}
          unit="%"
          showProfitInNewLine={true}
        />
        <GeneralReport
          title="Net sales"
          currentMonthCount={`RM${data?.netSales}`}
          currentMonthNewRecord={data?.netSalesPerformance}
          unit="%"
          showProfitInNewLine={true}
        />
        <GeneralReport
          title="Orders"
          currentMonthCount={data?.orders}
          currentMonthNewRecord={data?.ordersPerformance}
          unit="orders"
          showProfitInNewLine={true}
        />
        <GeneralReport
          title="Discounts"
          currentMonthCount={data?.discounts}
          currentMonthNewRecord={data?.discountsPerformance}
          showProfitInNewLine={true}
        />
        <GeneralReport
          title="Returns"
          currentMonthCount={`RM${data?.returns}`}
          currentMonthNewRecord={data?.returnsPerformance}
          unit="%"
          showProfitInNewLine={true}
        />
        <GeneralReport
          title="Shipping fees"
          currentMonthCount={`RM${data?.shippingFees}`}
          currentMonthNewRecord={data?.shippingFeesPerformance}
          unit="%"
          showProfitInNewLine={true}
        />
        <GeneralReport
          title="Tax"
          currentMonthCount={`RM${data?.tax}`}
          currentMonthNewRecord={data?.taxPerformance}
          unit="%"
          showProfitInNewLine={true}
        />
      </div>
      <div className={styles.subText}>
        The different calculated based on comparable number from the previous
        month
      </div>
      {/* <div>
                <LineChart
                    id="totalSalesChart"
                    data={data[0].data}
                    color="#3E517A"
                    getYaxislabel={(label) => getYaxislabel(label)}
                    className={styles.chartHelper}
                />
            </div> */}
    </div>
  );
};

export default TotalSalesChart;
