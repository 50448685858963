import { Controller } from "react-hook-form";
import CheckBox from "components/SixtyCheckBox";

export default function ControlledCheckBox({
  control,
  name,
  handleChange,
  label,
  defaultValue,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value, ...rest }) => (
        <>
          <CheckBox
            onChange={(e) => {
              onChange(e.target.checked);
              handleChange && handleChange(name, e.target.checked);
            }}
            label={label}
            checked={!!value}
            defaultValue={!!value}
            {...props}
            {...rest}
          />
        </>
      )}
    ></Controller>
  );
}
