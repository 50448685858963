import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useImmer } from "utils/hooks/useImmer";
import useRowSelectionTable from "utils/hooks/useRowSelectionTable";
import { useGetCustomers } from "queries/customer";
import _isEmpty from "lodash/isEmpty";

import Table, {
  RowSelectionContainer,
  TableHeader,
} from "components/SixtyTable";
import { ReactComponent as CustomerIcon } from "assets/icons/Icon-Customers.svg";
import Avatar from "components/Avatar";
import EmptyGrid from "components/EmptyGrid";
import SixtyText from "components/SixtyText";
import SixtyButton from "components/SixtyButton";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import Filter from "components/SixtyTable/components/Filter";
import Sort from "components/SixtyTable/components/Sort";
import CustomersSort from "./components/Sort";
import Loader from "components/Loader";
import { PanelActionButton } from "components/SixtyPanel";

import styles from "./index.module.css";
import ErrorModal from "components/Error";
import { unknown_Error } from "common/constants";

export default function CustomersTable({ onCustomerClick }) {
  const onCustomerSelect = useCallback(
    (customer) => {
      if (onCustomerClick) {
        onCustomerClick(customer?.original);
      }
    },
    [onCustomerClick]
  );

  const [filter, setFilter] = useImmer({});
  const [search, setSearch] = useState("");
  const handleSearch = (value) => setSearch(value);
  const [sort, setSort] = useImmer("orderByDescending");

  const onFilterChange = (filterVal) => {
    // component filter state set
    setFilter(() => filterVal);
  };
  const handleSortChange = useCallback(
    (field) => {
      setSort(field);
    },
    [setSort]
  );

  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    return {
      ...filter,
      [sort]: true,
      keyword: search,
      startFrom,
      limit,
    };
  }, [filter, search, sort, startFrom]);

  const {
    data: customersData,
    isLoading,
    isError,
    refetch,
    error,
  } = useGetCustomers(getFilters());

  const onRowClick = (row, e) => {
    onCustomerSelect(row);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, row }) => (
          <div className={styles.customerNameCell}>
            <div>
              <Avatar name={value} />
            </div>
            <div className={styles.customerNameValue}>{value}</div>
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Mobile number",
        accessor: "contact",
      }
    ],
    []
  );

  let data = useMemo(
    () => (customersData?.result ? customersData.result : []),
    [customersData]
  );

  const tableInstance = useRowSelectionTable({ data, columns });

  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  const [errorOpen, setErrorOpen] = useState(false);
  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (_isEmpty(filter) && !search && customersData?.result?.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      {(!Array.isArray(customersData?.result) || isDataEmpty()) && !isLoading && (
        <>
          <EmptyGrid
            Icon={CustomerIcon}
            emptyMessage={
              isError
                ? "Error in fetching data"
                : "Your customers will show up here"
            }
          />
          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}
      {!isError && !isDataEmpty() && (
        <>
          {tableInstance.selectedFlatRows.length > 0 ? (
            <RowSelectionContainer
              renderRowSelectionText={() => (
                <>
                  <SixtyText>
                    {tableInstance.selectedFlatRows.length} products selected
                  </SixtyText>
                  <SixtyButton
                    className={styles.unselectButton}
                    variant="text"
                    onClick={() => tableInstance.toggleAllRowsSelected(false)}
                  >
                    unselect all
                  </SixtyButton>
                </>
              )}
              renderRowSelectionButtons={() => (
                <>
                  {/* <SixtyButton>Approved</SixtyButton>
                  <SixtyButton variant="warning">Reject</SixtyButton>
                  <SixtyButton variant="secondary">Archive</SixtyButton> */}
                </>
              )}
            />
          ) : (
            <>
              <TableHeader>
                <SearchFilter
                  search={search}
                  onSearchChange={handleSearch}
                  placeholder="Search for customers"
                />
                {/* <Filter
                  filter={filter}
                  setFilter={onFilterChange}
                  //   renderFilter={SellerFilter}
                  columnLayout
                />
                <Sort
                  render={CustomersSort}
                  state={sort}
                  handleSortChange={handleSortChange}
                /> */}
              </TableHeader>
              {/* {renderFilterChips(filterState, setFilterState)} */}
            </>
          )}
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(customersData?.result) && (
              <Table
                instance={tableInstance}
                totalItems={customersData?.totalRecords}
                startFrom={startFrom}
                limit={limit}
                setStart={setStart}
                onRowClicked={onRowClick}
              />
            )
          )}
        </>
      )}
    </>
  );
}
