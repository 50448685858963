import React, { useState, useRef, useEffect }  from "react";
import styles from "./index.module.css";
import { ReactComponent as GoBackIcon } from "assets/icons/Icon-Back-Button.svg";
import Button from "components/SixtyButton";
import SixtySelectField from "components/SixtySelect";
import { setToast } from "components/Toast";
import {
  useMarkVendorRefundComplete,
} from "queries/admin";

const RefundPageHeader = ({
  title,
  onGoBack,
  status,
  id
}) => {

  const {
    mutateAsync: markOrderComplete,
    isSuccess: isRefundSuccess,
    isLoading: isRefundLoading
  } = useMarkVendorRefundComplete();
  
  useEffect(() => {
    if (isRefundSuccess) {
      setToast("Order marked as complete successfully");
    }
  }, [isRefundSuccess]);

  const markAsRefund = async () => {
    try {
      await markOrderComplete([id]);
    } catch (error) {
      setToast(`Failed order ${id} mark as complete`, "error");
    }
  };

  return (
    <div className={styles.orderHeader}>
      <div className={styles.titleBox}>
        <div onClick={onGoBack}>
          <GoBackIcon />
        </div>
        <div className={styles.titleContent}>{title}</div>
      </div>
      <div className={styles.ButtonsContainer}>
        <div>
          <Button
            className={styles.markAsShippedBtn}
            label="Mark as complete"
            variant={
              ["Processing", "Achieved", "Shipped", "Completed"].includes(status)
                ? "secondary"
                : "primary"
            }
            onClick={markAsRefund}
            disabled={isRefundLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default RefundPageHeader;
