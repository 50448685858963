import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useGetPostingContentDetail, useDownloadPostMedia } from "queries/posting";
import{ useGetInstagramMediaInsights } from "queries/socialMedia";
import { useDownloadMedia } from "queries/media";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Button from "components/SixtyButton";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import { useHistory } from "react-router-dom";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";

const ReportingDetail = ({ selectedPost, handleClose }) => {

  const role = localStorage.getItem("userRole");
  const { id, socialMediaId, sellerCode, postUrl } = selectedPost;

  const { data: postInfo, isLoading, isError, refetch } = useGetPostingContentDetail(
    id
  );

  // const { data: insights, 
  //         isLoading: insightLoading, 
  //         isError: insightError, 
  //         refetch: insightRefetch } =  useGetInstagramMediaInsights({ 
  //             url: postUrl,
  //             mediaId: socialMediaId,
  //             code: sellerCode
  //       });
  

  const {
         mutateAsync: downloadMedia,
         isLoading: exporting,
  } = useDownloadPostMedia();

  const {
    mutateAsync: downloadMediaByPath,
    isLoading: downloadingMedia,
  } = useDownloadMedia();


  const onDownload = async () => {    
    setToast("Downloading Media");
    if (!exporting) await downloadMedia(id);
  };

  const onDownloadInsightReport = async () => {    
    setToast("Downloading Report");

    let model = {
      path: postInfo?.reportingUrl
    };

    if (!downloadingMedia) await downloadMediaByPath(model);
  };

  const onPreview = () => {    
     const path = `${postInfo?.previewUrl}`;
     window.open(path, "_blank")
  };

  const onViewPost = () => {    
    window.open(postInfo?.postLink, "_blank")
  };

  return (
      <>
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
        {(isLoading) && (
          <div style={{width:800, height:500}} >
            <Loader/>
          </div>
        )}

      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
        <Panel className={styles.panelWrap}>
        <PanelHeader
                className={styles.campaignInfoHeader}
                title="Reporting"
        />
         <div className={styles.btnActionContainer}>
            {/* {postInfo?.status == 'Completed' && (
                <SixtyButton
                  variant="tertiary"
                  onClick={() => onPreview()}
                  className={styles.approveBtn}
                  disabled={postInfo?.isSuspendContent ? true : false}
                >
                  {"Preview Web Store"}
              </SixtyButton>
            )} */}
            {postInfo?.postLink && (
              <SixtyButton
                variant="secondary"
                onClick={() => onViewPost()}
                className={styles.approveBtn}
                >
                  {"View Social Media Post"}
              </SixtyButton>
            )}
          </div>
          <div className={styles.subHeader}>
                <span> Media </span>
                 <div className={styles.profPhotoContainer}>
                  <div className={styles.profPhotoDropzone}>
                  {postInfo?.postLink && postInfo?.postLink?.includes('instagram') && (
                    <>
                   <iframe 
                     src={postInfo?.postLink + "embed"} 
                     width="400" 
                     height="480" 
                     frameborder="0" 
                     scrolling="no" 
                     allowtransparency="true" />
                     </>
                  )}

                  </div>
                </div> 
           
            </div>
        </Panel>
        <Panel className={styles.panelWrap}>
        <PanelHeader
                className={styles.campaignInfoHeader}
                title="Insights Report"
        />
          <div className={styles.subHeader}>               
                {postInfo?.reportingUrl ? 
                 (<>
                    <span> Report screenshot </span>
                    <div className={styles.profPhotoContainer}>
                        <div className={styles.profPhotoDropzone}>
                        <img
                          src={postInfo?.reportingUrl}
                          className={clsx(styles.img)}
                          alt="No preview"
                        />
                        </div>
                        <div className={styles.actionButtonsContainer}>
                            <div onClick={onDownloadInsightReport} disabled={downloadingMedia}>
                              <Button label="Download" variant="secondary" />
                            </div>
                        </div>
                  </div>             
                 </>) : 
                 (<>
                    <p>{'Pending from creator'}</p>
                 </>)}
            </div>
        </Panel>
        <Panel className={styles.panelWrap}>
             <PanelHeader
                className={styles.campaignInfoHeader}
                title="Post Info"
              />
              <div className={styles.subHeader}>
              <div className={styles.campaignInfoContainer}>
                <div className={styles.campaignInfoRow}>
                  <div className={styles.campaignInfoItem}>
                    <div className={styles.campaignInfoHeader}>
                      Content Type
                    </div>
                    <div>
                      {postInfo?.contentType}
                    </div>
                  </div>
                  <div className={styles.campaignInfoItem}>
                    <div className={styles.campaignInfoHeader}>Uploaded by</div>
                    <div>
                      {postInfo?.uploadBy}
                    </div>
                  </div>
                  <div className={styles.campaignInfoItem}>
                    <div className={styles.campaignInfoHeader}>Created Date</div>
                    <div>
                      {postInfo?.createdDate}
                    </div>
                  </div>
                </div>
              </div>
              </div>
        </Panel>
          {/* <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title={postInfo?.contentType + " Insights"}
            />
            <div className={styles.subHeader}>
              {insights?.errorMessage ? (
                <>
                  <p>{insights?.errorMessage}</p>
                </>
              ) : (
                <>
                  <div className={styles.campaignInfoContainer}>
                    <div className={styles.campaignInfoRow}>
                      <div className={styles.campaignInfoItem}>
                        <div className={styles.campaignInfoHeader}>
                          Reach
                        </div>
                        <div>
                          {0}
                        </div>
                      </div>
                      <div className={styles.campaignInfoItem}>
                        <div className={styles.campaignInfoHeader}>Impressions</div>
                        <div>
                          {0}
                        </div>
                      </div>
                      <div className={styles.campaignInfoItem}>
                        <div className={styles.campaignInfoHeader}>Content Interaction</div>
                        <div>
                          {0}
                        </div>
                      </div>
                    </div>
                    <div className={styles.campaignInfoRow}>
                      <div className={styles.campaignInfoItem}>
                        <div className={styles.campaignInfoHeader}>
                          Likes
                        </div>
                        <div>
                          {0}
                        </div>
                      </div>
                      <div className={styles.campaignInfoItem}>
                        <div className={styles.campaignInfoHeader}>Comments</div>
                        <div>
                          {0}
                        </div>
                      </div>
                      <div className={styles.campaignInfoItem}>
                        <div className={styles.campaignInfoHeader}>Shares</div>
                        <div>
                          {0}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Panel> */}
      
        </div>
      </div>

      <footer className={styles.footer} hidden={isLoading}>
       
          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
          >
            Close
          </SixtyButton>

      </footer>
      </div>
    </React.Fragment>
    </>
  );
};

export default ReportingDetail;
