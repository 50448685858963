import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useGetPostingContentDetail, useUpdateStatus, useDownloadPostMedia, useSuspendOrActivateContent } from "queries/posting";
import { useGetSellerRegistrationDetail } from "queries/seller";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Button from "components/SixtyButton";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import { useHistory } from "react-router-dom";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import ContentRejectForm from "./components/ContentRejectForm"; 
import ContentRepostForm from "./components/ContentRepostForm";
import { Link } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';

const CampaignContentDetail = ({ selectedPost, handleClose, viewOnly = false }) => {

  const [isRejectFormOpen, setRejectFormOpen] = useState(false);
  const rejectFormRef = useRef();
  const [selectedRejectPost, setSelectedRejectPost] = useState({});
  const role = localStorage.getItem("userRole");

  const { data: sellerInfo, isLoading : isSellerloading, refetch : sellerRefetch } = useGetSellerRegistrationDetail(
    selectedPost?.sellerCode
  );

  const RejectPostTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedRejectPost.title} />
          </div>
          <div className={styles.modalTitle}>{selectedRejectPost.title}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setRejectFormOpen(false)}
          />
        </div>
      </div>
    );
  };

  const [isRepostFormOpen, setRepostFormOpen] = useState(false);
  const repostFormRef = useRef();
  const [selectedRepost, setSelectedRepost] = useState({});

  const RepostTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedRepost?.title} />
          </div>
          <div className={styles.modalTitle}>{selectedRepost.title}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setRepostFormOpen(false)}
          />
        </div>
      </div>
    );
  };

  const { id } = selectedPost;

  const { data: postInfo, isLoading, isError, refetch } = useGetPostingContentDetail(
    id
  );

  const { mutateAsync: updateStatus, 
          isLoading : statusUpdating 
        } = useUpdateStatus();

  const { mutateAsync: SuspendOrActivateContent, 
          isLoading : suspendLoading 
        } = useSuspendOrActivateContent();

  const {
         mutateAsync: downloadMedia,
         isLoading: exporting,
        } = useDownloadPostMedia();

    const onSuspendOrActivate = async () => {
      try {
      
        var result = await SuspendOrActivateContent(postInfo?.id);

        if(result){
           refetch();
           setToast("successfully update status");
           handleClose();
        }
       
     } catch (e) {
        reportError(
          "error in : onApproved: onSubmit, error: ",
          e
        );
     }
  };

  const onApproved = async () => {
    try {

        const model = {
            id: postInfo?.id,
            status: "Approved"
         };
 
         var result = await updateStatus(model);

         if(result){
            refetch();
            setToast("successfully update status");
            handleClose();
         }
        
    } catch (e) {
      reportError(
        "error in : onApproved: onSubmit, error: ",
        e
      );
    }
  };

  const onRejected = async () => {
    setSelectedRejectPost(selectedPost);
    setRejectFormOpen(true);
  };

  const onComplete = async () => {
    try {

        const model = {
            id: postInfo?.id,
            status: "Completed"
         };
 
         var result = await updateStatus(model);

         if(result){
            refetch();
            setToast("successfully update status");
            handleClose();
         }
        
    } catch (e) {
      reportError(
        "error in : onApproved: onSubmit, error: ",
        e
      );
    }
  };

  const onRepost = async (data) => {
    setSelectedRepost(selectedPost);
    setRepostFormOpen(true);
  };

  const onDownload = async () => {    
    // setToast("Downloading Media");
    // if (!exporting) await downloadMedia(id);

     const path = `https://snapinsta.app/`;
     window.open(path, "_blank")
  };

  const onPreview = () => {    
     const path = `${postInfo?.previewUrl}`;
     window.open(path, "_blank")
  };

  const onViewPost = () => {    
    window.open(postInfo?.postLink, "_blank")
  };

  const onDownloadRatingReview = () => {    
    const path = `${postInfo?.ecommerceReviewUrl}`;
    window.open(path, "_blank")
 };

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    let innerText = ``;
    if (postInfo?.postLink)
    innerText = `${postInfo?.postLink}`;

    textField.innerText = innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (innerText !== "") setToast("Link copied to clipboard");
    else setToast("No content to copy", "error");
};

  return (
      <>
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
          {postInfo?.postLink && (
            <>
              <Panel className={styles.panelWrap}>
              <div className={styles.btnActionContainer}>

                  {postInfo?.postLink && (
                    <SixtyButton
                      variant="secondary"
                      onClick={() => onViewPost()}
                      className={styles.approveBtn}
                      >
                      {"View on Social Media"}
                    </SixtyButton>
                  )}

              </div>
                    <PanelHeader
                    className={styles.campaignInfoHeader}
                    title="Media"
                    />

                    <div className={styles.profPhotoContainer}>
                      <div className={styles.profPhotoDropzone}>
                      {/* <img
                        src={postInfo?.postImageUrl}
                        className={clsx(styles.img)}
                        alt="No preview"
                      /> */}
                      {postInfo?.postLink && postInfo?.postLink?.includes('instagram') && (
                        <>
                      <iframe 
                        src={postInfo?.postLink + "embed"} 
                        width="400" 
                        height="480" 
                        frameborder="0" 
                        scrolling="no" 
                        allowtransparency="true" />
                        </>
                      )}
                      </div>
                      {postInfo?.postLink && postInfo?.postLink?.includes('instagram') && (
                        <div className={styles.actionButtonsContainer}>
                        <div onClick={copyToClipboard}>
                          <Button label="Copy Link" variant="secondary" />
                        </div>
                        <div onClick={onDownload} disabled={exporting}>
                          <Button label="Download" variant="secondary" />
                        </div>
                        </div>
                      )}

                    </div>
              </Panel>
            </>
          )}

         {postInfo?.postLink && (
          <>
             <Panel className={styles.panelWrap}>
              <PanelHeader
                  className={styles.campaignInfoHeader}
                  title="Link"
                />
                <div className={styles.campaignInfoContainer}>
                  <div className={styles.campaignInfoRow}>
                    <div className={styles.campaignInfoItem}>
                      <div className={styles.campaignInfoHeader}>Uploaded link</div>
                      <div>
                            <a className={styles.link} href={postInfo?.postLink} target={"_blank"} rel={"noreferrer"}>
                                  {postInfo?.postLink}
                              </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
          </>
         )}

    
         
         {postInfo?.ecommerceReviewUrl && (
          <>
              <Panel className={styles.panelWrap}>
              <PanelHeader
                  className={styles.campaignInfoHeader}
                  title="Ratings & Review"
              />
                            {postInfo?.ecommerceReviewUrl ? 
                      (<>
                          <div className={styles.profPhotoContainer}>
                              <div className={styles.profPhotoDropzone}>
                              <img
                                src={postInfo?.ecommerceReviewUrl}
                                className={clsx(styles.img)}
                                alt="No preview"
                              />
                              </div>
                              <div className={styles.actionButtonsContainer}>
                                  <div onClick={onDownloadRatingReview}>
                                    <Button label="Download" variant="secondary" />
                                  </div>
                              </div>
                        </div>             
                      </>) : 
                      (<>
                          <p>{'Pending from creator'}</p>
                      </>)}
              </Panel>
          </>
         )}


          <Panel className={styles.panelWrap}>
          <PanelHeader
              className={styles.campaignInfoHeader}
              title="Post Info"
            />
            {sellerInfo?.contactNumber && (
              <div className={styles.btnActionContainer}>             
              <ReactWhatsapp number={sellerInfo?.contactNumber} message="" className={clsx(styles.button, styles.tertiary)}>
                Chat With Influencers
              </ReactWhatsapp>
              </div>
            )}
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Status
                  </div>
                  <div>
                    {postInfo?.status}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Uploaded by</div>
                  <div>
                    {postInfo?.uploadBy}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Created Date</div>
                  <div>
                    {postInfo?.createdDate}
                  </div>
                </div>
              </div>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Content Type
                  </div>
                  <div>
                    {postInfo?.contentType}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Campaign</div>
                  <div>
                    {postInfo?.campaign}
                  </div>
                </div>
                {/* {postInfo?.status == 'Completed' &&(
                    <div className={styles.campaignInfoItem}>
                    <div className={styles.campaignInfoHeader}>Web Store Visibility </div>
                    <div>
                      {postInfo?.isSuspendContent ? 'Suspended' : 'Active'}
                      
                    </div>
                  </div>
                )} */}
              </div>
               {/* <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoFullWidthItem}>
                  <div className={styles.campaignInfoHeader}>
                    Caption
                  </div>
                  <div>
                    {postInfo?.description}
                  </div>
                </div>
              </div>  */}
            </div>
          </Panel>

          <Panel className={styles.panelWrap}>
            
          <PanelHeader
              className={styles.campaignInfoHeader}
              title="Selected Products"
          />
           <div className={styles.subHeader}>
                <span> Products </span>
                <React.Fragment>
                     {postInfo && postInfo?.products?.length > 0 && (
                         <div className={styles.profPhotoContainer}>
                            {postInfo?.products.map((img) => (
                                <div key={img.productId} className={styles.profPhotoDropzone}>
                                  <a href={img?.productUrl} target="_blank">
                                  <img
                                       src={img?.imageUrl}
                                       className={clsx(styles.img)}
                                       alt={img?.name}
                                     />
                                  </a>   
                                  <span>{img?.name}</span>
                                 </div>
                             ))}
                         </div>
                      )}
                 </React.Fragment>
           </div>
          </Panel>
      
        </div>
      </div>

      <footer className={styles.footer} hidden={isLoading}>

          {postInfo && postInfo?.status == 'Pending' && !postInfo?.isSuspendContent && (   
              <>         
            <SixtyButton
               type="submit"
               onClick={onApproved}
               className={styles.approveBtn}
               disabled={statusUpdating}
             >
               {"Approve"}
             </SixtyButton>
             <SixtyButton
                type="submit"
                variant="warning"
                onClick={onRejected}
                className={styles.approveBtn}
                disabled={statusUpdating}
             >
               {"Revise"}
             </SixtyButton>
            </>
          )}

          {/* {postInfo && postInfo?.isPosted == false && postInfo?.status == 'Approved' && !postInfo?.isSuspendContent && (   
              <>         
             <SixtyButton
                type="submit"
                variant="warning"
                onClick={onRejected}
                className={styles.approveBtn}
                disabled={statusUpdating}
             >
               {"Revise"}
             </SixtyButton>
            </>
          )} */}

          {postInfo && postInfo?.status == 'Rejected' && !postInfo?.isSuspendContent && (   
              <>         
            <SixtyButton
               type="submit"
               onClick={onApproved}
               className={styles.approveBtn}
               disabled={statusUpdating}
             >
               {"Approve"}
             </SixtyButton>
            </>
          )}

          {postInfo && role == 'InternalUser' && postInfo?.status == 'Approved' && !postInfo?.isSuspendContent && (   
              <>         
             <SixtyButton
               type="submit"
               onClick={onComplete}
               className={styles.approveBtn}
               disabled={statusUpdating}
             >
               {"Complete"}
             </SixtyButton>
             <SixtyButton
                type="submit"
                variant="warning"
                onClick={onRepost}
                className={styles.approveBtn}
                disabled={statusUpdating}
             >
               {"Revise Social Media Post"}
             </SixtyButton>
            </>
          )}

          {postInfo && postInfo?.status == 'Repost' && !postInfo?.isSuspendContent && (   
              <>         
            <SixtyButton
               type="submit"
               onClick={onComplete}
               className={styles.approveBtn}
               disabled={statusUpdating}
             >
               {"Complete"}
             </SixtyButton>
            </>
          )}

          {role == 'InternalUser' && (
              <SixtyButton
                 variant= {postInfo?.isSuspendContent ? "tertiary" : "secondary"}
                 className={styles.approveBtn}
                 onClick={onSuspendOrActivate}
                 disabled={statusUpdating || suspendLoading}
              >
             {postInfo?.isSuspendContent ? 'Activate' : 'Trash'}
             </SixtyButton> 
          )}
          
          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={statusUpdating}
          >
            Close
          </SixtyButton>

        </footer>
      </div>
    </React.Fragment>
    {isRejectFormOpen && (
        <Modal
          isOpen={isRejectFormOpen}
          onAttemptClose={() => {
            setRejectFormOpen(false);
          }}
          modalRef={rejectFormRef}
          Title={RejectPostTitle}
        >
          <ContentRejectForm
            selectedContent={selectedRejectPost}
            handleClose={() => setRejectFormOpen(false)}
          />
        </Modal>
    )}
    {isRepostFormOpen && (
        <Modal
          isOpen={isRepostFormOpen}
          onAttemptClose={() => {
            setRepostFormOpen(false);
          }}
          modalRef={repostFormRef}
          Title={RepostTitle}
        >
          <ContentRepostForm
            selectedContent={selectedRepost}
            handleClose={() => setRepostFormOpen(false)}
          />
        </Modal>
    )}
    </>
  );
};

export default CampaignContentDetail;
