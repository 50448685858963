import React, { useRef } from "react";
import styles from "./index.module.css";
import { ReactComponent as CopyContentsIcon } from "assets/icons/Icon-Copy-Content.svg";
import SixtyButton from "components/SixtyButton";
import { setToast } from "components/Toast";

const CustomerDetails = ({ data }) => {
  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    let innerText = ``;
    if (data?.shippingAddress?.buildingName)
      innerText = `${innerText}${data?.shippingAddress.buildingName}
      `;
    if (data?.shippingAddress?.street1)
      innerText = `${innerText}${data?.shippingAddress.street1}
      `;
    if (data?.shippingAddress?.street2)
      innerText = `${innerText}${data?.shippingAddress.street2}
      `;
    if (data?.shippingAddress?.street3)
      innerText = `${innerText}${data?.shippingAddress.street3}
      `;
    if (data?.shippingAddress?.buildingName)
      innerText = `${innerText}${data?.shippingAddress.buildingName}
      `;
    if (data?.shippingAddress?.postcode && data?.shippingAddress?.city)
      innerText = `${innerText}${data?.shippingAddress?.postcode}, ${data?.FshippingAddress.city}
      `;
    if (data?.shippingAddress?.state && data?.shippingAddress?.country)
      innerText = `${innerText}${data?.shippingAddress.state}, ${data?.shippingAddress.country}
      `;
    textField.innerText = innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (innerText !== "") setToast("Address copied to clipboard");
    else setToast("No content to copy", "error");
  };

  const copyCustomerToClipboard = () => {
    const textField = document.createElement("textarea");
    let innerText = ``;
    if (data?.customerName)
      innerText = `${innerText}${data?.customerName}
    `;
    if (data?.email)
      innerText = `${innerText}${data?.email}
    `;
    if (data?.contact) innerText = `${innerText}${data?.contact}`;

    textField.innerText = innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (innerText !== "") setToast("Customer copied to clipboard");
    else setToast("No content to copy", "error");
  };

  return (
    <React.Fragment>
      <div className={styles.customerBody}>
        <div className={styles.customerInfo}>
          <div>
            <span className={styles.customerName}>{data?.customerName}</span>
            {data?.orderCount && (
              <span className={styles.orderCount}>
                {data?.orderCount + " orders"}
              </span>
            )}
          </div>
          {data?.email && (
            <div className={styles.customerEmail}>
              <span>{data?.email}</span>
              <span>
                <CopyContentsIcon onClick={copyCustomerToClipboard} />
              </span>
            </div>
          )}
          {data?.contact && (
            <div className={styles.customerPhone}>{data?.contact}</div>
          )}
        </div>
        {(data?.shippingAddress?.buildingName ||
          data?.shippingAddress?.street1 ||
          data?.shippingAddress?.street2 ||
          data?.shippingAddress?.street3 ||
          data?.shippingAddress?.postcode ||
          data?.shippingAddress?.city ||
          data?.shippingAddress?.state ||
          data?.shippingAddress?.country) && (
          <div className={styles.shippingAddress}>
            <div className={styles.customerFieldName}>Shipping address</div>
            <div className={styles.customerFieldValue}>
              <div className={styles.addressContent}>
                {data?.shippingAddress?.buildingName && (
                  <div>{data?.shippingAddress?.buildingName}</div>
                )}
                {data?.shippingAddress?.street1 && (
                  <div>{data?.shippingAddress?.street1},</div>
                )}
                {data?.shippingAddress?.street2 && (
                  <div>{data?.shippingAddress?.street2},</div>
                )}
                {data?.shippingAddress?.street3 && (
                  <div>{data?.shippingAddress?.street3},</div>
                )}
                {data?.shippingAddress?.postcode &&
                  data?.shippingAddress?.city && (
                    <div>
                      {data?.shippingAddress?.postcode}{" "}
                      {data?.shippingAddress.city},
                    </div>
                  )}
                {data?.shippingAddress?.state &&
                  data?.shippingAddress?.country && (
                    <div>
                      {data?.shippingAddress?.state}{" "}
                      {data?.shippingAddress.country}
                    </div>
                  )}
              </div>
              <span>
                <CopyContentsIcon onClick={copyToClipboard} />
              </span>
            </div>
          </div>
        )}
        {(data?.billingAddress.buildingName ||
          data?.billingAddress.street1 ||
          data?.billingAddress.street2 ||
          data?.billingAddress.postcode ||
          data?.billingAddress.city ||
          data?.billingAddress.state ||
          data?.billingAddress.country) && (
          <div className={styles.billingAddress}>
            <div className={styles.customerFieldName}>Billing address</div>
            <div className={styles.customerFieldValue}>
              {data?.billingAddress && (
                <div className={styles.addressContent}>
                  {data?.billingAddress.buildingName && (
                    <div>{data?.billingAddress.buildingName}</div>
                  )}
                  {data?.billingAddress.street1 && (
                    <div>{data?.billingAddress.street1},</div>
                  )}
                  {data?.billingAddress.street2 && (
                    <div>{data?.billingAddress.street2},</div>
                  )}
                  {data?.billingAddress.street3 && (
                    <div>{data?.billingAddress.street3},</div>
                  )}
                  {data?.billingAddress.postcode && data?.billingAddress.city && (
                    <div>
                      {data?.billingAddress.postcode}{" "}
                      {data?.billingAddress.city},
                    </div>
                  )}
                  {data?.billingAddress.state && data?.billingAddress.country && (
                    <div>
                      {data?.billingAddress.state}{" "}
                      {data?.billingAddress.country}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CustomerDetails;
