import React from "react";
import styles from "./index.module.css";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import { ReactComponent as IconSingleImageDrop } from "assets/icons/Icon-SingleImageDrop.svg";
import Button from "components/SixtyButton";
import { useFormContext, useWatch } from "react-hook-form";
import Input from "components/FormComponents/ControlledInput";
import { useGetBusinessTypeDropdown } from "queries/vendor";
import { RegexExpressions } from "common/constants";
import { isArray } from "lodash";
import Select from "components/FormComponents/ControlledSelect";

const BusinessInfoForm = () => {
  const { control, errors, setValue } = useFormContext();
  const { data: businessTypeData } = useGetBusinessTypeDropdown();

  const businessTypes = businessTypeData?.length
    ? businessTypeData.map((option) => ({
      label: option.name,
      value: option.key,
    }))
    : [];

  const path = useWatch({
    control,
    name: "path",
    defaultValue: [],
  });
  const onCompanyLogoDelete = () => {
    setValue("path", null);
  };
  const imageSelected = typeof(path) === 'string' ?
  [{
    path: path
  }] :
  isArray(path) ? path : [];
  return (
    <React.Fragment>
      <div className={styles.subHeader}>
        <span> Company logo </span>
        <div className={styles.companyLogoContainer}>
          <div className={styles.companyLogoDropzone}>
            <ImageDropzone
              control={control}
              name="path"
              showButton
              buttonLabel="Upload"
              DefaultThumb={IconSingleImageDrop}
              maximumFiles={1}
              onDropAccepted={(name, selectedFiles) => {
                setValue("path", selectedFiles);
              }}
              selectedFiles={imageSelected}
              // imageClassName={styles.avatar}
              noClick
              rounded
              noName
            />
          </div>
          {imageSelected?.length > 0 && (
            <div className={styles.actionButtonsContainer}>
              <div onClick={onCompanyLogoDelete}>
                <Button label="Delete" variant="secondary" />
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className={styles.formRow}>
          <Input
            name="storeName"
            control={control}
            rules={{ required: "required" }}
            label="Store name in 60SEC"
            error={errors.storeName}
            defaultValue=""
          />
          <Input
            name="customerSupportEmail"
            rules={{
              pattern: {
                value: RegexExpressions.email,
                message: "Invalid email",
              },
              required: { value: true, message: "Customer support email is required" },
            }}
            control={control}
            label="Customer support email"
            error={errors.customerSupportEmail}
            defaultValue=""
          />
        </div>
        <div className={styles.formRow}>
          <Input
            name="companyContact"
            control={control}
            rules={{
              pattern: {
                value: RegexExpressions.phone,
                message: "Please enter a valid format '800-555-1234'",
              },
              required: { value: true, message: "Phone number is required" },
            }}
            error={errors.companyContact}
            label="Phone number"
            defaultValue=""
          />
        <Select
            name="businessType"
            control={control}
            label="Industry"
            options={businessTypes} 
            defaultOption={control.getValues("businessType")}
            defaultValue={control.getValues("businessType")} 
            handleChange={(name, value) => setValue("businessType", value)}
            fullWidth={true}         
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default BusinessInfoForm;
