import React, { useState, useRef } from "react";
import Button from "components/SixtyButton";
import styles from "./index.module.css";
import Modal from "components/Modal";
import { useHistory } from "react-router-dom";
import TopupCreditForm from "../TopupCreditForm";
import { formatCurrency } from "utils/common";

const BalanceDetails = ({ balance }) => {
  const [isTopupModalOpen, seTopupModalOpen] = useState(
    false
  );
  const history = useHistory();
  const balanceDetailRef = useRef();

  const Title = () => {
    return (
      <div className={styles.balanceDetailsTitleBar}>
        <div className={styles.modalTitle}>Top-up your credits</div>
      </div>
    );
  };

  const onTopupCredit = () => {
    seTopupModalOpen(true);
  };

  const onTopupCreditCancel = () => {
    seTopupModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.leftContainer}>
          <div className={styles.rowHeader}>Credits</div>
          <div className={styles.rowValue}>{formatCurrency(balance || 0)}</div>
        </div>
        <div className={styles.rightContainer}>
          <Button
            className={styles.inviteSellerBtn}
            label="Top-up"
            onClick={onTopupCredit}
          />
        </div>
      </div>
      {isTopupModalOpen && (
        <Modal
          isOpen={isTopupModalOpen}
          onAttemptClose={() => {
            history.push("/dashboard/walletbalances");
            seTopupModalOpen(false);
          }}
          modalRef={balanceDetailRef}
          Title={Title}
        >
          <TopupCreditForm
            handleClose={onTopupCreditCancel}
            availableBalance={balance}
          />
        </Modal>
      )}
    </div>
  );
};

export default BalanceDetails;
