import React from "react";

import Panel from "components/SixtyPanel";
import Button from "components/SixtyButton";
import DiscountTable from "./components/DiscountTable";
import GridHeader from "components/GridHeader";
import { useHistory } from "react-router-dom";

import styles from "./index.module.css";

const Discounts = () => {
  const history = useHistory();

  const onAddNewDiscount = () => {
    history.push("/dashboard/create-promotion")
  };

  return (
    <div>
      <GridHeader
        header="Promotions"
        renderButtons={() => (
          <>
            <Button onClick={onAddNewDiscount}>Add new promotion</Button>
          </>
        )}
      />
      <Panel>
        <div className={styles.discountTable}>
          <DiscountTable  />
        </div>
      </Panel>
    </div>
  );
};

export default Discounts;
