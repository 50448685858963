import Input from "components/SixtyInput";
import Checkbox from "components/SixtyCheckBox";
import {
  FilterGroup,
  FilterItem,
  FilterRow,
  FilterChipContainer,
  ChipItem,
} from "components/SixtyTable/components/Filter";

export const labelMap = {
  productLessThanValue: (state) => "Products less than: " + state,
  productMoreThanValue: (state) => "Products more than: " + state,
  filterByActive: (state) => "Active",
  filterByArchived: (state) => "Archived ",
};

export default function CategoryFilter({ state, setState }) {
  const handleInputChange = (field, value) => {
    setState((filter) => {
      if (value) filter[field] = value;
      else delete filter[field];
    });
  };

  return (
    <>
      <FilterRow>
        <FilterGroup label="By number of products" colWidth={3}>
          <FilterItem colWidth={2}>
            <Input
              label="Product less than"
              value={state?.productLessThanValue}
              type="number"
              onChange={(e) => {
                handleInputChange(
                  "productLessThanValue",
                  e.target.value
                );
              }}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <Input
              label="Product more than"
              value={state?.productMoreThanValue}
              type="number"
              onChange={(e) => {
                handleInputChange(
                  "productMoreThanValue",
                  e.target.value
                );
              }}
            />
          </FilterItem>
        </FilterGroup>
      </FilterRow>

      <FilterRow>
        <FilterGroup label="Status" colWidth={2}>
          <FilterItem colWidth={2}>
            <Checkbox
              label="Active"
              name={"filterByActive"}
              checked={!!state["filterByActive"]}
              onChange={(e) => {
                handleInputChange("filterByActive", e.target.checked);
              }}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <Checkbox
              label="Archived"
              name={"filterByArchived"}
              checked={!!state["filterByArchived"]}
              onChange={(e) => {
                handleInputChange("filterByArchived", e.target.checked);
              }}
            />
          </FilterItem>
        </FilterGroup>
      </FilterRow>
    </>
  );
}

export function renderFilterChips(filterState, setFilterState) {
  let filtersPresent = false;
  for (const filterKey in filterState) {
    if (!!filterState[filterKey]) {
      filtersPresent = true;
    }
  }

  const removeFilter = (filterItem) => {
    setFilterState((filter) => {
      delete filter[filterItem];
    });
  };

  if (filtersPresent) {
    return (
      <FilterChipContainer>
        {Object.keys(filterState).length > 0 &&
          Object.keys(filterState).map((filterItem) => (
            <>
              <ChipItem
                label={labelMap[filterItem](filterState[filterItem])}
                name={filterItem}
                handleRemove={removeFilter}
              />
            </>
          ))}
      </FilterChipContainer>
    );
  }
  return null;
}
