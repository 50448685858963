import { useMutation } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from 'components/Toast';
import React, { useState, useEffect, useRef  } from "react";
import { saveAs } from "file-saver";

export const useProgressUploads = () => {

  const [isSuccess, setIsSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState("");

  const uploadMedia = async (files) => {

   let response = await api
    .post(Endpoints.Media.Uploads, files, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        setProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    })

    if(response?.data?.result){
      setIsSuccess(true)
      setResult(response?.data?.result[0].path);
    }
  };

  return { uploadMedia, isSuccess, progress, result };
};

export function useUploads() {
  return useMutation(
    (files) =>
      api
        .post(Endpoints.Media.Uploads, files, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUploads: useMutation: onError: error", error);
      },
    }
  );
}

export function useUpload(config = {}) {
  return useMutation(
    (files) =>
      api
        .post(Endpoints.Media.Upload, files, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpload: useMutation: onError: error", error);
      },
      ...config,
    }
  );
}

export function useDownloadMedia() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Media.DownloadMedia, data, {
          responseType: "blob",
          "Accept-Encoding": "gzip, deflate, br",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
          ?.split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();
        let url = window.URL.createObjectURL(new Blob([response.data]));
        saveAs(url, filename);
        }),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useDownloadMedia: useMutation: onError: error",
          error
        );
      },
    }
  );
}
