import React, { useState } from "react";
import styles from "./index.module.css";
import { ReactComponent as GoBackIcon } from "assets/icons/Icon-Back-Button.svg";
import Button from "components/SixtyButton";
import SixtySelectField from "components/SixtySelect";
import Avatar from "components/Avatar";

const SellerPageHeader = ({
  title,
  onGoBack,
  onEdit,
  onDeactivate,
  status,
}) => {
  const [moreActionsValue, setMoreActionsValue] = useState("");
  // Delete hidden for this release
  const getMoreActions = () => {
    if (status && status === "Approved") {
      return [
        {
          label: "Deactivate",
          value: "deactivate",
        },
      ];
    }
    if (status && status === "Suspended") {
      return [
        {
          label: "Activate",
          value: "activate",
        },
      ];
    }
    return [];
  };
  const onSelectAction = (value) => {
    if (value === "deactivate") {
      onDeactivate();
    } else if (value === "activate") {
      onDeactivate();
    }
  };

  return (
    <div className={styles.sellerHeader}>
      <div className={styles.titleBox}>
        <div onClick={onGoBack}>
          <GoBackIcon />
        </div>
        <div className={styles.sellerAvatar}>
          <Avatar name={title} />
        </div>
        <div className={styles.titleContent}>{title}</div>
      </div>
      <div className={styles.ButtonsContainer}>
        <div>
          <Button
            className={styles.actionButtons}
            variant="secondary"
            label="Edit"
            onClick={onEdit}
          />
        </div>
        <div>
          <SixtySelectField
            options={getMoreActions()}
            defaultOption="More actions"
            value={moreActionsValue}
            selectBoxHelperClass={styles.moreActionsSelectBox}
            variant="secondary"
            size="regular"
            selectBoxFontHelper={styles.selectBoxText}
            optionBoxFontHelper={styles.optionBoxText}
            onChange={(value) => {
              setMoreActionsValue(value);
              onSelectAction(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SellerPageHeader;
