import React from 'react';
import styles from './index.module.css';
import useOnClickOutside from '../../utils/hooks/useOnClickOutside';
import useOnEscape from '../../utils/hooks/useOnEscape';
import PropTypes from 'prop-types';

const Flyout = ({
    isOpen,
    flyoutRef,
    onAttemptClose,
    helperClass,
    ...props
}) => {
    const handleEsc =
        () => {
            if (
                isOpen &&
                onAttemptClose
            ) {
                onAttemptClose();
            }
        };

    useOnClickOutside(flyoutRef, () => onAttemptClose());
    useOnEscape((e) => handleEsc());

    return (
        <div
            ref={flyoutRef}
            role="dialog"
            className={[
                styles.flyout,
                helperClass || '',
                !isOpen ? styles.flyoutHidden : '',
            ].join(' ')}
        >
            <div className={styles.flyoutContent}>
                {props.children}
            </div>
        </div>
    );
}

Flyout.propTypes = {
    isOpen: PropTypes.bool,
    flyoutRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    onAttemptClose: PropTypes.func,
    helperClass: PropTypes.string
}

export default Flyout;
