import React, { useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import SellerPageHeader from "./components/SellerPageHeader";
import PanelHeader from "components/PanelHeader";
import AddressDetails from "./components/SellerAddress";
import BrandTraffic from "./components/BrandTraffic";
import clsx from "clsx";
import Summary from "./components/Summary";
import SellerInfo from "./components/SellerInfo";
import MediaDetails from "./components/SocialMediaDetails";
import { ReactComponent as IconArrowRight } from "assets/icons/Icon-Arrow-Right.svg";
import Modal from "components/Modal";
import { useGetSellerDetails, useSuspendOrActivateSeller, useApproveSellerRegistration, useRestrictAccount, useUnlockAccountRestriction } from "queries/admin";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import ReactWhatsapp from 'react-whatsapp';

const SellerAnalyticPage = () => {

  const history = useHistory();
  const params = useParams();

  const { data: sellerInfo, isLoading, isError, refetch } = useGetSellerDetails(
    params.id
  );

  const { mutateAsync: restricAccount, isLoading: restricLoading } = useRestrictAccount();
  const { mutateAsync: unlockAccountRestriction, isLoading: unlockLoading } = useUnlockAccountRestriction();
  const { mutateAsync: updateSellerStatus, isLoading: statusLoading } = useSuspendOrActivateSeller();
  const { mutateAsync: approvaSeller, isLoading: approveLoading } = useApproveSellerRegistration();

  const onGoBack = () => {
    history.goBack();
  };

  const onRestricAccount = async (id) => {
    try {

      const result = await restricAccount(params.id);
      if (result) {
        setToast("Account Restricted");
        refetch();
      }
    } catch (error) {
      reportError("onRestricAccount Error: ", error);
      setToast("Error while updating influncer status", "error");
    }
  };

  const onUnlockAccountRestriction= async (id) => {
    try {

      const result = await unlockAccountRestriction(params.id);
      if (result) {
        setToast("Account Restriction Unlocked");
        refetch();
      }
    } catch (error) {
      reportError("onUnlockAccountRestriction Error: ", error);
      setToast("Error while updating influncer status", "error");
    }
  };

  const onStatusUpdate = async (id) => {
    try {
      const action = sellerInfo?.status;
      const result = await updateSellerStatus(params.id);
      if (result) {
        setToast(
          `Successfully ${
            action === "Suspended" ? "activate" : "suspend"
          } influencer`
        );
        refetch();
      }
    } catch (error) {
      reportError("updateSellerStatus: onDeactivate: Error: ", error);
      setToast("Error while updating influncer status", "error");
    }
  };

  const onApprovalUpdate = async (id) => {
    try {

      const result = await approvaSeller(params.id);
      if (result) {
        setToast(
          `Successfully ${
            "approve"
          } influencer`
        );
        refetch();
      }
    } catch (error) {
      reportError("onApprovalUpdate: onDeactivate: Error: ", error);
      setToast("Error while updating influncer status", "error");
    }
  };


  const Title = () => {
    return (
      <div>
        <div className={styles.modalTitle}>Edit Seller details</div>
      </div>
    );
  };

  return (
    <div className={styles.sellerDetailsPage}>
      <SellerPageHeader
        title={sellerInfo?.name}
        onGoBack={onGoBack}
        onEdit={onStatusUpdate}
        onActivate={onApprovalUpdate}
        onRestrictAccount={onRestricAccount}
        onUnlockAccountRestriction={onUnlockAccountRestriction}
        status={sellerInfo?.status}
        loadingStatus={statusLoading || approveLoading}
        pendingApproval={sellerInfo?.pendingApproval}
        accountRestricted={sellerInfo?.restrictedAccount}
        restrictLoading={restricLoading}
        unlockLoading={unlockLoading}
        code={params.id}
        contact={sellerInfo?.contactNumber}
      />
      <div className={styles.row}>
        <div className={styles.sellerDetailsLeftContainer}>
          <Panel className={clsx(styles.panelWrap, styles.firstPanel)}>
            <PanelHeader title="Summary" />
            <Summary data={sellerInfo?.analytics} />
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader title="Social media" />
            <MediaDetails socialMediaListing={sellerInfo?.socialMediaChannels} />
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader title="Brand traffic" />
            <BrandTraffic code={params?.id} />
          </Panel>

        </div>
        <div className={styles.sellerDetailsRightContainer}>
          {sellerInfo && (
            <React.Fragment>
              <Panel
                className={[styles.panelWrap, styles.sellerInfoPanel].join(" ")}
              >
                <PanelHeader title="Influencer info" />
                <SellerInfo data={sellerInfo} />
              </Panel>
              <Panel
                className={[styles.panelWrap, styles.sellerInfoPanel].join(" ")}
              >
                <PanelHeader title="Influencer's Address" />
                <AddressDetails data={sellerInfo} />
              </Panel>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default SellerAnalyticPage;
