import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import TaxesTable from "./components/TaxesTable";
import AddTaxForm from "./components/AddTaxForm";
import GridHeader from "components/GridHeader";

const TaxesSettings = (props) => {
  const [isAddTaxOpen, setAddTaxOpen] = useState(false);
  const history = useHistory();
  const addShippingRef = useRef();

  const Title = () => {
    return (
      <div className={styles.modalTitleContainer}>
        <div className={styles.modalTitle}>New tax group</div>
      </div>
    );
  };

  const onAddTax = () => {
    setAddTaxOpen(true);
  };

  const onExport = () => {};

  const handleClose = () => {
    setAddTaxOpen(false);
  };

  return (
    <div>
      <GridHeader
        header="Tax group"
        renderButtons={() => (
          <>
            <Button onClick={onAddTax}>Add tax group</Button>
          </>
        )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.table}>
          <TaxesTable />
        </div>
      </Panel>
      {isAddTaxOpen && (
        <Modal
          isOpen={isAddTaxOpen}
          onAttemptClose={() => {
            history.push("/dashboard/settings/taxes");
            setAddTaxOpen(false);
          }}
          modalRef={addShippingRef}
          Title={Title}
        >
          <AddTaxForm handleClose={handleClose}/>
        </Modal>
      )}
    </div>
  );
};

export default TaxesSettings;
