import React, { useState, useRef } from "react";
import {
  useGetMemberBilling,
  useRenewSubscription,
  useCancelMemberSubscription,
  useGetCustomerDefaultPaymentMethod,
  useActivateCancelledSubscription
} from "queries/subscription";
import { getFormattedDateString } from "utils/date.util";
import { PaymentUrl } from "common/constants";
import Button from "components/SixtyButton";
import SixtyText from "components/SixtyText";
import styles from "./index.module.css";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Loader from "components/Loader";
import { useHistory } from "react-router-dom";
import AddEditCardDetailsForm from "../AddEditCardDetailsForm";
import Modal from "components/Modal";

const BillingPlan = () => {

  const history = useHistory();
  const { data, isLoading } = useGetMemberBilling();
  const [isCanceled, setCancelled] = useState(false);

  const [isAddCardModalOpen, setAddCardModalOpen] = useState(false);
  const addCardModalRef = useRef();

  const Title = () => {
    return (
        <div className={styles.modalTitleBar}>
           <div className={styles.modalTitle}>Subscription</div>
        </div>
    )
  };

  const updateCreditCard = () => {
    setAddCardModalOpen(true);
  };

  const handleCreditCardClose = () => {
    setAddCardModalOpen(false);
  };
  
  const { 
    mutateAsync: renewPlan,  
    isLoading: renewLoading
  } = useRenewSubscription();

  const { 
    mutateAsync: cancelPlan,  
    isLoading: cancelLoading
  } = useCancelMemberSubscription();

  const { 
    mutateAsync: activatePlan,  
    isLoading: activateLoading
  } = useActivateCancelledSubscription();

  const onPlanRenew = async () => {
    const result = await renewPlan();
    if (result?.id) {
      window.open(PaymentUrl(result.id), "_blank");
    }
  };

  const onPlanSubscribe = async () => {
    const path = `/pricing-plan`;
    history.push(path);
  };

  const onCancelPlan = async () => {
    try {

        const model = {
            companyId: data?.companyId,
            referenceSubscriptionId: data?.subscriptionId,
            referencePaymentSubscriptionId: data?.subscriptionPaymentId,
        }       
        
        var result = await cancelPlan(model);

        if(result){
          setToast("SUBSCRIPTION CANCELLED");
          setCancelled(true);
        }
        
    } catch (e) {
      reportError(
        "error in : CampaignForm: onSubmit, error: ",
        e
      );
    }
  };

  const onActivatePlan = async () => {
    try {

      const path = `/dashboard/settings/billing/plans`;
      history.push(path);
        
    } catch (e) {
      reportError(
        "error in : BillingPlan: onActivatePlan, error: ",
        e
      );
    }
  };

  const onChangePlan = async () => {
    try {

      const path = `/dashboard/settings/billing/plans`;
      history.push(path);
        
    } catch (e) {
      reportError(
        "error in : BillingPlan: onChangeNewPlan, error: ",
        e
      );
    }
  };

  return (
    <div className={styles.container}>
       {isLoading && (
           <Loader/>
        )}
      {!isLoading && (
        <div className={styles.row}>
          <div className={styles.leftContainer}>
            <div className={styles.rowHeader}>Your plan</div>
            <div className={styles.rowValue}>{data?.name}</div>
            <div className={styles.rowValueDetails}>
              {/* {(data?.amount || 0) +
                ` per ${data?.period !== 12 ? data?.period : ""} ${
                  data?.period === 12 ? "annual" : "months"
                }`}{" "}
              ·{" "} */}
              
              {(data?.amount || 0)}
              {data.subscriptionType == "lifetime" ? ` forever ` : (" per " + data.period + " " + data.subscriptionType) + "   "}
              {data?.membershipExpiredDate &&
                `Expires on : ${getFormattedDateString(
                  data?.membershipExpiredDate,
                  "MMM d, y"
                )}`}    
            </div>
            <div className={styles.rowValueDetails}>
                {data.subscriptionType == "lifetime" ? "Subscribe to unlock more feature" : "The subscription non refundable"}
            </div>
          </div>
          <div className={styles.rightContainer}>
            {data && data.subscriptionPaymentId && data.subscriptionCancelled === false && (
              <>
                 <Button
                    className={styles.planUpgradeBtn}
                    label="Cancel Subscription"
                    disabled={cancelLoading || isCanceled}
                    variant="warning"
                    onClick={onCancelPlan}
                 />
                 <Button
                    className={styles.planUpgradeBtn}
                    label="Upgrade plan"
                    variant="tertiary"
                    onClick={onChangePlan}
                 />
              </>
            )}
            {data && data.subscriptionPaymentId && data.subscriptionCancelled === true && (
                 <Button
                    className={styles.planUpgradeBtn}
                    label="Activate Subscription"
                    onClick={onActivatePlan}
                />
            )}
            {data && !data.subscriptionPaymentId && (
               <Button
                  className={styles.planUpgradeBtn}
                  label="Subscribe"
                  disabled={renewLoading}
                  onClick={onPlanSubscribe}
               />
            )}
            {data && data.subscriptionPaymentId && (
               <Button
                   label="Update Card"
                   variant="secondary"
                   onClick={updateCreditCard}
               />
            )}

          </div>

          {isAddCardModalOpen && (
         <Modal
            isOpen={isAddCardModalOpen}
            onAttemptClose={() => {
               history.push("/dashboard/settings/billing");
               setAddCardModalOpen(false);
            }}
                modalRef={addCardModalRef}
                Title={Title}
          >
          <AddEditCardDetailsForm
              handleCancel={handleCreditCardClose}
          />
         </Modal>
     )}
        </div>
      )}

    </div>
  );
};

export default BillingPlan;
