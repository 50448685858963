import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import TotalSalesPageHeader from "./components/TotalSalesPageHeader";
import TotalSalesChart from "./components/TotalSalesChart";
import TotalSalesTable from "./components/TotalSalesTable";
import { useGetOrderSummary, useGetTotalSalesFullReport } from "queries/report";
import format from "date-fns/format";
import Loader from "components/Loader";

const TotalSalesDetailsPage = () => {
  const history = useHistory();

  const [month, setMonth] = useState(new Date());
  const onGoBack = () => {
    history.goBack();
  };

  const { data, isLoading, isSuccess } = useGetTotalSalesFullReport(
    format(month, "MM/yyyy")
  );

  return (
    <div className={styles.totalSalesDetailsPage}>
      <TotalSalesPageHeader
        title={"Total Sales"}
        onGoBack={onGoBack}
        month={month}
        setMonth={setMonth}
      />
      <div className={styles.row}>
        {(isLoading || isSuccess) && (
          <Panel className={styles.panelWrap}>
            {isLoading ? (
              <Loader />
            ) : isSuccess ? (
              <TotalSalesChart data={data} />
            ) : null}
          </Panel>
        )}
      </div>
      <div className={styles.row}>
        <TotalSalesTable month={month} />
      </div>
    </div>
  );
};

export default TotalSalesDetailsPage;
