import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import CampaignDraftTable from "../components/CampaignDraftTable";
import CampaignContentDetail from "../components/CampaignContentDetails";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import BasicPageHeader from "../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetCampaignPublishContentRequestCount } from "queries/posting";
import { useGetCampaignAnalytics } from "queries/campaign";

const CampaignDraft = () => {
  const [isPostOpen, setPostOpen] = useState(false);
  const history = useHistory();
  const params = useParams();
  const postRef = useRef();
  const [selectedPost, setSelectedPost] = useState({});
  const role = localStorage.getItem("userRole");

  const { data: campaignData, isLoading: campaignLoading } = useGetCampaignAnalytics(params.id);

  const {
    data: requestData,
  } = useGetCampaignPublishContentRequestCount(params.id);

  const PostTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedPost.title} />
          </div>
          <div className={styles.modalTitle}>{selectedPost.title}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPostOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onContentClick = (value) => {
    setSelectedPost(value);
    setPostOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <BasicPageHeader title="Draft Content" onGoBack={onGoBack} />
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Pending Approval",
              recordsCount: requestData?.pendingDraftContentCount,
              TabComponent: CampaignDraftTable,
              props: {
                status: "Pending",
                onContentClick: onContentClick
              },
            },
            {
              tabId: "2",
              label: "Revised Content",
              recordsCount: requestData?.rejectedDraftContentCount,
              TabComponent: CampaignDraftTable,
              props: {
                status: "Rejected",
                onContentClick: onContentClick
              },
            },
            {
                tabId: "3",
                label: "Pending Post",
                recordsCount: requestData?.approvedDraftContentCount,
                TabComponent: CampaignDraftTable,
                props: {
                  status: "Approved",
                  onContentClick: onContentClick
                },
            },
          ]}
        ></Tabs>
      </Panel>
      {isPostOpen && (
        <Modal
          isOpen={isPostOpen}
          onAttemptClose={() => {
            setPostOpen(false);
          }}
          modalRef={postRef}
          Title={PostTitle}
        >
          <CampaignContentDetail
            selectedPost={selectedPost}
            handleClose={() => setPostOpen(false)}
            viewOnly={campaignData?.campaignType == 'AffiliateEvent' && role == 'Supplier'}
          />
        </Modal>
      )}
    </div>
  );
};

export default CampaignDraft;
