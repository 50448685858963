import React, { useState, useEffect, useRef } from "react";
import { Controller, useFieldArray, useFormContext, useWatch, useForm, FormProvider } from "react-hook-form";
import { useGetVideoStoryDropdown, useUpdateUgcCampaign, useGetCampaignDetail, useDeleteCampaign } from "queries/campaign";
import { useUploads } from "queries/media";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import ChipInput from "components/FormComponents/ControlledChipInput";
import SixtySelect from "components/SixtySelect";
import Checkbox from "components/SixtyCheckBox";
import DatePicker from "components/SixtyDatePicker";
import { format, parse } from "date-fns";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";
import SixtyButton from "components/SixtyButton";
import { setToast } from "components/Toast";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import { ReactComponent as IconSingleImageDrop } from "assets/icons/Icon-SingleImageDrop.svg";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import Button from "components/SixtyButton";
import Confirmation from "permissions/Confirmation";
import Modal from "components/Modal";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import { isEmpty, isArray } from 'lodash';
import EditForm  from './Components/EditForm';
import Loader from "components/Loader";

const defaultValues = {
    supplierReference: '',
    supplierCode: '',
    supplierName: '',
    supplierId: '',
    name: '',
    instruction: '',
    price: 0,
    priceType: '',
    isPublic: '',
    expiredDate: '',
    defaultCampaignProducts: [],
    campaignProducts: [],
    campaignSellers: []
 };

const EditCampaign = ({ selectedCampaign, handleClose }) => {

    const formMethods = useForm({
        defaultValues,
    });

    const { setValue } = formMethods;
    const { id } = selectedCampaign;
    const comfirmationRef = useRef();
    const [isComfirmationOpen, setComfirmationOpen] = useState(false);

    const { data: campaignInfo, isLoading, isError, isFetchedAfterMount, refetch } = useGetCampaignDetail(
      id
    );

    const ConfirmationTitle = () => {
      return (
        <div className={styles.modalTitleBar}>
          <div className={styles.modalContainer}>
            <div className={styles.modalTitle}>{'Confirmation'}</div>
          </div>
          <div>
            <CloseIcon
              className={styles.closeIcon}
              onClick={() => setComfirmationOpen(false)}
            />
          </div>
        </div>
      );
    };

    useEffect(() => {

        if (campaignInfo && isFetchedAfterMount) {
         let newDate = "";
         if (campaignInfo?.expiredDate) {
           let myDate = campaignInfo?.expiredDate.split("/");
           newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
         }
     
         let postingDate = "";
         if (campaignInfo?.postingExpiredOnUtc) {
           let myDate = campaignInfo?.postingExpiredOnUtc.split("/");
           postingDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
         }
     
         for (const [key, value] of Object.entries({
             id:campaignInfo?.id,
             name: campaignInfo?.name,
             socialMediaId: campaignInfo.socialMediaId,
             videoStoryId: campaignInfo.videoStoryId,
             campaignType: campaignInfo?.campaignType,
             totalCommission: campaignInfo?.totalCommission,
             instruction: campaignInfo?.instruction,
             price: campaignInfo?.price,
             priceType: campaignInfo?.priceType,
             isPublic: campaignInfo?.isPublic,
             expiredDate: campaignInfo?.expiredDate && newDate,
             status: campaignInfo?.status,
             applicantLimit: campaignInfo?.applicantLimit,
             mainImages: campaignInfo?.defaultCampaignImagePath,
             picContact: campaignInfo?.picContact,
             redemptionType: campaignInfo?.redemptionType,
             redemptionReferenceUrl: campaignInfo?.redemptionReferenceUrl,
             deliverableType: campaignInfo?.deliverableType,
             deliverableReferenceUrl: campaignInfo?.deliverableReferenceUrl,
             shipmentType: campaignInfo?.shipmentType,
             appointmentType: campaignInfo?.appointmentType,
             appointmentLocation: campaignInfo?.appointmentLocation,
             isRequiredPurchaseProduct: campaignInfo?.isRequiredPurchaseProduct,
             productPurchasePrice: campaignInfo?.productPurchasePrice,
             shippingCoveredCost: campaignInfo?.shippingCoveredCost,
             purchaseLink: campaignInfo?.purchaseLink,
             isCoveredShippingCost: campaignInfo?.isCoveredShippingCost,
             isRequiredEcommerceReview: campaignInfo?.isRequiredEcommerceReview,
             isRequiredDraftReview: campaignInfo?.isRequiredDraftReview,
             isProfitSharing: campaignInfo?.isProfitSharing,
             isRequiredOwnership: campaignInfo?.isRequiredOwnership,
             isAdsUsage: campaignInfo?.isAdsUsage,
             IsRequiredVideoEditing: campaignInfo?.IsRequiredVideoEditing,
             postingExpiredOnUtc: campaignInfo?.postingExpiredOnUtc && postingDate,
             tasks: campaignInfo?.tasks,
             guidelines: campaignInfo?.guidelines,
             additionalRewards: campaignInfo?.additionalRewards,
             campaignProducts: campaignInfo?.campaignProducts,
             campaignSellers: campaignInfo?.campaignSellers,
             race: campaignInfo?.recruitmentRequirement?.race,
             gender: campaignInfo?.recruitmentRequirement?.gender,
         })) {
           setValue(key, value);
         }
       }
     
    }, [setValue, campaignInfo, isFetchedAfterMount]);

    const { mutateAsync: deleteCampaign, 
       isLoading : deleteCampaignLoading 
    } = useDeleteCampaign();

    const onConfirmation = async () => {
        try {
          
              var result = await deleteCampaign(campaignInfo?.id);

              if(result){
                setToast("successfully delete campaign");
                handleClose();
              }
            
        } catch (e) {
          reportError(
            "error in : EditCampaign: onConfirmation, error: ",
            e
          );
        }
    };

    const onCloseConfirmation = () => {
      setComfirmationOpen(false);
    };

    const onDeleteCampaign = () => {
      setComfirmationOpen(true);
    };

    const { mutateAsync: uploadFiles,
            isLoading: uploadingFiles,
    } = useUploads();

    const { mutateAsync: updateCampaign, 
         isLoading : campaignLoading 
    } = useUpdateUgcCampaign();

    const onSubmit = async (data) => {
        try {
    
            var defaultCampaignImagePath = null;
            if (isArray(data.mainImages) && data.mainImages?.length > 0) {
              const images = new FormData();
              let hasNewImages = false;
              if (!data.mainImages[0]?.id) {
                images.append(`files[0].fileName`, data.mainImages[0].name);
                images.append("files[0].file", data.mainImages[0]);
                hasNewImages = true;
              }
        
              const result = hasNewImages ? await uploadFiles(images) : [];
        
              const path = result?.length ? result[0].path : '';
              defaultCampaignImagePath= path;
            }

            let recruitmentRequirement = {
              race: data.race,
              gender: data.gender
            };

            if(["Pending"].includes(campaignInfo?.topupStatus)){
                const model = {
                  id: id,
                  videoStoryId: data.videoStoryId,
                  name: data.name,
                  instruction: data.instruction,
                  defaultCampaignImagePath: defaultCampaignImagePath,
                  picContact: data.picContact,
                  applicantLimit: data.applicantLimit,
                  shipmentType: data.shipmentType,
                  isRequiredOwnership: data.isRequiredOwnership,
                  isAdsUsage: data.isAdsUsage,
                  isRequiredVideoEditing: data.isRequiredVideoEditing,
                  tasks: data.tasks,
                  guidelines: data.guidelines,
                  recruitmentRequirement: recruitmentRequirement
              };
  
              var response = await updateCampaign(model);
      
              if(response){
                  setToast("successfully update campaign");
                  refetch();
                  handleClose();
              }
            }
            else{
                const model = {
                  id: id,
                  videoStoryId: data.videoStoryId,
                  name: data.name,
                  instruction: data.instruction,
                  defaultCampaignImagePath: defaultCampaignImagePath,
                  picContact: data.picContact,
                  tasks: data.tasks,
                  guidelines: data.guidelines,
                  recruitmentRequirement: recruitmentRequirement
              };

              var response = await updateCampaign(model);
      
              if(response){
                  setToast("successfully update campaign");
                  refetch();
                  handleClose();
              }
            }
            
        } catch (e) {
          reportError(
            "error in : CampaignForm: onSubmit, error: ",
            e
          );
        }
      };

    return (
      <React.Fragment>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}
        <div className={styles.campaignRequestFormContainer} hidden={isLoading}>
         <Panel className={styles.panelWrap}>
            <PanelHeader
                className={styles.sellerInfoHeader}
                title="Create story for your product with creator"
            />
            {!["Paid"].includes(campaignInfo?.topupStatus) && (
                  <div className={styles.HeadContainer}>  
                    <SixtyButton
                       variant="warning"
                       size="Large"
                       className={styles.cancelButton}
                       onClick={onDeleteCampaign}
                       disabled={campaignLoading || deleteCampaignLoading}
                    >
                        Delete Your Order
                    </SixtyButton>
                  </div>
            )}
 
            {/* <FormProvider {...formMethods}>
              
            <form className={styles.form}>
                 <EditForm
                  selectedCampaign={campaignInfo}
                 />       
            </form>                      
            </FormProvider> */}
        </Panel>
        {/* <footer className={styles.footer}>           
                    <SixtyButton
                        type="submit"
                        onClick={formMethods.handleSubmit(onSubmit)}
                        className={styles.approveBtn}
                        disabled={campaignLoading || uploadingFiles}
                        >
                        {"Update"}
                    </SixtyButton>
                    <SixtyButton
                        variant="warning"
                        className={styles.cancelButton}
                        onClick={handleClose}
                        disabled={campaignLoading}
                    >
                        Close
                    </SixtyButton>
        </footer> */}
        {isComfirmationOpen && (
            <Modal
              isOpen={isComfirmationOpen}
              onAttemptClose={() => {
                setComfirmationOpen(false);
              }}
              modalRef={comfirmationRef}
              Title={ConfirmationTitle}
            >
            <Confirmation
              title={'Are you confirm to delete the campaign.'}
              handleSuccess={onConfirmation}
              handleClose={onCloseConfirmation}
            />
            </Modal>
         )}
    </div>
    </React.Fragment>
  );
};

export default EditCampaign;
