import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useAchieveProduct,
  useDeleteProduct,
  useGetProductDetail,
  useUpdateProducts,
} from "queries/product";
import { useImmer } from "utils/hooks/useImmer";
import clsx from "clsx";
import _cloneDeep from "lodash/cloneDeep";

import Panel from "components/SixtyPanel";
import ProductPageHeader from "./components/ProductPageHeader";
import PanelHeader from "components/PanelHeader";
import OrderStatus from "./components/OrderStatus";
import TopSellers from "./components/Topsellers";
import BasicInfo from "../components/BasicInfo";
import Media from "../components/DetailMedia";
import Pricing from "../components/Pricing";
import Inventory from "../components/Inventory";
import Shipping from "../components/Shipping";
import Options from "../components/Options";
import Variants from "../components/Variants";
import Loader from "components/Loader";
import SixtyButton from "components/SixtyButton";

import styles from "./index.module.css";
import { useUploads } from "queries/media";
import { setToast } from "components/Toast";

const defaultValues = {
  categoryId: "",
  taxGroupId: "",
  shippingGroupId: "",
  baseUomUnit: "Unit",
  skuCode: "",
  name: "",
  description: "",
  costPrice: undefined,
  sellingPrice: undefined,
  marketPrice: undefined,
  gotStockCount: false,
  weight: null,
  weightUom: "Kg",
  height: null,
  heightUom: "Cm",
  width: null,
  widthUom: "Cm",
  length: null,
  lengthUom: "Cm",
  barcode: null,
  ContinueSellingWithoutStock: false,
  status: "",
  categories: [],
  optionGroups: [],
  productImages: [],
  productVariants: [],
  productPriceTiers: [],
};

const ProductDetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();

  const [productDetails, setProductDetails] = useImmer(() => {});

  const { data, isLoading, isError } = useGetProductDetail(id);
  const {
    mutateAsync: uploadFiles,
    isLoading: uploadingFiles,
    // isError: uploadError,
  } = useUploads();

  const {
    mutateAsync: updateProducts,
    isLoading: updatingProduct,
    // isError: updateError,
  } = useUpdateProducts();

  const { mutateAsync: archiveProduct } = useAchieveProduct();
  const { mutateAsync: deleteProduct } = useDeleteProduct();

  // validation schema [------IMPORTANT------]
  const schema = yup.object().shape({
    name: yup.string().required("name is required"),
    description: yup.string(),
    status: yup.string().required("select the status of product"),
    costPrice: yup
      .number()
      .required("required")
      .min(0, "price should be valid")
      .nullable(),
    sellingPrice: yup
      .number()
      .required("required")
      .min(0, "price should be valid")
      .nullable(),
    marketPrice: yup
      .number()
      .required("required")
      .min(0, "price should be valid")
      .nullable(),
    taxGroupId: yup.string().nullable(),
    productPriceTiers: yup.array().of(
      yup.object().shape({
        PriceTierId: yup.string(),
        OverrideDiscountRate: yup.number().nullable(),
      })
    ),
    skuCode: yup.string().nullable(),
    barcode: yup.string().nullable(),
    gotStockCount: yup.boolean(),
    stockCount: yup.number().when("gotStockCount", {
      is: true,
      then: yup
        .number()
        .min(0, "stock count should be valid")
        .required("enter stock count"),
      otherwise: yup.number().nullable(),
    }),
    weight: yup.number().nullable(),
    height: yup.number().nullable(),
    width: yup.number().nullable(),
    length: yup.number().nullable(),
    optionGroups: yup.array(
      yup.object().shape({
        optionGroupName: yup.string().required("required"),
        options: yup
          .array(
            yup.object().shape({
              optionName: yup.string(),
            })
          )
          .required()
          .min(1, "add atleast one option or remove the option group"),
      })
    ),
    productVariants: yup.array().when("optionGroups", {
      is: (val) => Array.isArray(val) && val.length > 0,
      then: yup.array(
        yup.object().shape({
          skuCode: yup.string(),
          costPrice: yup.number(),
          sellingPrice: yup.number(),
          marketPrice: yup.number(),
          gotStockCount: yup.boolean(),
          stockCount: yup.number(),
          variantOptions: yup.array(
            yup.object().shape({
              optionGroupName: yup.string(),
              optionValueName: yup.string(),
            })
          ),
        })
      ),
      otherwise: yup.array(),
    }),
  });

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const { reset } = formMethods;

  useEffect(() => {
    if (!!data) {
      let productData = _cloneDeep(data);
      // split product images into main images and more images
      if (
        Array.isArray(productData.productImages) &&
        productData.productImages.length > 0
      ) {
        let mainImage = productData.productImages.find(
          (image) => image.position === 0
        );
        productData.mainImages = mainImage
          ? [mainImage]
          : productData.productImages.slice(0, 1);
        productData.moreImages = mainImage
          ? productData.productImages.filter((image) => image.position !== 0)
          : productData.productImages.slice(1);
      }
      if (productData?.optionGroups?.length > 0) {
        productData.optionGroups = productData.optionGroups.map((group) => ({
          optionGroupName: group.optionGroupName,
          options: group.options.map((option) => ({
            optionName: option.optionName,
          })),
        }));
      }

      if (productData?.productVariants?.length > 0) {
        productData.productVariants.forEach((variant) => {
          if (variant.imagePath) {
            variant.imagePath = [
              {
                path: variant.imagePath,
              },
            ];
          } else {
            variant.imagePath = [];
          }
        });
      }
      setProductDetails(() => productData);
    }
  }, [data, setProductDetails]);

  useEffect(() => {
    reset(productDetails);
  }, [productDetails, reset]);

  const onSubmit = async (formData) => {

    try {
      // if images are added, need to upload and get the links and avoid uploading existing images
      if (formData.mainImages?.length > 0) {
        const images = new FormData();
        let hasNewImages = false;
        let oldMain = [];
        if (!formData.mainImages[0]?.id) {
          images.append(`files[0].fileName`, formData.mainImages[0].name);
          images.append("files[0].file", formData.mainImages[0]);
          hasNewImages = true;
        } else {
          oldMain = formData.mainImages;
        }
        let oldImages = [];
        if (formData.moreImages?.length > 0) {
          let i = 0;
          formData.moreImages?.forEach((file, index) => {
            if (!file.id) {
              images.append(`files[${i}].fileName`, file.name);
              images.append(`files[${i}].file`, file);
              ++i;
              hasNewImages = true;
            } else {
              oldImages = [...oldImages, file];
            }
          });
        }

        const result = hasNewImages ? await uploadFiles(images) : [];

        // transform API result to addProducts API body format
        let imagesList = [
          ...(oldMain.length > 0 ? oldMain : [result[0]]),
          ...oldImages,
          ...(oldMain.length > 0 ? result : result.slice(1)),
        ];
        formData.productImages = imagesList
          ? imagesList.map((image, index) => ({
              name: image.fileName || image.name,
              path: image.path,
              position: index,
            }))
          : null;
      }

      if (formData.productPriceTiers?.length === 0)
        delete formData.productPriceTiers;

      if (formData.optionGroups?.length === 0) {
        delete formData.optionGroups;
        delete formData.productVariants;
      }

      if (formData.productVariants?.length > 0) {
        let variantImages = [];
        let variantImageList = new FormData();
        let currentIndex = 0;
        let oldImages = [];

        formData.productVariants?.forEach((variant, index) => {
          if (variant.imagePath.length > 0) {
            if (variant.imagePath[0].preview) {
              variantImages = [
                ...variantImages,
                { file: variant.imagePath[0], position: index },
              ];
              if (variant.imagePath[0].name) {
                variantImageList.append(
                  `files[${currentIndex}].fileName`,
                  variant.imagePath[0].name
                );
                variantImageList.append(
                  `files[${currentIndex}].file`,
                  variant.imagePath[0]
                );
                ++currentIndex;
              }
            } else {
              oldImages = [
                ...oldImages,
                { path: variant.imagePath[0].path, position: index },
              ];
            }
          } else {
            variant.imagePath = null;
          }
        });

        if (variantImages.length > 0) {
          const result = await uploadFiles(variantImageList);

          variantImages.forEach((image, i) => {
            let index = image.position;
            if (result[i])
              formData.productVariants[index].imagePath = result[i]?.path;
          });
        }
        if (oldImages.length > 0) {
          oldImages.forEach((img) => {
            let index = img.position;
            formData.productVariants[index].imagePath = img.path;
          });
        }
      }

      await updateProducts(formData);
    } catch (e) {
      console.error(e);
    }
  };

  const onGoBack = () => {
    history.goBack();
  };

  const onArchive = async () => {
    console.log("Clicked Option: Archive");
    const success = await archiveProduct(id);
    if (success) {
      history.push("/dashboard/products");
    }
  };

  const onDuplicate = () => {
    console.log("Clicked Option: Duplicate");
  };

  const onPreview = () => {
    console.log("Clicked Option: onPreview");
  };

  const onDelete = async () => {
    const success = await deleteProduct(id);
    if (success) {
      setToast("successfully deleted product");
      history.push("/dashboard/products");
    }
  };

  const options = formMethods.watch("optionGroups");

  const isValidOptions = () => {
    if (Array.isArray(options) && options.length > 0) {
      for (let i = 0; i < options.length; ++i) {
        if (options[i].options.length > 0) {
          return true;
        }
      }
      return false;
    }
  };

  return (
    <div className={styles.productDetailsPage}>
      {isLoading || uploadingFiles || updatingProduct ? (
        <div>
          <Panel className={clsx(styles.panelWrap)}>
            <div className={styles.loaderContainer}>
              <Loader
                text={
                  uploadingFiles
                    ? "Uploading files"
                    : updatingProduct
                    ? "Updating product data"
                    : null
                }
              />
            </div>
          </Panel>
        </div>
      ) : (
        data &&
        !isError && (
          <>
            <ProductPageHeader
              title={data?.name || ""}
              onGoBack={onGoBack}
              onArchive={onArchive}
              onDuplicate={onDuplicate}
              onPreview={onPreview}
              onDelete={onDelete}
            />
            <FormProvider {...formMethods}>
              <form
                onSubmit={formMethods.handleSubmit(onSubmit)}
                className={styles.form}
              >
                <div className={styles.row}>
                  <div className={styles.productLeftContainer}>
                    <Panel
                      className={clsx(styles.panelWrap, styles.firstPanel)}
                    >
                      <PanelHeader title="Basic info" />
                      <BasicInfo />
                    </Panel>
                    <Panel className={styles.panelWrap}>
                      <PanelHeader title="Media" />
                      <Media />
                    </Panel> 
                    <Panel className={styles.panelWrap}>
                      <PanelHeader title="Pricing" />
                      <Pricing 
                       priceTiers={data?.productPriceTiers}
                       variants={data?.productVariants}
                      />
                    </Panel>
                    {/* <Panel className={styles.panelWrap}>
                      <PanelHeader title="Inventory" />
                      <Inventory />
                    </Panel> */}
                    {/* <Panel className={clsx(styles.panelWrap, styles.lastPanel)}>
                      <PanelHeader
                        title="Shipping"
                        subTitle="Scale below are used to calculate shipping rates at checkout. Shipping address and method will be collected when customers checkout."
                        className={styles.shippingPanelHeader}
                      />
                      <Shipping />
                    </Panel> */}
                    {/* <Panel className={styles.panelWrap}>
                      <PanelHeader title="Options" />
                      <Options />
                    </Panel> */}
                    {/* {isValidOptions() && (
                      <Panel className={styles.panelWrap}>
                        <PanelHeader title="7. Variants" />
                        <Variants
                          options={options}
                          edit
                          editOptions={data?.optionGroups}
                        />
                      </Panel>
                    )} */}
                  </div>
                  {/* <div className={styles.productRightContainer}>
                    <Panel
                      className={[
                        styles.panelWrap,
                        styles.orderStatusPanel,
                      ].join(" ")}
                    >
                      <PanelHeader title="Order status" />
                      <OrderStatus id={id} />
                    </Panel>
                    <Panel
                      className={[
                        styles.panelWrap,
                        styles.topSellersPanel,
                      ].join(" ")}
                    >
                      <PanelHeader title="Top creators" />
                      <TopSellers />
                    </Panel>
                  </div> */}
                </div>
                <div className={styles.footer}>
                  <SixtyButton type="submit">Save</SixtyButton>
                </div>
              </form>
            </FormProvider>
          </>
        )
      )}
    </div>
  );
};

export default ProductDetailsPage;
