import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import SellerRegistrationTable from "./components/RegistrationTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import RegistrationDetails from "./components/RegistrationDetails";

const SellerRegistration = () => {

  const [isSellerDetailOpen, setSellerDetailOpen] = useState(false);
  const history = useHistory();
  const sellerDetailRef = useRef();
  const [selectedSeller, setSelectedSeller] = useState({});

  const SellerDetailTitle = () => {
    return (
      <div className={styles.sellerDetailModalTitleBar}>
        <div className={styles.sellerNameContainer}>
          <div>
            <Avatar name={selectedSeller.name} />
          </div>
          <div className={styles.modalTitle}>{selectedSeller.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setSellerDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onSellerClick = (value) => {
    setSelectedSeller(value);
    setSellerDetailOpen(true);
  };

  return (
    <div>
      <GridHeader header="Influencer Registrations" />
      <Panel className={styles.panelWrap}>
        <div className={styles.sellerTable}>
          <SellerRegistrationTable onSellerClick={onSellerClick} />
        </div>
      </Panel>
      {isSellerDetailOpen && (
        <Modal
          isOpen={isSellerDetailOpen}
          onAttemptClose={() => {
            history.push("/admin/influencers/registrations");
            setSellerDetailOpen(false);
          }}
          modalRef={sellerDetailRef}
          Title={SellerDetailTitle}
        >
          <RegistrationDetails
            selectedSeller={selectedSeller}
            handleClose={() => setSellerDetailOpen(false)}
            isHideRejection={false}
          />
        </Modal>
      )}
    </div>
  );
};


export default SellerRegistration;
