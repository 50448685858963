import { useState, useRef, useEffect } from "react";
import clsx from "clsx";
//import useSelect from "use-select";
import Select from 'react-select'
import Creatable, { useCreatable } from 'react-select/creatable';
import _uniq from "lodash/uniq";

import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search-icon.svg";
import styles from "./index.module.css";
import { ReactComponent as DropDownIcon } from "../../assets/icons/Icon-Arrow-Right.svg";

const ChipInput = ({
  name,
  onBlur,
  onChange,
  onSearchChange,
  label,
  options,
  optionsLoading,
  value,
  placeholder,
  className,
  showDropdownIcon = false,
  isMultiSelect = true,
  error,
  helperText,
  useSelectProps = {},
  ...rest
}) => {
  const reactWindowInstanceRef = useRef(null);
  const optionsRef = useRef(null);

  const scrollToIndex = (index) => {
    if (!reactWindowInstanceRef.current) {
      return;
    }
    reactWindowInstanceRef.current.scrollToItem(index);
  };

  const shiftAmount = 5;

  // store options selected in a cache,
  // So, even if async options change, we can get prev option labels
  const [selected, setSelected] = useState([]);

  const handleChange = (val) => {
    let update = [];

    if(isMultiSelect){
      val.forEach((item) => {
        let present = selected.find((option) => option.value === item);
        if (present) {
          update = [...update, present];
        } else {
          if(options){
            let option = options.find((option) => option.value === item);
            update = [...update, option];
          }
        }
      });
    }
    else{
      if(val){
        update = [...update, val];
      }     
    }

    setSelected(update);
    onChange(val, update);
    setOpen(false);
  };

  const {
    visibleOptions,
    selectedOption,
    highlightedIndex,
    isOpen,
    setOpen,
    searchValue,
    setSearch,
    getInputProps,
    getOptionProps,
  } = useCreatable({
    isMulti: isMultiSelect,
    options,
    value,
    onChange: handleChange,
    scrollToIndex,
    optionsRef,
    shiftAmount,
    filterFn: (options, value) => {
      return options.filter(
        (option) => !option.label?.toLowerCase().search(value?.toLowerCase())
      );
    },
    ...useSelectProps,
  });

  useEffect(() => {
    onSearchChange?.(searchValue);
  }, [onSearchChange, searchValue]);

  const [active, setActive] = useState(false);

  return (
    <div
      className={clsx(
        styles.field,
        error && styles.error,
        className && className
      )}
    >
      <div
        className={clsx(styles.fieldGroup, {
          [styles.activeGroup]:
            active || !!searchValue || (value && value.length > 0),
        })}
      >
        <label
          htmlFor={name}
          className={clsx(styles.label, {
            [styles.activeLabel]:
              active || !!searchValue || (value && value.length > 0),
          })}
        >
          {!active
            ? placeholder
              ? value && value.length > 0
                ? label
                : placeholder
              : label
            : label}
        </label>
        <div className={styles.inputWrapper}>
          {/* Tags */}
          {isMultiSelect && [...(selected.length === 0 ? selectedOption : selected)].length >
            0 &&
            [...(selected.length === 0 ? selectedOption : selected)].map((option) => (
              <div className={styles.chipItem} key={option.value}>
                {option?.label ?? option?.value}
                <span>
                  <CloseIcon
                    className={styles.chipCloseIcon}
                    onClick={() =>
                      handleChange(value.filter((d) => d !== option.value))
                    }
                  />
                </span>
              </div>
            ))}
          {/* Input */}
          <input
            className={styles.input}
            autoComplete="off"
            {...getInputProps({
              onFocus: () => {
                setActive(true);
              },
              onBlur: () => {
                setActive(false);
              },
              onKeyDown: (e) => {
                if (!options && ["Enter", ","].includes(e.key)) {
                  e.preventDefault();

                  let currentValue = searchValue.trim();
                  if (currentValue) {
                    let update = [...value, currentValue];
                    update = _uniq(update);
                    onChange(update);
                    setSearch("");
                  }
                }
              },
            })}
            {...rest}
          />
          {showDropdownIcon && (
            <div className={styles.endIcon}>
              <DropDownIcon />
            </div>
          )}
          {options && !showDropdownIcon && (
            <div className={styles.endIcon}>
              <SearchIcon />
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <div className={styles.optionsWrapper} ref={optionsRef}>
          {optionsLoading && (
            <div className={styles.option}>Loading options...</div>
          )}
          {!optionsLoading && visibleOptions.length === 0 && (
            <div className={styles.option}>No options were found...</div>
          )}
          {!optionsLoading &&
            options?.length > 0 &&
            visibleOptions.map((option, index) => {
              return (
                <div
                  className={clsx(styles.option, {
                    [styles.highlightedOption]: index === highlightedIndex,
                    [styles.selectedOption]: option === selectedOption,
                  })}
                  {...getOptionProps({
                    option,
                    className: clsx(styles.option, {
                      [styles.highlightedOption]: index === highlightedIndex,
                      [styles.selectedOption]: option === selectedOption,
                    }),
                    index,
                  })}
                >
                  {option.PreIcon && (
                    <span className={styles.preIcon}>
                      <option.PreIcon />
                    </span>
                  )}
                  {option.label}
                </div>
              );
            })}
        </div>
      )}
      {!error && helperText && (
        <div className={styles.helperText}>{helperText}</div>
      )}
      {error && <div className={styles.errorMessage}>{error.message}</div>}
    </div>
  );
};

export default ChipInput;
