import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useRejectRefund } from "queries/order";

import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import ControlledSelect from "components/FormComponents/ControlledSelect";
import Input from "components/FormComponents/ControlledInput";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";


const defaultValues = {
  reason: "",
};

const RefundRejectForm = ({ selectedContent, handleClose }) => {

  const formMethods = useForm({
    defaultValues,
  });

  const { mutateAsync: updateRefundStatus, isLoading: statusLoading } = useRejectRefund();


  const onSubmit = async (data) => {

    const { id } = selectedContent;
    
    try {

      let reason = data.reason;

      var model = {
        id: id,
        reason: reason
      };

      const result = await updateRefundStatus(model);

      if (result) {
        handleClose();
        setToast("successfully update status");
      }
    } catch (e) {
      handleClose();
      reportError("error in : ContentRejectForm: onRejectContent, error: ", e);
    }
  };
  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.contentRejectForm}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={styles.sellerRejectForm}>
            
            <div className={styles.rejectionReasonContainer}>
                  <Input
                    name="reason"
                    placeholder="Reason for rejection"
                    label="Reason to buyer"
                    lines="5"
                  />
            </div>
            </div>
            <footer className={styles.footer}>
              <SixtyButton
                type="submit"
                variant="warning"
                className={styles.rejectBtn}               
                disabled={statusLoading}
              >
                Reject
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={handleClose}
                disabled={statusLoading}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default RefundRejectForm;
