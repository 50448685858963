import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import PromotionTable from "../components/PromotionTable";
import CreatePromotion from "../CreatePromotion";
import PromotionDetails from "../PromotionDetails";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import SixtyButton from "components/SixtyButton";

const Promotions = () => {

  const history = useHistory();
  const [isPromotionDetailOpen, setPromotionDetailOpen] = useState(false); 
  const promotionDetailRef = useRef();
  const [selectedPromotion, setSelectedPromotion] = useState({});

  const [isCreatePromotionOpen, setCreatePromotionOpen] = useState(false);
  const createPromotionRef = useRef();

  const CreatePromotionTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={'Create Promotion'} />
          </div>
          <div className={styles.modalTitle}>{'Create New Promotion'}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setCreatePromotionOpen(false)}
          />
        </div>
      </div>
    );
  };

  const PromotionDetailTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedPromotion.name} />
          </div>
          <div className={styles.modalTitle}>{selectedPromotion.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPromotionDetailOpen(false)}
          />
        </div>
      </div>
    );
  };


  const onPromotionClick = (value) => {
    setSelectedPromotion(value);
    setPromotionDetailOpen(true);
  };

  return (
    <div>
      <GridHeader header="Promotions" 
         renderButtons={() => (
          <>
            <SixtyButton
              onClick={() => setCreatePromotionOpen(true)}
            >
              Add new promotion
            </SixtyButton>
          </>
         )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.pricingTable}>
          <PromotionTable onPromotionClick={onPromotionClick} />
        </div>
      </Panel>
      {isCreatePromotionOpen && (
        <Modal
          isOpen={isCreatePromotionOpen}
          onAttemptClose={() => {
            setCreatePromotionOpen(false);
            history.push("/admin/promotions");
          }}
          modalRef={createPromotionRef}
          Title={CreatePromotionTitle}
        >
          <CreatePromotion
            handleClose={() => setCreatePromotionOpen(false)}
          />
        </Modal>
      )}
      {isPromotionDetailOpen && (
        <Modal
          isOpen={isPromotionDetailOpen}
          onAttemptClose={() => {
            setPromotionDetailOpen(false);
            history.push("/admin/promotions");
          }}
          modalRef={promotionDetailRef}
          Title={PromotionDetailTitle}
        >
          <PromotionDetails
            selectedPromotion={selectedPromotion}
            handleClose={() => setPromotionDetailOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Promotions;
