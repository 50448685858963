import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useImmer } from "utils/hooks/useImmer";
import useRowSelectionTable from "utils/hooks/useRowSelectionTable";
import { useGetVendorAffiliateAnalytics } from "queries/admin";
import _isEmpty from "lodash/isEmpty";

import Table, {
  RowSelectionContainer,
  TableHeader,
} from "components/SixtyTable";
import { ReactComponent as VendorIcon } from "assets/icons/Icon-Vendors2.svg";
import Avatar from "components/Avatar";
import EmptyGrid from "components/EmptyGrid";
import SixtyText from "components/SixtyText";
import SixtyButton from "components/SixtyButton";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import Loader from "components/Loader";
import { PanelActionButton } from "components/SixtyPanel";

import styles from "./index.module.css";
import ErrorModal from "components/Error";
import { unknown_Error } from "common/constants";

export default function VendorAnalyticTable({onAnalyticClick}){

    const onAnalyticSelect = useCallback(
        (analytic) => {
          if (onAnalyticClick) {
            onAnalyticClick(analytic?.original?.id);
          }
        },
        [onAnalyticClick]
      );

      const [filter, setFilter] = useImmer({});
      const [search, setSearch] = useState("");
      const handleSearch = (value) => setSearch(value);
      const [sort, setSort] = useImmer("orderByDescending");

      const onFilterChange = (filterVal) => {
        setFilter(() => filterVal);
      };

      const handleSortChange = useCallback(
        (field) => {
          setSort(field);
        },
        [setSort]
      );

        // pagination
        const [startFrom, setStart] = useState(0);
        const limit = 20;

        const getFilters = useCallback(() => {
            return {
            ...filter,
            [sort]: true,
            keyword: search,
            startFrom,
            limit,
            };
        }, [filter, search, sort, startFrom]);

        const {
            data: analyticData,
            isLoading,
            isError,
            refetch,
            error,
          } = useGetVendorAffiliateAnalytics(getFilters());

        const onRowClick = (row, e) => {
            onAnalyticSelect(row);
        };

        const columns = useMemo(
            () => [
              {
                Header: "Code",
                accessor: "code",
                Cell: ({ value, row }) => (
                  <div className={styles.vendorNameCell}>
                    <div>
                      <Avatar name={value} />
                    </div>
                    <div className={styles.vendorNameValue}>{value}</div>
                  </div>
                ),
              },
              {
                Header: "Company Name",
                accessor: "storeName",
              },
              {
                Header: "Status",
                accessor: "status",
              },
              {
                Header: "Visitors",
                accessor: "visitors",
              },
              {
                Header: "Add To Cart",
                accessor: "addToCartCount",
              },
              {
                Header: "Checkout",
                accessor: "checkoutCount",
              },
            ],
            []
          );

          let data = useMemo(() => (analyticData?.result ? analyticData.result : []), [
            analyticData,
          ]);

          const tableInstance = useRowSelectionTable({ data, columns });

          const errorRef = useRef();
        
          const closeError = () => {
            setErrorOpen(false);
          };

          const handleAction = () => {
            refetch();
            closeError();
          };

          const [errorOpen, setErrorOpen] = useState(false);
          useEffect(() => {
            if (isError) {
              setErrorOpen(true);
            }
          }, [isError]);

          if (errorOpen) {
            return (
              <ErrorModal
                open={errorOpen}
                handleClose={closeError}
                handleCancel={closeError}
                handleAction={handleAction}
                actionLabel="Try again"
                errorRef={errorRef}
                errorMessage={error?.message ? error.message : unknown_Error}
              />
            );
          }

          function isDataEmpty() {
            if (_isEmpty(filter) && !search && analyticData?.result?.length === 0) {
              return true;
            }
            return false;
          }

          return (
            <>
              {(!Array.isArray(analyticData?.result) || isDataEmpty()) && !isLoading && (
                <>
                  <EmptyGrid
                    Icon={VendorIcon}
                    emptyMessage={
                      isError
                        ? "Error in fetching data"
                        : "Your analytic will show up here"
                    }
                  />
                  {isError && (
                    <PanelActionButton onClick={() => refetch()}>
                      Try Again
                    </PanelActionButton>
                  )}
                </>
              )}
              {!isError && !isDataEmpty() && (
                <>
                  {tableInstance.selectedFlatRows.length > 0 ? (
                    <RowSelectionContainer
                      renderRowSelectionText={() => (
                        <>
                          <SixtyText>
                            {tableInstance.selectedFlatRows.length}
                            {tableInstance.selectedFlatRows.length === 1
                              ? "vendor"
                              : "vendors"}{" "}
                            selected
                          </SixtyText>
                          <SixtyButton
                            className={styles.unselectButton}
                            variant="text"
                            onClick={() => tableInstance.toggleAllRowsSelected(false)}
                          >
                            unselect all
                          </SixtyButton>
                        </>
                      )}
                      renderRowSelectionButtons={() => (
                        <>
        
                        </>
                      )}
                    />
                  ) : (
                    <>
                      <TableHeader>
                        <SearchFilter
                          search={search}
                          onSearchChange={handleSearch}
                          placeholder="Search for vendors"
                        />
                      </TableHeader>
                    </>
                  )}
                  {isLoading ? (
                    <div className={styles.loaderContainer}>
                      <Loader />
                    </div>
                  ) : (
                    Array.isArray(analyticData?.result) && (
                      <Table
                        instance={tableInstance}
                        totalItems={analyticData?.totalRecords}
                        startFrom={startFrom}
                        limit={limit}
                        setStart={setStart}
                        onRowClicked={onRowClick}
                      />
                    )
                  )}
                </>
              )}
            </>
          );
}