import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import ContentPublishingTable from "../components/ContentPublishingTable";
import ReportingTable from "../components/ReportingTable";
import ReportingDetail from "../components/ReportingDetails";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import { setToast } from "components/Toast";
import BasicPageHeader from "../../Components/BasicHeader";
import Tabs from "components/Tabs";

const Reporting = () => {
  const [isPostOpen, setPostOpen] = useState(false);
  const history = useHistory();
  const params = useParams();
  const postRef = useRef();
  const [selectedPost, setSelectedPost] = useState({});
  const role = localStorage.getItem("userRole");


  const PostTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedPost.title} />
          </div>
          <div className={styles.modalTitle}>{selectedPost.title}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPostOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onContentClick = (value) => {
    setSelectedPost(value);
    setPostOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <BasicPageHeader title="Insights Reporting" onGoBack={onGoBack} />
      <Panel className={styles.panelWrap}>
        <div className={styles.campaignTable}>
          <ReportingTable status={'Reporting'} onContentClick={onContentClick} />
        </div>
      </Panel>

      {isPostOpen && (
        <Modal
          isOpen={isPostOpen}
          onAttemptClose={() => {
            setPostOpen(false);
          }}
          modalRef={postRef}
          Title={PostTitle}
        >
          <ReportingDetail
            selectedPost={selectedPost}
            handleClose={() => setPostOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Reporting;
