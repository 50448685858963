import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import AdminCampaignProductTable from "../components/AdminCampaignProductTable";
import ProductPreviewDetails from "../components/ProductPreviewDetails";
import AdminSearchProductTable from "../components/AdminSearchProductTable";
import CampaignProductDetails from "../components/CampaignProductDetails";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import { setToast } from "components/Toast";
import BasicPageHeader from "../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetCampaignProductCount } from "queries/admin";
import { useGetCampaignAnalytics } from "queries/campaign";
import Loader from "components/Loader";
import SixtyText from "components/SixtyText";

const AdminCampaignProducts = () => {
  
  const history = useHistory();
  const params = useParams();

  const [isProductDetailOpen, setProductDetailOpen] = useState(false);
  const productDetailRef = useRef();
  const [selectedProduct, setSelectedProduct] = useState({});

  const [isCampaignProductDetailOpen, setCampaignProductDetailOpen] = useState(false);
  const campaignProductDetailRef = useRef();
  const [selectedCampaignProduct, setSelectedCampaignProduct] = useState({});


  const {
    data: requestData,
  } = useGetCampaignProductCount(params.id);

  const { 
    data: campaignData, 
    isLoading: campaignLoading 
  } = useGetCampaignAnalytics(params.id);

  const ProductDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedProduct.name} />
          </div>
          <div className={styles.modalTitle}>{selectedProduct.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setProductDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const CampaignProductDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedCampaignProduct.name} />
          </div>
          <div className={styles.modalTitle}>{selectedCampaignProduct.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setCampaignProductDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onCampaignProductClick = (value) => {
    setSelectedCampaignProduct(value);
    setCampaignProductDetailOpen(true);
  };

  const onProductClick = (value) => {  
    setSelectedProduct(value);
    setProductDetailOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      {campaignLoading && (
        <div style={{width:800, height:500}} >
             <Loader/>
        </div>
      )}
      {!campaignLoading && campaignData && (
      <>
         
          <SixtyText type="h1" color="secondary" className={styles.name}>
                {campaignData?.name}
           </SixtyText>
        
          <BasicPageHeader title="Campaign Products" onGoBack={onGoBack} />

            <Panel className="tabs">
              <Tabs
                activeTab={ "1" }
                tabs={[              
                  {
                    tabId: "1",
                    label: "Vendor Products",
                    TabComponent: AdminSearchProductTable,
                    props: {
                      campaignId: params.id,
                      onContentClick: onProductClick,
                      msg: "Search vendor products and add them to the campaign."
                    },           
                  },
                  {
                    tabId: "2",
                    label: 'Campaign Products',
                    recordsCount: requestData?.productCount,
                    TabComponent: AdminCampaignProductTable,
                    props: {
                      campaignId: params.id,
                      onContentClick: onCampaignProductClick,
                      msg: "Manage campaign products."                        
                    },
                  },
                ]}
              ></Tabs>
            </Panel>
        
       </>
      )}

      {isProductDetailOpen && (
        <Modal
          isOpen={isProductDetailOpen}
          onAttemptClose={() => {
            setProductDetailOpen(false);
          }}
          modalRef={productDetailRef}
          Title={ProductDetailTitle}
        >
          <ProductPreviewDetails
            selectedCampaign={campaignData}
            selectedProduct={selectedProduct}
            handleClose={() => setProductDetailOpen(false)}
          />
        </Modal>
      )}

      {isCampaignProductDetailOpen && (
        <Modal
          isOpen={isCampaignProductDetailOpen}
          onAttemptClose={() => {
            setCampaignProductDetailOpen(false);
          }}
          modalRef={campaignProductDetailRef}
          Title={CampaignProductDetailTitle}
        >
          <CampaignProductDetails
            selectedProduct={selectedCampaignProduct}
            handleClose={() => setCampaignProductDetailOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default AdminCampaignProducts;
