import Report from "../Report";
import styles from "./index.module.css";
import clsx from "clsx";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import SixtyLink from "components/SixtyLink";

const BasicReport = ({ data }) => {

  return (
    <div className={styles.totalSellersReport}>
      <div className={styles.row}>
            <Report
              title={'Active Registered Users'}
              currentMonthCount={data?.registeredUserCount}
              unit={'+ ' + data?.currentRegisteredUserCount}
              desc={'Registered users and at least logged in.'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
            <Report
              title={'Bio Link Users'}
              currentMonthCount={data?.bioLinkUserCount}
              unit={'+ ' + data?.currentbioLinkUserCount}
              desc={'Users who activated bio link.'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
            <Report
              title={'Active Bio Link Users'}
              currentMonthCount={data?.activeBioLinkUserCount}
              desc={'Users who generated traffics with bio link.'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
            <Report
              title={'Bio Link Unique Visitors'}
              currentMonthCount={data?.bioLinkGeneratedTraffics}
              unit={'+ ' + data?.currentBioLinkGeneratedTraffics}
              desc={'Unique visitors to bio link through social media.'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
            <Report
              title={'Bio Link Visitors Conversion'}
              currentMonthCount={data?.bioLinkTrafficConversion}
              desc={'Average of followers of active influencers visit bio link'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
            <Report
              title={'Influencer wallets'}
              currentMonthCount={data?.totalSellerBalance}
              redirectTitle={"Browse"}
              redirectLink={"/Admin/InfluencerWallets"}
              desc={'Total credits hold by all influencers.'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
            <Report
              title={'Influencer on hold withdraws'}
              currentMonthCount={data?.totalSellerWithdraw}
              desc={'Total credits pending for withdraw and bank in.'}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            />
      </div>
      {/* <div className={styles.viewRequestBtn}>
        {data?.id && (
          <SixtyLink
            type="p2"
            color="orange"
            weight="medium"
            inline
            to={"/dashboard/campaigns/AnalyticDetails/" + data?.id}
          >
            Manage
          </SixtyLink>
        )}
      </div> */}
    </div>
  );
};

export default BasicReport;
