import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useImmer } from "utils/hooks/useImmer";
import useRowSelectionTable from "utils/hooks/useRowSelectionTable";
import {
  useApproveSellerApplications,
  useGetSellerRequests,
  useRejectSellerApplications,
} from "queries/seller";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";

import Table, {
  RowSelectionContainer,
  TableHeader,
} from "components/SixtyTable";
import Avatar from "components/Avatar";
import SixtyText from "components/SixtyText";
import SixtyButton from "components/SixtyButton";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import Filter from "components/SixtyTable/components/Filter";
import SellerRequestFilter, { renderFilterChips } from "./components/Filter";
import Sort from "components/SixtyTable/components/Sort";
import Loader from "components/Loader";
import ErrorModal from "components/Error";
import EmptyGrid from "components/EmptyGrid";
import { PanelActionButton } from "components/SixtyPanel";
import { getFormattedDateString } from "utils/date.util";
import styles from "./index.module.css";
import { ReactComponent as SellerIcon } from "assets/icons/Icon-Sellers.svg";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import { unknown_Error } from "common/constants";

export default function SellerRequestTable({ onSellerClick }) {
  const onSellerRequestClick = useCallback(
    (sellerRequest) => {
      if (onSellerClick) {
        console.log(sellerRequest);
        onSellerClick(sellerRequest.original);
      }
    },
    [onSellerClick]
  );

  const [search, setSearch] = useState("");
  const handleSearch = (value) => setSearch(value);

  const [filterState, setFilterState] = useImmer({});
  const [sort, setSort] = useImmer("orderByAscending");

  const onFilterChange = (filterVal) => {
    // component filter state set
    setFilterState(() => filterVal);
  };

  const handleSortChange = useCallback(
    (field) => {
      setSort(field);
    },
    [setSort]
  );

  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    let filter = { ..._omit(filterState, "date") };

    let dateFilters = { ...(filterState.date ? filterState.date : {}) };

    if (!!dateFilters["custom"]) delete dateFilters.custom;
    filter = { ...filter, ...dateFilters };
    return {
      [sort]: true,
      ...filter,
      keyword: search,
      startFrom,
      limit,
    };
  }, [filterState, search, sort, startFrom]);

  const {
    data: sellerRequestData,
    isLoading,
    isError,
    refetch,
    error,
  } = useGetSellerRequests(getFilters());

  let data = useMemo(
    () => (sellerRequestData?.result ? sellerRequestData.result : []),
    [sellerRequestData]
  );

  const onRowClick = (row, e) => {
    onSellerRequestClick(row);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "sellerName",
        Cell: ({ value, row }) => (
          <div className={styles.sellerNameCell}>
            <div>
              <Avatar name={value} />
            </div>
            <div className={styles.sellerNameValue}>{value}</div>
          </div>
        ),
      },
      {
        Header: "Email address",
        accessor: "email",
      },
      // {
      //   Header: "No. of dropship brand",
      //   accessor: "joinedBrandCount",
      // },
      // {
      //   Header: "Total no. of sales",
      //   accessor: "saleCount",
      // },
      {
        Header: "Requested Date",
        accessor: "submitDate",
        Cell: ({ value, row }) => (
          <div>{getFormattedDateString(value, "MMM d, y")}</div>
        ),
      },
    ],
    []
  );

  const tableInstance = useRowSelectionTable({
    data,
    columns,
  });

  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  // row selection actions
  const { mutateAsync: approveSellers } = useApproveSellerApplications();
  const onApprove = async () => {
    try {
      const approvableIds = [];
      tableInstance.selectedFlatRows.forEach((row) => {
        if (row.original.allowedActions.includes("Approve")) {
          approvableIds.push(row.original.id);
        }
      });
      const result = await approveSellers(approvableIds);
      if (result) {
        setToast("Successfully approved creator");
        tableInstance.toggleAllRowsSelected(false);
        refetch();
      }
    } catch (error) {
      reportError("SellerRequestTable: onApprove: Error: ", error);
      setToast("Error while approving creator", "error");
    }
  };

  const { mutateAsync: rejectSellers } = useRejectSellerApplications();
  const onReject = async () => {
    try {
      const rejectableIds = [];
      tableInstance.selectedFlatRows.forEach((row) => {
        if (row.original.allowedActions.includes("Reject")) {
          rejectableIds.push(row.original.id);
        }
      });
      const result = await rejectSellers(rejectableIds);
      if (result) {
        setToast("Successfully rejected creator");
        tableInstance.toggleAllRowsSelected(false);
        refetch();
      }
    } catch (error) {
      reportError("SellerRequestTable: onReject: Error: ", error);
      setToast("Error while rejecting creator", "error");
    }
  };

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (
      _isEmpty(filterState) &&
      !search &&
      sellerRequestData?.result?.length === 0
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      {(!Array.isArray(sellerRequestData?.result) || isDataEmpty()) &&
        !isLoading && (
          <>
            <EmptyGrid
              Icon={SellerIcon}
              emptyMessage={
                isError
                  ? "error in loading table"
                  : "Your creators request will show up here"
              }
            />
            {isError && (
              <PanelActionButton onClick={() => refetch()}>
                Try Again
              </PanelActionButton>
            )}
          </>
        )}
      {!isError && !isDataEmpty() && (
        <>
          {tableInstance.selectedFlatRows.length > 0 ? (
            <RowSelectionContainer
              renderRowSelectionText={() => (
                <>
                  <SixtyText>
                    {tableInstance.selectedFlatRows.length}{" "}
                    {tableInstance.selectedFlatRows.length === 1
                      ? "creator"
                      : "creators"}{" "}
                    selected
                  </SixtyText>
                  <SixtyButton
                    className={styles.unselectButton}
                    variant="text"
                    onClick={() => tableInstance.toggleAllRowsSelected(false)}
                  >
                    unselect all
                  </SixtyButton>
                </>
              )}
              renderRowSelectionButtons={() => (
                <>
                  <SixtyButton onClick={onApprove}>Approve</SixtyButton>
                  <SixtyButton variant="warning" onClick={onReject}>
                    Reject
                  </SixtyButton>
                  {/* <SixtyButton variant="secondary">Archive</SixtyButton> */}
                </>
              )}
            />
          ) : (
            <>
              <TableHeader>
                <SearchFilter
                  search={search}
                  onSearchChange={handleSearch}
                  placeholder="Search for creators"
                />
                <Filter
                  filter={filterState}
                  setFilter={onFilterChange}
                  renderFilter={SellerRequestFilter}
                  columnLayout
                />
                <Sort
                  // render={ProductSort}
                  state={sort}
                  handleSortChange={handleSortChange}
                />
              </TableHeader>
              {renderFilterChips(filterState, setFilterState)}
            </>
          )}
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(sellerRequestData?.result) && (
              <Table
                instance={tableInstance}
                totalItems={sellerRequestData?.totalRecords}
                startFrom={startFrom}
                limit={limit}
                setStart={setStart}
                onRowClicked={onRowClick}
              />
            )
          )}
        </>
      )}
    </>
  );
}
