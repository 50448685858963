import React, { useState } from "react";
import clsx from "clsx";
import { useFormContext, useWatch } from "react-hook-form";

import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import Button from "components/SixtyButton";

import { ReactComponent as IconSingleImageDrop } from "assets/icons/Icon-SingleImageDrop.svg";
import styles from "./index.module.css";

const Media = ({ details }) => {
  const { control, errors, setValue } = useFormContext();

  const mainImage = useWatch({
    control,
    name: "mainImages",
    defaultValue: details?.mainImages || [],
  });

  const moreImages = useWatch({
    control,
    name: "moreImages",
    defaultValue: [],
  });

  const [openDropozoneHook, setOpenDropozoneHook] = useState(() => {});

  const onMainImageDelete = () => {
    setValue("mainImages", []);
  };

  const removeFile = (name, file) => {
    const allFiles = [...moreImages];
    allFiles.splice(allFiles.indexOf(file), 1);
    setValue("moreImages", allFiles);
  };

  return (
    <React.Fragment>
      <div className={clsx(styles.mainImage, styles.mediaSubHeader)}>
        <span> Main image </span>
        <div className={styles.mainImageDropzoneContainer}>
          <div
            className={clsx(styles.mainImageDropzone, {
              [styles.dropZoneWithContent]: mainImage?.length > 0,
            })}
          >
            <ImageDropzone
              control={control}
              error={errors.mainImages}
              name="mainImages"
              title="Drag your image here"
              subTitle="or Click to browse for a file"
              DefaultThumb={IconSingleImageDrop}
              maximumFiles={1}
              onDropAccepted={(name, selectedFiles) => {
                setValue("mainImages", selectedFiles);
              }}
              setOpenDropzonehook={(open) => {
                setOpenDropozoneHook(() => open);
              }}
              selectedFiles={mainImage}
            />
          </div>
          {mainImage.length > 0 && (
            <div className={styles.actionButtonsContainer}>
              <div>
                <Button
                  onClick={openDropozoneHook}
                  label="Replace"
                  variant="secondary"
                />
              </div>
              <div>
                <Button
                  onClick={onMainImageDelete}
                  label="Delete"
                  variant="secondary"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={clsx(styles.moreImages, styles.mediaSubHeader)}>
        <span> More images </span>
        <div className={styles.moreImageDropzone}>
          <ImageDropzone
            control={control}
            name="moreImages"
            onDropAccepted={(name, selectedFiles) => {
              setValue("moreImages", [...moreImages, ...selectedFiles]);
            }}
            selectedFiles={moreImages}
            onRemoveFile={(name, file) => {
              removeFile(name, file);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Media;
