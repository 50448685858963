import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useApproveRefund } from "queries/order";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Button from "components/SixtyButton";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import {
    useGetRefundItemDetails,
    useMarkBuyerRefundComplete
  } from "queries/admin";
import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

const RefundDetails = ({ selectedRefund, handleClose }) => {

  const { 
    id
  } = selectedRefund;

  const { data: refundData, isLoading, isError, refetch } = useGetRefundItemDetails(id);

  const { mutateAsync: MarkComplete, 
          isLoading : refundLoading 
        } = useMarkBuyerRefundComplete();

    const onMarkCompleted = async () => {
      try {
      
        var result = await MarkComplete(id);

        if(result){
           setToast("successfully update status");
           handleClose();
        }
       
     } catch (e) {
        reportError(
          "error in : onMarkCompleted: onSubmit, error: ",
          e
        );
     }
  };

  return (
    <>
    {isLoading && (
          <>
        <div className={styles.refundRequestFormContainer}>
          <Panel className={styles.panelWrap}>
            <Loader />
          </Panel>
        </div>
          </>
    )}
    {!isLoading && (
          <>
            <React.Fragment>
                <div className={styles.refundRequestFormContainer}>

                <div className={styles.refundRequestForm}>
                    <div>
                    <Panel className={styles.panelWrap}>
                    <div className={styles.subHeader}>
                            <span> Evidences </span>
                            <React.Fragment>
                                {refundData && refundData?.images?.length > 0 && (
                                    <div className={styles.profPhotoContainer}>
                                    {refundData?.images.map((img) => (
                                        <div key={img.id} className={styles.profPhotoDropzone}>
                                            <img
                                                src={img?.path}
                                                className={clsx(styles.img)}
                                                alt="No preview"
                                            />
                                        </div>
                                    ))}
                                    </div>
                                )}
                            </React.Fragment>
                        </div>
                        <PanelHeader
                          className={styles.refundInfoHeader}
                          title="Order"
                        />
                         <div className={styles.refundInfoContainer}>
                            <div className={styles.refundInfoRow}>
                                <div className={styles.refundInfoItem}>
                                    <div className={styles.refundInfoHeader}>
                                    Transaction Code
                                    </div>
                                    <div>
                                        {refundData?.transactionCode}
                                    </div>
                               </div>
                               <div className={styles.refundInfoItem}>
                                    <div className={styles.refundInfoHeader}>
                                      Stripe Reference
                                    </div>
                                    <div>
                                        {refundData?.stripeReferenceId}
                                    </div>
                               </div>
                            </div>
                         </div>
                        <PanelHeader
                        className={styles.refundInfoHeader}
                        title="Refund Proposal"
                        />
                        <div className={styles.refundInfoContainer}>
                        <div className={styles.refundInfoRow}>
                            <div className={styles.refundInfoItem}>
                            <div className={styles.refundInfoHeader}>
                                Item
                            </div>
                            <div>
                                {refundData?.itemName}
                            </div>
                            </div>
                            <div className={styles.refundInfoItem}>
                            <div className={styles.refundInfoHeader}>Amount</div>
                            <div>
                                {'MYR ' + refundData?.amount}
                            </div>
                            </div>
                            <div className={styles.refundInfoItem}>
                            <div className={styles.refundInfoHeader}>Status</div>
                            <div>
                                {refundData?.status}
                            </div>
                            </div>
                        </div>
                        <div className={styles.refundInfoRow}>
                            <div className={styles.refundInfoFullWidthItem}>
                            <div className={styles.refundInfoHeader}>
                                Reason
                            </div>
                            <div>
                                {refundData?.refundReason}
                            </div>
                            </div>
                        </div>
                        <div className={styles.refundInfoRow}>
                            <div className={styles.refundInfoFullWidthItem}>
                            <div className={styles.refundInfoHeader}>
                                Addtional Comments
                            </div>
                            <div>
                                {refundData?.comment}
                            </div>
                            </div>
                        </div>
                        </div>
                    </Panel>
                
                    </div>
                </div>

                <footer className={styles.footer}>

                    <SixtyButton
                        variant= {"tertiary"}
                        className={styles.approveBtn}
                        onClick={onMarkCompleted}
                        disabled={refundLoading}
                    >
                        {'Complete'}
                    </SixtyButton> 
                    
                    <SixtyButton
                        variant="warning"
                        className={styles.cancelButton}
                        onClick={handleClose}
                        disabled={refundLoading}
                    >
                        Close
                    </SixtyButton>

                    </footer>
                </div>
            </React.Fragment>
          </>
    )}

    </>
  );
};

export default RefundDetails;
