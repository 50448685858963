import styles from "./index.module.css";
import clsx from "clsx";
import SixtyLink from "components/SixtyLink";

const Card = ({
  title,
  currentMonthCount,
  unit,
  className,
  showProfitInNewLine = false,
  hideMinusIcon = false,
  redirectLink = null,
  redirectTitle = null,
  desc = null,
  ...props
}) => {
  return (
    <div className={clsx(styles.GeneralReportContainer, className)}>
      <div className={styles.title}>{title}</div>
      <div className={clsx(styles.counts, props.countsHelper)}>
        <span className={styles.currentMonthCount}>{currentMonthCount}</span>
        {unit && (
          <span
            className={clsx({ [styles.profitInNewLine]: showProfitInNewLine })}
          >
              <span
                className={clsx(styles.unit, styles.profit)}
              >
                {" "}
                {unit}
              </span>
          </span>
        )}
      </div>
      {desc && (
        <div className={styles.previousMonthCountText}>
           {desc}
        </div>
      )}
      {redirectLink && (
        <>
           <SixtyLink
              type="p2"
              color="orange"
              weight="medium"
              inline
              to={redirectLink}
           >
                {redirectTitle}
           </SixtyLink>
        </>
      )}
      {props.children && <div>{props.children}</div>}
    </div>
  );
};

export default Card;
