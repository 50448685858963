import React, { useState } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetCampaignSellerDetail } from "queries/campaign";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import ReactWhatsapp from 'react-whatsapp';

const InfluencerDetails = ({ selectedSeller, handleClose }) => {
  const { id } = selectedSeller;

  const { data: sellerInfo, isLoading, isError, refetch } = useGetCampaignSellerDetail(
    id
  );


  const isUrlValid = (userInput) => {

    if(!userInput) return (false);

    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return (false);
    else
        return (true);
  };

  const OpenWhatApps = () => {

    if(sellerInfo?.contact){
      
    }

  }

  return (
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Influencer Information"
            />
            {sellerInfo?.contact && (
              <div className={styles.btnActionContainer}>             
              <ReactWhatsapp number={sellerInfo?.contact} message="" className={clsx(styles.button, styles.tertiary)}>
                Open Whatapps
              </ReactWhatsapp>
              </div>
            )}

            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Name</div>
                  <div>
                    {sellerInfo?.name}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={clsx(styles.email, styles.elipsis, styles.campaignInfoHeader)}>Email</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.email}
                  >
                    {sellerInfo?.email}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Status</div>
                  <div>
                    {sellerInfo?.status}
                  </div>
                </div>
              </div>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={clsx(styles.campaignInfoHeader)}>Race</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.race}
                  >
                    {sellerInfo?.race}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Contact</div>
                  <div>
                    {sellerInfo?.contact}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Age</div>
                  <div>
                    {sellerInfo?.age}
                  </div>
                </div>
              </div>
              <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignFullInfoItem)}>
                  <div className={styles.campaignInfoHeader}>Address</div>
                  <div>                   
                <div>
                {sellerInfo?.shippingAddress?.unitNumber && 
                   sellerInfo?.shippingAddress?.floorNumber &&(
                    <div>
                      {sellerInfo?.shippingAddress?.unitNumber}{' , '}
                      {sellerInfo?.shippingAddress?.floorNumber}
                  </div>
                )}
                {sellerInfo?.shippingAddress?.unitNumber && 
                 !sellerInfo?.shippingAddress?.floorNumber &&(
                    <div>
                      {sellerInfo?.shippingAddress?.unitNumber}
                   </div>
                )}
                {!sellerInfo?.shippingAddress?.unitNumber && 
                 sellerInfo?.shippingAddress?.floorNumber &&(
                    <div>
                      {sellerInfo?.shippingAddress?.floorNumber}
                   </div>
                )}
                {/* {sellerInfo?.shippingAddress?.unitNumber && (
                  <div>{sellerInfo?.shippingAddress?.unitNumber}</div>
                )}
                {sellerInfo?.shippingAddress?.floorNumber && (
                  <div>{sellerInfo?.shippingAddress?.floorNumber}</div>
                )} */}
                {sellerInfo?.shippingAddress?.buildingName && (
                  <div>{sellerInfo?.shippingAddress?.buildingName}</div>
                )}
                {sellerInfo?.shippingAddress?.street1 && (
                  <div>{sellerInfo?.shippingAddress?.street1},</div>
                )}
                {sellerInfo?.shippingAddress?.street2 && (
                  <div>{sellerInfo?.shippingAddress?.street2},</div>
                )}
                {sellerInfo?.shippingAddress?.street3 && (
                  <div>{sellerInfo?.shippingAddress?.street3},</div>
                )}
                {sellerInfo?.shippingAddress?.postcode &&
                  sellerInfo?.shippingAddress?.city && (
                    <div>
                      {sellerInfo?.shippingAddress?.postcode}{" "}
                      {sellerInfo?.shippingAddress.city},
                    </div>
                )}
                {sellerInfo?.shippingAddress?.postcode &&
                  !sellerInfo?.shippingAddress?.city && (
                    <div>
                      {sellerInfo?.shippingAddress?.postcode},
                    </div>
                 )}
                 {!sellerInfo?.shippingAddress?.postcode &&
                  sellerInfo?.shippingAddress?.city && (
                    <div>
                      {sellerInfo?.shippingAddress?.city},
                    </div>
                 )}
                {sellerInfo?.shippingAddress?.state &&
                  sellerInfo?.shippingAddress?.country && (
                    <div>
                      {sellerInfo?.shippingAddress?.state}{" "}
                      {sellerInfo?.shippingAddress.country}
                    </div>
                  )}
              </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Social Media"
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignFullInfoItem)}>
                  <div className={styles.campaignInfoHeader}>Instagram</div>
                  <div>                   
                    {isUrlValid(sellerInfo?.instragramLink) &&(
                     <a className={styles.link} href={sellerInfo?.instragramLink} target={"_blank"} rel={"noreferrer"}>
                         {sellerInfo?.instragramLink}
                     </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Performance"
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Generated Affiliate</div>
                  <div>{sellerInfo?.totalAffiliate}</div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Total Traffics </div>
                  <div>{sellerInfo?.traffics}</div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Products </div>
                  <div>{sellerInfo?.campaignProducts?.length}</div>
                </div>
              </div>
            </div>
          </Panel>
         {sellerInfo?.campaignProducts.map((field, index) =>(
         <React.Fragment key={field.id}>

           <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title={"[product] " + sellerInfo?.campaignProducts[index].name}
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem)}>
                  <div className={styles.campaignInfoHeader}>Generated Traffics</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.campaignProducts[index].traffics}
                  >
                    {sellerInfo?.campaignProducts[index].traffics}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Redirect Link
                  </div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.campaignProducts[index].redirectLink}
                  >
                    {isUrlValid(sellerInfo?.campaignProducts[index].redirectLink) &&(
                     <a className={clsx(styles.link, styles.elipsis)} href={sellerInfo?.campaignProducts[index].redirectLink} target={"_blank"} rel={"noreferrer"}>
                         {sellerInfo?.campaignProducts[index].redirectLink}
                     </a>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </Panel>

         </React.Fragment>
         ))}

        </div>
        </div>

        <footer className={styles.footer} hidden={isLoading}>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default InfluencerDetails;
