import { useState, forwardRef } from "react";
import clsx from "clsx";
import Text from "components/SixtyText";
import styles from "./index.module.css";

const SixtyInput = forwardRef(
  (
    {
      label = "",
      name,
      value = "",
      onBlur,
      type = "text",
      placeholder,
      endIcon,
      endAdornment,
      startAdornment,
      error,
      lines,
      helperText,
      className,
      fadeOut = false,
      alwaysShowPlaceholder = false,
      ...rest
    },
    ref
  ) => {
    const [active, setActive] = useState(false);

    const hasValue = !!value || value === 0;

    return (
      <div
        className={clsx(
          styles.field,
          error && styles.error,
          className && className
        )}
      >
        <div
          className={clsx(styles.fieldGroup, {
            [styles.activeGroup]: active || hasValue,
            [styles.fadeOut]: fadeOut,
            [styles.textAreaGroup]: lines,
          })}
        >
          <label
            htmlFor={name}
            className={clsx(styles.label, {
              [styles.activeLabel]: active || hasValue,
            })}
          >
            {label}
          </label>
          <div
            className={clsx(
              styles.inputWrapper,
              startAdornment && styles.rowReverse
            )}
          >
            {lines ? (
              <textarea
                type={type}
                ref={ref}
                value={value}
                className={clsx(styles.input, styles.textarea)}
                onFocus={(e) => setActive(true)}
                onBlur={(e) => setActive(false)}
                id={name}
                placeholder={active || alwaysShowPlaceholder ? placeholder : ""}
                rows={lines}
                {...rest}
              />
            ) : (
              <input
                type={type}
                ref={ref}
                value={value}
                className={styles.input}
                onFocus={(e) => setActive(true)}
                onBlur={(e) => {
                  onBlur && onBlur(e);
                  setActive(false);
                }}
                onKeyDown={(e) => {
                  if (type !== "number") return;
                  else {
                    if (rest.min >= 0) {
                      if (e.key === "-" || e.key === "-" || e.key === "−") {
                        e.preventDefault();
                        return false;
                      }
                    }
                    if (rest.max) {
                      if (value + Number(e.key) > rest.max) {
                        e.preventDefault();
                        return false;
                      }
                    }
                  }
                }}
                id={name}
                placeholder={active || alwaysShowPlaceholder ? placeholder : ""}
                {...rest}
              />
            )}
            {startAdornment && (active || hasValue) && (
              <div className={styles.startAdornment}>{startAdornment}</div>
            )}
            {endIcon && <div className={styles.endIcon}>{endIcon}</div>}
            {endAdornment && (
              <span className={styles.endAdornment}>
                {endAdornment}
              </span>
            )}
          </div>
        </div>
        {helperText && (
          <Text type="p2" color="secondary" style={{ marginTop: 4 }}>
            {helperText}
          </Text>
        )}
        {error && <div className={styles.errorMessage}>{error.message}</div>}
      </div>
    );
  }
);

export default SixtyInput;
