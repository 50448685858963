import { queryClient } from "common/query";
import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";


export function useCheckVendorPermission(action) {
  return useQuery(
    [Endpoints.Vendor.CheckVendorPermission, action],
    () =>
      api
        .get(Endpoints.Vendor.CheckVendorPermission + `/${action}`)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError(
          "useCheckVendorPermission: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useCheckVendorOnboardingStatus(config) {
  return useQuery(
    Endpoints.Vendor.CheckVendorOnboardingStatus,
    () =>
      api
        .get(Endpoints.Vendor.CheckVendorOnboardingStatus)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useCheckVendorOnboardingStatus: useQuery: onError: error",
          error
        );
      },
      // staleTime: Infinity,
      ...config,
    }
  );
}

export function useGetAffiliateProvider() {
  return useQuery(
    Endpoints.Vendor.GetAffiliateProvider,
    () =>
      api
        .get(Endpoints.Vendor.GetAffiliateProvider)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetAffiliateProvider: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetVendorUserDetails() {
  return useQuery(
    Endpoints.Vendor.GetVendorUserDetail,
    () =>
      api
        .get(Endpoints.Vendor.GetVendorUserDetail)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetVendorUserDetails: useQuery: onError: error", error);
      },
      staleTime: Infinity,
    }
  );
}

export function useGetVendorCompanyDetails() {
  return useQuery(
    Endpoints.Vendor.GetVendorCompanyDetail,
    () =>
      api
        .get(Endpoints.Vendor.GetVendorCompanyDetail)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetVendorCompanyDetails: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetBusinessTypeDropdown() {
  return useQuery(
    Endpoints.Vendor.GetBusinessTypeDropdown,
    () =>
      api
        .get(Endpoints.Vendor.GetBusinessTypeDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetBusinessTypeDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetCountryDropdown() {
  return useQuery(
    Endpoints.Vendor.GetCountryDropdown,
    () =>
      api
        .get(Endpoints.Vendor.GetCountryDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCountryDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetStateDropdown() {
  return useQuery(
    Endpoints.Vendor.GetStateDropdown,
    () =>
      api
        .get(Endpoints.Vendor.GetStateDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetStateDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useUpdateVendorProfile() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Vendor.UpdateVendorProfile, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateVendorProfile: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useUpdateVendorCompany() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Vendor.UpdateVendorCompany, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateVendorCompany: useMutation: onError: error",
          error
        );
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(Endpoints.Vendor.GetVendorCompanyDetail);
      },
    }
  );
}

export function useSetupVendorAccountInfo() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Vendor.SetupVendorAccountInfo, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useSetupVendorAccountInfo: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useGetGenderDropdown() {
  return useQuery(
    Endpoints.Vendor.GetGenderDropdown,
    () =>
      api
        .get(Endpoints.Vendor.GetGenderDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("GetGenderDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetNotifications() {
  return useQuery(
    Endpoints.Vendor.GetNotifications,
    () =>
      api
        .get(Endpoints.Vendor.GetNotifications)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("GetNotifications: useQuery: onError: error", error);
      },
    }
  );
}
