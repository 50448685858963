import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useGetAmountMeasurementDropdown } from "queries/product";

import Input from "components/FormComponents/ControlledInput";
import Checkbox from "components/SixtyCheckBox";
import Select from "components/FormComponents/ControlledSelect";

import styles from "./index.module.css";

const DiscountDetails = () => {
  const { control, errors, watch } = useFormContext();
  const discountUom = watch("discountAmountMeasurement");
  const [allowMaxDiscount, setAllowMaxDiscount] = useState(false);

  const { data: amountMeasurementOptions } = useGetAmountMeasurementDropdown();

  const uomOptions = amountMeasurementOptions?.length
    ? amountMeasurementOptions.map((option) => ({
        label: option.name,
        value: option.key,
      }))
    : [];

  return (
    <div className={styles.form}>
      <div className={styles.section}>Apply discount</div>
      <div className={styles.formRow}>
        <Select
          name="discountAmountMeasurement"
          options={uomOptions}
          control={control}
          defaultOption="Select an option"
          error={errors.discountAmountMeasurement}
          fullWidth
        />
        <div className={styles.typeValueContainer}>
          <Input
            name="discountAmount"
            error={errors.discountAmount}
            type="number"
            control={control}
            step=".01"
            placeholder="0% to 100%"
            defaultValue={10}
            min={0}
            alwaysShowPlaceholder={true}
            endAdornment={
              <>
                {discountUom === "Fixed" ? "RM" : null}
                {discountUom === "Percentage" ? "%" : null}
              </>
            }
          />
          {/* <span className={styles.percentSign}>
            {discountUom === "Fixed" ? "RM" : null}
            {discountUom === "Percentage" ? "%" : null}
          </span> */}
        </div>
      </div>
      <div className={styles.formRow}>
        <Checkbox
          label="Set maximum discount value"
          uncheckStyleVariant="greyedOut"
          checked={allowMaxDiscount}
          onChange={(e) => setAllowMaxDiscount(e.target.checked)}
        />
      </div>
      {allowMaxDiscount && (
        <div className={styles.formRow}>
          <Input
            name="maximumDiscountValue"
            error={errors.maximumDiscountValue}
            control={control}
            label="RM"
            defaultValue={null}
            type="number"
            min={0}
            step=".01"
            className="maximumDiscountInput"
          />
        </div>
      )}
    </div>
  );
};

export default DiscountDetails;
