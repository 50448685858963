import { useMemo, useState } from "react";
import Table from "components/SixtyTable";
import { ReactComponent as IconCreditBalances } from "assets/icons/Icon-CreditBalance.svg";
import EmptyGrid from "components/EmptyGrid";
import { format } from "date-fns";
import { useTable } from "react-table";
import Text from "components/SixtyText";
import styles from "./index.module.css";
import Panel, { PanelActionButton } from "components/SixtyPanel";
import { useGetOrderSummary } from "queries/report";
import Loader from "components/Loader";

export default function TotalSalesTable({ month }) {
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const { data, isLoading, isError, refetch } = useGetOrderSummary({
    reportDate: format(month, "MM/yyyy"),
  });

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        style: { padding: "0px 16px" },
        collapse: true,
      },
      {
        Header: "Orders",
        accessor: "orders",
        collapse: true,
      },
      {
        Header: "Discounts",
        accessor: "discounts",
        collapse: true,
        Cell: ({ value, row }) => <div>{"-" + value}</div>,
      },
      {
        Header: "Returns",
        accessor: "returns",
        collapse: true,
        Cell: ({ value, row }) => <div>{"-" + value}</div>,
      },
      {
        Header: "Shipping fees",
        accessor: "shippingFees",
        collapse: true,
      },
      {
        Header: "Tax",
        accessor: "tax",
        collapse: true,
      },
      {
        Header: "Gross sales",
        accessor: "grossSales",
        collapse: true,
      },
      {
        Header: "Net sales",
        accessor: "netSales",
        collapse: true,
      },
      {
        Header: "Total sales",
        accessor: "totalSales",
        collapse: true,
      },
    ],
    []
  );

  const tableData = useMemo(() => (data ? data?.result : []), [data]);
  const tableInstance = useTable({
    columns,
    data: tableData,
  });

  return (
    <Panel className={styles.panelWrap}>
      {!Array.isArray(data?.result) && !isLoading && (
        <>
          <EmptyGrid
            Icon={IconCreditBalances}
            emptyMessage={
              isError
                ? "Error in fetching data"
                : "There is no recent transactions available for the last 30 days"
            }
          />

          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}
      {!isError && (
        <>
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(data?.result) && (
              <>
                <div className={styles.header}>
                  <Text type="h2" weight="medium">
                    Summary
                  </Text>
                  {/* <div className={styles.headerOptions}>
              <Filter />
              <Sort />
            </div> */}
                </div>
                <Table
                  instance={tableInstance}
                  totalItems={data?.totalRecords}
                  startFrom={startFrom}
                  limit={limit}
                  setStart={setStart}
                />
              </>
            )
          )}
        </>
      )}
    </Panel>
  );
}
