import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./index.module.css";
import Table, { TableHeader } from "components/SixtyTable";
import ErrorModal from "components/Error";
import Loader from "components/Loader";
import EmptyGrid from "components/EmptyGrid";
import { PanelActionButton } from "components/SixtyPanel";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import Sort from "components/SixtyTable/components/Sort";
import ProductSalesSort from "./components/Sort";
import { useTable } from "react-table";
import { useGetSellerProductSales } from "queries/seller";
import { unknown_Error } from "common/constants";
import { ReactComponent as ProductIcon } from "assets/icons/Icon-Products.svg";
import { formatCurrency } from "utils/common";
import { ReactComponent as ImageIcon } from "assets/icons/Icon-Image.svg";

const ProductSales = ({ sellerId }) => {
  const history = useHistory();

  const onProductClick = useCallback(
    (value) => {
      const path = `/dashboard/products/${value}`;
      history.push(path);
    },
    [history]
  );

  const [sort, setSort] = useState("orderByAscending");
  const [search, setSearch] = useState("");

  const handleSortChange = useCallback(
    (field) => {
      setSort(field);
    },
    [setSort]
  );

  const handleSearch = (val) => setSearch(val);

  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    return {
      [sort]: true,
      keyword: search,
      sellerId,
      startFrom,
      limit,
    };
  }, [search, sellerId, sort, startFrom]);

  const {
    data: productSalesData,
    isLoading,
    isError,
    refetch,
    error,
  } = useGetSellerProductSales(getFilters());

  const columns = useMemo(
    () => [
      {
        Header: "Product name",
        accessor: "productName",
        Cell: ({ value, row }) => (
          <div className={styles.productItemContainer}>
            {row?.original?.imagePath ? (
              <div className={styles.productImg}>
                {" "}
                <img
                  className={styles.productImage}
                  src={row?.original?.imagePath}
                  alt="product"
                />{" "}
              </div>
            ) : (
              <div className={styles.imageIconBox}>
                <ImageIcon />
              </div>
            )}
            <div className={styles.productNameContainer}>
              <div
                title={value}
                className={[styles.productName, styles.elipsis].join(" ")}
              >
                {value}
              </div>
              <div className={[styles.brandName, styles.elipsis].join(" ")}>
                {/* ToDo: Remove when provided from api */}
                {row.original?.brandName || "brandName"}
              </div>
            </div>
          </div>
        ),
        style: {
          paddingLeft: 16,
        },
      },
      {
        Header: "Margins",
        accessor: "margins",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Total orders",
        accessor: "totalOrder",
      },
      {
        Header: "Total sales",
        accessor: "totalSales",
        Cell: ({ value }) => formatCurrency(value),
      },
    ],
    []
  );

  let data = useMemo(
    () => (productSalesData?.result ? productSalesData.result : []),
    [productSalesData]
  );

  const tableInstance = useTable({
    data,
    columns,
  });

  // Error modal
  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (!search && productSalesData?.result?.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      {(!Array.isArray(productSalesData?.result) || isDataEmpty()) &&
        !isLoading && (
          <>
            <EmptyGrid
              Icon={ProductIcon}
              emptyMessage={
                isError
                  ? "Error in fetching data"
                  : "The products will show up here"
              }
            />

            {isError && (
              <PanelActionButton onClick={() => refetch()}>
                Try Again
              </PanelActionButton>
            )}
          </>
        )}

      {!isError && !isDataEmpty() && (
        <>
          <TableHeader>
            <SearchFilter
              search={search}
              onSearchChange={handleSearch}
              placeholder="Search for products"
            />

            <Sort
              render={ProductSalesSort}
              state={sort}
              handleSortChange={handleSortChange}
            />
          </TableHeader>

          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(productSalesData?.result) && (
              <Table
                instance={tableInstance}
                totalItems={productSalesData?.totalRecords}
                startFrom={startFrom}
                limit={limit}
                setStart={setStart}
                // onRowClicked={(row) => onProductClick(row.original.id)}
              />
            )
          )}
        </>
      )}
    </>
  );
};

export default ProductSales;
