import clsx from "clsx";
import styles from "./index.module.css";

const CustomerDetails = ({ customer }) => {
  const {
    email,
    contact,
    transactionCount,
    billingAddress,
    shippingAddress,
  } = customer;

  return (
    <div className={styles.customerDetailContainer}>
      <div className={styles.row}>
        <div
          className={clsx(styles.fieldContainer, styles.emailFieldContainer)}
        >
          <div className={styles.fieldHeader}>Email address</div>
          <div className={clsx(styles.fieldValue, styles.emailFieldValue)}>
            {email}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldHeader}>Mobile number</div>
          <div className={styles.fieldValue}>{contact}</div>
        </div>
      </div>
      {/* <div className={styles.row}>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldHeader}>
            No of successful transactions
          </div>
          <div className={styles.fieldValue}>{transactionCount}</div>
        </div>
      </div> */}
      {shippingAddress && (
        <div className={styles.row}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldHeader}>Shipping address</div>
            <div className={styles.fieldValue}>{shippingAddress}</div>
          </div>
        </div>
      )}
      {billingAddress && (
        <div className={styles.row}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldHeader}>Billing address</div>
            <div className={styles.fieldValue}>{billingAddress}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDetails;
