import { SortItem } from "components/SixtyTable/components/Sort";

export default function SellerTierSort({ state, handleSortChange }) {
  return (
    <>
      <SortItem
        label="Tier name (A - Z)"
        name="orderByTierNameAscending"
        checked={state === "orderByTierNameAscending"}
        onChange={() => handleSortChange("orderByTierNameAscending")}
      />
      <SortItem
        label="Tier name (Z - A)"
        name="orderByTierNameDescending"
        checked={state === "orderByTierNameDescending"}
        onChange={() => handleSortChange("orderByTierNameDescending")}
      />
      <SortItem
        label="Commission rate (Ascending)"
        name="orderByDefaultDiscountAscending"
        checked={state === "orderByDefaultDiscountAscending"}
        onChange={() => handleSortChange("orderByDefaultDiscountAscending")}
      />
      <SortItem
        label="Commission rate (Descending)"
        name="orderByDefaultDiscountDescending"
        checked={state === "orderByDefaultDiscountDescending"}
        onChange={() => handleSortChange("orderByDefaultDiscountDescending")}
      />
      <SortItem
        label="No. of sellers (Ascending)"
        name="orderByNoOfSellerAscending"
        checked={state === "orderByNoOfSellerAscending"}
        onChange={() => handleSortChange("orderByNoOfSellerAscending")}
      />
      <SortItem
        label="No. of sellers (Descending)"
        name="orderByNoOfSellerDescending"
        checked={state === "orderByNoOfSellerDescending"}
        onChange={() => handleSortChange("orderByNoOfSellerDescending")}
      />
      <SortItem
        label="Created date (From newest)"
        name="orderByDescending"
        checked={state === "orderByDescending"}
        onChange={() => handleSortChange("orderByDescending")}
      />
      <SortItem
        label="Created date (From oldest)"
        name="orderByAscending"
        checked={state === "orderByAscending"}
        onChange={() => handleSortChange("orderByAscending")}
      />
    </>
  );
}
