import React from "react";
import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import { ReactComponent as IconWarning } from "assets/icons/Icon-Warning.svg";

const DeactivateSellerName = ({
  onDeactivateConfirm,
  onDeactivateCancel,
  data,
}) => {
  return (
    <React.Fragment>
      <div className={styles.DeleteSellerContainer}>
        <div className={styles.deleteSellerBody}>
          <div>
            <IconWarning />
          </div>
          <div className={styles.warningText}>
            {["Approved", "Activated"].includes(data?.status)
              ? `Are you sure you want to deactive ${data?.name} account?`
              : ["Suspended", "Rejected"].includes(data?.status)
              ? `Are you sure you want to activate ${data?.name} account?`
              : ""}
          </div>
        </div>
        <footer className={styles.footer}>
          <SixtyButton
            type="submit"
            variant="warning"
            className={styles.updateBtn}
            onClick={onDeactivateConfirm}
          >
            {data?.status && data.status === "Approved"
              ? "Deactivate"
              : data?.status === "Suspended"
              ? "Activate"
              : ""}
          </SixtyButton>
          <SixtyButton
            variant="secondary"
            className={styles.cancelButton}
            onClick={onDeactivateCancel}
          >
            Cancel
          </SixtyButton>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default DeactivateSellerName;
