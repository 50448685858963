import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useImmer } from "utils/hooks/useImmer";
import { useHistory } from "react-router-dom";
import { useGetCampaigns } from "queries/campaign";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import useRowSelectionTable from "utils/hooks/useRowSelectionTable";

import SixtyText from "components/SixtyText";
import Avatar from "components/Avatar";
import SixtyButton from "components/SixtyButton";
import Table, {
  RowSelectionContainer,
  TableHeader,
} from "components/SixtyTable";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import EmptyGrid from "components/EmptyGrid";

import { ReactComponent as SellerIcon } from "assets/icons/Icon-Sellers.svg";
import styles from "./index.module.css";
import ErrorModal from "components/Error";
import Loader from "components/Loader";
import { PanelActionButton } from "components/SixtyPanel";
import { format } from "date-fns";
import { unknown_Error } from "common/constants";
import { getFormattedDateString } from "utils/date.util";

export default function CampaignAnalyticTable({status, onCampaignClick}) {
  const history = useHistory();

  const [search, setSearch] = useState("");
  const handleSearch = (value) => setSearch(value);

  const [filterState, setFilterState] = useImmer({});
  const [sort, setSort] = useState("orderByDateJoinedDescending");

  const onFilterChange = (filterVal) => {
    setFilterState(() => filterVal);
  };

  const handleSortChange = useCallback(
    (field) => {
      setSort(field);
    },
    [setSort]
  );

  // pagination
  const [startFrom, setStart] = useState(0);
  const limit = 20;

  const getFilters = useCallback(() => {
    let filter = { ..._omit(filterState, ["date", "tierName"]) };

    let dateFilters = { ...(filterState.date ? filterState.date : {}) };

    if (!!dateFilters["custom"]) {
      delete dateFilters.custom;
      dateFilters = {
        fromDate: format(dateFilters.fromDate, "dd/MM/yyyy"),
        untilDate: format(dateFilters.untilDate, "dd/MM/yyyy"),
      };
    }

    filter = { ...filter, ...dateFilters };
    return {
      ...filter,
      [sort]: true,
      keyword: search,
      startFrom,
      limit,
      status: status ?? null
    };
  }, [filterState, search, sort, startFrom]);

  const {
    data: campaignData,
    isError,
    isLoading,
    error,
    refetch,
  } = useGetCampaigns(getFilters());

  const onRowClick = (row, e) => {
    onCampaignClick(row.original);
  };

  const columns = useMemo(
    () => [
    {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Assigned Candidates",
        accessor: "joinedSellerCount",
      },
      {
        Header: "Price per content",
        accessor: "price",
      },
      {
        Header: "Total",
        accessor: "total",
      },
      {
        Header: "Progress",
        accessor: "progressStatus",
      },
      // {
      //   Header: "Campaign Type",
      //   accessor: "campaignType",
      // },
    ],
    []
  );

  let data = useMemo(() => (campaignData?.result ? campaignData.result : []), [
    campaignData,
  ]);

  const tableInstance = useRowSelectionTable({
    data,
    columns,
  });

  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty() {
    if (_isEmpty(filterState) && !search && campaignData?.result?.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      {(!Array.isArray(campaignData?.result) || isDataEmpty()) && !isLoading && (
        <>
          <EmptyGrid
            Icon={SellerIcon}
            emptyMessage={
              isError
                ? "Error in fetching data"
                : "Your campaigns will show up here"
            }
          />
          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}
      {!isError && !isDataEmpty() && (
        <>
          {tableInstance.selectedFlatRows.length > 0 ? (
            <RowSelectionContainer
              renderRowSelectionText={() => (
                <>
                  <SixtyText>
                    {tableInstance.selectedFlatRows.length} campaigns selected
                  </SixtyText>
                  <SixtyButton
                    className={styles.unselectButton}
                    variant="text"
                    onClick={() => tableInstance.toggleAllRowsSelected(false)}
                  >
                    unselect all
                  </SixtyButton>
                </>
              )}
              renderRowSelectionButtons={() => (
                <>
                </>
              )}
            />
          ) : (
            <>
              <TableHeader>
                <SearchFilter
                  search={search}
                  onSearchChange={handleSearch}
                  placeholder="Search for campaigns"
                />
              </TableHeader>
            </>
          )}
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(campaignData?.result) && (
              <Table
                instance={tableInstance}
                totalItems={campaignData?.totalRecords}
                startFrom={startFrom}
                limit={limit}
                setStart={setStart}
                onRowClicked={onRowClick}
              />
            )
          )}
        </>
      )}
    </>
  );
}
