import styles from "./index.module.css";
import CheckBox from "components/SixtyCheckBox";
import clsx from "clsx";

const NotificationRow = ({
  settingsName,
  settingsTitle,
  settingsDescription,
  isNotificationEnabled,
  setNotificationPreference,
  className,
}) => {
  return (
    <div className={clsx(styles.row, className)}>
      <div className={styles.firstCol}>{settingsTitle}</div>
      <div className={styles.secondCol}>{settingsDescription}</div>
      <div className={styles.thirdCol}>
        <CheckBox
          name={settingsName}
          onClick={(e) =>
            setNotificationPreference(e.target.checked, settingsName)
          }
          checked={isNotificationEnabled}
        />
      </div>
    </div>
  );
};

export default NotificationRow;
