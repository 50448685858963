import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useForm, FormProvider } from "react-hook-form";
import { useApproveCampaignRequest, useRejectCampaignRequest, useUpdateSellerCampaignShipment } from "queries/campaign";
import { useUpdateStatus } from "queries/posting";
import { useGetSellerRegistrationDetail } from "queries/seller";
import { useGetCampaignSellerDetail } from "queries/campaign";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import SixtyText from "components/SixtyText";

import styles from "./index.module.css";
import Loader from "components/Loader";
import ReactWhatsapp from 'react-whatsapp';
import Input from "components/FormComponents/ControlledInput";


const defaultValues = {
  shippingCompany: '',
  trackingCode: ''
};

const ContentDetails = ({campaign, selectedSeller, handleClose, viewOnly = false }) => {
  const { code, id } = selectedSeller;
  const role = localStorage.getItem("userRole");

  const formMethods = useForm({
    defaultValues,
  });

  const { setValue } = formMethods;

  const { data: sellerInfo, isLoading, isError, refetch } = useGetSellerRegistrationDetail(
    code
  );

  const { data: sellerRequestInfo, isLoading: isCampaignSellerLoading, isFetchedAfterMount } = useGetCampaignSellerDetail(
    id
  );

  useEffect(() => {

  //   if (sellerRequestInfo && isFetchedAfterMount) {

  //    for (const [key, value] of Object.entries({
  //       shippingCompany: sellerRequestInfo?.shippingCompany,
  //       trackingCode: sellerRequestInfo?.trackingCode,
  //    })) {
  //      setValue(key, value);
  //    }
  //  }
 
   }, [setValue, sellerRequestInfo, isFetchedAfterMount]);

  const onIgChat = () => {    
    const path = `${sellerRequestInfo?.instagramChatLink}`;
    window.open(path, "_blank")
  };

  const onCheckGoogleDrive = () => {    
    const path = `${sellerRequestInfo?.googleDriveLink}`;
    window.open(path, "_blank")
  };

  const { mutateAsync: updateStatus, 
          isLoading : statusUpdating 
  } = useUpdateStatus();

  const onApprovePayout = async () => {
    try {
      const model = {
        id: sellerRequestInfo?.postingId,
        status: "Approved"
     };

     var result = await updateStatus(model);

     if(result){
        refetch();
        setToast("successfully update status");
        handleClose();
     }
    } catch (e) {
      reportError(
        "error in : ContentDetails: onApprovePayout, error: ",
        e
      );
    }
  };

 
  const { mutateAsync: updateShipment, 
      isLoading : shipmentLoading 
  } = useUpdateSellerCampaignShipment();

  const onUpdateShipment = async (data) => {
    try {

      const model = {
          id: id,
          shippingCompany: data.shippingCompany,
          trackingCode: data.trackingCode
      };

      const result = await updateShipment(model);

      if (result) {
        handleClose();
        setToast("successfully update shipping information");
      }
    } catch (e) {
      reportError(
        "error in : RecruitmentDetails: onUpdateShipment, error: ",
        e
      );
    }
  };


  return (
    <React.Fragment>
      <div className={styles.sellerRequestFormContainer}>
        {(isLoading || isCampaignSellerLoading) && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.sellerRequestForm} hidden={isLoading || isCampaignSellerLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Account Information"
            />
            {/* <div className={styles.btnActionContainer}>   
              {sellerInfo?.contactNumber && (                    
                <ReactWhatsapp number={sellerInfo?.contactNumber} message="" className={clsx(styles.button, styles.tertiary, styles.approveBtn)} disabled={["Pending"].includes(sellerRequestInfo?.status)}>
                  Chat with Whatapps
                </ReactWhatsapp>            
              )}
              {sellerRequestInfo?.instagramChatLink && (      
                <SixtyButton
                  onClick={onIgChat}
                  variant={'warning'}
                  className={styles.approveBtn}
                  disabled={["Pending"].includes(sellerRequestInfo?.status)}
                >
                  Chat With Instagram
                </SixtyButton>
              )}
            </div>
            <SixtyText className={styles.message}>
                  {'Chat and negotiate with the creator, if you have changes need to revise, our creator can revise your content up to max 2 times.'}
            </SixtyText> */}
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerInfoItem)}>
                  <div className={styles.sellerInfoHeader}>Name</div>
                  <div>
                    {sellerInfo?.name}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}> Gender </div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.gender}
                  >
                    {sellerInfo?.gender}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Race</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.race}
                  >
                    {sellerInfo?.race}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          {/* <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Creator Payout"
            />
            <SixtyText className={styles.message}>
                  {'Proceed creator payout once you confirm received the right video content.'}
            </SixtyText>
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerFullInfoItem)}>
                  <div className={styles.sellerInfoHeader}>Amount</div>
                  <div>
                    {'RM ' +campaign?.price}
                  </div>
                </div>
              </div>
            </div>
          </Panel> */}

          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Uploaded Content"
            />
            <div className={styles.btnActionContainer}>      
                <SixtyButton
                  onClick={onCheckGoogleDrive}
                  variant={'tertiary'}
                  className={styles.approveBtn}
                  disabled={!sellerRequestInfo?.googleDriveLink}
                >
                  Check uploaded content
                </SixtyButton>
            </div>
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerFullInfoItem)}>
                  <div className={styles.sellerInfoHeader}>Content Link</div>
                  <div>
                    {sellerRequestInfo?.googleDriveLink? sellerRequestInfo?.googleDriveLink : "Awaiting Upload"}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
   
          </div>
        </div>

        <div style={{height:50}} ></div>
        <footer className={styles.btnFooter} hidden={isLoading}>
          {["Pending"].includes(sellerRequestInfo?.jobStatus) && (
            <>
              <SixtyButton
                onClick={onApprovePayout}
                className={styles.approveBtn}
                disabled={statusUpdating || shipmentLoading || !sellerRequestInfo?.postingId}
              >
                Approve contents
              </SixtyButton>
            </>
          )}

          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={statusUpdating}
          >
            Close
          </SixtyButton>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default ContentDetails;
