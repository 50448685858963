import React, { useState, useRef, useEffect, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _cloneDeep from "lodash/cloneDeep";
import {
  useDeleteDiscount,
  useGetDiscountDetail,
  useUpdateDiscount,
} from "queries/discount";

import Modal from "components/Modal";
import Panel from "components/SixtyPanel";
import BasicInfo from "../components/BasicInfo";
import PanelHeader from "components/PanelHeader";
import SixtyButton from "components/SixtyButton";
import DiscountDetails from "../components/DiscountDetails";
import DiscountDates from "../components/DiscountDates";
import DiscountSettings from "../components/DiscountSettings";

import styles from "./index.module.css";
import { setToast } from "components/Toast";
import { useImmer } from "utils/hooks/useImmer";
import clsx from "clsx";
import SixtyText from "components/SixtyText";

const defaultValues = {
  name: "",
  description: "",
  discountCode: "",
  status: "",
  discountAmountMeasurement: "",
  discountAmount: 10,
  startDate: "",
  expiredDate: null,
  settings: {
    limitPerUserCondition: "",
    reusablePerUser: 1,
    minimumOrderCondition: "",
    minimumOrderValue: 10,
    usageLimitCondition: "",
    usageLimit: 1,
    appliedProducts: [],
  },
};

const DiscountDetailsForm = () => {
  const { id } = useParams();

  const [modalIsOpen, setModalIsOpen] = useState(true);
  const createDiscountRef = useRef();

  const { data: discountData } = useGetDiscountDetail(id);

  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/dashboard/discounts");
  }, [history]);

  const schema = yup.object().shape({
    discountCode: yup.string().required("Add a discount code"),
    name: yup.string().required("Add a name"),
    description: yup.string().nullable(),
    discountAmountMeasurement: yup.string().required("Required"),
    discountAmount: yup.number().required("Add discount amount"),
    maximumDiscountValue: yup.number().nullable(),
    startDate: yup.string().required("Add start date"),
    expiredDate: yup.string().nullable(),
    status: yup.string().required("Add a status"),

    Settings: yup.object().shape({
      limitPerUserCondition: yup.string().required("required"),
      reusablePerUser: yup.number().nullable(),
      usageLimitCondition: yup.string().required("required"),
      usageLimit: yup.number().nullable(),
      minimumOrderCondition: yup.string().required("required"),
      minimumOrderValue: yup.number().nullable(),
      appliedProducts: yup
        .array()
        .of(
          yup.object({
            name: yup.string().nullable(),
            productId: yup.string().nullable(),
          })
        )
        .nullable(),
    }),
  });

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
    mode: "onChange",
  });

  const { reset } = formMethods;

  const [state, setState] = useImmer(() => {});

  useEffect(() => {
    if (!!discountData) {
      let data = _cloneDeep(discountData);
      data.Settings = { ...data.settings };
      delete data.settings;
      setState(() => data);
    }
  }, [discountData, setState]);

  useEffect(() => {
    reset(state);
  }, [reset, state]);

  const { mutateAsync: update, isSuccess } = useUpdateDiscount();

  const onSubmit = async (data) => {
    try {
      const updateData = _cloneDeep(data);
      updateData.Settings = { ...updateData.settings };
      delete updateData.settings;

      await update(data);
    } catch (error) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      setToast("Promotion successfully updated");
    }
  }, [handleClose, isSuccess]);

  // delete
  const {
    mutateAsync: deleteDiscount,
    isSuccess: deleteSuccess,
  } = useDeleteDiscount();
  const onDelete = async () => {
    try {
      await deleteDiscount(id);
    } catch (e) {}
  };

  useEffect(() => {
    if (deleteSuccess) {
      setToast("Promotion deleted successfully");
      handleClose();
    }
  }, [deleteSuccess]);

  const Title = () => {
    return (
      <div className={styles.titleContainer}>
        <div className={styles.newDiscountTitle}>
          {discountData?.discountCode}
        </div>
        <div>
          <SixtyText color="secondary" inline>
            Status:
          </SixtyText>
          <span
            className={clsx(
              styles.statusDot,
              discountData?.status === "Disabled" && styles.statusDisabled,
              discountData?.status === "Enabled" && styles.statusActive
            )}
          ></span>
          <SixtyText inline>{discountData?.status}</SixtyText>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAttemptClose={() => {
        history.push("/dashboard/promotions");
        setModalIsOpen(false);
      }}
      modalRef={createDiscountRef}
      Title={Title}
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          className={styles.form}
        >
          <div className={styles.itemsContainer}>
            <Panel className={styles.panelWrap}>
              <PanelHeader title="1. Basic info" />
              <BasicInfo />
            </Panel>
            <Panel className={styles.panelWrap}>
              <PanelHeader title="2. Discount details" />
              <DiscountDetails />
            </Panel>
            <Panel className={styles.panelWrap}>
              <PanelHeader title="3. Discount dates" />
              <DiscountDates
                startDate={discountData?.startDate}
                expiredDate={discountData?.expiredDate}
              />
            </Panel>
            <Panel className={styles.panelWrap}>
              <PanelHeader title="4. Settings" />
              <DiscountSettings />
            </Panel>
          </div>

          <footer className={styles.footer}>
            <SixtyButton type="submit">Update</SixtyButton>
            <SixtyButton
              variant="warning"
              className={styles.cancelButton}
              onClick={onDelete}
            >
              Delete
            </SixtyButton>
            <SixtyButton
              variant="secondary"
              className={styles.cancelButton}
              onClick={handleClose}
            >
              Cancel
            </SixtyButton>
          </footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default DiscountDetailsForm;
