import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import AffiliateContentTable from "../components/AffiliateContentTable";
import AffiliateContentDetails from "../components/AffiliateContentDetails";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import { setToast } from "components/Toast";
import BasicPageHeader from "../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetAffiliateContentCount } from "queries/posting";

const AffiliateContent = () => {
  const [isPostOpen, setPostOpen] = useState(false);
  const history = useHistory();
  const postRef = useRef();
  const [selectedPost, setSelectedPost] = useState({});
  const role = localStorage.getItem("userRole");

  const {
    data: requestData,
  } = useGetAffiliateContentCount();

  const PostTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedPost.title} />
          </div>
          <div className={styles.modalTitle}>{selectedPost.title}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPostOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onContentClick = (value) => {
    setSelectedPost(value);
    setPostOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
    <GridHeader header="Affiliate Contents" 
         renderButtons={() => (
          <>
          </>
         )}
      />

      <Panel className="tabs">
          <Tabs
            activeTab={"1"}
            tabs={[
              {
                tabId: "1",
                label: "Active",
                recordsCount: requestData?.activeContentCount,
                TabComponent: AffiliateContentTable,
                props: {
                  status: "Completed",
                  onContentClick: onContentClick
                },
              },
              {
                tabId: "2",
                label: "Trash",
                recordsCount: requestData?.suspendedContentCount,
                TabComponent: AffiliateContentTable,
                props: {
                  status: "Suspended",
                  onContentClick: onContentClick
                },           
              },
            ]}
          ></Tabs>
        </Panel>

      {isPostOpen && (
        <Modal
          isOpen={isPostOpen}
          onAttemptClose={() => {
            setPostOpen(false);
          }}
          modalRef={postRef}
          Title={PostTitle}
        >
          <AffiliateContentDetails
            selectedPost={selectedPost}
            handleClose={() => setPostOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default AffiliateContent;
