import Checkbox from "components/SixtyCheckBox";
import {
  FilterGroup,
  FilterItem,
  FilterChipContainer,
  ChipItem,
} from "components/SixtyTable/components/Filter";

export const labelMap = {
  active: "Active",
  disabled: "Disabled",
};

export default function DiscountFilter({ state, setState }) {
  const handleFieldChange = (fieldName, value) => {
    setState((filter) => {
      if (value && !state[fieldName]) {
        filter[fieldName] = true;
      }
      if (!value) {
        delete filter[fieldName];
      }
    });
  };
  return (
    <>
      <FilterGroup label="Inventory status">
        <FilterItem>
          <Checkbox
            label="Active"
            value={"active"}
            checked={!!state?.active}
            onChange={(e) => {
              handleFieldChange("active", e.target.checked);
            }}
          />
        </FilterItem>
        <FilterItem>
          <Checkbox
            label="Disabled"
            value={"disabled"}
            checked={!!state?.disabled}
            onChange={(e) =>
              handleFieldChange("disabled", e.target.checked)
            }
          />
        </FilterItem>
      </FilterGroup>
    </>
  );
}

export function renderFilterChips(filterState, setFilterState) {
  let filtersPresent = false;
  for (const filterKey in filterState) {
     if (!!filterState[filterKey]) {
      filtersPresent = true;
    }
  }

  const removeFilter = (filterItem) => {
    setFilterState((filter) => {
      delete filter[filterItem];
    });
  };

  if (filtersPresent) {
    let filters = filterState || {};

    return (
      <FilterChipContainer>
        {Object.keys(filters).length > 0 &&
          Object.keys(filters)?.map((filterItem) => (
            <>
              <ChipItem
                label={labelMap[filterItem]}
                name={filterItem}
                handleRemove={removeFilter}
                key={filterItem}
              />
            </>
          ))}
      </FilterChipContainer>
    );
  }
  return null;
}
