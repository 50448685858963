import React, { useRef } from "react";
import styles from "./index.module.css";
import { ReactComponent as CopyContentsIcon } from "assets/icons/Icon-Copy-Content.svg";
import SixtyButton from "components/SixtyButton";
import { setToast } from "components/Toast";

const AddressDetails = ({ data }) => {
  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    let innerText = ``;
    if (data?.shippingAddress?.unitNumber)
    innerText = `${innerText}${data?.shippingAddress.unitNumber}
    `;
    if (data?.shippingAddress?.floorNumber)
    innerText = `${innerText}${data?.shippingAddress.floorNumber}
    `;
    if (data?.shippingAddress?.buildingName)
      innerText = `${innerText}${data?.shippingAddress.buildingName}
      `;
    if (data?.shippingAddress?.street1)
      innerText = `${innerText}${data?.shippingAddress.street1}
      `;
    if (data?.shippingAddress?.street2)
      innerText = `${innerText}${data?.shippingAddress.street2}
      `;
    if (data?.shippingAddress?.street3)
      innerText = `${innerText}${data?.shippingAddress.street3}
      `;
    if (data?.shippingAddress?.buildingName)
      innerText = `${innerText}${data?.shippingAddress.buildingName}
      `;
    if (data?.shippingAddress?.postcode && data?.shippingAddress?.city)
      innerText = `${innerText}${data?.shippingAddress?.postcode}, ${data?.shippingAddress.city}
      `;
    if (data?.shippingAddress?.state && data?.shippingAddress?.country)
      innerText = `${innerText}${data?.shippingAddress.state}, ${data?.shippingAddress.country}
      `;
    textField.innerText = innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (innerText !== "") setToast("Address copied to clipboard");
    else setToast("No content to copy", "error");
  };

  return (
    <React.Fragment>
      <div className={styles.customerBody}>
        {(data?.shippingAddress?.buildingName ||
          data?.shippingAddress?.street1 ||
          data?.shippingAddress?.street2 ||
          data?.shippingAddress?.street3 ||
          data?.shippingAddress?.postcode ||
          data?.shippingAddress?.city ||
          data?.shippingAddress?.state ||
          data?.shippingAddress?.country) && (
          <div className={styles.shippingAddress}>
            <div className={styles.customerFieldName}>Address</div>
            <div className={styles.customerFieldValue}>
              <div className={styles.addressContent}>
                {data?.shippingAddress?.unitNumber && (
                  <div>{data?.shippingAddress?.unitNumber}</div>
                )}
                {data?.shippingAddress?.floorNumber && (
                  <div>{data?.shippingAddress?.floorNumber}</div>
                )}
                {data?.shippingAddress?.buildingName && (
                  <div>{data?.shippingAddress?.buildingName}</div>
                )}
                {data?.shippingAddress?.street1 && (
                  <div>{data?.shippingAddress?.street1},</div>
                )}
                {data?.shippingAddress?.street2 && (
                  <div>{data?.shippingAddress?.street2},</div>
                )}
                {data?.shippingAddress?.street3 && (
                  <div>{data?.shippingAddress?.street3},</div>
                )}
                {data?.shippingAddress?.postcode &&
                  data?.shippingAddress?.city && (
                    <div>
                      {data?.shippingAddress?.postcode}{" "}
                      {data?.shippingAddress.city},
                    </div>
                  )}
                {data?.shippingAddress?.state &&
                  data?.shippingAddress?.country && (
                    <div>
                      {data?.shippingAddress?.state}{" "}
                      {data?.shippingAddress.country}
                    </div>
                  )}
              </div>
              <span>
                <CopyContentsIcon onClick={copyToClipboard} />
              </span>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AddressDetails;
