import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetSellerRegistrationDetail, useIssuedPayout } from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

const PayoutForm = ({ selectedSeller, handleClose }) => {

  const defaultValues = {
    issuedToReference: '',
    issuedToCode: '',
    issuedToName: '',
    issuedToId: '',
    amount: 0,
    remark: ''
  };
  const formMethods = useForm({
     defaultValues,
  });

  const { code } = selectedSeller;

  const { data: sellerInfo, isLoading, isError, refetch } = useGetSellerRegistrationDetail(
    code
  );

  const { mutateAsync: issuedPayout, 
          isLoading : payoutLoading 
        } = useIssuedPayout();

  const onIssuedPayout = async (data) => {
    try {

      const model = {
        issuedToReference: '',
        issuedToCode: sellerInfo?.code,
        issuedToName: '',
        issuedToId: '',
        amount: parseFloat(data.amount), 
        remark: data.remark,
        password: data.password
      };

      const result = await issuedPayout(model);

      if (result) {
        handleClose();
        setToast("successfully issue payout");
      }
    } catch (e) {
      reportError(
        "error in : PayoutForm: onIssuedPayout, error: ",
        e
      );
    }
  };

    const onCancel = async () => {
        try {
            handleClose();
        } catch (e) {
          reportError(
            "error in : PayoutForm: onCancel, error: ",
            e
          );
        }
    };

  return (
    <React.Fragment>
      <div className={styles.payoutFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.payoutRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.payoutInfoHeader}
              title="Account Information"
            />
            <div className={styles.payoutInfoContainer}>
              <div className={styles.payoutInfoRow}>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Code</div>
                  <div>
                    {sellerInfo?.code}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={clsx(styles.email, styles.elipsis, styles.payoutInfoHeader)}>Email</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.email}
                  >
                    {sellerInfo?.email}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Contact</div>
                  <div>
                    {sellerInfo?.contactNumber}
                  </div>
                </div>
              </div>

              <div className={styles.payoutInfoRow}>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Name</div>
                  <div>{sellerInfo?.name}</div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Occupation</div>
                  <div>{sellerInfo?.occupation}</div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}> Register Date </div>
                  <div>{sellerInfo?.registerDate}</div>
                </div>
              </div>

            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.payoutInfoHeader}
              title="Payout"
            />
          <FormProvider {...formMethods}>
            <form
                className={styles.form}
            >
                <div className={styles.row}>             
                      <Input
                        name="amount"
                        type="number"
                        defaultValue={undefined}
                        label="Amount"
                        step=".01"
                        min={0}
                        className={styles.nameInput}
                      />                 
                </div>
                <div className={styles.row}>
                    <Input
                        name="remark"
                        label="Remark"
                        defaultValue=""
                        className={styles.nameInput}
                    />
                </div>
                <div className={styles.row}>
                    <Input
                        name="password"
                        label="Password"
                        type={"password"}
                        defaultValue=""
                        className={styles.nameInput}
                    />
                </div>
            </form>
        </FormProvider>
         </Panel>

   
        </div>
        </div>

        <footer className={styles.footer} hidden={isLoading}>
          <SixtyButton
            type="submit"
            onClick={formMethods.handleSubmit(onIssuedPayout)}
            className={styles.approveBtn}
            disabled={payoutLoading}
          >
            Confirm Payment
          </SixtyButton>
          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            disabled={payoutLoading}
            onClick={onCancel}
          >
            Cancel
          </SixtyButton>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default PayoutForm;
