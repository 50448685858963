import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import PostingTable from "../components/PostingTable";
import CampaignContentDetail from "../../components/CampaignContentDetails";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import BasicPageHeader from "../../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetCampaignPublishContentRequestCount } from "queries/posting";

const Posting = ({campaign}) => {
  const [isPostOpen, setPostOpen] = useState(false);
  const history = useHistory();
  const params = useParams();
  const postRef = useRef();
  const [selectedPost, setSelectedPost] = useState({});
  const role = localStorage.getItem("userRole");

  const {
    data: requestData,
  } = useGetCampaignPublishContentRequestCount(params.id);

  const PostTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedPost.title} />
          </div>
          <div className={styles.modalTitle}>{selectedPost.title}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPostOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onContentClick = (value) => {
    setSelectedPost(value);
    setPostOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <BasicPageHeader title="Posted Contents" onGoBack={onGoBack} disableBack={true} />
      {role == 'InternalUser' && (
               <Panel className="tabs">
               <Tabs
                 activeTab={"1"}
                 tabs={[
                   {
                       tabId: "1",
                       label: "Posted Content",
                       recordsCount: requestData?.postedContentCount,
                       TabComponent: PostingTable,
                       props: {
                         status: "",
                         campaignId: params.id,
                         onContentClick: onContentClick
                       },
                   },
                   {
                     tabId: "4",
                     label: "Trash",
                     recordsCount: requestData?.suspendedContentCount,
                     TabComponent: PostingTable,
                     props: {
                       status: "Suspended",
                       campaignId: params.id,
                       onContentClick: onContentClick
                     },           
                   },
                 ]}
               ></Tabs>
             </Panel>
      )}

      {role == 'Supplier' && (
               <Panel className="tabs">
               <Tabs
                 activeTab={"1"}
                 tabs={[
                   {
                       tabId: "1",
                       label: "Posted Content",
                       TabComponent: PostingTable,
                       props: {
                         status: "",
                         campaignId: params.id,
                         onContentClick: onContentClick
                       },
                   },
                  //  {
                  //    tabId: "2",
                  //    label: "Pending Repost",
                  //    recordsCount: requestData?.rePostContentCount,
                  //    TabComponent: PostingTable,
                  //    props: {
                  //      status: "Repost",
                  //      campaignId: params.id,
                  //      onContentClick: onContentClick
                  //    },           
                  //  },
                  //  {
                  //    tabId: "3",
                  //    label: "Completed",
                  //    recordsCount: requestData?.completedContentCount,
                  //    TabComponent: PostingTable,
                  //    props: {
                  //      status: "Completed",
                  //      campaignId: params.id,
                  //      onContentClick: onContentClick
                  //    },           
                  //  },
                 ]}
               ></Tabs>
             </Panel>
      )}

      {isPostOpen && (
        <Modal
          isOpen={isPostOpen}
          onAttemptClose={() => {
            setPostOpen(false);
          }}
          modalRef={postRef}
          Title={PostTitle}
        >
          <CampaignContentDetail
            selectedPost={selectedPost}
            handleClose={() => setPostOpen(false)}
            viewOnly={campaign?.campaignType == 'AffiliateEvent' && role == 'Supplier'}
          />
        </Modal>
      )}
    </div>
  );
};

export default Posting;
