import { queryClient } from "common/query";
import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";

export function useJoinAffiliateProgram() {
  return useMutation(() =>
    api
      .put(Endpoints.Dashboard.JoinAffiliateProgram)
      .then((response) => response?.data),
      {
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError("useJoinAffiliateProgram: useMutation: onError: error", error);
        },
      }
  );
}

export function useGetSupplierCompanyStatus() {
  return useQuery(
    Endpoints.Dashboard.GetSupplierCompanyStatus,
    () =>
      api
        .get(Endpoints.Dashboard.GetSupplierCompanyStatus)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGetSupplierCompanyStatus: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetStepsCompletion() {
  return useQuery(
    Endpoints.Dashboard.GetStepsCompletion,
    () =>
      api
        .get(Endpoints.Dashboard.GetStepsCompletion)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGetStepsCompletion: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetAdminReporting(month) {
  return useQuery(
    [Endpoints.Dashboard.GetAdminReporting + (month ? "?reportDate=" + month : "")],
    () =>
      api
        .get(
          Endpoints.Dashboard.GetAdminReporting +
          (month ? "?reportDate=" + month : "")
        )
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGetAdminReporting: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetAdminSummaryReport(month) {
  return useQuery(
    [Endpoints.Dashboard.GetAdminSummaryReport, month],
    () =>
      api
        .get(
          Endpoints.Dashboard.GetAdminSummaryReport +
            (month ? "?reportDate=" + month : "")
        )
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGetAdminSummaryReport: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSummaryReport(month) {
  return useQuery(
    [Endpoints.Dashboard.GetSummaryReport, month],
    () =>
      api
        .get(
          Endpoints.Dashboard.GetSummaryReport +
            (month ? "?reportDate=" + month : "")
        )
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGetSummaryReport: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetNewOrders() {
  return useQuery(
    Endpoints.Dashboard.GetNewOrders,
    () =>
      api
        .get(Endpoints.Dashboard.GetNewOrders)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGetNewOrders: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSideMenuRecordCount() {
  return useQuery(
    Endpoints.Dashboard.GetSideMenuRecordCount,
    () =>
      api
        .get(Endpoints.Dashboard.GetSideMenuRecordCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useGetSideMenuRecordCount: useQuery: onError: error",
          error
        );
      },
      staleTime: 60 * 1000,
    }
  );
}

export function useGlobalSearch(filter) {
  return useQuery(
    [Endpoints.Dashboard.GlobalSearch, filter],
    () =>
      api
        .post(Endpoints.Dashboard.GlobalSearch, filter)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useGlobalSearch: useQuery: onError: error", error);
      },
    }
  );
}


