import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetCreditDetail  } from "queries/admin";
import { reportError } from "crashlytics";
import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Modal from "components/Modal";
import Loader from "components/Loader";

import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

const CreditHistoryDetails = ({ selectedCredit, handleClose }) => {
  const { id } = selectedCredit;

  const { data: creditInfo, isLoading, isError, refetch } = useGetCreditDetail(
    id
  );

  const onCancel = async () => {
    try {
        handleClose();
    } catch (e) {
      reportError(
        "error in : CreditHistoryDetails: onCancel, error: ",
        e
      );
    }
};

  return (
    <React.Fragment>
      <div className={styles.payoutFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.payoutRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.payoutInfoHeader}
              title="Credit Information"
            />
            <div className={styles.payoutInfoContainer}>
              <div className={styles.payoutInfoRow}>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Code</div>
                  <div>
                    {creditInfo?.transactionCode}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={clsx(styles.payoutInfoHeader)}>Influencer</div>
                  <div>
                    {creditInfo?.sellerName}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Status</div>
                  <div>
                    {creditInfo?.status}
                  </div>
                </div>
              </div>

              <div className={styles.payoutInfoRow}>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Credit</div>
                  <div>
                    {creditInfo?.credit}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={clsx(styles.payoutInfoHeader)}>Debit</div>
                  <div>
                    {creditInfo?.debit}
                  </div>
                </div>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Usage Type</div>
                  <div>
                    {creditInfo?.usageType}
                  </div>
                </div>
              </div>

              <div className={styles.payoutInfoRow}>
                <div className={styles.payoutInfoItem}>
                  <div className={styles.payoutInfoHeader}>Transaction Date</div>
                  <div>
                    {creditInfo?.transactionDate}
                  </div>
                </div>
              </div>

            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.payoutInfoHeader}
              title="Credit Summary"
            />
            <div className={styles.payoutInfoContainer}>
              <div className={styles.payoutInfoRow}>
                <div className={styles.payoutFullInfoItem}>
                  <div className={styles.payoutInfoHeader}>Summary</div>
                  <div>
                  {creditInfo?.summary} 
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </div>
        </div>

        <footer className={styles.footer} hidden={isLoading}>
          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            disabled={isLoading}
            onClick={onCancel}
          >
            Close
          </SixtyButton>
        </footer>
      </div>

    </React.Fragment>
  );
};

export default CreditHistoryDetails;
