import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetBlogDetailById, useUpdateBlog, usePublishOrUnpublishBlog } from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import { useUploads } from "queries/media";
import styles from "./index.module.css";
import Loader from "components/Loader";
import Form from "./components/Form";
import { format } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import BasicPageHeader from "../../Components/BasicHeader";
import { isEmpty, isArray } from 'lodash';

const defaultValues = {
    supplierReference: '',
    supplierCode: '',
    supplierName: '',
    supplierId: '',
    title: '',
    description: '',
    imageUrl: '',
    keywords: '',
    content: '',
    isPublished: false,
 };

const UpdateBlog = () => {

const history = useHistory();
const params = useParams();

const [newBlog, setCreatedBlog] = useState();
const [blogModel, setBlogModel] = useState();
const [blogCreated, setBlog] = useState(false);
const [content, setContent] = useState();


 const formMethods = useForm({
    defaultValues,
 });

  const { setValue } = formMethods;
  
  const { data: blogInfo, isLoading, isError, refetch , isFetchedAfterMount} = useGetBlogDetailById(
    params.id
  );

  useEffect(() => {

    if (blogInfo && isFetchedAfterMount) {
      
     setContent(blogInfo?.content);
     for (const [key, value] of Object.entries({
         title: blogInfo?.title,
         description: blogInfo?.description,
         keywords: blogInfo?.keywords,
     })) {
       setValue(key, value);
     }
   }
 
   }, [setValue, blogInfo, isFetchedAfterMount]);

  const {
    mutateAsync: uploadFiles,
    isLoading: uploadingFiles,
  } = useUploads();

  const onBrowseBlogProducts = () => {    
    const path = `/admin/blogs/blog-products/${params.id}`;
    window.open(path, "_blank")
  };

  const onPreview = () => {    
    window.open(blogInfo?.permalink, "_blank")
  };

  const { mutateAsync: updateBlog, 
          isLoading : blogLoading 
        } = useUpdateBlog();

  const onSubmit = async (data) => {
    try {

      let newImageUrl = null;
      if (isArray(data.replaceImageUrl) && data.replaceImageUrl?.length > 0) {
        const images = new FormData();

        images.append(`files[0].fileName`, data.replaceImageUrl[0].name);
        images.append("files[0].file", data.replaceImageUrl[0]);
  
        const result = await uploadFiles(images);
  
        const path = result?.length ? result[0].path : '';
        newImageUrl = path;
      }

        const model = {
            id: params.id,
            title: data.title,
            description: data.description,
            imageUrl: newImageUrl ?? blogInfo?.imageUrl,
            keywords: data.keywords,
            content: content,
         };

         var response = await updateBlog(model);

         if(response){
            refetch();
            setToast("successfully save changes");
         }
        
    } catch (e) {
      reportError(
        "error in : CampaignForm: onSubmit, error: ",
        e
      );
    }
  };

  const { mutateAsync: publishOrUnpublishBlog, 
          isLoading : publishLoading 
  } = usePublishOrUnpublishBlog();

  const onPublishOrUnpublish = async () => {
    try {

         var response = await publishOrUnpublishBlog(params.id);

         if(response){
            refetch();
            setToast("successfully save changes");
         }
        
    } catch (e) {
      reportError(
        "error in : CreateBlog: onSubmit, error: ",
        e
      );
    }
  };

   const copyToClipboard = () => {
      const textField = document.createElement("textarea");
      let innerText = ``;
      if (blogInfo?.permalink){
          innerText = `${blogInfo?.permalink}`;
      }
          
      textField.innerText = innerText;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      if (innerText !== "") setToast("Permalink copied to clipboard");
      else setToast("No content to copy", "error");
    };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}
      <BasicPageHeader title="Update Blog" onGoBack={onGoBack} hidden={isLoading} />
      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Vendor details"
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>Store name</div>
                  <div
                    className={styles.elipsis}
                    title={blogInfo?.supplierName}
                  >
                    {blogInfo?.supplierName}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                     Email
                  </div>
                  <div>
                    {blogInfo?.supplierEmail}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Code</div>
                  <div
                    className={styles.elipsis}
                    title={blogInfo?.supplierCode}
                  >
                    {blogInfo?.supplierCode}
                  </div>
                </div>
              </div>

            </div>
          </Panel>

          <Panel className={styles.panelWrap}>
              <PanelHeader
                className={styles.campaignInfoHeader}
                title="Products"
              />
              <div className={styles.btnActionContainer}>
                    <SixtyButton
                      variant="secondary"
                      onClick={() => onBrowseBlogProducts()}
                      className={styles.approveBtn}
                      >
                      {"Manage Products"}
                    </SixtyButton>
             </div>
              <div className={styles.campaignInfoContainer}>
                <div className={styles.campaignInfoRow}>
                  <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                    <div className={styles.campaignInfoHeader}>Available Products</div>
                    <div
                      className={styles.elipsis}
                      title={'Available Products'}
                    >
                      {blogInfo?.availableProductCount}
                    </div>
                  </div>
                  <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                    <div className={styles.campaignInfoHeader}>Blog Products</div>
                    <div
                      className={styles.elipsis}
                      title={'Blog Products'}
                    >
                      {blogInfo?.blogProductCount}
                    </div>
                  </div>
                  <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                    <div className={styles.campaignInfoHeader}>Override url Products</div>
                    <div
                      className={styles.elipsis}
                      title={'Override Url Products'}
                    >
                      {blogInfo?.productRedirectCount}
                    </div>
                  </div>
                </div>
              </div>
            </Panel>

            <Panel className={styles.panelWrap}>
              <PanelHeader
                className={styles.campaignInfoHeader}
                title="Permalink"
              />
              <div className={styles.btnActionContainer}>
                    <SixtyButton
                      variant="secondary"
                      onClick={() => copyToClipboard()}
                      className={styles.approveBtn}
                      >
                      {"Copy"}
                    </SixtyButton>
                    <SixtyButton
                      variant="tertiary"
                      onClick={() => onPreview()}
                      className={styles.approveBtn}
                      >
                      {"Preview"}
                    </SixtyButton>
             </div>
              <div className={styles.campaignInfoContainer}>
                <div className={styles.campaignInfoRow}>
                  <div className={clsx(styles.campaignFullInfoItem, styles.elipsis)}>
                    <div className={styles.campaignInfoHeader}>Blog Permalink</div>
                    <div
                      className={styles.elipsis}
                      title={'Permalink'}
                    >
                      {blogInfo?.permalink}
                    </div>
                  </div>
                </div>
              </div>
            </Panel>


          {blogInfo && (
            <>
              <Panel className={styles.panelWrap}>
                  <PanelHeader
                    className={styles.sellerInfoHeader}
                    title="Update Vendor Blog"
                  />
                  <FormProvider {...formMethods}>
                  <form
                      className={styles.form}
                  >
                  {blogInfo && (
                   <Form selectedBlog={blogInfo} setContent={setContent} />
                  )}         
                  </form>
              </FormProvider>
              </Panel>
            </>
          )}

        </div>
      </div>

        <footer className={styles.footer} hidden={isLoading}>
          {blogCreated == false && (
            <>
            <SixtyButton
              variant={blogInfo?.isPublished ? "warning" : "tertiary"}
              onClick={() => onPublishOrUnpublish()}
              className={styles.approveBtn}
              disabled={blogLoading || uploadingFiles || publishLoading}
              >
              {blogInfo?.isPublished ? "Unpublish content" : "Publish content" }
            </SixtyButton>
            <SixtyButton
              type="submit"
              onClick={formMethods.handleSubmit(onSubmit)}
              className={styles.approveBtn}
              disabled={blogLoading || uploadingFiles || publishLoading}
              >
              {"Update"}
            </SixtyButton>
            </>
          )}

        </footer>
      </div>
    </React.Fragment>
  );
};

export default UpdateBlog;
