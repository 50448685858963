import { Controller, useFormContext } from "react-hook-form";
import { Endpoints } from "common/constants";
import { queryClient } from "common/query";
import RadioField from "components/RadioField";
import Checkbox from "components/SixtyCheckBox";
import SixtyInput from "components/SixtyInput";
import SixtySelect from "components/SixtySelect";
import {
  FilterGroup,
  FilterItem,
  FilterChipContainer,
  ChipItem,
  FilterRow,
} from "components/SixtyTable/components/Filter";
import DatePicker from "components/SixtyDatePicker";


import _omit from "lodash/omit";
import { useGetSellerDropdown } from "queries/seller";

// for rendering chips
export const labelMap = {
  FilterBySellerId: "Seller id",
  filterByScheduleShipping: "Scheduled shipping",
  filterByShipped: "Shipped",
  filterByAchieved: "Archived",
  filterByPaidPayment: "Paid",
  filterByRefundPayment: "Refund",
  date: {
    filterByToday: "Today",
    filterByLast7Days: "Last 7 days",
    filterByLast30Days: "Last 30 days",
    filterByLast90Days: "Last 90 days",
    filterByLast12Months: "Last 12 months",
  },
};

export default function OrderFilter({ state, setState }) {
  const { data: sellerDropdown, isLoading } = useGetSellerDropdown({});
  const sellerOptions = sellerDropdown?.map((option) => ({
    label: option.name,
    value: option.id,
  }));

  const handleFieldChange = (value, fieldName) => {
    setState((filter) => {
      if (value) {
        filter[fieldName] = value;
      }
      if (!value) {
        delete filter[fieldName];
      }
    });
  };

  function handleCustomFieldChange(field, value) {
    setState((filterState) => {
      filterState["date"][field] = value;
    });
  }

  const handleDateFieldChange = (fieldName) => {
    setState((filter) => {
      if (fieldName === "custom")
        filter.date = { custom: true, fromDate: null, untilDate: null };
      else filter.date = { [fieldName]: true };
    });
  };

  return (
    <>
      <FilterRow>
        <FilterGroup label="Date" colWidth={2}>
          <FilterItem colWidth={2}>
            <RadioField
              label="Today"
              name={"date"}
              value={"filterByToday"}
              checked={state?.date?.filterByToday}
              onChange={(e) => handleDateFieldChange("filterByToday")}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <RadioField
              label="Last 7 Days"
              name={"date"}
              value={"filterByLast7Days"}
              checked={state?.date?.filterByLast7Days}
              onChange={(e) => handleDateFieldChange("filterByLast7Days")}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <RadioField
              label="Last 30 Days"
              name={"date"}
              value={"filterByLast30Days"}
              checked={state?.date?.filterByLast30Days}
              onChange={(e) => handleDateFieldChange("filterByLast30Days")}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <RadioField
              label="Last 90 Days"
              name={"date"}
              value={"filterByLast90Days"}
              checked={state?.date?.filterByLast90Days}
              onChange={(e) => handleDateFieldChange("filterByLast90Days")}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <RadioField
              label="Last 12 Months"
              name={"date"}
              value={"filterByLast12Months"}
              checked={state?.date?.filterByLast12Months}
              onChange={(e) => handleDateFieldChange("filterByLast12Months")}
            />
          </FilterItem>
          <FilterItem colWidth={2}>
            <RadioField
              label="Custom"
              name={"date"}
              value={"custom"}
              checked={state?.date?.custom}
              onChange={(e) => handleDateFieldChange("custom")}
            />
          </FilterItem>
          {state?.date?.custom && (
            <>
              <FilterItem colWidth={2}>
                <DatePicker
                  label="Start date"
                  value={state?.date?.fromDate}
                  onChange={(date) => {
                    handleCustomFieldChange("fromDate", date);
                  }}
                  dateFormat="dd/MM/yyyy"
                  maxDate={state?.date?.untilDate}
                />
              </FilterItem>
              <FilterItem colWidth={2}>
                <DatePicker
                  label="End date"
                  value={state?.date?.untilDate}
                  onChange={(date) => {
                    handleCustomFieldChange("untilDate", date);
                  }}
                  dateFormat="dd/MM/yyyy"
                  minDate={state?.date?.fromDate}
                />
              </FilterItem>
            </>
          )}
        </FilterGroup>
        <FilterGroup label="Order status">
          <FilterItem>
            <Checkbox
              label="Scheduled shipping"
              name={"filterByScheduleShipping"}
              checked={!!state?.filterByScheduleShipping}
              onChange={(e) => {
                handleFieldChange(e.target.checked, "filterByScheduleShipping");
              }}
            />
          </FilterItem>
          <FilterItem>
            <Checkbox
              label="Shipped"
              name={"filterByShipped"}
              checked={!!state["filterByShipped"]}
              onChange={(e) => {
                handleFieldChange(e.target.checked, "filterByShipped");
              }}
            />
          </FilterItem>
          {/* <FilterItem>
            <Checkbox
              label="archived"
              name={"filterByAchieved"}
              checked={!!state["filterByAchieved"]}
              onChange={(e) => {
                handleFieldChange(e.target.checked, "filterByAchieved");
              }}
            />
          </FilterItem> */}
        </FilterGroup>
        <FilterGroup label="Payment status">
          <FilterItem>
            <Checkbox
              label="Paid"
              name={"filterByPaidPayment"}
              checked={!!state["filterByPaidPayment"]}
              onChange={(e) => {
                handleFieldChange(e.target.checked, "filterByPaidPayment");
              }}
            />
          </FilterItem>
          <FilterItem>
            <Checkbox
              label="Refund"
              name={"filterByRefundPayment"}
              checked={!!state["filterByRefundPayment"]}
              onChange={(e) => {
                handleFieldChange(e.target.checked, "filterByRefundPayment");
              }}
            />
          </FilterItem>
        </FilterGroup>
      </FilterRow>
      <FilterRow>
        <FilterGroup label="Seller" colWidth={2}>
          <FilterItem fullWidth>
            <SixtySelect
              label={isLoading ? "loading options..." : "Seller name"}
              value={state?.FilterBySellerId}
              options={sellerOptions || []}
              fullWidth
              onChange={(value) => handleFieldChange(value, "FilterBySellerId")}
            />
          </FilterItem>
        </FilterGroup>
      </FilterRow>
    </>
  );
}

export function renderFilterChips(filterState, setFilterState) {
  const sellers = queryClient.getQueryData(Endpoints.Seller.GetSellerDropdown);
  let filtersPresent = false;
  for (const filterKey in filterState) {
    if (filterKey === "date") {
      for (const dateKey in labelMap["date"]) {
        if (!!filterState.date[dateKey]) {
          filtersPresent = true;
          break;
        }
      }
      if (filtersPresent) break;
    } else if (!!filterState[filterKey]) {
      filtersPresent = true;
    }
  }

  const removeFilter = (filterItem) => {
    setFilterState((filter) => {
      delete filter[filterItem];
    });
  };

  const removeDateFilter = (filterItem) => {
    setFilterState((filter) => {
      if (filterItem === "custom") {
        delete filter.date.custom;
        delete filter.date.fromDate;
        delete filter.date.untilDate;
      } else delete filter.date[filterItem];
    });
  };

  if (filtersPresent) {
    let dateFilters = filterState.date || {};
    let sellerFilter = filterState.FilterBySellerId;
    let sellerName = sellerFilter
      ? sellers.find((seller) => seller.id === sellerFilter).name
      : null;
    let otherFilters = _omit(filterState, ["date", "FilterBySellerId"]);
    let customFields = false;
    if (dateFilters.custom) {
      customFields = true;
      dateFilters = _omit(dateFilters, ["custom", "fromDate", "untilDate"]);
    }
    return (
      <FilterChipContainer>
        {Object.keys(otherFilters).length > 0 &&
          Object.keys(otherFilters)?.map((filterItem) => (
            <>
              <ChipItem
                label={`${labelMap[filterItem]}`}
                name={filterItem}
                handleRemove={removeFilter}
                key={filterItem}
              />
            </>
          ))}
        {sellerFilter && sellerName && (
          <>
            <ChipItem
              label={`${labelMap.FilterBySellerId}: ${sellerName}`}
              name={"FilterBySellerId"}
              handleRemove={removeFilter}
              key={"FilterBySellerId"}
            />
          </>
        )}
        {Object.keys(dateFilters).length > 0 &&
          Object.keys(dateFilters)?.map((filterItem) => (
            <ChipItem
              label={labelMap.date[filterItem]}
              name={filterItem}
              handleRemove={removeDateFilter}
              key={filterItem}
            />
          ))}
        {customFields && (
          <ChipItem
            label={`${filterState.date.fromDate} - ${filterState.date.untilDate}`}
            name="custom"
            handleRemove={removeDateFilter}
          />
        )}
      </FilterChipContainer>
    );
  }
  return null;
}
