import React from "react";
import styles from "./index.module.css";
import { ReactComponent as GoBackIcon } from "assets/icons/Icon-Back-Button.svg";
import { ButtonDatePicker } from "components/SixtyDatePicker";

const TotalSalesPageHeader = ({ title, onGoBack, month, setMonth }) => {
  return (
    <div className={styles.header}>
      <div className={styles.titleBox}>
        <div onClick={onGoBack}>
          <GoBackIcon />
        </div>
        <div className={styles.titleContent}>{title}</div>
      </div>
      <div className={styles.ButtonsContainer}>
        <div>
          <ButtonDatePicker
            value={month}
            maxDate={new Date()}
            handleChange={(date) => setMonth(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
        </div>
      </div>
    </div>
  );
};

export default TotalSalesPageHeader;
