import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useGetPostingContentDetail, useUpdateStatus } from "queries/posting";
import { useGetProductPreview, useAddProductToCampaign } from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Button from "components/SixtyButton";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import { useHistory } from "react-router-dom";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";

import { formatCurrency } from "utils/common";

const ProductPreviewDetails = ({ selectedCampaign, selectedProduct, handleClose }) => {

  const { id } = selectedCampaign;
  const { id : productId } = selectedProduct;

  const { data: productData, isLoading, isError, refetch } = useGetProductPreview(
    productId
  );

  const { mutateAsync: AddProductToCampaign, 
          isLoading : productUpdating 
        } = useAddProductToCampaign();

  const onAddToCampaign = async () => {
    try {

        const model = {
            productId: productId,
            campaignId: id
         };
 
         var result = await AddProductToCampaign(model);

         if(result){
            refetch();
            setToast("successfully added product to campaign");
            handleClose();
         }
        
    } catch (e) {
      reportError(
        "error in : onAddToCampaign: onSubmit, error: ",
        e
      );
    }
  };

  const onPreview = () => {    
    window.open(productData?.productUrl, "_blank")
  };

  return (
      <>
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>

          <div className={styles.btnActionContainer}>
              <SixtyButton
                 variant="tertiary"
                 onClick={() => onPreview()}
                 className={styles.approveBtn}
               >
                 {"Preview"}
              </SixtyButton>
          </div>

          <div className={styles.subHeader}>
                <span> Media </span>
                <div className={styles.profPhotoContainer}>
                  <div className={styles.profPhotoDropzone}>
                  <img
                    src={productData?.path}
                    className={clsx(styles.img)}
                    alt="No preview"
                  />
                  </div>
                </div>
              </div>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Product Info"
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Name
                  </div>
                  <div>
                    {productData?.name}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>price</div>
                  <div>
                    {formatCurrency(productData?.price)}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Total Visitors</div>
                  <div>
                    {productData?.traffics}
                  </div>
                </div>
              </div>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoFullWidthItem}>
                  <div className={styles.campaignInfoHeader}>
                    Description
                  </div>
                  <div>
                    {productData?.description}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

        {productData && productData?.variants.map((field, index) => (
             <React.Fragment key={field.id}>
                 <Panel className={styles.panelWrap}>
                    <PanelHeader
                     className={styles.campaignInfoHeader}
                     title={'[Variation] ' + productData?.variants[index].combination}
                    />
                    <div className={styles.campaignInfoContainer}>

                    <div className={styles.campaignInfoRow}>
                        <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                        <div className={styles.campaignInfoHeader}>Sku Code</div>
                        <div>
                            {productData?.variants[index].skucode}
                        </div>
                        </div>
                        <div className={styles.campaignInfoItem}>
                        <div className={styles.campaignInfoHeader}>
                            Price
                        </div>
                        <div>
                            {formatCurrency(productData?.variants[index].price)}
                        </div>
                        </div>
                    </div>
                    </div>
                </Panel>
                 
              </React.Fragment>
          ))}
      
        </div>
      </div>

      <footer className={styles.footer} hidden={isLoading}>

          <SixtyButton
               type="submit"
               onClick={onAddToCampaign}
               className={styles.approveBtn}
               disabled={productUpdating}
             >
               {"Add to campaign"}
          </SixtyButton>

          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={productUpdating}
          >
            Close
          </SixtyButton>

        </footer>
      </div>
    </React.Fragment>
    </>
  );
};

export default ProductPreviewDetails;
