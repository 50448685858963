import React, { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import GridHeader from "components/GridHeader";
import Input from "components/FormComponents/ControlledInput";
import { setToast } from "components/Toast";
import _isEmpty from "lodash/isEmpty"; 
import {
    useImportLazadaProducts,
} from "queries/product";
import SixtyButton from "components/SixtyButton";
  

const LazadaImport = () =>{
    
    const formMethods = useForm();
    const { control, setValue, errors } = formMethods;
    const [files, setFiles] = useState([]);
    const [fileName, setFileName] = useState("");
    const fileInput = useRef();

    const {
        mutateAsync: importProducts,
        isLoading: uploadingFiles,
      } = useImportLazadaProducts();

           
      const onBrowse = () => {
        fileInput.current.click();
      };

      const onImport = async () => {

        if (files.length <= 0){
            setToast("Files is empty !", "error");
            return;
        }

        setToast("Importing Products, it may take some time");

        let formData = new FormData();
        formData.append(`name`, files[0].name);
        formData.append("file", files[0]);

        await importProducts(formData);

        setToast("successfully imported files");
        setFiles([]);
      };

      useEffect(() => {
        if (files.length > 0) {
          setValue("fileName", files[0].name);
          setFileName(files[0].name);       
        }
      }, [files]);
    
    return (
      <div className={styles.lazadaImport}>
      <GridHeader header="Lazada Product Import" />
      <div className={styles.accountSettingsBody}>
        <FormProvider {...formMethods}>
          <form
            className={styles.form}
          >
            <Panel className={styles.panelWrap}>
            <div className={styles.formRow}>
                  <div className={styles.commissionInputContainer}>
                  
                    <div className={styles.commission}>
                        <input
                            type="file"
                            hidden
                            ref={fileInput}
                            onChange={(e) => setFiles(e.target.files)}
                            accept=".xlsx, .xls, .csv"
                        />
                       <Input
                            name="fileName"
                            control={control}
                            error={errors.commissionAmount}
                            defaultValue={fileName}
                            value={fileName}
                            disabled={true}
                            label="Lazada excel file"

                        />
                        <SixtyButton
                            variant="secondary"
                            onClick={onBrowse}
                            disabled={uploadingFiles}
                            className={styles.btn}
                        >
                            Browse
                       </SixtyButton>
                    </div>
                  </div>
                 
            </div>
            </Panel>
            <div className={styles.footer}>
                <SixtyButton
                    variant="secondary"
                    onClick={onImport}
                    disabled={uploadingFiles || files.length <= 0}
                >
                    Import
                </SixtyButton>
            </div>
            </form>
            </FormProvider> 
            </div>
            </div>
   )
}

export default LazadaImport;