import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import ProductsList from "./components/ProductsList";
import RefundList from "./components/RefundList";
import PaymentDetails from "./components/PaymentDetails";
import OrderSummary from "./components/OrderInfo";
import CustomerDetails from "./components/CustomerDetails";
import OrderPageHeader from "./components/OrderPageHeader";
import PanelHeader from "components/PanelHeader";
import Button from "components/SixtyButton";
import { useParams } from "react-router-dom";
import Modal from "components/Modal";
import SendInvoiceForm from "./components/SendInvoiceForm";
import {
  useAchieveOrder,
  useGetOrderDetail,
  useMarkOrdersAsShipped,
  useOrderRefund,
  useUpdateOrederShipping,
  useDownloadOrderStatement
} from "queries/order";
import Loader from "components/Loader";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import RefundForm from "./components/RefundForm";
import UpdateOrderForm from "./components/UpdateOrderForm";
import { format } from "date-fns";
import ErrorModal from "components/Error";

const OrderDetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isSendInvoiceModalOpen, setSendInvoiceModalOpen] = useState(false);
  const [isUpdateOrderModalOpen, setUpdateOrderModalOpen] = useState(false);
  const [isRefundModalOpen, setRefundModalOpen] = useState(false);

  const sendInvoiceModalRef = useRef();
  const refundModalRef = useRef();

  const { data: orderData, isLoading, isError, refetch } = useGetOrderDetail(id);

  const {
    mutateAsync: archiveOrder,
    isSuccess: isArchiveSuccess,
    isLoading: isArchieveLoading
  } = useAchieveOrder();

  const {
    mutateAsync: downloadInvoice,
    isSuccess: isDownloadSuccess,
    isLoading: isDownloadLoading
  } = useDownloadOrderStatement();

  useEffect(() => {
 
    let message = orderData?.archieve ? 
                  "Order unarchived successfully":
                  "Order archived successfully";
    if (isArchiveSuccess) {
      setToast(message);
    }
  }, [isArchiveSuccess]);

  const {
    mutateAsync: refundOrder,
    isSuccess: isRefundSuccess,
    isLoading: isRefundLoading
  } = useOrderRefund();

  useEffect(() => {
    if (isRefundSuccess) {
      setToast("Order refunded successfully");
    }
  }, [isRefundSuccess]);

  const {
    mutateAsync: markAsShippedMutation,
    isSuccess: isMarkAsShipSuccess,
    isLoading: markAsShipLoading
  } = useMarkOrdersAsShipped();

  useEffect(() => {
    if (isMarkAsShipSuccess) {
      setToast("Order marked as Shipped successfully");
    }
  }, [isMarkAsShipSuccess]);

  const SendInvoiceTitle = () => {
    return <div className={styles.modalTitle}>Send invoice</div>;
  };

  const markAsShipped = async () => {
    try {
      await markAsShippedMutation([id]);
    } catch (error) {
      setToast(`Failed order ${id} mark as shipped`, "error");
      reportError("OrderDetails: markAsShipped: error: ", error);
    }
  };

  const UpdateOrederTitle = () => {
    return (
      <div className={styles.modalTitle}>Update parcel courier information</div>
    );
  };

  const {
    mutateAsync: updateOrederShippingMutation,
    isSuccess: isUpdateOrederShippingSuccess,
    isLoading: isLoadingShipping
  } = useUpdateOrederShipping();

  useEffect(() => {
    if (isUpdateOrederShippingSuccess) {
      setUpdateOrderModalOpen(false);
      setToast("Order shipping updated successfully");
    }
  }, [isUpdateOrederShippingSuccess]);

  const updateOrder = async (data) => {
    data["orderId"] = orderData?.id;
    data["shippedOn"] = format(data?.shippedOn, "dd/MM/yyyy");
    try {
      await updateOrederShippingMutation(data);
    } catch (error) {
      setToast(`Failed order ${id} update`, "error");
      reportError("OrderDetails: updateOrder: error: ", error);
    }
  };

  const refund = async () => {
    try {
     let result = await refundOrder(id);

      if(result)
      setRefundModalOpen(false);

    } catch (error) {
      setRefundModalOpen(false);
      setToast(`Failed order ${id} refund`, "error");
      reportError("OrderDetail: refund: error: ", error);
    }
  };

  const editOrder = () => {
    console.log("Clicked Option: edit");
  };

  const onArchive = async () => {
    try {
      await archiveOrder(id);
    } catch (error) {
      setRefundModalOpen(false);
      setToast(`Failed order ${id} archive`, "error");
      reportError("OrderDetail: onArchive: error: ", error);
    }
  };

  const onDownloadInvoice = async () => {
    setToast(`Downloading Invoice`);
    const model = {
      id: orderData?.id,
      transactionCode: orderData?.transactionCode,
      supplierId: orderData?.supplierId
    }
    await downloadInvoice(model);
  };

  const onDuplicate = () => {
    console.log("Clicked Option: Duplicate");
  };

  const onPrint = () => {
    console.log("Clicked Option: Print");
  };

  const onDelete = () => {
    console.log("Clicked Option: Delete");
  };

  const onSendInvoice = () => {
    setSendInvoiceModalOpen(true);
  };

  const onSendInvoiceSubmit = () => {
    setSendInvoiceModalOpen(false);
  };

  const onSendInvoiceCancel = () => {
    setSendInvoiceModalOpen(false);
  };

  const onRefundClose = () => {
    setRefundModalOpen(false);
  };

  const onRefundOpen = () => {
    setRefundModalOpen(true);
  };

  const onUpdateOrder = () => {
    setUpdateOrderModalOpen(true);
  };
  const onUpdateOrderCancel = () => {
    setUpdateOrderModalOpen(false);
  };

  const onRefetch = () => {
    refetch();
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Panel className={styles.panelWrap}>
            <Loader />
          </Panel>
        </div>
      ) : (
        orderData &&
        !isLoading &&
        !isError && (
          <div className={styles.orderDetailsPage}>
            <OrderPageHeader
              title={"#" + orderData?.transactionCode}
              onGoBack={onGoBack}
              onMarkAsShipped={markAsShipped}
              onRefund={onRefundOpen}
              onEdit={editOrder}
              onArchive={onArchive}
              onDuplicate={onDuplicate}
              onPrint={onPrint}
              onDelete={onDelete}
              onDownloadInvoice={onDownloadInvoice}
              status={orderData?.status}
              onUpdateOrder={onUpdateOrder}
              onArchiveloading={isArchieveLoading}
              isArchive={orderData?.archieve}
              isDownloadLoading={isDownloadLoading}
            />
            <div className={styles.row}>
              <div className={styles.orderLeftContainer}>
                <Panel className={styles.panelWrap}>
                  <PanelHeader title="Products" />
                  <ProductsList products={orderData?.items} />
                </Panel>
                {orderData?.refunds && orderData?.refunds?.length > 0 && (
                  <>
                    <Panel className={styles.panelWrap}>
                      <PanelHeader title="Refund Requests" />
                      <RefundList refunds={orderData?.refunds} refetchData={onRefetch} />
                    </Panel>
                  </>
                )}
                <Panel className={styles.panelWrap}>
                  <PanelHeader title="Payment">
                    {/* <div>
                      <Button
                        className={styles.sendInvoiceBtn}
                        label="Send Invoice"
                        onClick={onSendInvoice}
                      />
                    </div> */}
                  </PanelHeader>
                  <PaymentDetails paymentDetail={orderData?.paymentDetail} />
                </Panel>
              </div>
              <div className={styles.orderRightContainer}>
                <Panel
                  className={[styles.panelWrap, styles.orderInfoPanel].join(
                    " "
                  )}
                >
                  <div>
                    <PanelHeader title="Order info" />
                    <OrderSummary data={orderData} onUpdateOrder={onUpdateOrder} />
                  </div>
                </Panel>
                <Panel
                  className={[
                    styles.panelWrap,
                    styles.customerDetailsPanel,
                  ].join(" ")}
                >
                  <div>
                    <PanelHeader title="Customer" />
                    <CustomerDetails data={orderData?.customerDetail} />
                  </div>
                </Panel>
              </div>
            </div>
            {isSendInvoiceModalOpen && (
              <Modal
                isOpen={isSendInvoiceModalOpen}
                onAttemptClose={() => {
                  setSendInvoiceModalOpen(false);
                }}
                modalRef={sendInvoiceModalRef}
                Title={SendInvoiceTitle}
              >
                <SendInvoiceForm
                  handleSubmit={onSendInvoiceSubmit}
                  handleCancel={onSendInvoiceCancel}
                />
              </Modal>
            )}
            {/* refund */}
            <ErrorModal
              open={isRefundModalOpen}
              loading={isRefundLoading}
              handleClose={onRefundClose}
              handleCancel={onRefundClose}
              handleAction={refund}
              actionLabel={"Refund"}
              errorRef={refundModalRef}
              errorMessage={"Are you sure?"}
            />

            {isUpdateOrderModalOpen && (
              <Modal
                isOpen={isUpdateOrderModalOpen}
                onAttemptClose={() => {
                  setUpdateOrderModalOpen(false);
                }}
                modalRef={sendInvoiceModalRef}
                Title={UpdateOrederTitle}
              >
                <UpdateOrderForm
                  handleSubmit={updateOrder}
                  handleCancel={onUpdateOrderCancel}
                  data={orderData?.shippingDetail}
                  isLoading={isLoadingShipping}
                />
              </Modal>
            )}
          </div>
        )
      )}
    </>
  );
};

export default OrderDetailsPage;
