import styles from "./index.module.css";
import SixtyLogo from "assets/images/Logo.svg";
import { useHistory } from "react-router-dom";
import { useCheckVendorOnboardingStatus } from "queries/vendor";
import { useEffect } from "react";
import { queryClient } from "common/query";
import { Redirect } from "react-router-dom";
// import { clearStorage } from "../../../utils/storage";

export default function LoginLayout({ children }) {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const {
    data: onBoardingStatus,
    error,
    isError,
    isSuccess,
  } = useCheckVendorOnboardingStatus({
    enabled: !!token,
    retry: false,
  });

  const user = queryClient.getQueryData("user");
  const role = localStorage.getItem("userRole");

  useEffect(() => {
    if (
      isError &&
      error &&
      (!error?.response?.data?.result?.completedVendorSetup ||
       !onBoardingStatus?.completedVendorSetup)
    ) {
      if (token && user && user.email) {
        history.push("/setup-profile?email=" + user?.email);
      }
    }
  }, [onBoardingStatus, error, isError, history, user, token, role]);

  useEffect(() => {
    if (
      (isError || isSuccess) &&
      (onBoardingStatus?.completedVendorSetup ||
        error?.response?.data?.result?.completedVendorSetup)
    ) {

      if (
        onBoardingStatus?.subscriptionPaymentStatus === "PendingForPayment" ||
        onBoardingStatus?.requiredPayment 
      )
        history.push("/pricing-plan");  
       else{
        history.push("/dashboard");
        if (token && role && role === "InternalUser") {
          history.push("/admin/dashboard");
        } else if (token) {
          history.push("/dashboard");
        }
      }

    }
  }, [error, history, isError, isSuccess, onBoardingStatus, token, role]);

  return (
    <div className={styles.page}>
      <img src={SixtyLogo} alt="sixty sec logo" className={styles.logo} />
      {children}
    </div>
  );
}
