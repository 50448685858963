import React, { useState, useEffect, useRef } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Checkbox from "components/FormComponents/ControlledCheckbox";
import ControlledSelect from "components/FormComponents/ControlledSelect";
import SixtyButton from "components/SixtyButton";
import {
  useGetPriceTierDropdown,
  useGetTaxGroupDropdown,
} from "queries/product";
import {
  useGetCommission,
} from "queries/setting";


export default function Pricing({  priceTiers, variants }) 
{
 
const [togglePriceTier, setToggle] = useState(false);

// default values for
 const defaultFieldValues = {
  priceTierId: "",
  overrideDiscountRate: null,
 };

 const [priceChanges, setChanges] = useState(0);
  const {
    data: taxGroupsOptions,
    isLoading: taxGroupsLoading,
  } = useGetTaxGroupDropdown();

  const taxOptions =
    taxGroupsOptions?.length > 0
      ? [
          { label: "None", value: null },
          ...taxGroupsOptions.map((group) => ({
            label: group.name,
            value: group.id,
          })),
        ]
      : [];

  // tiers dropdown options query
  // const {
  //   data: priceTierOptions,
  //   isLoading: tiersLoading,
  // } = useGetPriceTierDropdown();

  // const tierOptions =
  //   priceTierOptions?.length > 0
  //     ? priceTierOptions.map((group) => ({
  //         label: group.name,
  //         value: group.id,
  //       }))
  //     : [];

  const { control, watch, setValue, errors } = useFormContext();
  const isTiersEnabled = watch("enableTiers");
  const isAutoUpdateVariantPricing = watch("applyVariantUpdate");

  // fieldArray for price tiers
  const { fields, append, remove } = useFieldArray({
    control,
    name: "productPriceTiers",
  });

  const tiers = watch("productPriceTiers");
  const pricingVariants = watch("productVariants");

  const updateVariants = () => {
    let marketPrice = control.getValues("marketPrice");
    let costPrice = control.getValues("costPrice");
    let sellingPrice = control.getValues("sellingPrice");

    pricingVariants.map((variant) => {

      variant.costPrice = String(costPrice);
      variant.sellingPrice = String(sellingPrice);
      variant.marketPrice = String(marketPrice);

    });
     setValue("productVariants", pricingVariants);
  }

  const { data: sellingDataCommission } = useGetCommission();
    const calculateCostPriceHandler = (name, value) =>{

    var amount = parseFloat(value) - parseFloat(value)*parseFloat(sellingDataCommission?.result?.commissionAmount/100);
    setValue('costPrice', String(parseFloat(amount).toFixed(2)));
    setChanges(1);

    if(isAutoUpdateVariantPricing){

      let marketPrice = control.getValues("marketPrice");
      pricingVariants.map((variant) => {

        variant.costPrice = String(parseFloat(amount).toFixed(2));
        variant.sellingPrice = String(value);
        variant.marketPrice = String(marketPrice);

      });
       setValue("productVariants", pricingVariants);
    }
  };

  const marketPriceHandler = (name, value) =>{
    setChanges(1);

    if(isAutoUpdateVariantPricing){
      updateVariants();
    }
  };

  const onAutoPriceChangeToggle = (_name, enable) => {
    if (enable) {
      updateVariants(); 
    }
  };

  const onTiersToggle = (_name, enable) => {
    if (enable && tiers.length === 0) {
      append({ ...defaultFieldValues });
    } else {
      // if tiers are disabled or no tiers present, set empty
      // setValue("productPriceTiers", []);

      fields.map((tier, index) => {
        
        if(tier.priceTierId === '' || 
           tier.priceTierId === undefined){
          remove(index);
        }
    
      });
    }
  };

  const renderVariantCheckbox = () => {

    if(!variants) return;
    if(variants.length > 0 && priceChanges > 0){
     return (
      <div className={styles.formRow}> 
      <Checkbox
          name="applyVariantUpdate"    
          label="Apply changes to existing variant pricing"  
          uncheckStyleVariant="greyedOut"
          handleChange={onAutoPriceChangeToggle}
       />
     </div>
     );
    } else {
      return(<div></div>);
    }
  }

  const loadPriceTiers = () =>{

    if(!priceTiers) return;
    if(priceTiers.length > 0 && togglePriceTier === false){
      setToggle(true);
    }

    priceTiers.map((tier) => {

      var exists = fields.find(p => p.priceTierId === tier.priceTierId);
      if (!exists){
        append({
          id: tier.id,
          priceTierId: tier.priceTierId,
          overrideDiscountRate: tier.overrideDiscountRate,
        })
      }
    });

  }

  return (
    <div className={styles.form}>
      <div className={styles.formRow}>
        <Input
          name="costPrice"
          type="number"
          control={control}
          error={errors.costPrice}
          defaultValue={undefined}
          disabled={true}
          label="Cost Price"
          startAdornment="RM"
          step=".01"
          min={0}
        />
        <Input
          name="marketPrice"
          type="number"
          control={control}
          error={errors.marketPrice}
          defaultValue={undefined}
          label="Market Price"
          startAdornment="RM"
          step=".01"
          min={0}
          handleChange={marketPriceHandler}
        />
      </div>
      <div className={styles.formRow}>
        <Input
          name="sellingPrice"
          type="number"
          control={control}
          error={errors.sellingPrice}
          defaultValue={undefined}
          label="Selling Price"
          startAdornment="RM"
          step=".01"
          min={0}
          handleChange={calculateCostPriceHandler}
        />
        <ControlledSelect
          name={`taxGroupId`}
          label={taxGroupsLoading ? "loading options..." : "Tax"}
          options={taxOptions}
          control={control}
          error={errors.taxGroupId}
          disabled={taxGroupsLoading}
          fullWidth={true}
        />
      </div>     
      {loadPriceTiers()}
      {renderVariantCheckbox()}       
      {/* <div className={styles.formRow}>
        <Checkbox
          control={control}
          name="enableTiers"
          label="Enable tiers on this product"
          uncheckStyleVariant="greyedOut"
          handleChange={onTiersToggle}
          defaultValue={togglePriceTier}
        />
      </div> */}
      {/* <div>
        {isTiersEnabled &&
          fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <div className={styles.formRow}>
                <ControlledSelect
                  name={`productPriceTiers[${index}].priceTierId`}
                  options={tierOptions}
                  label={tiersLoading ? "fetching options..." : "Tier"}
                  control={control}
                  fullWidth={true}
                  defaultValue={fields[index].priceTierId}
                  value={fields[index].priceTierId}
                  disabled={tiersLoading}
                />
                <div>
                  <SixtyButton
                    variant="text"
                    onClick={(e) => {
                      remove(index);
                    }}
                  >
                    Remove
                  </SixtyButton>
                </div>
              </div>

              <div className={styles.formRow}>
                <Input
                  name={`productPriceTiers[${index}].overrideDiscountRate`}
                  type="number"
                  control={control}
                  label="Override Commission Rate"
                  defaultValue={fields[index].overrideDiscountRate}
                  value={fields[index].overrideDiscountRate}
                />
              </div>
              <hr className={styles.hr} />
            </React.Fragment>
          ))}

        {isTiersEnabled && (
          <SixtyButton
            variant="secondary"
            onClick={(e) => append({ ...defaultFieldValues })}
          >
            Add new tier
          </SixtyButton>
        )}
      </div> */}
    </div>
  );

};

