import { useEffect, useMemo, useRef, useState } from "react";
import Table from "components/SixtyTable";
import { ReactComponent as IconSettings } from "assets/icons/Icon-Settings.svg";
import EmptyGrid from "components/EmptyGrid";
import useRowSelectionTable from "utils/hooks/useRowSelectionTable";
import { useHistory } from "react-router-dom";
import { useGetTaxGroups } from "queries/product";
import { unknown_Error } from "common/constants";
import ErrorModal from "components/Error";
import { PanelActionButton } from "components/SixtyPanel";
import styles from "./index.module.css";
import Loader from "components/Loader";

export default function TaxesTable() {
  const {
    data: taxGroups,
    refetch,
    isLoading,
    isError,
    error,
  } = useGetTaxGroups({});

  const history = useHistory();

  const onEditTax = (id) => {
    history.push("/dashboard/settings/taxes/" + id);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        style: { padding: "0px 16px" },
      },
      {
        Header: "Number of taxes",
        accessor: "taxes",
        Cell: ({ value }) => value?.length || 0,
      },
    ],
    []
  );

  const data = useMemo(() => (taxGroups ? taxGroups : []), [taxGroups]);

  const tableInstance = useRowSelectionTable({
    columns,
    data,
  });

  // Error modal
  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  return (
    <>
      {!Array.isArray(data) && !isLoading && (
        <>
          <EmptyGrid
            Icon={IconSettings}
            emptyMessage={
              isError
                ? "Error in fetching data"
                : "The products will show up here"
            }
          />

          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}
      {!isError && (
        <>
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            Array.isArray(data) && (
              <Table
                instance={tableInstance}
                onRowClicked={(row) => onEditTax(row.original.id)}
              />
            )
          )}
        </>
      )}
    </>
  );
}
