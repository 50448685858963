import { useEffect } from "react";

function useOnClickOutside(ref, handler, allowRefs = []) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      if (
        allowRefs.filter(
          (ref) => ref.current && ref.current.contains(event.target)
        ).length > 0
      )
        return;
      else handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, allowRefs]);
}

export default useOnClickOutside;
