import { SortItem } from "components/SixtyTable/components/Sort";

export default function ProductSort({ state, handleSortChange }) {
  return (
    <>
      <SortItem
        label="Order number (Ascending)"
        name="orderByAscending"
        checked={state === "orderByAscending"}
        onChange={() => handleSortChange("orderByAscending")}
      />
      <SortItem
        label="Order number (Descending)"
        name="orderByDescending"
        checked={state === "orderByDescending"}
        onChange={() => handleSortChange("orderByDescending")}
      />
     
      <SortItem
        label="Sales (Ascending)"
        name="orderBySalesAscending"
        checked={state === "orderBySalesAscending"}
        onChange={() => handleSortChange("orderBySalesAscending")}
      />
      <SortItem
        label="Sales (Descending)"
        name="orderBySalesDescending"
        checked={state === "orderBySalesDescending"}
        onChange={() => handleSortChange("orderBySalesDescending")}
      />
     
    </>
  );
}
