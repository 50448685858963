import React, { useState } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetPriceTierDropdown } from "queries/product";
import { useUpdateSellerStatus } from "queries/seller";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import SubscriptionPermission from "../../../../Permissions/SubscriptionPermissions"; 
import Restricted from "../../../../../permissions/Restrictions";

import styles from "./index.module.css";

const SellerRequestForm = ({ selectedSeller, handleClose, onReject }) => {
  const [tier, setTier] = useState('');

  // const { data: tierOptionsData, isLoading } = useGetPriceTierDropdown();

  // const tierOptions =
  //   tierOptionsData?.length > 0
  //     ? tierOptionsData?.map((option) => ({
  //         label: option.name,
  //         value: option.id,
  //       }))
  //     : [];

  const { mutateAsync: updateSellerStatus, isLoading: updateLoading } = useUpdateSellerStatus();

  const onApproveSeller = async () => {
    const { id } = selectedSeller;
    try {
      const result = await updateSellerStatus({
        id,
        priceTierId: tier,
        action: "Approve",
      });
      if (result) {
        handleClose();
        setToast("successfully approved seller");
      }
    } catch (e) {
      reportError("error in : SellerRequestForm: onApproveSeller, error: ", e);
    }
  };

  const requestedDate = getFormattedDateString(
    selectedSeller?.submitDate,
    "MMM d, y"
  );

  return (
    <React.Fragment>
      <div className={styles.sellerRequestFormContainer}>
        <div className={styles.sellerRequestForm}>
          <div>
            <Panel className={styles.panelWrap}>
              <PanelHeader
                className={styles.sellerInfoHeader}
                title="Creator's information"
              />
              <Restricted 
               children={
                (
                  <div className={styles.sellerInfoContainer}>
                  <div className={styles.sellerInfoRow}>
                    <div className={clsx(styles.sellerInfoItem, styles.elipsis)}>
                      <div className={styles.sellerInfoHeader}>Email address</div>
                      <div
                        className={clsx(styles.email, styles.elipsis)}
                        title={selectedSeller?.email}
                      >
                        {selectedSeller?.email}
                      </div>
                    </div>
                    <div className={styles.sellerInfoItem}>
                      <div className={styles.sellerInfoHeader}>Request date</div>
                      <div className={styles.elipsis} title={requestedDate}>
                        {requestedDate}
                      </div>
                    </div>
                    <div className={styles.sellerInfoItem}>
                      <div className={styles.sellerInfoHeader}>Joined date</div>
                      <div className={styles.elipsis} title={requestedDate}>
                        {requestedDate}
                      </div>
                    </div>
                  </div>
  
                  <div className={styles.sellerInfoRow}>
                    <div className={styles.sellerInfoItem}>
                      <div className={styles.sellerInfoHeader}>
                        No. of joined brand
                      </div>
                      <div>{selectedSeller?.joinedBrandCount}</div>
                    </div>
                    <div className={styles.sellerInfoItem}>
                      <div className={styles.sellerInfoHeader}>
                        Total no. of sales
                      </div>
                      <div>{selectedSeller?.saleCount}</div>
                    </div>
                    <div className={styles.sellerInfoItem}>
                      <div className={styles.sellerInfoHeader}>
                        Total no. of order
                      </div>
                      <div>{selectedSeller?.orderCount}</div>
                    </div>
                  </div>
                </div> 
                )
               }
               action={'affiliate.recruitment.view'}
               fallback={(<SubscriptionPermission />)}
              />                     
            </Panel>
          </div>
          {/* <div>
            <ChipInput
              options={tierOptions}
              fullWidth={true}
              label="Tier"
              placeholder={isLoading ? "loading..." : "Select a tier"}
              value={tier}
              isMultiSelect={false}
              onChange={(value) => setTier(value)}
              variant="secondary"
              showDropdownIcon
            />
            <div className={styles.subNotes}>
              Sellers will only see product price based on their assigned tier.
            </div>
          </div> */}
        </div>
        <footer className={styles.footer}>
          <SixtyButton
            type="submit"
            onClick={onApproveSeller}
            className={styles.approveBtn}
            disabled={!selectedSeller.allowedActions.includes("Approve") || updateLoading}
          >
            Approve
          </SixtyButton>
          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={onReject}
            disabled={!selectedSeller.allowedActions.includes("Reject") || updateLoading}
          >
            Reject
          </SixtyButton>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default SellerRequestForm;
