import React, { useState } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetSellerRegistrationDetail, useApproveSellerRegistration, useRejectSellerRegistration } from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

const RegistrationDetails = ({ selectedSeller, handleClose, isHideRejection }) => {
  const { code } = selectedSeller;

  const { data: sellerInfo, isLoading, isError, refetch } = useGetSellerRegistrationDetail(
    code
  );

  const { mutateAsync: approveSeller, 
          isLoading : approveLoading 
        } = useApproveSellerRegistration();

  const onApproveSeller = async () => {
    try {
      const result = await approveSeller(code);

      if (result) {
        handleClose();
        setToast("successfully updated registration");
      }
    } catch (e) {
      reportError(
        "error in : RegistrationDetails: onApproveSeller, error: ",
        e
      );
    }
  };

    const { mutateAsync: rejectSeller, 
        isLoading : rejectLoading 
    } = useRejectSellerRegistration();

    const onRejectSeller = async () => {
    try {
    const result = await rejectSeller(code);
    if (result) {
    handleClose();
    setToast("successfully updated registration");
    }
    } catch (e) {
    reportError(
    "error in : RegistrationDetails: onRejectSeller, error: ",
    e
    );
    }
    };

  const isUrlValid = (userInput) => {

    if(!userInput) return (false);

    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return (false);
    else
        return (true);
};

  return (
    <React.Fragment>
      <div className={styles.sellerRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.sellerRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Account Information"
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Code</div>
                  <div>
                    {sellerInfo?.code}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={clsx(styles.email, styles.elipsis, styles.sellerInfoHeader)}>Email</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.email}
                  >
                    {sellerInfo?.email}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Contact</div>
                  <div>
                    {sellerInfo?.contactNumber}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Personal details"
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerInfoItem)}>
                  <div className={styles.sellerInfoHeader}>Name</div>
                  <div>
                    {sellerInfo?.name}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}> Gender </div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.gender}
                  >
                    {sellerInfo?.gender}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Race</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.race}
                  >
                    {sellerInfo?.race}
                  </div>
                </div>
              </div>

              <div className={styles.sellerInfoRow}>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Occupation</div>
                  <div>{sellerInfo?.occupation}</div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}> Date Of Birth </div>
                  <div>{sellerInfo?.dateOfBirth}</div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}> Register Date </div>
                  <div>{sellerInfo?.registerDate}</div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Audience Type Details"
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerFullInfoItem, styles.elipsis)}>
                  <div className={styles.sellerInfoHeader}>Audience Type</div>
                  <div>
                    {sellerInfo?.audienceTypes}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title="Location"
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>State</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.state}
                  >
                    {sellerInfo?.state}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>Country</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.country}
                  >
                    {sellerInfo?.country}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

         {sellerInfo?.socialMediaChannels.map((field, index) =>(
         <React.Fragment key={field.id}>

           <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title={sellerInfo?.socialMediaChannels[index].name}
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerInfoItem, styles.elipsis)}>
                  <div className={styles.sellerInfoHeader}>Follower Range</div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.socialMediaChannels[index].followerRange}
                  >
                    {sellerInfo?.socialMediaChannels[index].followerRange}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>
                    Profile Link
                  </div>
                  <div
                    className={styles.elipsis}
                    title={sellerInfo?.socialMediaChannels[index].profileLink}
                  >
                    {isUrlValid(sellerInfo?.socialMediaChannels[index].profileLink) &&(
                     <a className={styles.link} href={sellerInfo?.socialMediaChannels[index].profileLink} target={"_blank"} rel={"noreferrer"}>
                         {sellerInfo?.socialMediaChannels[index].profileLink}
                     </a>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </Panel>

         </React.Fragment>
         ))}

        </div>
        </div>

        <footer className={styles.footer} hidden={isLoading}>
          <SixtyButton
            type="submit"
            onClick={onApproveSeller}
            className={styles.approveBtn}
            disabled={approveLoading || rejectLoading}
          >
            Approve
          </SixtyButton>
          {isHideRejection == false &&(
            <SixtyButton
              variant="warning"
              className={styles.cancelButton}
              disabled={approveLoading || rejectLoading}
              onClick={onRejectSeller}
            >
              Reject
            </SixtyButton>
          )}

        </footer>
      </div>
    </React.Fragment>
  );
};

export default RegistrationDetails;
