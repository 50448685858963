import React, { useState } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetCampaignProductDetail } from "queries/campaign";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

const ProductDetails = ({ selectedProduct, handleClose }) => {
  const { id } = selectedProduct;

  const { data: productInfo, isLoading, isError, refetch } = useGetCampaignProductDetail(
    id
  );

  const isUrlValid = (userInput) => {

    if(!userInput) return (false);

    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return (false);
    else
        return (true);
  };

  return (
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Product Information"
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Name</div>
                  <div>
                    {productInfo?.name}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={clsx(styles.campaignInfoHeader)}>Generated Affliate</div>
                  <div
                    className={styles.elipsis}
                    title={productInfo?.totalAffilate}
                  >
                    {productInfo?.totalAffilate}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Traffics</div>
                  <div>
                    {productInfo?.traffics}
                  </div>
                </div>
              </div>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignFullInfoItem}>
                  <div className={styles.campaignInfoHeader}>Redirect Link</div>
                  <div>
                    {isUrlValid(productInfo?.redirectLink) &&(
                     <a className={styles.link} href={productInfo?.redirectLink} target={"_blank"} rel={"noreferrer"}>
                         {productInfo?.redirectLink}
                     </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

         {productInfo?.campaignSellers.map((field, index) =>(
         <React.Fragment key={field.id}>

           <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title={"[Influencer] " + productInfo?.campaignSellers[index].registerName}
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
              <div className={styles.campaignInfoItem}>
                  <div className={clsx(styles.email, styles.elipsis, styles.campaignInfoHeader)}>Email</div>
                  <div
                    className={styles.elipsis}
                    title={productInfo?.campaignSellers[index].sellerName}
                  >
                    {productInfo?.campaignSellers[index].sellerName}
                  </div>
                </div>
                <div className={clsx(styles.campaignInfoItem)}>
                  <div className={styles.campaignInfoHeader}>Generated Traffics</div>
                  <div
                    className={styles.elipsis}
                    title={productInfo?.campaignSellers[index].traffics}
                  >
                    {productInfo?.campaignSellers[index].traffics}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Status
                  </div>
                  <div
                    className={styles.elipsis}
                    title={productInfo?.campaignSellers[index].status}
                  >
                   {productInfo?.campaignSellers[index].status}
                  </div>
                </div>

              </div>
            </div>
          </Panel>

         </React.Fragment>
         ))}

        </div>
        </div>

        <footer className={styles.footer} hidden={isLoading}>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default ProductDetails;
