import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import RecruitmentTable from "../components/RecruitmentTable";
import OfflineRecruitmentTable from "../components/OfflineRecruitmentTable";
import InfluencerRequestDetails from "../../components/InfluencerRequestDetails";
import styles from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import BasicPageHeader from "../../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetCampaignSellerRequestCount } from "queries/campaign";

const Recruitment = ({campaign}) => {
  const [isSellerDetailOpen, setSellerDetailOpen] = useState(false);
  const history = useHistory();
  const params = useParams();
  const sellerDetailRef = useRef();
  const [selectedSeller, setSelectedSeller] = useState({});
  const role = localStorage.getItem("userRole");

  const {
    data: requestData,
  } = useGetCampaignSellerRequestCount(params.id);

  const SellerDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedSeller.name} />
          </div>
          <div className={styles.modalTitle}>{selectedSeller.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setSellerDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const RenderOnlineRecruitment = () => {
    return (
      <>
        <Panel className="tabs">
          <Tabs
            activeTab={"1"}
            tabs={[
              {
                tabId: "1",
                label: "Pending",
                recordsCount: requestData?.pendingRequestCount,
                TabComponent: RecruitmentTable,
                props: {
                  status: "Pending",
                  onInfluencerClick: onInfluencerClick
                },
              },
              {
                tabId: "2",
                label: "Shortlist",
                recordsCount: requestData?.shortlistRequestCount,
                TabComponent: RecruitmentTable,
                props: {
                  status: "Shortlisted",
                  onInfluencerClick: onInfluencerClick
                },
              },
              {
                tabId: "3",
                label: "Approved",
                recordsCount: requestData?.approvedRequestCount,
                TabComponent: RecruitmentTable,
                props: {
                  status: "Approved",
                  onInfluencerClick: onInfluencerClick
                },
              },
              {
                tabId: "4",
                label: "Rejected",
                recordsCount: requestData?.rejectedRequestCount,
                TabComponent: RecruitmentTable,
                props: {
                  status: "Rejected",
                  onInfluencerClick: onInfluencerClick
                },
              },
            ]}
          ></Tabs>
      </Panel>
      </>
    );
  };

  const RenderOfflineRecruitment = () => {
    return (
      <>
        <Panel className="tabs">
          <Tabs
            activeTab={"1"}
            tabs={[
              {
                tabId: "1",
                label: "Pending",
                recordsCount: requestData?.pendingRequestCount,
                TabComponent: OfflineRecruitmentTable,
                props: {
                  status: "Pending",
                  onInfluencerClick: onInfluencerClick
                },
              },
              {
                tabId: "2",
                label: "Shortlist",
                recordsCount: requestData?.shortlistRequestCount,
                TabComponent: OfflineRecruitmentTable,
                props: {
                  status: "Shortlisted",
                  onInfluencerClick: onInfluencerClick
                },
              },
              {
                tabId: "3",
                label: "Approved",
                recordsCount: requestData?.approvedRequestCount,
                TabComponent: OfflineRecruitmentTable,
                props: {
                  status: "Approved",
                  onInfluencerClick: onInfluencerClick
                },
              },
              {
                tabId: "4",
                label: "Rejected",
                recordsCount: requestData?.rejectedRequestCount,
                TabComponent: OfflineRecruitmentTable,
                props: {
                  status: "Rejected",
                  onInfluencerClick: onInfluencerClick
                },
              },
            ]}
          ></Tabs>
      </Panel>
      </>
    );
  };

  const onInfluencerClick = (value) => {
    setSelectedSeller(value);
    setSellerDetailOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <BasicPageHeader title="Influencer Recruitment" onGoBack={onGoBack} disableBack={true} />
      {["Standard", "WebsiteReview"].includes(campaign?.campaignType) && (
        <>
           {RenderOnlineRecruitment()}
        </>
      )}
      {["Offline"].includes(campaign?.campaignType) && (
        <>
           {RenderOfflineRecruitment()}
        </>
      )}
      
      {isSellerDetailOpen && (
        <Modal
          isOpen={isSellerDetailOpen}
          onAttemptClose={() => {
            setSellerDetailOpen(false);
          }}
          modalRef={sellerDetailRef}
          Title={SellerDetailTitle}
        >
          <InfluencerRequestDetails
            selectedSeller={selectedSeller}
            handleClose={() => setSellerDetailOpen(false)}
            viewOnly={campaign?.campaignType == 'AffiliateEvent' && role == 'Supplier'}
          />
        </Modal>
      )}
    </div>
  );
};

export default Recruitment;
