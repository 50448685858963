import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetVendorDetail, useCreateBlog } from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import { useUploads } from "queries/media";
import styles from "./index.module.css";
import Loader from "components/Loader";
import Form from "./components/Form";
import { format } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import BasicPageHeader from "../../Components/BasicHeader";
import { isEmpty, isArray } from 'lodash';

const CreateBlog = () => {

const history = useHistory();
const params = useParams();

const [newBlog, setCreatedBlog] = useState();
const [blogModel, setBlogModel] = useState();
const [blogCreated, setBlog] = useState(false);
const [content, setContent] = useState();

const defaultValues = {
    supplierReference: '',
    supplierCode: '',
    supplierName: '',
    supplierId: '',
    title: '',
    description: '',
    imageUrl: '',
    keywords: '',
    content: '',
    isPublished: false,
 };
 const formMethods = useForm({
    defaultValues,
 });
  
  const { data: venderInfo, isLoading, isError, refetch } = useGetVendorDetail(
    params.id
  );

  const {
    mutateAsync: uploadFiles,
    isLoading: uploadingFiles,
  } = useUploads();

  const onBrowseBlogProducts = () => {    
    const path = `/admin/blogs/blog-products/${newBlog?.id}`;
    window.open(path, "_blank")
  };

  const { mutateAsync: createBlog, 
          isLoading : blogLoading 
        } = useCreateBlog();

  const onSubmit = async (data) => {
    try {

      let uploadedPath = null;
      if(!isArray(data.imageUrl) || data.imageUrl?.length <= 0){
        setToast("Main image is required", "error");
        return;
      }

      if (isArray(data.imageUrl) && data.imageUrl?.length > 0) {
        const images = new FormData();

        images.append(`files[0].fileName`, data.imageUrl[0].name);
        images.append("files[0].file", data.imageUrl[0]);
  
        const result = await uploadFiles(images);
  
        const path = result?.length ? result[0].path : '';
        uploadedPath = path;
      }

        const model = {
            supplierReference: 'Supplier',
            supplierCode: venderInfo?.code,
            supplierName: venderInfo?.storeDetails?.storeName,
            supplierId: venderInfo?.id,
            title: data.title,
            description: data.description,
            imageUrl: uploadedPath,
            keywords: data.keywords,
            content: content,
            isPublished: true,
         };

         var response = await createBlog(model);

         if(response){
            setBlogModel(model);
            setCreatedBlog(response);
            setBlog(true);
            setToast("successfully publish new blog");
         }
        
    } catch (e) {
      reportError(
        "error in : CampaignForm: onSubmit, error: ",
        e
      );
    }
  };

  const onSaveAsDraft = async (data) => {
    try {

      let uploadedPath = null;
      if(!isArray(data.imageUrl) || data.imageUrl?.length <= 0){
        setToast("Main image is required", "error");
        return;
      }

      if (isArray(data.imageUrl) && data.imageUrl?.length > 0) {
        const images = new FormData();

        images.append(`files[0].fileName`, data.imageUrl[0].name);
        images.append("files[0].file", data.imageUrl[0]);
  
        const result = await uploadFiles(images);
  
        const path = result?.length ? result[0].path : '';
        uploadedPath = path;
      }

        const model = {
            supplierReference: 'Supplier',
            supplierCode: venderInfo?.code,
            supplierName: venderInfo?.storeDetails?.storeName,
            supplierId: venderInfo?.id,
            title: data.title,
            description: data.description,
            imageUrl: uploadedPath,
            keywords: data.keywords,
            content: content,
            isPublished: false,
         };

         var response = await createBlog(model);

         if(response){
            setBlogModel(model);
            setCreatedBlog(response);
            setBlog(true);
            setToast("successfully save changes");
         }
        
    } catch (e) {
      reportError(
        "error in : CreateBlog: onSubmit, error: ",
        e
      );
    }
  };

  const onPreview = () => {    
    window.open(newBlog?.permalink, "_blank")
  };

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    let innerText = ``;
    if (newBlog?.permalink){
        innerText = `${newBlog?.permalink}`;
    }
        
    textField.innerText = innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (innerText !== "") setToast("Permalink copied to clipboard");
    else setToast("No content to copy", "error");
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}
      <BasicPageHeader title="Create Blog" onGoBack={onGoBack} hidden={isLoading} />
      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Store details"
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>Store name</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.storeDetails?.storeName}
                  >
                    {venderInfo?.storeDetails?.storeName}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Business support email
                  </div>
                  <div>
                    {venderInfo?.vendorDetails?.businessEmailAddress}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Mobile number</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.storeDetails?.contactNumber}
                  >
                    {venderInfo?.storeDetails?.contactNumber}
                  </div>
                </div>
              </div>

              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Industry</div>
                  <div>{venderInfo?.storeDetails?.industry}</div>
                </div>
                <div className={clsx(styles.address,styles.campaignInfoItem)}>
                  <div className={styles.campaignInfoHeader}>
                    Business address
                  </div>
                  <div>{venderInfo?.storeDetails?.businessAddress}</div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Subscription plan"
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                  <div className={styles.campaignInfoHeader}>Plan</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.subscriptionPlan?.plan}
                  >
                    {venderInfo?.subscriptionPlan?.plan}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Subscription plan status
                  </div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.subscriptionPlan?.planStatus}
                  >
                    {venderInfo?.subscriptionPlan?.planStatus}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Expired date</div>
                  <div
                    className={styles.elipsis}
                    title={venderInfo?.subscriptionPlan?.expiredDate}
                  >
                    {venderInfo?.subscriptionPlan?.expiredDate}
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          {blogCreated && (
            <>
                <Panel className={styles.panelWrap}>
                <PanelHeader
                  className={styles.campaignInfoHeader}
                  title="New Created Blog"
                />
                <div className={styles.campaignInfoContainer}>
                  <div className={styles.campaignInfoRow}>
                    <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                      <div className={styles.campaignInfoHeader}>Name</div>
                      <div
                        className={styles.elipsis}
                        title={'Title'}
                      >
                        {blogModel?.title}
                      </div>
                    </div>
                    <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                      <div className={styles.campaignInfoHeader}>Status</div>
                      <div
                        className={styles.elipsis}
                        title={'Status'}
                      >
                        {blogModel?.isPublished ? "Published" : "Draft"}
                      </div>
                    </div>
                    <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                      <div className={styles.campaignInfoHeader}>Keywords</div>
                      <div
                        className={styles.elipsis}
                        title={'Keywords'}
                      >
                        {blogModel?.keywords}
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
  
            <Panel className={styles.panelWrap}>
              <PanelHeader
                className={styles.campaignInfoHeader}
                title="Products"
              />
              <div className={styles.btnActionContainer}>
                    <SixtyButton
                      variant="secondary"
                      onClick={() => onBrowseBlogProducts()}
                      className={styles.approveBtn}
                      >
                      {"Manage Products"}
                    </SixtyButton>
             </div>
              <div className={styles.campaignInfoContainer}>
                <div className={styles.campaignInfoRow}>
                  <div className={clsx(styles.campaignInfoItem, styles.elipsis)}>
                    <div className={styles.campaignInfoHeader}>Blog Products</div>
                    <div
                      className={styles.elipsis}
                      title={'Blog Products'}
                    >
                      {0}
                    </div>
                  </div>
                </div>
              </div>
            </Panel>

            <Panel className={styles.panelWrap}>
              <PanelHeader
                className={styles.campaignInfoHeader}
                title="Permalink"
              />
              <div className={styles.btnActionContainer}>
                    <SixtyButton
                      variant="secondary"
                      onClick={() => copyToClipboard()}
                      className={styles.approveBtn}
                      >
                      {"Copy"}
                    </SixtyButton>
                    <SixtyButton
                      variant="tertiary"
                      onClick={() => onPreview()}
                      className={styles.approveBtn}
                      >
                      {"Preview"}
                    </SixtyButton>
             </div>
              <div className={styles.campaignInfoContainer}>
                <div className={styles.campaignInfoRow}>
                  <div className={clsx(styles.campaignFullInfoItem, styles.elipsis)}>
                    <div className={styles.campaignInfoHeader}>Blog Permalink</div>
                    <div
                      className={styles.elipsis}
                      title={'Permalink'}
                    >
                      {newBlog?.permalink}
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
            </>
          )}

          {blogCreated == false && (
            <>
              <Panel className={styles.panelWrap}>
                  <PanelHeader
                    className={styles.sellerInfoHeader}
                    title="Setup Vendor Blog"
                  />
                  <FormProvider {...formMethods}>
                  <form
                      className={styles.form}
                  >
                  {venderInfo && (
                   <Form setContent={setContent} />
                  )}         
                  </form>
              </FormProvider>
              </Panel>
            </>
          )}

        </div>
      </div>

        <footer className={styles.footer} hidden={isLoading}>
          {blogCreated == false && (
            <>
            <SixtyButton
              type="submit"
              variant="tertiary"
              onClick={formMethods.handleSubmit(onSaveAsDraft)}
              className={styles.approveBtn}
              disabled={blogLoading || uploadingFiles}
              >
              {"Save as draft"}
            </SixtyButton>
            <SixtyButton
              type="submit"
              onClick={formMethods.handleSubmit(onSubmit)}
              className={styles.approveBtn}
              disabled={blogLoading || uploadingFiles}
              >
              {"Publish content"}
            </SixtyButton>
            </>
          )}

        </footer>
      </div>
    </React.Fragment>
  );
};

export default CreateBlog;
