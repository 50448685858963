import React, { useState } from "react";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";

const MediaDetails = ({ socialMediaListing }) => {


  const isUrlValid = (userInput) => {

    if(!userInput) return (false);

    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return (false);
    else
        return (true);
 };

  return (
    <React.Fragment>
      <div className={styles.sellerRequestFormContainer}>

      <div className={styles.sellerRequestForm} >
        <div>

         {socialMediaListing && socialMediaListing.map((field, index) =>(
         <React.Fragment key={field.id}>

           <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.sellerInfoHeader}
              title={socialMediaListing[index].name}
            />
            <div className={styles.sellerInfoContainer}>
              <div className={styles.sellerInfoRow}>
                <div className={clsx(styles.sellerInfoItem, styles.elipsis)}>
                  <div className={styles.sellerInfoHeader}>Follower Range</div>
                  <div
                    className={styles.elipsis}
                    title={socialMediaListing[index].followerRange}
                  >
                    {socialMediaListing[index].followerRange}
                  </div>
                </div>
                <div className={styles.sellerInfoItem}>
                  <div className={styles.sellerInfoHeader}>
                    Profile Link
                  </div>
                  <div
                    className={styles.elipsis}
                    title={socialMediaListing[index].profileLink}
                  >
                    {isUrlValid(socialMediaListing[index].profileLink) &&(
                     <a className={styles.link} href={socialMediaListing[index].profileLink} target={"_blank"} rel={"noreferrer"}>
                         {socialMediaListing[index].profileLink}
                     </a>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </Panel>

         </React.Fragment>
         ))}

        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MediaDetails;
