import { queryClient } from "common/query";
import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";
import { saveAs } from "file-saver";


export function useGetAffiliateProgramPostContents(filter) {
  return useQuery(
    [Endpoints.Posting.GetAffiliateProgramPostContents, filter],
    () =>
      api
        .post(Endpoints.Posting.GetAffiliateProgramPostContents, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetAffiliateProgramPostContents: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignRewardRequests(filter) {
  return useQuery(
    [Endpoints.Posting.GetCampaignRewardRequests, filter],
    () =>
      api
        .post(Endpoints.Posting.GetCampaignRewardRequests, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetCampaignRewardRequests: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignPublishContent(filter) {
  return useQuery(
    [Endpoints.Posting.GetCampaignPublishContent, filter],
    () =>
      api
        .post(Endpoints.Posting.GetCampaignPublishContent, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetCampaignPublishContent: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetPostingContentDetail(id) {
    return useQuery(
      [Endpoints.Posting.GetPostingContentDetail, id],
      () =>
        api
          .get(Endpoints.Posting.GetPostingContentDetail + `/${id}`)
          .then((response) => response?.data?.result),
      {
        onError: (error) => {
          reportError("useGetPostingContentDetail: useQuery: onError: error", error);
        },
      }
    );
  }

  export function useUpdateStatus() {
    return useMutation(
      (data) =>
        api
          .put(Endpoints.Posting.UpdatePostStatus, data)
          .then((response) => response?.data),
      {
        onSuccess: (data) => {
          queryClient.refetchQueries(Endpoints.Posting.GetCampaignPublishContent);
        },
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError(
            "useUpdateStatus: useMutation: onError: error",
            error
          );
        },
      }
    );
  }

  export function useSuspendOrActivateContent() {
    return useMutation(
      (id) =>
        api
          .put(Endpoints.Posting.SuspendOrActivateContent + `/${id}`)
          .then((response) => response?.data),
      {
        onSuccess: (data) => {
          queryClient.refetchQueries(Endpoints.Posting.GetCampaignPublishContent);
        },
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError(
            "useSuspendOrActivateContent: useMutation: onError: error",
            error
          );
        },
      }
    );
  }

  export function useDownloadPostMedia() {
    return useMutation(
      (id) =>
        api
          .get(Endpoints.Posting.DownloadPostMedia + `/${id}`, {
            responseType: "blob",
            "Accept-Encoding": "gzip, deflate, br",
          })
          .then((response) => {
            let filename = response.headers["content-disposition"]
            ?.split(";")
            .find((n) => n.includes("filename="))
            .replace("filename=", "")
            .trim();
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename);
          }),
      {
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError(
            "useDownloadPostMedia: useMutation: onError: error",
            error
          );
        },
      }
    );
  }

export function useGetCampaignPublishContentRequestCount(id) {
    return useQuery(
      [Endpoints.Posting.GetCampaignPublishContentRequestCount, id],
      () =>
        api
          .get(Endpoints.Posting.GetCampaignPublishContentRequestCount + `/${id}`)
          .then((response) => response?.data?.result),
      {
        onError: (error) => {
          reportError("useGetCampaignPublishContentRequestCount: useQuery: onError: error", error);
        },
      }
    );
}

export function useGetAffiliateContentCount() {
  return useQuery(
    [Endpoints.Posting.GetAffiliateContentCount],
    () =>
      api
        .get(Endpoints.Posting.GetAffiliateContentCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetAffiliateContentCount: useQuery: onError: error", error);
      },
    }
  );
}