import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import CampaignEventTable from "../components/CampaignEventTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import Tabs from "components/Tabs";
import { useGetCampaignStatusCount } from "queries/campaign";

const CampaignEvents = () => {

  const onCampaignClick = (value) => {
    
    const role = localStorage.getItem("userRole");

    if (role === "InternalUser") {
      const path = `/admin/campaigns/AnalyticDetails/${value?.id}`;
      window.open(path, "_blank")
    } else {
      const path = `/dashboard/campaigns/stepToComplete/${value?.id}`;
      window.open(path, "_blank")
    }

  };


  return (
    <div>
      <GridHeader header="Events" 
         renderButtons={() => (
          <>
          </>
         )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.campaignTable}>
           <CampaignEventTable onCampaignClick={onCampaignClick} />
        </div>
      </Panel>
    </div>
  );
};

export default CampaignEvents;
