import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useImmer } from "utils/hooks/useImmer";
import styles from "./index.module.css";
import { ReactComponent as FilterIcon } from "assets/icons/filter-icon.svg";
import SixtyButton from "components/SixtyButton";
import useOnClickOutside from "utils/hooks/useOnClickOutside";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

export default function Filter({
  filter,
  setFilter,
  renderFilter,
  columnLayout,
}) {
  const [status, setStatus] = useState("closed");

  const clickFilter = (e) => {
    if (status === "closed") setStatus("open");
    else {
      setStatus("closed");
    }
  };

  const anchorEl = useRef(null);

  return (
    <div className={styles.filterContainer}>
      <button
        className={clsx(styles.filterButton, {
          [styles.activeButton]: status === "open",
        })}
        onClick={clickFilter}
        ref={anchorEl}
      >
        <FilterIcon className={styles.filterIcon} /> Filters
      </button>
      {status === "open" && (
        <FilterBox
          handleClose={() => setStatus("closed")}
          state={filter}
          onFilterChange={setFilter}
          anchorEl={anchorEl}
          renderFilter={renderFilter}
          open={status === "open"}
          columnLayout={columnLayout}
        />
      )}
    </div>
  );
}

const FilterBox = ({
  state,
  onFilterChange,
  handleClose,
  anchorEl,
  renderFilter,
  columnLayout,
}) => {
  const [filterVal, setFilterVal] = useImmer(() => state);

  const filterBoxRef = useRef(null);
  useOnClickOutside(
    filterBoxRef,
    () => {
      handleClose();
    },
    [anchorEl]
  );

  // on clicking apply
  const applyFilter = () => {
    onFilterChange(filterVal);
    handleClose();
  };

  // on clicking clear
  const handleClear = () => {
    onFilterChange({});
  };

  // when filter changes in Parent, update state here. (reset)
  useEffect(() => {
    setFilterVal(() => {
      return state;
    });
  }, [state, setFilterVal]);

  return (
    <div className={styles.filterBox} ref={filterBoxRef}>
      <div className={styles.header}>Filters</div>
      <div
        className={clsx(styles.body, {
          [styles.columnLayout]: columnLayout,
        })}
      >
        {renderFilter &&
          renderFilter({
            state: filterVal,
            setState: setFilterVal,
          })}
      </div>
      <div className={styles.footer}>
        <SixtyButton onClick={applyFilter}>Apply</SixtyButton>
        <SixtyButton variant="secondary" onClick={handleClear}>
          Clear all filters
        </SixtyButton>
        <SixtyButton variant="secondary" onClick={handleClose}>
          Close
        </SixtyButton>
      </div>
    </div>
  );
};

export function FilterRow({ children }) {
  return <div className={styles.row}>{children}</div>;
}

export function FilterGroup({ children, label = "", colWidth }) {
  return (
    <div
      className={clsx(
        styles.filterGroup,
        colWidth && styles[`width-${colWidth}`] // 2 or 3
      )}
    >
      <div className={styles.filterGroupTitle}>{label}</div>
      {children}
    </div>
  );
}

export function FilterItem({ children, colWidth, fullWidth }) {
  return (
    <div
      className={clsx(styles.filterItem, {
        [styles.fullWidth]: fullWidth,
        [styles.itemWidth50]: colWidth && colWidth === 2,
        [styles.itemWidth33]: colWidth && colWidth === 3,
      })}
    >
      {children}
    </div>
  );
}

export const FilterChipContainer = ({ children }) => (
  <div className={styles.filterChipsContainer}>{children}</div>
);

export const ChipItem = ({ label, name, handleRemove }) => (
  <div className={styles.chipItem}>
    <div className={styles.chipLabel}>{label}</div>{" "}
    <div
      className={styles.chipIcon}
      onClick={() => {
        handleRemove(name);
      }}
    >
      <CloseIcon />
    </div>
  </div>
);
