import styles from "./index.module.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import SixtyButton from "components/SixtyButton";
import Avatar from "components/Avatar";
import { ReactComponent as SixtyLogo } from "assets/images/Appbar-Logo.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/Icon-Notification.svg";
import NotificationsFlyOut from "components/NotificationsFlyOut";
import AdminDropdown from "components/AdminDropdown";
import { useHistory } from "react-router-dom";
import { useGetNotifications } from "queries/vendor";
import GlobalSearch from "components/GlobalSearch";

export default function AdminTopbar() {
  const [searchValue, setSearch] = useState("");
  const [isNotificationsModalOpen, setNotificationsModalOpen] = useState(false);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const history = useHistory();

  const { data: notificationData } = useGetNotifications();

  return (
    <div className={styles.topbar}>
      <div className={styles.leftContainer}>
        <Link to="/admin/vendors">
          <SixtyLogo />
        </Link>
        <GlobalSearch placeholder={"Search for vendors"} />
      </div>

      <div className={styles.rightContainer}>
        <div className={styles.notificationBtn}>
          <SixtyButton
            variant="iconButton"
            onClick={() => setNotificationsModalOpen(true)}
          >
            <NotificationIcon />
          </SixtyButton>
          {isNotificationsModalOpen && notificationData && (
            <NotificationsFlyOut
              notificationData={notificationData}
              isOpen={isNotificationsModalOpen}
              onAttemptClose={() => setNotificationsModalOpen(false)}
            />
          )}
        </div>
        <div
          className={styles.avatarBtn}
          onClick={() => setProfileModalOpen(true)}
        >
          <Avatar name={"A"} />
          {isProfileModalOpen && (
            <AdminDropdown
              isOpen={isProfileModalOpen}
              onAttemptClose={() => setProfileModalOpen(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
