import { SortItem } from "components/SixtyTable/components/Sort";

export default function OrderSort({ state, handleSortChange }) {
  return (
    <>
      <SortItem
        label="Order number (Ascending)"
        name="orderByAscending"
        checked={state === "orderByAscending"}
        onChange={() => handleSortChange("orderByAscending")}
      />
      <SortItem
        label="Order number (Descending)"
        name="orderByDescending"
        checked={state === "orderByDescending"}
        onChange={() => handleSortChange("orderByDescending")}
      />
      <SortItem
        label="Sales (Ascending)"
        name="orderBySaleAscending"
        checked={state === "orderBySaleAscending"}
        onChange={() => handleSortChange("orderBySaleAscending")}
      />
      <SortItem
        label="Sales (Descending)"
        name="orderBySaleDescending"
        checked={state === "orderBySaleDescending"}
        onChange={() => handleSortChange("orderBySaleDescending")}
      />
      <SortItem
        label="Customer name (Ascending)"
        name="orderByCustomerAscending"
        checked={state === "orderByCustomerAscending"}
        onChange={() => handleSortChange("orderByCustomerAscending")}
      />
      <SortItem
        label="Customer name (Descending)"
        name="orderByCustomerDescending"
        checked={state === "orderByCustomerDescending"}
        onChange={() => handleSortChange("orderByCustomerDescending")}
      />
    </>
  );
}
