import styles from "./index.module.css";
import clsx from "clsx";

const EarningHistory = ({ data }) => {
  return (
    <div className={styles.earningHistoryContainer}>
      <div
        className={clsx(
          styles.earningHistoryItem,
          styles.firstEarningHistoryItem
        )}
      >
        <div className={styles.header}>Revenue</div>
        <div className={styles.value}>{data?.totalSales}</div>
        {/* <div
          className={clsx(
            data?.totalSales >= 0 ? [styles.profit] : [styles.loss]
          )}
        >
          {(data?.totalSales > 0 ? "+" : "") + revenueProfitPercent + "%"}
        </div> */}
      </div>
      <div className={styles.earningHistoryItem}>
        <div className={styles.header}>Commission</div>
        <div className={styles.value}>{data?.commission}</div>
        {/* <div
          className={clsx(
            commissionProfitPercent >= 0 ? [styles.profit] : [styles.loss]
          )}
        >
          {(commissionProfitPercent > 0 ? "+" : "") +
            commissionProfitPercent +
            "%"}
        </div> */}
      </div>
      <div className={styles.earningHistoryItem}>
        <div className={styles.header}>Orders</div>
        <div className={styles.value}>{data?.orderCount}</div>
        {/* <div
          className={clsx(
            orderProfitPercent >= 0 ? [styles.profit] : [styles.loss]
          )}
        >
          {(orderProfitPercent > 0 ? "+" : "") + orderProfitPercent + "%"}
        </div> */}
      </div>
      <div
        className={clsx(
          styles.earningHistoryItem,
          styles.firstEarningHistoryItem
        )}
      >
        <div className={styles.header}>Customers</div>
        <div className={styles.value}>{data?.customerCount}</div>
        {/* <div
          className={clsx(
            customerProfitPercent >= 0 ? [styles.profit] : [styles.loss]
          )}
        >
          {(customerProfitPercent > 0 ? "+" : "") + customerProfitPercent + "%"}
        </div> */}
      </div>
    </div>
  );
};

export default EarningHistory;
