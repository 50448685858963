import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import CampaignPayoutTable from "../components/CampaignPayoutTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import PayoutRequestDetails from "../components/PayoutRequestDetails";
import Tabs from "components/Tabs";

const CampaignPayout = () => {

  const [isPayoutDetailOpen, setPayoutDetailOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  const payoutDetailRef = useRef();
  const [selectedPayout, setSelectedPayout] = useState({});


  const InfluencerDetailTitle = () => {
    return (
      <div className={styles.payoutDetailModalTitleBar}>
        <div className={styles.payoutNameContainer}>
          <div>
            <Avatar name={selectedPayout.sellerName} />
          </div>
          <div className={styles.modalTitle}>{selectedPayout.sellerName}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setPayoutDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onPayoutClick = (value) => {
    setSelectedPayout(value);
    setPayoutDetailOpen(true);
  };

  return (
    <div>
      <GridHeader header="Influencer Payout Requests" 
         renderButtons={() => (
          <>
          </>
         )}
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.payoutTable}>
          <CampaignPayoutTable campaignId={id} onPayoutClick={onPayoutClick} />
        </div>
      </Panel>

      {isPayoutDetailOpen && (
        <Modal
          isOpen={isPayoutDetailOpen}
          onAttemptClose={() => {
            history.push("/admin/PayoutRequest");
            setPayoutDetailOpen(false);
          }}
          modalRef={payoutDetailRef}
          Title={InfluencerDetailTitle}
        >
          <PayoutRequestDetails
            selectedPayout={selectedPayout}
            handleClose={() => setPayoutDetailOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default CampaignPayout;
