import styles from "./index.module.css";
import Navigation from "components/Navigation";
import Topbar from "components/Topbar";
import { ReactComponent as IconHome } from "assets/icons/Icon-Home.svg";
import { ReactComponent as IconOrders } from "assets/icons/Icon-Orders.svg";
import { ReactComponent as IconProducts } from "assets/icons/Icon-Products.svg";
import { ReactComponent as IconSellers } from "assets/icons/Icon-Sellers.svg";
import { ReactComponent as IconCustomers } from "assets/icons/Icon-Customers.svg";
import { ReactComponent as IconDiscounts } from "assets/icons/Icon-Discounts.svg";
import { ReactComponent as IconReports } from "assets/icons/Icon-Reports.svg";
import { ReactComponent as IconWalletBalances } from "assets/icons/Icon-CreditBalance.svg";
import { ReactComponent as IconSettings } from "assets/icons/Icon-Settings.svg";
import { ReactComponent as IconCampaign } from "assets/icons/Icon-Reports.svg";
import CustomToastContainer from "components/Toast";
import { useGetSideMenuRecordCount } from "queries/dashboard";
import { useCheckVendorOnboardingStatus, useGetAffiliateProvider } from "queries/vendor";
import { useGetVideoStoryDropdown } from "queries/campaign";
import { useGetVendorCreditBalance } from "queries/wallet";
import { useEffect } from "react";
import React, { useState, useRef } from "react";
import { useHistory } from "react-router";
import { queryClient } from "common/query";
import SixtyButton from "components/SixtyButton";
import Modal from "components/Modal";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import { ReactComponent as BackIcon } from "assets/icons/Icon-Back-Button.svg";
import CreateCampaign from "../../../screens/CampaignSubProducts/Components/UgcCampaign/Components/CreateCampaign";
import CampaignOptions from "../../../screens/CampaignSubProducts/Components/CampaignOptions";
import ContentRoadmap from "../../../screens/CampaignSubProducts/Components/ContentRoadmap";
import { setToast } from "components/Toast";
import { clearStorage, writeToStorage } from "../../../utils/storage";

const Navbar = (props) => {
  const { data } = useGetSideMenuRecordCount();
  const { data : providerData, isFetchedAfterMount } = useGetAffiliateProvider();
  const { data: videoStories } = useGetVideoStoryDropdown();
  const { data: wallet } = useGetVendorCreditBalance();

  const [isAdsCampaign, setAdsCampaign] = useState(false);
  const [ContentTemplateId, setTemplate] = useState(null);

  const newCampaignRef = useRef();
  const [isNewCampaign, setNewCampaign] = useState(false);

  const campaignGoalRef = useRef();
  const [isCampaignGoal, setCampaignGoal] = useState(false);

  const contentGoalRef = useRef();
  const [isContentGoal, setContentGoal] = useState(false);

  const NewCampaignTitle = () => {
    return (
      <div className={styles.modalTitleBar}>
        <div>
          <BackIcon
            className={styles.closeIcon}
            onClick={() => onBackContentRoadmap(false)}
          />
        </div> 
        <div className={styles.modalContainer}>
          <div className={styles.modalTitle}>{'New Content Creation Campaign'}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setNewCampaign(false)}
          />
        </div>
      </div>
    );
  };

  const CampaignGoalTitle = () => {
    return (
      <div className={styles.modalTitleBar}>
        <div className={styles.modalContainer}>
          <div className={styles.modalTitle}>{'Tell Us Your Campaign Objective'}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setCampaignGoal(false)}
          />
        </div>
      </div>
    );
  };

  const ContentGoalTitle = () => {
    return (
      <div className={styles.modalTitleBar}>
        <div className={styles.modalContainer}>
          <div className={styles.modalTitle}>{'Choose Your Content Goal'}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setContentGoal(false)}
          />
        </div>
      </div>
    );
  };

  const onComingSoon = () => {
    setToast("Coming Soon", "error");
  };

  const onOpenContentRoadmap = () => {
    setContentGoal(true);
    setCampaignGoal(false);
  };

  const onBackContentRoadmap = () => {
    setContentGoal(true);
    setNewCampaign(false);
  };

  const onNewAdsCampaign = () => {
    setAdsCampaign(true);
    setNewCampaign(true);
    setCampaignGoal(false);
  };

  const onOpenNewCampaign = (value) => {
    setAdsCampaign(false);
    setNewCampaign(true);
    setContentGoal(false);
    setTemplate(value);
  };

  const onCloseNewCampaign = () => {
    setNewCampaign(false);
  };

  const onOpenCampaignGoal = () => {
    setCampaignGoal(true);
  };

  const onCloseCampaignGoal = () => {
    setCampaignGoal(false);
  };

  const defaultSettings = [
    {
      itemId: "9.1",
      title: "Company",
      path: "/dashboard/settings/company",
    },
    // {
    //   itemId: "9.2",
    //   title: "Shippings",
    //   path: "/dashboard/settings/shippings",
    // },
    // {
    //   itemId: "9.3",
    //   title: "Taxes",
    //   path: "/dashboard/settings/taxes",
    // },
    // {
    //   itemId: "9.4",
    //   title: "Billing",
    //   path: "/dashboard/settings/billing",
    // },
    // {
    //   itemId: "9.5",
    //   title: "Notifications",
    //   path: "/dashboard/settings/notifications",
    // },
    // {
    //   itemId: "9.6",
    //   title: "Wallet balances",
    //   ElemBefore: IconWalletBalances,
    //   path: "/dashboard/walletbalances",
    // },
    // ["Lazada" ].includes(providerData?.provider) ? 
    // { 
    //   itemId: "9.7",
    //   title: "Lazada Import",
    //   path: "/dashboard/settings/lazada-import",} : {}
  ];

  return (
    <div>
       <div className={styles.dashboardBtnContainer}>
          <SixtyButton
              variant="primary"
              onClick={onOpenCampaignGoal}
              className={"create-campaign-btn"}
          >
              Create Campaign
          </SixtyButton>
       </div>

      <Navigation
        items={[
          {
            itemId: "1",
            title: "Home",
            ElemBefore: IconHome,
            path: "/dashboard",
          },
          {
            itemId: "2",
            title: "Topup",
            ElemBefore: IconWalletBalances,
            path: "/dashboard/walletbalances",
          },
           {
             itemId: "3",
             title: "My Orders",
             ElemBefore: IconCampaign,
             subNav: [
               {
                 itemId: "3.1",
                 title: "All Orders",
                 recordCount: data?.campaignCount,
                 path: "/dashboard/VideoOrders",
               },
             ],
           },
          // {
          //   itemId: "3",
          //   title: "Orders",
          //   ElemBefore: IconOrders,
          //   subNav: [
          //     {
          //       itemId: "3.1",
          //       title: "All Orders",
          //       recordCount: data?.orderCount,
          //       path: "/dashboard/orders",
          //     },
          //     {
          //       itemId: "3.2",
          //       title: "Refund Requests",
          //       path: "/dashboard/refundRequests",
          //     },
          //   ],
          // },
          // {
          //   itemId: "4",
          //   title: "Products",
          //   ElemBefore: IconProducts,
          //   subNav: [
          //     {
          //       itemId: "4.1",
          //       title: "All Products",
          //       recordCount: data?.productCount,
          //       path: "/dashboard/products",
          //     },
          //     {
          //       itemId: "4.2",
          //       title: "Product Categories",
          //       recordCount: data?.productCategoryCount,
          //       path: "/dashboard/product-categories",
          //     },
          //   ],
          // },
          // {
          //   itemId: "5",
          //   title: "Creators",
          //   ElemBefore: IconSellers,
          //   subNav: [
              // {
              //   itemId: "5.1",
              //   title: "All Creators",
              //   recordCount: data?.sellerCount,
              //   path: "/dashboard/creators",
              // },
              // {
              //   itemId: "5.2",
              //   title: "Product requests",
              //   path: "/dashboard/product-requests",
              // },
              // {
              //   itemId: "5.2",
              //   title: "Requests",
              //   recordCount: data?.sellerRequestCount,
              //   path: "/dashboard/creatorRequest",
              // },
              // {
              //   itemId: "5.3",
              //   title: "Affiliate Commissions",
              //   path: "/dashboard/settings/affiliateCommission",
              // },
              // {
              //   itemId: "5.3",
              //   title: "Commission Tiers",
              //   path: "/dashboard/tiers",
              // },
           // ],
         // },
          // {
          //   itemId: "6",
          //   title: "Customers",
          //   recordCount: data?.customerCount,
          //   ElemBefore: IconCustomers,
          //   path: "/dashboard/customers",
          // },
          // {
          //   itemId: "7",
          //   title: "Promotions",
          //   recordCount: data?.discountCount,
          //   ElemBefore: IconDiscounts,
          //   path: "/dashboard/promotions",
          // },
          // {
          //   itemId: "8",
          //   title: "Reports",
          //   ElemBefore: IconReports,
          //   subNav: [
          //     {
          //       itemId: "8.1",
          //       title: "Reports",
          //       path: "/dashboard/reports",
          //     },
          //     {
          //       itemId: "8.2",
          //       title: "Insights Reporting",
          //       path: "/dashboard/campaign/reporting",
          //     },
          //   ],
          // },
          {
            itemId: "9",
            title: "Settings",
            ElemBefore: IconSettings,
            subNav: defaultSettings           
          },
        ]}
      />
      {isNewCampaign && (
          <Modal
              isOpen={isNewCampaign}
              onAttemptClose={() => {
                  setNewCampaign(false);
              }}
              modalRef={newCampaignRef}
              Title={NewCampaignTitle}
          >
            <CreateCampaign
               handleClose={onCloseNewCampaign}
               templateId={ContentTemplateId}
               adsCampaign={isAdsCampaign}
               availableBalance={wallet?.credit}
            />
          </Modal>
     )}
     {isCampaignGoal && (
          <Modal
              isOpen={isCampaignGoal}
              onAttemptClose={() => {
                  setCampaignGoal(false);
              }}
              modalRef={campaignGoalRef}
              Title={CampaignGoalTitle}
          >
            <CampaignOptions
               onSocialMedia={onOpenContentRoadmap}
               onAds={onNewAdsCampaign}
               onComingSoon={onComingSoon}
               handleClose={onCloseCampaignGoal}
            />
          </Modal>
     )}
     {isContentGoal && (
          <Modal
              isOpen={isContentGoal}
              onAttemptClose={() => {
                  setContentGoal(false);
               }}
              modalRef={campaignGoalRef}
              Title={ContentGoalTitle}
          >
            <ContentRoadmap
               items={videoStories}
               onSocialMedia={onOpenNewCampaign}
            />
          </Modal>
     )}
    </div>
  );
};

export default function DashboardLayout({ children }) {

  const history = useHistory();

  const {
    data: onBoardingStatus,
    error,
    isError,
    isSuccess,
    isFetchedAfterMount,
  } = useCheckVendorOnboardingStatus({
    // retry: false,
    // staleTime: 0,
  });

  useEffect(() => {
    if (
      isFetchedAfterMount &&
      isError &&
      error &&
      error?.response?.data?.result?.requiredPayment &&
      error?.response?.data?.result?.subscriptionPaymentStatus === "Pending"
    ) {
      history.push("/pricing-plan");
    }
  }, [error, isError, history, isFetchedAfterMount]);

  useEffect(() => {
    if (
      isFetchedAfterMount &&
      isSuccess &&
      onBoardingStatus
    ) {
      if(onBoardingStatus?.requiredPayment ||
         onBoardingStatus?.subscriptionPaymentStatus === "PendingForPayment"){
          history.push("/pricing-plan");
      }    
    }
  }, [history, isFetchedAfterMount, isSuccess, onBoardingStatus]);

  const user = queryClient.getQueryData("user");

  useEffect(() => {
    if (
      isError &&
      error &&
      !error.response.data?.result?.completedVendorSetup &&
      user?.email
    ) {
      clearStorage();
      history.push("/auth/login");
    }
  }, [onBoardingStatus, error, isError, history, user]);

  return (
    <>
      <Topbar />
      <div className={styles.page}>
        <Navbar />
        {children}
        <div>
          <CustomToastContainer />
        </div>
      </div>
    </>
  );
}
