import Panel from "components/SixtyPanel";
import { map } from "lodash";
import styles from "./index.module.css";
import { ReactComponent as SuccessIcon } from "assets/icons/Icon-Success-Orange.svg";
import Button from "components/SixtyButton";
import clsx from "clsx";
import { reportError } from "crashlytics";

const PricingPlanTemplate = ({
  subscriptionPlan,
  handleSubmit,
  activePanelId = "",
  setActivePanel = () => {},
  isCurrentplan,
  isLoading,
}) => {
  const { id, name, label, salePrice, freeTrial, isCustomPlan } = subscriptionPlan;
  const isActivePanel = () => activePanelId === name;

  const onSubmit = async (id) => {
    if (id) {
      try {
        await handleSubmit(id);
      } catch (error) {
        reportError("ProceedSubscriptionData: onSubmit: error: ", error);
      }
    }
  };

  const role = localStorage.getItem("userRole");
  const isVendorAccess = role === "Supplier";

  return (
    <Panel
      className={clsx(styles.panelWrap, {
        [styles.panelActive]: isActivePanel(),
      })}
      onClick={() => !isCurrentplan && setActivePanel(name)}
    >
      <div className={styles.label}>{name}</div>
      <div className={styles.priceContainer}>
        
        {!isCustomPlan &&(
          <div className={styles.priceUnit}>RM</div>
        )}
        {freeTrial && !isCustomPlan &&(
          <div className={styles.salePrice}>{0}</div>
        )}
         {!freeTrial && ! isCustomPlan &&(
          <div className={styles.salePrice}>{salePrice}</div>
         )}
        <div className={styles.duration}>{label}</div>
      </div>
      <div className={styles.panelContainer}>
      {map(subscriptionPlan.packages, (feature, index) => {
        return (
          <div key={index} className={styles.row}>
             <div className={styles.successContainer}>
              <SuccessIcon />
             </div>
            <span className={styles.featureText}>{feature.title}</span>
          </div>
        );
      })}
      </div>
     {role && isVendorAccess &&(
      <footer className={styles.footer}>
      <Button
        variant={isActivePanel() ? "primary" : "secondary"}
        className={clsx(styles.submitBtn, {
          [styles.activeBtn]: isActivePanel(),
        })}
        onClick={() => onSubmit(id)}
        fullWidth
        disabled={isCurrentplan || isLoading}
        size="large"
      >
        {freeTrial ? "Start free trial" : isCurrentplan ? "Subscribed" : "Get started"}
      </Button>
    </footer>
     )}

    </Panel>
  );
};

export default PricingPlanTemplate;
