import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import VendorsTable from "../components/VendorSelectionTable";
import CampaignForm from "../components/CampaignForm";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import { setToast } from "components/Toast";
import BasicPageHeader from "../../Components/BasicHeader";

const VendorSelection = () => {
  const [isVendorDetailOpen, setVendorDetailOpen] = useState(false);
  const history = useHistory();
  const vendorDetailRef = useRef();
  const [selectedVendor, setSelectedVendor] = useState({});

  const VendorDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedVendor.vendorName} />
          </div>
          <div className={styles.modalTitle}>{selectedVendor.vendorName}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setVendorDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onVendorClick = (value) => {
    setSelectedVendor(value);
    setVendorDetailOpen(true);
  };

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <BasicPageHeader title="Vendor Selection" onGoBack={onGoBack} />
      <Panel className={styles.panelWrap}>
        <div className={styles.vendorTable}>
          <VendorsTable onVendorClick={onVendorClick} />
        </div>
      </Panel>
      {isVendorDetailOpen && (
        <Modal
          isOpen={isVendorDetailOpen}
          onAttemptClose={() => {
            setVendorDetailOpen(false);
          }}
          modalRef={vendorDetailRef}
          Title={VendorDetailTitle}
        >
          <CampaignForm
            selectedVendor={selectedVendor}
            handleClose={() => setVendorDetailOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default VendorSelection;
