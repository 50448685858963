import { formatCurrency } from "utils/common";
import GeneralReport from "../../../../Reports/components/GeneralReport";
import styles from "./index.module.css";

const Reports = ({ summaryData, orderCount }) => {
  return (
    <div>
      {summaryData && (
        <>
          <GeneralReport
            title= {`Affiliate sales`}
            currentMonthCount={formatCurrency(summaryData?.totalSales)}
            className={styles.reports}
            hideMinusIcon
            countsHelper={styles.countsHelper}
          >
            <div className={styles.subValue}>
              {summaryData?.totalOrderCount} orders
            </div>
          </GeneralReport>
          <GeneralReport
            title="Total products unique visitors"
            currentMonthCount={summaryData?.totalViewCount}
            className={styles.reports}
            unit="Visitors"
            hideMinusIcon
            countsHelper={styles.countsHelper}
          />
          <GeneralReport
            title="Total campaigns"
            currentMonthCount={summaryData?.totalCampaignCount}
            className={styles.reports}
            unit="Campaign"
            hideMinusIcon
            countsHelper={styles.countsHelper}
          />
          <GeneralReport
            title="Total campaign unique visitors"
            currentMonthCount={summaryData?.totalCampaignTrafficCount}
            className={styles.reports}
            unit="Campaign Visitors"
            hideMinusIcon
            countsHelper={styles.countsHelper}
          />
        </>
      )}

    </div>
  );
};

export default Reports;
