import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useForm, FormProvider } from "react-hook-form";
import { useGetCampaignProductDetail } from "queries/campaign";
import { useRemoveProductFromBlog, useUpdateBlogProductRedirection, useGetBlogProductDetail } from "queries/admin";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Button from "components/SixtyButton";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import RedirectUrlForm from "./components/RedirectUrlForm";

import styles from "./index.module.css";
import Loader from "components/Loader";
import { useHistory } from "react-router-dom";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";

import { formatCurrency } from "utils/common";

const defaultValues = {
   redirectUrl: "",
 };

const BlogProductDetails = ({ selectedProduct, handleClose }) => {

  const { id : productId } = selectedProduct;

  const { data: productData, isLoading, isError, refetch, isFetchedAfterMount } = useGetBlogProductDetail(
    productId
  );

  const formMethods = useForm({
    defaultValues,
  });

  const { setValue } = formMethods;

  useEffect(() => {

    if (productData && isFetchedAfterMount) {

     for (const [key, value] of Object.entries({
         redirectUrl: productData?.overrideRedirectUrl,
     })) {
       setValue(key, value);
     }
   }
 
   }, [setValue, productData, isFetchedAfterMount]);

  const { mutateAsync: UpdateRedirectUrl, 
          isLoading : redirectUpdating 
        } = useUpdateBlogProductRedirection();

   const onUpdateRedirectUrl = async (data) => {
     try {
      
          var model = {
            id: productId,
            redirectUrl: data?.redirectUrl
          };
           var result = await UpdateRedirectUrl(model);
      
           if(result){
               refetch();
               setToast("successfully update redirect url");
               handleClose();
            }
              
          } catch (e) {
            reportError(
              "error in : onUpdateRedirectUrl: onSubmit, error: ",
              e
            );
          }
  };

  const { mutateAsync: RemoveProductFromBlog, 
          isLoading : productUpdating 
        } = useRemoveProductFromBlog();

  const onRemoveFromBlog = async () => {
    try {

         var result = await RemoveProductFromBlog(productId);

         if(result){
            refetch();
            setToast("successfully removed product from campaign");
            handleClose();
         }
        
    } catch (e) {
      reportError(
        "error in : onRemoveFromBlog: onSubmit, error: ",
        e
      );
    }
  };

  return (
      <>
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}

      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
          <Panel className={styles.panelWrap}>

          <div className={styles.subHeader}>
                <span> Media </span>
                <div className={styles.profPhotoContainer}>
                  <div className={styles.profPhotoDropzone}>
                  <img
                    src={productData?.path}
                    className={clsx(styles.img)}
                    alt="No preview"
                  />
                  </div>
                </div>
              </div>
            <PanelHeader
              className={styles.campaignInfoHeader}
              title="Product Info"
            />
            <div className={styles.campaignInfoContainer}>
              <div className={styles.campaignInfoRow}>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>
                    Name
                  </div>
                  <div>
                    {productData?.name}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>price</div>
                  <div>
                    {formatCurrency(productData?.price)}
                  </div>
                </div>
                <div className={styles.campaignInfoItem}>
                  <div className={styles.campaignInfoHeader}>Total Visitors</div>
                  <div>
                    {productData?.totalVisitors}
                  </div>
                </div>
              </div>

            </div>


            <FormProvider {...formMethods}>
                  <form>
                    <RedirectUrlForm />    
                  </form>
                </FormProvider>
          </Panel>
     
        </div>
      </div>

      <footer className={styles.footer} hidden={isLoading}>

         <SixtyButton
               type="submit"
               onClick={formMethods.handleSubmit(onUpdateRedirectUrl)}
               className={styles.approveBtn}
               disabled={productUpdating || redirectUpdating}
             >
               {"Update Redirect Url"}
          </SixtyButton>

          <SixtyButton
               type="submit"
               variant="warning"
               onClick={onRemoveFromBlog}
               className={styles.approveBtn}
               disabled={productUpdating || redirectUpdating}
             >
               {"Remove from blog"}
          </SixtyButton>

          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={productUpdating || redirectUpdating}
          >
            Close
          </SixtyButton>

        </footer>
      </div>
    </React.Fragment>
    </>
  );
};

export default BlogProductDetails;
