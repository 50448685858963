import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useCreatePromotion, useUpdateCampaign, useDeleteCampaign } from "queries/admin";
import { useGetSubscriptionDetail } from "queries/subscription";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";

import styles from "./index.module.css";
import Loader from "components/Loader";
import PromotionForm from "../components/PromotionForm";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

const CreatePromotion = ({ handleClose }) => {

const defaultValues = {
    name: '',
    promotionCode: '',
    percentOff: 0,
    duration: '',
    durationInMonths: 0,
 };

  const formMethods = useForm({
    defaultValues,
    shouldUnregister: false,
    mode: "onChange",
  });

  const { setValue } = formMethods;
  const history = useHistory();

  const { mutateAsync: createNewPromotion, 
          isLoading : planLoading 
        } = useCreatePromotion();

  const onSubmit = async (data) => {
    try {

         const model = {
            name: data.name,
            promotionCode: data.promotionCode,
            percentOff: parseFloat(data.percentOff),
            duration: data.duration,
            durationInMonths: data.durationInMonths ? parseFloat(data.durationInMonths) : null,
         };
     
         var result = await createNewPromotion(model);

         if(result){
            setToast("successfully create new promotion");
            handleClose();
         }
        
    } catch (e) {
      reportError(
        "error in : CreatePromotion: onSubmit, error: ",
        e
      );
    }
  };

  return (
    <React.Fragment>
      <div className={styles.pricingRequestFormContainer}>
      <div className={styles.pricingRequestForm}>
        <div>
          <Panel className={styles.panelWrap}>
            <PanelHeader
              className={styles.pricingInfoHeader}
              title="Promotion"
            />
          <FormProvider {...formMethods}>
            <form
                className={styles.form}
            >        
             <PromotionForm />                   
            </form>
        </FormProvider>
         </Panel>

        </div>
      </div>

        <footer className={styles.footer}>
          <SixtyButton
            type="submit"
            onClick={formMethods.handleSubmit(onSubmit)}
            className={styles.approveBtn}
            disabled={planLoading}
          >
            Create
          </SixtyButton>

          <SixtyButton
            variant="warning"
            className={styles.cancelButton}
            onClick={handleClose}
            disabled={planLoading}
          >
            Close
          </SixtyButton>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default CreatePromotion;
