import { SortItem } from "components/SixtyTable/components/Sort";

export default function SellerSort({ state, handleSortChange }) {
  return (
    <>
      <SortItem
        label="Tiers (A-Z)"
        name="orderByTierAlphabetAscending"
        checked={state === "orderByTierAlphabetAscending"}
        onChange={() => handleSortChange("orderByTierAlphabetAscending")}
      />
      <SortItem
        label="Tiers (Z-A)"
        name="orderByTierAlphabetDescending"
        checked={state === "orderByTierAlphabetDescending"}
        onChange={() => handleSortChange("orderByTierAlphabetDescending")}
      />
      <SortItem
        label="No. of item sold (Low - high)"
        name="orderBySalesAscending"
        checked={state === "orderBySalesAscending"}
        onChange={() => handleSortChange("orderBySalesAscending")}
      />
      <SortItem
        label="No. of item sold (High - low)"
        name="orderBySalesDescending"
        checked={state === "orderBySalesDescending"}
        onChange={() => handleSortChange("orderBySalesDescending")}
      />
      {/* <SortItem
        label="Total revenue  (Low - high)"
        name="orderByTotalRevenueAscending"
        checked={state === "orderByTotalRevenueAscending"}
        onChange={() => handleSortChange("orderByTotalRevenueAscending")}
      />
      <SortItem
        label="Total revenue (High - low)"
        name="orderByTotalRevenueDescending"
        checked={state === "orderByTotalRevenueDescending"}
        onChange={() => handleSortChange("orderByTotalRevenueDescending")}
      /> */}
      {/* <SortItem
        label="Total comission (Low - high)"
        name="orderByTotalComissionAscending"
        checked={state === "orderByTotalComissionAscending"}
        onChange={() => handleSortChange("orderByTotalComissionAscending")}
      />
      <SortItem
        label="Total comission (High - low)"
        name="orderByTotalComissionDescending"
        checked={state === "orderByTotalComissionDescending"}
        onChange={() => handleSortChange("orderByTotalComissionDescending")}
      /> */}
      <SortItem
        label="Date joined (From oldest)"
        name="orderByDateJoinedAscending"
        checked={state === "orderByDateJoinedAscending"}
        onChange={() => handleSortChange("orderByDateJoinedAscending")}
      />
      <SortItem
        label="Date joined (From newest)"
        name="orderByDateJoinedDescending"
        checked={state === "orderByDateJoinedDescending"}
        onChange={() => handleSortChange("orderByDateJoinedDescending")}
      />
      {/* <SortItem
        label="Status (A - Z)"
        name="orderByStatusAscending"
        checked={state === "orderByStatusAscending"}
        onChange={() => handleSortChange("orderByStatusAscending")}
      />
      <SortItem
        label="Status (Z - A)"
        name="orderByStatusDescending"
        checked={state === "orderByStatusDescending"}
        onChange={() => handleSortChange("orderByStatusDescending")}
      /> */}
    </>
  );
}
