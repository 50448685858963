import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import CustomersTable from "./components/CustomersTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useHistory } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import CustomerDetails from "./components/CustomerDetails";
import GridHeader from "components/GridHeader";

const Customers = () => {
  const [isCustomerDetailOpen, setCustomerDetailOpen] = useState(false);
  const history = useHistory();
  const customerDetailRef = useRef();
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const CustomerDetailTitle = () => {
    return (
      <div className={styles.customerDetailModalTitleBar}>
        <div className={styles.customerNameContainer}>
          <div>
            <Avatar name={selectedCustomer.name} />
          </div>
          <div className={styles.modalTitle}>{selectedCustomer.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setCustomerDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onCustomerClick = (value) => {
    setSelectedCustomer(value);
    setCustomerDetailOpen(true);
  };


  return (
    <div>
      <GridHeader
        header="Customers"
      />
      <Panel className={styles.panelWrap}>
        <div className={styles.customerTable}>
          <CustomersTable onCustomerClick={onCustomerClick} />
        </div>
      </Panel>
      {isCustomerDetailOpen && (
        <Modal
          isOpen={isCustomerDetailOpen}
          onAttemptClose={() => {
            history.push("/dashboard/customers");
            setCustomerDetailOpen(false);
          }}
          modalRef={customerDetailRef}
          Title={CustomerDetailTitle}
        >
          <CustomerDetails customer={selectedCustomer} />
        </Modal>
      )}
    </div>
  );
};

export default Customers;
